<div class="flex justify-center h-screen" *ngIf="loading">
    <progress class="progress progress-customTeal w-full"></progress>
</div>
<div *ngIf="!loading">
    <ng-container *ngIf="userProfile">
        <div class="flex flex-col md:flex-row justify-end gap-2 gap-y-4 pb:10 md:pb-0 ">
            <!-- <div *ngIf="hasActiveAccounts" class="justify-self-end ">
                <button [routerLink]="['../create-post']" [relativeTo]="activatedRoute"
                    class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                    <i class="material-icons mr-3 float-left">post_add</i>
                    Create A Post
                </button>
            </div> -->

            <div *ngIf="!hasActiveAccounts" class=" justify-self-end">
                <app-link-account [new]="true" [link]="false"></app-link-account>
            </div>
        </div>

        <div *ngIf="hasActiveAccounts">
            <div class="flex py-4 col-span-12">
                <!-- Select media -->
                <div class="space-y-4">
                    <p class="text-lg font-bold">Active Accounts</p>
                    <div *ngIf="hasActiveAccounts" class="flex flex-wrap gap-y-3 md:gap-y-6">
                        <!-- button -->
                        <div *ngFor="let v of userProfile['activeSocialAccounts']">
                            <div
                                class="relative rounded-full flex justify-center items-center mx-4 bg-white w-16 h-16 shadow-md">
                                <!-- [ngClass]="'rounded-full bg-white w-16 h-16 hover:border-4 hover:border-[' + socialMediaIcons[p].color + '] shadow-md'"> -->
                                <i *ngIf="v !== 'tiktok'"
                                    [ngClass]="'text-xl fa ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                                <svg class="p-0 justify-self-center" *ngIf="v == 'tiktok'"
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24"
                                    viewBox="0 0 50 50">
                                    <path
                                        d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                                    </path>
                                </svg>
                                <button (click)="removeAccount(v)" *ngIf="isAdmin || (permSocial && permSocial.unlinkAccount)"
                                    class="absolute top-0 left-0 translate-x-9 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-red-700 shadow-md">
                                    <i class="fa fa-minus text-md text-red-700"></i>
                                </button>
                            </div>
                        </div>

                        <app-link-account class="ml-3" [link]="true" *ngIf="isAdmin || (permSocial && permSocial.linkAccount)"></app-link-account>
                    </div>
                </div>
            </div>

            <!-- <div (click)="unlinkSocialMedia()" class="font-bold text-red-700 hover:cursor-pointer py-3"><i class="fa fa-minus mr-4 float-left"></i>
                <a>Unlink social media account</a>
            </div> -->

        </div>


        <div *ngIf="!hasActiveAccounts" class="">


            <div class="p-b-8">

                <div class="px-5 md:px-0 py-14 flex flex-col w-full items-center jusify-center gap-y-3">
                    <div class="w-full py-2 text-center">
                        <span class="text-lg font-bold pb-2 block">
                            Seems like you don't have any linked account</span>
                    </div>
                    <app-link-account [new]="true" [link]="false"></app-link-account>

                </div>

            </div>

        </div>

        <!-- <div class="p-6 md:pt-12 ">
            <p class="text-base font-bold">Request for more profiles</p>

            <button type="button" (click)="requestProfileModal()"
                class="m-2 py-2 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg">
                Request for profile
            </button>
        </div> -->

        <hr />

        <div class="p-6 md:pt-12 ">
            <button type="button" (click)="deleteModalOpen=true"
                class="m-2 py-2 px-8 modal-button gap-2 text-black border-2 border-black-700 text-red-500 rounded-lg">
                Delete Profile
            </button>
            <div class="tooltip" data-tip="Delete the current social media profile">
                <button type="button"><i class="material-icons">help</i></button>
              </div>
        </div>
    </ng-container>


    <div id="deleteModalOpen" *ngIf="deleteModalOpen" class="modal"
        [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
        <div class="modal-box  bg-white pt-6 pb-10 w-11/12 md:w-5/12 max-w-5xl px-0 ">
            <!-- Are you sure you want ot delete post

            <button type="button" class="font-bold" (click)="commentModalOpen=false">Cancel</button> -->
            <div class="modal-content ">
                <div class="pb-4 md:pb-6 ">
                    <h3 class="text-center font-bold text-xl text-black pt-6">Delete Profile
                    </h3>
                    <div class="divider px-0"></div>

                    <div class="text-base text-[#636363] text-center px-4 md:px-6">
                        Are you sure you want to delete your profile
                    </div>
                </div>

                <div class="flex justify-evenly pt-6">
                    <button (click)="deleteModalOpen=false"
                        class="font-bold py-3 px-5 border-none rounded-md bg-customTeal text-white">Cancel</button>
                    <button (click)="deleteProfile()"
                        class="font-bold py-3 text-red-500 px-5 border-none rounded-md">Delete profile</button>

                </div>


            </div>
        </div>



    </div>


    <div id="removePlatformModal" *ngIf="removePlatformModal" class="modal"
        [ngClass]="{'modal-open': removePlatformModal, 'modal-action': !removePlatformModal }">
        <div class="modal-box  bg-white pt-6 pb-10 w-11/12 md:w-4/12 max-w-5xl px-0 ">
            <!-- Are you sure you want ot delete post

            <button type="button" class="font-bold" (click)="commentModalOpen=false">Cancel</button> -->
            <div class="modal-content ">
                <div class="pb-4 md:pb-6 ">
                    <h3 class="text-center font-bold text-xl text-black pt-6">Unlink {{platformToRemove == 'fbg'? 'Facebook group' : platformToRemove | titlecase}}
                    </h3>
                    <div class="divider px-0"></div>

                    <div class="text-base text-[#636363] text-center px-4 md:px-6">
                        Are you sure you want to unlink {{platformToRemove == 'fbg'? 'Facebook group' : platformToRemove | titlecase}}?
                    </div>
                </div>

                <div class="flex justify-evenly pt-6">
                    <button (click)="removePlatformModal=false"
                        class="font-bold py-3 px-5 border-none rounded-md bg-customTeal text-white">Cancel</button>
                    <button (click)="unlinkSocialMedia()"
                        class="font-bold py-3 text-red-500 px-5 border-none rounded-md">Yes,unlink</button>

                </div>


            </div>
        </div>



    </div>
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

</div>