<div class="p-5 mx-3 mt-3 bg-white rounded-md ">

  <form [formGroup]="apptSettingsForm" (ngSubmit)="saveAppointmentSettings(apptSettingsForm.value)">

    <div class="">
      <!-- Title -->
      <div class="text-lg font-bold text-gray-600 pb-4">
        Appointment Settings
        <div class="tooltip" data-tip="Change appointment settings.">
          <button type="button"><i class="material-icons">help</i></button>
        </div>
      </div>
      <hr>
      <!-- Settings Content -->
      <div class=" flex gap-4 flex-col pt-4">

        <div class="flex ">
          <!-- title -->
          <div class="w-1/3">
            <p>Allow Clients to Reschedule or Cancel Appointments</p>
          </div>

          <!-- reschedule -->
          <div class=" flex flex-col gap-4 w-2/3  ">
             <div class="flex gap-4 justify-end">
               <!-- can Reschedule -->
               <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
                <div class="flex items-center w-full ">
                    <div class="text-sm font-bold">Allow clients to reschedule appointments?</div>
                </div>
                <div class="flex  w-full ">
                  <!-- toggle -->
                  <div>
                    <div class="form-control">
                      <app-toggle
                        formControlName="canReschedule"
                        [checked]="true"
                        [disabled]="false"
                        label="Allow reschedule"
                      ></app-toggle>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- Can Cancel -->
              <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
                <div class="flex items-center w-full ">
                  <div>
                    <div class="text-sm font-bold">Enable clients to cancel appointment?</div>
                  </div>
                </div>

                <div class="flex  w-full ">
                  <!-- toggle -->
                  <div>
                    <div class="form-control">
                      <app-toggle
                      formControlName="canCancel"
                      [checked]="true"
                      [disabled]="false"
                      label="Allow Cancellation"
                    ></app-toggle>
                    </div>
                  </div>
                </div>

              </div>
             </div>
            
 
         <div class="flex w-full justify-end ">
          <div class="flex flex-col w-full md:w-[616px] justify-end gap-y-4">
            <!-- reschedule hour -->
            <div *ngIf="canReschedule.value" >
              <div>
                <div class="text-sm font-bold">Window of time within which client can reschedule an appointment?<span class="text-red-500">*</span></div>
              </div>
              <!-- days/hrs -->
              <div class="flex space-x-2">
                    <div class="form-control">
                      <label class="cursor-pointer label">
                        <input type="radio" class="radio checked:bg-customTeal" formControlName="rescheduleOption"
                          value="Days" (change)="onRescheduleChange('Days')" />
                        <span class="pl-2 font-bold label-text ">Days</span>
                      </label>
                    </div>
                           
                    <div class="form-control">
                      <label class="cursor-pointer label">
                        <input type="radio" class="radio checked:bg-customTeal" formControlName="rescheduleOption"
                          value="Hours" (change)="onRescheduleChange('Hours')" />
                        <span class="pl-2 font-bold label-text ">Hours</span>
                      </label>
          
    
                </div>

              </div>

              <!-- dropdown -->
                
              <div class="flex w-full ">
                <div *ngIf="rescheduleOption=='Hours'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="canRescheduleBeforeHr"
                       class="select select-bordered select-sm w-full max-w-xs">
                        <option disabled selected>Select Hour</option>
                        <option value={{hour}}
                          *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                          {{hour==1 ? 'hour' : 'hours'}}</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div *ngIf="rescheduleOption=='Days'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="canRescheduleBeforeHr"
                       class="select select-bordered select-sm w-full max-w-xs">
                        <option disabled selected>Select Days</option>
                        <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <!-- cancellation hour -->

            <div *ngIf="canCancel.value">
              <div>
                <div class="text-sm font-bold">Window of time within which client can cancel appointments  <span
                    class="text-red-500">*</span></div>
              </div>
                     <!-- days/hrs -->
              <div>
                <div class="flex space-x-2">
                      <div class="form-control">
                        <label class="cursor-pointer label">
                          <input type="radio" class="radio checked:bg-customTeal" formControlName="cancelOption" value="Days"
                            (change)="onCancelChange('Days')" />
                          <span class="pl-2 font-bold label-text ">Days</span>
                        </label>
                      </div>
                         
                      <div class="form-control">
                        <label class="cursor-pointer label">
                          <input type="radio" class="radio checked:bg-customTeal" formControlName="cancelOption" value="Hours"
                            (change)="onCancelChange('Hours')" />
                          <span class="pl-2 font-bold label-text ">Hours</span>
                        </label>
                      </div>
                </div>
              </div>
                 <!-- dropdown -->
              <div class="flex w-full  ">
                <div *ngIf="cancelOption=='Hours'">
                  <div class="form-control">
                      <select formControlName="canCancelBeforeHr" class="select select-bordered select-sm w-full max-w-xs">
                        <option disabled selected>Select Hour</option>
                        <option value={{hour}}
                          *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                          {{hour==1 ? 'hour' : 'hours'}}</option>
                      </select>
                  </div>
                </div>
                <div *ngIf="cancelOption=='Days'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="canCancelBeforeHr" class="select select-bordered select-sm w-full max-w-xs">
                        <option disabled selected>Select Days</option>
                        <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
      
              </div>
            </div>
         
    </div>
         </div>
          </div>

        </div>
       <hr>
        <!-- ------------------------------------------------------------------------------ -->
        <div class="flex ">
          <!-- title -->
          <div class="w-1/3">
            <p>Confirmation/Reminder Options</p>
          </div>

          <!-- confirmation -->
          <div class=" flex flex-col gap-4 w-2/3  ">
            <div class="flex w-full justify-end ">
              <div class="flex flex-col w-full md:w-[616px] justify-end gap-y-4">
                <!-- reschedule hour -->
                <div class="flex flex-wrap gap-4 justify-end" >
             <div class="flex flex-col gap-4">
              <div class="flex flex-wrap gap-4 ">
                     <!-- sms/email -->
                     <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
                      <div class="flex items-center w-full ">
                        <div class="text-sm font-bold">Send a confirmation?<span class="text-red-500">*</span></div>
                      </div>
                      <div class="flex space-x-2">
                        <app-checkbox
                        formControlName="sendEmailConfirmation"
                        label="Email"
                      ></app-checkbox>
                     
  
                      <app-checkbox
                        formControlName="sendSMSConfirmation"
                        label="SMS"
             
                      ></app-checkbox>
                     
                  </div>
                    </div>
  
                            <!-- sms/email -->
                            <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
                              <div class="flex items-center w-full ">
                                <div class="text-sm font-bold">Send a reminder?<span class="text-red-500">*</span></div>
                              </div>
                              <div class="flex space-x-2">
                                <app-checkbox
                                formControlName="sendEmailReminder"
                                label="Email"
                              ></app-checkbox>
                                       
                              <app-checkbox
                              formControlName="sendSMSReminder"
                              label="SMS"
                            ></app-checkbox>
                          </div>
                            </div>
              </div>
                                              <!-- reminder hour -->
        <div class="flex flex-col w-fullborder border-solid rounded-md border-gray-200 px-4 py-4" *ngIf="sendSMSReminder.value || sendEmailReminder.value">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Preferred time to send email reminders to clients <span
                  class="text-red-500">*</span></div>
            </div>
          </div>
          <!-- Choose hrs or number of days -->
            <div class="flex flex-col justify-start w-full ">

              <div class="flex space-x-2">
                    <div class="form-control">
                      <label class="cursor-pointer label">
                        <input type="radio" class="radio checked:bg-customTeal" formControlName="preferedEmailReminderOption"
                          value="Days" (change)="onEmailReminderChange('Days')" />
                        <span class="pl-2 font-bold label-text ">Days</span>
                      </label>
                    </div>

                    <div class="form-control">
                      <label class="cursor-pointer label">
                        <input type="radio" class="radio checked:bg-customTeal" formControlName="preferedEmailReminderOption"
                          value="Hours" (change)="onEmailReminderChange('Hours')" />
                        <span class="pl-2 font-bold label-text ">Hours</span>
                      </label>
                    </div>
               
              </div>
  
              <div class="flex w-full ">
                <div *ngIf="preferedEmailReminderOption=='Hours'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="sendReminderBeforeHr"
                        class="select select-bordered select-sm w-full max-w-xs">
                        <option disabled selected>Select Hour</option>
                        <option value={{hour}}
                          *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                          {{hour==1 ? 'hour' : 'hours'}}</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div *ngIf="preferedEmailReminderOption=='Days'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="sendReminderBeforeHr"
                        class="select select-bordered select-sm w-full max-w-xs">
                        <option disabled selected>Select Days</option>
                        <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
        </div>
       
             </div>

 

        <!-- Reminder for admin/employee -->
        <div class="flex flex-col space-y-4">
          <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
            <div class="flex items-center w-full ">
              <div class="text-sm font-bold">Send Reminder to admin/employee?<span class="text-red-500">*</span></div>
            </div>
            <div class="flex space-x-2">
              <app-checkbox
              formControlName="sendNTAEmailReminder"
              label="Email"
            ></app-checkbox>

            <app-checkbox
            formControlName="sendNTASMSReminder"
            label="SMS"
          ></app-checkbox>
        </div>
          </div>


          <div class="flex w-full justify-end ">
            <div class="flex flex-col w-full md:w-[616px] justify-end gap-y-4">
              <!-- reschedule hour -->
              <div *ngIf="canReschedule.value" >
                <div>
                  <div class="text-sm font-bold">Window of time within which client can reschedule an appointment?<span class="text-red-500">*</span></div>
                </div>
                <!-- days/hrs -->
                <div class="flex space-x-2">
                      <div class="form-control">
                        <label class="cursor-pointer label">
                          <input type="radio" class="radio checked:bg-customTeal" formControlName="rescheduleOption"
                            value="Days" (change)="onRescheduleChange('Days')" />
                          <span class="pl-2 font-bold label-text ">Days</span>
                        </label>
                      </div>
                             
                      <div class="form-control">
                        <label class="cursor-pointer label">
                          <input type="radio" class="radio checked:bg-customTeal" formControlName="rescheduleOption"
                            value="Hours" (change)="onRescheduleChange('Hours')" />
                          <span class="pl-2 font-bold label-text ">Hours</span>
                        </label>
            
      
                  </div>
  
                </div>
  
                <!-- dropdown -->
                  
                <div class="flex w-full ">
                  <div *ngIf="rescheduleOption=='Hours'">
                    <div class="form-control">
                      <label class="cursor-pointer label">
                        <select formControlName="canRescheduleBeforeHr"
                         class="select select-bordered select-sm w-full max-w-xs">
                          <option disabled selected>Select Hour</option>
                          <option value={{hour}}
                            *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                            {{hour==1 ? 'hour' : 'hours'}}</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="rescheduleOption=='Days'">
                    <div class="form-control">
                      <label class="cursor-pointer label">
                        <select formControlName="canRescheduleBeforeHr"
                         class="select select-bordered select-sm w-full max-w-xs">
                          <option disabled selected>Select Days</option>
                          <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                          </option>
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <!-- cancellation hour -->
  
              <div *ngIf="canCancel.value">
                <div>
                  <div class="text-sm font-bold">Window of time within which client can cancel appointments  <span
                      class="text-red-500">*</span></div>
                </div>
                       <!-- days/hrs -->
                <div>
                  <div class="flex space-x-2">
                        <div class="form-control">
                          <label class="cursor-pointer label">
                            <input type="radio" class="radio checked:bg-customTeal" formControlName="cancelOption" value="Days"
                              (change)="onCancelChange('Days')" />
                            <span class="pl-2 font-bold label-text ">Days</span>
                          </label>
                        </div>
                           
                        <div class="form-control">
                          <label class="cursor-pointer label">
                            <input type="radio" class="radio checked:bg-customTeal" formControlName="cancelOption" value="Hours"
                              (change)="onCancelChange('Hours')" />
                            <span class="pl-2 font-bold label-text ">Hours</span>
                          </label>
                        </div>
                  </div>
                </div>
                   <!-- dropdown -->
                <div class="flex w-full  ">
                  <div *ngIf="cancelOption=='Hours'">
                    <div class="form-control">
                        <select formControlName="canCancelBeforeHr" class="select select-bordered select-sm w-full max-w-xs">
                          <option disabled selected>Select Hour</option>
                          <option value={{hour}}
                            *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                            {{hour==1 ? 'hour' : 'hours'}}</option>
                        </select>
                    </div>
                  </div>
                  <div *ngIf="cancelOption=='Days'">
                    <div class="form-control">
                      <label class="cursor-pointer label">
                        <select formControlName="canCancelBeforeHr" class="select select-bordered select-sm w-full max-w-xs">
                          <option disabled selected>Select Days</option>
                          <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                          </option>
                        </select>
                      </label>
                    </div>
                  </div>
        
                </div>
              </div>
           
      </div>
           </div>

          <div class="flex flex-col w-full md:w-[616px] justify-end gap-y-4" *ngIf="sendNTASMSReminder.value || sendNTAEmailReminder.value">
            <div class="flex items-center w-full ">
              <div>
                <div class="text-sm font-bold">Preferred time to send SMS reminders to admin (employees)? <span
                    class="text-red-500">*</span></div>
                <!-- <p class="hidden md:block">The time where the you recieve reminders about appointment, Notes and also
                  Tasks.</p> -->
              </div>
            </div>
            <!-- Choose hrs or number of days -->
            <div class="flex flex-col justify-start w-full">

              <div class="flex space-x-2">
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="preferedSMSReminderOption"
                      value="Days" (change)="onSMSReminderChange('Days')" />
                    <span class="pl-2 font-bold label-text ">Days</span>
                  </label>
                </div>
      
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="preferedSMSReminderOption"
                      value="Hours" (change)="onSMSReminderChange('Hours')" />
                    <span class="pl-2 font-bold label-text ">Hours</span>
                  </label>
                </div>
              </div>
         
  
              <div class="flex w-full ">
                <div *ngIf="preferedSMSReminderOption=='Hours'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="sendReminderNTABeforeHr"
                        class="select select-bordered select-sm w-full max-w-xs">
                        <option disabled selected>Select Hour</option>
                        <option value={{hour}}
                          *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                          {{hour==1 ? 'hour' : 'hours'}}</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div *ngIf="preferedSMSReminderOption=='Days'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="sendReminderNTABeforeHr"
                        class="select select-bordered select-sm w-full max-w-xs">
                        <option disabled selected>Select Days</option>
                        <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
  
  
          </div>
         
  
     
        </div>

            
                </div>     
              </div>
             </div>
          </div>
        </div>
        <hr>
        <!-- ------------------------------------------------------------------------------ -->

        <div class="flex ">
          <!-- title -->
          <div class="w-1/3">
            <p>Customize your Calander</p>
          </div>

          <!-- confirmation -->
          <div class=" flex flex-col gap-4 w-2/3  ">
            <div class="flex w-full justify-end ">
              <div class="flex flex-col w-full md:w-[616px] justify-end gap-y-4">
                <!-- reschedule hour -->
                <div class="flex flex-wrap gap-4 justify-end" >
                  <!-- BackGround Color for the notes -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Pick background color for tasks</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <!-- <span class="pr-2 font-bold label-text ">YES</span> -->
                  <input type="color" class="float-right w-10 h-10 ml-5" checked
                    formControlName="appointmentBgTaskColor" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Pick background color for notes</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <!-- <span class="pr-2 font-bold label-text ">YES</span> -->
                  <input type="color" class="float-right w-10 h-10 ml-5" checked
                    formControlName="appointmentBgNoteColor" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Pick background color for appointments</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <!-- <span class="pr-2 font-bold label-text ">YES</span> -->
                  <input type="color" class="float-right w-10 h-10 ml-5" checked formControlName="appointmentBgApptColor" />
            
                </label>
              </div>
            </div>
            </div>
            
            </div>
           
            
            <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4" >
                      <div class="flex items-center w-full ">
                        <div>
                          <div class="text-sm font-bold">Pick font size</div>
                          <!-- <p class="hidden md:block">The font size will be changed on the calendar.</p> -->
                        </div>
                      </div>
            
                      <div class="flex  w-full ">
                        <div>
                          <div class="form-control">
                            <label class="cursor-pointer label">
                              <!-- <input type="color" class="float-right w-10 h-10 ml-5 rounded-full" checked
                                formControlName="appointmentFontSize" /> -->

                                <select formControlName="appointmentFontSize"
                                class="select select-bordered select-sm w-full max-w-xs">
                                <option disabled selected>Select Font Size</option>
                                <option value={{font.value}}
                                  *ngFor="let font of sortedFontSize">{{font.key}}
                                  </option>
                              </select>
            
                            </label>
                          </div>
                        </div>
                      </div>
            
                    </div>

                </div>     
              </div>
             </div>
          </div>
        </div>
           

      </div>
   <!-- Save button -->
   <div class="m-5">
    <button type="button" 
    class="flex items-center rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] border border-customTeal"
      [disabled]="!apptSettingsForm.valid" (click)="openSaveModal()"
      [ngClass]="{'bg-[#cccccc] text-[#666666]': !apptSettingsForm.valid}">
     
      Save 
    </button>
    <br />
  </div>
    </div>


  </form>

</div>

<!-- create Modal -->
<app-create-confirmation *ngIf="openSave"  (cancelClicked)=closeSaveModal() (saveBtnClicked)=saveAppointmentSettings(apptSettingsForm.value) createdFromComponent='Appointment Settings' componentName="ApptSetting"></app-create-confirmation>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
 [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>