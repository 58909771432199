import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { EmployeeConversationsModel, GroupConversationsModel } from 'src/app/models/Conversations.Model';
import { PrivateConversationsModel } from 'src/app/models/Conversations.Model';




@Injectable({
    providedIn: 'root'
})
export class ConversationsService {

    conversationListRef: AngularFirestoreCollection<any>;
    conversationRef: AngularFirestoreDocument<any>;

    constructor(private db: AngularFirestore) { }

    // ------------------------------- Private Conversations -------------------------------
    createPrivateConversation(model: PrivateConversationsModel) {
        const newContent = {
            id: this.db.createId(),
            dateCreated: model.dateCreated,
            user_1: model.user_1,
            user_2: model.user_2,
            lastMessage: model.lastMessage,
            // unreadCount: model.unreadCount


        };
        this.db.collection('PrivateChat').doc(newContent.id).set(newContent);
        return newContent;
    }

    getPrivateChatByChatId(chatId: string) {
        return this.db.collection('PrivateChat', ref => ref.where('id', '==', chatId));
    }

    getPrivateChatsByUserId1(id: string): any {
        this.conversationListRef = this.db.collection('PrivateChat', ref => ref.where('user_1.id', '==', id));

        return this.conversationListRef;

    }
    getPrivateChatsByUserId2(id: string): any {
        this.conversationListRef = this.db.collection('PrivateChat', ref => ref.where('user_2.id', '==', id));
        return this.conversationListRef;

    }

    updatePrivatelastMessage(id: any, messageObj: any) {
        return this.db.collection('PrivateChat').doc(id).update({ lastMessage: messageObj });
    }

    async addUnreadCount(conversationId: any, userId: any) {
        this.conversationRef = await this.db.doc('PrivateChat/' + conversationId);
        this.conversationRef.get()
            .pipe(map(doc => doc.data()))
            .subscribe(x => {
                if (x) {
                    if (x.user_1.id === userId) {
                        x.user_1.unreadCount += 1;
                        return this.db.collection('PrivateChat').doc(conversationId).update(x);

                    } else if (x.user_2.id === userId) {
                        x.user_2.unreadCount += 1;
                        return this.db.collection('PrivateChat').doc(conversationId).update(x);

                    }
                }
            });
    }

    async removeUnreadCount(conversationId: any, userId: any) {
        this.conversationRef = await this.db.doc('PrivateChat/' + conversationId);
        this.conversationRef.get()
            .pipe(map(doc => doc.data()))
            .subscribe(x => {
                if (x) {
                    if (x.user_1.id === userId) {
                        x.user_1.unreadCount = 0;
                        return this.db.collection('PrivateChat').doc(conversationId).update(x);

                    } else if (x.user_2.id === userId) {
                        x.user_2.unreadCount = 0;
                        return this.db.collection('PrivateChat').doc(conversationId).update(x);

                    }
                }
            });
    }
    updatePrivateChat(model: any) {
        return this.db.collection('PrivateChat').doc(model.id).update(model);
    }
    deletePrivateChat(id: string) {

        return this.db.collection('PrivateChat').doc(id).delete();

    }

    // ------------------------------- Group Conversations -------------------------------



    createGroupConversation(model: GroupConversationsModel) {
        const newContent = {
            id: this.db.createId(),
            dateCreated: model.dateCreated,
            name: model.name,
            users: model.users,
            isPrivate: model.isPrivate,
            profilePicture: model.profilePicture,
            lastMessage: model.lastMessage,
            admin: model.admin,
            previousMembers: model.previousMembers,
            usersInfo: model?.usersInfo,
            unreadCount: model.unreadCount

        };
        return this.db.collection('GroupChat').doc(newContent.id).set(newContent);
        // return newContent;
    }


    getGroupChatsByUserId(id: string): any {
        this.conversationListRef = this.db.collection('GroupChat', ref => ref.where('users', "array-contains", id));
        return this.conversationListRef;
    }


    getAllGroups(): any {
        return this.db.collection('GroupChat');
    }
    getPublicGroups(): any {
        return this.db.collection('GroupChat', ref => ref.where('isPrivate', "==", false));
    }

    updateGrouplastMessage(id: any, messageObj: any) {
        return this.db.collection('GroupChat').doc(id).update({ lastMessage: messageObj });
    }
    updateGroupChat(model: any) {
        return this.db.collection('GroupChat').doc(model.id).update(model);
    }

    updateGrouponMessageSent(id: any, messageObj: any, users: any[]) {
        return this.db.collection('GroupChat').doc(id).update({ lastMessage: messageObj, unreadCount: users });
    }

    async updateGroupUnreadCount(id: any, unreadCount: any) {
        let conversationRef = await this.db.doc('GroupChat/' + id);
        conversationRef.get()
            .pipe(map(doc => doc.data()))
            .subscribe((x: any) => {
                if (x) {
                    x.unreadCount = unreadCount
                    return this.db.collection('GroupChat').doc(id).update({ unreadCount: unreadCount });
                }
            })

    }
    updateGroupMembersList(id, users: any) {
        return this.db.collection('GroupChat').doc(id).update({ users: users });

    }

    updateGroupInformation(model: GroupConversationsModel) {
        const newContent = {
            id: model.id,
            dateCreated: model.dateCreated,
            name: model.name,
            users: model.users,
            isPrivate: model.isPrivate,
            profilePicture: model.profilePicture,
            lastMessage: model.lastMessage,
            admin: model.admin,
            previousMembers: model.previousMembers

        };
        return this.db.collection('GroupChat').doc(newContent.id).update(newContent);
    }

    deleteGroupChat(id: string) {
        this.conversationRef = this.db.doc('GroupChat/' + id);

        this.conversationRef.get()
            .pipe(map(doc => doc.data()))
            .subscribe(x => {
                if (x) {
                    this.db.collection('Archived-Chats').doc(x.id).set(x);
                    return this.db.collection('GroupChat').doc(x.id).delete();
                }
            });

    }

    // ------------------------------- Employee Conversations -------------------------------


    createEmployeeConversation(model: EmployeeConversationsModel) {
        const newContent = {
            id: this.db.createId(),
            dateCreated: model.dateCreated,
            name: model.name,
            users: model.users,
            profilePicture: model.profilePicture,
            lastMessage: model.lastMessage,
            admin: model.admin,
            previousMembers: model.previousMembers,
            unreadCount: model.unreadCount,
            usersInfo: model.usersInfo

        };

        return this.db.collection('EmployeeChat').doc(newContent.id).set(newContent);

    }
    createEmpConversation(model: EmployeeConversationsModel) {
        const newContent = {
            id: this.db.createId(),
            dateCreated: model.dateCreated,
            name: model.name,
            users: model.users,
            usersInfo: model.usersInfo,
            profilePicture: model.profilePicture,
            lastMessage: model.lastMessage,
            admin: model.admin,
            previousMembers: model.previousMembers,
            unreadCount: model.unreadCount

        };
        this.db.collection('EmployeeChat').doc(newContent.id).set(newContent);
        return newContent
    }


    getEmployeeChatsByUserId(id: string): any {
        this.conversationListRef = this.db.collection('EmployeeChat', ref => ref.where('users', "array-contains", id));
        return this.conversationListRef;
    }
    getEmployeeChats(): any {
        return this.db.collection('EmployeeChat');
    }

    // updateGroupUnreadCount(id: any, unreadCount: any[]){
    //     return this.db.collection('GroupChat').doc(id).update({unreadCount: unreadCount});
    // }




    updateEmployeelastMessage(id: any, messageObj: any) {
        return this.db.collection('EmployeeChat').doc(id).update({ lastMessage: messageObj });
    }

    updateEmployeeChat(model: any) {
        return this.db.collection('EmployeeChat').doc(model.id).update(model);
    }


    // getTotalUnreadCountByUserId(id:any): any{
    //     let conversationListRef1 = this.db.collection('PrivateChat', ref => ref.where('user_1.id', '==', id));
    //     let conversationListRef2 = this.db.collection('PrivateChat', ref => ref.where('user_2.id', '==', id));
    //     let unreadCount = 0;
    //     conversationListRef1.get().toPromise()
    //     .then(function(querySnapshot) {
    //             querySnapshot.forEach(function(doc) {
    //                 let conversation = doc.data();
    //                 unreadCount += conversation.user_1.unreadCount;
    //             });            })
    //         .catch(function(error) {
    //         });

    //         conversationListRef2.get().toPromise()
    //     .then(function(querySnapshot) {
    //             querySnapshot.forEach(function(doc) {
    //                 let conversation = doc.data();
    //                 unreadCount += conversation.user_2.unreadCount;
    //             });
    //             return unreadCount;
    //         })
    //         .catch(function(error) {
    //         });
    //         // setTimeout(() => {
    //         //     return unreadCount;
    //         // }, 2000);

    // }

    updateEmployeeList(id, users: any) {
        return this.db.collection('EmployeeChat').doc(id).update({ users: users });

    }


}
