import { Component, OnInit } from '@angular/core';
import { PollsService } from 'src/app/services/firebase/polls.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css']
})
export class PollsComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any[];
  showLoader = false;

  constructor(private pollsService: PollsService, private location: Location) { }

  ngOnInit() {
    this.showLoader = true;
    this.pollsService.getPollList().valueChanges().subscribe(pol => {
      if (pol.length > 0) {
        this.model = pol;
        this.model = this.model.sort(function (a, b) {
          return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
        });
      }
      this.showLoader = false;
    });
  }

  backClicked() {
    this.location.back();
  }

  async getDocumentDefinition(index) {
    return {
      info: {
        title: this.model[index].name
      },
      pageMargins: [60, 60, 60, 60],
      content: [
        {
          columns: [
            [{
              text: this.model[index].name,
              style: 'mainTitle'
            }],
            [
              {
                text: 'Start Date : ' + this.model[index].startDate,
              },
              {
                text: 'End Date : ' + this.model[index].endDate,
                color: 'red'
              }
            ]
            // [
            //   await this.getCheckBox(),
            // ],
            // [
            //   await this.getRadioButton()
            // ]
          ],
        },
        this.getQuestions(index),
        await this.getCheckBox(),
        await this.getRadioButton(),
        this.getInput()


        // {
        //   table: {
        //     widths: [200, 100],
        //   }
        // }
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 16,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
      }
    }
  }

  getQuestions(index) {
    let questionArray = [];
    this.model[index].questions.forEach(question => {
      // if (question.answerType === 'Open Ended Answer') {
      let q = {
        text: question.questionNumber + '. ' + question.question
      }
      questionArray.push(q);
      let ans = this.getAnswers(question)
      // if (question.answerType === 'Multiple Choice') {
      //   question.answers.forEach(answer => {
      //    let a = this.getCheckBox();
      //    questionArray.push(a);
      //   });
      // }

    });
    return questionArray;
  }

  getAnswers(question) {

  }

  async getCheckBox() {
    //let checkbox = [];
    let img;
    let imageLink = "https://buildingtechsystems.com/wp-content/uploads/2021/06/unchecked-checkbox.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      img = {
        image: image,
        width: 20,
        alignment: 'left'
      }
    }
    return {
      columns: [
        img,
        {
          text: "  " + 'answer',
        }
      ]
    }
  }

  async getRadioButton() {
    let img;
    let imageLink = "https://icon-library.com/images/radio-button-icon/radio-button-icon-13.jpg";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      img = {
        image: image,
        width: 20,
        alignment: 'left'
      }
    }
    return {
      columns: [
        img,
        {
          text: "  " + 'answer',
        }
      ]
    }
  }

  getInput() {
    return {
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 500,
          h: 60,
        }]
    }
  }

  async openPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).open();
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

}
