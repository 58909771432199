import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { ImageModel } from 'src/app/models/ImageModel';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { HelperService } from 'src/app/services/helper/helper';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { Modal, Ripple, initTE } from "tw-elements";
  
@Component({
  selector: 'app-customize-card-image',
  templateUrl: './customize-card-image.component.html',
  styleUrls: ['./customize-card-image.component.css']
})
export class CustomizeCardImageComponent implements OnInit {

  
    @Input() callingSection;
    @Input() modalOpen;
    @Input() cardViewSection
    @Output() closeModal = new EventEmitter<string>();
  
    //card customization
    hexColor: string = '';
    backgroundColor: string = '';
    textColor: string;
    borderRadius: number;
    boxWidth:number;
    boxHeight: number;
    cardRef: any;
    backgroundImage: any;
    position: number;
    imageChangedEventCardCustomization: any;
    cropModalOpenCardCustomization = false;
    base64ImageCardCustomization: any
    base64Image = '';
    imgErrorMsg: any;
    usePopupBase64=false
    mediaList = [];
    isMobile = false;
  
    appSettings:any
    pageSections: any
    section:any
    applyToEachSection = false
      // toast
      toastMessage: any;
      toastClass: any;
      toastType: any;
      openToast = false;
    sliderRef: any;
    blogsRef: any;
    eventRef: any;
    productRef: any;
    aboutRef: any;
    serviceRef: any;
    appStoreRef: any;
    socialMediaRef: any;
    footerRef: any;
    welcomeRef: any;
    model: any[];
    selectedColor: any;
    returnedMediaLinks = {
      imageURL: ''
    };
    hello = ''
    mediaLink: any;
    uploadFileModalOpen = false;
    appSettings$: any;
    cardDesign =  {
      fontSize: '12px', 
      fontWeight: 'bold', 
      fontPosition: 'bottom', 
      cardShape: 'rectangle',
      imageShape: 'none', 
      imageStyle: 'full', 
      imageWidth: 60,
      imageHeight: 60,
      width: 160,
      height: 160, 
      boxColor: this.envService.getColor('primary'), 
      textColor: '#ffffff'
    }

    constructor( private router: Router,
      private helperService: HelperService,
      private store: Store,
      private settingsService: AppSettingsService, 
      private envService: EnvironmentService
    ) { 
        
        this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
          this.pageSections = this.helperService.cloneDeep(settings);
        });
    }
  
    ngOnInit(): void {
      initTE({ Modal, Ripple });
      if (!this.pageSections) {
        this.pageSections = GlobalConstants.EmptyPageSections;
      }
      const btn4 = document.getElementById('close');
      this.router.events.subscribe((val) =>{
        btn4.click()
      })

      if(this.cardViewSection){
        this.backgroundImage = this.cardViewSection.section.cardDetail.backgroundImage
      }
    
      }

    fileChangeEventCardImage(event){
      // this.cardRef = cardRef
      this.imageChangedEventCardCustomization = event;
      this.cropModalOpenCardCustomization = true;
    }
    removeWelcomeImage(){
      // this.cardRef.imgUrl = '';
      this.base64ImageCardCustomization = '';
      this.mediaList = [];
    }
    removeSavedWelcomeImage(){
      // this.cardRef.imgUrl = '';
      this.base64ImageCardCustomization = '';
      this.mediaList = [];
      this.backgroundImage = '';
      this.mediaLink = ''
    }
    processCroppedImageCardCustomization(event){
      this.cropModalOpenCardCustomization = false;
      this.usePopupBase64 = false;
      this.base64ImageCardCustomization = event;
      if(this.base64ImageCardCustomization){
        if(this.isMobile){
          const img = new ImageModel();
          img.imgBase64 = this.base64ImageCardCustomization.replace(/^data:image\/[a-z]+;base64,/, "" );
          this.mediaList.push({type:'Image', file: img});
        } 
        else{
        let base64result = this.base64ImageCardCustomization.split(',')[1];
        const img = new ImageModel();
        img.imgBase64 = base64result;
        this.mediaList.push({type:'Image', file: img});
        }
      }
      this.cardViewSection.section.cardDetail.backgroundImage= this.base64ImageCardCustomization
    }
  
    closeCroppingModal(){
      this.cropModalOpenCardCustomization = false
    }
  
    getCardDetail(){
      // this.pageSections.appSections.forEach((section:any) => {
      //   if((section.originalName == this.callingSection)){
      //     this.section = section
      //   }
      // });
  
      this.hello = this.callingSection;
    }
  
    saveSettings(){
      if(this.mediaList.length > 0){
        this.uploadFileModalOpen = true;
    }
  
    if(this.mediaList.length === 0){
      this.saveChanges()
    }
    }
  
    saveChanges(){
    if (this.applyToEachSection ){
         this.pageSections.appSections.forEach((section:any) => {
          section.cardDetail={
           backgroundColor: section.cardDetail.backgroundColor ? section.cardDetail.backgroundColor : (this.pageSections.themeColor ? this.pageSections.themeColor : this.envService.getColor('primary')), 
           textColor: section.cardDetail.textColor ? section.cardDetail.textColor : '#FFFFFF',
           boxWidth: section.cardDetail.boxWidth ? section.cardDetail.boxWidth: 160,
           boxHeight: section.cardDetail.boxHeight ? section.cardDetail.boxHeight : 160,
           borderRadius: section.cardDetail.borderRadius ? section.cardDetail.borderRadius: 12,
           backgroundImage: this.mediaLink ? this.mediaLink : this.backgroundImage,
           position: section.cardDetail.position
          }
        });

        this.updateCard()
      } else {
        this.pageSections.appSections.forEach((section:any) => {
          if(this.cardViewSection.section.originalName == section.originalName && !this.applyToEachSection){           
             section.cardDetail={
              backgroundColor: section.cardDetail.backgroundColor ? section.cardDetail.backgroundColor : (this.pageSections.themeColor ? this.pageSections.themeColor :  this.envService.getColor('primary')), 
              textColor: section.cardDetail.textColor ? section.cardDetail.textColor : '#FFFFFF',
              boxWidth: section.cardDetail.boxWidth ? section.cardDetail.boxWidth: 160,
              boxHeight: section.cardDetail.boxHeight ? section.cardDetail.boxHeight : 160,
              borderRadius: section.cardDetail.borderRadius ? section.cardDetail.borderRadius: 12,
              backgroundImage: this.mediaLink ? this.mediaLink : this.backgroundImage,
              position: section.cardDetail.position 
             }
             this.updateCard()
          }
  
        });
      }
    }
  
    updateCard(){
      this.settingsService.updateAppSettings(this.pageSections).then(x => {
        this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
        this.closeCustomizeModal()
      });
    }
  
    toast(obj){
      this.toastMessage = obj.html;
      this.toastClass = obj.classes ? obj.classes : 'green' ;
      this.toastType = obj.type ? obj.type : 'success';
      this.openToast = true;
      setTimeout(() => {
        this.openToast = false;
     
      }, 2000);
    }
  
    closeCustomizeModal(){
      this.closeModal.emit();
  
    }
  
    getTheUrlsSaved(event){
      this.uploadFileModalOpen = false;
      this.returnedMediaLinks = event;
      this.mediaLink = this.returnedMediaLinks.imageURL;
      this.saveChanges();
    }
  }
  