import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from 'src/app/services/api/email.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientAnswersService } from 'src/app/services/firebase/client-answers.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/firebase/user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { LoadingService } from 'src/app/services/loader/loading.service';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { takeUntil } from 'rxjs/operators';
import { MediaService } from 'src/app/services/api/media.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-admin-client-creation-form',
  templateUrl: './admin-client-creation-form.component.html',
  styleUrl: './admin-client-creation-form.component.css'
})
export class AdminClientCreationFormComponent {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  clientForm: UntypedFormGroup;
  answersArray: UntypedFormArray;
  visibleStepIndex = 0;
  totalQuestions = 0;
  submitDisabled = false;
  showComment = false;
  leaveComment = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  //added
  form: any;
  model: any;
  userGlobal: any;
  names: any;

  currentUser: any;
  userFilled: boolean = false;

  //to unsubscribe
  clientGlobal: any;

  invitationPopup: boolean = false;
  id: any;

  inviteGlobal: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-from-admin-invite/' : 'http://localhost:4200/register-from-admin-invite/';

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  client: any;
  forms: any;
  answer = '';
  participation: any;
  invite: any;
  value: any;
  clientPhone: any;
  emailExists: boolean = false; //the email has already been entered so it can't be modified
  multipleOne: any;
  multipleTwo: any;
  userForEmail: any;
  count: number = 0;
  exists: boolean; // the email is already inside the database

  phoneExists: boolean = false;
  confirmationPopup: boolean = false;
  role: any;

  deleteModal: boolean = false;
  userModel: any;

  appName: any;
  updateModal: boolean = false;
  beforeDeleteModal: boolean;
  confirmDeleteModal: boolean;
  confirmSecondModal: boolean;
  confirmThreeModal: boolean;

  emailChangeModal: boolean = false;
  emailChanged: string = '';
  password: any;
  emailTobeChanged: any;
  baseFields: any[] = []
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  base64Image: any;
  mediaType: any;
  imgErrorMsg: any;
  binDoc: any;
  pdfErrorMsg: any;

  imgURL: any;
  loggedUser: any;
  mobileBase64Image: any;
  isMobile: boolean;

  constructor(private fb: UntypedFormBuilder,
    private clientFormService: ClientFormService,
    private clientAnswerService: ClientAnswersService,
    private appSettingsService: AppSettingsService,
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private inviteService: InviteService,
    private emailService: EmailService,
    private location: Location,
    public afAuth: AngularFireAuth,
    private loaderService: LoadingService,
    private mediaService: MediaService,
    private contactService: ContactService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.role = this.currentUser.role;

    this.clientForm = this.fb.group({
      GivenName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      Email: ['', [Validators.email]],
      cellPhone: [''],
    });

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings: any) => {
      this.appName = settings[0].appName;
    })
    this.fetchContactHeaders();
  }


  submitClient(value: any) {

    this.emailChanged = value.Email;

  }

  closeModal() {
    this.invitationPopup = false;
  }

  openConfirmation() {
    if (this.clientForm.valid) {
      this.confirmationPopup = true;
    }

  }

  cancelConfirmation() {
    this.confirmationPopup = false;
  }

  onSubmit() {

    if (this.clientForm.value.Email) {
      // Use takeUntil to manage subscription lifecycle
      this.userService.getUserByEmail(this.clientForm.value.Email)
        .valueChanges()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((user: any) => {
          this.unsubscribe$.next(); // Complete the subject to unsubscribe
          this.unsubscribe$.complete(); // Ensure it's completed

          if (user && user.length !== 0) {
            this.toast({ html: 'This Client has already registered with this email address!', classes: 'red', type: 'error' });
          } else {
            this.saveClientInfo();
          }
        });
    } else {
      this.saveClientInfo();
    }

  }

  saveClientInfo() {
    let userModel = { ...this.clientForm.value, uid: this.userService.createID(), role: "User" }
    this.userService.saveUser(userModel).then(x => {
      if (this.clientForm.value.Email) {
        this.inviteClient();
      }
      else {
        this.router.navigate(['/admin/search-users']);
        this.toast({ html: 'Client added Successfully', classes: 'green', type: 'success' });
      }

      this.confirmationPopup = false;

    }).catch((err) => {
      this.unsubscribe$.next(); // Complete the subject in case of error
      this.unsubscribe$.complete(); // Ensure it's completed
    });
  }

  inviteClient() {
    this.invite = this.inviteService.getInviteByEmail(this.clientForm.value.Email).valueChanges().subscribe(invite => {

      if (invite) {
        if (invite.length === 0) {
          const timestamp = new Date();
          this.inviteService.saveInvite({ inviteEmail: this.clientForm.value.Email, timestamp, userType: 'User', firstName: this.clientForm.value.GivenName, cellPhone: this.clientForm.value.cellPhone, lastName: this.clientForm.value.LastName }).then((res: any) => {
          }).catch((err: any) => {
          });
        }

        let hostname;
        let newUserInviteEmail;
        let appName;
        let FirstName;
        let LastName;
        let jobtitle;

        if (invite.length === 1) {
          this.inviteGlobal = invite;
          hostname = `${this.host}${this.inviteGlobal[0].id}`;
          newUserInviteEmail = this.clientForm.value.Email;
          appName = this.currentUser.GivenName ? this.currentUser.GivenName : '';
          FirstName = this.inviteGlobal[0].GivenName
          LastName = this.inviteGlobal[0].LastName
          jobtitle = this.inviteGlobal[0].jobTitle
          this.invite.unsubscribe();

          this.emailService.sendUserInvite({
            hostname, inviteEmail: newUserInviteEmail, appName, host: this.host, id: this.inviteGlobal[0].id
          }, this.currentUser);

          this.router.navigate(['/admin/search-users']);
          this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' });
        }
      }
    });


  }

  openUpdateModal() {
    this.updateModal = true;
    setTimeout(() => {
      this.cancelConfirmation();
      this.updateModal = false;
      //this.location.back();
      this.router.navigate(['/admin/search-users']);

    }, 3000);
    // this.router.navigate(['/user/edit-clientInfo',this.id]);
  }


  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

  // fetch existing contact headers
  fetchContactHeaders() {

    this.showLoader()
    this.contactService.getContactHeaderNew().subscribe((querySnapshot) => {
      let contacts = [];

      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() });
      });


      let existingFormFields = contacts;

      // filter fields which are not the default one
      let headers = existingFormFields?.filter((header) => !header.default)
      this.baseFields = headers;

      for (let i = 0; i < headers?.length; i++) {
        let validators = [];

        if (headers?.[i]?.required) {
          validators.push(Validators.required);
        }

        if (headers?.[i]?.type === 'email') {
          validators.push(Validators.email)
        }
        else if (headers?.[i]?.type === 'phone') {
          validators.push(this.northAmericanPhoneNumberValidator())
        }

        if (headers?.[i]?.type == 'date') {
          if (headers?.[i]?.minLength) {
            validators.push(this.minDateValidator(new Date(headers[i].minLength)));
          }

          if (headers?.[i]?.maxLength) {
            validators.push(this.maxDateValidator(new Date(headers[i].maxLength)));
          }
        }
        else  {
          if (headers?.[i]?.type === 'number'){
            if (headers?.[i]?.minLength) {
              validators.push(Validators.min(headers[i].minLength));
            }
  
            if (headers?.[i]?.maxLength) {
              validators.push(Validators.max(headers[i].maxLength));
            }
          }
          else{
            if (headers?.[i]?.minLength) {
              validators.push(Validators.minLength(headers[i].minLength));
            }
  
            if (headers?.[i]?.maxLength) {
              validators.push(Validators.maxLength(headers[i].maxLength));
            }
          }
        

        }
       

          let headerValue = headers[i].type === 'checkbox'
            ? null
            : '';

        const newControl = new FormControl(headerValue, validators);
        this.clientForm.addControl(headers[i]?.key, newControl);
      }
      this.hideLoader();
    }, (err) => {
      this.hideLoader();
    });
  }

  // Custom validator functions for min and max date
  minDateValidator(minDate: Date) {
    return (control: any) => {
      const inputDate = new Date(control.value);
      return inputDate < minDate ? { 'minDate': { value: control.value, expected: minDate } } : null;
    };
  }
  maxDateValidator(maxDate: Date) {
    return (control: any) => {
      const inputDate = new Date(control.value);
      return inputDate > maxDate ? { 'maxDate': { value: control.value, expected: maxDate } } : null;
    };
  }

  // phone number validation
  northAmericanPhoneNumberValidator() {
    const phoneNumberPattern = /^(?:\+1\s?)?(?:\(?[2-9][0-9]{2}\)?[\s.-]?)?[2-9][0-9]{2}[\s.-]?[0-9]{4}$/;
    return (control) => {
      if (!control.value) {
        // If the control value is empty, return null to indicate no error
        return null;
      }
      // Perform validation if the control value is not empty
      const isValid = phoneNumberPattern.test(control.value);
      return isValid ? null : { 'invalidPhoneNumber': { value: control.value } };
    };
  }


  getMinLengthErrorMessage(control: any): string {
    let controlKey = control?.key;
    let controlType = control?.type;
    const controlErrors = this.clientForm.controls[controlKey].errors;
    if (controlErrors && controlErrors.minlength) {
      const requiredLength = controlErrors.minlength.requiredLength;
      return `The minimum ${controlType === 'number' ? 'number' : 'characters'} required is ${requiredLength}`;
    }
    else if(controlErrors && controlErrors.min) {
      const requiredLength = controlErrors?.min?.min;
      return `The minimum ${controlType === 'number' ? 'number' : 'characters'} required is ${requiredLength}`;
    }
    return '';
  }

  getMaxLengthErrorMessage(control: any): string {
    let controlKey = control?.key;
    let controlType = control?.type;
    const controlErrors = this.clientForm.controls[controlKey].errors;
    if (controlErrors && controlErrors.maxlength) {
      const requiredLength = controlErrors.maxlength.requiredLength;
      return `The maximum ${controlType === 'number' ? 'number' : 'characters'} required is ${requiredLength}`;
    }
    else if(controlErrors && controlErrors.max) {
      const requiredLength = controlErrors.max?.max;
      return `The minimum ${controlType === 'number' ? 'number' : 'characters'} required is ${requiredLength}`;
    }
    return '';
  }

  // show Loader
  showLoader() {
    this.loaderService.show()
  }

  // hide loader
  hideLoader() {
    this.loaderService.hide();
  }

  get GivenName() { return this.clientForm?.get('GivenName'); }
  get LastName() { return this.clientForm?.get('LastName'); }
  get Email() { return this.clientForm?.get('Email'); }
  get cellPhone() { return this.clientForm?.get('cellPhone'); }

}
