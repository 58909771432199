import { AuthService } from './../../../../services/firebase/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { HelperService } from 'src/app/services/helper/helper';
import { EnvironmentService } from 'src/app/services/helper/env';
import { EmployeeScheduleModel } from 'src/app/models/ScheduleModel';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { filter, map } from 'rxjs/operators';
import { TwilioPhoneNumberModel } from 'src/app/models/TwilioPhoneNumberModel';
import { AvailableNumbersModel } from 'src/app/models/AvailableNumbersModel';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

@Component({
  selector: 'app-general-setup',
  templateUrl: './general-setup.component.html',
  styleUrls: ['./general-setup.component.css']
})
export class GeneralSetupComponent implements OnInit {

  @Input() calledFromEmptyDashboard:string;
  
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() generalSetupSaved = new EventEmitter<string>();
  @Output() redirectToOtherPage = new EventEmitter<string>()
  @Input() selectedTab:string;
  @Output() customized = new EventEmitter<string>();

  appSettingsForm: UntypedFormGroup;
  generalSettingsForm: UntypedFormGroup;
  manifestForm: UntypedFormGroup;
  addScheduleModal = false
  model: any;
  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  isSidebarMenu = false;
  isBottomMenu = false;
  currentUser: any;
  isBusinessApp = false;
  isMobile = false;
  // Added B
  verifyUsers = false;
  isChecked = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  // modal
  hasActiveSection = false;
  section: any;
  editModalOpen = false;
  settingsToBeSaved: any;
  deleteModalOpen = false;
  sectionToBeDeleted: any;
  appSections = GlobalConstants.AppSections;
  currencies = GlobalConstants.Currencies;
  role: any;
  isAdmin: boolean;
  newModel: any;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';
  manifest: any;
  newSchedule: any;
  existingSchedule: any;
  defaultSchedule: any;
  previewToShow: boolean = false;
  userHasSchedule = false;
  generic: boolean ;

  //the selected
  selectedCountry: string;
  selectedProvince: string;
  selectedState: string;
  selectedPreference: string;
  fetched: any;  
  toBeChanged: boolean = false;
  selectedPhone: any;
  availablePhones : any;
  activePhones= [];

  provinces = GlobalConstants.provinces;
  states = GlobalConstants.states;
  smsClicked : boolean = false;
  subscribe: boolean = false;
  appSettings: any;
  appSettings$: Subscription;
  constructor(public appSettingsService: AppSettingsService,
              private fb: UntypedFormBuilder,
              private location: Location,
              private authService: AuthService,
              private helperService: HelperService,
              public envService: EnvironmentService,
              private scheduleService: ScheduleService,
              private smsService: SMSService,
              private store: Store
              ) {
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
                  this.appSections = this.helperService.cloneDeep(settings.appSections);
                  this.appSettings = this.helperService.cloneDeep(settings);
                  this.model = this.helperService.cloneDeep(settings);
                });

                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {

                  this.isMobile = true;
                }
              }

  ngOnInit() {

    this.currentUser = this.authService.getGlobalUser();
    // this.currentUser = this.authService.getCurrentUser();
    if(this.currentUser){
      this.role = this.currentUser.role;
    }
    if(this.role == 'Admin'){
      this.isAdmin = true;
    }

    // this.smsService.fetchAllPhoneNumber().pipe(
    //   filter((fetched) => !!fetched && fetched.length > 0),
    //   map((fetched) => fetched.map((item) => item.phoneNumber))
    // ).subscribe((phoneNumbers) => {
    //   this.fetched = phoneNumbers;
    // });

    // this.smsService.activePhone().subscribe((active: any) => {
    //   if (active && active.length) {
    //     this.activePhones = active.filter((phoneNumber) => !this.fetched?.includes(phoneNumber));
    //   } 
    // });
    
    this.generalSettingsForm = this.fb.group({
      serviceType: ['']
    });
    this.manifestForm = this.fb.group({
      name: ['',[Validators.required]],
      shortName: ['', [Validators.required]],
      icons: this.fb.array([]),
    });

    this.appSettingsForm = this.fb.group({
      appName: [''],
      description: [''],
      appLogo: [''],
      serviceType: ['', Validators.required],
      serviceName: ['Services'],
      productName: ['Products'],
      general:[''],
      takeAppointment: [''],
      // sendSMS:[false],
      isBusinessApp: [true],
      verifyUsers: [false],
      welcomeMessage: [''],
      currency: ['CAD'],
      availablePhone:[''],
      localityNumber:[''],
      // verifySubscribers:['', Validators.required],
      adminEmail: this.fb.array([this.fb.control('', [Validators.email])])
    });

    
    if (this.appSettings) {
      // this.model = {...this.appSettings}
      this.appSections.forEach(section => {
        if (section.isEnabled){
          this.hasActiveSection = true;
        }
      });
      this.model.gettingStarted.pageSelection.forEach(element => {
        if(element.originalName == 'appointment'){

        }
        if(element.originalName == 'service'){
            
        }
        if(element.originalName == 'product'){

        }
        if(element.originalName == 'audience'){

        }
      });
      if(this.appSettings){
        this.model = {...this.appSettings}
        this.isBusinessApp = this.model.isBusinessApp;
        this.verifyUsers = this.model.verifyUsers;
        this.model.logo = this.model.logo ? this.model.logo : '';
        if(this.model.adminEmail.length > 0){
          this.model.adminEmail = this.model.adminEmail.filter((ae)=>ae!=='')
        }
        // if(!this.model.general){
        //   this.model.general = '';
        // }
        this.appSettingsForm.patchValue({
          appName: this.model.appName ?? this.currentUser.GivenName,
          description: this.model.description ?? '',
          serviceType: this.model.serviceType ?? '',
          appLogo: this.model.logo,
          takeAppointment: this.model.takeAppointment ?? '',
          // sendSMS: this.model.sendSMS ? this.model.sendSMS : false,
          isBusinessApp: this.model.isBusinessApp ?? true,
          serviceName: this.model.serviceName ?? 'Services',
          productName: this.model.productName ?? 'Products',
          general: this.model.general ? this.model.general : '',
          // verifySubscribers : this.model.verifySubscribers ? this.model.verifySubscribers : '',
          verifyUsers: this.model.verifyUsers ?? false,
          welcomeMessage: this.model.welcomeMessage ??'',
          currency: this.model.currency ?? 'CAD',
          adminEmail: this.model.adminEmail ?? [],
          greetings: this.model.greetings ?? '',
          signOff: this.model.signOff ?? '',
          messageSMS: this.model.messageSMS ?? '',
          availablePhone : this.model?.availablePhone ?? '',
          localityNumber: this.model?.localityNumber ?? '',

        });
        if(this.currentUser.Email){
          if(this.model.adminEmail && !this.model.adminEmail.includes(this.currentUser.Email)){
            this.model.adminEmail.push(this.currentUser.Email);
          }
        }
        this.appSettingsForm.patchValue({
          adminEmail: this.model.adminEmail as UntypedFormArray
        })
        
        if(this.model.adminEmail.length > 1){
          for(let i = 1; i < this.model.adminEmail.length; i++){
            this.adminEmail.push(this.fb.control(this.model.adminEmail[i], [Validators.email, Validators.required]));
          }
        }

      } 
      if(this.envService.fromFNLeaders){
        this.updateSettingsDefault()
      }
      }
    
  if(this.envService.fromFNLeaders){
    this.updateSettingsDefault()
  }
  this.scheduleService.getScheduleByEmployeeId(this.currentUser.uid).valueChanges().subscribe((schedule: any) => {
    if(schedule && schedule.length > 0){
      this.userHasSchedule = true;
    }
  })
  }
  get businessAppControl(){return this.appSettingsForm.get('isBusinessApp')}
  get welcomeMessage(){return this.appSettingsForm.get('welcomeMessage')}
  get adminEmail(){return this.appSettingsForm.get('adminEmail') as UntypedFormArray; }
  get serviceType(){return this.appSettingsForm.get('serviceType'); }
  get greetings() {return this.appSettingsForm.get('greetings')};
  get signOff() {return this.appSettingsForm.get('signOff')}
  get messageSMS() {return this.appSettingsForm.get('messageSMS')}
  get takeAppointment() {return this.appSettingsForm.get('takeAppointment')}
  // get sendSMS(){return this.appSettingsForm.get('sendSMS')}
  get name(){return this.manifestForm.get('name')}
  get shortName(){return this.manifestForm.get('shortName')}
  get icons() : UntypedFormArray {return this.manifestForm.get("icons") as UntypedFormArray}
  get appLogo() { return this.appSettingsForm.get('appLogo'); }
  get availablePhone(){return this.appSettingsForm.get('availablePhone')}
  get localityNumber() {return this.appSettingsForm.get('localityNumber')}
  get general() {return this.appSettingsForm.get('general')}
  // get verifySubscribers() {return this.appSettingsForm.get('verifySubscribers')}



  addAdminEmail(){
    this.adminEmail.push(this.fb.control('', [Validators.email, Validators.required]));
  }

  removeAdminEmail(indexOfEmailToBeRemoved){
    this.adminEmail.removeAt(indexOfEmailToBeRemoved);
  }

  updateSettingsDefault(){
    this.businessAppControl.patchValue(false)
    this.takeAppointment.patchValue('')
    // this.sendSMS.patchValue(false)
    this.serviceType.patchValue('None')
    this.businessAppControl.updateValueAndValidity()
    this.takeAppointment.updateValueAndValidity()
    // this.sendSMS.updateValueAndValidity()
    this.serviceType.updateValueAndValidity()
  }

  SaveSettings(modelValues: any) {
      // updating
      if (this.model){
        // update option
        this.model.appName = modelValues.appName? modelValues.appName: this.model.appName;
        this.model.description = modelValues.description ? modelValues.description : this.model.description;
        this.model.serviceType = modelValues.serviceType ? modelValues.serviceType : this.model.serviceType;
        this.model.takeAppointment = modelValues.takeAppointment ? modelValues.takeAppointment : this.model.takeAppointment;
        // this.model.sendSMS = modelValues.sendSMS ? modelValues.sendSMS : this.model.sendSMS;
        this.model.isBusinessApp = modelValues.isBusinessApp ? modelValues.isBusinessApp : this.model.isBusinessApp;
        this.model.serviceName = modelValues.serviceName ? modelValues.serviceName : this.model.serviceName;
        // this.model.general = modelValues.general ? modelValues.general : this.model.general;
        this.model.general = modelValues.general ? modelValues.general : "";
        this.model.productName = modelValues.productName ? modelValues.productName : this.model.productName;
        // this.model.verifySubscribers = modelValues.verifySubscribers ? modelValues.verifySubscribers : this.model.verifySubscribers;
        this.model.verifyUsers = modelValues.verifyUsers ? modelValues.verifyUsers : this.model.verifyUsers;
        this.model.welcomeMessage = modelValues.welcomeMessage ? modelValues.welcomeMessage : this.model.welcomeMessage;
        this.model.adminEmail = modelValues.adminEmail ? modelValues.adminEmail : this.model.adminEmail;
        this.model.currency = modelValues.currency ? modelValues.currency : this.model.currency;
        this.model.appSections = this.appSections;
        this.model.availablePhone = this.availablePhone?.value ? this.availablePhone?.value : '';
        this.model.localityNumber = modelValues?.localityNumber?.value ? modelValues?.localityNumber?.value : '';

        if(modelValues.takeAppointment == 'yes'){
          if(this.existingSchedule){
            this.existingSchedule.employeeId = this.currentUser.uid;
            this.scheduleService.updateSchedule(this.existingSchedule)
          }
          else if(this.newSchedule){
            this.newSchedule.employeeId = this.currentUser.uid;
            this.scheduleService.createEmployeeSchedule(this.newSchedule)
          }      
          else{
            if(this.defaultSchedule){
              this.newSchedule = {schedule: [], employeeId: this.currentUser.uid , id: ''}
              this.newSchedule.schedule = this.defaultSchedule.value.schedules;
              this.scheduleService.getScheduleByEmployeeId(this.currentUser.uid).valueChanges().subscribe((schedule: any) => {
                if(schedule && schedule.length > 0){
                  this.newSchedule.id = schedule[0].id;
                  // this.scheduleService.updateSchedule(this.existingSchedule)
                } else {
                  this.scheduleService.createEmployeeSchedule(this.newSchedule)
                }
              })
            }
          }
        }
          this.updateAppSettings(this.model);
      }
      // creating new setting
      else {
        this.newModel = modelValues;
        if(this.newModel){
          this.createAppSettings(this.newModel); 
        }
      }
  }

  updateAppSettings(model){
    this.appSettingsService.updateAppSettings(model).then(() => {
      this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });
      if(this.calledFromEmptyDashboard == 'GeneralSetup'){
        // this.closeCollapse.emit('GeneralSetup');
        this.generalSetupSaved.emit('generalSetupSaved')
      }
    })
  }
  
  createAppSettings(model){
    this.appSettingsService.saveAppSettings(model).then(
      () => {
      this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
      if(this.calledFromEmptyDashboard == 'GeneralSetup'){
        // this.closeCollapse.emit('GeneralSetup');
        this.generalSetupSaved.emit('generalSetupSaved')
      }
    })
  }

  backClicked(){
    this.location.back();
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }

  toggleGenericPhone(){
    /*if(this.general.value === 'buyNum'){
      this.generic = false;
      this.appSettingsForm.get('availablePhone').clearValidators();    
      this.appSettingsForm.get('availablePhone').updateValueAndValidity(); 
    }else{
      this.generic = true;
      this.appSettingsForm.get('availablePhone').addValidators(Validators.required);  
      this.appSettingsForm.get('availablePhone').updateValueAndValidity(); 
      
    }
    this.resetInput();*/
  }


  onOfferChange(){
    if (this.serviceType.value === 'Service'){
      this.appSections.forEach(section => {
        if (section.originalName === 'Services'){
          section.isEnabled= true;
        }
        if (section.originalName === 'Products'){
          section.isEnabled= false;
        }
      });
    }
    if (this.serviceType.value === 'Product'){
      this.appSections.forEach(section => {
        if (section.originalName === 'Products'){
          section.isEnabled= true;
        }
        if (section.originalName === 'Services'){
          section.isEnabled= false;
        }
      });
    }
    if (this.serviceType.value === 'Both'){
      this.appSections.forEach(section => {
        if (section.originalName === 'Services' || section.originalName === 'Products'){
          section.isEnabled= true;
        }
      });
    }
    if (this.serviceType.value === 'Personal'|| this.serviceType.value=='None'){
      this.appSections.forEach(section => {
        if (section.originalName === 'Services' || section.originalName === 'Products'){
          section.isEnabled= false;
        }
      });
    }
  }

  confirmUpdate(appsettings){
    this.settingsToBeSaved = appsettings;
    this.settingsToBeSaved.availablePhone = this.availablePhone.value ? this.availablePhone.value: '';
    this.editModalOpen = true;
    this.appSettings = appsettings;
    const invalid = [];
    const controls = this.appSettingsForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
  }

  saveUpdate(){
    this.editModalOpen = false;
    this.settingsToBeSaved.availablePhone = this.availablePhone.value? this.availablePhone.value: '';
    this.appSettings.appSections = this.settingsToBeSaved;
    this.SaveSettings(this.appSettings);
    this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });

  }
  closeEditModal(){
    this.editModalOpen = false;
  }

  showPreview(){
    this.previewToShow = true;
  }
  onAppSignupChange(){
    this.verifyUsers = this.appSettingsForm.value.verifyUsers;
    if(this.verifyUsers){
      this.appSettingsForm.controls["verifySubscribers"].clearValidators();
      this.appSettingsForm.controls["verifySubscribers"].updateValueAndValidity();
    }else{
      this.appSettingsForm.controls["verifySubscribers"].setValidators(Validators.required);
      this.appSettingsForm.controls["verifySubscribers"].updateValueAndValidity();
    }
  }
  initializeSchedule(data){
    this.defaultSchedule = data;
  }
  updateSchedule(data){
    this.addScheduleModal=false
    const employeeSchedule = {
        employeeId: this.currentUser.uid,
        schedule: this.helperService.validateSchedule(data.data.schedules),
        id: data.savedEmployeeSchedule.id
    };
    this.existingSchedule = employeeSchedule;
  }

  createSchedule(data){
    this.addScheduleModal=false
    const employeeSchedule = new EmployeeScheduleModel();
    employeeSchedule.employeeId = this.currentUser.id;
    employeeSchedule.schedule = this.helperService.validateSchedule(data.schedules);
    this.newSchedule = employeeSchedule;
  }
  redirectToSchedule(){
    this.closeCollapse.emit('GeneralSetup');
    this.redirectToOtherPage.emit('GeneralSetup')
  }
  // toggleGenericPhone(){
  //   this.generic = !this.generic;
  //     if(this.generic==true){
  //       this.appSettingsForm.get('availablePhone').addValidators(Validators.required);               
  //     }
  //     else {                
  //       this.appSettingsForm.get('availablePhone').clearValidators();    
  //       this.appSettingsForm.get('availablePhone').updateValueAndValidity();    
  //     }
    
  //   this.resetInput();
  // }
  resetInput(){
    this.selectedCountry = "";
    this.selectedState = "";
    this.selectedProvince = "";
    this.selectedPreference = "";
  }
  selectPhone(selected){
    this.selectedPhone = selected;
    
  }
  selectCountry(country){
    this.selectedCountry = country;
  
  }
  selectState(state){
    this.selectedState = state;
 
  }
  selectProvince(province){
    this.selectedProvince = province;
  }
  selectPreference(preference){
    this.selectedPreference = preference; 
  }
  
  filter(){
    const availableModel = new AvailableNumbersModel();
    availableModel.countryCode = this.selectedCountry;
    availableModel.locality = this.localityNumber.value ? parseInt(this.localityNumber.value) : 0;
    if(this.selectedCountry == 'US'){
      availableModel.inRegion = this.selectedState ? this.selectedState: '';
    }else{
      availableModel.inRegion = this.selectedProvince ? this.selectedProvince : '';
    }
      this.smsService.availablePhoneLocalities(availableModel).subscribe((available:any) => {
          if(available && available.length){
            this.availablePhones = available;
          }
      })
  }
  
  buy(){
    const phoneModel = new TwilioPhoneNumberModel();
  
    if(this.model.availablePhone !== '' && this.fetched.includes(this.model.availablePhone)){
      phoneModel.phoneNumber = this.model.availablePhone;
      this.smsService.deletePhoneNumberFromSQL(phoneModel).subscribe((x)=>{
        this.model.availablePhone = this.selectedPhone;
        this.model.general = 'buyNum'
        phoneModel.phoneNumber = this.availablePhone.value;
      
        this.smsService.createPhoneNumber(phoneModel).subscribe((buy) =>{
          if(buy && buy.length != 0){
            this.toast({html: 'You have Successfully bought the Phone Number!', classes: 'green', type:'success'});
            this.model.purchased = true;
           this.appSettingsService.updateAppSettings(this.model).then(() => {
            this.toBeChanged = false;
           }).catch(err => {console.error(err); });
      
          }else{
            this.toast({html: 'Failed to buy the number', classes: 'red', type:'failure'})
                }
        })
      })
    }else{
    this.model.availablePhone = this.selectedPhone;
    this.model.general = 'buyNum'
    phoneModel.phoneNumber = this.availablePhone.value;
  
    this.smsService.createPhoneNumber(phoneModel).subscribe((buy) =>{
      if(buy && buy.length != 0){
        this.toast({html: 'You have Successfully bought the Phone Number!', classes: 'green', type:'success'})
        this.model.purchased = true;
       this.appSettingsService.updateAppSettings(this.model).then(() => {
        this.toBeChanged = false;
       }).catch(err => {console.error(err); });
  
      }else{
        this.toast({html: 'Failed to buy the number', classes: 'red', type:'failure'})
        }
    })
    }
  }
  
  use(){
    this.model.sendSMS = true;
    if(this.model.purchased){
      const phoneModel = new TwilioPhoneNumberModel();
      phoneModel.phoneNumber = this.model.availablePhone;
      this.smsService.deletePhoneNumberFromSQL(phoneModel).subscribe((x)=>{
        this.model.availablePhone = this.selectedPhone;
        this.model.purchased = false;
        this.appSettingsService.updateAppSettings(this.model).then(() => {
          this.toast({html: 'You have Successfully Saved the Phone Number!', classes: 'green', type:'success'})
          this.toBeChanged = false;
        }).catch(err => {console.error(err); });
      })
    }else{
      this.model.availablePhone = this.selectedPhone;
      this.model.purchased = false;
      this.appSettingsService.updateAppSettings(this.model).then(() => {
        this.toast({html: 'You have Successfully Saved the Phone Number!', classes: 'green', type:'success'})
        this.toBeChanged = false;
      }).catch(err => {console.error(err); });
    }
  
  }
  
  release(){
    const phoneModel = new TwilioPhoneNumberModel();
    phoneModel.phoneNumber = this.model.availablePhone;
    this.smsService.releasePhoneNumber(this.model.availablePhone).subscribe(x=>{
      this.smsService.deletePhoneNumberFromSQL(phoneModel).subscribe(x =>{
        
      })
      this.model.availablePhone = '';
      this.appSettingsService.updateAppSettings(this.model).then(() => {
        this.toBeChanged = false;
      this.toast({html: 'You have Successfully released the Phone Number!', classes: 'green', type:'success'})
    }).catch(err => {console.error(err); });
    })
    
  }
  change(){
    this.toBeChanged = true;
  }

  toggleVerifySubs(){
    if(this.verifyUsers){
      this.appSettingsForm.controls["verifySubscribers"].clearValidators();
      this.appSettingsForm.controls["verifySubscribers"].updateValueAndValidity();
    }else{
      this.appSettingsForm.controls["verifySubscribers"].setValidators(Validators.required);
      this.appSettingsForm.controls["verifySubscribers"].updateValueAndValidity();
    }
  }
  
}
