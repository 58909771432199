<div class="w-full  ">
  <div class="pb-4">
    <h5
    class="text-xl font-medium leading-normal text-center text-neutral-800 dark:text-neutral-200"
    id="customizeCard">
    Rearrange Cards
  </h5>
  
  <hr>
  </div>
  <div >
  
    <div cdkDropList  class="example-list" (cdkDropListDropped)="drop($event)"   class="example-list flex flex-row flex-wrap gap-4 border border-solid border-white min-h-16  mx-auto"  >
      <div class="example-box bg-customTeal text-white px-4 py-2 cursor-move " *ngFor="let section of cardElement" cdkDrag>
       <div class="flex space-x-4 items-center">
        <div class="border border-solid border-white px-4 py-2 rounded-full font-bold">
          {{section.position}}
        </div>
        
        <p class="font-bold">{{section.name}}</p>
      
       </div>
      </div>
    </div>
  
  </div>
  
  <div class="modal-action">
    <!-- if there is a button in form, it will close the modal -->
  
    <div
    class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-gray-100 border-opacity-100 p-4 dark:border-opacity-50">
    <button
      (click)="saveSettings()"
      type="button"
      class="ml-1 inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      data-te-modal-dismiss
      data-te-ripple-init
      data-te-ripple-color="light">
      Save changes
    </button>
  </div>
  </div>
    
    <!-- toast -->
  <app-alert class="fixed top-2 right-1 flex" 
  [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
  
</div>