import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from '../api/apiKeyManager.Service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AddUserToFirebaseModel, AnalyticAccModel, DataStreamParamModel, UpdateFirebaseRulesModel, UserCollectionModel, WebAppModel,  } from 'src/app/models/AutomationModel';
import { AnalyticsCached } from '../../models/AnalyticsModel'
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AutomationService {

  li: any;
  analyticsListRef: AngularFirestoreCollection<any>;
  analyticsRef: AngularFirestoreDocument<any>;
  apiUrl: string = "https://api.goingmobile.app/"

  constructor(private http: HttpClient, private keyManager: ApiKeyManagerService, private db: AngularFirestore
  ) { }


  //setup project
  setupProject(AppName: string | any) {

    const url = this.apiUrl + 'api/ConfigManager/SetupProject/';
    const model = {
      AppName: AppName
    }

    try {

      const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

      headers.append('API-KEY', this.keyManager.getKey());

      return this.http.post<any>(url, model, { headers });

    } catch (e) {
      console.error(e);

    }


  }

  // create google cloud project
  createCloudProject(AppName: string | any) {

    const url = this.apiUrl + 'api/ConfigManager/CreateCloudProject/';
    const model = {
      AppName: AppName
    }

    try {

      const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

      headers.append('API-KEY', this.keyManager.getKey());
      return this.http.post<any>(url, model, { headers });

    } catch (e) {
      console.error(e);
    }

  }

  // add firebase project
  addFirebaseProject(AppName: string | any) {

    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());
    const url = this.apiUrl + 'api/ConfigManager/AddFirebaseProject/';
    const model = {
      AppName: AppName
    }
    return this.http.post<any>(url, model, { headers });

  }

  // create web app project
  createWebappProject(model: WebAppModel) {

    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    const url = this.apiUrl + 'api/ConfigManager/CreateWebAppProject/';
    return this.http.post<any>(url, model, { headers });

  }

  // configure analytics
  configureAnalytics(AppName: string | any) {

    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    const url = this.apiUrl + 'api/ConfigManager/ConfigureAnalytics/';
    const model = {
      AppName: AppName
    }
    return this.http.post<any>(url, model, { headers });

  }

  // add cloud firestore database
  addCloudFirestore(AppName: string | any) {

    const url = this.apiUrl + 'api/ConfigManager/AddFirestore/';
    const model = {
      AppName: AppName
    }

    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());
    return this.http.post<any>(url, model, { headers });

  }



  // add firestore rules
  addFirestoreRules(AppName: string | any) {

    const url = this.apiUrl + 'api/ConfigManager/AddFirestoreRules/';
    const model = {
      AppName: AppName
    }

    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());
    return this.http.post<any>(url, model, { headers });

  }

  // create cloud firestore database and add firestore rules
  addFirestoreAndRules(AppName: string | any) {

    const url = this.apiUrl + 'api/ConfigManager/AddFirestoreAndRules/';
    const model = {
      AppName: AppName
    }

    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());
    return this.http.post<any>(url, model, { headers });

  }

  // get config variables
  getConfigEnvVar(AppName: string | any) {

    const url = this.apiUrl + 'api/ConfigManager/GetConfigParams/';
    const model = {
      AppName: AppName
    }
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());
    return this.http.post<any>(url, model, { headers });

  }

  DeleteProjectFrom_Server_DNS_AppPool(appname: any) {
    const url = this.apiUrl + 'api/AppManagment/DeleteApp/';
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });
    var model = {
      appName: appname,
    }
    headers.append('API-KEY', this.keyManager.getKey());
    return this.http.post<any>(url, model, { headers });
  }

  // delete cloud project
  deleteCloudProject(AppName: string | any) {

    const url = this.apiUrl + 'api/ConfigManager/DeleteAccProject/';
    const model = {
      AppName: AppName
    }
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());
    return this.http.post<any>(url, model, { headers });

  }

  // get cloud project
  listCloudProject(AppName: string | any) {

    const url = this.apiUrl + 'api/ConfigManager/ListAccProjects/';
    const model = {
      AppName: AppName
    }
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());
    return this.http.post<any>(url, model, { headers });

  }

  // deploy app
  deployApp(AppDesc: object | any) {
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    return this.http.post<any[]>('https://api.goingmobile.app/api/AppManagment/CreateApp', JSON.stringify(AppDesc), { headers })


  }

  // list account id for the configured google account 
  listAccountIds() {
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    return this.http.get<any[]>('https://api.goingmobile.app/api/ConfigManager/ListOfAccountId/', { headers })

  }

  // list account details for the configured google account 
  listAccountDetails() {

    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    return this.http.get<any[]>('https://api.goingmobile.app/api/ConfigManager/ListOfAccounts/', { headers })

  }

  // list properties that are linked to the analytics account
  listLinkedProjects(accountId: any | string) {

    const model = {
      AccountId: accountId
    }

    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    return this.http.post<any[]>('https://api.goingmobile.app/api/ConfigManager/NameOfProperties/', model, { headers })

  }

  // delete property id
  deleteProperty(PropertyName: string | any, AccountId: string | any) {
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    const url = this.apiUrl + 'api/ConfigManager/DeletePropertyId/';
    const model = {
      PropertyName: PropertyName,
      AccountId: AccountId
    }
    return this.http.post<any>(url, model, { headers });
  }

  // activate billing account for a project
  // BillingAccount: string | any = "billingAccounts/01E866-F50682-393BF0"
  activateBilling(AppName: string | any, BillingAccount: string | any = "billingAccounts/01E866-F50682-393BF0") {
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    const url = this.apiUrl + 'api/ConfigManager/SActivateBillingProject/';
    const model = {
      AppName: AppName,
      BillingAccount: BillingAccount
    }
    return this.http.put<any>(url, model, { headers });
  }

  // enable identity platform for a project
  enableIdentityPlatform(AppName: string | any) {
    const headers = new HttpHeaders({
      appName: environment.firstNation.name,
      'Content-Type': 'application/json; charset=utf-8',


    });

    const options = {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    };

    headers.append('API-KEY', this.keyManager.getKey());

    const url = this.apiUrl + 'api/ConfigManager/EnableIdentityPlatformApi/';
    const model = {
      AppName: AppName
    }
    return this.http.post<any>(url, model, { headers });
  }

  // enable email/password sign-in method for a project
  enableSignInMethod(AppName: string | any) {
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    const url = this.apiUrl + 'api/ConfigManager/EnableEmailAndPassword/';
    const model = {
      AppName: AppName
    }
    return this.http.patch<any>(url, model, { headers });
  }

  // get access token
  getToken() {
    const headers = new HttpHeaders({ appName: environment.firstNation.name, 'Content-Type': 'application/json; charset=utf-8' });

    headers.append('API-KEY', this.keyManager.getKey());

    return this.http.get<any[]>('https://api.goingmobile.app/api/ConfigManager/getAccessToken/', { headers })

  }

  addUserToCollection(model: UserCollectionModel) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
      'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding'
    });
    headers.append('API-KEY', this.keyManager.getKey());

    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/AddUserToCollection/`, model, { headers })

  }

  addUserAndGetId(model: AddUserToFirebaseModel) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
      'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding'
    });
    headers.append('API-KEY', this.keyManager.getKey());

    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/AddUserOnFirebase/`, model, { headers })

  }

  readFromCollection(AppName: string | any, collectionName: any | string) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      AppName: AppName,
      collectionName: collectionName,
    };

    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/ReadFromCollection/`, payload, { headers })
  }

  createRealtimeDatabase(AppName: string | any) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      AppName: AppName
    };

    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/CreateRealtimeDatabase/`, payload, { headers })

  }

  authorizeInOAuth2(scope: string | any, redirect_uri: string | any) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      scope: scope,
      redirect_uri: redirect_uri
    };

    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/AuthorizeWithOAuth2/`, payload, { headers })

  }

  createAnalyticsWithOAuth2(model: AnalyticAccModel) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/CreateAnalyticsWithOAuth2`, model, { headers })
  }

  oauth2AccessToken(authorizationCode: string | any, redirect_uri: string | any) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      code: authorizationCode,
      redirect_uri: redirect_uri
    };
    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/GetOauth2AccessToken`, payload, { headers })

  }

  addServiceAccountToAnalytics(AccountId: string | any, accessToken: string | any) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      AccountId: AccountId,
      accessToken: accessToken
    };
    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/AddUserToAnalytics`, payload, { headers })

  }

  getAccountId(displayName: string | any, accessToken: string | any) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      displayName: displayName,
      accessToken: accessToken
    };
    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/GetAccountId`, payload, { headers })

  }

  listOfAnalyticsNames(accessToken: string | any) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      accessToken: accessToken
    };
    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/ListOFAnalyticsNames`, payload, { headers })

  }

  // deleteAnalyticsAccount(code:string | any, redirect_uri: string | any, analyticsId: string | any){
  //   const headers = new HttpHeaders({})
  //   headers.append('API-KEY', this.keyManager.getKey());

  //   var payload = {
  //     code: code,
  //     redirect_uri: redirect_uri,
  //     analyticsId: analyticsId
  //   };
  //   return this.http.delete<any[]>(`https://api.goingmobile.app/api/ConfigManager/DeleteAnalyticsAccount`, payload, {})

  // }
  
  // update firebase rules for newly created apps
  updateFirebaseRules(model: UpdateFirebaseRulesModel) {
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      collectionName: model.CollectionName,
      readAuth: model.ReadAuth,
      writeAuth: model.WriteAuth
    };
    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/UpdateRules`, payload, { headers })

  }

  // Get web app config attributes
  getWebAppConfig(AppName: string){
    // https://api.goingmobile.app/api/ConfigManager/GetWebAppConfig/
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      AppName: AppName
    };
    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/GetWebAppConfig/`, payload, { headers })

  }

  // Get all billing accounts
  listBillingAccounts(){
    // https://api.goingmobile.app/api/ConfigManager/GetWebAppConfig/
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    // return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/ListAllBillingAccounts/`, payload, { headers })
    return this.http.get<any[]>('https://api.goingmobile.app/api/ConfigManager/ListAllBillingAccounts/', { headers })

  }

  // Get all billing accounts
  listProjectsUnderBilling(billingAccountName: string){
    // https://api.goingmobile.app/api/ConfigManager/GetWebAppConfig/
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    var payload = {
      billingAccountName: billingAccountName
    };
    return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/BillingProjects/`, payload, { headers })

  }

  // get measurementId
  getMeasurementId(propertyId:string){
    const headers = new HttpHeaders({})
    headers.append('API-KEY', this.keyManager.getKey());

    // return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/ListAllBillingAccounts/`, payload, { headers })
    return this.http.get<any[]>(`https://api.goingmobile.app/api/ConfigManager/GetMeasurementId?id=${propertyId}`, { headers })

  }

    // create datasetream
    createDatastream(model:DataStreamParamModel){
      const headers = new HttpHeaders({})
      headers.append('API-KEY', this.keyManager.getKey());

      var payload = {
        displayName: model.displayName,
        defaultUri: model.defaultUri,
        propertyId: model.propertyId
      };
  
      // return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/ListAllBillingAccounts/`, payload, { headers })
      // return this.http.get<any[]>(`https://api.goingmobile.app/api/ConfigManager/CreateDataStream`, { headers })
      return this.http.post<any[]>(`https://api.goingmobile.app/api/ConfigManager/CreateDataStream/`, payload, { headers })

  
    }


    // Store fetched analytics accountss from Api
    storeAnalyticsAcc(accounts: any[]){
       

        // let accountName = "accountData"

       

        // const currentDate = new Date().toISOString().split('T')[0];;

        

        // const docRef = this.db.collection('CachedAnalytics').doc(accountName);
        // return docRef.get().subscribe((doc) => {
        //   if (doc.exists) {
        //     docRef.update({
        //       accounts: accounts,
        //       currentDate: currentDate
        //     });
        //   } else {
        //     docRef.set({
        //       account: accounts,
        //       currentDate: currentDate

        //     });
        //   }
        // });

        let accountName = 'accountData';
        const currentDate = new Date().toISOString().split('T')[0];
        const docRef = this.db.collection('CachedAnalytics').doc(accountName);

        // Clean the accounts data
        // const cleanedAccounts = cleanData(accounts);

        return docRef.get().subscribe((doc) => {
          if (doc.exists) {
            docRef.update({
              accounts: accounts,
              currentDate: currentDate
            })
            .then(() => {
            })
            .catch(error => {
              console.error("Error updating document: ", error);
            });
          } else {
            docRef.set({
              accounts: accounts,
              currentDate: currentDate
            })
            .then(() => {
            })
            .catch(error => {
              console.error("Error writing document: ", error);
            });
          }
        });

    

      }


      getStoredAnalyticsAcc(): Observable<any> {
        return this.db.collection('CachedAnalytics').doc('accountData').valueChanges();
      }


}


