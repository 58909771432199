 
<div *ngIf="!calledFromDashboardCreate && allSections && allSections.length > 0" class="flex flex-wrap">
    <div class="w-full md:w-72    mb-12 md:mx-4  md:mb-10 flex  min-h-max  border bg-white drop-shadow-md rounded-lg hover:scale-110 hover:ease-in hover:duration-300"
        *ngFor="let section of allSections" (click)="navigateToSection(section.id)" [ngStyle]="{'background-color': section.backgroundColor}">

        <div class=" flex flex-col w-full items-center space-y-4   px-4  pt-4  md:px-6 ">
            <div *ngIf="section.imgLink" class="w-full h-40 md:h-48 rounded-xl " [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0)),url('+ section.imgLink +'); background-size: contain; background-repeat: no-repeat; background-position: center;'">
            </div>
            <h4 class="max-w-sm text-2xl font-bold text-center pb-5" >{{getInnerText(section.sectionTitle)}}</h4>
        </div>
    </div>
</div>

<div *ngIf="calledFromDashboardCreate && allSections && allSections.length > 0" class="mx-10">
    <!-- <form #selectSection="sectionSelection"> -->
        <select class="select select-bordered w-full max-w-md" (change)="onSelectSection($event.target.value)">
            <option disabled selected>Select a page</option> 
            <option  *ngFor="let section of allSections" [value]="section.id" >{{getInnerText(section.sectionTitle)}}</option>
          </select>
    <!-- </form> -->
 

</div>