import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/firebase/form.service';



// src/app/models/form-field.model.ts
export interface FormField {
  label: string;
  type: 'text' | 'textarea' | 'date' | 'dropdown' | 'toggle';
  placeholder: string,
  required: boolean;
  fullWidth: boolean;
  options?: string[];
  validation?: {
    pattern?: string;
    minLength?: number;
    maxLength?: number;
  };
}

@Component({
  selector: 'app-customer-form-builder-configration',
  templateUrl: './customer-form-builder-configration.component.html',
  styleUrl: './customer-form-builder-configration.component.css'
})


export class CustomerFormBuilderConfigrationComponent implements OnInit {
  fieldForm: FormGroup;
  loading = false;
  id = null;
  fromParam;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private formService: FormService) {
    this.fieldForm = this.fb.group({
      label: ['', Validators.required],
      placeholder: [''],
      type: ['text', Validators.required],
      required: [false],
      fullWidth: [false],
      options: this.fb.array([]),
      minLength: [''],
      maxLength: ['']
    });

    this.fromParam = this.route.snapshot.queryParamMap.get('from');

    this.fieldForm.get('type')?.valueChanges.subscribe(value => {

      if (value === 'dropdown') {
        if (this.options.length === 0) {
          this.addOption();
        }
        this.fieldForm.get('options')?.setValidators(Validators.required);
      } else {
        this.clearOptions();
        this.fieldForm.get('options')?.clearValidators();
      }
      this.fieldForm.get('options')?.updateValueAndValidity();
    });
  }

  ngOnInit() {
    // Extract the 'id' parameter from the route
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id) {
        this.fetchContactHeaderById();
        this.fieldForm.get('type').disable();
      }

    });

    // 
    if (this.fieldForm.get('type')?.value === 'dropdown') {
      this.addOption();
    }
  }

  get options() {
    return this.fieldForm.get('options') as FormArray;
  }

  createOption(): FormGroup {
    return this.fb.group({
      option: ['', Validators.required]
    });
  }

  addOption() {
    this.options.push(this.createOption());
  }

  removeOption(index: number) {
    this.options.removeAt(index);
  }

  clearOptions() {
    while (this.options.length) {
      this.options.removeAt(0);
    }
  }

  fetchContactHeaderById() {
    this.formService.getContactHeaderNewId(this.id).subscribe((data: any) => {
      // Update the form fields with the fetched data
      this.fieldForm.patchValue({
        label: data.name || '',
        placeholder: data.name || data.placeholder || '',
        type: data.type || 'text',
        required: data.required || false,
        fullWidth: data.fullWidth || false,
        minLength: data.minLength || '',
        maxLength: data.maxLength || ''
      });
      // If options is an array, update the FormArray accordingly
      if (data.options && Array.isArray(data.options)) {
        this.options.clear()
        data.options.forEach(option => {
          this.options.push(this.fb.group({
            option: [option?.option, Validators.required]
          }));
        });
      }

    }, (err) => {
      console.error(err);
      // this.hideLoader();

    });
  }

  onSave() {
    const formField: any = {
      name: this.fieldForm.value.label,
      type: this.fieldForm.value.type,
      key: (this.fieldForm.value.label).toLowerCase().replace(/ /g, "_"),
      placeholder: this.fieldForm.value.placeholder || "",
      required: this.fieldForm.value.required,
      fullWidth: this.fieldForm.value.fullWidth,
      options: this.fieldForm.value.type === 'dropdown' ? this.fieldForm.value.options : [],
      clientField: true,
      minLength: this.fieldForm.value.minLength,
      maxLength: this.fieldForm.value.maxLength,
      created_at: (new Date()).toISOString(),
      updated_at: (new Date()).toISOString()
    };

    if (this.fieldForm.value.validation) formField['validation'] = this.fieldForm.value.validation;

    this.loading = true;
    this.formService.saveContactHeaderNew(formField).then(() => {
      this.loading = false;
      this.navigateBack();
    }).catch(error => {
      console.error('Error saving form: ', error);
      this.loading = false;
    });


  }

  onUpdate() {
    const formField: any = {
      name: this.fieldForm.value.label,
      key: this.fieldForm.value.label,
      placeholder: this.fieldForm.value.placeholder || "",
      required: this.fieldForm.value.required,
      fullWidth: this.fieldForm.value.fullWidth,
      clientField: true,
      id: this.id,
      minLength: this.fieldForm.value.minLength,
      maxLength: this.fieldForm.value.maxLength,
      updated_at: (new Date()).toISOString()
    };

    if (this.fieldForm.value.validation) formField['validation'] = this.fieldForm.value.validation;

    this.loading = true;
    this.formService.updateContactHeaderNew(formField).then(() => {
      this.loading = false;
      this.navigateBack();
    }).catch(error => {
      console.error('Error saving form: ', error);
      this.loading = false;
    });
  }

  navigateBack() {
    if (this.fromParam === 'importContact') {
      this.router.navigate(["/admin/users-list/import-contact"]);
    } else {
      this.router.navigate(["/admin/customer-form"]);
    }
  }

}







