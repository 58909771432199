import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-element',
  templateUrl: './card-element.component.html',
  styleUrl: './card-element.component.css'
})
export class CardElementComponent {


  @Input() backgroundColor: string = '#4CAF50'; // Default background color
  @Input() textColor: string = '#fff';   // Default text color
  @Input() padding: string = '10px 20px';
  @Input() borderRadius: string = '5px';

  @Input() cardDesign
  @Input() section

  getImageShape(){
    let imageShape 

    switch(this.cardDesign.imageShape){
      case 'square':
        imageShape= '0'
        break;
      case 'circle':
        imageShape= '100'
        break;
      case 'rectangle':
        imageShape= '0'
        break;
      default:
        imageShape= '0'
  
    }

    return imageShape
    
  }


  // @Input() boxHeight: string = '5px';
  // @Input() boxWidth: string = '5px';
  // @Input() backgroundImage: string = '#4CAF50'
  // @Input() fontPosition: string = '#4CAF50'
  // @Input() fontSize: string = '#4CAF50'
  // @Input() fontWeight: string = '#4CAF50'
  // @Input() imageShape: string = '#4CAF50'

  // @Input() fontWeight: string = '#4CAF50'



  
}
