import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { ForwardedMessageModel, MessagesModel } from 'src/app/models/Messages.Model';
import { UnreadCountModel } from 'src/app/models/UnreadCountModel';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messageListRef: AngularFirestoreCollection<any>;
  messageRef: AngularFirestoreDocument<any>;
  unreadCountListRef: AngularFirestoreCollection<any>;

  constructor(private db: AngularFirestore) { }

  createMessage(model: MessagesModel) {
    const newContent = {
        id: this.db.createId(),
        timeStamp : model.timeStamp,
        message: model.message,
        fromId : model.fromId,
        toId: model.toId,
        conversationId: model.conversationId,
        attachment: model.attachment,
        edited: model.edited,
        likes: model.likes
    };
    return this.db.collection('Messages').doc(newContent.id).set(newContent);
  }
  createForwardedMessage(model: ForwardedMessageModel){

    const newContent = {
      id: this.db.createId(),
      timeStamp : model.timeStamp,
      message: model.message,
      fromId : model.fromId,
      forwardedFrom: model.forwardedFrom,
      toId: model.toId,
      conversationId: model.conversationId,
      likes: model.likes,
      edited: model.edited,
      forwardedMessage: model.forwardedMessage,
      attachment : model.attachment
    };
    return this.db.collection('Messages').doc(newContent.id).set(newContent);
  }


  getMessageByChatId(id: string): AngularFirestoreCollection<any[]> {
    this.messageListRef = this.db.collection('Messages', ref => ref.where('conversationId', '==', id));
    return this.messageListRef;
  }

  createUnreadCount(model: UnreadCountModel){
    const newContent = {
      id: this.db.createId(),
      ConverId : model.ConverId,
      userId: model.userId,
      unreadCount: model.unreadCount
    };
    this.db.collection('UnreadCount').doc(newContent.id).set(newContent);
    return newContent;
  }
  getUserCountById(id: any){
    return this.db.doc('UnreadCount/' + id);
  }
  getUnreadCountByUserId(id: any){
    return this.messageListRef = this.db.collection('UnreadCount', ref => ref.where('userId', '==', id));
  }
  getUnreadCountByConverId(id: any){
    return this.messageListRef = this.db.collection('UnreadCount', ref => ref.where('ConverId', '==', id));
  }

  // update unread count whenever a new message is sent
  updateUnreadCount(userId, conversationId, increment){
    this.unreadCountListRef = this.db.collection('UnreadCount', ref => ref.where('userId', '==', userId)
                                                                          .where('ConverId', '==', conversationId));
    const unreadCollection = this.db.collection('UnreadCount');
    this.unreadCountListRef.get().toPromise()
    .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const unreadCountDoc = doc.data();
                if (increment){
                  unreadCountDoc.unreadCount += 1;
                }else{
                  unreadCountDoc.unreadCount = 0;
                }
                unreadCollection.doc(doc.id).update(unreadCountDoc);
            });
        })
        .catch((error) => {
            console.error('Error getting documents: ', error);
        });
  }

  deleteMessage(id: string) {
      return this.db.collection('Messages').doc(id).delete();
  }

  deleteUnreadCountByConverId(converId: any, userId: any){
    this.unreadCountListRef = this.db.collection('UnreadCount', ref => ref.where('ConverId', '==', converId)
                                                                           .where('userId', '==', userId));
    const unreadCollection = this.db.collection('UnreadCount');
    this.unreadCountListRef.get().toPromise()
    .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const unreadCountDoc = doc.data();
                unreadCollection.doc(doc.id).delete();
            });
        })
        .catch((error) => {
            console.error('Error getting documents: ', error);
        });


  }

  deleteUnreadCount(id: string) {
    return this.db.collection('UnreadCount').doc(id).delete();
  }

  updateMessageLikes(model: MessagesModel){
    return this.db.collection('Messages').doc(model.id).update({likes: model.likes});
  }

  editMessage(model: MessagesModel){
    return this.db.collection('Messages').doc(model.id).update({message: model.message, edited: true});
  }

}
