<div class="bg-gray-100 min-h-screen max-h-max pt-10" [ngClass]="{'px-2 md:px-12': !currentUser, 'pl-2 md:pl-12': currentUser}">
 
  <app-firebase-loading *ngIf="showLoading"></app-firebase-loading>
  <div class="flex flex-wrap gap-4 items-center" *ngIf="!(calledFrom == 'onBoarding')">
    <!-- <div class="cursor-pointer">
      <div (click)="viewAsFeed = !viewAsFeed" class="btn-custom-bordered">{{'Change View Design'}}</div>
      </div>-->
    <div *ngIf="(isAdmin || (permPES && permPES.createPosts)) && !(calledFrom == 'onBoarding')"  class="flex flex-col md:flex-row gap-4 md:items-center mb-4"> 
      <div class="flex md:justify-start justify-center gap-4">
        <button [routerLink]="['/admin/create-blog']" *ngIf="isAdmin" 
        class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] border border-customTeal">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        <span>   Add New</span>
        </button>
        <div *ngIf="permPES && permPES.createPosts">
          <button  [routerLink]="['/employee/create-blog']" class="flex items-center rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"><i class="material-icons float-left md:mr-2">drive_file_rename_outline</i>Add New</button>
        </div>
        <div>
          <app-rename-section section="Blogs" ></app-rename-section>
        </div>
 
      </div>

      <div  class="flex flex-col md:flex-row gap-4 items-center">
      <app-homepage-section-number section="Blogs" [length] = "filteredPosts.length" (confirmationModal) = "openConfirmationModal()"></app-homepage-section-number>

        <button 
        type="button"
        *ngIf="filteredPosts.length > 0"
       (click)="openMoveModal=true"
      class="flex space-x-2 items-center bg-white rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
>    
    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
          <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
        <span>Arrange Items</span>  
        
      </button>
  
  
      </div>
    </div>

  </div> 


  <div class="mt-5"  *ngIf="filteredPosts.length==0">
    <div class="flex items-center justify-center">
      <img  src="../../../../../assets/icons/Illustrations/blog Illustration.png"
        class="block w-64 items-center justify-center">
    </div>
    <div *ngIf="isAdmin">
      <div class="text-customTeal text-lg text-center font-bold">Feel like sharing your thoughts and Ideas?</div>
      <div class="text-gray-400 text-sm text-center font-bold">Share them with a blog!</div>
    </div>
    <div *ngIf="!isAdmin">
      <div class="text-customTeal text-lg text-center font-bold">No blogs found!</div>

    </div>
  </div>

  <div >
  <!-- <div *ngIf="filteredPosts.length > 0"> -->
          <!-- Pagination -->
          <!-- <div class="w-full text-center mt-10">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div> -->
          
    <div class="flex flex-col md:flex-row  p-3  place-content-center">
      <!-- your blogs -->
      <ng-container *ngIf="displayedBlogs.length > 0">

        <div *ngIf="!viewAsFeed" class="flex flex-col space-y-2  md:w-4/5">
          <!-- <p class="md:text-lg font-bold text-gray-500">{{blogsName}}</p> -->

          <div (click)="navigate(post)" class="" *ngFor="let post of getSortedList(displayedBlogs) ">
              <div class="flex bg-white shadow p-4 md:p-8 rounded-sm">
                <div class="flex flex-col md:flex-row w-full">
                  <div class="flex w-full flex-col md:flex-row md:w-11/12 gap-5">
                    <div class="" [ngClass]="{'md:w-4/12 w-full':post.mediaLink}">
                      <div *ngIf="post.mediaLink && !post.videoLink" id='i' class=" w-full rounded-md "  >
                        <div id='i' class=" w-full h-full rounded-lg blur-none" style="object-fit: cover ">
                          <img [src]="post.mediaLink" class=" object-contain w-full h-56  rounded-lg"  >
                        </div>

                        
                      </div>
                      <div *ngIf="post.mediaLink && post.videoLink">
                        <video controls playsinline preload="none" class=" w-full rounded-md  object-fill" [poster]="post.mediaLink">
                          <source [src]="post.videoLink | safeUrl"  >
                          Your browser does not support HTML video.
                        </video>
                      </div>
                      <div *ngIf="!post.mediaLink && post.videoLink">
                        <video controls preload="none" class=" w-full   rounded-md object-fill" poster="https://api.goingmobile.app/media/personal-app-assets/img/webp/empty-video.webp">
                          <source [src]="post.videoLink | safeUrl"  >
                          Your browser does not support HTML video.
                        </video>
                      </div>
                    </div>
                    <!-- content -->
                    <div class="space-y-3" [ngClass]="{'md:w-8/12 w-full': post.mediaLink, 'w-full':!post.mediaLink}">
                        <div class="flex space-x-5 items-center">
                          <p class="text-xs text-gray-500 ">{{post.timeStamp.toDate() | date: 'MMM-dd-yyyy'}}</p>
                       
                          <app-share-status *ngIf="currentUser && currentUser.role" [status] = 'post.shareWith' design = 'Primary' ></app-share-status>
                 
                        </div>



                        <p class=" md:text-xl font-bold break-words">{{truncateHTML(post.postTitle,60)  | titlecase }}</p>
                        <div class="w-full break-words">
                          <h4 class="text-sm">{{truncateHTML(getInnerText(post.message), 250)}}</h4>
                        </div>
                        <div class="flex justify-between items-center pt-4 space-x-6 w-4/5 text-gray-500">
                            <!-- <div class="flex flex-row space-x-1 items-center  md:ml-24 md:mt-0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                                  </svg>
                                <span class="text-xs text-center ">{{post.readingTime}} min read</span>
                            </div> -->
                        </div>
                    </div>
 
  
                   
                  </div>

                    <div class="md:ml-3 flex md:flex-col gap-x-4 items-center">
                      <div class="md:my-2"><i class="material-icons text-gray-400" *ngIf="post.videoLink">videocam</i></div>
                      <div class="md:mb-2"><i class="material-icons text-gray-400" *ngIf="post.pdfLink">picture_as_pdf</i></div>
                      <div class="md:mb-2"><i class="material-icons text-gray-400" *ngIf="post.sharedLink">link</i></div>
                    </div>
            
                </div>
            </div>
          </div>

        </div>
        <!-- your blogs /view as feed -->
        <div *ngIf="viewAsFeed"  class="flex flex-col space-y-3 pt-12 md:w-2/3">
          <p class="md:text-lg font-bold text-gray-500">{{blogsName}}</p>
          <div (click)="navigate(post)" class="" *ngFor="let post of getSortedList(displayedBlogs)">
              <div class="w-full bg-white p-4 md:p-8 rounded-md">

                <div class=" w-full space-y-3">
                    <p class="break-words md:text-lg font-bold">{{truncateHTML(post.postTitle,40) | titlecase }}</p>
                    <div class="w-full break-words">
                      <h3 class="hidden md:block text-sm">{{truncateHTML(getInnerText(post.message), 200)}}</h3>
                    </div>
                    <div class="flex justify-between items-center pt-4 space-x-6 w-4/5 text-gray-500">
                        <p class="text-xs text-gray-500 ">{{post.timeStamp.toDate() | date: 'MMM.dd.yyyy'}}</p>
                        <div class="flex flex-row space-x-1 items-center  md:ml-24 md:mt-0">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                              </svg>
                            <span class="text-xs text-center ">{{post.readingTime}} min read</span>
                        </div>
                    </div>
                </div>
                <div class="w-full items-center">
                  <img class="mx-auto" [src]="post.mediaLink">
                </div>
                <div class="w-full flex flex-row mt-5 p-4">
                  <hr/>
                  <div class="w-3/12">
                    <i class="material-icons float-left mr-2">thumb_up</i>
                    <span class="hidden md:inline">Like</span>
                  </div>

                  <div class="w-3/12">
                    <i class="material-icons float-left mr-2">comment</i>
                    <span class="hidden md:inline">Comment</span>
                  </div>

                  <div class="w-3/12">
                    <i class="material-icons float-left mr-2">share</i>
                    <span class="hidden md:inline">Share</span>
                  </div>
                  <div class="w-3/12">
                    <i class="material-icons float-left mr-2">send</i>
                    <span class="hidden md:inline">Send</span>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </ng-container>


    </div>
      <!-- Pagination -->
      <div class="w-full text-center mt-10">
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        <app-pagination [totalItems]="total" [itemsPerPage]="itemsPerPage" [currentPage]="p" (currentPageChange)="pageChangeEvent($event)"></app-pagination>

      </div>
  </div>

</div>

<app-move-elements *ngIf="openMoveModal"  [elementsList]="posts" sectionType="Posts" (closeClicked)="openMoveModal=false"></app-move-elements>
<!-- <app-blog-list-minimal [posts] = " getSortedList(filteredPosts)" [currentUser] = "currentUser"></app-blog-list-minimal>
<app-blog-list-default [posts] = " getSortedList(filteredPosts)" [currentUser] = "currentUser"></app-blog-list-default> -->


<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]"  [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
