<!--------------If Employee Clicked------------>
<div class=" max-w-7xl mx-4 md:mx-10">
  <!----------------Employee and Invited Employee Button------------------------>
  <div class="flex md:flex-row flex-col justify-between items-center">
    <div class="flex font-bold space-x-4" *ngIf="!showInvitedEmployeesList">
      <div class="text-sm text-center"><button (click)="fetchEmployees()"
          class="px-2 py-1 font-bold border-2 md:px-10 rounded-md border-gray-200 flex items-center space-x-2"
          [ngClass]="{'text-white bg-customTeal fill-white' : !showInvitedEmployees, 'text-customTeal bg-white fill-customTeal': showInvitedEmployees}">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3" viewBox="0 0 448 512">
            <path
              d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
          </svg>
          <span>Employees</span>
        </button></div>
      <div class=" text-sm text-center"><button (click)="onFilterInvitedEmployees()"
          class="px-2 py-1 font-bold border-2  rounded-md md:px-10 border-gray-200 flex items-center space-x-2  "
          [ngClass]="{'text-white bg-customTeal fill-white': showInvitedEmployees, 'text-customTeal bg-white fill-customTeal': !showInvitedEmployees}">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 640 512">
            <path
              d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
          </svg>
          <span>Invited Employees</span>
        </button></div>
    </div>


    <!-- To show the colors   -->
    <div
      class="flex flex-wrap items-center px-4 py-2  mb-2 md:flex-row md:space-x-4 md:mx-12 lg:mx-4 bg-white rounded-sm "
      *ngIf="allUsersByRole && allUsersByRole.length != 0">
      <div class="flex items-center">
        <div class="w-3 h-3 bg-green-500 rounded-full"></div> <span class="px-2 text-base">SMS and Email allowed.</span>
      </div>
      <div class="flex items-center">
        <div class="w-3 h-3 bg-yellow-200 rounded-full"></div> <span class="px-2 text-base">Email or SMS</span>
      </div>
      <div class="flex items-center">
        <div class="w-3 h-3 bg-red-500 rounded-full"></div> <span class="px-2 text-base">Do not Contact </span>
      </div>
    </div>
  </div>
  <div class="flex justify-end px-6 py-2">
    <p></p>

    <!---------------Contact Employee using Email or SMS---------------------->
    <button (click)="openContactEmployee()" class="px-2 py-2 text-white border rounded-md modal-button bg-customTeal"
      *ngIf="selectedEmployees.length > 0 && (isAdmin || permMember && permMember.contactUsers) && !showInvitedEmployees"><i
        class="float-left w-2 h-2 pr-8 material-icons">group</i>
      Contact Employees</button>

    <!-- Contact Employees modal -->
    <div id="my-modal-users" *ngIf="contactEmployeeModal" class="modal"
      [ngClass]="{'modal-open': contactEmployeeModal, 'modal-action': !contactEmployeeModal }">
      <div class="max-w-4xl modal-box">
        <div class="modal-content">
          <h3 class="text-lg font-bold">Contact Employees</h3>
          <p class="pt-4 pl-1 text-gray-400">You can contact Employees using email or SMS if the Employee is registered
            his/her phonenumber</p>

          <form [formGroup]="contactEmployeeForm"
            (ngSubmit)="sendEmployees(contactEmployeeForm.value.subject, contactEmployeeForm.value.emailbody, contactEmployeeForm.value.message)">

            <div class="flex items-center mt-4 space-x-8 ">
              <div class="flex-col px-2 text-black">Contact with:</div>
              <div>
                <input type="radio" id="pub" name="fav_language" value="Public" class="mx-2"
                  [checked]="notificationType == 'email'" (click)="SMSselectedEmp('email')" [disabled]="isLimitEmail">
                <label for="pub">Send Email</label><br>
              </div>
              <div>
                <input type="radio" id="User" name="fav_language" value="Users" class="mx-2"
                  [checked]="notificationType == 'sms'" (click)="SMSselectedEmp('sms')" [disabled]=" isLimitSMS">
                <label for="User">Send SMS</label><br>
              </div>
              <div>
                <input type="radio" id="User" name="fav_language" value="Users" class="mx-2"
                  [checked]="notificationType == 'push'" (click)="SMSselectedEmp('push')">
                <label for="User">Send Push Notification</label><br>
              </div>
            </div>
            <br />
            <span *ngIf="disable" class="text-error">This User doesn't have a phone number.</span>

            <div class="flex flex-col">
              <p class="flex font-bold" *ngIf="notificationType == 'email'">Selected Emails
              </p>
              <p class="flex font-bold" *ngIf="notificationType == 'sms'">Selected Phone Numbers</p>
              <p class="flex font-bold" *ngIf=" notificationType == 'push'">Selected Users
              </p>
            </div>

            <div class="flex flex-wrap px-2 bg-gray-100" *ngIf="selectedEmployees.length != 0 && !isLimitEmail">
              <p *ngFor="let selected of selectedEmployees" class="flex py-2 ">
                <span *ngIf="selected.Email != '' && notificationType == 'email'" class="flex"
                  [ngClass]="{'text-green-500': (selected.contact.includes('SMS') && selected.contact.includes('Email')), 'text-red-500': (!selected.contact.includes('SMS') && !selected.contact.includes('Email')),  'text-yellow-300' : (selected.contact.includes('SMS') && !(selected.contact.includes('Email'))), 'text-yellow-300' : (!selected.contact.includes('SMS') && selected.contact.includes('Email'))}">
                  {{selected.Email}} , </span>
              </p>
              <p *ngFor="let selected of selectedEmployees" class="flex py-2"><span
                  *ngIf="(selected.cellPhone != '' && notificationType == 'sms') && !isLimitSMS" class="flex"
                  [ngClass]="{'text-green-500': (selected.contact.includes('SMS') && selected.contact.includes('Email')), 'text-red-500': (!selected.contact.includes('SMS') && !selected.contact.includes('Email')),  'text-yellow-300' : (selected.contact.includes('SMS') && !(selected.contact.includes('Email'))), 'text-yellow-300' : (!selected.contact.includes('SMS') && selected.contact.includes('Email'))}">
                  {{selected.cellPhone}} , </span></p>
              <p *ngFor="let selected of selectedEmployees" class="flex py-2"><span *ngIf="(notificationType == 'push')"
                  class="flex" [ngClass]="{'text-green-500': (selected.token), 'text-red-500': (!selected.token)}">
                  {{selected.GivenName}} , </span></p>
            </div>

            <div *ngIf="(notificationType == 'sms') && !isLimitSMS">

              <div class="px-2 text-red-400 ">
                <i class="float-left pr-2 material-icons blue-text">warning</i>
                Some Users don't have their mobile number installed therefore wont recieve an SMS
              </div>

              <div class="w-full px-2 mt-2 ">
                <label for=" " class="mb-2 text-sm font-bold "> Message: </label>
                <textarea id="message" formControlName='message'
                  class="w-full h-32 px-3 py-4 mt-2 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline "
                  placeholder="Message"></textarea>
              </div>

              <div class="flex justify-between m-2">
                <button type="button" (click)="closeContactEmployee()"
                  class="font-bold text-black border-none">Cancel</button>
                <button class="px-3 py-2 font-bold border-none rounded-md text-customTeal" type="submit">Send
                  Message</button>
              </div>
            </div>

            <div *ngIf="(notificationType == 'email') && !isLimitEmail">
              <div class="w-full px-2 mt-2 ">

                <div>
                  <label for=" " class="mb-2 text-sm font-bold "> Subject: </label>
                  <input type="email" name="subject" formControlName='subject'
                    class="block w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm form-control border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 sm:text-sm focus:ring-1"
                    placeholder="Subject" />
                  <br />
                </div>
                <label for=" " class="mb-2 text-sm font-bold "> Message: </label>
                <app-editor [control]="emailbody" class="bg-white" required [fromComponent]="'message'"></app-editor>
              </div>


              <div class="flex justify-between mt-2">
                <button type="button" (click)="closeContactEmployee()"
                  class="text-black bg-white border-none btn hover:bg-gray-400 hover:text-white">Cancel</button>
                <button
                  class="p-2 text-white border-none rounded-md bg-customTeal hover:bg-gray-100 hover:text-customTeal"
                  type="submit">Send Message</button>
              </div>
            </div>

            <!-- Push Notfication body section -->
            <div *ngIf="(notificationType == 'push')">
              <div class="w-full px-2 mt-2 ">
                <div class="px-2 text-red-400 ">
                  <i class="float-left pr-2 material-icons blue-text">warning</i>
                  Some users may not be logged in to the system, which could prevent them from receiving notifications
                </div>

                <div>
                  <label for=" " class="mb-2 text-sm font-bold "> Title: </label>
                  <input type="email" name="subject" formControlName='subject'
                    class="block w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm form-control border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 sm:text-sm focus:ring-1"
                    placeholder="Subject" />
                  <br />
                </div>
                <label for=" " class="mb-2 text-sm font-bold "> Message: </label>

                <app-editor [control]="emailbody" class="bg-white" required [fromComponent]="'message'"></app-editor>
              </div>


              <div class="flex justify-between mt-2">
                <button type="button" (click)="closeContactEmployee()"
                  class="text-black bg-white border-none btn hover:bg-gray-400 hover:text-white">Cancel</button>
                <button
                  class="p-2 text-white border-none rounded-md bg-customTeal hover:bg-gray-100 hover:text-customTeal"
                  type="submit">Send Message</button>
              </div>
            </div>

          </form>

          <div class="py-2 mx-2 font-bold text-blue-600 underline underline-offset-2 text sm"
            [routerLink]="['/admin/app-setup']">Create Signature</div>
        </div>
      </div>
    </div>



    <!-------------Add Employee Modal------------->
    <div id="my-modal-employee" *ngIf="employeeInviteModalOpen" class="modal"
      [ngClass]="{'modal-open': employeeInviteModalOpen, 'modal-action': !employeeInviteModalOpen }">
      <div class="w-10/12 max-w-3xl modal-box">
        <app-close-modal (closeBtnClicked)=closeEmpModal()></app-close-modal>
        <div class="modal-content">
          <h3 class="mb-1 text-lg font-bold text-center">Invite Employee</h3>
          <hr />
          <p class="py-4 text-center">Add Employees to invite them to your app. An email will be sent to them.</p>
          <form [formGroup]="inviteAdminForm" (ngSubmit)="sendInvite(inviteAdminForm.value)">
            <div class="flex flex-col pt-12 mb-4 space-y-4 md:flex-col ">
              <div class="grid-cols-2 gap-6 px-4 space-y-4 md:px-0 md:space-y-0 md:grid">

                <div class="flex flex-col space-y-2 md:w-80">
                  <label for=" " class="text-xs font-bold text-gray-500 "> First Name </label>
                  <input maxlength="60" formControlName="firstName"
                    class="px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline w-80"
                    type="text" placeholder="John">
                </div>

                <div formArrayName="adminInviteEmails"
                  *ngFor="let inviteEmail of adminInviteEmails.controls; let i=index">
                  <p class="mb-2 text-xs font-bold text-gray-500">Email *</p>
                  <input type="email" name="email" id="email"
                    class="px-3 py-3 leading-tight text-gray-700 border rounded form-control focus:outline-gray-200 focus:shadow-outline w-80"
                    placeholder="Johnsmith@example.com" [formControlName]="i" required />
                  <div
                    *ngIf="(adminInviteEmails.controls[i].dirty || adminInviteEmails.controls[i].touched) && adminInviteEmails.controls[i].invalid">
                    <small *ngIf="adminInviteEmails.controls[i].errors.required" class="text-red-500"> The Email is
                      required. </small>
                    <small *ngIf="adminInviteEmails.controls[i].errors.email" class="text-red-500"> Wrong email format.
                    </small>
                  </div>
                </div>

                <div class="flex flex-col space-y-2 md:w-80 ">
                  <label for=" " class="text-xs font-bold text-gray-500 "> Last Name </label>
                  <input formControlName="lastName"
                    class="px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline w-80"
                    type="text" placeholder="Smith ">
                </div>

                <div class="flex flex-col space-y-2 md:w-80 ">
                  <label for=" " class="text-xs font-bold text-gray-500 "> Job Title *</label>
                  <input formControlName="jobTitle"
                    class="px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline w-80"
                    type="text" placeholder="Job title" required>
                  <div *ngIf="(jobTitle.dirty || jobTitle.touched) && jobTitle.invalid">
                    <small *ngIf="jobTitle.errors.required" class="text-red-500"> The Job Title is required. </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-between">
              <button (click)="closeEmpModal()" class="font-bold border-none text-customTeal">Cancel</button>
              <button class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal" type="submit"
                [disabled]="!inviteAdminForm.valid">Invite</button>
            </div>

          </form>
        </div>
      </div>
    </div>



  </div>



  <div class="flex justify-between items-center py-4">
    <!----------------------Contact Users and Filters(Phone Number)------------------------>
    <div class="flex w-full justify-between  py-2" *ngIf="!showInvitedEmployees">
      <div *ngIf="allUsersByRole" class="flex space-x-2 items-center">
        <input
          class="float-left w-4 h-4  align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-customTeal checked:border-customTeal focus:outline-none"
          type="checkbox" value="" id="flexCheckDefault" (click)="onMobileEmployeeFilter()"
          [checked]="isMobileCheckedEmp">
        <p class="text-base">Display all with Mobile Number</p>
      </div>
    </div>
    <!-- Add employee button -->
    <div class="w-full flex justify-end">

      <button (click)="openEmpInvite()"
        *ngIf="((isAdmin || (permMember && permMember.inviteEmp)) && !showInvitedEmployeesList)"
        class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white " height="1em"
          viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
        </svg>
        <span>Add Employee</span>
      </button>
    </div>
  </div>

  <!-------------------The header for the lists----------------->
  <div class="flex flex-col  bg-gray-200  " *ngIf="allUsersByRole || invitedEmployees">
    <div class="flex flex-row items-center p-2 " *ngIf="allUsersByRole.length > 0 || invitedEmployees.length > 0">
      <div class="w-1/3  ">
        <div class="form-check" *ngIf="!showInvitedEmployees">

          <input
            class="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
            type="checkbox" value="" id="flexCheckDefault" (click)="selectedAllEmployee()"
            [checked]=selectAllcheckedEmp>
        </div>
        <p class="text-sm " [ngClass]="{'pl-4':showInvitedEmployees, 'pl-12': !showInvitedEmployees }">Name</p>
      </div>
      <div class="hidden w-3/12 lg:block " [ngClass]="{'pl-6':showInvitedEmployees, 'pl-4': !showInvitedEmployees }">
        <p class="text-sm ">Email</p>
      </div>
      <!-- <div class="hidden w-3/12 lg:block " *ngIf="!showInvitedEmployees">
          <p class="text-sm ">Last Login</p>
        </div> -->
      <div class="w-3/12 lg:block " [ngClass]="{'pl-12':showInvitedEmployees }">
        <p class="text-sm ">Status</p>
      </div>

      <div class="w-3/12">
      </div>
    </div>
  </div>

  <!---------------------------- No Employee Registered or Invited--------------------------->
  <div *ngIf="!allUsersByRole && !showInvitedEmployees">
    <div class="flex items-center justify-center">
      <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
        class="flex items-center justify-center block w-64">
    </div>
    <div class="text-lg font-bold text-center text-customTeal">There are no Employees registered</div>
  </div>

  <div *ngIf="!invitedEmployees && showInvitedEmployees">
    <div class="flex items-center justify-center">
      <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
        class="flex items-center justify-center block w-64">
    </div>
    <div class="text-lg font-bold text-center text-customTeal">There are no Employees invited</div>
  </div>

  <!----------------------Display the Employee List------------------>
  <div *ngIf="!showInvitedEmployees">
    <ng-template *ngTemplateOutlet="EmpOrAdminList; context: {isAdmin: false, usersList: allUsersByRole}">
    </ng-template>

    <ng-template *ngTemplateOutlet="EmpOrAdminList; context: {isAdmin: true, usersList: admins}">
    </ng-template>
  </div>

  <!-- show invited employees list -->

  <div *ngIf="showInvitedEmployees">
    <ng-template *ngTemplateOutlet="InviteList; context: {isAdmin: false, invitedEmployees: invitedEmployees}">
    </ng-template>
  </div>

  <!-- toast -->
  <div class="alert alert-success shadow-lg fixed top-0 right-0 md:z-50"
    [ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success': toastType=='success', 'alert-error': toastType=='failure' }">
    <div class="text-white font-bold">
      <span class="material-icons">check_circle</span>
      <span>{{toastMessage}}</span>
    </div>
  </div>
  <!-- <app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert> -->

</div>







<ng-template #EmpOrAdminList let-isAdmin="isAdmin" let-usersList="usersList">
  <div *ngFor="let item of usersList ;
   let i=index" class="flex items-center justify-center w-full">
    <ng-container *ngIf="item.Email !='admin@goingmobile.app'">
      <span>
        <input
          class="float-left w-4 h-4 mt-2 ml-2 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
          type="checkbox" value="" id="flexCheckDefault" [checked]="checkEmployee" (click)="selectedEmployee(item)">
      </span>
      <div class="w-full">
        <div (click)="viewEmployeeProfile(item)"
          class="flex flex-row items-center w-full p-2   bg-white border-2 rounded-sm cursor-pointer md:space-x-6">
          <div class="flex w-7/12 lg:w-4/12 ">
            <div class="flex items-center space-x-3">
              <div class="form-check">

              </div>
              <div class="avatar placeholder">
                <div class="w-10 h-10 border border-solid border-gray-200 rounded-full mask mask-squircle"
                  *ngIf="item.profileImgLink">
                  <img loading="lazy" [src]="item.profileImgLink">
                </div>
                <div
                  class="w-10 h-10 border border-solid border-gray-200 rounded-full bg-neutral-focus text-neutral-content "
                  *ngIf="!item.profileImgLink">
                  <span class="text-xl">{{item.GivenName[0] | uppercase}} </span>
                </div>
              </div>
              <div>
                <div class="flex items-center space-x-2">
                  <p class="text-base font-bold">{{item.GivenName}} {{item.LastName}}</p>
                  <span *ngIf="isAdmin" class="gap-2 badge badge-success text-sm">
                    Admin
                  </span>
                </div>

              </div>
            </div>
          </div>
          <div class="hidden w-3/12 lg:block ">
            <p class="text-base text-gray-500 ">{{item.Email}}</p>
          </div>
          <div class="hidden w-3/12 lg:block ">

            <p class="text-base text-gray-500  " *ngIf="item.lastLogin">{{item.lastLogin.toDate() | date:'medium'}}</p>
          </div>

          <div class="w-2/12 lg:w-1/12">
            <label for="my-modal-enableE" *ngIf="item.status !== 'Enabled'" [disabled]="item.status !== 'Enabled'"
              (click)="selectEmployee(i)" class="pl-2 text-error modal-button"> <i
                class="w-5 h-5 material-icons success-text">person_off</i>
            </label>
            <ng-container *ngIf="item.contact">
              <label for="my-modal-suspendE" *ngIf="item.status === 'Enabled'" [disabled]="(item.status !== 'Enabled')"
                (click)="selectEmployee(i)"
                [ngClass]="'modal-button pl-2 ' + (item.contact.includes('SMS') && item.contact.includes('Email')) ? ' text-green-500' :  ( item.contact.includes('SMS') || item.contact.includes('Email') ? 'text-yellow-500 ': 'text-red-500')">
                <i
                  [ngClass]="!(item.contact.includes('SMS') && item.contact.includes('Email')) ? 'material-icons success-text w-5 h-5' : 'material-icons success-text w-5 h-5'">how_to_reg</i>
              </label>
            </ng-container>

          </div>

          <!--This is a confirmation to suspend a user-->
          <input type="checkbox" id="my-modal-suspendE" class="modal-toggle" />
          <div class="modal">
            <div class="modal-box">
              <h3 class="mb-2 text-lg font-bold text-center">Disable Account</h3>
              <hr />
              <p class="mt-3 text-center">Are you sure you want to Disable this Account?</p>
              <p class="py-2 text-center">An Employee with a disabled accounts won't be able to sign in.</p>

              <div class="items-center justify-between m-4 modal-action">
                <label for="my-modal-suspendE"
                  class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal">Cancel</label>
                <label for="my-model"><button
                    class="font-bold text-red-500 border-none hover:bg-red-500 hover:text-white hover:rounded-md hover:font-normal hover:p-2"
                    type="button" (click)="suspendEmployee(i)">Disable</button></label>
              </div>
            </div>
          </div>

          <!--This is a confirmation to Enable a user-->
          <input type="checkbox" id="my-modal-enableE" class="modal-toggle" />
          <div class="modal">
            <div class="modal-box">
              <h3 class="text-lg font-bold">Are You sure you want to Enable this Account?</h3>
              <p class="py-4">An Employee with enabled accounts will be able to sign in again.</p>

              <div class="items-center justify-between modal-action">
                <label for="my-modal-enableE"
                  class="text-black bg-white border-none btn hover:bg-gray-400 hover:text-white">Cancel</label>
                <label for="my-model"><button class="text-white border-none btn btn-success hover:bg-customTeal-400"
                    type="button" (click)="restoreEmployee(i)">Enable</button></label>
              </div>
            </div>
          </div>


          <div class="w-1/12 lg:1/12">
            <button (click)="viewEmployeeProfile(item)" type="button">
              <i class="float-left text-gray-400 material-icons material-symbols-outlined">arrow_circle_right </i>
            </button>
          </div>
        </div>
        <hr>
      </div>
    </ng-container>

  </div>
</ng-template>


<ng-template #InviteList let-isAdmin="isAdmin" let-usersList="invitedEmployees">
  <div *ngFor="let item of invitedEmployees; let i=index" class=" w-full">

    <div class="flex flex-row items-center w-full p-2   bg-white border-2 rounded-sm cursor-pointer md:space-x-6">
      <div class="flex w-7/12 lg:w-4/12">
        <div class="flex items-center space-x-3">
          <div class="form-check">

          </div>
          <div class="avatar placeholder">

            <div
              class="w-10 h-10 rounded-full bg-neutral-focus  border border-solid border-gray-200 text-neutral-content ">
              <span class="text-2xl">{{item.firstName[0] | uppercase}} </span>
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <p class="text-base font-bold">{{item.firstName}} {{item.lastName}}</p>
              <span *ngIf="isAdmin" class="gap-2 badge badge-success text-sm">
                Admin
              </span>
            </div>

          </div>
        </div>
      </div>
      <div class="hidden w-3/12 lg:block">
        <p class="text-base text-gray-500 ">{{item.inviteEmail}}</p>
      </div>

      <div class="w-3/12 lg:block">
        <p class="text-base  text-orange-600">Pending</p>
      </div>

      <div class="w-2/12 lg:block">
        <button class="text-base  text-white bg-customTeal px-3 py-2 md:px-6 md:py-2 "
          (click)="inviteAgain(item.inviteEmail)">Invite</button>
      </div>

    </div>
    <hr>
  </div>
</ng-template>