import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent implements OnInit {

  @Input() sharedFromComponent
  @Input() calledFromDashboard = ''

  @Output() onCancel = new EventEmitter();
  @Output() onPushNotification = new EventEmitter();

  user

  constructor() {

  }

  ngOnInit(): void {
      
  }


  cancelActionClicked() {
    this.onCancel.emit();
  }

  // send push notifcation
  sendPushNotfication(){
    this.onPushNotification.emit();
    
  }



}
