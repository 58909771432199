<div class=" pt-6 font-body max-h-max pb-6 p-4 md:px-0">
  <div class="flex ">
    <form [formGroup]="clientForm"  class="w-full">
      <div class="flex flex-col px-4 py-8 mx-auto bg-white rounded-md shadow-md md:px-10 md:w-2/3  ">
        <div class="mt-4 text-lg font-bold text-brandColor md:mt-0 flex space-x-2 py-2 space-y-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            class="w-6 h-6 stroke-customTeal ">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
          </svg>
          Create Client Account
        </div>
        <hr>


        <div class=" pt-4">
          <!-- Basic Inf0 -->
          <div>
            <div class="w-full grid-cols-2 gap-6 px-4 space-y-4 md:px-0 md:space-y-0 md:grid">
              <!-- First Name -->

              <div class="flex flex-col space-y-2">
                <label for=" " class="text-xs font-bold text-gray-500 "> First Name<span class="text-red-500">*</span>
                </label>
                <div class="relative flex flex-wrap items-stretch w-full mb-4">

                  <input [(ngModel)]="names" formControlName="GivenName" type="text" placeholder="Jane"
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    maxlength="60" aria-label="Firstname" aria-describedby="basic-addon1" />
                </div>

                <div *ngIf="(GivenName.dirty || GivenName.touched) && GivenName.invalid">
                  <small *ngIf="GivenName.errors.required" class="text-red-500"> The First Name is required.
                  </small>
                </div>
              </div>

              <!-- Last Name -->
              <div class="flex flex-col space-y-2 ">
                <label for=" " class="text-xs font-bold text-gray-500 "> Last Name <span class="text-red-500">*</span>
                </label>
                <div class="relative flex flex-wrap items-stretch w-full mb-4">

                  <input formControlName="LastName" type="text" placeholder="John"
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    maxlength="60" aria-label="Lastname" aria-describedby="basic-addon1" />
                </div>

                <div *ngIf="(LastName.dirty || LastName.touched) && LastName.invalid">
                  <small *ngIf="LastName.errors.required" class="text-red-500"> The Last Name is required.
                  </small>
                </div>
              </div>
              <!-- Phone Number -->
              <div class="flex flex-col space-y-2">
                <label for=" " class="text-xs font-bold text-gray-500 "> Phone Number
                </label>
                <div class="relative flex flex-wrap items-stretch w-full mb-4">
                  <span
                    class="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    </svg>
                  </span>
                  <input type="text" id='cellPhone' formControlName="cellPhone" placeholder="000-000-0000"
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    aria-label="Phonenumber" aria-describedby="basic-addon1" />
                </div>

                <small *ngIf="phoneExists" class="text-red-500"> The phone number already exists.
                </small>
                <small *ngIf="!phoneExists" class="text-red-500"></small>
              </div>
              <!-- Email  -->
              <div class="flex flex-col space-y-2 ">
                <label for=" " class="text-xs font-bold text-gray-500 "> Email
                </label>
                <div class="relative flex flex-wrap items-stretch w-full mb-4">
                  <span
                    class="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                  </span>
                  <input [readonly]=emailExists formControlName="Email" type="text" placeholder="johnjane@gmail.com"
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    aria-label="Email" aria-describedby="basic-addon1" />
                </div>
                <div *ngIf="(Email.dirty || Email.touched) && Email.invalid">
                  <small *ngIf="Email.errors.required" class="text-red-500"> The Email is required.
                  </small>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="baseFields.length > 0 ">
            <!-- additional fields  -->
            <div class="flex flex-col space-y-4 pt-6">
              <div class="mt-4 text-lg font-bold text-brandColor md:mt-0 flex items-center space-x-2 ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  class="w-6 h-6 stroke-customTeal ">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>

                <span class="mt-4 text-lg font-bold text-brandColor md:mt-0 flex space-x-2">Additional Info</span>
              </div>
              <hr>
              <div class="w-full grid-cols-2 gap-6 px-4 space-y-4 md:px-0 md:space-y-0 md:grid">
                <div class="flex flex-col space-y-2 items-center justify-center" *ngFor="let field of baseFields">
                  <div class="flex w-full"
                    [ngClass]="{'flex-row-reverse justify-end items-center gap-1' : field.type === 'checkbox', 'flex-col space-y-2' : field.type != 'checkbox'}">
                    <label for=" " class="text-xs font-bold text-gray-500 "> {{field?.name}}<span class="text-red-500"
                        *ngIf="field?.required">*</span> </label>
                    <div class="flex " [ngClass]="{'flex-wrap items-stretch w-full mb-4' : field.type !== 'checkbox'}">
                      <!-- dropdown -->
                      <select *ngIf="field.type === 'dropdown'"
                        class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]"
                        [formControlName]="field?.key" [placeholder]="field?.name">
                        <option *ngFor="let option of field.options" [value]="option">{{ option?.option }}
                        </option>
                      </select>


                      <!-- text area -->
                      <textarea *ngIf="field.type == 'textarea'"
                        class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]"
                        [formControlName]="field?.key" [placeholder]="field?.name"></textarea>

                      <input type="checkbox" *ngIf="field.type == 'checkbox'" [formControlName]="field?.key"
                        id="acceptTerms" class="form-check-input" />

                      <input *ngIf=" field.type !='dropdown' && field.type !='textarea' && field.type !='checkbox'"
                        class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]"
                        [formControlName]="field?.key" [type]="field?.type" [placeholder]="field?.name">
                    </div>
                  </div>
                  <div *ngIf="(clientForm.controls[field?.key].touched)" class="w-full">
                    <small *ngIf="clientForm.controls[field?.key].errors?.required" class="text-red-500 w-full "> The
                      {{field?.name}} is required.
                    </small>
                    <small *ngIf="clientForm.controls[field?.key].errors?.minlength || clientForm.controls[field?.key].errors?.min" class="text-red-500 w-full"> {{
                      getMinLengthErrorMessage(field) }}
                    </small>
                    <small *ngIf="clientForm.controls[field?.key].errors?.maxlength || clientForm.controls[field?.key].errors?.max" class="text-red-500 w-full"> {{
                      getMaxLengthErrorMessage(field) }}
                    </small>
                    <small *ngIf="(clientForm.controls[field?.key].errors?.email)" class="text-red-500 w-full">
                      Please add valid email!
                    </small>
                    <small *ngIf="(clientForm.controls[field?.key].errors?.invalidPhoneNumber)"
                      class="text-red-500 w-full">
                      Please add valid phone number!
                    </small>
                    <small *ngIf="clientForm.controls[field?.key].errors?.minDate" class="text-red-500 w-full"> The date
                      must
                      be after {{ clientForm.controls[field?.key].errors?.minDate?.value }}.
                    </small>
                    <small *ngIf="clientForm.controls[field?.key].errors?.maxDate" class="text-red-500 w-full "> The
                      date must
                      be before {{ clientForm.controls[field?.key].errors?.maxDate?.value }}.
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>


        </div>

        <div class="row card white col mt-2 s12">

          <div class="card-action">
            <div class="flex justify-between">
              <div *ngIf="role == 'Admin'" class="flex justify-end w-full space-x-2">
                <button type="button" (click)="openConfirmation()"
                  class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- && !exists && !phoneExists && (role == 'Admin') && !invitationPopup -->
<div id="my-modal-confirmationPopup" *ngIf="confirmationPopup && clientForm.valid" class="modal"
  [ngClass]="{'modal-open': confirmationPopup, 'modal-action': !confirmationPopup }">
  <div class="modal-box">

    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Create Client</h3>
      <hr />
      <p class="mt-3 text-center">Are you sure you want to create {{names}}'s profile?</p>

      <div class="items-center justify-between m-4 modal-action">
        <label (click)="cancelConfirmation()" [disabled]="!clientForm.valid"
          class="px-4 py-2 text-black border-2 rounded-md border-customTeal ">Cancel</label>
        <button [disabled]="!clientForm.valid" (click)="onSubmit()"
          class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal ">Yes</button>
      </div>
    </div>
  </div>
</div>