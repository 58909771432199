<div class="bg-gray-100 min-h-screen max-h-max" [ngClass]="{'px-4 md:px-12': !currentUser && !calledFromEmptyDashboard, 'px-4 md:pl-12': currentUser && !calledFromEmptyDashboard, 'px-4': calledFromEmptyDashboard}">
  <div class="" *ngIf="!calledFromEmptyDashboard" >
    <div *ngIf="isAdmin"> 
      <div class="flex md:justify-start justify-center flex-wrap gap-4 ">
        <button  [routerLink]="['/admin/create-section-element',sectionId]" class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white " height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
          <span>Add New</span>  
    </button>

    <button [routerLink]="['/admin/edit-custom-section',sectionId]"  
      class="flex items-center md:ml-5 text-customTeal space-x-2 rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-customTeal">
        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
      </svg>
      <span>Edit Section</span>  
    </button>

    <button 
    type="button"
    class="flex space-x-2 items-center bg-white rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
    (click)="openMoveModal=true"
    *ngIf="isAdmin" >
    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
    <span>Arrange Items</span>  
    
  </button>   
      </div>
     
    </div>

    <div *ngIf="isEmployee && permCustomPage" class="flex">
      <button *ngIf="permCustomPage.createPage" [routerLink]="['/employee/create-section-element', sectionId]" class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white " height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
            <span>Add New</span>  
      </button>
      <button *ngIf="permCustomPage.editPage" [routerLink]="['/employee/edit-custom-section',sectionId]"  
      class="flex items-center md:ml-5 text-customTeal space-x-2 rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-customTeal">
        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
      </svg>
        <span>Edit Section</span>  
      </button>

    </div>
  </div> 
  <br/>
  <!-- <div class="font font-bold text-xl  text-gray-500" *ngIf="sectionName">
    {{sectionName}}
  </div> -->
  <div *ngIf="posts">
    <div *ngIf="posts.length == 0">
      <ng-container *ngTemplateOutlet="emptyPage;">
      </ng-container>
    </div>
    <div class="">
      <div  class="flex flex-wrap gap-x-4  w-full  md:gap-y-8 gap-y-4 px-4 md:gap-x-6 md:justify-start justify-center  mx-auto mt-5 ">
        <div *ngFor="let post of getSortedList(posts)"  class="w-full h-max   md:mx-4  md:mb-10 flex  md:min-h-max  border bg-white md:w-[341px] md:h-min drop-shadow-md rounded-lg hover:scale-100 hover:ease-in hover:duration-300"
          (click)="navigate(post)" [ngStyle]="{'background-color': post.backgroundColor}">
          <div class="flex flex-col w-full   px-4  py-4  md:px-6 space-y-2  md:h-min border border-gray-200    rounded-lg hover:scale-100 hover:ease-in hover:duration-300 ">
            <div *ngIf="post.imgLink && !post.videoLink" id='i' class=" w-full rounded-md "  >
              <div id='i' class=" w-full h-full rounded-lg blur-none" style="object-fit: cover ">
                <img [src]="post.imgLink" class=" h-full  w-full  rounded-lg" style="object-fit: cover " >
              </div>

            </div>
            <div *ngIf="post.imgLink && post.videoLink">
              <video controls playsinline preload="none" class=" w-full rounded-md  object-fill" [poster]="post.imgLink">
                <source [src]="post.videoLink | safeUrl"  >
                Your browser does not support HTML video.
              </video>
            </div>
            <div *ngIf="!post.imgLink && post.videoLink">
              <video controls preload="none" class=" w-full   rounded-md object-fill" poster="https://api.goingmobile.app/media/personal-app-assets/img/webp/empty-video.webp">
                <source [src]="post.videoLink | safeUrl"  >
                Your browser does not support HTML video.
              </video>
            </div>
           
            <div class="break-words">
              <h6 class=" cursor-pointer max-w-sm text-xl font-bold py-2 text-center" [ngStyle]="{'color':post.titleColor}">{{truncateHTML(getInnerText(post.title),60)}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!posts">
    <ng-container *ngTemplateOutlet="emptyPage;">
    </ng-container>
  </div>
</div>

<!-- Empty pages -->
<ng-template #emptyPage>
  <div class="mt-5">
    <div class="flex items-center justify-center">
      <img loading="lazy" data-src="../../../../assets/icons/Illustrations/product-empty-Illustration-10.png"
      class="block w-64 items-center justify-center"> 
    </div>
    <br/>
    <ng-container *ngIf="!isAdmin">
      <div class="text-customTeal text-lg text-center font-bold">No {{sectionName}} found.</div>
      <div class="text-gray-400 text-sm text-center font-bold">{{sectionName}}  will appeare here once added!</div>
    </ng-container>
    <ng-container *ngIf="isAdmin">
      <div class="text-customTeal text-lg text-center font-bold">No {{sectionName}} found.</div>
      <div class="text-gray-400 text-sm text-center font-bold">Why not let the world know!</div>
    </ng-container>
  </div>
</ng-template>


<!-- Rename Modal -->
<div class="modal" class="modal" id="renameModal"   *ngIf="section"
[ngClass]="{'modal-open': renameModalOpen, 'modal-action': !renameModalOpen }">
  <div class="modal-box">

    <input type="text" id="sectionRename"  [(ngModel)]="section.name" (click)="sectionInputChange(section, $event)"
            class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
            name="Page name" placeholder="Page name" />
    <div class="modal-action">
        <button type="button" for="my-modal" class="text-black font-bold rounded-md py-1 px-6 w-max border-solid border bg-white border-gray-400" (click)="renameModalOpen=false">
            Cancel
          </button>
      <button type="button" class="px-6 py-1  text-white rounded-md  bg-customTeal font-bold" (click)="renamePage()" >Save</button>
    </div>
  </div>
</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" 
[toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<app-move-elements *ngIf="openMoveModal"  [elementsList]="posts" sectionType="CustomSectionElements" (closeClicked)="openMoveModal=false"></app-move-elements>
