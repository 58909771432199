import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-custom-sections-list',
  templateUrl: './custom-sections-list.component.html',
  styleUrls: ['./custom-sections-list.component.css']
})
export class CustomSectionsListComponent implements OnInit {

  constructor(
    private customSectionService: CustomSectionService,
    private helperService: HelperService,
    private router: Router,
    private authService: AuthService) { }
  @Input() calledFromDashboardCreate
  @Input() calledFrom;
  @Output() customSection = new EventEmitter();

  allSections: any;
  isAdmin = false;
  currentUser: any;
  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser && this.currentUser.role == 'Admin'){
      this.isAdmin = true;
    }
    this.customSectionService.getCustomSections().valueChanges().subscribe(sec => {
      if(sec.length > 0){
        this.allSections = sec;
      }
    })
  }

  getInnerText(html){
    return this.helperService.getInnerText(html);
  }
  navigateToSection(sectionId){
    if(this.isAdmin){
      this.router.navigate(['admin/custom-section-list',sectionId])
    }
  }
  onSelectSection(value){
    this.customSection.emit(value)
  }
  checkVisibility(visibility: string = 'Public'): boolean {
    const currentRole = this.currentUser.role || 'Public';

    const roleHierarchy = {
        'Public': 1,
        'Employee': 2,
        'User': 3
    };

    return roleHierarchy[currentRole] >= roleHierarchy[visibility];
  }
}
