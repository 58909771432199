import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsComponent } from '../../admin/settings/app-settings/app-settings.component';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { UserService } from 'src/app/services/firebase/user.service';
import { EmailService } from 'src/app/services/api/email.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { CountersService } from 'src/app/services/firebase/counters.service';
import { HelperService } from 'src/app/services/helper/helper';
import { environment } from 'src/environments/environment';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';

@Component({
  selector: 'app-message-send',
  templateUrl: './message-send.component.html',
  styleUrls: ['./message-send.component.css']
})
export class MessageSendComponent implements OnInit {
  @Input() userLogged;
  // @Input() contactMsg;
  // @Input () deletedFromCategory;
  @Output() cancelSendClicked = new EventEmitter();
  @Output() sendClicked: EventEmitter<any> = new EventEmitter<any>();

  contactForm: UntypedFormGroup;
  contactMsg: String = ' ';

  //currentUser
  currentUser: any;
  role: any;
  isAdmin : boolean; //remove
  setUp: any;
  appLogo:any;
  storeEmail: any;

  adminSMSCounter: number = 0;
  userSMSCounter: number = 0;
  userCounter: any;
  adminCounter = {
    SMSTotalCounter: 0,
    emailTotalCounter: 0,
    socialMediaCount: 0,
    chatGptCounter: 0,
  };
  adminEmailCounter: number = 0;
  userEmailCounter: number = 0;;

  counterGlobal: any;

  currentDate = new Date();
  isLimitSMS: boolean = false;
  isLimitEmail: boolean = false;

  domain = environment.firstNation.name;
  // domainName = "https://" + this.domain + ".goingmobile.app"
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  
  greetings: any;
  signOff: any;
  email: any;
  messageForSMS: any;
  availablePhone: any;
  
  //socialMedias
  facebook:any;
  instagram:any;
  linkedin: any;

  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private emailService: EmailService,
              private sMSService: SMSService,
              private counter: CountersService,
              private helperService: HelperService,
              private cdr: ChangeDetectorRef,
              private socialMedia : SocialMediaService,
              private appSettingsService: AppSettingsService) { }

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();

        // Get the current month
        const currentMonth = this.currentDate.getMonth();

        // Move to the next day
        const nextDay = new Date(this.currentDate);
        nextDay.setDate(this.currentDate.getDate() + 1);
    
        // Check if the next day is in a different month
        const isNewMonth = nextDay.getMonth() !== currentMonth;
    

    this.contactForm = this.fb.group({
      contact: [this.contactMsg],
      subject: [''],
      emailbody: [''],
      messageSMS: ['']
    });

    this.counter.getCounter().valueChanges().subscribe((adminCounter: any) => {
      if (adminCounter && adminCounter.length != 0) {
        this.adminCounter = adminCounter[0];
        this.adminSMSCounter = this.adminCounter.SMSTotalCounter ? this.adminCounter.SMSTotalCounter : 0;
        this.adminEmailCounter = this.adminCounter.emailTotalCounter ? this.adminCounter.emailTotalCounter : 0;

        if (isNewMonth) {
          this.adminSMSCounter = 0;
          this.adminEmailCounter = 0;

        } else {
          if (this.adminSMSCounter <= 10000) {
            this.adminSMSCounter = this.adminCounter.SMSTotalCounter;
          }
          if (this.adminEmailCounter <= 10000) {
            this.adminEmailCounter = this.adminCounter.emailTotalCounter ? this.adminCounter.emailTotalCounter : 0;
          }
          if (this.adminSMSCounter > 10000) {
            this.isLimitSMS = true;
          }
          if (this.adminEmailCounter > 10000) {
            this.isLimitEmail = true;
          }
        }


      } else {
        this.adminSMSCounter = 0;
        // const counter = {
        //   id: '',
        //   SMSTotalCounter: 0,
        //   emailTotalCounter: 0,
        //   socialMediaCount: 0,
        //   chatGptCounter: 0,
        // }
        this.counter.saveCounter(this.adminCounter);
      }
    });

    this.socialMedia.getSocialMedia().valueChanges().subscribe((links : any)=>{
      if(links && links.length){
        this.facebook = links[0].facebook;
        this.instagram  = links[0].instagram;
        this.linkedin = links[0].linkedin;

      }
    })

    if (this.currentUser) {
      this.role = this.currentUser.role;

      if (this.role == 'Admin') {
        // this.isAdmin = true;

        this.appSettingsService.getAppSettingsList().valueChanges().subscribe(setup => {
          this.setUp = setup[0]
          this.appLogo = this.setUp.logo;
          this.greetings = this.setUp.greetings ? this.setUp.greetings : '';
          this.signOff = this.setUp.signOff ? this.setUp.signOff : '';
          this.email = this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff;
          this.messageForSMS =  this.setUp.messageSMS ? '\n \n ' + this.setUp.messageSMS : '';
          this.availablePhone = this.setUp.availablePhone ? this.setUp.availablePhone : '';
          this.contactForm.patchValue({
            emailbody: this.email,
            messageSMS: this.messageForSMS
          });
          
        });

      } else if (this.role == 'Employee') {

        // this.userService.getUserByEmail(this.currentUser.Email).valueChanges().subscribe(employee => {
        //   this.employee = employee[0];
        // })

      }
    }


    this.userService.getUserByEmail(this.userLogged.Email).valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.userLogged = x[0];
        // this.GivenName = this.userLogged.GivenName
        // this.LastName = this.userLogged.LastName;
        // this.cellPhone = this.userLogged.cellPhone;
        // this.Email = this.userLogged.Email;
        // this.profileImgLink = this.userLogged.profileImgLink;
        // this.lastLogin = this.userLogged.lastLogin;
        // this.status = this.userLogged.status;
        // this.sentSMSCount = this.userLogged.SMSCount;
      }
    })

  }

  cancelSendBtnClicked(){
    this.cancelSendClicked.emit();
  }

  sendBtnClicked(response){
    this.cancelSendClicked.emit();

    this.sendClicked.emit(response);
  }

  SMSselected() {
    this.contactMsg = 'SMS'
  }
  emailSelected() {
    this.contactMsg = 'Email'
  }
  directMessageSelected() {
    this.contactMsg = 'directMessage'
  }

  reset(models) {
    this.contactForm.patchValue({
      subject: '',
      emailbody: this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff,
      messageSMS: this.setUp.messageSMS,
    });

  }

  sendClient(models) {

    if ((this.contactMsg == 'Email' && this.userLogged.contact.includes('Email')) && this.userLogged.role == 'User') {
      // const emailModel = new EmailModel();
      const emailModel = new CustomAdminEmailModel()
      emailModel.subject = models.subject;
      emailModel.isNationApp = this.isNationApp;
      emailModel.body = this.userLogged.GivenName + ", <br/>"
      emailModel.body += models.emailbody;
      emailModel.body += '<p>';
      if (this.appLogo) {
        emailModel.body += `<img src="${this.appLogo}"
        width="100" height="100" style=" display: block; alt="Logo">`;
      }
      emailModel.body += '</p>';
      // emailModel.body += `<b>Facebook :</b> ${this.facebook}<br><b>Instagram :</b> ${this.instagram}<br/> <b>LinkedIn :</b> ${this.linkedin}`


      if (environment.firstNation.name !== "nationalchief") {
        emailModel.body += `<p> To stop receiving SMS please login to <a href="${this.domainName}">${this.domainName}</a></p>`
        emailModel.body += `<p> Go to your preferences and disable Email, SMS or Both.</p>`
      }
    
      emailModel.emailList = ["samri@mynation.app", "kirubelmewa3@gmail.com", "lidyamulugetamewa@gmail.com", "samrawitmewa88@gmail.com", "mkproject.app@gmail.com"],
    // "subject": "hi samrawitthis is for test",
    // "body": "hello there",
      emailModel.host = "smtp.gmail.com",
      emailModel.adminEmail = "mkproject.app@gmail.com",
      emailModel.appPassword = "ytklyidomvqfmtky",
      emailModel.sendFrom = "mkproject.app@gmail.com",

      this.storeEmail = [];
      this.storeEmail.push(this.userLogged.Email);
      // emailModel.emailList = this.storeEmail;
      // this.cancelSendBtnClicked();
      this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
        if (res) {
          this.adminEmailCounter++;
          this.adminCounter.emailTotalCounter = this.adminEmailCounter;
          this.counter.updateCounter(this.adminCounter);

          // this.counter.getCounter().valueChanges().subscribe((count)=>{
          //   if(count && count.length !=0){
          //     this.counter.updateCounter(this.adminCounter);

          //   }else{
          //   }
          // })

          this.sendBtnClicked({ html: 'Email sent sucessfully ', classes: 'green', type: 'success' });
          this.cdr.detectChanges();
          this.reset(models);
        
        }
        else {
        }
      })
    }
    else if ((this.contactMsg == 'Email' && !this.userLogged.contact.includes('Email')) && this.userLogged.role == 'User') {

      this.sendBtnClicked({ html: 'The Subscribers doesnt allow the Email Preference ', classes: 'red', type: 'failure' });
      // this.cancelSendBtnClicked();

      // this.toast({ html: 'The Subscribers doesnt allow the Email Preference ', classes: 'red', type: 'failure' });

    } else if ((this.contactMsg == 'SMS' && this.userLogged.contact.includes('SMS')) && this.userLogged.role == 'User') {
      
      const smsModel = new SMSModel();
      if(this.setUp.general === 'buyNum' && !this.setUp.changeGreetings.firstName && !this.setUp.changeGreetings.lastName){
        smsModel.body = this.setUp.greetingMessage + ", \n";
      }else if(this.setUp.general === 'buyNum' && this.setUp.changeGreetings.firstName && !this.setUp.changeGreetings.lastName){
        smsModel.body = this.setUp.greetingMessage + " " + this.userLogged.GivenName +", \n";
      }else if(this.setUp.general === 'buyNum' && !this.setUp.changeGreetings.firstName && this.setUp.changeGreetings.lastName){
        smsModel.body = this.setUp.greetingMessage +  " " + this.userLogged.LastName +", \n";
      }else if(this.setUp.general === 'buyNum' && this.setUp.changeGreetings.firstName && this.setUp.changeGreetings.lastName){
        smsModel.body = this.setUp.greetingMessage +  " " + this.userLogged.GivenName + " " + this.userLogged.LastName +", \n";
      }else{
        smsModel.body = this.userLogged.GivenName + ", \n";
      }
      smsModel.body += this.helperService.truncateHTML(models.messageSMS, "1000") + '\n';

      if(this.setUp.links && this.setUp.general === 'buyNum' && this.setUp.linkSocial){
        smsModel.body += this.setUp.links + "\n";
      }
      // if(this.facebook){
      //   smsModel.body += `Facebook  : ${this.facebook}\n`
      // }if(this.instagram){
      //   smsModel.body += `Instagram  : ${this.instagram}\n`
      // }if(this.linkedin){
      //   smsModel.body += `LinkedIn  : ${this.linkedin}\n`
      // }

      // smsModel.body += `Facebook : ${this.facebook}\nInstagram : ${this.instagram}\nLinkedIn : ${this.linkedin}`

      if (environment.firstNation.name !== "nationalchief" && this.setUp.general === 'existingNumber') {
        smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
        smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
      }else if(this.setUp.general === 'buyNum' && this.setUp.customizeUnsubscribe){
        smsModel.body += this.setUp.unSubscribe;
      }
      smsModel.from = this.availablePhone;
      smsModel.phoneNumberList.push(this.userLogged.cellPhone);

      this.sMSService.sendSMS(smsModel).subscribe((res) => {
        if (res) { 
           this.sendBtnClicked({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
              
              this.cdr.detectChanges();
          this.adminSMSCounter++;
          this.adminCounter.SMSTotalCounter = this.adminSMSCounter;
          this.counter.updateCounter(this.adminCounter);

          this.reset(models);
          this.sendBtnClicked({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
          
        }
        else {
          this.sendBtnClicked({ html: 'Error occured sending SMS', classes: 'red', type: 'failure' });
        }
      });

    } else if ((this.contactMsg == 'SMS' && !this.userLogged.contact.includes('SMS')) && this.userLogged.role == 'User') {
      this.sendBtnClicked({ html: 'The Subscribers doesnt allow the SMS Preference ', classes: 'red', type: 'failure' });
     

    }else if(this.userLogged.role='Employee' && this.contactMsg == 'Email'){
      const emailModel = new CustomAdminEmailModel();
      emailModel.subject = models.subject;
      emailModel.body = this.userLogged.GivenName + ", <br />"
      emailModel.body += models.emailbody;
      emailModel.isNationApp = this.isNationApp;

      emailModel.body += '<p>';
      if (this.appLogo) {
        emailModel.body += `<img src="${this.appLogo}"
        width="100" height="100" style=" display: block; alt="Logo">`;
      }
      emailModel.body += '</p>';

      if (environment.firstNation.name !== "nationalchief") {
      emailModel.body += `<p> To stop receiving SMS please login to <a href="${this.domainName}">${this.domainName}</a></p>`
      emailModel.body += `<p> Go to your preferences and disable Email, SMS or Both.</p>`
      }

      this.storeEmail = [];
      this.storeEmail.push(this.userLogged.Email);
      emailModel.emailList = this.storeEmail;
      // this.cancelSendBtnClicked();
      // custom send email used
      emailModel.host = "smtp.gmail.com",
      emailModel.adminEmail = "mkproject.app@gmail.com",
      emailModel.appPassword = "ytklyidomvqfmtky",

      this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
        if (res) {
           this.sendBtnClicked({ html: 'Email sent sucessfully ', classes: 'green', type: 'success' });          
          this.adminEmailCounter++;
          if(this.adminCounter){
          this.adminCounter.emailTotalCounter = this.adminEmailCounter;
        }

          this.counter.updateCounter(this.adminCounter);
          this.reset(models);

       
        }
        });
    }
    else if(this.userLogged.role='Employee' && this.contactMsg == 'SMS'){
      const smsModel = new SMSModel();


      if(this.setUp.general === 'buyNum' && !this.setUp.changeGreetings.firstName && !this.setUp.changeGreetings.lastName){
        smsModel.body = this.setUp.greetingMessage + ", \n";
      }else if(this.setUp.general === 'buyNum' && this.setUp.changeGreetings.firstName && !this.setUp.changeGreetings.lastName){
        smsModel.body = this.setUp.greetingMessage + " " + this.userLogged.GivenName +", \n";
      }else if(this.setUp.general === 'buyNum' && !this.setUp.changeGreetings.firstName && this.setUp.changeGreetings.lastName){
        smsModel.body = this.setUp.greetingMessage +  " " + this.userLogged.LastName +", \n";
      }else if(this.setUp.general === 'buyNum' && this.setUp.changeGreetings.firstName && this.setUp.changeGreetings.lastName){
        smsModel.body = this.setUp.greetingMessage +  " " + this.userLogged.GivenName + " " + this.userLogged.LastName +", \n";
      }else{
        smsModel.body = this.userLogged.GivenName + ", \n";
      }

      /*if(this.setUp.general === 'buyNum'){
        smsModel.body = this.setUp.greetingMessage + ", \n";
      }else{
        smsModel.body = this.userLogged.GivenName + ", \n";
      }*/
      smsModel.body += this.helperService.truncateHTML(models.messageSMS, "1000") + '\n';
      if(this.setUp.links && this.setUp.general === 'buyNum' && this.setUp.linkSocial){
        smsModel.body += this.setUp.links + "\n";
      }
    
      if (environment.firstNation.name !== "nationalchief" && this.setUp.general === 'existingNumber') {
        smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
        smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
      }else if(this.setUp.general === 'buyNum' && this.setUp.customizeUnsubscribe){
        smsModel.body += this.setUp.unSubscribe;
      }

      smsModel.from = this.availablePhone;
      smsModel.phoneNumberList.push(this.userLogged.cellPhone);
      this.sMSService.sendSMS(smsModel).subscribe((res) => {
        if (res) {
          this.sendBtnClicked({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
          this.cdr.detectChanges();

          this.adminSMSCounter++;
          this.adminCounter.SMSTotalCounter = this.adminSMSCounter;
          this.counter.updateCounter(this.adminCounter);

          this.reset(models);
          
        }
        else {
          this.sendBtnClicked({ html: 'Error occured sending SMS', classes: 'red', type: 'failure' });
          
        }
      });
    }

  }
  
  get emailbody() { return this.contactForm.get('emailbody'); }
  get subject() { return this.contactForm.get('subject'); }
  get messageSMS() { return this.contactForm.get('messageSMS'); }
  get contact() { return this.contactForm.get('contact'); }
}
