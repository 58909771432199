<div class="flex gap-4 md:flex-row flex-col" *ngIf="type !='primary'">
  <div class="flex flex-col bg-white p-4 shadow-md px-4 gap-y-4  w-full md:max-w-sm py-4  rounded-md">
        
    <div class="flex flex-col gap-y-2">
      <p class="font-bold">Home Page on {{pageView}}</p>
      <p class="text-sm text-gray-500">Switch between list view and card view to customize how content is displayed. Use the top navigation to choose your preferred view and adjust the details shown to fit your needs, whether on web or mobile.</p>
    </div>
    <div class="space-x-2  flex ">
      <button
      type="button"
      class="flex items-center rounded border-2 border-customteal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customteal transition duration-150 ease-in-out hover:border-customteal-600 hover:bg-opacity-10 hover:text-customteal-600 focus:border-customteal-600 focus:text-customteal-600 focus:outline-none focus:ring-0 active:border-customteal-700 active:text-customteal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
      [ngClass]="{'bg-customTeal text-white': (landingPageView[pageView] == 'list')}"
      (click)="changeView(pageView, 'list');" style="margin-bottom: 5px;">
      <i class="material-icons mr-3  float-left">list</i>List View </button>   
      <button
      type="button"
      class="flex items-center rounded border-2 border-customteal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customteal transition duration-150 ease-in-out hover:border-customteal-600 hover:bg-opacity-10 hover:text-customteal-600 focus:border-customteal-600 focus:text-customteal-600 focus:outline-none focus:ring-0 active:border-customteal-700 active:text-customteal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
      [ngClass]="{'bg-customTeal text-white ': (landingPageView[pageView] == 'card')}"
      (click)="changeView(pageView, 'card');" style="margin-bottom: 5px;">
      <i class="material-icons mr-3 float-left" >view_module</i>Card View </button>
    </div>
  </div>
                    <!-- preview -->
  <div class="bg-white p-4 shadow-md space-y-4 w-full md:w-full">
                      <!-- Mobile view vs Web view tab -->
                 <div class="flex font-bold  mx-auto justify-star w-full " >
                        <div class="text-sm text-center"><button type="button"  (click)="changePageView('web')" class="px-2 py-2 font-bold border-2 md:px-10 rounded-md border-gray-200 flex items-center space-x-2" [ngClass]="{'text-white bg-customTeal fill-white' : pageView == 'web', 'text-customTeal bg-white fill-customTeal': pageView !='web'}">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                          </svg>
                          <span class="text-base">Web View</span>
                        </button></div>
                        <div class=" text-sm text-center"><button type="button" (click)="changePageView('mobile')"  class="px-2 py-2 font-bold border-2  rounded-md md:px-10 border-gray-200 flex items-center space-x-2  " [ngClass]="{'text-white bg-customTeal fill-white': pageView == 'mobile', 'text-customTeal bg-white fill-customTeal': pageView !='mobile'}">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                          </svg>
                          <span class="text-base">Phone View</span>
                        </button></div>
                     
                   </div>
                   <hr>
                <div  class=" space-y-4" >
                  <p class="font-bold capitalize">{{pageView}} Preview </p>
              
                  <div *ngIf="pageView=='web'">
                    
                    <div class="mockup-window border bg-gray-300" >
                      <div class=" flex  justify-center px-4 overflow-x-hidden max-h-screen scrollbar bg-gray-200">
                        <app-landing-page class="w-full" [preview]="true" [view]='pageView' [landingPageSettings]="true"></app-landing-page>
                      </div>
                    </div>
              
                  </div>
              
                  <div *ngIf="pageView=='mobile'">
              
                    <div class=" max-w-sm max-h-screen overflow-x-hidden scrollbar shadow-md rounded-sm border-2 border-solid  border-gray-200">
                      <app-landing-page [preview]="true" view='mobile'></app-landing-page>
                    </div>
                  </div>
              </div>
        
              
            
  </div>
</div>


<div *ngIf="type=='primary'">
  <div class="flex flex-col bg-white justify-start w-full p-4">
    <p class="text-sm" [ngClass]="{'text-yellow-600':landingPageView[pageView] != calledFrom, 'text-green-600':landingPageView[pageView] == calledFrom }">Your page is currently on {{landingPageView[pageView]}} view </p>
    <p class="text-sm" *ngIf="landingPageView[pageView] != calledFrom">Please consider changing it to {{calledFrom == 'card'? 'card': 'list'}} View to view the changes you make here</p>
    <a *ngIf="landingPageView[pageView] != calledFrom" class="underline text-customTeal cursor-pointer text-sm" (click)="changeView(pageView, calledFrom );" >Change to {{calledFrom == 'card'? 'card': 'list'}} View</a>
  </div>

</div>
<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" 
[toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
