import { Component, OnInit } from '@angular/core';
import { log } from 'console';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { UserService } from 'src/app/services/firebase/user.service';

@Component({
  selector: 'app-manage-subscriptions',
  templateUrl: './manage-subscriptions.component.html',
  styleUrls: ['./manage-subscriptions.component.css']
})
export class ManageSubscriptionsComponent implements OnInit {

  currentUser: any;
  constructor(private authService: AuthService,
    private checkout: CheckoutService,
    private memberService: FireMembersService,
    private userService : UserService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();

  }

  // CreateSubscription() {

  //   this.userService.getUserById(this.authService.currentUser.uid).valueChanges().subscribe(x => {
  //     if(x){
  //       var myuser = x[0]
  //       var customerID = ""
  //       if(myuser['stripeCustomerID']) {
  //         customerID = myuser['stripeCustomerID']
  //       }
        

  //       this.checkout.CreateSubscriptionPlan("price_1O024MBv0LG3RwaxiVJgfSc0",customerID).subscribe(sub => {
  //         if(sub){          
  //             const model = {
  //               uid : this.authService.userID,
  //               hasActiveStripeSession : true,
  //               stripeSessionID : sub.id
  //             };
              
  //             this.memberService.updateAccount(model).then(x => {            
  //              window.location.href = sub.url;
  //             })
              
  //         }
  //       })
  //     }
  //   })

 

  // }

  ManageSubscription(){
    this.userService.getUserById(this.authService.currentUser.uid).valueChanges().subscribe(x => {
      if(x){
        var myuser = x[0]
        this.checkout.SubscriptionPortal(myuser['stripeCustomerID']).subscribe(portal => {
          if(portal){
            window.location.href = portal.url;          
          }
        })
      }
    })
    
  }
}
