<div id="signupModal" class="modal modal-open">
    <div class="modal-box max-w-2xl">
      <div class="flex flex-row-reverse">
        <a (click)="closeSignupModal()"
        class="float-right text-black cursor-pointer">
        <i class="material-icons">close</i>
      </a>

      </div>
      <div class="flex flex-col justify-center">
        <span class="text-center font-bold text-2xl" *ngIf="section.title"  [innerHTML]="section.title | noSanitize"></span>
        <span class="text-center" *ngIf="section.description" class="py-4"  [innerHTML]="section.description | noSanitize"></span>
        <img loading="lazy" *ngIf="section.imgUrl" class="" [src]="section.imgUrl" />
      </div>

      <div class="modal-action flex justify-center">
        <button
        type="button"
        class="btn-custom-solid"
        (click)="navigateToRegisterPageClicked()"
        >
          {{section.callToAction}}
        </button>
        <button *ngIf="nationOfuse=='Demo' || nationOfuse=='FNLeaders' || nationOfuse=='Build'"
        type="button"
        class="btn-custom-bordered" (click)="navigateToBuildPageClicked()">
          Build Your platform
        </button>
      </div>
    </div>
  </div>