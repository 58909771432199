<div *ngIf="!preview" class="w-full px-5 py-3 md:px-14">

  <div class="border-b-4 border-customTeal py-5">
    <div class="flex  text-left  space-x-2 items-center">
      <div class="w-full flex flex-row justify-between">
        <div class="font-bold text-lg">
          <span>Edit </span>
        </div>
        <div>
          <button type="submit" (click)="previewChanges(editEventForm.value)" [disabled]="!editEventForm.valid"
            [ngClass]="{'bg-[#cccccc] text-[#666666]': !editEventForm.valid}" class="btn-custom-solid">
            <i class="material-icons mr-3 text-white float-left">preview</i>
            Preview Changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col lg:flex-row gap-5 overflow-x-hidden w-full py-3" *ngIf="event">
    <div class="w-full mx-auto">
      <form [formGroup]=" editEventForm" (ngSubmit)="updateEvent( editEventForm.value)">
        <!-- if the date is passed -->
        <div *ngIf="eventStartDate.invalid" class="p-2 mb-3 border-r-2 border-2 border-red-500 text-red-500 bg-white">
          The date is passed change the date to continue editing.
        </div>

        <div *ngIf="step==1" class="flex flex-col gap-y-2">
          <!-- Title -->
          <div class="form-control w-full bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm rounded-md">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 55 55" fill="blue" class="text-gray-400"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black" />
                <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black" />
                <path
                  d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z"
                  fill="black" />
                <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black" />
              </svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">Title <span class="text-red-500 text-sm">*</span></p>
                <p class="text-xs text-gray-500">This is the first part of your post that your readers will see and the
                  part that's responsible for getting them to click through and read the rest.</p>
              </div>
            </div>
            <app-editor [control]="eventTitle" class="bg-white" [inputType]="'title'"
              [fromComponent]=eventsName></app-editor>
            <div *ngIf="(eventTitle.dirty || eventTitle.touched) && eventTitle.invalid">
              <small *ngIf="eventTitle.errors.required" class="text-red-500 italic">Title is required. </small>
            </div>
          </div>

          <!-- Description -->
          <div class="form-control w-full   bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm rounded-md">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4 37H16.3357V24.5531H4V37ZM7.08574 27.6667H13.25V33.8865H7.08574V27.6667ZM19.4143 35.4469H41V32.3333H19.4143V35.4469ZM4 21.4469H16.3357V9H4V21.4469ZM7.08574 12.1135H13.25V18.3333H7.08574V12.1135V12.1135ZM19.4143 10.5531V13.6667H41V10.5531H19.4143V10.5531ZM19.4143 29.2198H41V26.1062H19.4143V29.2198ZM19.4143 19.8865H41V16.7729H19.4143V19.8865Z"
                  fill="black" />
              </svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">Description <span class="text-red-500 text-sm">*</span></p>
                <p class="text-xs text-gray-500">Discription/Content of your post in the form of text.</p>
              </div>
            </div>
            <app-editor [control]="description" class="bg-gray-100" [fromComponent]=eventsName></app-editor>
            <div *ngIf="(description.dirty || description.touched) && description.invalid">
              <small *ngIf="description.errors.required" class="text-red-500 italic"> The Description is required.
              </small>
            </div>

          </div>

          <!-- Location -->
          <div class="form-control w-full   bg-white md:px-10 px-2  py-6 shadow-sm space-y-4">
            <div class="flex space-x-4 items-center">
              <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" />
              </svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">Event Place <span class="text-red-500 text-sm">*</span></p>
                <p class="text-xs text-gray-500">The specific venue and city, or virtual platform, where the event will
                  take place.</p>
              </div>
            </div>
            <div class="space-y-4 flex flex-col  ml-3">
              <div class="w-4/12 flex space-x-10">
                <label class="flex items-center">
                  <input class="radio rounded-sm" formControlName="isOnline"  type="checkbox" 
                  value="Online" (click)='onEventLocationChange($event)'/>
                  <span class="ml-3 font-bold">Online</span>
                </label>
                
              </div>

              <div class="w-full md:w-7/12">
                <input id="eventPlace" type="text" required formControlName='eventPlace' placeholder="Event Place"
                  class="input input-bordered w-full" [ngClass]="{
                  'border-red-500': (eventPlace.dirty || eventPlace.touched) && eventPlace.invalid,
                  'border-gray-200': !((eventPlace.dirty || eventPlace.touched) && eventPlace.invalid)
                }" autocomplete="off" />
                <div *ngIf="(eventPlace.dirty || eventPlace.touched) && eventPlace.invalid">
                  <small *ngIf="eventPlace.errors.required" class="text-red-500 italic">The Event location is required.
                  </small>              
                </div>

                
              <!-- <div class="mt-2" *ngIf="isOnline.value === 'Online'">
                  <input id="eventLink" type="text" required formControlName='eventLink' placeholder="Enter the Link where the event is happening"
                class="input input-bordered w-full" [ngClass]="{
                'border-red-500': (eventLink.dirty || eventLink.touched) && eventLink.invalid,
                'border-gray-200': !((eventLink.dirty || eventLink.touched) && eventLink.invalid)
              }" autocomplete="off" />
                </div> -->


              </div>

              
              <!--donot use this <app-locate-map *ngIf="isOnline.value === 'notOnline'" (long)="receiveLongitude($event)" (lati)="receiveLatitude($event)" (location)="receiveAddress($event)"></app-locate-map> -->
              <!-- <app-locate-map *ngIf="isOnline.value === 'notOnline'" [component] = "'edit'" [viewLat]="event.latitude" [viewLong]="event.longitude" (location)="receiveAddress($event)"></app-locate-map> -->
            </div>
          </div>

          <!-- Date and time -->
          <div class="w-full   bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm">
            <div class="flex space-x-4 items-center">
              <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
              </svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">Date and Time </p>
                <p class="text-xs text-gray-500">Enter the start date, end date</p>
              </div>
            </div>

            <div>
              <div class="flex ">
                <div class="flex flex-col md:w-1/2">
                  <div class="w-full px-3 md:px-0 md:mb-0">
                    <label class="label">
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Start Date
                        <span class="text-red-500">*</span>
                      </span>
                    </label>
                  </div>

                  <div class="w-full  px-3 md:mb-0">
                    <div class=" bg-gray-100 p-1 rounded-lg">
                      <input id="eventStartDate" formControlName="eventStartDate" type="date" class="input w-full "
                        [ngClass]="{
                        'border-red-500': (eventStartDate.dirty || eventStartDate.touched) && eventStartDate.invalid,
                        'border-gray-200': !((eventStartDate.dirty || eventStartDate.touched) && eventStartDate.invalid)
                      }" (change)="dateChangeEventStartDate()">
                    </div>
                  </div>
                </div>

                <div class="flex flex-col w-1/2">
                  <div class="w-full  px-3 md:px-0 md:mb-0">
                    <label class="label">
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Start Time
                        <span class="text-red-500">*</span>
                      </span>
                    </label>
                  </div>

                  <div class="w-full  px-3 md:mb-0">
                    <div class="flex w-full">
                      <div class=" bg-gray-100  rounded-lg w-full">
                        <div class="dropdown  w-full">
                          <label tabindex="0"
                            class="btn  w-full rounded-sm text-gray-700  my-1 mr-1 flex justify-between hover:bg-gray-100">
                            <input class="input focus:border-none w-11/12" maxlength="8"
                              formControlName="eventStartTime" />
                            <i class="material-icons">arrow_drop_down</i>
                          </label>
                          <ul tabindex="0" onclick="document.activeElement.blur()"
                            class="dropdown-content menu p-2 shadow  bg-white text-gray-700 rounded-box w-full max-h-52  overflow-y-scroll">
                            <ng-container *ngFor="let item of times">
                              <li><a onclick="document.activeElement.blur()" (click)="setStartTime(item)">{{item}}</a>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!-- Errors -->
              <div>
                <div *ngIf="(eventStartDate.dirty || eventStartDate.touched) && eventStartDate.invalid">
                  <small *ngIf="eventStartDate.errors.required" class="text-red-500 italic">Event start date is
                    required.
                  </small>
                  <small *ngIf="eventStartDate.errors.invalidStartDate" class="text-red-500 italic">Event start date is
                    invalid.
                  </small>
                </div>
                <div *ngIf="(eventStartTime.dirty || eventStartTime.touched) && eventStartTime.invalid">
                  <small *ngIf="eventStartTime.hasError('pattern')" class="text-red-500">
                    Invalid time format</small>
                </div>
              </div>
              <br />

              <div class="flex flex-row">
                <div class="flex flex-col md:w-1/2 ">
                  <div class="w-full px-3 md:px-0 md:mb-0">
                    <label class="label">
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">End Date
                      </span>
                    </label>
                  </div>
                  <div class="w-full  px-3 md:mb-0">
                    <div class=" bg-gray-100 p-1 rounded-lg">
                      <input id="eventEndDate" formControlName="eventEndDate" type="date"
                        class="input w-full leading-tight rounded-sm bg-gray-100 text-gray-700" [ngClass]="{
                  'border-red-500': (eventEndDate.dirty || eventEndDate.touched) && eventEndDate.invalid,
                  'border-gray-200': !((eventEndDate.dirty || eventEndDate.touched) && eventEndDate.invalid)
                }">
                    </div>
                  </div>
                </div>

                <div class="flex flex-col  md:w-1/2 ">

                  <div class="w-full px-3 md:px-0 md:mb-0">
                    <label class="label">
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">End Time
                      </span>
                    </label>
                  </div>

                  <div class="w-full px-3 md:mb-0">
                    <div class="flex w-full">
                      <div class=" bg-gray-100  rounded-lg w-full">
                        <div class="dropdown  w-full">
                          <label tabindex="0"
                            class="btn  w-full rounded-sm bg-gray-100 text-gray-700  my-1 mr-1 flex justify-between hover:bg-gray-100">
                            <input class="input focus:border-none bg-gray-100 w-11/12" maxlength="8"
                              formControlName="eventEndTime" />
                            <i class="material-icons">arrow_drop_down</i>
                          </label>
                          <ul tabindex="0" onclick="document.activeElement.blur()"
                            class="dropdown-content menu p-2 shadow  bg-white text-gray-700 rounded-box w-full max-h-52  overflow-y-scroll">
                            <ng-container *ngFor="let item of times">
                              <li><a onclick="document.activeElement.blur()" (click)="setEndTime(item)">{{item}}</a>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- Errors -->
              <div>
                <div *ngIf="(eventEndDate.dirty || eventEndDate.touched) && eventEndDate.invalid">
                  <small *ngIf="eventEndDate.hasError('invalid')" class="text-red-500">End date must be after/the same
                    as
                    start date! </small>
                </div>
              </div>
              <div *ngIf="(eventEndTime.dirty || eventEndTime.touched) && eventEndTime.invalid">
                <small *ngIf="eventEndTime.hasError('pattern')" class="text-red-500">
                  Invalid time format</small>
              </div>
            </div>


          </div>
        </div>

        <div *ngIf="step==2" class="flex flex-col gap-y-2">
          <!-- File Upload -->
          <div class="form-control w-full   bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
            <div class="flex space-x-4 items-center">
              <svg width="32" height="32" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.125 31.875H31.875C32.25 31.875 32.5313 31.7031 32.7188 31.3594C32.9063 31.0156 32.875 30.6875 32.625 30.375L27.4688 23.4844C27.2812 23.2344 27.0312 23.1094 26.7188 23.1094C26.4062 23.1094 26.1562 23.2344 25.9688 23.4844L21.0938 30L17.625 25.3594C17.4375 25.1094 17.1875 24.9844 16.875 24.9844C16.5625 24.9844 16.3125 25.1094 16.125 25.3594L12.375 30.375C12.125 30.6875 12.0938 31.0156 12.2813 31.3594C12.4688 31.7031 12.75 31.875 13.125 31.875ZM9.375 39.375C8.34375 39.375 7.46062 39.0081 6.72562 38.2744C5.99187 37.5394 5.625 36.6562 5.625 35.625V9.375C5.625 8.34375 5.99187 7.46062 6.72562 6.72562C7.46062 5.99187 8.34375 5.625 9.375 5.625H35.625C36.6562 5.625 37.5394 5.99187 38.2744 6.72562C39.0081 7.46062 39.375 8.34375 39.375 9.375V35.625C39.375 36.6562 39.0081 37.5394 38.2744 38.2744C37.5394 39.0081 36.6562 39.375 35.625 39.375H9.375ZM9.375 35.625H35.625V9.375H9.375V35.625ZM9.375 9.375V35.625V9.375Z"
                  fill="black" />
              </svg>
              <div class="w-10/12">
                <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Upload Files</span>
                <p class="text-xs text-gray-500">This video is what the users will see first.</p>

              </div>
            </div>
            <div class="flex flex-col md:flex-row my-24">
              <!-- Image -->
              <div class="w-full mt-5 md:w-1/3">
                <button type="button" *ngIf="!sMobile">
                  <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 7704.74 6791.12">
                      <circle cx="2373.6" cy="2638.32" r="695.57" transform="translate(-1413.99 1788.05) rotate(-45)"
                        style="fill:#231f20" />
                      <path
                        d="M7671.44,940c-178.58-178.61-416-276.93-668.57-276.93H1189.17c-252.56,0-490,98.32-668.61,276.93s-276.93,416-276.93,668.57V6508.7c0,252.56,98.36,490,276.93,668.57s416.05,276.93,668.61,276.93h5813.7c252.56,0,490-98.36,668.57-276.93s276.93-416,276.93-668.57V1608.58C7948.37,1356,7850,1118.59,7671.44,940ZM7386.2,6508.7c0,9.58-.34,19.13-1.06,28.56q-582.86-730.58-1077.85-1430.1C5530.21,4014.71,5164.73,3357.57,4738.42,3418.77c-198.19,28.45-370.5,253-715.09,702.14-455.58,593.83-525,924.41-737.08,924-300.22-.57-401.22-663.59-746.59-678.79-230.42-10.15-415.18,274.9-509.58,419.25-325.6,497.92-721.5,1073.86-1219.91,1781.25a381.07,381.07,0,0,1-4.37-57.88V1608.58c0-211.4,172-383.33,383.37-383.33h5813.7c211.36,0,383.33,171.93,383.33,383.33Z"
                        transform="translate(-243.63 -663.08)" style="fill:#231f20" />
                    </svg>
                    <input type="file" type="file" accept="image/*" (change)="fileChangeEvent($event.target.files)">
                    <span class="block font-bold pt-1 text-center">Image</span>
                  </label>
                </button>
                <div *ngIf="isMobile">
                  <app-image-uploader class="justify-start items-start" (onImageUpload)=onImageUpload($event)
                    [customConfig]="{'upload':false}"></app-image-uploader>
                </div>
                <!-- Image upload Preview  -->
                <div class="pt-5 md:pt-0 md:pl-2">
                  <div class="flex flex-wrap gap-8">
                    <div class="relative">
                      <ng-container *ngIf="base64Image">
                        <img loading="lazy" [src]="base64Image" class="object-cover  w-36 h-24 rounded-md cover"
                          alt="Image">
                        <button (click)="removeImage()" type="button"
                          class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                          <i class="fa fa-trash text-md"></i>
                        </button>
                      </ng-container>

                      <ng-container *ngIf="event && event.imgLink!= '' && !base64Image">
                        <img loading="lazy" [src]="event.mediaLink" class="object-cover  w-24 h-24 rounded-md cover"
                          alt="Event Image">
                        <button (click)="removeImage()" type="button"
                          class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                          <i class="fa fa-trash text-md"></i>
                        </button>
                      </ng-container>
                    </div>

                  </div>
                </div>
              </div>

              <!-- Video -->
              <div class="w-full mt-5 md:w-1/3">
                <button type="button">
                  <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 7540.61 4795.88">
                      <rect width="4703.35" height="4795.88" rx="348.07" style="fill:#231f20" />
                      <path
                        d="M7092.71,5797.22,5670.1,4995.05V3122.23l1422.61-802.17c358.38-202.07,773.6-152.74,773.6,131.07v3215C7866.31,5950,7451.09,5999.3,7092.71,5797.22Z"
                        transform="translate(-325.69 -1660.7)" style="fill:#231f20" />
                    </svg>
                    <input type="file" accept="video/*" (change)="handleVideoInput($event.target.files)" />
                    <span class="block font-bold pt-1 text-center">Video</span>
                  </label>
                </button>

                <!-- Video upload Preview  -->
                <div class="pt-5 md:pt-0 md:pl-2 w-2/3">
                  <ng-container *ngIf="attachedVideo">
                    <video controls [src]="attachedVideo"
                      class="object-cover w-full mt-3 md:mt-0 w-45  rounded-md cover" alt="Video">
                    </video>
                    <button (click)="removeVideo()" type="button"
                      class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="event.videoLink && !attachedVideo">
                    <video controls [src]="event.videoLink"
                      class="object-cover w-full mt-3 md:mt-0 w-45  rounded-md cover" alt="Video">
                    </video>
                    <button (click)="removeVideo()" type="button"
                      class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </ng-container>
                </div>

              </div>

            </div>
          </div>
          <!-- Errors -->
          <div>
            <div *ngIf="(eventEndDate.dirty || eventEndDate.touched) && eventEndDate.invalid">
              <small *ngIf="eventEndDate.hasError('invalid')" class="text-red-500">End date must be after/the same as
                start date! </small>
            </div>
          </div>
          <div *ngIf="(eventEndTime.dirty || eventEndTime.touched) && eventEndTime.invalid">
            <small *ngIf="eventEndTime.hasError('pattern')" class="text-red-500">
              Invalid time format</small>
          </div>
          <!-- pricing -->
          <div class="form-control  bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm">
            <div class="flex space-x-4 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
              </svg>

              <div class="w-10/12">
                <p class="font-bold text-lg">Pricing <span class="text-red-500 text-sm">*</span></p>
                <p class="text-xs text-gray-500">Let users know what the cost is in order to attend, pricing could be
                  paid, free, or hidden. </p>
              </div>
            </div>

            <div class="flex gap-x-5 px-3">
              <button type="button" (click)="pricingSet('paid')"
                class="text-black font-bold rounded-lg py-2 px-4 md:py-2 md:px-10 w-max border-solid border bg-white border-gray-400"
                [ngClass]="{'bg-customTeal text-white': (pricing =='paid')}">
                Paid
              </button>
              <button type="button" (click)="pricingSet('free')"
                class="text-black font-bold rounded-lg px-4 md:py-2 md:px-10 w-max border-solid border bg-white border-gray-400"
                [ngClass]="{'bg-customTeal text-white': (pricing =='free')}">
                Free
              </button>
              <button type="button" (click)="pricingSet('dontInclude')"
                class="text-black font-bold rounded-lg px-4 md:py-2 md:px-10 w-max border-solid border bg-white border-gray-400"
                [ngClass]="{'bg-customTeal text-white': (pricing =='dontInclude')}">
                Don't Include
              </button>
            </div>

            <div *ngIf="pricing =='paid'" class="form-control w-full ">
              <label class="label">
                <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Enterance fee</span>
              </label>
              <input id="price" type="text" [placeholder]="'Price (IN '+currency+')'"
                class="input input-bordered rounded-sm bg-gray-100 w-full md:w-7/12 text-gray-700 leading-tight"
                [ngClass]="{
                'border-red-500': (price.dirty || price.touched) && price.invalid,
                'border-gray-200': !((price.dirty || price.touched) && price.invalid)
              }" autocomplete="off" formControlName="price" />
              <div *ngIf="(price.dirty || price.touched) && price.invalid">
                <small class="text-red-500 italic">Price could only be a positive number.</small>
              </div>
            </div>
          </div>


          <!-- Registration Required -->
          <div class="form-control w-full  bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm">

            <div class="flex flex-col my-6  w-full md:flex-row">
              <div class="flex items-center w-full md:w-1/2">
                <div>
                  <div class="text-md font-bold">Registration Required?<span *ngIf="text-red-500">*</span></div>
                  <p class="hidden md:block text-gray-400 text-sm">Enter the maximum number to register for this event
                    if registration is required.</p>
                </div>
              </div>

              <div class="flex justify-center w-full md:w-1/2">
                <!-- toggle -->
                <div>
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <span class="font-bold label-text ">YES</span>
                      <input type="checkbox" class="toggle checked:bg-customTeal" checked
                        formControlName="registerRequired" 
                        (change)="toggleRegistration()"/>

                    </label>
                  </div>
                </div>
              </div>

            </div>


            <div class="form-control" *ngIf="registerRequired.value">
              <div>Enter the maximum Number of Registration</div>
              <label class="cursor-pointer label">
                <input type="number" class="input input-bordered" checked formControlName="maxRegistration" [min]="1"/>
              </label>
            </div>

          </div>

          <!-- Allow Comment -->
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 32.2838H37C37.51 32.2838 37.9372 32.1135 38.2816 31.773C38.6272 31.4336 38.8 31.0127 38.8 30.5101C38.8 30.0076 38.6272 29.5861 38.2816 29.2455C37.9372 28.9062 37.51 28.7365 37 28.7365H19C18.49 28.7365 18.0622 28.9062 17.7166 29.2455C17.3722 29.5861 17.2 30.0076 17.2 30.5101C17.2 31.0127 17.3722 31.4336 17.7166 31.773C18.0622 32.1135 18.49 32.2838 19 32.2838ZM19 26.9628H37C37.51 26.9628 37.9372 26.7926 38.2816 26.452C38.6272 26.1127 38.8 25.6917 38.8 25.1892C38.8 24.6866 38.6272 24.2651 38.2816 23.9246C37.9372 23.5852 37.51 23.4155 37 23.4155H19C18.49 23.4155 18.0622 23.5852 17.7166 23.9246C17.3722 24.2651 17.2 24.6866 17.2 25.1892C17.2 25.6917 17.3722 26.1127 17.7166 26.452C18.0622 26.7926 18.49 26.9628 19 26.9628ZM19 21.6419H37C37.51 21.6419 37.9372 21.4722 38.2816 21.1328C38.6272 20.7923 38.8 20.3708 38.8 19.8682C38.8 19.3657 38.6272 18.9442 38.2816 18.6036C37.9372 18.2643 37.51 18.0946 37 18.0946H19C18.49 18.0946 18.0622 18.2643 17.7166 18.6036C17.3722 18.9442 17.2 19.3657 17.2 19.8682C17.2 20.3708 17.3722 20.7923 17.7166 21.1328C18.0622 21.4722 18.49 21.6419 19 21.6419ZM42.94 43.4578L38.8 39.3784H13.6C12.61 39.3784 11.7628 39.0313 11.0584 38.3372C10.3528 37.642 10 36.8066 10 35.8311V14.5473C10 13.5718 10.3528 12.7364 11.0584 12.0411C11.7628 11.347 12.61 11 13.6 11H42.4C43.39 11 44.2378 11.347 44.9434 12.0411C45.6478 12.7364 46 13.5718 46 14.5473V42.1719C46 42.97 45.6328 43.524 44.8984 43.8338C44.1628 44.1447 43.51 44.0194 42.94 43.4578ZM13.6 14.5473V35.8311H40.285L42.4 37.9151V14.5473H13.6ZM13.6 14.5473V37.9151V14.5473Z" fill="black"/>
                </svg>
                <div class="w-10/12">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Allow Comments?</span>
                  <p class="text-xs text-gray-500">Would you want your users to be able to comment?</p>
                </div>
            </div>

            <div class="flex gap-x-10 w-1/2 ">
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="commentable" [value]=true
                    class="radio checked:bg-customTeal" />
                  <span class="label-text ml-4 font-normal text-black">Yes</span>

                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="commentable" [value]=false
                    class="radio checked:bg-customTeal" />
                  <span class="label-text ml-4 font-normal text-black">No</span>

                </label>
              </div>
            </div>
          </div>
          <!-- Additional Media -->
          <div class="form-control w-full   bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm">

            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.125 31.875H31.875C32.25 31.875 32.5313 31.7031 32.7188 31.3594C32.9063 31.0156 32.875 30.6875 32.625 30.375L27.4688 23.4844C27.2812 23.2344 27.0312 23.1094 26.7188 23.1094C26.4062 23.1094 26.1562 23.2344 25.9688 23.4844L21.0938 30L17.625 25.3594C17.4375 25.1094 17.1875 24.9844 16.875 24.9844C16.5625 24.9844 16.3125 25.1094 16.125 25.3594L12.375 30.375C12.125 30.6875 12.0938 31.0156 12.2813 31.3594C12.4688 31.7031 12.75 31.875 13.125 31.875ZM9.375 39.375C8.34375 39.375 7.46062 39.0081 6.72562 38.2744C5.99187 37.5394 5.625 36.6562 5.625 35.625V9.375C5.625 8.34375 5.99187 7.46062 6.72562 6.72562C7.46062 5.99187 8.34375 5.625 9.375 5.625H35.625C36.6562 5.625 37.5394 5.99187 38.2744 6.72562C39.0081 7.46062 39.375 8.34375 39.375 9.375V35.625C39.375 36.6562 39.0081 37.5394 38.2744 38.2744C37.5394 39.0081 36.6562 39.375 35.625 39.375H9.375ZM9.375 35.625H35.625V9.375H9.375V35.625ZM9.375 9.375V35.625V9.375Z"
                  fill="black" />
              </svg>
              <div class="w-10/12">
                <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Additional Media</span>
                <p class="text-xs text-gray-500">Attach additional information regarding your post.</p>
              </div>
            </div>

            <!-- upload video -->
            <!-- <div  class="form-control flex space-x-2 w-full md:w-1/3 px-4  py-2 bg-gray-100" (click)="uploadVideo()">
              <label class="custom-file-upload space-x-2 flex ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 16C10.2833 16 10.521 15.904 10.713 15.712C10.9043 15.5207 11 15.2833 11 15V13H13C13.2833 13 13.521 12.904 13.713 12.712C13.9043 12.5207 14 12.2833 14 12C14 11.7167 13.9043 11.479 13.713 11.287C13.521 11.0957 13.2833 11 13 11H11V9C11 8.71667 10.9043 8.479 10.713 8.287C10.521 8.09567 10.2833 8 10 8C9.71667 8 9.47933 8.09567 9.288 8.287C9.096 8.479 9 8.71667 9 9V11H7C6.71667 11 6.479 11.0957 6.287 11.287C6.09567 11.479 6 11.7167 6 12C6 12.2833 6.09567 12.5207 6.287 12.712C6.479 12.904 6.71667 13 7 13H9V15C9 15.2833 9.096 15.5207 9.288 15.712C9.47933 15.904 9.71667 16 10 16ZM4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H16C16.55 4 17.021 4.196 17.413 4.588C17.8043 4.97933 18 5.45 18 6V10.5L21.15 7.35C21.3 7.2 21.4793 7.16233 21.688 7.237C21.896 7.31233 22 7.46667 22 7.7V16.3C22 16.5333 21.896 16.6873 21.688 16.762C21.4793 16.8373 21.3 16.8 21.15 16.65L18 13.5V18C18 18.55 17.8043 19.021 17.413 19.413C17.021 19.8043 16.55 20 16 20H4ZM4 18H16V6H4V18ZM4 18V6V18Z" fill="black"/>
                      </svg>
                  <input type="file" accept="video/*"
                      (change)="handleVideoInput($event.target.files)" />
                  <span class="block font-bold pt-1">Upload Video</span>
              </label>
            </div> -->

            <!-- video preview -->
            <!-- <ng-container *ngIf="attachedVideo">
              <video class="w-2/5"  controls>
                <source [src]="attachedVideo | safeUrl" type="video/mp4"  (click)="getVideoType(attachedVideo)">
                Your browser does not support HTML video.
              </video>
              <button (click)="removeFile()"
                class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                <i class="fa fa-trash text-md"></i>
              </button>
            </ng-container> -->

            <!-- pdf upload -->
            <!-- <div>
              <div  class="form-control flex space-x-2 w-full md:w-1/3 px-4  py-2 bg-gray-100" (click)="uploadPdf()">          
                <label class="custom-file-upload flex space-x-2 ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 10.5H11C11.2833 10.5 11.521 10.404 11.713 10.212C11.9043 10.0207 12 9.78333 12 9.5V8.5C12 8.21667 11.9043 7.979 11.713 7.787C11.521 7.59567 11.2833 7.5 11 7.5H9.5C9.36667 7.5 9.25 7.55 9.15 7.65C9.05 7.75 9 7.86667 9 8V12C9 12.1333 9.05 12.25 9.15 12.35C9.25 12.45 9.36667 12.5 9.5 12.5C9.63333 12.5 9.75 12.45 9.85 12.35C9.95 12.25 10 12.1333 10 12V10.5ZM10 9.5V8.5H11V9.5H10ZM15 12.5C15.2833 12.5 15.521 12.404 15.713 12.212C15.9043 12.0207 16 11.7833 16 11.5V8.5C16 8.21667 15.9043 7.979 15.713 7.787C15.521 7.59567 15.2833 7.5 15 7.5H13.5C13.3667 7.5 13.25 7.55 13.15 7.65C13.05 7.75 13 7.86667 13 8V12C13 12.1333 13.05 12.25 13.15 12.35C13.25 12.45 13.3667 12.5 13.5 12.5H15ZM14 11.5V8.5H15V11.5H14ZM18 10.5H18.5C18.6333 10.5 18.75 10.45 18.85 10.35C18.95 10.25 19 10.1333 19 10C19 9.86667 18.95 9.75 18.85 9.65C18.75 9.55 18.6333 9.5 18.5 9.5H18V8.5H18.5C18.6333 8.5 18.75 8.45 18.85 8.35C18.95 8.25 19 8.13333 19 8C19 7.86667 18.95 7.75 18.85 7.65C18.75 7.55 18.6333 7.5 18.5 7.5H17.5C17.3667 7.5 17.25 7.55 17.15 7.65C17.05 7.75 17 7.86667 17 8V12C17 12.1333 17.05 12.25 17.15 12.35C17.25 12.45 17.3667 12.5 17.5 12.5C17.6333 12.5 17.75 12.45 17.85 12.35C17.95 12.25 18 12.1333 18 12V10.5ZM8 18C7.45 18 6.97933 17.8043 6.588 17.413C6.196 17.021 6 16.55 6 16V4C6 3.45 6.196 2.979 6.588 2.587C6.97933 2.19567 7.45 2 8 2H20C20.55 2 21.021 2.19567 21.413 2.587C21.8043 2.979 22 3.45 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.021 17.8043 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97933 21.8043 2.588 21.413C2.196 21.021 2 20.55 2 20V7C2 6.71667 2.096 6.479 2.288 6.287C2.47933 6.09567 2.71667 6 3 6C3.28333 6 3.521 6.09567 3.713 6.287C3.90433 6.479 4 6.71667 4 7V20H17C17.2833 20 17.5207 20.096 17.712 20.288C17.904 20.4793 18 20.7167 18 21C18 21.2833 17.904 21.5207 17.712 21.712C17.5207 21.904 17.2833 22 17 22H4ZM8 4V16V4Z" fill="black"/>
                    </svg>  
                  <input type="file" type="file" (change)="handlePDFInput($event.target.files)"  accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"/>
                  <span class="block font-bold pt-1">Upload PDF File</span>
                </label>
              </div>
              <p class="text-red-500 italic text-xs" *ngIf="pdfErrorMsg"> <strong><small>{{pdfErrorMsg}}</small></strong>
              </p>
            </div> -->

            <!-- pdf preview -->
            <!-- <ng-container *ngIf="pdfUpload">
              <div class="flex space-x-4">
                <div class="gap-x-3 px-4 pt-4" >
                  <i class="material-icons float-left text-4xl">feed</i><span class="font-bold break-all">{{pdfUpload.name}}</span>
                </div>
                <button (click)="removeFile()"
                  class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                  <i class="fa fa-trash text-md"></i>
                </button>
              </div>
            </ng-container> -->

            <!-- Event Link -->
            <div>
              <button class="flex space-x-2 w-full md:w-1/3 px-4  py-2 bg-gray-100" type="button"
                (click)="additionalLink()">
                <div class=" flex space-x-2 ">
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
                  </svg>
                  <span class="block font-bold pt-1">Event Link or URL</span>
                </div>
              </button>
              <div *ngIf="eventLinkClicked" class="space-y-2 pt-4">
                <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">
                  Event Link or URL</span>
                <div class="input-field">
                  <input id="sharedLink" type="text" formControlName='sharedLink'
                    class="input input-bordered rounded-sm bg-gray-100 w-full md:w-7/12 text-gray-700 leading-tight"
                    [ngClass]="{
                      'border-red-500': (sharedLink.dirty || sharedLink.touched) && sharedLink.invalid,
                      'border-gray-200': !((sharedLink.dirty || sharedLink.touched) && sharedLink.invalid)
                    }" autocomplete="off" />
                  <div *ngIf="(sharedLink.dirty || sharedLink.touched) && sharedLink.invalid">
                    <small class="text-red-500 italic">Please enter a valid URL. </small>
                  </div>
                </div>
              </div>
            </div>

            <!-- Youtube URL -->
            <div>
              <button class="flex space-x-2 w-full md:w-1/3 px-4  py-2 bg-gray-100" type="button"
                (click)="addYoutubeLink()">
                <div class=" flex space-x-2 ">
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
                  </svg>
                  <span class="block font-bold pt-1">Youtube URL</span>
                </div>
              </button>
              <div *ngIf="youtubeLinkClicked" class="space-y-2 pt-4">
                <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">
                  Youtube URL</span>
                <div class="input-field">
                  <input id="youtubeUrl" type="text" formControlName='youtubeUrl'
                    class="input input-bordered rounded-sm bg-gray-100 w-full md:w-7/12 text-gray-700 leading-tight"
                    [ngClass]="{
                      'border-red-500': (youtubeUrl.dirty || youtubeUrl.touched) && youtubeUrl.invalid,
                      'border-gray-200': !((youtubeUrl.dirty || youtubeUrl.touched) && youtubeUrl.invalid)
                    }" autocomplete="off" />
                  <div *ngIf="(youtubeUrl.dirty || youtubeUrl.touched) && youtubeUrl.invalid">
                    <small class="text-red-500 italic">Please enter a valid URL. </small>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <!-- Buttons -->
        <div class="mt-20">
          <div>
            <app-form-button  *ngIf="step==2 && editEventForm.valid"  buttonStyle="preview" buttonType="button" (click)="previewChanges(editEventForm.value)"></app-form-button>
        </div>
          <!-- <div>
            <button *ngIf="step==2 && editEventForm.valid" type="submit" (click)="previewChanges(editEventForm.value)"
            class="flex py-2 md:px-10 space-x-2 md:border-customTeal md:border rounded-md text-black w-full md:w-max  md:bg-white justify-end md:justify-start"
            
            >
            
            <i class="material-icons mr-3 text-customTeal float-left">preview</i>
            Preview Changes
            </button>
          </div> -->
          <div class="flex flex-row justify-between mt-5 w-full max-w-xgap-x-3">
            <div
              class="form-control w-full  justify-between flex flex-col md:flex-row gap-y-5 md:gap-y-0 md:gap-x-5">
              <!-- cancel and delete -->
              <div class="flex gap-x-4">
                <app-form-button buttonText="Cancel" buttonStyle="cancel" buttonType="button" (click)="openCancelModal()"></app-form-button>
                <app-form-button buttonText="Delete" buttonStyle="delete" buttonType="button" (click)="openDeleteModal()" *ngIf="isAdmin ||(permPES && permPES.deleteEvents)"></app-form-button>
                <app-form-button *ngIf="step==2" buttonText="Update" [disabled]="!editEventForm.valid" buttonStyle="filled" buttonType="button" (click)="editModalOpen=true"></app-form-button>
              </div>
  
          <!-- next, previous and update -->
            <div class="flex gap-x-4">
              <app-form-button *ngIf="step==1" buttonText="Next" [disabled]="((eventTitle.invalid || eventPlace.invalid || eventStartDate.invalid || eventEndDate.invalid))" buttonStyle="filled" buttonType="button" (click)="nextStep()"></app-form-button>
              <app-form-button *ngIf="step==2" buttonText="Previous" [disabled]="false" buttonStyle="bordered-with-icon" buttonType="button" (click)="nextStep()">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-customTeal" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
              </app-form-button>
            </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>

<div *ngIf="preview && event">
  <div class="flex flex-row-reverse mb-2 mr-5 md:ml-0">
    <app-form-button buttonText="Save Changes" buttonStyle="bordered" buttonType="button" (click)="editModalOpen=true" [disabled]="false"></app-form-button>
    <app-form-button buttonText="Back to previous page" buttonStyle="filled" buttonType="button" (click)="preview=false"></app-form-button>
                
    <!-- <button class="btn-custom-bordered" (click)="editModalOpen=true">Save Changes</button>
    <button class="btn-custom-solid" (click)="preview=false">Back to previous page</button> -->
  </div>
  <app-view-event [modelToPreview]="blogToPreview"></app-view-event>
</div>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteEvent(event)
  (cancelDeleteClicked)="deleteModalOpen=false" [deletedFromComponent]=eventsName></app-delete-confirmation>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal()
  (saveBtnClicked)=updateEvent(editEventForm.value) [editedFromComponent]=eventsName></app-edit-confirmation>

<!-- Cancel Modal -->
<app-cancel-confirmation *ngIf="cancelModalOpen" (cancelClicked)=backClicked() (continueClicked)=closeCancelModal()
  canceledFromAction="Editing" [canceledFromComponent]=eventsName></app-cancel-confirmation>

<!-- Success/failure alert -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [useBase64]="useBase64" [aspectRatio]="8 / 5"
  (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Upload File Modal -->
<app-file-upload-modal *ngIf="uploadFileModalOpen" [mediaList]="mediaList"
  (returnSavedMediaURLs)="getTheUrlsSaved($event)"></app-file-upload-modal>