import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../firebase/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiKeyManagerService } from '../api/apiKeyManager.Service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

declare var Stripe;

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  private apiKey = 'pk_test_51KxZNKBv0LG3RwaxNKYGnTnVBuzKFIju64aGHZonR7o1YxPdj4a04CewPr2c38TDrKWwvaoBAOA3nShklNwMKZLs00IdllUv5d';
  private provider : any;
  customer : any;
  paymentMethod: any;

  url = environment.firstNation.apiUrl + 'api/Checkout/';
  constructor(
    private httpClient: HttpClient,
    private db: AngularFirestore,
    private authService: AuthService,
    private keyManager: ApiKeyManagerService
    ) { 
      this.provider = Stripe(this.apiKey);
      this.GetStripeCustomer().valueChanges().subscribe(cus => {
        if(cus){
          if(cus.length > 0)
          {
            this.customer = cus[0];
          }
        }
      })
      this.GetPaymentMethodsList().valueChanges().subscribe(pmt => {
        if(pmt){
          if(pmt.length > 0)
          {
            this.paymentMethod = pmt[0];
          }
        }

      })
  }

  createSubscriptionPlan(){
    if(!this.customer || !this.paymentMethod) { return;} 
    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    
    var model = {
      customerId : this.customer.stripeCustomerID,
      creditcardId : this.paymentMethod.paymentMethodID ,
      priceId: ''
    };
    return this.httpClient.post<any>(this.url + 'CreateSubscriptionPlan/', JSON.stringify(model), {headers})
   
  }

  createStripeCustomer(customer: any): Observable<any>{
    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    
    var user_email = this.authService.currentUser.email;
   
    var model = {
      id : '',
      email : this.authService.currentUser.email ,
      name: this.authService.globalUser.GivenName + ' ' + this.authService.globalUser.LastName,
    };

    return this.httpClient.post<any>(this.url + 'CreateStripeCustomer/', JSON.stringify(model), {headers})
       
  }

  attachPaymentMethod(paymentMethodID: any): Observable<any>{
    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    
    var model = {
      CustomerId : this.customer.stripeCustomerID,
      PaymentMethodId : paymentMethodID ,     
    };

    return this.httpClient.post<any>(this.url + 'AttachPaymentMethod/', JSON.stringify(model), {headers})
       
  }

  buyProduct(prodList: any) {

    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    this.httpClient.post<any>(this.url + 'CreateCheckoutSession/', JSON.stringify(prodList), {headers}).subscribe(result => {
      if(result) {
        this.provider.redirectToCheckout({
            sessionId: result.sessionId,
        }).then(function (result: { error: { message: any; }; }) {
        });
      } else { }        
    });
  }

  initStripeElements(){
    const stripe = Stripe(this.apiKey);
    const elements = stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    const card = elements.create('card', {hidePostalCode: true,style: style});
    card.mount('#card-element');
    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  
    const paymentForm = document.getElementById('payment-form');
      paymentForm.addEventListener('submit', event => {
        event.preventDefault();

        

        stripe.createPaymentMethod({
          type : 'card',
          card : card,
          billing_details: {
            name: this.authService.globalUser.GivenName + ' ' + this.authService.globalUser.LastName,
            email : this.authService.currentUser.email,
          },
          
        }).then(result => {
          if (result.error) {
            const errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
          } else {  

            this.addPaymentMethod(result).then(()=> {
              this.attachPaymentMethod(result.paymentMethod.id).subscribe(pmt=> {
              })
             
            });
     
          }
        });
      });
  
  }

  buySubscriprion() {

  }

 

  private GetStripeCustomer() {
    const uid = this.authService.currentUser.uid;
    return this.db.collection('StripeCustomers', ref => ref.where('uid', '==', uid));
  }

  private GetPaymentMethodsList() {
    const uid = this.authService.currentUser.uid;
    return this.db.collection('PaymentMethods', ref => ref.where('userID', '==', this.authService.currentUser.uid));
  }
   addStripeCustomer( stripeCustomerID: any) {
    
    var model = {
      uid : this.authService.currentUser.uid,
      stripeCustomerID : stripeCustomerID,
      email : this.authService.currentUser.email,
      dateCreated : new Date()
    }
    return this.db.collection('StripeCustomers').doc(model.uid).set(model);
  }
  
  addPaymentMethod(stripeResult: any) {
    
    var model = {
      userID : this.authService.currentUser.uid,
      paymentMethodID : stripeResult.paymentMethod.id,
      cardType : stripeResult.paymentMethod.card.brand,
      last4Digits : stripeResult.paymentMethod.card.last4,
      expMonth: stripeResult.paymentMethod.card.exp_month,
      expYear: stripeResult.paymentMethod.card.exp_year,
      dateCreated : new Date()
    }
    return this.db.collection('PaymentMethods').doc(this.db.createId()).set(model);
  }




  CreateSubscriptionPlan(model:any){
    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>("https://api.goingmobile.app/api/Subscription/CreateSubscription" , JSON.stringify(model), {headers}) 
  }

  SubscriptionSuccess(session_id:any){
    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    
    var model = {
      session_id : session_id,     
    };

    return this.httpClient.post<any>("https://api.goingmobile.app/api/Subscription/SubscriptionSuccess" , JSON.stringify(model), {headers})
       
  }

  SubscriptionPortal(cid:any){
    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    
    const model = {
      customerId : cid,     
    };

    return this.httpClient.post<any>("https://api.goingmobile.app/api/Subscription/CreateCustomerPortalSession" , JSON.stringify(model), {headers})
       
  }
}
