<div class="font-body min-h-screen max-h-max pt-6">

    <div [routerLink]="['/admin/edit-subscriber']" class="flex justify-end mr-8">
        <p class="px-4 py-2 bg-customTeal text-white">Edit Form</p>
    </div>
    <p class=" font-bold text-xl text-center text-customTeal my-2">Informations to be included in the
        Clients' Profile
    </p>
    <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4"
        *ngIf="!formExists">
        <p class="my-3 text-center mx-2 md:mx-10 text-md"> You can add as many fields as you like. You
            can ask a question with a format open ended question, Date and also Number.</p>
        <hr />

        <div class="flex md:flex-row flex-col md:space-x-10 sm:space-y-2 md:gap-x-3">
            <div class="flex space-y-2 md:w-1/2  flex-col">
                <label for=" " class="text-xs text-gray-500 font-bold "> First Name <span class="text-red-500">*</span>
                </label>
                <input maxlength="60"
                    class=" border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline md:w-96 w-76"
                    type="text" placeholder="Jane">
            </div>

            <div class="flex space-y-2 md:w-1/2 flex-col ">
                <label for=" " class="text-xs text-gray-500 font-bold "> Last Name <span class="text-red-500">*</span>
                </label>
                <input
                    class=" border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline md:w-96 w-76"
                    type="text" placeholder="John">
            </div>

        </div>

        <div class="flex md:flex-row flex-col md:space-x-10 sm:space-y-2 md:gap-x-3">

            <div class="flex space-y-2 md:w-1/2 flex-col ">
                <label for=" " class="text-xs text-gray-500 font-bold "> Phone Number <span
                        class="text-red-500">*</span>
                </label>
                <input type="text"  id='cellPhone'
                    class=" w-full border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline md:w-96 w-76"
                    placeholder="000-000-0000" />
            </div>

            <div class="flex space-y-2 md:w-1/2 flex-col ">
                <label for=" " class="text-xs text-gray-500 font-bold "> Email
                </label>
                <input
                    class=" border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline md:w-96 w-76"
                    type="text" placeholder="johnjane@gmail.com">
            </div>
        </div>


        <form [formGroup]="skillsForm" (ngSubmit)="submitQuestions(skillsForm.value)">
            <div formArrayName="skills">
                <div *ngFor="let skill of skills.controls; let i=index">
                    <div [formGroupName]="i">

                        <div class="flex justify-between" *ngIf="i > 0">
                            <p></p>
                            <p class="px-3 py-3 md:mr-4 bg-customTeal rounded-full w-12" (click)="removeFile(i)"><i
                                    class="material-icons text-white ">clear</i></p>
                        </div>


                        <div class="flex space-x-2">
                            <div class="flex space-y-2 md:w-1/2  flex-col mt-1">
                                <label class="font-bold text-sm">Field {{i+1}}: <span class="text-red-500">*</span>
                                </label>
                                <input
                                    class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                    id="question " type="text " placeholder="Field Name" formControlName="question">
                                <div *ngIf="(skill.controls.question.dirty ||
                                        skill.controls.question.touched) &&
                                        skill.controls.question.invalid">
                                    <small *ngIf="skill.controls.question.errors.required" class="text-red-500">
                                        The
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <br />

                            <div class="flex space-y-2 md:w-1/2 flex-col mb-4 mt-1">
                                <label class="font-bold text-sm">Field Type: <span class="text-red-500">*</span>
                                </label>
                                <select formControlName="answerType" required
                                    class="validate py-3 px-4 select select-bordered w-full bg-veryLightGray"
                                    (change)="answerTypeChange($event.target.value, i)">
                                    <option value="" disabled selected>Please Select Answer Type</option>
                                    <option *ngFor="let answer of answerTypes" [value]="answer.type" title="Tooltip">
                                        {{answer.type}}
                                        <span class="text-green-400"> ( {{answer.helpText}} )</span>
                                    </option>
                                </select>

                                <div *ngIf="(skill.controls.answerType.dirty ||
                                                    skill.controls.answerType.touched) &&
                                                    skill.controls.answerType.invalid">
                                    <small *ngIf="skill.controls.answerType.errors.required" class="text-red-500"> The
                                        Answer Type is required.
                                    </small>
                                </div>
                            </div>




                            <div *ngIf="skill.controls.answerType.value === 'Multiple Choice'">

                                <div class="row input-field col s12 m5 right">
                                    <label class="flex justify-end">
                                        <input class="flex justify-end checkbox checked:bg-customTeal"
                                            type="checkbox" formControlName="allowMultipleAnswers" />
                                        <span>Allow Multiple Answers</span>
                                    </label>
                                </div>
                                <br />
                                <div class="row input-field col s12" formArrayName="answers"
                                    *ngFor="let ans of getAnswers(i).controls; let j = index;">
                                    <div [formGroupName]="j">

                                        <div class="row input-field col s11">
                                            <span class="font-bold text-sm">Choice {{j+1}}: </span>
                                            <input id="answer" type="text" formControlName="answer" required
                                                class="validate border mb-2 rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                                autocomplete="off" style="text-transform: capitalize;"
                                                placeholder="Answer" />
                                            <a *ngIf="j > 1"
                                                class="btn-flat waves-effect waves-light col s1 right"><i
                                                    class="material-icons text-red-500"
                                                    (click)="removeAnswer(i, j)">delete</i></a>
                                            <div
                                                *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                                                <small *ngIf="ans.controls.answer.errors.required"
                                                    class="text-red-500">
                                                    Answer {{j+1}} is
                                                    required.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row col s12">
                                    <button type="button" class="mb-4 bg-customTeal px-4 py-2 rounded-md"
                                        (click)="onAddAnswer(i)"
                                        [disabled]="!skillsForm.get('skills').controls[i].get('answers').valid"
                                        style="cursor: pointer;">
                                        <i class="material-icons text-white float-left">add_circle</i><span
                                            class="text-white">Add Option</span>
                                    </button>
                                </div>
                            </div>




                        </div>

                        <div class="flex justify-end">
                            <input  [checked]="skill.controls.required.value" id="checked-checkbox" type="checkbox" value="" formControlName ="required"
                            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"  (change)="toggleRequired($event)" 
                            >
                            <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Required</label>
                        </div>                       

                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <button type="button" (click)="addQuestion()" [disabled]="!skillsForm.valid"
                    class="flex space-x-2 border-2 border-customTeal px-4 md:px-6 py-2 text-customTeal rounded-lg w-max h-min text-sm md:text-base font-bold focus:outline-none  ">
                    <i class="material-icons text-customTeal">add_circle</i><span>Add Field</span>
                </button>
            </div>


            <div class="pt-6 space-y-2 flex justify-between">
                <div class="">
                </div>
                <div class="">
                    <button type="submit" [disabled]="!skillsForm.valid"
                        class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                        <span>Update Form</span>
                        <!-- <span>Save Form</span> -->
                    </button>
                </div>
            </div>
        </form>

    </div>

    <div>

    </div>

</div>
<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}">
</app-alert>





<!-- <div class="flex space-x-4">

    <p class="font-bold mx-16 text-lg">{{names}} : </p>

    <div *ngIf="value == 'Open Ended Answer'">
        <input maxlength="60"
            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
            type="text" placeholder="Jane" required>
    </div>

    <div *ngIf="value == 'Date'">
        <input type="date" id="birthday" name="birthday" formControlName="answer"
            class="border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline ">

    </div>

    <div class="bottom-0 mx-20 mt-48">
        <input maxlength="60" [(ngModel)]="names"
            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
            type="text" placeholder="Jane" required>

        <div class="flex space-y-2 md:w-full flex-col mb-4">
            <label class="font-bold text-sm">Field Type: <span class="text-red-500">*</span>
            </label>
            <select required [(ngModel)]="value"
                class="validate py-3 px-4 select select-bordered w-full bg-veryLightGray">
                <option value="" disabled selected>Please Select Answer Type</option>
                <option *ngFor="let answer of answerTypes" [value]="answer.type" title="Tooltip">
                    {{answer.type}}
                    <span class="text-green-400"> ( {{answer.helpText}} )</span>
                </option>
            </select>


        </div>
    </div>

</div> -->










<!-- <div class="font-body min-h-screen max-h-max pt-6">
    <div class="flex flex-col mx-4 md:mx-24 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4">
        <p class=" font-bold text-xl text-center text-customTeal ">Invite Subscriber </p>
        <p class="py-2 text-center">Add an Subscriber to your app. An invitation email will be sent to them.</p>

        <form [formGroup]="subscriberForm" (ngSubmit)="SubmitForm(subscriberForm.value)">
            <div class="flex flex-col pt-12 space-y-4 mb-4 md:flex-col  ">
                <div class="px-4 md:px-0 space-y-4 md:space-y-0  md:grid grid-cols-2 gap-6">
                    <div class="flex space-y-2 md:w-80  flex-col">
                        <label for=" " class="text-xs text-gray-500 font-bold "> First Name <span class="text-red-500">*</span> </label>
                        <input maxlength="60" [(ngModel)]="names" formControlName="firstName"
                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
                            type="text" placeholder="Jane" required>
                        <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                            <small *ngIf="firstName.errors.required" class="text-red-500"> The First Name is required.
                            </small>
                        </div>
                    </div>

                    <div class="flex space-y-2 md:w-80 flex-col ">
                        <label for=" " class="text-xs text-gray-500 font-bold "> Last Name <span class="text-red-500">*</span> </label>
                        <input formControlName="lastName"
                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
                            type="text" placeholder="John" required>
                        <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                            <small *ngIf="lastName.errors.required" class="text-red-500"> The Last Name is required.
                            </small>
                        </div>
                    </div>
                    <div class="flex space-y-2 md:w-96 flex-col">
                        <label for=" " class="text-xs text-gray-500 font-bold "> Email <span class="text-red-500">*</span></label>
                        <input type="email" formControlName="email"
                            class="peer border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline"
                            id="Email" type="email" placeholder="janejohn@gmail.com" required />
                       
                        <div *ngIf="(email.dirty || email.touched) && email.invalid">
                            <small *ngIf="email.errors.required" class="text-red-500"> The Email is required.
                            </small>
                        </div>
                    </div>

                    <div class="flex space-y-2 md:w-96 flex-col ">
                        <label for=" " class="text-xs text-gray-500 font-bold ">{{names | titlecase}}<span
                                *ngIf="names != ''">'s</span> Phone Number</label>
                        <input type="text" formControlName='cellPhone' [textMask]="{mask:phoneNumberMask}"
                            id='cellPhone'
                            class=" w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "  placeholder="000-000-0000"/>
                        
                    </div>

                </div>

                <hr />

                <div class="space-y-2 pt-2 md:flex md:justify-between">
                    <div>
                        <p class="font-bold"> {{names | titlecase}}<span *ngIf="names != ''">'s</span> Date of birth </p>
                        <p class="text-xs text-darkGray">Please put in {{names | titlecase}}<span
                                *ngIf="names != ''">'s</span> Date of birth on the space provided.</p>
                    </div>

                    <div class="flex space-y-2 md:w-1/2 flex-col">
                        <div class="flex justify-between space-x-2 w-full">
                            <input formControlName="DOB"
                                class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-2/3"
                                type="date" placeholder="000-0000-0000 ">
                        </div>
                    </div>
                </div>
                <br />
                <hr />


                <div class="mt-3 flex flex-col space-y-6  md:flex-row md:space-y-0 ">
                    <div class="md:w-1/2 space-y-2 mt-2 ">
                        <p class="font-bold">
                            {{names | titlecase}}<span *ngIf="names != ''">'s</span> Profile Picture
                        </p>
                        <p class=" text-xs text-darkGray ">
                            Please upload {{names | titlecase}}<span *ngIf="names != ''">'s</span> profile picture.
                        </p>
                    </div>


                    <div class="flex justify-center align-middle space-x-8 items-baseline">
                        <div class="form-control w-full max-w-x">
                            <div class="flex flex-row w-full">
                                <div *ngIf="!isMobile"
                                    class="flex justify-center content-center w-full h-44  bg-white rounded-lg border-2 gap-x-3">

                                    <label class="custom-file-upload  self-center flex flex-col">
                                        <i
                                            class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                                        <input type="file" type="file" accept="image/*"
                                            (change)="handleFileInput($event.target.files)">
                                        <span class="block font-bold pt-1 px-10">Upload Image</span>
                                    </label>
                                </div>
                                <div *ngIf="isMobile">
                                    <button type="button"
                                        class="py-2 px-16 modal-button text-black border-2 border-customTeal rounded-lg w-full"
                                        type="button" (click)="handleMobilePhotoInput()">
                                        Upload Images
                                    </button>
                                </div>
                                <div class=" mx-4" *ngIf="base64Image">
                                    <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Image</p>
                                    <div class="flex flex-wrap gap-8">

                                        <div class="relative">
                                            <img [src]="base64Image" class="object-cover w-48 rounded-md cover"
                                                alt="Image">
                                            <button (click)="removeFile()"
                                                class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                                <i class="fa fa-trash text-md"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
                                <strong><small><i class="material-icons">error_outline</i>
                                        {{imgErrorMsg}}</small></strong>
                            </p>

                        </div>

                    </div>

                </div>


                <div class="pt-6 space-y-2">
                    <div class="">
                    </div>
                    <div class=" flex justify-end w-full">
                        <button type="submit"
                            class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  "
                            [disabled]="!subscriberForm.valid">
                            <span>Finish</span>
                        </button>
                    </div>
                </div>

            </div>
        </form>
    </div>




    <div id="my-modal-disable" *ngIf="finishModal" class="modal"
        [ngClass]="{'modal-open': finishModal, 'modal-action': !finishModal }">
        <div class="modal-box">
            <div class="modal-content">
                <h3 class="font-bold text-lg text-center mb-2">Invitation Email</h3>
                <hr />
                <p class="text-center mt-3" *ngIf="!userExists">An Invitation email will be sent to this {{names | titlecase}} subscriber. So that they can register to
                    this app.</p>
                    <p class="text-center my-3" *ngIf="userExists">This account (email) already exists.</p>

            </div>
        </div>
    </div>

</div> -->

<!-- THIS IS FOR MULTIPLE CHOICES TO BE ENTERED BEFORE CREATING A FORM -->

