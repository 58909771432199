import { createSelector, createFeatureSelector } from '@ngrx/store';
import  { UserState}  from './app.state';

export const selectAppState = createFeatureSelector<UserState>('user');
export const selectRoleFeature = (state: UserState) => state.role;

export const selectRole = createSelector(
    selectAppState,
    (state: UserState) => state.role
);

export const selectAppSettings = ((state: any) => state.settings);
export const selectUserData = ((state: any) => state.user);
export const selectScheduleData = ((state: any) => state.schedule);

