import { ViewProductComponent } from './view-product/view-product.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DataTablesModule } from 'angular-datatables';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TooltipDirective } from 'src/app/tooltip.directive';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { MessengerComponent } from './messenger/messenger.component';
import { MyInboxComponent } from './messenger/my-inbox/my-inbox.component';
import { SettingsComponent } from './settings/settings.component';
import { AppSettingsComponent } from './settings/app-settings/app-settings.component';
import { ContactSettingsComponent } from './settings/contact-settings/contact-settings.component';
import { MessagingComponent } from './messaging/messaging.component';
import { RegistrationRequestComponent } from './registration-request/registration-request.component';
import { UsersListComponent } from './users-list/users-list.component';
import { AccountSettingComponent } from './settings/account-setting/account-setting.component';
import { CreateEventComponent } from './events/create-event/create-event.component';
import { EditEventComponent } from './events/edit-event/edit-event.component';
import { LandingPageSettingsComponent } from './settings/landing-page-settings/landing-page-settings.component';
import { AdminMenuSettingsComponent } from './settings/admin-menu-settings/admin-menu-settings.component';
import { EmployeeListComponent } from './employee/employee-list/employee-list.component';
import { EmployeeAppointmentComponent } from './employee/employee-appointment/employee-appointment.component';
import { EmployeeMenuSettingsComponent } from './settings/employee-menu-settings/employee-menu-settings.component';
import { EditServiceComponent } from './service/edit-service/edit-service.component';
import { CreateServiceComponent } from './service/create-service/create-service.component';
import { UserMenuSettingsComponent } from './settings/user-menu-settings/user-menu-settings.component';
import { UpdateOwnerProfileComponent } from './settings/update-owner-profile/update-owner-profile.component';
import { ProductsComponent } from './products/products.component';
import { CreateProductComponent } from './products/create-product/create-product.component';
import { EditProductComponent } from './products/edit-product/edit-product.component';
import { PollsComponent } from './polls/polls.component';
import { EditPollComponent } from './polls/edit-poll/edit-poll.component';
import { CreatePollComponent } from './polls/create-poll/create-poll.component';
import { PollResultsComponent } from './polls/poll-results/poll-results.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { SocialMediaProfileComponent } from './social-media/profile/profile.component';
import { CreateBlogComponent } from './blog/create-blog/create-blog.component';
import { AdminSettingsMainComponent } from './admin-settings-main/admin-settings-main.component';
import { AdminSocialMainComponent } from './admin-social-main/admin-social-main.component';
import { FaqSettingComponent } from './settings/faq-setting/faq-setting.component';
import { SeoSettingComponent } from './settings/seo-setting/seo-setting.component'
import { AboutSettingComponent } from './settings/about-setting/about-setting.component';
import { EditBlogComponent } from './blog/edit-blog/edit-blog.component';
import { AdminNotificationsComponent } from './admin-notifications/admin-notifications.component';
import { AppRequestComponent } from './app-request/app-request.component';
import { SignupSettingsComponent } from './settings/signup-settings/signup-settings.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { AppRequestDetailComponent } from './app-request/app-request-detail/app-request-detail.component';
import { EmployeeFormComponent } from './users-list/employee-form/employee-form.component';
import { EmployeeEditComponent } from './users-list/employee-edit/employee-edit.component';
import { ServicesComponent } from './service/services/services.component';
import { EmployeeSocialMainComponent } from './employee-social-main/employee-social-main.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { EmployeeScheduleComponent } from './employee-schedule/employee-schedule.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { EditClientFormComponent } from './edit-client-form/edit-client-form.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AgCharts } from 'ag-charts-angular';
import { AppSetupComponent } from './app-setup/app-setup.component';
import { PublicPageSetupComponent } from './public-page-setup/public-page-setup.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InviteSubscriberComponent } from './invite-subscriber/invite-subscriber.component';
import { EditSubscriberComponent } from './invite-subscriber/edit-subscriber/edit-subscriber.component';
import { CreateFormComponent } from './create-form/create-form.component';
import { CreateClientComponent } from './invite-subscriber/create-client/create-client.component';
import { AnalyticsUserComponent } from './analytics-user/analytics-user.component';
import { AnalyticsPageComponent } from './analytics-page/analytics-page.component';
import { AnalyticsChannelComponent } from './analytics-channel/analytics-channel.component';
import { AnalyticsAllComponent } from './analytics-all/analytics-all.component';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';
import { AppointmentSettingComponent } from './settings/appointment-setting/appointment-setting.component';
import { FirstLoginSetupComponent } from './first-login-setup/first-login-setup.component';
import { PushNotificationSettingComponent } from './settings/push-notification-settings/push-notification-settings.component';
import { VerifyAccountRequestComponent } from './verify-account-request/verify-account-request.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppRequestAcceptComponent } from './app-request/app-request-accept/app-request-accept.component';
import { EmptyDashboardComponent } from './empty-dashboard/empty-dashboard.component';
import { GuidePageComponent } from './guide-page/guide-page.component';
import { CreateNewSectionComponent } from './custom_section/create-new-section/create-new-section.component';
import { EditCustomSectionComponent } from './custom_section/edit-custom-section/edit-custom-section.component';
import { CreateSectionElementComponent } from './custom_section/create-section-element/create-section-element.component';
import { EditSectionElementComponent } from './custom_section/edit-section-element/edit-section-element.component';
import { WelcomeSettingComponent } from './settings/welcome-setting/welcome-setting.component';
import { SliderSettingComponent } from './settings/slider-setting/slider-setting.component';
import { FooterSettingComponent } from './settings/footer-setting/footer-setting.component';
import { CardListComponent } from './empty-dashboard/card-list/card-list.component';
import { NotificationComponent } from './notification/notification.component';
import { SuccessCardComponent } from './empty-dashboard/success-card/success-card.component';
import { CustomizeCardComponent } from './settings/customize-card/customize-card.component';
import { PollsAndSurveyComponent } from './polls-and-survey/polls-and-survey.component';
import { ContactUsMessagesComponent } from './contact-us-messages/contact-us-messages.component';
import { HomepageComponent } from './settings/homepage/homepage.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { PollsListComponent } from './polls-and-survey/polls-list/polls-list.component';
import { IosSettingsComponent } from './settings/pwa-settings/ios-settings/ios-settings.component';
import { AndroidSettingsComponent } from './settings/pwa-settings/android-settings/android-settings.component';
import { AnalyticsSetupComponent } from './analytics-setup/analytics-setup.component';
import { CustomizeCardImageComponent } from './settings/landing-page-settings/customize-card-image/customize-card-image.component';
import { PreviewHompageComponent } from './preview-hompage/preview-hompage.component';
import { AddScheduleComponent } from './schedule/add-schedule/add-schedule.component';
import { BrandSetupComponent } from './app-setup/brand-setup/brand-setup.component';
import { GeneralSetupComponent } from './app-setup/general-setup/general-setup.component';
import { RearrangeCardsComponent } from './settings/landing-page-settings/rearrange-cards/rearrange-cards.component';
import { CreateAnalyticsAccountComponent } from './analytics-setup/create-analytics-account/create-analytics-account.component';

import {DragDropModule} from '@angular/cdk/drag-drop';
import { ContactFormComponent } from './contact-list/contact-form/contact-form.component'
import { UserActivityComponent } from './settings/homepage/user-activity/user-activity.component';
import { RemindersComponent } from './settings/homepage/reminders/reminders.component';
import { HomepageAppointmentsComponent } from './settings/homepage/homepage-appointments/homepage-appointments.component'



import { InvitedEmployeesComponent } from './settings/departments/invited-employees/invited-employees.component';
import { ContactDetailComponent } from './contact-list/contact-detail/contact-detail.component';

import { CustomSectionsListComponent } from './custom_section/custom-sections-list/custom-sections-list.component';
import { ImportContactComponent } from './users-list/import-contact/import-contact.component';
import { PageSelectionComponent } from './empty-dashboard/page-selection/page-selection.component';
import { ListItemComponent } from './empty-dashboard/list-item/list-item.component';
import { UpdateFirebaseRulesComponent } from './update-firebase-rules/update-firebase-rules.component';
import { OnboardingModalComponent } from './empty-dashboard/onboarding-modal/onboarding-modal.component';
import { EmailApppasswordGuideComponent } from './app-setup/email-apppassword-guide/email-apppassword-guide.component';
import { BillingSetupComponent } from './billing-setup/billing-setup.component';

import { EditClientInfoComponent } from './invite-subscriber/create-client/edit-client-info/edit-client-info.component';
import { SettingsComponent as Settings } from '../user/settings/settings.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateDetailComponent } from './templates/template-detail/template-detail.component';
import { TemplatePreviewComponent } from './templates/template-preview/template-preview.component';

import { HeaderComponent } from '../navigation/header/header.component';
import { PreviewComponent } from './preview/preview.component';
import { CustomerFormBuilderComponent } from './customer-form-builder/customer-form-builder.component';
import { CustomerFormBuilderConfigrationComponent } from './customer-form-builder/custom-form-builder-configration/customer-form-builder-configration.component';
import { ItemUnderServiceFormComponent } from './service/item-under-service-form/item-under-service-form.component';
import { AdminClientCreationFormComponent } from './invite-subscriber/create-client/admin-client-creation-form/admin-client-creation-form.component';
import { PaginationComponent } from '../shared/pagination/pagination.component';
import { BaseChartDirective } from 'ng2-charts';

import { NgxChartsModule } from "@swimlane/ngx-charts";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';  // added
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CardPreviewComponent } from './settings/landing-page-settings/card-preview/card-preview.component';
import { EmployeesComponent } from './settings/departments/employees/employees.component';
import { PageViewSetupComponent } from "./settings/landing-page-settings/page-view-setup/page-view-setup.component";
import { ListViewRearrangeComponent } from './settings/landing-page-settings/list-view-rearrange/list-view-rearrange.component';
import { CardViewCustomizationComponent } from './settings/landing-page-settings/card-view-customization/card-view-customization.component';
import { ModalComponent } from '../shared/modal/modal.component';

@NgModule({
    declarations: [
        AdminMainComponent,
        MessengerComponent,
        MyInboxComponent,
        AppSettingsComponent,
        ContactSettingsComponent,
        TooltipDirective,
        MessagingComponent,
        RegistrationRequestComponent,
        UsersListComponent,
        SettingsComponent,
        Settings,
        // SettingsComponent,
        // NoSanitizePipe,
        AccountSettingComponent,
        CreateEventComponent,
        EditEventComponent,
        LandingPageSettingsComponent,
        // ScheduleComponent,
        // AppointmentComponent,
        EditClientInfoComponent,
        AdminMenuSettingsComponent,
        EmployeeListComponent,
        EmployeeAppointmentComponent,
        EmployeeMenuSettingsComponent,
        EditServiceComponent,
        CreateServiceComponent,
        UserMenuSettingsComponent,
        UpdateOwnerProfileComponent,
        ProductsComponent,
        CreateProductComponent,
        EditProductComponent,
        PollsComponent,
        EditPollComponent,
        CreatePollComponent,
        PollResultsComponent,
        DashboardComponent,
        SocialMediaComponent,
        SocialMediaProfileComponent,
        CreateBlogComponent,
        AdminSettingsMainComponent,
        AdminSocialMainComponent,
        FaqSettingComponent,
        SeoSettingComponent,
        AboutSettingComponent,
        ViewProductComponent,
        EditBlogComponent,
        AdminNotificationsComponent,
        AppRequestComponent,
        SignupSettingsComponent,
        AdminProfileComponent,
        AppRequestDetailComponent,
        EmployeeFormComponent,
        EmployeeEditComponent,
        ServicesComponent,
        EmployeeSocialMainComponent,
        AppointmentComponent,
        EmployeeScheduleComponent,
        ClientFormComponent,
        EditClientFormComponent,
        AppSetupComponent,
        PublicPageSetupComponent,
        InviteSubscriberComponent,
        EditSubscriberComponent,
        CreateFormComponent,
        CreateClientComponent,
        // AnalyticsUserComponent,
        // AnalyticsPageComponent,
        AnalyticsAllComponent,
        CreateAppointmentComponent,
        AppointmentSettingComponent,
        FirstLoginSetupComponent,
        PushNotificationSettingComponent,
        VerifyAccountRequestComponent,
        AppRequestAcceptComponent,
        EmptyDashboardComponent,
        GuidePageComponent,
        CreateNewSectionComponent,
        EditCustomSectionComponent,
        CreateSectionElementComponent,
        EditSectionElementComponent,
        WelcomeSettingComponent,
        SliderSettingComponent,
        FooterSettingComponent,
        CardListComponent,
        SuccessCardComponent,
        CustomizeCardComponent,
        PollsAndSurveyComponent,
        ContactUsMessagesComponent,
        NotificationComponent,
        HomepageComponent,
        ContactListComponent,
        // DownloadPwAButtonComponent,
        PollsListComponent,
        IosSettingsComponent,
        AndroidSettingsComponent,
        AnalyticsSetupComponent,
        CustomizeCardImageComponent,
        PreviewHompageComponent,
        AddScheduleComponent,
        RearrangeCardsComponent,
        BrandSetupComponent,
        GeneralSetupComponent,
        CreateAnalyticsAccountComponent,
        ContactFormComponent,
        UserActivityComponent,
        RemindersComponent,
        HomepageAppointmentsComponent,
        InvitedEmployeesComponent,
        ContactDetailComponent,
        PageSelectionComponent,
        ListItemComponent,
        CustomSectionsListComponent,
        ImportContactComponent,
        CustomerFormBuilderComponent,
        CustomerFormBuilderConfigrationComponent,
        UpdateFirebaseRulesComponent,
        OnboardingModalComponent,
        TemplatesComponent,
        TemplateDetailComponent,
        TemplatePreviewComponent,
        BillingSetupComponent,
        EmailApppasswordGuideComponent,
        PreviewComponent,
        ItemUnderServiceFormComponent,
        //
        AdminClientCreationFormComponent,
        CardPreviewComponent,
        EmployeesComponent,
        PageViewSetupComponent,
        ListViewRearrangeComponent,
        CardViewCustomizationComponent,
      
    ],
    exports: [
        UsersListComponent,
        HomepageAppointmentsComponent,
        UserActivityComponent,
        RemindersComponent,
    ], imports: [
    DragDropModule,
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    SharedModule,
    DataTablesModule,
    CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
    }),
    HighchartsChartModule,
        AgCharts,
    BaseChartDirective,
    NgxChartsModule,
    BaseChartDirective,
    BrowserAnimationsModule, // added
    ImageCropperModule
], providers: [
        provideHttpClient(withInterceptorsFromDi())],
    })
export class AdminModule { }
