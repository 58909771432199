import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { UserService } from 'src/app/services/firebase/user.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

// declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  bottomMenu: any[];
  moreMenu: any[];
  menu: any;
  showDefault = false;
  isLogged = false;
  settings: any;
  isDemo = (environment.firstNation.name === 'Demo') ? true : false;
  @Input() navbarOption = '';
  pageName = '';
  socialMediaModel
  contactForm: UntypedFormGroup;

  appStore
  playStore=environment.mobileAppLinks.playStore
  appleStore=environment.mobileAppLinks.appleStore

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  admins=[]

  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://mynation.app" : "https://goingmobile.app";
  appSettings$: Subscription;
  constructor(private router: Router,
              private settingService: AppSettingsService,
              private authService: AuthService,
              private location: Location,
              private socialService: SocialMediaService,
              private fb: UntypedFormBuilder,
              private emailService: EmailService,
              private helperService: HelperService,
              private store: Store,
              private userService: UserService) {
                this.router.events.subscribe(val => {


                  if (location.path() === '/auth/login') {
                    this.pageName = 'Login';
                  }

                  else if (location.path() === '/auth/login') {
                    this.pageName = 'Login';
                  }


                  else if (location.path().includes('auth/admin-registration')) {
                    this.pageName = 'Admin Sign Up';
                  }

                  else if (location.path() === '/registration-request') {
                    this.pageName = 'Register';
                  }

                  else if (location.path() === '/register-admin') {
                    this.pageName = 'Register';
                  }
                  else if (location.path() === '/register') {
                    this.pageName = 'Register';
                  }
                  else if (location.path() === '/login') {
                    this.pageName = 'Login';
                  }
                  else if (location.path() === '/auth/change-password') {
                    this.pageName = 'ForgotPassword';
                  }else if (location.path() === '/contact-us') {
                    this.pageName = 'Contact';
                  }else if (location.path() === '/register-from-invite/:id') {
                    this.pageName = 'Register';
                  }
                  else{
                    this.pageName = '';
                  }
                });

                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
                  let allsetting = this.helperService.cloneDeep(settings) || { appSections: [] };
                  this.settings = allsetting.appSections;
                });
               }

  ngOnInit() {

    this.contactForm = this.fb.group({
      from: ['', [Validators.required,Validators.email]],
      message: ['', Validators.required],
    });


          if (this.settings) {
            this.appStore = this.settings.filter(s => s.name === 'AppStore' && s.isEnabled);
            const socialSection = this.settings.filter(s => s.name === 'SocialMedia' && s.isEnabled);
            if (socialSection) {
              if (socialSection.length > 0) {
                this.socialService.getSocialMedia().valueChanges().subscribe(x => {
                  if (x) {
                    if (x.length > 0) {
                        this.socialMediaModel = x[0];
                    }
                  }
                });
              }
            }
          }


    this.authService.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isLogged = true;
      } else {
        this.isLogged = false;
        setTimeout(() => {
        }, 25);
      }
    });


  }

  get from(){return this.contactForm.get('from'); }
  get message(){return this.contactForm.get('message'); }

  getUserEmail(values){
    const mail = new CustomAdminEmailModel();
    mail.subject = 'Email from user';
    mail.body = `<span>Hello</span><br/>`;
    mail.isNationApp = this.isNationApp;

    mail.body += `<span>An app user with the email <span><strong>${values.from} </strong> has sent you the following message: </span><br/><br/>`;
    mail.body +=  `<p stype="padding: 0px 10px">${values.message}</p><br/>`;
    mail.body += '<br/> Thank You.';
    // custom send email used
    mail.host = "smtp.gmail.com"
    mail.adminEmail = "mkproject.app@gmail.com"
    mail.appPassword = "ytklyidomvqfmtky"
    return mail
  }
  sendEmail(values){
    let msg = this.getUserEmail(values)

    this.userService.getAllAdmins().valueChanges().subscribe(admins => {
      if (admins.length > 0){

        this.admins = admins.map((admin: any)=>admin.Email)
        msg.emailList=this.admins

        this.emailService.sendCustomEmail(msg).subscribe(x => {

          if (x && x.Status == 'OK') {
            this.toast({html: 'Email successfuly sent', classes: 'green', type: 'success'});

            this.contactForm.reset()
          } else {
          }
        })
      }
    });
  }

  navigate(url: any) {
    if (url) {
      this.router.navigate([url]);
    } else {this.router.navigate(['/']); }
  }

  termsAndPolicyClicked(event) {

    localStorage.setItem('FromPath', 'TermsAndPolicy');
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home-sub-menu']));
    // localStorage.removeItem('FromPath');
    // localStorage.setItem('FromPath', 'TermsAndPolicy');
    // this.router.navigate(['/home-sub-menu']);
  }

  socialNetworkClicked(event) {

    localStorage.setItem('FromPath', 'SocialNetwork');
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home-sub-menu']));

    // localStorage.removeItem('FromPath');
    //
    // this.router.navigate(['/home-sub-menu']);
  }

  employersClicked() {

    localStorage.setItem('FromPath', 'Employers');
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home-sub-menu']));

  }

  back(){
    this.location.back();
  }
  toast(obj){

    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
}
