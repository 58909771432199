import { createAction, props } from '@ngrx/store';
import { UserState } from './app.state';

export const loginUser = createAction('[LoggedInUser] LoginUser', props<{ username: string; password: string }>());
export const addUser = createAction('[LoggedInUser] Add user data', props<Partial<UserState>>());
export const fetchUserData = createAction('[LoggedInUser] Get user data', props<{userId: string}>());
export const updateUserData = createAction('[LoggedInUser] Update user data');
export const logout = createAction('[LoggedInUser] Remove user data', props<{userId: string}>());


export const getLandingPageSEttings = createAction('[AppSettings] Get landing page settings');
export const updateAppSettings = createAction('[AppSettings] Update landing page settings', props<{settings: object}>());
export const addAppSettings = createAction('[AppSettings] Add landing page settings', props<{settings: object}>()); 
export const updateAppSettingsFailure = createAction('[AppSettings] Update App Settings Failure', props<{ error: any }>());
export const updateAppsssSettings = createAction('[AppSettings] Update landing page settings', props<{settings: object}>());

export const getAllSchedules = createAction('[Schedule] Get all schedules');
export const addEmployeeIdsFromSchedule = createAction('[Schedule] Fetch employee ids from schedule', props<{schedule: object}>());
export const getAllUsersEmails = createAction('[Users] Get all users emails');