import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input() modalTitle;
  @Input () btnName;
  @Output() cancelBtnClicked  = new EventEmitter<void>();
  @Output() btnClicked = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
    console.log("This is from the app modal")
  }
  cancelClicked(){
    this.cancelBtnClicked.emit();
  }
  buttonClicked(){
    this.btnClicked.emit();
  }
}
