import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UserModel } from 'src/app/models/UserModel';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { EmployeeScheduleModel } from 'src/app/models/ScheduleModel';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-schedule',
  templateUrl: './employee-schedule.component.html',
  styleUrls: ['./employee-schedule.component.css']
})
export class EmployeeScheduleComponent implements OnInit {
  currentUser: any;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  user: UserModel;
  scheduleForm: UntypedFormGroup;
  id: any;
  hasSchedule = false;
  employeeSchedule: any;
  date: boolean = false;
  clickedDate: any;
  selectedDate: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  selectedEmployeeId: any;
  isEditingEmployee = false;
  daysOfTheWeek: any[] = [
    {
      day: 'Sunday',
      startTime: null,
      endTime: null,
      unAvailable: true
    },
    {
      day: 'Monday',
      startTime: '09:00',
      endTime: '17:00',
      unAvailable: false
    },
    {
      day: 'Tuesday',
      startTime: '09:00',
      endTime: '17:00',
      unAvailable: false
    },
    {
      day: 'Wednesday',
      startTime: '09:00',
      endTime: '17:00',
      unAvailable: false
    },
    {
      day: 'Thursday',
      startTime: '09:00',
      endTime: '17:00',
      unAvailable: false
    },
    {
      day: 'Friday',
      startTime: '09:00',
      endTime: '17:00',
      unAvailable: false
    },
    {
      day: 'Saturday',
      startTime: null,
      endTime: null,
      unAvailable: true
    },

  ];


  constructor(private fb: UntypedFormBuilder, private router: Router, private authService: AuthService,
              private fireMemberService: FireMembersService, private scheduleService: ScheduleService,
              private location: Location, private route: ActivatedRoute)
              { }

  get schedules(){return this.scheduleForm.get('schedules') as UntypedFormArray; }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.selectedEmployeeId = params.get('id');
    });
    if (this.selectedEmployeeId){
      this.scheduleForm = this.fb.group({
        // schedules: this.fb.array([  ],)
      });
      this.fireMemberService.getMemberByID(this.selectedEmployeeId).valueChanges().subscribe(user => {
        if (user){
          if (user.role === 'Employee' || user.role === 'Admin'){
            this.currentUser = user;
            this.id = this.currentUser.uid;

            this.scheduleService.getScheduleByEmployeeId(this.id).valueChanges().subscribe(s=>{
              let controls;
              if (s.length > 0){
                this.employeeSchedule = s[0];
                this.hasSchedule = true;
                controls = this.employeeSchedule.schedule.map((sc: any) => {
                  return this.createFormGroup(sc);
                });

              }
              else{
                controls = this.daysOfTheWeek.map(sc => {
                  return this.createFormGroup(sc);
                });
              }
              this.scheduleForm.registerControl('schedules', new UntypedFormArray(controls));

            });

          }
        }

      });
    }
  }

  createFormGroup(values){
    const startTime = values.unAvailable ? new UntypedFormControl({value: values.startTime, disabled: true}) :
                    new UntypedFormControl({value: values.startTime, disabled: false});
    const endTime = values.unAvailable ? new UntypedFormControl({value: values.endTime, disabled: true}) :
                    new UntypedFormControl({value: values.endTime, disabled: false});

    const formGroup =  this.fb.group({
      day: new UntypedFormControl({value: values.day, disabled: false}),
      startTime,
      endTime,
      unAvailable: new UntypedFormControl(values.unAvailable)
    });

    formGroup.setValidators(this.timeValidators());
    return formGroup;
  }


  timeValidators(): ValidatorFn{
    return(group: UntypedFormGroup) => {
          const errors = {};
          const startTime = group.controls['startTime'];
          const endTime = group.controls['endTime'];
          const unAvailable = group.controls['unAvailable'];

          if (startTime.value === null && endTime.value !== null){
            startTime.setErrors({notEquivalent: true});
          }
          if (endTime.value === null && startTime.value !== null){
            endTime.setErrors({notEquivalent: true});
          }

          if (unAvailable.value === false && startTime.value == null && endTime.value === null){
            endTime.setErrors({notEquivalent: true});
          }


          return null;

        };
  }

  updateDaySchedule(index){
    const schedule = this.schedules.at(index).value;
    const item = {
                day: this.daysOfTheWeek[index].day,
                startTime: schedule.startTime || '09:00',
                endTime: schedule.endTime || '17:00',
                unAvailable: !schedule.unAvailable
              };

    this.schedules.setControl(index, this.createFormGroup(item));
  }

  validateSchedule(schedule){
    return schedule.map(day => {
      day.startTime = (day.unAvailable === true) ? null : day.startTime;
      day.endTime = (day.unAvailable === true) ? null : day.endTime;
      // day.unAvailable = (day.startTime=== null && day.endTime ===null) ? true : false
      return day;
    });

  }
  onSubmit(data) {
    if (this.hasSchedule){
      this.updateSchedule(data);
    }else{
      this.createSchedule(data);
    }
  }
  updateSchedule(data){

    const employeeSchedule = {
        employeeId: this.id,
        schedule: this.validateSchedule(data.schedules),
        id: this.employeeSchedule.id
    };

    this.scheduleService.updateSchedule(employeeSchedule).then(schedule => {
      this.toast({ html: 'Employee Schedule Successfully Updated!', classes: 'green' });
    }).catch(err => {
      this.toast({ html: 'Error updating Employee Schedule', classes: 'red' });

    });
  }

  createSchedule(data){

    const employeeSchedule = new EmployeeScheduleModel();
    employeeSchedule.employeeId = this.id;
    employeeSchedule.schedule = this.validateSchedule(data.schedules);

    this.scheduleService.createEmployeeSchedule(employeeSchedule).then(schedule => {
      this.toast({ html: 'Employee Schedule Successfully Created!', classes: 'green' });
    }).catch(err => {
      this.toast({ html: 'Error creatind Employee Schedule', classes: 'red' });

    });
  }

 day(item){
  this.date = true;
  this.clickedDate = item.day;
  this.selectedDate = item;
}
isdateClicked(item){

  if(item.day == this.clickedDate){
    return true
  }
}

  backClicked(){
    this.location.back();
  }
  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.backClicked();
    }, 2000);
  }
}
