import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-page-view-setup',
  templateUrl: './page-view-setup.component.html',
  styleUrl: './page-view-setup.component.css'
})
export class PageViewSetupComponent implements OnInit{
  @Input() type
  @Input() calledFrom
  pageSections
  pageView;
  landingPageView ={
    web : 'list',
    mobile : 'card'
  }
    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
  constructor(private settingsService: AppSettingsService,){

  }
  
  ngOnInit(): void {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];

          if(this.pageSections.viewType){
            if(this.pageSections.viewType == 'list' || this.pageSections.viewType == 'card'){
              this.pageView ='web'
            }else{
              this.pageView = this.pageSections.viewType
            }
          }else{
            this.pageView ='web'
          }
          this.landingPageView = this.pageSections.landingPageView ? this.pageSections.landingPageView : this.landingPageView;
          
        
    }}
  })
  }
  changeView(platform, view: string){

    this.landingPageView[platform] = view
    this.pageSections.landingPageView = this.landingPageView
    console.log("Page sections: ", this.pageSections)
    this.saveChanges()
  }

  saveChanges() {
    if (this.pageSections) {
      console.log('log 1')
      this.settingsService.updateAppSettings(this.pageSections).then(() => {
        console.log('log 1')
        this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
      });
    }

  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  
  changePageView(view){
    this.pageView = view
    this.pageSections.viewType = view
     this.settingsService.updateAppSettings(this.pageSections).then(() => {
    
      this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
      }).catch(err => {
        this.toast({html: 'Can\'t create blog!', classes: 'red', type: 'failure'});
        // this.onLoading = false
      });

  }

}
