<div class="flex justify-center h-screen mx-3 lg:mx-10  p-5 mt-3" *ngIf="loading">
    <progress class="progress progress-[#077373] w-full"></progress>
</div>

<ng-container *ngIf="!loading">

    <div *ngIf="about && !viewMoreClicked" class="bg-gray-100 min-h-screen max-h-max">

        <div class="md:gap-y-20 mx-5 md:mx-0">
            <div class="flex flex-col space-y-10" *ngFor="let section of about.sections;">
                <ng-container *ngIf="section.visible">
                    <!-- <app-about-main *ngIf="section.sectionCatagory =='main'" [data]="section"></app-about-main> -->
                    <app-about-values *ngIf="section.sectionCatagory =='values'" [data]="section"></app-about-values>
                    <!-- <app-about-mission *ngIf="section.sectionCatagory =='mission'" [data]="section"></app-about-mission> -->
                </ng-container>

            </div>
            <!-- Members -->


            <div *ngIf="employeeGlobal && employeeGlobal.length > 0 && showEmpFromAdmin" class="flex flex-col align-middle w-full items-center pt-12 space-y-12">
          <p class="text-2xl font-bold text-center text-customTeal" *ngIf="!empty">Meet the Team</p>

          <div class="flex flex-wrap justify-center w-10/12 gap-x-20 md:gap-x-24 gap-y-10 md:gap-y-16">            
                <!-- <div *ngFor="let team of employeeGlobal" class="flex flex-col items-center space-y-1"> -->
                <div *ngFor="let team of employeeGlobal" class="flex flex-col items-center space-y-1"> 
                    <div (click)="openAboutModal(team)" class="flex flex-col items-center">
                        <div class="avatar" *ngIf="team && team.profileImgLink">
                            <div class="w-24 rounded-full ml-2 ">
                                <img loading="lazy" [src]="team.profileImgLink" />
                            </div>
                        </div>
                        <div *ngIf="team && !team.profileImgLink" class="avatar placeholder flex justify-center">
                            <div class="bg-neutral-focus text-neutral-content rounded-full w-24">
                                <span class="text-4xl">{{team.GivenName[0]}}</span>
                            </div>
                        </div>
                        <p class="font-bold pt-3 text-lg  text-center">{{team.GivenName}} {{team.LastName}}</p>
                        <p class="text-md text-darkGray text-center">{{team.jobTitle}}</p>
                    </div>
                </div>
          </div>
          <!-- <div *ngIf="allEmployees.length >= 4" class="flex justify-end underline underline-offset-4 text-customTeal font-bold cursor-pointer" (click)="viewMore()">View More</div> -->

          <br/> <br/>
            </div>


      <div id="my-modal-disable" *ngIf="aboutModal" class="modal"
      [ngClass]="{'modal-open': aboutModal, 'modal-action': !aboutModal }">
      <div class="modal-box max-w-4xl">
          <div class="modal-content">
            <div class="flex justify-end "  (click)="closeAboutModal()"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>
            <div class="avatar flex justify-center" *ngIf="clickedTeam && clickedTeam.profileImgLink">
                <div class="w-36 rounded-full flex justify-center">
                    <img loading="lazy" [src]="clickedTeam.profileImgLink" class="flex justify-center" />
                </div>
            </div>
            <div *ngIf="clickedTeam && !clickedTeam.profileImgLink" class="avatar placeholder flex justify-center">
                <div class="bg-neutral-focus text-neutral-content rounded-full w-36">
                    <span class="text-6xl">{{clickedTeam.GivenName[0]}}</span>
                </div>
            </div>
            <p class="font-bold pt-3 pb-1 text-xl text-center">{{clickedTeam.GivenName}} {{clickedTeam.LastName}}</p>
            <ng-container *ngIf="contactTeam">
                <a class="text-md underline pb-2 text-center" *ngIf="contactTeam.Email == 'public' || contactTeam.Email=='Public'" [href]="'mailto:'+clickedTeam.Email" >{{clickedTeam.Email}}</a>
            </ng-container>
            <div class="flex justify-between md:mx-40">
                <p class="text-md text-darkGray pb-2" *ngIf="clickedTeam.jobTitle"><span class="font-bold">Job title: </span>
                    {{clickedTeam.jobTitle | titlecase}}</p>
            </div>
            <div class="flex justify-between md:mx-40" *ngIf="contactTeam">
                <p class="text-md text-darkGray pb-2" *ngIf="(clickedTeam.Work == 'public'||clickedTeam.Work == 'Public') && clickedTeam.workPhone"><span
                        class="font-bold">Work Phone: </span>{{clickedTeam.workPhone}}</p>
                <p class="text-md text-darkGray pb-2" *ngIf="(contactTeam.Phone== 'public'||clickedTeam.Phone == 'Public') && clickedTeam.cellPhone"><span
                        class="font-bold">Cell Phone: </span>{{clickedTeam.cellPhone}}</p>
            </div>
            <hr />

            <p class="pt-3 text-md text-center text-justify text-gray px-3 pb-6" [innerHTML]="clickedTeam.biography"></p>

              <!-- <div class="modal-action items-center justify-center m-4">
                  <label (click)="closeAboutModal()"
                      class=" "><i class="material-icons text-white bg-customTeal px-2 py-2 rounded-full">check</i></label>
              </div> -->
          </div>
      </div>
  </div>



        </div>
    </div>

    <div *ngIf="allEmployees && allEmployees.length > 0" class="mt-8 mx-5 lg:mx-16 flex flex-wrap">
        <div class="mt-18 md:mt-8 mx-4 md:mx-2 flex flex-wrap" *ngIf="viewMoreClicked">
            <div class="w-full md:w-1/3 mb-12 " *ngFor="let team of allEmployees">
                <div class="mx-4 md:mx-2 mb-10 flex flex-row  md:h-46 bg-white drop-shadow-md rounded-lg">
                    <div class=" flex flex-col w-full relative items-center space-y-4   px-4  pt-4 py-8 md:px-6 md:py-8 ">
                        <div class="absolute pr-124 -top-10 md:pr-0">
                            <span *ngIf="team && team.profileImgLink"
                                class="inline-flex p-1 items-center justify-center bg-customTeal rounded-full shadow-lg">
                                <img loading="lazy" [src]="team.profileImgLink" class="w-16 h-16 rounded-full" />
                            </span>
                        </div>
                        <h4 class="max-w-sm text-lg font-bold text-center">{{team.GivenName}} {{team.LastName}}</h4>
                        <p class="max-w text-sm text-center font-light text-darkGray">
                            {{truncateHTML(team.biography,60)}}
                        </p>
                        <button type="button"
                            class=" flex items-center w-auto  px-6 pt-1 text-darkBlue font-bold  baseline hover:text-customTeal">Details
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pl-1" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>
                        <a href=""
                            class="px-3 md:px-6 py-1 justify-start text-white  bg-customTeal rounded-md baseline text-xl font-bold bottom-0 translate-y-[50%] absolute md:ml-0">
                            {{team.jobTitle}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!about" class="bg-gray-100 min-h-screen pt-5">
        <div class=" bg-veryLightBlue ">

            <div class=" flex flex-col space-y-5 items-center p-8 md:py-20 md:p-0 pt-12">
                <p class="text-center md:w-2/6 font-bold text-3xl text-customTeal">
                    Going Mobile is business, socials, sales and more, all on a platform built just for you.</p>
                <p class="text-center md:w-2/4">With just three simple steps, you can give your small business
                    or personal brand a competitive edge with Going Mobile. Reach 100% of your community
                    100% of the time with a platform more intuitive and easier to use than any web builder,
                    app builder or social management app on the market.</p>
            </div>

        </div>

        <div class=" md:gap-y-20">
            <!-- Mission -->
            <div class="flex flex-col  pt-12 w-full">
                <div class="flex flex-col md:flex-row md:space-x-12 justify-center ">
                    <div
                        class="flex flex-col text-center items-center md:items-start md:text-left md:w-1/2 space-y-4 h-max  p-4 md:pl-4">
                        <!-- title -->
                        <p class="text-2xl  font-light text-customTeal">Mission</p>
                        <p class="text-darkGray md:w-5/6  text-sm">Going Mobile is business, socials, sales and more,
                            all on
                            a platform built just for you.
                            Going Mobile is business, socials, sales and more, all on a platform built just for you.
                        </p>
                        <ul class="pl-4 md:pl-12 text-left space-y-2 text-sm" style=" list-style-type: square; ">
                            <li>Manage Your Team </li>
                            <li>Streamline Your Socials</li>
                            <li>Know Your Market</li>
                            <li>Share and Book Service</li>
                        </ul>

                    </div>
                    <div class="flex justify-center md:bg-customTeal rounded-lg  space-y-2 p-4">
                        <img loading="lazy" data-src="https://api.lorem.space/image/face?hash=445566"
                            class="object-cover w-96 border-8 h-96 rounded-md" alt="Logo">
                    </div>
                </div>

            </div>

            <!-- Vision -->
            <div class="flex flex-col   pt-12 w-full">
                <div class="flex flex-col-reverse md:flex-row md:space-x-12 justify-center ">
                    <div class="flex justify-center md:bg-customTeal rounded-lg  space-y-2 p-4">
                        <img loading="lazy" data-src="https://api.lorem.space/image/face?hash=445566"
                            class="object-cover w-96 border-8 h-96 rounded-md" alt="Logo">
                    </div>
                    <div
                        class="flex flex-col text-center items-center md:items-start md:text-left md:w-1/2 space-y-4 h-max  p-4 md:pl-4">
                        <!-- title -->
                        <p class="text-2xl  font-light text-customTeal">Vision</p>
                        <p class="text-darkGray md:w-5/6  text-sm">Going Mobile is business, socials, sales and more,
                            all on
                            a platform built just for you.
                            Going Mobile is business, socials, sales and more, all on a platform built just for you.

                        </p>
                        <ul class="pl-4 md:pl-12 text-left space-y-2 text-sm" style=" list-style-type: square; ">
                            <li>Manage Your Team </li>
                            <li>Streamline Your Socials</li>
                            <li>Know Your Market</li>
                            <li>Share and Book Service</li>
                        </ul>

                    </div>

                </div>

            </div>

            <!-- Values -->

            <div class="flex flex-col align-middle w-full items-center mt-12 gap-y-12">
                <p class="text-2xl font-bold text-center text-customTeal pb-24">Values</p>
                <div class="flex flex-wrap justify-center   gap-12 gap-y-32 p-4">
                    <!-- items -->

                    <div
                        class="relative flex flex-col bg-veryLightBlue p-10 rounded-lg max-w-xl space-y-4 items-center">

                        <div class="absolute top-0 -translate-y-24 w-32 h-32 "
                            style='background: url("https://api.goingmobile.app/media/personal-app-assets/img/webp/manuel-cosentino-M3fhZSBFoFQ-unsplash.webp");  background-size: cover;'>
                            <!-- <img src="./img/manuel-cosentino-M3fhZSBFoFQ-unsplash.webp" class="" alt=""> -->
                        </div>
                        <p class="font-bold pt-3">Be The Next</p>
                        <p class="text-sm text-center text-darkGray">"We strive to set the highest standard of quality
                            in
                            everything we do. We always want to drive towards “the NEXT” great thing! By constantly
                            challenging ourselves to improve and break new ground."</p>

                    </div>
                    <!-- items -->

                    <div class="relative flex flex-col bg-veryLightBlue p-10 rounded-lg max-w-xl space-y-4 items-center">

                        <div class="absolute top-0 -translate-y-24 w-32 h-32 "
                            style='background: url("https://api.goingmobile.app/media/personal-app-assets/img/webp/manuel-cosentino-M3fhZSBFoFQ-unsplash.webp");  background-size: cover;'>
                            <!-- <img src="./img/manuel-cosentino-M3fhZSBFoFQ-unsplash.webp" class="" alt=""> -->
                        </div>
                        <p class="font-bold pt-3">Impact</p>
                        <p class="text-sm text-center text-darkGray">
                            "Impact demonstrates the immeasurable power of our shared values and collective effort to
                            effect
                            positive, ground-breaking change and deliver excellence for our customers, colleagues, and
                            communities."</p>

                    </div>





                </div>
            </div>

            <!-- Members -->
            <div class="flex flex-col align-middle w-full items-center mt-12 space-y-12">
                <p class="text-2xl font-bold text-center text-customTeal" *ngIf="!empty">Meet The Team</p>
                <div class="flex flex-wrap justify-center w-10/12 gap-12 md:gap-24">

                    <!-- item -->
                    <div class="flex flex-col items-center space-y-1">
                        <div class="avatar">
                            <div class="w-40 rounded-full">
                                <img loading="lazy" data-src="https://api.lorem.space/image/face?hash=92310" />
                            </div>
                        </div>
                        <p class="font-bold pt-3">Full Name</p>
                        <p class="text-sm text-darkGray">Position</p>
                    </div>

                    <!-- item -->
                    <div class="flex flex-col items-center space-y-1">
                        <div class="avatar">
                            <div class="w-40 rounded-full">
                                <img loading="lazy" data-src="https://api.lorem.space/image/face?hash=92310" />
                            </div>
                        </div>
                        <p class="font-bold pt-3">Full Name</p>
                        <p class="text-sm text-darkGray">Position</p>
                    </div>

                    <!-- item -->
                    <div class="flex flex-col items-center space-y-1">
                        <div class="avatar">
                            <div class="w-40 rounded-full">
                                <img loading="lazy" data-src="https://api.lorem.space/image/face?hash=92310" />
                            </div>
                        </div>
                        <p class="font-bold pt-3">Full Name</p>
                        <p class="text-sm text-darkGray">Position</p>
                    </div>

                </div>
            </div>
        </div>

    </div>

</ng-container>