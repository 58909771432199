<div class="bg-gray-100 min-h-screen pt-6">
  <div  class="flex mt-6 flex-wrap md:px-8 gap-4 w-full  justify-center md:justify-start  items-center" *ngIf="(isAdmin || (isEmployee && permPES && permPES.createEvents)) && !(calledFrom == 'onBoarding')">

      <div >
        <button (click)="routeToCreateEvent()" 
        class="flex items-center space-x-2 rounded bg-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-4 h-4 stroke-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>         
          <span>Add New</span>  
        </button>
      </div>

    <app-rename-section  section="Events"></app-rename-section>
    
     <app-homepage-section-number   section="Events" (confirmationModal) = "openConfirmationModal()"></app-homepage-section-number>


    <div class="">
      <button 
        (click)="toggleEventsView()"
        class="flex space-x-2 items-center bg-white  rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-customTeal">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>
        
        <span *ngIf="viewPastEvents">View latest events</span>  
        <span *ngIf="!viewPastEvents">View past events</span>  
      
      </button>
    </div>

</div>

  <div class="mt-5"  *ngIf="filteredEventsList.length==0">
    <div class="flex items-center justify-center">
    </div> 
    <ng-container *ngIf="isAdmin">
      <div class="text-customTeal text-lg text-center font-bold">Have an upcoming event?</div>
      <div class="text-gray-400 text-sm text-center font-bold">Let the world know!</div>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <div class="text-customTeal text-lg text-center font-bold">No {{eventsName}} found</div>
      <div class="text-gray-400 text-sm text-center font-bold">Let the world know!</div> 
    </ng-container>
  </div>

  <div class="mt-20   flex flex-col  px-8 justify-center " *ngIf="filteredEventsList">
    <div class="flex flex-wrap gap-x-4  w-full  gap-y-20 md:gap-x-6 justify-center  mx-auto   ">
      <div
      *ngFor="let event of filteredEventsList "
      (click)="navigate(event)" class="w-full  md:w-[341px] md:h-[360px]">

        <ng-container *ngIf="event.imgLink">
          <div class="flex  flex-col bg-white rounded-xl md:h-[360px] h-max drop-shadow-md hover:scale-105 hover:ease-in hover:duration-300">
            <div class="w-full  md:h-48 rounded-xl">
              <div class="">
                <div class=" top-0 ml-3 md:ml-6 -mt-9 md:-mt-12 items-center  w-12 md:w-16">
                  <span class="flex items-center justify-center p-2 md:p-3 bg-white border border-customTeal rounded-lg shadow-lg">
                      <div class="flex flex-col">
                          <h5 class="text-base md:text-2xl font-bold  text-customTeal">{{event.eventStartDate | date: 'dd'}}</h5>
                          <h5 class="text-xs md:text-base text-customTeal">{{event.eventStartDate | date: 'MMM'}}</h5>
                      </div>
                  </span>
              </div>
              </div>
              <div class="flex justify-center   md:h-48 " >
                <img [src]="event.imgLink" class="md:w-56 h-full w-full rounded-lg md:object-contain" >
              </div>
            </div>
            
            <div class="p-4 space-y-4 pt-8">
              <div class="w-full md:w-[250px] lg:w-[310px] break-words">
                <h1 class="h-max content-end font-bold first-letter text-xl ">{{truncateHTML(getInnerText(event.eventTitle), 45)}}</h1>
              </div>
              <div class="flex flex-row space-x-1 text-gray-500 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 md:h-4 md:w-4 " viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs md:text-base text-center  ">{{truncateHTML(event.eventPlace, 30)}}</span>
                </div>
                <div class="flex flex-row space-x-1 text-gray-500 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5 fill-gray-500" height="16" width="14" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/></svg>

                     <div class="space-x-2 flex items-center">
                      <div class=" flex space-x-2">
                        <span class="text-xs md:text-base text-center ">{{event.eventStartDate | date: 'EEEE'}},</span>
                        <span class="text-xs md:text-base text-center ">{{event.eventStartDate | date: 'MMM-dd'}}</span>
                       </div>
                       <div class="p-0.5 bg-gray-500  rounded-full h-min"></div>
                        <span class="text-xs md:text-base text-center ">{{event.eventStartTime }} - {{event.eventEndTime}}</span>
                     </div>

                </div>
                

         

            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!event.imgLink" >
         <div class="w-full">
          <div class="flex flex-col rounded-xl w-full bg-white md:h-[360px] drop-shadow-md hover:scale-105 hover:ease-in hover:duration-300">
            <div class=" top-0 ml-3 md:ml-6 -mt-9 md:-mt-12 items-center  w-12 md:w-16">
                <span class="flex items-center justify-center  p-2 md:p-3    bg-white border border-customTeal rounded-lg shadow-lg">
                    <div class="flex flex-col">
                        <h5 class="text-base md:text-2xl font-bold text-customTeal">{{event.eventStartDate | date: 'dd'}}</h5>
                        <h5 class="text-xs md:text-base text-customTeal">{{event.eventStartDate | date: 'MMM'}}</h5>
                    </div>
                </span>
            </div>

            <div class="p-4 space-y-4">
              <div class="w-full md:w-[250px] lg:w-[310px] break-words">
                <h1 class="h-max content-end  font-bold first-letter text-xl ">{{truncateHTML(getInnerText(event.eventTitle), 45)}}</h1>
              </div>
              <div class="flex flex-row space-x-1 text-gray-500 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 md:h-4 md:w-4 " viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs md:text-base text-center f ">{{event.eventPlace}}</span>

                </div>
                <div class="flex flex-row space-x-1 text-gray-500 items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5 fill-gray-500" height="16" width="14" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/></svg>
                      <div class="space-x-2 flex items-center">
                        <div class=" flex space-x-2">
                          <span class="text-xs md:text-base text-center ">{{event.eventStartDate | date: 'EEEE'}},</span>
                          <span class="text-xs md:text-base text-center ">{{event.eventStartDate | date: 'MMM-dd'}}</span>
                         </div>
                         <div class="p-0.5 bg-gray-500  rounded-full h-min"></div>
                          <span class="text-xs md:text-base text-center ">{{event.eventStartTime }} - {{event.eventEndTime}}</span>
                       </div>
                </div>
            </div>
          </div>
         </div>

        </ng-container>
      </div>
    </div>

  </div>

</div>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]"  [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
