import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore){ }

  createProduct(model: any){
    const newContent = {
      id: this.db.createId(),
      createdById: model.createdById,
      productName: model.productName,
      productId: model.productId,
      productDescription: model.productDescription,
      imgLink: model.imgLink,
      videoLink: model.videoLink,
      sharedLink: model.sharedLink,
      shareWithPublic: model.shareWithPublic,
      price: model.price,
      timeStamp: new Date(),
      hasSubProducts: model.hasSubProducts,
      hasPrice: model.hasPrice,
      allProducts: model.allProducts,
      backgroundColor: model.backgroundColor,
      commentable: model.commentable,
      reactions: model.reactions,
      comments: model.comments
    };
    return this.db.collection('Products').doc(newContent.id).set(newContent);
  }


  getProductById(id){
    return this.db.collection('Products', ref => ref.where('id', '==', id));
  }

  getProductsList(){
    return this.db.collection('Products');
  }
  getProductUnderCategory(category){
    return this.db.collection('Products', ref => ref.where('category', '==', category));
  }
  getProductByCategoryId(categoryId){
    return this.db.collection('Products', ref => ref.where('categoryId', '==', categoryId));
  }

  updateProduct(product: any){
    return this.db.collection('Products').doc(product.id).update(product);
  }

  deleteProduct(product: any){
    return this.db.collection('Products').doc(product.id).delete();
  }
}
