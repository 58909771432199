import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageModel } from 'src/app/models/ImageModel';
import { EmailService } from 'src/app/services/api/email.service';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { Location } from '@angular/common';
import { ContactService } from 'src/app/services/firebase/contact.service';

@Component({
  selector: 'app-edit-subscriber',
  templateUrl: './edit-subscriber.component.html',
  styleUrls: ['./edit-subscriber.component.css']
})
export class EditSubscriberComponent implements OnInit {

  currentUser: any;
  loggedInUser: any;
  id: any;
  //subscriberEditForm: FormGroup;
  userGlobal: any;

  //image
  // mediaType: any;
  // binDoc = undefined;
  // base64Image = undefined;
  // mobileBase64Image: any;
  // isMobile = false;
  model: any;
  // imgURL: any;
  // imgErrorMsg: any;
  // pdfErrorMsg: any;
  // profileImgLink: any;

  //toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  subscriberGlobal: any;
  User: any;

  updateModal: boolean = false;
  requiredChecked: boolean = false;

  // existing form fields
  existingFormFields: any = null;
  formFieldName: any = null;
  selectedFieldForEdit: any = null;

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public extensionMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];


  //for clients

  //model: any;
  editedModel: any;
  surveyId = '';
  surveyForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  questionArray: UntypedFormArray;
  answersArray: UntypedFormArray;
  questionNumber = 1;
  showMultiAnswer = false;
  answerTypes: any = [
    { 'type': 'Open Ended', 'helpText': 'Allows participants to answer in open text format' },
    //{ 'type': 'Short Answer', 'helpText': 'Allows participants to answer in open text format' },
    //{ 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' }
    { 'type': 'Date', 'helpText': 'Displays a calander for the client to choose from' },
    // { 'type': 'Long Answer', 'helpText': 'Allows participants to answer in open text box format'},
    { 'type': 'Number', 'helpText': 'Allows participants to answer in open Number format' },
    { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the client can choose from' },
    //{ 'type': 'Address', 'helpText': 'Displays a list of address the client can choose from' },

  ];
  selectedField = null;
  modalTitle = "";

  scales = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  numberOfAnswers: any = ['2', '3', '4'];
  showSurveyResult = false;
  step1 = true;
  step2 = false;
  step3 = false;
  createPoll = false;

  //for pop up
  updateFormPopup: boolean = false;

  deleteQuestion: boolean = false;
  currentIndex: number;
  currentAnswer: number;
  clientGlobal: any;
  deleteMultipleQuestion: boolean = false;

  showModal: boolean = false
  baseFields : any = [
    {
      name: 'First Name',
      type: 'text',
      category: 'text',
      placeholder: 'Jane',
      key: 'GivenName'
    },
    {
      name: 'Last Name',
      type: 'text',
      category: 'text',
      placeholder: 'John',
      key: 'LastName'
    },
    {
      name: 'Phone Number',
      type: 'text',
      category: 'phone',
      placeholder: '000-000-0000',
      key:'cellPhone'
    },
    {
      name: 'Email',
      type: 'text',
      category: 'email',
      placeholder: 'Jane@gmail.com',
      key:'Email'
    },
  ]

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private clientFormService: ClientFormService,
    private contactService: ContactService,
    private inviteService: InviteService,
    private emailService: EmailService,
    private mediaService: MediaService,
    private route: ActivatedRoute,
    private location: Location) { }

  get skills(): UntypedFormArray { return this.skillsForm.get("skills") as UntypedFormArray }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.currentUser = this.authService.getGlobalUser();

    this.skillsForm = this.fb.group({
      skills: this.fb.array([]),
    });

    this.clientGlobal = this.clientFormService.getFormList().valueChanges().subscribe(form => {
      if (form) {
        this.model = form;
      }
      this.editedModel = {
        id: this.model[0].id,
        numberOfQuestions: this.model[0].numberOfQuestions,
        questions: [],
        datePosted: new Date(),
      };

      this.model[0].questions.forEach(question => {
        this.addQuestionFromModel(question);
      });

      this.clientGlobal.unsubscribe();
    })

    // fetch contact headers
    this.fetchContactHeaders();

  }

  getAnswers(index) {
    const questions = this.skillsForm.get('skills') as UntypedFormArray;
    return questions.controls[index].get('answers') as UntypedFormArray;

  }

  getScales(index) {
    const questions = this.skillsForm.get('skills') as UntypedFormArray;
    return questions.controls[index].get('scales');
  }

  addAnswers() {
    return this.fb.group({
      answer: ['', [Validators.required]]
    });
  }

  onAddAnswer(index) {
    const answers = this.getAnswers(index);
    answers.push(this.addAnswers());
  }

  submitSurveyInfo(value: any) {
    this.editedModel = {
      id: this.model.id,
      numberOfQuestions: this.model.numberOfQuestions,
      questions: [],
      adminID: this.currentUser.uid,
      adminName: this.currentUser.GivenName + " " + this.currentUser.LastName,
      datePosted: new Date(),
    };

    this.model.questions.forEach(question => {
      this.addQuestionFromModel(question);
    });

    this.step1 = false;
    this.step2 = true;

  }

  addQuestionFromModel(question: any): void {
    this.questionNumber += 1;
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    this.questionArray.push(this.createQuestionFromModel(question));
  }

  createQuestionFromModel(question: any) {
    let answers = this.fb.array([]);

    // const scales =  this.fb.group({
    //   start: [""],
    //   startLabel: [],
    //   end: [""],
    //   endLabel: []
    // });

    if (question.answerType === 'Multiple Choice') {
      question.answers.forEach(a => {
        answers.push(this.addAnswersFromModel(a.answer));
      });
    }
    // else if(question.answerType === 'Scale'){
    //   scales.get('start').patchValue(question.scales.start);
    //   scales.get('startLabel').patchValue(question.scales.startLabel);
    //   scales.get('end').patchValue(question.scales.end);
    //   scales.get('endLabel').patchValue(question.scales.endLabel);
    // }

    this.requiredChecked = question.required;
    return this.fb.group({
      question: [question.question, [Validators.required]],
      answerType: [question.answerType, [Validators.required]],
      allowMultipleAnswers: [question.allowMultipleAnswers],
      answers: answers,
      required: [question.required],

    });
  }

  addAnswersFromModel(value) {
    return this.fb.group({
      answer: [value, [Validators.required]]
    });
  }

  addQuestion(): void {
    this.questionNumber += 1;
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    this.questionArray.push(this.createQuestion());

  }

  createQuestion() {
    let questionFormGroup = this.fb.group({
      question: ['', [Validators.required]],
      answerType: ['', [Validators.required]],
      answers: this.fb.array([]),
      allowMultipleAnswers: [false],
      required: [false],
    });
    return questionFormGroup
  }

  removeFile(index: any) {
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    this.questionArray.removeAt(index);
    this.closeDeleteModal();
  }

  removeQuestion(index: any) {
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    this.questionArray.removeAt(this.currentIndex);
    this.closeDeleteModal();
  }
  answerTypeChange(e: any, index: any) {
    this.resetControls(index);
    const answers = this.getAnswers(index);

    if (e === 'Multiple Choice') {
      if (answers.length < 2) {
        answers.push(this.addAnswers());
        answers.push(this.addAnswers());
      }
    } else if (e === 'Scale') {
      this.getScales(index).get('start').patchValue('1');
      this.getScales(index).get('end').patchValue('10');
    } else if (e == 'Open Ended') {

    } else { }


  }

  resetControls(index) {
    const answers = this.getAnswers(index);
    while (answers.length !== 0) {
      answers.removeAt(0);
    }
    const scales = this.getScales(index);
    //answers.reset();
    answers.clearValidators();
    answers.updateValueAndValidity();
    // scales.reset();
    // scales.clearValidators();
    //scales.updateValueAndValidity();
  }

  removeAnswer(question_index, answer_index) {
    const answers = this.getAnswers(question_index);
    answers.removeAt(answer_index);
  }

  removeAnswers(question_index, answer_index) {
    const answers = this.getAnswers(this.currentIndex);
    answers.removeAt(this.currentAnswer);
    this.closeMultiDeleteModal()
  }

  submitQuestions(value: any) {
    this.editedModel.questions = [];

    let qNumber = 0;
    value.skills.forEach(element => {
      qNumber += 1;

      const question = {
        questionNumber: qNumber,
        question: element.question,
        answerType: element.answerType,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers,
        required: element.required
      };

      // if(element.answerType === 'Scale'){
      //   // var items: number[] = [];
      //   for (var i = element.scales.start; i <= element.scales.end; i++) {
      //     element.answers.push({'answer': i.toString()});
      //   }
      // }

      this.editedModel.questions.push(question);

    });

    this.editedModel.numberOfQuestions = this.editedModel.questions.length;
    this.clientFormService.updateForm(this.editedModel).then(x => {
      //this.location.back();
      this.toast({ html: 'Client Information Form Successfully updated!', classes: 'green', type: 'success' });
      this.cancelUpdate();
      this.router.navigate(['/admin/edit-subscriber']);

    })
    this.toast({ html: 'Client Information Form Successfully updated!', classes: 'green', type: 'success' });

    // setTimeout(() => {
    //   this.location.back();
    // },100)
    //this.router.navigate(['/admin/create-client']);

  }

  openUpdateModal() {
    this.updateModal = true;
    setTimeout(() => {
      this.updateModal = false;
      this.router.navigate(['/admin/search-users']);

    }, 2000);

  }
  openUpdate() {
    this.updateFormPopup = true;
  }
  cancelUpdate() {
    this.updateFormPopup = false;
  }

  toggleRequired(event) {
    this.requiredChecked = !this.requiredChecked;
  }
  openDeleteModal(i) {
    this.deleteQuestion = true;
    this.currentIndex = i;
  }
  closeDeleteModal() {
    this.deleteQuestion = false;
  }
  openMultiDeleteModal(i, j) {
    this.currentIndex = i;
    this.currentAnswer = j;
    this.deleteMultipleQuestion = true
  }
  closeMultiDeleteModal() {
    this.deleteMultipleQuestion = false;
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }

  submitFieldType(event: any) {
    // check if the user wants to edit 
    if (this.selectedFieldForEdit) {
      // iterate over the existing headers and grab the index
        this.updateContactsHeader({
          type: this.selectedFieldForEdit?.type,
          value: this.formFieldName,
          name: this.selectedFieldForEdit?.name,
          key: this.selectedFieldForEdit?.key
        })
    }
    else {
      this.saveContactsHeader({
        type: this.selectedField?.type,
        value: this.formFieldName,
        name: this.selectedField?.label,
        key: this.selectedField?.key
      })
    }
  }

  openModal(selectedField) {
    this.resetFields();
    this.selectedField = {
      label: selectedField?.name,
      value: selectedField?.value,
      type: selectedField?.category,
      key: selectedField?.key
    };
    this.showModal = true;
    this.modalTitle = "Add field name for `" + this.selectedField?.label + "`"
  }

  // open edit modal
  openEditModal(selectedField) {
    this.resetFields();
    // iterate over the already added selected fields
    let index = this.existingFormFields.headers.findIndex((item) => item?.name === selectedField?.name);
    if (index != -1) {
      this.selectedFieldForEdit = this.existingFormFields.headers[index];
      this.formFieldName = this.selectedFieldForEdit?.value;
    }
    this.modalTitle = "Update field name for `" + this.selectedFieldForEdit?.name + "`"
    this.showModal = true;
  }

  // reset fields
  resetFields(){
    this.selectedField = null;
    this.selectedFieldForEdit = null;
    this.modalTitle = ""
    this.formFieldName = null
  }

  closeModal() {
    this.showModal = false;
  }


  // fetch existing contact headers
  fetchContactHeaders() {
    // this.showLoader();
    this.contactService.getContactHeaderNew().subscribe((querySnapshot) => {
      let contacts = [];
      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() });
      });
      this.existingFormFields = contacts?.[0];
      // iterate over the header and check if the fields have field name which is going to be used while importing
      for(let i = 0; i < this.baseFields?.length; i++)
      {
        if (this.existingFormFields?.headers?.length) {
          let index = this.existingFormFields.headers.findIndex((item) => item?.name === this.baseFields[i]?.name);
          if (index != -1) {
            this.baseFields[i]['active'] = true
          }
        }
      }
      // this.hideLoader();
    }, (err) => {
      // this.hideLoader();
    });
  }

  // add new contact headers

  saveContactsHeader(currentFormField) {
    // check if we have existing contact headers , if not add it

    if (this.existingFormFields) {

      let headerData = {
        uid: this.existingFormFields?.id,
        headers: [...this.existingFormFields?.headers, currentFormField]
      };

      this.contactService.updateContactHeaderNew(headerData).then((response) => {

        this.fetchContactHeaders();

        this.closeModal();



      }).catch((err) => {
      })

    }
    else {
      let headerData = {
        uid: this.contactService.createID(),
        headers: [currentFormField]
      };


      this.contactService.saveContactHeaderNew(headerData).then((response) => {
        this.fetchContactHeaders();
        this.closeModal();

      }).catch((err) => {
      })
    }


  }


  updateContactsHeader(currentFormField) {
    // check if we have existing contact headers , if not add it

    if (this.existingFormFields) {

      let fieldIndex = this.existingFormFields?.headers?.findIndex((item) => item?.name === currentFormField?.name);

      if (fieldIndex != -1) {
        this.existingFormFields.headers[fieldIndex] = currentFormField;
      }

      let headerData = {
        uid: this.existingFormFields?.id,
        headers: [...this.existingFormFields?.headers]
      };

      this.contactService.updateContactHeaderNew(headerData).then((response) => {

        this.fetchContactHeaders();

        this.closeModal();

      }).catch((err) => {
      })
    }
  }

  // check field existance
  checkFieldExistance(fieldName) {
    let fieldExist = false;
    if (this.existingFormFields?.headers?.length) {

      let index = this.existingFormFields.headers.findIndex((item) => item?.name === fieldName);

      if (index != -1) {
        fieldExist = true;

      }

    }
    return fieldExist;
  }




  // get firstName() { return this.subscriberEditForm.get('firstName'); }
  // get lastName() { return this.subscriberEditForm.get('lastName'); }
  // get email() { return this.subscriberEditForm.get('email'); }
  // get cellPhone() { return this.subscriberEditForm.get('cellPhone'); }
  // get DOB() { return this.subscriberEditForm.get('DOB'); }
  // get imgLink() { return this.subscriberEditForm.get('imgLink'); }

}