<div class="modal modal-open">
    <div class="modal-box max-w-6xl rounded-md flex flex-col justify-center">
        <h3 class="font-bold text-lg text-center mb-2">{{modalTitle}}</h3>

        <!-- Modal Body -->
        <div class="modal-body">
            <ng-content></ng-content> <!-- Content from parent goes here -->
        </div>

        <div class="modal-action items-center justify-between">
            <label (click)="cancelClicked()"
                class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md">
                Cancel
            </label>
            <label>
                <button
                    class="font-bold p-2 text-customTeal hover:border-customTeal hover:rounded-md hover:font-normal hover:border-2 hover:p-2"
                    type="button" (click)="buttonClicked()">{{btnName}}</button>
            </label>
        </div>
    </div>
</div>