import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {

    @Input() customConfig
    @Input() disabled 

    config={
        "style":"py-2 px-6 bg-customTeal text-white rounded-md font-bold ",
        "secondaryStyle":" py-2 text-base text-customTeal font-bold",
        "customStyle":"",
        "text":"Button",
        "type":"button",
        "buttonType": ""
    } 
    
    
    constructor( ) { }

    ngOnInit(): void {
        if(this.customConfig){
            Object.keys(this.customConfig).map(key=>{
                if( !["text","type", "buttonType"].includes(key)) {
                    this.config[key]  = this.config[key] + " " + this.customConfig[key]
                }
                else{
                    this.config[key]=this.customConfig[key]

                }
            })
        }
       
    }
}
