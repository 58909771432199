
<div class="bg-gray-100 min-h-screen pt-2 max-h-max">
  <div *ngIf="event" class=" md:mx-auto gap-4   p-6" [ngClass]="{ 'w-full flex flex-col space-y-4 ': modelToPreview && view=='mobile', 'flex flex-col md:flex-row md:max-w-7xl': !modelToPreview || (modelToPreview && view=='web')}" >
    
      <!-- post reaction -->
    <div *ngIf="event.commentable && !modelToPreview"  class="hidden md:flex md:w-1/12   md:min-w-min gap-y-4   md:gap-y-8 md:flex-col">
      <div class="flex justify-center  h-max p-3  ">
        <div class="flex w-max md:flex-col fixed md:justify-evenly  space-x-12 md:space-x-0   md:gap-y-12 items-center md:bg-none md:border-0 md:p-0  border p-3   mb-10 px-6 rounded-full">
          <ng-container *ngTemplateOutlet="likeCommentCount;">
          </ng-container>
        </div>
      </div>
    </div>
    
    <!-- Image and event title -->
    <div class="gap-y-6  rounded-md " [ngClass]="{'w-2/3': modelToPreview && view=='web', 'w-full ': modelToPreview && view=='mobile', 'md:w-2/3 w-full': !modelToPreview}">
      <div class="bg-white flex rounded-md flex-col w-full">
          <div class="w-full px-4 md:px-16 py-8 space-y-4   rounded-md ">
              <div class="flex justify-between text-base md:text-2xl font-bold ">
                <div class="w-full break-words">
                  <span [innerHTML]="event.eventTitle | noSanitize"></span>
                          </div>
                <div class="float-right text-base md:text-xl font-bold" *ngIf="currentUser">
                  <div class="dropdown dropdown-end z-50" *ngIf="currentUser.role=='Admin' || (permPES && (permPES.editEvents || permPES.deleteEvents))">
                    <label tabindex="0" class="m-1"><i class="material-icons gray-text-600">more_vert</i></label>
                    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-white rounded-box w-52 font-normal">
                      <li *ngIf="currentUser.role=='Admin' ||(permPES && permPES.editEvents)" (click)="navigateEditEvent(event)" ><a><i class="material-icons gray-text-600">edit</i>Edit</a></li>
                      <li (click)="openDeleteModal()" *ngIf="currentUser.role=='Admin' ||(permPES && permPES.deleteEvents)"><a><i class="material-icons gray-text-600">delete</i>Delete</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div *ngIf="!event.videoLink || event.videoLink==''">
                <div class="flex flex-col space-y-2 " *ngIf="event.imgLink && event.sharedLink">
                  <a [href]="event.sharedLink" target="_blank" class="hover:cursor-pointer">  
                    <img loading="lazy" [src]="event.imgLink" class="object-cover w-full rounded-md" alt="Event Image" >
                  </a>
                </div>
                <div class="flex flex-col space-y-2 " *ngIf="event.imgLink && !event.sharedLink">
                  <img loading="lazy" [src]="event.imgLink" class="object-cover w-full rounded-md" alt="Event Image">
                </div>
              </div>
              <div *ngIf="event.videoLink && event.videoLink!=''">
                <ng-container *ngIf="!event.imgLink">
                  <video autoplay controls *ngIf="event.videoLink != ''" [src]="event.videoLink" class="object-cover" alt="Event Image"></video>
                </ng-container>
                <ng-container *ngIf="event.imgLink">
                  <video autoplay controls *ngIf="event.videoLink != ''" [src]="event.videoLink" class="object-cover" alt="Event Image" [poster]="event.imgLink"></video>
                </ng-container>
              </div>
          </div>
        <!-- Event Description -->
        <div class="flex flex-col space-y-4  md:space-y-10">
            <div class=" bg-white w-full px-4 md:px-16 py-6   rounded-md break-words">
              <span [innerHTML]="event.description | noSanitize"></span>
            </div>
        </div>

        <div *ngIf="event.sharedLink" class="mx-5">
          <br/>
          <p class="font-bold text-darkGray mb-4">Additional link</p>
          <a target="_blank" [href]="event.sharedLink" class="underline text-blue-500">{{event.eventTitle}}</a>
        </div>


      </div>

      <div *ngIf="event.commentable && currentUser">
        <app-post-comment [post]="event"
        [sectionName]="eventsName"
        postType="event"
       (showCommentDeletedToast)="openDeleteToast()"
        >
        </app-post-comment>
      </div>
    </div>

    <!-- Event Detail sider -->
    <div class="flex flex-col    space-y-4 md:gap-y-8 md:flex-col " [ngClass]="{'w-1/3 ': modelToPreview && view=='web', 'w-full ': modelToPreview && view=='mobile', 'md:w-1/3 w-full': !modelToPreview}">
      <div class="h-max bg-white p-4 space-y-6 rounded-md md:p-8 shadow-sm">
          <div class=" space-y-4 ">

            <div class="flex justify-end" *ngIf="!itIsFull">
              <button type="button" (click)="register()" class="py-2 px-10 modal-button gap-2 w-max  bg-customTeal rounded-lg text-white font-bold" *ngIf="(event && event.registerRequired) && !currentUser">Create an account to Register</button>
              <button type="button" (click)="confirmRegistration()" class="py-2 px-10 modal-button gap-2 w-max  bg-customTeal rounded-lg text-white font-bold" *ngIf="(event && event.registerRequired) && (currentUser && currentUser.role === 'User') && !cancelRegistration">Register</button>
              <button type="button" (click)="confirmCancelRegistration()" class="py-2 px-10 modal-button gap-2 w-max  bg-red-500 rounded-lg text-white font-bold" *ngIf="(event && event.registerRequired) && (currentUser && currentUser.role === 'User') && cancelRegistration">Cancel Registration</button>
              <!-- <button type="button" (click)="cancelRegister()" class="py-2 px-10 modal-button gap-2 w-max  bg-red-500 rounded-lg text-white font-bold" *ngIf="(event && event.registerRequired) && (currentUser && currentUser.role === 'User') && cancelRegistration">Cancel Register</button> -->

            </div>
            <app-modal *ngIf="registrationConfirmed" modalTitle="Are you sure you want to register to this event?" btnName="Yes" (btnClicked)="registerUser()" (cancelBtnClicked)="registrationConfirmed=false"></app-modal>
            <app-modal *ngIf="registrationCancelled" modalTitle="Are you sure you want to cancel registration to this event?" btnName="Yes" (btnClicked)="cancelRegister()" (cancelBtnClicked)="registrationCancelled=false"></app-modal>

            <div class="flex justify-end" *ngIf="itIsFull">
              <button type="button" (click)="cancelRegister()" class="py-2 px-10 modal-button gap-2 w-max  bg-red-500 rounded-lg text-white font-bold" *ngIf="(event && event.registerRequired) && (currentUser && currentUser.role === 'User') && cancelRegistration">Cancel Register</button>
              <button type="button" class="py-2 px-10 modal-button gap-2 w-max  bg-gray-400 rounded-lg text-white font-bold"  *ngIf="(event && event.registerRequired) && (currentUser && currentUser.role === 'User') && !cancelRegistration">No Registration</button>
            </div>

            <div class="flex space-x-2 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 fill-darkGray" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
              </svg>
              <p class="    text-base  text-darkGray ">
                Date and Time 
             </p>
            </div>
              <hr>
              <div class="flex flex-wrap p-2.5 md:mt-4 border-2 items-center gap-x-4 border-gray-300 rounded-xl bg-gray-100 md:w-full  md:gap-x-12 md:px-6">
                <div class="flex flex-col border-r pr-8  border-solid border-gray-300">
                  <h5 class="">{{event.eventStartDate | date: 'MMM'}}</h5>
                  <h5 class="text-xl font-bold text-customTeal">{{event.eventStartDate | date: 'dd'}}</h5>
                </div>
                <div class="flex flex-col ">
                  <h5 class=" ">{{event.eventStartDate | date: 'EEEE'}}</h5>
                  <h5 class="font-bold">{{event.eventStartTime}} - {{event.eventEndTime}}</h5>
                </div>
              </div>

              <!-- Price -->
              <div class="space-y-2 pt-4" *ngIf="!(event.price == 'dontInclude')">
                <div class="flex space-x-2 items-center" *ngIf="!(event.price=='')">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-darkGray" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                  </svg>
                  <p class=" text-base  text-darkGray ">
                    Entrance Fee 
                 </p>
                </div>
                <hr>
                  <div class=" flex flex-row  items-center">
                    <div class="">
                      <h4 class=" text-base font-bold text-customTeal " *ngIf="event.price !='free' && event.price !='dontInclude' && event.price !=''">
                        {{event.price | currency: currency:'symbol-narrow'}}
                      </h4>
                      <h4 class=" text-base font-bold text-customTeal " *ngIf="event.price == 'free' || event.price==''">
                        Free
                      </h4>
                    </div>
                  </div>
                
              </div>

              <!-- Location -->
              <div class="space-y-2 pt-2">
                <div class="flex space-x-2 items-center" *ngIf="!(event.price=='')">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-darkGray" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                  </svg>
                  <p class=" text-base  text-darkGray ">
                    Location
                 </p>
                </div>
                <hr>
                  <div class=" flex flex-row  items-center">                     
                      <div class=" w-full">
                          <h4 class=" text-base break-words font-bold text-customTeal">
                            {{event.eventPlace | titlecase}}
                          </h4>
                      </div>
                  </div>
              </div>


              
              <div class="flex" *ngIf="userNumber && currentUser.role === 'Admin'">
               <p class="font-bold"> Number of User Registered : <p> <p class="text-customTeal font-bold text-lg">{{userNumber}}<p>
              </div>
              <div *ngIf="userNumber && currentUser.role === 'Admin'" class="text-blue-500 underline font-bold cursor-pointer" (click)="viewSubs = true">View Registered Subscribers</div>


              <!-- <div class="flex justify-end">
                <button (click)="showOnMap()" *ngIf="event.eventPlace != 'Online'" class="bg-customTeal px-4 py-2 text-white">Show Map</button> 
              </div> -->

              <!-- Reservation  -->
              <!-- <div>
                <hr>
                <div>

                    <p class=" text-xs text-darkGray font-bold ">
                        Reserve
                    </p>
                    <div class="flex justify-between items-center pt-4">

                        <h5 class="text-sm font-bold">Number of people</h5>
                        <form class="flex text-center h-10 ">
                            <div class="border-2 border-gray-300  rounded-l-md w-10 p-2 bg-gray-200 font-bold hover:cursor-pointer" id="decrease" (click)="decreaseReserveCount()" value="Decrease Value">-</div>
                            <input type="number" id="number" class="w-10 border-y-2 text-center" [(ngModel)]="reserveCount" [ngModelOptions]="{standalone: true}" />
                            <div class=" w-10 p-2 text-white rounded-r-md bg-customTeal font-bold hover:cursor-pointer" id="increase" (click)="increaseReserveCount()" value="Increase Value">+</div>
                        </form>
                    </div>

                </div>


                <div class=" flex  md:pt-6 md:justify-end md:visible">
                    <button class=" px-6 py-2 bg-customTeal text-white rounded-lg text-md font-bold hover:bg-darkBlue focus:outline-none  ">
                        reserve
                    </button>
                </div>

              </div> -->


          </div>
      </div>
      <div *ngIf="event.pdfLink" class="h-max bg-white p-4 space-y-6 rounded-md md:p-8">
        <div class="space-y-4">
          <div>
            <!-- if video -->
            <!-- <div *ngIf="event.videoLink && event.videoLink!=''">
              <video controls *ngIf="event.videoLink != ''" [src]="event.videoLink" class="object-cover" alt="Logo"></video>
            </div> -->
            <!-- if PDF -->
            <div class="mt-4" *ngIf="event.pdfLink && event.pdfLink!=''" >
              <div *ngIf="event.pdfLink" class="font-bold text-darkGray mb-4">
                Shared Document
              </div>
              <div class=" text-white">
                <div class=" bg-customTeal p-1">
                  <a class="download-icon" [href]="event.pdfLink" target="_blank" download>
                    <span class="material-icons float-left mr-2">
                      feed
                    </span>
                    <span>Open File</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #likeCommentCount>
  <!-- clap -->
  <div class="flex flex-row items-center space-x-1 ">
      <button (click)="reactToPost(event.id)" class="hover:cursor-pointer" 
      [ngClass]="{'hover:cursor-pointer': currentUser && currentUser != null}" [disabled]="!currentUser || currentUser == null">
        <!-- <div *ngIf="checkIfReactionGiven(event.id); then liked; else notLiked">   </div> -->
      <span>
        <i class="fa fa-heart text-xl" [ngClass]="{'text-red-700': eventLiked, 'hover:text-red-700': (currentUser && currentUser != null) &&  !eventLiked}"></i>
        {{event.reactions.likes.count}}
      </span>
    </button>
      <!-- <span class="text-xs text-center ">{{event.reactions.likes.count}}</span> -->
  </div>
  <!-- comment -->
  <div class="flex flex-row items-center   space-x-1  ">
    <!-- onclick="location.href='#commentsContainer'"  -->
      <button (click)="scrollToComments()"
       type="button">
              <svg xmlns="http://www.w3.org/2000/svg " class="h-6 w-6 " fill="none " viewBox="0 0 24 24 " stroke="currentColor " stroke-width="2 ">
                  <path stroke-linecap="round " stroke-linejoin="round " d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z " />
                </svg>

          </button>
      <span class="text-xs text-center ">{{event.comments.length}}</span>
  </div>
</ng-template>


<app-modal *ngIf="viewSubs" (cancelBtnClicked)="closeViewSubs()" >
  <div class="flex flex-col bg-gray-200" *ngIf="userIds && userIds.length != 0">
    <div class="flex flex-row items-center p-2" *ngIf="userIds.length > 0">
      <div class="w-7/12 space-x-4 lg:w-4/12">
        <p class="pl-8 text-sm">Name</p>
      </div>
      <div class="hidden w-3/12 pl-3 lg:block ">
        <p class="text-sm ">Email</p>
      </div>
      <div class="hidden w-3/12 lg:block">
        <p class="text-sm ">Phone Number</p>
      </div>
      <div class="hidden w-3/12 lg:block">
        <p class="text-sm ">Last Login</p>
      </div>
      <div class="w-2/12 lg:w-1/12">

      </div>

      <div class="w-1/12">
      </div>
    </div>
  </div>

    <!-- The list of the subscribers -->
    <div class="flex items-center justify-center w-full space-x-4 "
    *ngFor="let item of subscribers | filter:search_text ; let i=index">

    <!-- The list of the subscribers -->
    <div class="w-full">
      <div (click)="viewSubscriberProfile(item)"
        class="flex flex-row items-center w-full p-2 bg-white border-2 cursor-pointer md:space-x-6">
        <div class="flex w-7/12 lg:w-4/12">
          <div class="flex items-center space-x-3">
            <div class="form-check">

            </div>
            <div class="avatar placeholder">
              <div class="w-10 h-10 border-2 rounded-full mask mask-squircle" *ngIf="item.profileImgLink">
                <img loading="lazy" [src]="item.profileImgLink">
              </div>
              <div
                class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
                *ngIf="!item.profileImgLink">
                <span class="font-medium text-gray-600 dark:text-gray-300">{{item.GivenName[0] | uppercase}}</span>
              </div>
              <!-- <div class="w-10 h-10 rounded-full bg-neutral-focus text-neutral-content " >
            <span class="text-2xl"></span>
          </div> -->
            </div>
            <div>
              <div class="text-base font-bold"> {{item.GivenName | titlecase}} {{item.LastName | titlecase}}</div>
            </div>
          </div>
        </div>
        <div class="hidden w-3/12 lg:block">
          <p class="text-base text-gray-500" *ngIf="item.email != ''">{{item.Email}}</p>
          <p class="text-base text-gray-500" *ngIf="item.email == ''">{{item.cellPhone}}</p>

        </div>
        <div class="hidden w-3/12 lg:block">
          <p class="text-base text-gray-500">{{item.cellPhone}}</p>

        </div>
        <div class="hidden w-3/12 lg:block">
          <p class="text-base text-gray-500" *ngIf="item.DateAdded">{{item.DateAdded.toDate() | date:'medium'}}</p>
        </div>
        <div class="w-2/12 lg:w-1/12">

          <label for="my-modal-enableE" *ngIf="item.contact?.includes('SMS') && !(item.contact?.includes('Email'))"
            class="pl-2 text-yellow-300 modal-button"> <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
          </label>

          <label for="my-modal-enableE" *ngIf="!(item.contact?.includes('SMS')) && item.contact?.includes('Email')"
            class="pl-2 text-yellow-300 modal-button"> <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
          </label>

          <label for="my-modal-enableE" *ngIf="item.contact?.includes('SMS') && item.contact?.includes('Email')"
            class="pl-2 text-green-500 modal-button"> <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
          </label>

          <label for="my-modal-enableE" *ngIf="!(item.contact?.includes('SMS')) && !(item.contact?.includes('Email'))"
            class="pl-2 text-red-500 modal-button"> <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
          </label>

        </div>
        <div class="w-1/12 lg:1/12">
          <button (click)="viewSubscriberProfile(item)" type="button">
            <i class="float-left text-gray-400 material-icons material-symbols-outlined">arrow_circle_right </i>
          </button>
        </div>
      </div>
      <hr>
    </div>

    <!-- If there is no subscribers -->
    <!-- <div *ngIf="subscribers">
      <div *ngIf="subscribers.length == 0" class="">
        <div class="flex items-center justify-center">
          <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
            class="flex items-center justify-center block w-64">
        </div>
        <div class="text-lg font-bold text-center text-customTeal">There are no {{search_text}} {{isNationApp ?
          'Members'
          : 'Subscribers'}} registered.
        </div>
      </div>
    </div> -->
  </div>
</app-modal>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteEvent() (cancelDeleteClicked)="deleteModalOpen=false" [deletedFromComponent]=eventsName></app-delete-confirmation>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<app-locate-map *ngIf="showMap" (close)="donotShowMap()" [component] = "'event'" [viewLat]="event.latitude" [viewLong]="event.longitude"></app-locate-map>