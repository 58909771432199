<app-firebase-loading *ngIf="loading"></app-firebase-loading>



<!-- Web View -->
<div  class=" md:px-10 md:mx-auto  mt-4 flex md:flex-row flex-col gap-x-4" >
<!-- setting option -->
  <div class="flex flex-col   w-full  space-y-6" >
    <!-- side menu -->
    <div class=" w-full  bg-white shadow-sm flex flex-wrap gap-x-8 p-4 gap-y-2 h-min rounded-sm ">
      <!-- Page View -->
      <div class="cursor-pointer items-center rounded-md p-2 group flex space-x-2 hover:bg-gray-100 group-hover:bg-gray-100" (click)="sideMenu = 'pageView'"
      [ngClass]="{'bg-customTeal ':sideMenu == 'pageView'}"
      >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="group-hover:stroke-black hover:stroke-black" [ngClass]="{'stroke-white group-hover:stroke-black':sideMenu == 'pageView'}" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
      <p class="group-hover:text-black" [ngClass]="{'text-white font-bold ':sideMenu == 'pageView'}" >Page View</p>
      </div>
      <!-- List View Setup -->
      <div class="cursor-pointer items-center rounded-md p-2 group flex space-x-2 hover:bg-gray-100 group-hover:bg-gray-100" (click)="sideMenu = 'section'"
      [ngClass]="{'bg-customTeal ':sideMenu == 'section'}">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="group-hover:stroke-black hover:stroke-black" [ngClass]="{'stroke-white group-hover:stroke-black':sideMenu == 'section'}" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
      </svg>
       
      <p class="group-hover:text-black" [ngClass]="{'text-white font-bold ':sideMenu == 'section'}" >List View </p>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-4 h-4 stroke-green-500" [ngClass]="{ 'hidden': landingPageView[pageView] == 'card' ,'stroke-white group-hover:stroke-green-500':sideMenu == 'section'}" >
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg>
      
      </div>
      <!-- Card View Setup -->
      <div class="cursor-pointer items-center rounded-md p-2 group flex space-x-2 hover:bg-gray-100 group-hover:bg-gray-100" (click)="sideMenu = 'appearance'"
      [ngClass]="{'bg-customTeal ':sideMenu == 'appearance'}">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="group-hover:stroke-black hover:stroke-black" stroke="currentColor" [ngClass]="{'stroke-white group-hover:stroke-black':sideMenu == 'appearance'}"  class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
      </svg>
        <p class="group-hover:text-black" [ngClass]="{'text-white font-bold ':sideMenu == 'appearance'}">Card View </p>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-4 h-4 stroke-green-500" [ngClass]="{ 'hidden': landingPageView[pageView] == 'list' ,'stroke-white group-hover:stroke-green-500':sideMenu == 'appearance'}" >
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        
      </div>
      <!-- Rearrange Section -->
      <!-- <div class="cursor-pointer items-center rounded-md p-2 flex space-x-2 group hover:bg-gray-100 group-hover:bg-gray-100 hover:stroke-black"  (click)="sideMenu = 'rearrange'"
      [ngClass]="{'bg-customTeal ':sideMenu == 'rearrange'}">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="group-hover:stroke-black" stroke-width="1.5" stroke="currentColor" [ngClass]="{'stroke-white group-hover:stroke-black':sideMenu == 'rearrange'}" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
      </svg>
      
        <p class="group-hover:text-black" [ngClass]="{'text-white font-bold ':sideMenu == 'rearrange'}">Rearrange Cards</p>
      </div> -->

    </div>
    <div class=" h-max">
      <div *ngIf="sideMenu == 'pageView'"  class="flex flex-col  mx-auto    gap-y-4 my-4">
    {{viewType}}
      
  
    <!-- Change view -->
    <div>
      <app-page-view-setup [landingPageView]="landingPageView"  [pageSections]="pageSections" [pageView]="pageView" (save)="saveChanges()"></app-page-view-setup>
      </div>
    </div>
 
    <!-- rearrange list element  -->
    <div *ngIf="sideMenu == 'section'"  class="flex mx-auto  md:mx-auto justify-center  flex-wrap items-center  gap-y-4 my-4">
      <app-list-view-rearrange class="w-full" [landingPageView]="landingPageView"  [pageSections]="pageSections" [pageView]="pageView" [sectionsSetting]="sectionsSetting" [listElement]="listElement" (save)="landingPageConfirmationUpdate()"></app-list-view-rearrange>
    </div>

    <div *ngIf="sideMenu == 'appearance'">
      <app-card-view-customization [landingPageView]="landingPageView"  [pageSections]="pageSections" [pageView]="pageView" [sectionsSetting]="sectionsSetting" [listElement]="listElement" [cardElement]="cardElement" (cardPreview)="cardViewSectionPreview($event)" (cardStylepreviewChange)="cardSettingChange($event)"></app-card-view-customization>
    </div>
  </div>
  </div>

</div>



 

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" 
[toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveRefUpdate() [editedFromComponent]='editingComponent'></app-edit-confirmation>

<!-- Edit Modal Landing page-->
<app-edit-confirmation *ngIf="editLandingPageModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveLandingPageUpdate() [editedFromComponent]='editingComponent'></app-edit-confirmation>

<!-- Rename Modal -->
<div class="modal" class="modal" id="renameModal"   *ngIf="section"
[ngClass]="{'modal-open': renameModalOpen, 'modal-action': !renameModalOpen }">
  <div class="modal-box">
    <h3 *ngIf="section.originalName == 'Blogs'" class="font-bold text-lg">Rename {{section.name }}</h3>
    <h3 *ngIf="section.originalName == 'Events'" class="font-bold text-lg">Rename {{section.name }}
    </h3>
    <h3 *ngIf="section.originalName == 'Products'" class="font-bold text-lg">Rename {{section.name }}</h3>
    <h3 *ngIf="section.originalName == 'Services'" class="font-bold text-lg">Rename {{section.name }}</h3>

    <input type="text" id="sectionRename"  [(ngModel)]="section.name" (click)="sectionInputChange(section, $event)"
            class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
            [name]="blogsName" [placeholder]="blogsName" />
    <div class="modal-action">
        <button type="button" for="my-modal" class="text-black font-bold rounded-md py-1 px-6 w-max border-solid border bg-white border-gray-400" (click)="closeRenameModal()">
            Cancel
          </button>
      <button type="button" class="px-6 py-1  text-white rounded-md  bg-customTeal font-bold" (click)="saveRenameChange()" >Save</button>
    </div>
  </div>
</div>





<app-customize-card *ngIf="customizeModal" (closeModal)="closeCustomizeModal()" [modalOpen] = 'true' [callingSection]='callingSection'></app-customize-card>
<app-rearrange-cards *ngIf="rearrangeModal" (closeModal)="closeRearrangeModal()" [modalOpen] = 'true' ></app-rearrange-cards>