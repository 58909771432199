import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UserModel } from 'src/app/models/UserModel';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { EmployeeScheduleModel } from 'src/app/models/ScheduleModel';
import { map } from 'rxjs/operators';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/models/GlobalConstants';

// declare var $: any;
// declare var M;

@Component({
  selector: 'app-employee-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  currentUser: any;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  user: UserModel;
  scheduleForm: UntypedFormGroup;
  id: any;
  hasSchedule = false;
  employeeSchedule: any;
  date: boolean = false;
  clickedDate: any;
  selectedDate: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  perm: any;
  permission: any;
  isEditable: boolean = true;

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;

  daysOfTheWeek: any[] = GlobalConstants.daysOfTheWeek;
  @Output() createClicked = new EventEmitter();
  @Output() updateClicked = new EventEmitter();
  @Output() defaultScheduleDisplayed = new EventEmitter();
  @Input() calledFrom;
  constructor(private fb: UntypedFormBuilder, private router: Router, private authService: AuthService,
              private fireMemberService: FireMembersService, private scheduleService: ScheduleService,
              private location: Location, private route: ActivatedRoute,
              private permissionService: PermissionService)
              { }

  get schedules(){return this.scheduleForm.get('schedules') as UntypedFormArray; }

  ngOnInit(): void {

    this.scheduleForm = this.fb.group({
      // schedules: this.fb.array([  ],)
    });
    this.id = this.authService.getCurrentUser().uid;
    if(this.id){
      this.permissionService.getPermissionByEmployeeId(this.id).valueChanges().subscribe(perm => {
        if(perm.length != 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];
          this.permMember = this.perm.permission[0]
          this.permPES = this.perm.permission[1]
          this.permAnalytics = this.perm.permission[2];
          this.permSocial = this.perm.permission[3];
          this.permSpecial = this.perm.permission[4];
          
          if(this.permMember.scheduleEmp == true){
            this.isEditable = false;
          }
        }
      })
    }
    this.fireMemberService.getMemberByID(this.id).valueChanges().subscribe(user => {
      if (user){
        if (user.role === 'Admin' || user.role === 'Employee'){
          this.currentUser = user;
        }
      }

    });
    this.scheduleService.getScheduleByEmployeeId(this.id).valueChanges().subscribe(s=>{
      let controls;
      if (s.length > 0){
        this.employeeSchedule = s[0];
        this.hasSchedule = true;
        controls = this.employeeSchedule.schedule.map((sc: any) => {
          return this.createFormGroup(sc);
        });

      }
      else{
        controls = this.daysOfTheWeek.map(sc => {
          return this.createFormGroup(sc);
        });
      }
      this.scheduleForm.registerControl('schedules', new UntypedFormArray(controls));

    });


  }



  createFormGroup(values){
    const startTime = values.unAvailable ? new UntypedFormControl({value: values.startTime, disabled: true}) :
                    new UntypedFormControl({value: values.startTime, disabled: false});
    const endTime = values.unAvailable ? new UntypedFormControl({value: values.endTime, disabled: true}) :
                    new UntypedFormControl({value: values.endTime, disabled: false});

    const formGroup =  this.fb.group({
      day: new UntypedFormControl({value: values.day, disabled: false}),
      startTime,
      endTime,
      unAvailable: new UntypedFormControl(values.unAvailable)
    });

    formGroup.setValidators(this.timeValidators());
    return formGroup;
  }


  timeValidators(): ValidatorFn{
    return(group: UntypedFormGroup) => {
          const errors = {};
          const startTime = group.controls['startTime'];
          const endTime = group.controls['endTime'];
          const unAvailable = group.controls['unAvailable'];

          if (startTime.value === null && endTime.value !== null){
            startTime.setErrors({notEquivalent: true});
          }
          if (endTime.value === null && startTime.value !== null){
            endTime.setErrors({notEquivalent: true});
          }

          if (unAvailable.value === false && startTime.value == null && endTime.value === null){
            endTime.setErrors({notEquivalent: true});
          }


          return null;

        };
  }



  updateDaySchedule(index){
    const schedule = this.schedules.at(index).value;
    const item = {
                day: this.daysOfTheWeek[index].day,
                startTime: schedule.startTime || '09:00',
                endTime: schedule.endTime || '17:00',
                unAvailable: !schedule.unAvailable
              };

    this.schedules.setControl(index, this.createFormGroup(item));

  }

  validateSchedule(schedule){
    return schedule.map(day => {
      day.startTime = (day.unAvailable === true) ? null : day.startTime;
      day.endTime = (day.unAvailable === true) ? null : day.endTime;

      // day.unAvailable = (day.startTime=== null && day.endTime ===null) ? true : false
      return day;
    });

  }
  onSubmit(data) {
    if (this.hasSchedule){
      this.updateSchedule(data);
    }else{
      this.createSchedule(data);
    }

  }
  updateSchedule(data){

    const employeeSchedule = {
        employeeId: this.id,
        schedule: this.validateSchedule(data.schedules),
        id: this.employeeSchedule.id
    };

    this.scheduleService.updateSchedule(employeeSchedule).then(schedule => {
      this.toast({ html: 'Business hours Updated!', classes: 'green' });
    }).catch(err => {
      this.toast({ html: 'Error updating business hours', classes: 'red' });

    });
  }

  createSchedule(data){

    const employeeSchedule = new EmployeeScheduleModel();
    employeeSchedule.employeeId = this.id;
    employeeSchedule.schedule = this.validateSchedule(data.schedules);

    this.scheduleService.createEmployeeSchedule(employeeSchedule).then(schedule => {
      this.toast({ html: 'Business Hours added!', classes: 'green' });
    }).catch(err => {
      this.toast({ html: 'Error adding business hours', classes: 'red' });

    });
  }

 day(item){
  this.date = true;
  this.clickedDate = item.day;
  this.selectedDate = item;
}
isdateClicked(item){
  //this.clickedDate = item.day;
  if(item.day == this.clickedDate){
    return true
  }

}

  backClicked(){
    this.location.back();
  }
  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.backClicked();
    }, 2000);
  }
}
