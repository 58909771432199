import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from './../../../services/firebase/products.service';
import { CartService } from 'src/app/services/checkout/cart.service';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  cart: any;
 
  totalPrice = 0
  itemsCount = 0
  deleteIndex
  deleteModal = false

  constructor(private cartService: CartService, private checkoutServie: CheckoutService) {
    this.cartService.getCartByUserID().valueChanges().subscribe(c => {
      if(c.length > 0){
        this.cart = c[0]; 
      }
    })
  }

  ngOnInit(): void {

  }

  updateItem(event) {
    const { index, value } = event
    if (value) {
      this.cart.items[index].quantity += 1
      this.cartService.updateCart(this.cart);
    } else {
      if (this.cart.items[index].quantity > 1) {
        this.cart.items[index].quantity -= 1
        this.cartService.updateCart(this.cart);
      } 

    }

  }

  deleteItem(index) {
    this.deleteModal = true
    this.deleteIndex = index
  }


  deleteProduct() {
    this.cart.items.splice(this.deleteIndex, 1)
    this.cartService.updateCart(this.cart)
    this.deleteModal = false

  }

  getSubTotal(){
    return this.cart.items.reduce((accumulator,currentValue)=>{
      return accumulator + (currentValue.price * currentValue.quantity)
    },0)
  }

  getCount(){
    return this.cart.items.reduce((accumulator,currentValue)=>{
      return accumulator +  currentValue.quantity
    },0)
  }


  checkout(){
    this.checkoutServie.buyProduct(this.cart.items)
  }
}
