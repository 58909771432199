import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { HelperService } from 'src/app/services/helper/helper';
import { selectAppSettings } from 'src/app/state/app.selectors';


@Component({
  selector: 'app-card-view-customization',
  templateUrl: './card-view-customization.component.html',
  styleUrl: './card-view-customization.component.css'
})
export class CardViewCustomizationComponent implements OnInit {
 
  @Input() landingPageView
  @Input() pageView
  @Output() save = new EventEmitter();
  @Output() cardPreview = new EventEmitter();
  @Output() cardStylepreviewChange = new EventEmitter();

  // appSettings$: Subscription;
  // model: any;
  // appSettings;
  cardElement =[]
  originalCardElement: any 
  pageSections: any;
  checked
  changeboxWidthHeight = true
  changeimgWidthHeight=true
  customizeCard = true
    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
    viewType;
    boxWidth:number;
    boxHeight: number;

  cardDesign =  {
    fontSize: '12px', 
    fontWeight: '800', 
    fontPosition: 'bottom', 
    cardShape: 'rectangle',
    imageShape: 'circle', 
    imageStyle: 'full', 
    imageWidth: 60,
    imageHeight: 60,
    width: 160,
    height: 160, 
    boxColor: this.envService.getColor('primary'), 
    textColor: '#ffffff',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    overlayOpacity: 0.5
  }
  overlayColorPicker: string = '#000000';
  sectionToPreview
  parentData: string = "Initial Parent Data";
  value: number | null = null;
  errorMessage: string | null = null;
  minValue: number = 0; // Default minimum value
  maxValue: number = 800; // Default maximum value


  constructor(private settingsService: AppSettingsService,  private store: Store, private helperService: HelperService,        private envService: EnvironmentService,){
  //   this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
  //     this.model = this.helperService.cloneDeep(settings);
  //     this.appSettings = this.helperService.cloneDeep(settings);

  // });
  }

  ngOnInit(): void {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          this.cardDesign = this.pageSections.cardDesign ? this.pageSections.cardDesign : this.cardDesign

          let index = 1
          let length = 1

          this.pageSections.appSections.forEach(section => {
  
            if(this.validSection(section) || section.isCustomSection){      
              let existingIndex = this.cardElement.findIndex(element => element.originalName == section.originalName);
              if (existingIndex !== -1) {
              
                // Element already exists, update it
                if( section.cardDetail && section.cardDetail.position){
      
                  this.cardElement[existingIndex] = {name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position ? section.cardDetail.position : ''}
                }else if(section.cardDetail && !section.cardDetail.position ){
                  this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: index} 
                  index += 1
                  length +=1
                }else{
                  section.cardDetail = {
                    backgroundColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor :this.envService.getColor('primary'),
                    textColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.textColor:  '#FFFFFF',
                    borderRadius : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.borderRadius :  12,
                    boxWidth : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxWidth:  160,
                    boxHeight : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxHeight:  160,
                    backgroundImage :this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundImage:  '',
                    position: length
                  }
                  this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: length}
                  length +=1
                }
              } else {

                // Element doesn't exist, append it
                if(section.cardDetail && section.cardDetail.position){
                  this.cardElement.push({name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position}) 
                  length +=1
                }else if(section.cardDetail && section.cardDetail.position == 0){
      
                  this.cardElement.push({name:section.name,originalName:section.originalName, section: section, position: 0});
                  length +=1
                 }
                else if(section.cardDetail && !section.cardDetail.position){
                  this.cardElement.push({name: section.name, originalName:section.originalName, section: section, position: index}) 
                  index += 1
                  length +=1
                }else{
      
      
                  section.cardDetail = {
                    backgroundColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor : this.envService.getColor('primary'),
                    textColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.textColor:  '#FFFFFF',
                    borderRadius : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.borderRadius :  12,
                    boxWidth : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxWidth:  160,
                    boxHeight : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxHeight:  160,
                    backgroundImage :this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundImage:  '',
                    position: length
                  }
                  this.cardElement.push({name: section.name, originalName:section.originalName, section: section, position: length}) 
                  length +=1
                }
              }
        
            }
          });
          this.cardElement = this.cardElement.slice().sort((a, b) => a.position - b.position)
          this.originalCardElement = structuredClone(this.cardElement);
      
    }}
  })
  }

  validSection(section) {
    let validSections = [
      "Login/Signup",
      "Events",
      "Blogs",
      "News",
      "Products",
      "Services",
      "About Us",
      "Footer",
      "faq"
    ];

    let chiefSections = ["First Nations Leaders"];
    return (
      (validSections.includes(section.originalName) ||
        (this.envService.isChiefApp && chiefSections.includes(section.originalName))) &&
      section.isEnabled || (section.isCustomSection)
    )
  }

  customizeCardStatus(status){
    this.customizeCard = status
  }

  cardSettingChange(view){
    this.cardStylepreviewChange.emit(view)
  }
  
  closeRearrangeModal(){
  }

  cardViewSectionPreview(section){
    this.sectionToPreview = section
  }

  onTextPositionChange(position: string): void{
    if(this.pageSections.cardDesign){
      this.pageSections.cardDesign.fontPosition = position
    }
    this.cardDesign.fontPosition = position
  }
  
  onFontWeightChange(weight){
    if(this.pageSections.cardDesign){
      this.pageSections.cardDesign.fontWeight = weight
    }
    this.cardDesign.fontWeight = weight
  }
  
  onCardShape(shape: string){
    if(this.pageSections.cardDesign){
      this.pageSections.cardDesign.cardShape = shape
    }
    this.cardDesign.cardShape = shape 
    this.changeCardElementDesign(shape)
  }

  resetCardDetail(){
    this.cardElement = this.originalCardElement
    this.originalCardElement.forEach((section: any) => {
      console.log( section.section.cardDetail.backgroundColor)
    });
  }
  
  changeCardElementDesign(shape){

    switch (shape) {
      case 'circle':
        this.cardElement.forEach((section: any) => {
          section.section.cardDetail.boxHeight = section.section.cardDetail.boxWidth
          section.section.cardDetail.borderRadius ='100'
          
        });
        break;
      case 'rectangle':
        this.cardElement.forEach((section: any) => {
          section.section.cardDetail.boxWidth = Number(section.section.cardDetail.boxHeight) * 2
          section.section.cardDetail.borderRadius ='0'
        });
          break;
      case 'square':
        this.cardElement.forEach((section: any) => {
          section.section.cardDetail.boxWidth =section.section.cardDetail.boxHeight;
          section.section.cardDetail.borderRadius ='0'
        });
        break;
      case 'none':
        this.cardElement.forEach((section: any) => {
          section.section.cardDetail.backgroundColor = '#ffffff5'
          section.section.cardDetail.borderRadius ='0'
          section.section.cardDetail.textColor = this.pageSections.themeColor
        });
        break;
      default:
        // this.cardDesign.fontSize = '14px';
    }

  }
  
  
  onImageShape(shape: string){
    if(this.pageSections.cardDesign){
      this.pageSections.cardDesign.imageShape = shape
    }
    this.cardDesign.imageShape = shape 


  }
  
  onImageStyle(style: string){
    if(this.pageSections.cardDesign){
      this.pageSections.cardDesign.imageStyle = style
    }
    this.cardDesign.imageStyle = style 
    if(this.cardDesign.fontPosition == 'center'){
      this.cardDesign.fontPosition = 'bottom'
    }

  }

  onWidth(width:number){
    if(this.pageSections.cardDesign){
      this.pageSections.cardDesign.width = width
    }
    this.cardElement.forEach(section => {
      section.section.cardDetail.boxWidth = width
      if(this.cardDesign.cardShape == 'square' || this.cardDesign.cardShape == 'circle'){
        section.section.cardDetail.boxHeight = width
      }
    })
    this.cardDesign.width = width
    if(this.cardDesign.cardShape == 'square' || this.cardDesign.cardShape == 'circle'){
       if(this.pageSections.cardDesign){
        this.pageSections.cardDesign.height = width 
       }
      this.cardDesign.height = width 
    }

  }
  
  onHeight(height:number){
    if(this.pageSections.cardDesign){
    this.pageSections.cardDesign.height = height}
    this.cardElement.forEach(section => {
      section.section.cardDetail.boxHeight = height
      if(this.cardDesign.cardShape == 'square' || this.cardDesign.cardShape == 'circle'){
        section.section.cardDetail.boxHeight = height
      }
    })
    this.cardDesign.height = height

  }

  onImageWidth(width:number){
    if(this.pageSections.cardDesign){
      this.pageSections.cardDesign.imageWidth = width
    }
    this.cardDesign.imageWidth = width
    if(this.cardDesign.imageShape == 'square' || 'circle'){
      if(this.pageSections.cardDesign){
      this.pageSections.cardDesign.imageHeight = width 
      this.cardDesign.imageHeight = width 
    }
 
    }

  }
  
  onImageHeight(height:number){
    if(this.pageSections.cardDesign){
          this.pageSections.cardDesign.imageHeight = height
    }

  }
  
  changeWidthandHeight(){
    this.changeboxWidthHeight = !this.changeboxWidthHeight
  }

  changeImageWidthandHeight(){
    this.changeimgWidthHeight = !this.changeimgWidthHeight
  }
  

  onFontSizeChange(size: string): void {
    switch (size) {
      case 'small':
        this.cardDesign.fontSize = '12px';
        break;
        case 'medium':
          this.cardDesign.fontSize = '14px';
          break;
      case 'large':
        this.cardDesign.fontSize = '16px';
        break;
      case 'extraLarge':
        this.cardDesign.fontSize = '20px';
        break;
      default:
        this.cardDesign.fontSize = '14px';
    }
    if(this.pageSections.cardDesign){
      this.pageSections.cardDesign = this.cardDesign
    }

  }

  updateChanges(){
    console.log('save')

    this.pageSections.cardDesign = this.cardDesign
 
    this.settingsService.updateAppSettings(this.pageSections).then(() => {
      this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
    });
  }

  updateAllSections(){
    console.log('log 0', this.changeboxWidthHeight)

    if(this.changeboxWidthHeight){
      console.log('log 1')
      this.pageSections.appSections.forEach((section:any) => {
      console.log('log 2')

        if(section && section.cardDetail){
          section.cardDetail.boxWidth = this.pageSections.cardDesign.width
          section.cardDetail.boxHeight = this.pageSections.cardDesign.height
      console.log('log 3')

        }
    });
  }
    this.settingsService.updateAppSettings(this.pageSections).then(x => {
      this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
    });
  }


  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }

  
  //to track the data being changed for preview 
  onPositionDataChange(updatedData) {
    this.cardElement = updatedData; // Update the parent data when the child emits an event
    console.log('Parent updated:', this.cardElement);
  }

  validateValue(): void {
    if (this.value !== null) {
      if (this.value < this.minValue) {
        this.errorMessage = `Value must be greater than or equal to ${this.minValue}`;
      } else if (this.value > this.maxValue) {
        this.errorMessage = `Value must be less than or equal to ${this.maxValue}`;
      } else {
        this.errorMessage = null;
      }
    }
  }

  onColorChange(event: any): void {
    this.cardDesign.overlayColor = `rgba(${parseInt(event.target.value.slice(1, 3), 16)}, ${parseInt(event.target.value.slice(3, 5), 16)}, ${parseInt(event.target.value.slice(5, 7), 16)}, ${this.cardDesign.overlayOpacity})`;
  }

  onOpacityChange(event: any): void {
    this.cardDesign.overlayOpacity = event.target.value;
    this.cardDesign.overlayColor = `rgba(${parseInt(this.overlayColorPicker.slice(1, 3), 16)}, ${parseInt(this.overlayColorPicker.slice(3, 5), 16)}, ${parseInt(this.overlayColorPicker.slice(5, 7), 16)}, ${this.cardDesign.overlayOpacity})`;
  }

  updateBackgroundColor(color){
    const input = color.target as HTMLInputElement;
    this.cardElement.forEach((section: any) => {
      section.section.cardDetail.backgroundColor = input.value
    });
  }

  updateTextColor(color){
    const input = color.target as HTMLInputElement;
    this.cardElement.forEach((section: any) => {
      section.section.cardDetail.textColor = input.value
    });
  }
}
