import { Component, OnInit, SimpleChanges } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { Tab, Modal, Ripple, initTE, Collapse, Stepper, Sidenav } from "tw-elements";
import { EnvironmentService } from 'src/app/services/helper/env';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { Router } from '@angular/router';
import { ScrollToTopService } from 'src/app/services/helper/scroll-to-top.service';

@Component({
  selector: 'app-empty-dashboard',
  templateUrl: './empty-dashboard.component.html',
  styleUrls: ['./empty-dashboard.component.css']
})
export class EmptyDashboardComponent implements OnInit {
  allSteps =[
    { 
      originalName: 'appSetup',
      quickGuide: [28, 27, 37]
    },
    {
      originalName: 'homePage',
      quickGuide: [0,1,2,21]
    },
    {
      originalName: 'appointment',
      quickGuide: [20,30]
    },
    {
      originalName: 'employee',
      // quickGuide: [20]
    },
    {
      originalName: 'about',
      quickGuide: [16,17]
    },

    {
      originalName: 'service',
      quickGuide: [10, 11]
    },
    {
      originalName: 'product',
      quickGuide: [13, 14]
    },
    {
      originalName: 'event',
      quickGuide: [7,8]
    },
    {
      originalName: 'blog',
      quickGuide: [3,4]
    },
    {
      originalName: 'audience',
      quickGuide: [23,34,35]
    },
    {
      originalName: 'custom',
      quickGuide: [31,36]
    },
    {
      originalName: 'socialMediaPage',
      quickGuide: [32]
    },
    {
      originalName: 'faq',
      quickGuide: [33]
    },
    // {
    //   originalName: 'seo',
    //   quickGuide: [37]
    // }
  ];
  isMobile = false;
  apikey: string;
  isNewSetting = true;
  appSections:any
  model: any
  appSectionsModel: any;
  serviceType: any
  pageSections: any;
  sliderRef: any;
  blogsRef: any;
  aboutRef:any;
  productRef:any
  eventRef: any;
  serviceRef: any;
  appStoreRef: any;
  socialMediaRef: any;
  footerRef: any;
  welcomeRef: any;
  section: any;
  sections = GlobalConstants.AppSections;
  quickGuide = GlobalConstants.quickGuide;
  pageSelectionModal = false
  selectedPageStatus= [];
  gettingStartedPercentage: number;
  blogName:string;
  eventName: string;
  serviceName: string;
  productName: string;
  aboutName: string;
  fromFNLeaders=false
  gettingStarted = {
    firstLogin: true,
    appSetup: false,
    homePage: false,
    employee: false,
    service: false,
    about: false,
    product: false,
    event: false, 
    blog: false,
    audience: false,
    socialMediaPage: false, 
    custom: false,
    faq: false, 
    seo: false
  }
  appointmentSetupDone = false;
  currentUser: any;
  existingSchedule: any;
  defaultSchedule: any;
  customPageCreated=false;
  firstLoginModal;
  modalPage= 'appSetupModal'
  selectedPage: any;
  selectedPages = []
  appName: any;
  userName: any;
  appointmentEmployee;
  closeFinalModal = true;
  userData: any;
  firstClick = true;
  currentStepItem: any;
  originalNameForOnboarding: any;
  previewHomepage = false
  isFinalPage = false;
  modalPageSelected = false
  firstSelection: boolean;
  appointmentEmployeeStatus: string;
  
  isModalOpen = false;

  constructor(
    private router: Router,
    public appSettingsService: AppSettingsService,
    private settingsService: AppSettingsService,
    private envService: EnvironmentService,
    private authService: AuthService,
    private userService: UserService,
    private productsServiceMenuService: ProductsServiceMenuService,
    private scrollService: ScrollToTopService
  ) { 
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
    this.getSettings();
    this.fromFNLeaders = this.envService.fromFNLeaders  
  }



  

  ngOnInit(): void {
    this.scrollService.scrollToTopOnNavigation();
    this.scrollService.scrollToTop(); 
    this.currentUser = this.authService.getCurrentUser();
    this.userService.getUserByEmail(this.currentUser.email).valueChanges().subscribe((user) =>{
      if(user && user.length >0){
        this.userData = user
      }
    })
    this.userName = this.currentUser?.GivenName ? this.currentUser.GivenName: ''
      if (!this.pageSections) {
        this.pageSections = {
          uid: '',
          sections: this.sections,
          appSections: GlobalConstants.AppSections,
              appName: '',
              buttonOption: '',
              navbarOption: 'bottomnavbar',
              themeColor: '',
              rightOrLeftSidebar:  false,
              description: '',
              serviceType: 'Both',
              appLogo: '',
              takeAppointment: '',
              sendSMS: false,
              isBusinessApp: true,
              serviceName: 'Services',
              productName: 'Products',
              verifyUsers: false,
              welcomeMessage: '',
              currency: 'CAD',
              adminEmail: [],
              greetings: '',
              signOff: '',
              messageSMS: '',
              showHiddenSections: true,
              cardDesign: GlobalConstants.cardDesign,
              landingPageView: GlobalConstants.landingPageView,
        };
      
    }

    initTE({ Tab, Modal, Ripple, Collapse, Stepper, Sidenav });
    const instanceMode = Sidenav.getInstance(
      document.getElementById("sidenav-2")
    );

    this.apikey = 'AlIW5XToVRT2qVS1UhHhNz';
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
        (appSettings: any) => {
          if (appSettings && appSettings.length > 0) {
              this.appSections = appSettings[0].appSections;
              this.appSectionsModel = appSettings[0];
             
              this.serviceType =  this.appSectionsModel.serviceType;
      } else {
        this.appSections = this.pageSections.appSections;
        this.appSectionsModel = this.pageSections
        this.serviceType = this.appSectionsModel.serviceType;
      }
    });

    this.settingsService.getLandingPageSettings().valueChanges().subscribe(
      lpSettings => {
        if (lpSettings) {
          if (lpSettings.length > 0) {
            this.model = lpSettings[0];
            this.model.title = this.model.title;
          }
        } 
        else {
          this.model = {
            description: "",
            descriptionColor: '',
            id: '',
            imgUrl: '',
            logo: '',
            title: '',
            titleColor: '',
          }
        }
      }
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.scrollService.scrollToTopOnNavigation();
    this.scrollService.scrollToTop(); 
    
  }
  showModal() {
    this.isModalOpen = true;
  }
  gettingStartedUpdate(name){
    if(name == 'settings'){
    this.pageSections.gettingStarted['appSetup'] = true      
    }else if(name == 'appointment'){
      if(this.appointmentEmployee == 'both'){
        this.pageSections.gettingStarted.appointment = true
        this.pageSections.gettingStarted.employee = true
      }
      else if(this.appointmentEmployee == 'none'){

      }else{
          this.pageSections.gettingStarted[name] = true
      }
    }
    
    else{
    this.pageSections.gettingStarted[name] = true
    }
    this.pageSections.gettingStarted.firstLogin = false
    this.settingsService.updateAppSettings(this.pageSections).then(x => {
    });
  }

  closeModal(){
    this.closeFinalModal = true
    this.router.navigate(['admin/homepage'])
    window.location.reload()
  }

  closeGetStartedModal(){
    this.pageSelectionModal = true
    if(this.pageSections.gettingStarted){
      this.pageSections.gettingStarted.firstLogin = false
      this.pageSections.gettingStarted.pageSelection = []
    }else{
      this.pageSections.gettingStarted = {
        firstLogin: false,
        appSetup: false,
        homePage: false,
        employee: false,
        service: false,
        about: false,
        product: false,
        event: false, 
        blog: false,
        audience: false,
        socialMediaPage: false, 
        faq: false,
        seo:false, 
        custom: false,
        pageSelection: []
      }
    }
    this.saveChanges()
  }

  getRange(start: number, end: number): number[] {
    const range = [];
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  }

  saveChanges() {
    if (this.pageSections && this.isNewSetting) {
      this.settingsService.saveAppSettings(this.pageSections).then(x => {
        this.getSettings();
    
      });
    }
    if (this.pageSections && !this.isNewSetting) {
      this.settingsService.updateAppSettings(this.pageSections).then(x => {
        // this.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.getSettings();
      
      });
    }
  }

  pageSelected(name){
    this.pageSections.gettingStarted = this.pageSections.gettingStarted ? this.pageSections.gettingStarted : this.gettingStarted
    this.firstLoginModal = this.pageSections.gettingStarted ? this.pageSections.gettingStarted.firstLogin : true

    if(this.pageSections && this.pageSections.gettingStarted && this.pageSections.gettingStarted.firstLogin){
      this.gettingStartedPercentage = 0
    }else if(this.pageSections.gettingStarted && this.pageSections.gettingStarted.pageSelection && this.pageSections.gettingStarted.pageSelection.length > 0){
        this.pageSections.gettingStarted.pageSelection.forEach(element => {
          if(element.isEnabled){
          
              this.selectedPageStatus.push({
                originalName: element.originalName,
                status: element.originalName == 'settings' ? this.pageSections.gettingStarted['appSetup'] : this.pageSections.gettingStarted[element.originalName]
              })
            
          }
        })

      let value = 0;
      this.selectedPageStatus.forEach(el =>{
        if(el.status){
          value +=1
        }
      })
      this.gettingStartedPercentage = Math.trunc((value/this.selectedPageStatus.length)*100)

    }
    let value = 0;
    this.selectedPageStatus.forEach(el =>{
      if(el.status){
        value +=1
      }
    })
    this.gettingStartedPercentage = Math.trunc((value/this.selectedPageStatus.length)*100)
    if(this.gettingStartedPercentage==100){
      // this.closeFinalModal = false
      // this.router.navigate(['/admin/homepage'])
      // window.location.reload()
    }

    this.currentStepItem = this.allSteps.filter(s => name==s.originalName)
    // if(name=='appointment'){
    //   if(this.appointmentEmployee == 'both'){
    //     this.currentStepItem[0].quickGuide = [30,20] 
    //   }
    //   if(this.appointmentEmployee == 'employee'){
    //     this.currentStepItem[0].quickGuide = [20] 
    //   }
    // }
    let page = {
      about: 'About Us',
      service: 'Services',
      product: 'Products',
      event: 'Events',
      blog: 'Blogs', 
    }
    if(name =='service' || name == 'product' || name == 'event' || name == 'blog' || name == 'about' ){

      this.pageSections.appSections.forEach(section => {
        if(section.originalName == page[name]){
          this.section = section;
          if(this.section){
            this.originalNameForOnboarding = this.section.originalName ? this.section.originalName : '';
          }
        }      
      });
    }
    this.selectedPage = name
    this.closePageSelectionModal()
  }

  skipPage(page){
  let  index = 0 
  let count = 0 
  this.selectedPages.forEach(pages =>{
    if(page == pages){
      if((this.selectedPage == 'appointment' || this.selectedPage == 'employee')){
        if(this.selectedPages[index+1] == 'appointment' && this.selectedPages[index+1] == 'employee'){
          this.selectedPage = this.selectedPages[index+2]
        } else{
          this.selectedPage = this.selectedPages[index+1]
        }
      }else{
        this.selectedPage = this.selectedPages[index+1]
      }
    }
    index++
  })

  this.gettingStartedUpdate(page);  // remove while using components
  this.pageSelected(this.selectedPage)
  }

  previousPage(page){
    let  index = 0 
    this.selectedPages.forEach(pages =>{
      if(page == pages){
        if((this.selectedPage == 'appointment' || this.selectedPage == 'employee')){
          if(this.selectedPages[index-1] == 'appointment' && this.selectedPages[index-1] == 'employee'){
            this.selectedPage = this.selectedPages[index-2]
          } else{
            this.selectedPage = this.selectedPages[index-1]
          }
        }else{
          this.selectedPage = this.selectedPages[index-1]
        }
      }
      index++
    })
  
    this.gettingStartedUpdate(page);  // remove while using components
    this.pageSelected(this.selectedPage)
  }

  getPageName(){
    this.pageSections.appSections.forEach(section => {
      if(this.blogsRef){
        this.blogName = this.blogsRef.name;
      }if(this.eventRef){
        this.eventName = this.eventRef.name;
      }if(this.serviceRef){
        this.serviceName = this.serviceRef.name;
      }if(this.productRef){
        this.productName = this.productRef.name;
      }if(this.aboutRef){
        this.aboutName = this.aboutRef.name;
      }      
    });
  }

  getSettings() {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          this.pageSections.gettingStarted = this.pageSections.gettingStarted ? this.pageSections.gettingStarted : this.gettingStarted
          this.firstLoginModal = this.pageSections.gettingStarted ? this.pageSections.gettingStarted.firstLogin : true

          if(this.pageSections && this.pageSections.gettingStarted && this.pageSections.gettingStarted.firstLogin){
            this.gettingStartedPercentage = 0
          }else if(this.pageSections.gettingStarted && this.pageSections.gettingStarted.pageSelection && this.pageSections.gettingStarted.pageSelection.length > 0){
              this.pageSections.gettingStarted.pageSelection.forEach(element => {
                if(element.isEnabled){
               
                    this.selectedPageStatus.push({
                      originalName: element.originalName,
                      status: element.originalName == 'settings' ? this.pageSections.gettingStarted['appSetup'] : this.pageSections.gettingStarted[element.originalName]
                    })
                  // }
                }
              })
      
            let value = 0;
            this.selectedPageStatus.forEach(el =>{
              if(el.status){
                value +=1
              }
            })
            this.gettingStartedPercentage = Math.trunc((value/this.selectedPageStatus.length)*100)
          }
          let value = 0;
          this.selectedPageStatus.forEach(el =>{
            if(el.status){
              value +=1
            }
          })
          this.gettingStartedPercentage = Math.trunc((value/this.selectedPageStatus.length)*100)
          this.isNewSetting = false;
          if(this.gettingStartedPercentage == 100){
            // this.closeFinalModal = false

            
          }
        

          if(!this.pageSections.gettingStarted){
            this.pageSections.gettingStarted = {
              firstLogin: true,
              appSetup: false,
              homePage: false,
              employee: false,
              service: false,
              about: false,
              product: false,
              event: false, 
              blog: false,
              audience: false,
              socialMediaPage: false, 
              faq: false,
              seo: false, 
              custom: false, 
              pageSelection: []
            }
          }

          if(this.pageSections.gettingStarted && this.pageSections.gettingStarted.pageSelection &&  this.pageSections.gettingStarted.pageSelection.length > 0){
            this.pageSections.gettingStarted.pageSelection.forEach(element => {
            let existingIndex = this.selectedPages.findIndex(section => section == element.originalName);
              if(existingIndex != -1){
                  // Element already exists, update it
          
              }else{
                  if(element.isEnabled && element.originalName != 'seo'){
                    // if(element.originalName=='product'){
                    //   if((this.pageSections.serviceType == 'Product' || this.pageSections.serviceType == 'Both') ){
                    //     this.selectedPages.push(element.originalName)
                    //   }
                    // }else if(element.originalName=='service' ){
                    //   if((this.pageSections.serviceType == 'Service' || this.pageSections.serviceType == 'Both')){
                    //     this.selectedPages.push(element.originalName)
                    //   }
                    // }else{
                      this.selectedPages.push(element.originalName)
                    // }
                  }
              }
            });

         
         this.appointmentStatus()
          }

          const previousPage = this.productsServiceMenuService.getOnboardingSectionData();

          // if(previousPage=='Blog'){
          //   this.pageSelected('blog')
          //   if(this.firstClick){
          //     this.firstClick = false
          //   } 
          // }
        }
        this.sliderRef = this.pageSections.appSections.filter(s => s.name === 'Slider')[0];
        this.blogsRef = this.pageSections.appSections.filter(s => s.originalName === 'Blogs')[0]; 
        this.eventRef = this.pageSections.appSections.filter(s => s.originalName === 'Events')[0]; 
        this.productRef = this.pageSections.appSections.filter(s => s.originalName === 'Products')[0]; 
        this.aboutRef = this.pageSections.appSections.filter(s => s.originalName === 'About Us')[0]; 
        this.serviceRef = this.pageSections.appSections.filter(s => s.originalName === 'Services')[0]; 
        this.appStoreRef = this.pageSections.appSections.filter(s => s.name === 'AppStore')[0]; 
        this.socialMediaRef = this.pageSections.appSections.filter(s => s.name === 'SocialMedia')[0]; 
        this.footerRef = this.pageSections.appSections.filter(s => s.originalName === 'Footer')[0]; 
        this.welcomeRef = this.pageSections.appSections.filter(s => s.name === 'WelcomePopup')[0]; 
        this.sliderRef = this.pageSections.appSections.filter(s => s.name === 'Slider')[0]; 
        this.getPageName() 
      }


    });

  }
  appointmentStatus() {
    if(this.selectedPages.includes('appointment') && this.selectedPages.includes('employee')){
      this.appointmentEmployee =  'both'
      this.appointmentEmployeeStatus = 'both'
      for (let i = 0; i < this.selectedPages.length; i++) {
        if (this.selectedPages[i] === "employee") {
            this.selectedPages.splice(i, 1);
        }
    }
    } else if(this.selectedPages.includes('appointment') && !this.selectedPages.includes('employee')){
      this.appointmentEmployee =  'appointment'
    } else if(this.selectedPages.includes('employee') && !this.selectedPages.includes('appointment')){
      this.appointmentEmployee =  'employee'
    } else{
      this.appointmentEmployee =  'none'
    }
    return this.appointmentEmployeeStatus? this.appointmentEmployeeStatus: this.appointmentEmployee
  }

  finishSetup(final:boolean){
    if(this.checkNamesExist( ['appointment', 'employee'], this.selectedPageStatus)){
      this.skipPage('appointment')
    }
    for (const key in this.pageSections.gettingStarted) {
      if(key == 'firstLogin'){
      this.pageSections.gettingStarted.firstLogin = false
      }else if(key == 'pageSelection'){

      }
      else{
      this.pageSections.gettingStarted[key] = true
      }
    }
    this.initalizeCards()
    this.navigateToDashboard(final)
  }
  navigateToDashboard(final){
    this.settingsService.updateAppSettings(this.pageSections).then(() => {
      if(final){
        this.router.navigate(['/admin-social/dashboard'])
      }else{
        this.closeFinalModal = true;
        this.router.navigate(['/admin/homepage'])
        window.location.reload() 
      }
    });
  }
  initalizeCards(){
    let index = 0

   this.pageSections.appSections.forEach((section:any) => {
  
    section.cardDetail ={
       backgroundColor :  this.envService.getColor('primary'),
       textColor : '#FFFFFF',
       borderRadius : 12,
       boxWidth : 160,
       boxHeight : 160,
       backgroundImage : '',
       position :  index
    
    }
    index++;
   });

  }

  goToSocial(){
    // this.finishSetup(true)
    this.router.navigate(['/admin-social/dashboard'])

  }

  getPageNameList(name){
    let  pages = {
      appSetup: 'Settings',
      homePage: 'Homepage',
      employee: this.appointmentStatus() == 'both' ? 'Appointment/ Employee' :'Employees',
      appointment: this.appointmentStatus() == 'both' ? 'Appointment/ Employee' :'Appointment',
      about:this.aboutRef? this.aboutName : 'About Us',
      service:this.serviceRef? this.serviceName : 'Services',
      product: this.productRef? this.productName : 'Products',
      event: this.eventRef? this.eventName : 'Events' ,
      blog: this.blogsRef ? this.blogName: 'Blogs, Vlogs & News' , 
      audience: 'Audience Engagement', 
      faq: 'FAQ',
      seo: 'Search Engine Optimization',
      socialMediaPage: 'Social Media Integration', 
      custom: 'Custom Page'
    }
    return pages[name]
  }

  getStatus(pageName){
    // this.enabledPage()
    if(pageName == 'settings'){
      return this.pageSections.gettingStarted['appSetup']
    }else if(pageName == 'appointment'){
      return this.pageSections.gettingStarted['appointment']
    }
    else if(pageName == 'employee'){
      return this.pageSections.gettingStarted['employee']
    }else if(pageName == 'custom'){
      return this.pageSections.gettingStarted['custom']
    } 
    else{
      return this.pageSections.gettingStarted[pageName]
    }
  }

  enabledPage(page){
    let isNextPage = false;
    let selectedMenu =[];
    let appointmentemp = [];
    this.pageSections.gettingStarted.pageSelection.forEach(element => {
      if(element.isEnabled){
        if(element.originalName=='employee'||element.originalName=='appointment'){
          appointmentemp.push(element.originalName)
        }
        selectedMenu.push({originalName: element.originalName, isEnabled: element.isEnabled, isCompleted: this.pageSections.gettingStarted[element.originalName]})
    
      }
    });


    let nextPage = this.findNextIsCompletedFalse(selectedMenu)
    if(nextPage==null){
      this.isFinalPage = true;
    }

    if(appointmentemp.includes('employee') && appointmentemp.includes('appointment')){
      if(nextPage=='employee'){
        nextPage = 'appointment';
      }
    }
    if(page==nextPage){
      isNextPage = true;
      return isNextPage
    }
    return isNextPage

  }

  findNextIsCompletedFalse(arr) {
    for (let i = 0; i < arr.length - 1; i++) {
      if(!arr[i].isCompleted){
        return arr[i].originalName
      }
      if (arr[i].isCompleted && !arr[i + 1].isCompleted) {
        return arr[i+1].originalName;
      }

    }
    return null; // Return null if no such element is found
  }
  
  closePageSelectionModal(){
    this.modalPageSelected = false
  }
  openPageSelectionModal(){

  }
  getModalPageSelected(){
    if(this.pageSections.gettingStarted && this.pageSections.gettingStarted.pageSelection && this.pageSections.gettingStarted.pageSelection.length == 0 ){  
      this.modalPageSelected = true
      this.firstSelection = true
    }
    return this.modalPageSelected
  }


  reloadComponent(self:boolean,urlToNavigateTo ?:string){

   const url=self ? this.router.url :urlToNavigateTo;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
  
     })
   })
 }

 checkNamesExist(namesToCheck: string[], items: { originalName: string; status: string }[]): boolean {
  return namesToCheck.every(name => items.some(item => item.originalName === name));
}

}
