import { UserService } from 'src/app/services/firebase/user.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { MenuSettingsService } from 'src/app/services/firebase/menu-settings.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { ClientAnswersService } from 'src/app/services/firebase/client-answers.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { VerificationService } from 'src/app/services/firebase/verification.service';
import { CartService } from 'src/app/services/checkout/cart.service';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from 'src/app/services/helper/env';
import { GetAppService } from 'src/app/services/firebase/get-app.service';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { RoutMetaService } from 'src/app/services/helper/rout-meta.service';

@Component({
  selector: 'app-member-main',
  templateUrl: './member-main.component.html',
  styleUrls: ['./member-main.component.css']
})
export class MemberMainComponent implements OnInit {
  scrWidth: number;
  route: string;
  id: any;
  currentUser: any;
  darkTheme: any;
  openSidebar = false; //was true
  minimizedMenu = false;
  user: any;
  appLogo: any;
  settings: any;
  model: any;
  appName: any;
  // constructor(private session: SessionsMonitorService, private fCMpushService: FCMpushService) {
  //   if (typeof window['cordova'] !== 'undefined') {
  //     this.fCMpushService.getToken();
  //     this.fCMpushService.onTokenRefresh();
  //   }
  //  }
  menu;
  servicesName;
  productsName;
  clientId: any; //use to work with the clientAnswer collection
  client: any;
  clientGlobal: any;
  form: any;

  verification: any;
  getVerified: boolean;
  isPending: boolean;
  isDeclined: boolean;
  isAccepted: boolean;

  shoppingCart: any;
  cartItems: any;
  isServiceEnabled = false;
  theme = 'light';
  userId: any; //for the users collection
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false

  hasAnApp: boolean = false;
  email: any;

  feedbackModal:boolean = false;
  emitted: any;
  pageName = 'Home'

  events :  any;


  hasActiveSubscription: any;
  metaTagSettings: any;

  constructor(private session: SessionsMonitorService,
              public router: Router,
              private location: Location,
              private authService: AuthService,
              private userService: UserService,
              public menuService: MenuSettingsService,
              private productsServiceMenuService: ProductsServiceMenuService,
              private appSettingService: AppSettingsService,
              private clientAnswerService: ClientAnswersService,
              private verificationService: VerificationService,
              private cartService: CartService,
              private getAppService: GetAppService,
              private envService: EnvironmentService,
              private newsEventService: NewsEventService,
              private checkout: CheckoutService,
              public appSettingsService: AppSettingsService,
              private routMeta: RoutMetaService,

             ) {

    this.router.events.subscribe((_: NavigationEnd) => this.route = _.url);
    this.scrWidth = window.innerWidth;
    // this.id = this.authService.getGlobalUser().uid;
    this.id = this.authService.getCurrentUser().uid;
    this.email = this.authService.getCurrentUser().email;
    
    this.router.events.subscribe(val => {
      if (location.path() === '/user/settings'){
        this.pageName = 'settings'
      }
      if (location.path() === '/user/events'){
        this.pageName = 'events'
      }
      if (location.path() === '/user/blog'){
        this.pageName = 'blog'
      }
      if (location.path() === '/user/view-products'){
        this.pageName = 'products'
      }
      if (location.path() === '/user/services'){
        this.pageName = 'services'
      }
      if (location.path() === '/user/chat-list'){
        this.pageName = 'chat'
      }
      if (location.path() === '/user/get-verified'){
        this.pageName = 'verified'
      }
      if (location.path() === '/user/manage-app/:id'){
        this.pageName = 'manage'
      }
      if (location.path() === '/user/edit-clientInfo/:id' ){
        this.pageName = 'client'
      }if(location.path() === `/user/subscription-success/:${this.id}`){
        this.pageName = 'subscriptionSuccess'
      }if(location.path() === '/user/subscription-fail'){
        this.pageName = 'subscriptionFail'
      }if(location.path() === '/user/homepage'){
        this.pageName = 'home'
      }
      

    });

     
   }

  ngOnInit() {
        // fetch exsting metatag
        this.getMetaTagSettings();

    if (this.id){
      this.userService.getUserById(this.id).valueChanges().subscribe(user => {
        this.currentUser = user[0];
        this.user = this.currentUser;
        this.darkTheme = this.currentUser.darkTheme ? this.currentUser.darkTheme : false;

        // this.newsEventService.getEventById(this.eventId).valueChanges().subscribe(event => {
        //   if (event.length > 0) {
        //     this.event = event[0];

        //     this.event.opened = this.event.opened === '' ? this.event.opened : [];

        //     if (this.currentUser.role == 'User') {
        //       this.event.opened.push(this.currentUser.uid);
        //     }
        //   }
        // });



        //find the user onside the client answer database through email then fetch the id of the clientAnswer
        this.clientAnswerService.getClientByEmail(this.currentUser.Email).valueChanges().subscribe(client =>{
          if(client && client.length != 0){
            this.client = client[0];
            this.clientId = this.client.id;
          }
        })
        this.userService.getUserByEmail(this.currentUser.Email).valueChanges().subscribe((user:any) => {
          if(user && user.length != 0){
            this.userId = user[0].uid
          }
        })
        this.verificationService.getVerificationReqByEmail(this.currentUser.Email).valueChanges().subscribe(verification => {
          this.verification = verification[0];
          if(verification.length == 0){
              this.getVerified = true;
              // this.isPending = false;
              // this.isAccepted = false;
              // this.isDeclined = false;
          }
          else{
              if(this.verification.status == 'Pending'){
                this.isPending = true;
                this.isAccepted = false;
                this.isDeclined = false;
              }else if(this.verification.status == 'Accepted'){
                this.isAccepted = true;
                this.isDeclined = false;
                this.isPending = false;
              }else if(this.verification.status == 'Declined'){
                this.isDeclined = true;
                this.isPending = false;
                this.isAccepted = false;
              }
          }
        })
      });

      this.getAppService.getRequestByEmail(this.email).valueChanges().subscribe((user) => {
          if(user && user.length != 0){
            this.hasAnApp = true;
          }
          else{
            this.hasAnApp = false;
          }
      })     

      this.cartService.getItemsInCart(this.id).valueChanges().subscribe(cart => {
        this.shoppingCart = cart[0];
        if(cart.length != 0){
          this.cartItems = this.shoppingCart.items;
        }
        
      })
  
      this.appSettingService.getAppSettingsList().valueChanges().subscribe(menu =>{
        if (menu){
          if (menu.length > 0){
            this.model = menu[0];
            this.appLogo = this.model.logo ? this.model.logo : '';
            this.appName = this.model.appName ? this.model.appName : 'Going Mobile';
            if (this.model.isBusinessApp){
              this.productsServiceMenuService.productsName.subscribe(p=>{
                this.productsName = p;
              });
              this.productsServiceMenuService.servicesName.subscribe(p=>{
                this.servicesName = p;
              });
            }
            this.model.appSections.forEach(element => {

              if(element.originalName=='Services' && element.isEnabled){
                if(element.isEnabled){
                  this.isServiceEnabled = true;
                }
                else {
                  this.isServiceEnabled = false;
                }
              }
            });
          }
        }
      });
    }

  }



  toggleSideBar(){
    this.openSidebar = !this.openSidebar;
  }
  toggleMinimizeMenu(){
    this.minimizedMenu = !this.minimizedMenu;
  }
  back(){
    this.location.back();
  }
  forward(){
    this.location.forward();
  }
  signOut() {
    this.authService.signOut();
  }

  redirectToProduct(){
    this.router.navigate(['/user/view-products']);  
  }

  redirectToServices(){
    this.router.navigate(['/user/services']);
  }
  getNavigateVerified(){
   if(this.verification && this.verification.reapply == true){
    this.router.navigate(['/user/get-verified'])
  }if(this.getVerified){
    this.router.navigate(['/user/get-verified'])
  }
  // else{
  //   this.router.navigate(['/user/home'])
  // }
  }
  closeSideBar(){
    this.openSidebar = false;
  }
  openModal(){
    this.openSidebar = false;
    this.feedbackModal = true
  }
  closeModal(){
    this.feedbackModal = false
  }
  onSendClicked(event){
    this.emitted = event;
    this.feedbackModal = false;
  }

  ManageSubscription() {
    const id = localStorage.getItem("stripeCustomerID");    
    this.checkout.SubscriptionPortal(id).subscribe(portal => {
      if(portal){
        window.location.href = portal.url;          
      }
    })
  }

  getMetaTagSettings() {
    this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
        this.routMeta.updateTitle(metaTags[0]?.appTitle);
        // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(metaTags[0]?.description);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(metaTags[0]?.keyWords ? metaTags[0]?.keyWords : "");
  
      }
    });
  }


}
