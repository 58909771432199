<div class="font-body min-h-screen max-h-max pt-6">
  <!-- <p class=" font-bold text-lg text-center text-customTeal my-2">Informations that is wanted to be included in the Clients' Profile
  </p> -->
    <p class=" font-bold text-lg text-center text-customTeal my-2" *ngIf="model">{{model.name}}
    </p>
    <p class="text-sm text-center text-gray my-2" *ngIf="model">{{model.description}}
    </p>
    <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 md:mt-6 rounded-lg space-y-4 md:translate-x-1/4">
    <form [formGroup]="surveyForm" (ngSubmit)="submitSurvey(surveyForm.value)">
      <div formArrayName="answerItems">
      <div *ngFor="let item of answers; let i = index;">
        <div [formGroupName]="i">

          <div *ngIf="visibleStepIndex === i" class="">
            <div class="row card-content">
              <span class="card-title"><strong class="text-blue-500">
                  {{item.controls.questionNumber.value}}
                  : </strong> {{item.controls.question.value | titlecase}} ? </span>
              <br />
  
              <div *ngIf="item.controls.questionType.value === 'Open Ended Answer'">
                <div class="flex space-y-2 md:w-full  flex-col">
                  <!-- <i class="material-icons prefix blue-text">question_answer</i> -->
                  <textarea id="answer" type="text" formControlName="answer" class="validate h-28 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                    autocomplete="off" maxlength="150">
                              </textarea>
                  <div *ngIf="(item.controls.answer.dirty ||
                              item.controls.answer.touched) &&
                              item.controls.answer.invalid">
                    <small *ngIf="item.controls.answer.errors.required" class="text-red-500"> The Answer is required. </small>
                  </div>
                </div>
              </div>
  
              <div *ngIf="item.controls.questionType.value === 'Date'">
                <div class="flex space-y-2 md:w-full  flex-col mt-2">
                  <!-- <i class="material-icons prefix blue-text">question_answer</i> -->
                  <!-- <textarea id="answer" type="text" formControlName="answer" class="materialize-textarea validate"
                    autocomplete="off" maxlength="150">
                              </textarea> -->
                <input type="date" id="birthday" name="birthday" formControlName="answer" class="border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline ">
                  <div *ngIf="(item.controls.answer.dirty ||
                              item.controls.answer.touched) &&
                              item.controls.answer.invalid">
                    <small *ngIf="item.controls.answer.errors.required" class="text-red-500"> The Answer is required. </small>
                  </div>
                </div>
              </div>


              <div *ngIf="item.controls.questionType.value === 'Multiple Choice'">
                <div formArrayName="answers"
                  *ngFor="let ans of surveyForm.get('answerItems').controls[i].get('answers').controls; let j = index;">
                  
                  <div class="flex space-x-8 space-y-4 mb-2" [formGroupName]="j" *ngIf="!model.questions[i].allowMultipleAnswers">
                    <label class="flex space-x-8">
                      <input name="answer" value="{{ans.controls.answer.value}}"
                        (change)="answerSelected(i,ans.controls.answer.value)" type="radio" class="radio checked:bg-customTeal"/>
                      <span><strong>{{ans.controls.answer.value | titlecase}}</strong></span>
                    </label>
                  </div>
  
                  <div class="flex space-x-8 space-y-4 mb-2" [formGroupName]="j" *ngIf="model.questions[i].allowMultipleAnswers">
                    <label class="flex space-x-8">
                      <input name="answer" value="{{ans.controls.answer.value}}"
                        (change)="answerSelected(i,ans.controls.answer.value)" type="checkbox" class="checkbox checked:bg-customTeal"/>
                      <span><strong>{{ans.controls.answer.value | titlecase}}</strong></span>
                    </label>
                  </div>
                </div>
              </div>


    
            </div>
  
            <div class="card-action">
              <div class="flex justify-between">
                <div class="" *ngIf="i === 0">
                  <button type="button" class="px-4 py-2 rounded-lg bg-white text-customTeal border my-4 border-customTeal" (click)="backToList()">
                    <i class="material-icons float-left">cancel</i> Cancel
                  </button>
                </div>
                <div class="" *ngIf="i !== 0 && i != (totalQuestions - 1)">
                  <button type="button" class="px-4 py-2 rounded-lg bg-white text-customTeal border my-4 border-customTeal" (click)="previous(i)">
                    <i class="material-icons float-left">arrow_back_ios</i> Previous
                  </button>
                </div>
  
                <div class="" *ngIf="i < totalQuestions - 1">
                  <button type="button" class="px-4 py-2 rounded-lg bg-white text-customTeal border my-4 border-customTeal" (click)="continue(i)"
                    [disabled]="!item.valid">
                    Continue <i class="material-icons float-right">arrow_forward_ios</i>
                  </button>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
      <div *ngIf="visibleStepIndex == totalQuestions - 1" class="row card white col s12">
  
        <div class="card-action">
            <div class="flex justify-between">
                <div class="">
                    <button type="button" class="px-4 py-2 rounded-lg bg-white text-customTeal border my-4" (click)="prev()">
                        <i class="material-icons float-left">arrow_back_ios</i> Previous
                    </button>
                </div>
        
                <div class="" *ngIf="!userFilled">
                    <button type="submit" class="px-4 py-2 rounded-lg text-white bg-customTeal border my-4" [disabled]="!surveyForm.valid">
                        Submit <i class="material-icons float-right">send</i>
                    </button>
                </div>

                <div class="" *ngIf="userFilled">
                  <button type="submit"  class="px-4 py-2 rounded-lg text-white bg-customTeal border my-4" [disabled]="!surveyForm.valid">
                      Update <i class="material-icons float-right">update</i>
                  </button>
              </div>
            </div>
        </div>
    </div>
    </form>
    </div>
</div> 

<!-- <div>
    <p>Information for Clients to fill</p>

</div> -->
<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
