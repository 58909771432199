import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientAnswersService } from 'src/app/services/firebase/client-answers.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { VerificationService } from 'src/app/services/firebase/verification.service';

@Component({
  selector: 'app-verify-account-request',
  templateUrl: './verify-account-request.component.html',
  styleUrls: ['./verify-account-request.component.css']
})
export class VerifyAccountRequestComponent implements OnInit {

  isPending: boolean;
  isDeclined: boolean;
  isAccepted: boolean = true;
  verificationRequest: any;

  isAdmin: boolean;
  perm: any;
  role: any;
  currentUser: any;
  permission: any;
  userSelected:any;
  subscribeGlobal: any;

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  
  constructor(private verificationService : VerificationService,
              private router: Router,
              private permissionService: PermissionService,
              private authService: AuthService,
              private userService : UserService,
              ) { }

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser){
      this.role = this.currentUser.role;
      if(this.role == 'Admin'){this.isAdmin = true;}
      
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm =>{
        if(perm.length != 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];
          [this.permMember, this.permPES, this.permAnalytics, this.permSocial, this.permSpecial] = this.perm.permission;
        }
      })
    }

    this.verificationService.getAllVerificationRequests().valueChanges().subscribe((verificationRequests:any) =>{
      this.verificationRequest = verificationRequests;
      this.userService.getUserByEmail(verificationRequests[0]?.email).valueChanges().subscribe((user : any) => {
        this.subscribeGlobal = user[0];
      })
    })
  }
  togglePending(){
    this.isPending = true;
    this.isDeclined =  false;
    this.isAccepted =  false;
    this.verificationService.getPendingVerificationRequests().valueChanges().subscribe(verificationRequests =>{
      this.verificationRequest = verificationRequests;
    })
  }
  toggleDecline(){
    this.isPending = false;
    this.isDeclined =  true;
    this.isAccepted =  false;
    this.verificationService.getDeclinedVerificationRequests().valueChanges().subscribe(verificationRequests =>{
      this.verificationRequest = verificationRequests;
    })
  }
  toggleAccept(){
    this.isPending = false;
    this.isDeclined =  false;
    this.isAccepted =  true;
    this.verificationService.getAcceptedVerificationRequests().valueChanges().subscribe(verificationRequests =>{
      this.verificationRequest = verificationRequests;
    })
  }
  viewClientProfile(userModel: any){
    if(this.isAdmin){
      this.userService.getUserByEmail(userModel.email).valueChanges().subscribe(user =>{
        if(user && user.length!= 0){
          this.userSelected = user[0];
          this.router.navigate(['/admin/client-detail/', this.userSelected.uid]);
        }
      })
    }else{
      this.userService.getUserByEmail(userModel.email).valueChanges().subscribe(user =>{
        this.userSelected = user[0];
        this.router.navigate(['/employee/client-detail/',this.userSelected.uid]);
      })
    }
  }

}
