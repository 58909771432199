import { ProductsService } from 'src/app/services/firebase/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location} from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  editProductForm: UntypedFormGroup;
  hasMedia = false;
  hasNoMedia = true;
  mediaType: any;
  addMedia = false;
  adminThemeColor = '';
  currentUser: any;
  model: any;
  binDoc = undefined;
  base64Image = undefined;
  base64ImageMain = undefined;
  showLogoInput = true;
  imgErrorMsg: any;
  isVideo = false;
  isPhoto = false;
  productId: any;
  product: any;

  isMobile = false;
  mobileBase64Image: any;
  settings: any;
  selectedCategoryId: any;
  previousCategoryId: any;

  // modal
  deleteModalOpen = false;
  shareModalOpen = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  step = 1;
  isList;

  productsName;
  catagoryName;
  cancelModalOpen = false;
  preview = false;
  modelToPreview: any;
  haveAccessPermission = false;
  permission: any;
  editModalOpen = false;
  productCategory: any;
  attachedVideo: any;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';
  cropModalOpen = false;
  imageChangedEvent: any = '';

  perm:any;
  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  useBase64=false
  uploadedDocument: File;
  videoUpload: File;
  uploadFileModalOpen = false;
  mediaList = [];
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };
  productLinkClicked = false;
  pageSections: any;
  productRef: any;
  onLoading = false;
  createItemUnderProductModal = false;
  editItemUnderProductModal = false;
  allProducts = [];
  productCategoryUnderToBeEdited: any;
  appSetting: any;
  addDetailToProduct = true;

  // to ask the admin if he/she wants to send notification
  showPushNotificationModal = false;
  productData;
  createProductForm: UntypedFormGroup;
  itemUnderCategoryForm: UntypedFormGroup;
  hasTimer = false;
  employees = []
  selectedEmployee = [];
  pricing = '';
  selectedEmployeeName = []
  videoLink: string = null;
  imageLink: string = null;
  imgURL: any;
  currency;
  notifSettings;
  createModalOpen = false;
  numberPattern = /^[-+]?(\d*\.\d+|\d+\.|\.\d+|\d+)([eE][-+]?\d+)?$/;
  newProductValues: any = {};
  uploadedImageLink = '';
  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private mediaService: MediaService,
              private route: ActivatedRoute,
              private location: Location,
              private productsService: ProductsService,
              private router: Router,
              private productsServiceMenuService: ProductsServiceMenuService,
              private appSettingsService: AppSettingsService,
              private permissionService: PermissionService,
              private helperService: HelperService
              ) {
                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
                  this.isMobile = true;
                }
              }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if (this.currentUser.role === 'Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe((perm: any) => {
          if (perm && perm.length > 0){
            this.perm = perm[0];
            //this.permission = this.perm.permission[0];
  
            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];

            if (this.permPES.editProducts){
              this.haveAccessPermission = true;
            }
          }
      })
    }
      if (this.currentUser.role === 'Admin'){
        this.haveAccessPermission = true;
      }
    }
    this.route.paramMap.subscribe(params => {
      this.productId = params.get('id');
    });

    this. editProductForm = this.fb.group({
      productName: ['', Validators.required],
      productDescription: [''],
      backgroundColor: [''],
      hasPrice: [''],
      price: ['', [Validators.pattern(this.numberPattern)]],
      imgLink: [''],
      videoLink: [''],
      shareWithPublic: [, Validators.required],
      sharedLink: [''],
      hasSubProducts: [, Validators.required],
      commentable: [true]
    });
    this.itemUnderCategoryForm = this.fb.group({
      productTitle: new UntypedFormControl('', Validators.required),
      productUnderDescription: new UntypedFormControl(''),
      productPrice: new UntypedFormControl('', Validators.pattern(this.numberPattern)),
    
    });

    this.productsService.getProductById(this.productId).valueChanges().subscribe(product => {
      if (product.length > 0){
        this.product = product[0];
        this.product.hasSubProducts = this.product.hasSubProducts? this.product.hasSubProducts : (this.product.hasSubProducts? false: true)
        this.allProducts = this.product.allProducts ? this.product.allProducts: [];
        if(this.product.hasPrice){
          this.pricingSet(this.product.price)
        }
        this.editProductForm.patchValue({
          productName: this.product.productName,
          productDescription: this.product.productDescription,
          backgroundColor: this.product.backgroundColor ? this.product.backgroundColor : '',
          imgLink: this.product.imgLink,
          videoLink: this.product.videoLink,
          shareWithPublic: this.product.shareWithPublic,
          sharedLink: this.product.sharedLink,
          price: this.product.price ? this.product.price : '',
          hasSubProducts: this.product.hasSubProducts? this.product.hasSubProducts : [],
          hasPrice: this.product.hasPrice ? this.product.hasPrice : 0,
          allProducts: this.product.allProducts,
          commentable: this.product.commentable ? this.product.commentable : true,
        });
      }
    });
    this.productsServiceMenuService.productsName.subscribe(p => {
      this.productsName = p;
    });
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings) => {
      if (settings.length > 0){
        this.settings = settings[0];
      }
    });
  }

  get productName(){return this. editProductForm.get('productName'); }
  get sharedLink(){return this. editProductForm.get('sharedLink'); }
  get productDescription(){return this. editProductForm.get('productDescription'); }
  get imgLink(){return this. editProductForm.get('imgLink'); }
  get hasPrice() { return this.editProductForm.get('hasPrice'); }
  get shareWithPublic() { return this.editProductForm.get('shareWithPublic'); }
  get productTitle() { return this.itemUnderCategoryForm.get('productTitle'); }
  get productUnderDescription() { return this.itemUnderCategoryForm.get('productUnderDescription'); }
  get productPrice() { return this.itemUnderCategoryForm.get('productPrice'); }
  get hasSubProducts() { return this.editProductForm.get('hasSubProducts'); }
  get price() { return this.editProductForm.get('price'); }

  updateProduct(value: any){
    if(!value.hasSubProducts){
      this.allProducts = []
      this.pricing = 'dontInclude'
    }
    this.editModalOpen = false;
    this.product.productName = value.productName;
    this.product.productDescription = value.productDescription;
    this.product.sharedLink = value.sharedLink;
    this.product.shareWithPublic = value.shareWithPublic;
    this.product.timeStamp = this.product.timeStamp? this.product.timeStamp : new Date();
    this.product.backgroundColor = value.backgroundColor || '';
    this.product.hasPrice =  this.product.hasPrice ? this.product.hasPrice : value.price > 0
    this.product.allProducts = this.allProducts;
    this.product.commentable = value.commentable;
    this.product.reactions = this.product.reactions ? this.product.reactions: {    likes: {
      count: 0,
      userId: [],
      usernames: []
    }}
    this.product.comments = this.product.comments ? this.product.comments : []
    if(this.pricing == 'paid'){
      this.product.price= value.price;
      this.product.hasPrice = true;
    }else if(this.pricing == 'dontInclude'){
      this.product.hasPrice= false;
      this.product.price = 0;
    }

    if(this.mediaList.length > 0){
      this.uploadFileModalOpen = true;
    }
    else {
      this.productsService.updateProduct(this.product).then(() => {
        this.toast({html: 'Successfully updated a product', classes: 'green', type: 'success'});
        }).catch((err) => {
          this.toast({html: err, classes: 'red', type: 'failure'});
      });
    }

  }

  backClicked(){
    this.location.back();
  }

  handleVideoInput(files: FileList) {
    this.binDoc = files[0];
    this.videoUpload = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachedVideo = reader.result as string;
    };
    this.mediaList.push({type:'Video', file: this.videoUpload});
  }

  removeImage(){    
    this.base64Image = undefined;
    this.base64ImageMain = undefined;
    this.product.imgLink = '';
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Image'){
        return file;
      }
    })
    if(this.productCategoryUnderToBeEdited){
      this.productCategoryUnderToBeEdited.imgLink = '';
    }
  }

  removeVideo(){
    this.product.videoLink = '';
    this.videoUpload = undefined;
    this.attachedVideo = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Video'){
        return file;
      }
    })
  }

  validateImage(name: any) {
    return this.helperService.validateImage(name);
  }

  deleteProduct(){
    this.closeDeleteModal();
    this.productsService.deleteProduct(this.product).then(() => {
      this.toast({html: 'Product deleted successfully', classes: 'green', type: 'success'});
    });
  }
  getUrl(url) {
    return this.helperService.getUrl(url);
  }

  dataURItoBlob(dataURI) {
    return this.helperService.dataURItoBlob(dataURI);
 }


toast(obj){
  this.toastMessage = obj.html;
  this.toastClass = obj.classes ? obj.classes : 'green' ;
  this.toastType = obj.type ? obj.type : 'success';
  this.openToast = true;
  setTimeout(() => {
    this.openToast = false;
    if (this.currentUser.role === 'Admin'){
      this.router.navigate(['/admin/view-products']);      
    }
    if (this.currentUser.role === 'Employee'){
      this.router.navigate(['/employee/view-products']);
    }
  }, 2000);
}
openDeleteModal(){
  this.deleteModalOpen = true;
}
closeDeleteModal(){
  this.deleteModalOpen = false;
}
openShareModal(){
  this.shareModalOpen = true;
}
closeShareModal(){
  this.shareModalOpen = false;
}

nextStep(){
  this.step =  this.step === 2 ? 1 : 2;
}

closeCancelModal(){
  this.cancelModalOpen = false;
}
openCancelModal(){
  this.cancelModalOpen = true;
}
closeEditModal(){
  this.editModalOpen = false;
}

getTheUrlsSaved(event){
  this.returnedMediaLinks = event;
  this.uploadFileModalOpen = false;
  this.product.imgLink = this.returnedMediaLinks.imageURL;
  this.product.videoLink = this.returnedMediaLinks.videoUrl;

  this.productsService.updateProduct(this.product).then(() => {
    this.toast({html: 'Successfully updated a product', classes: 'green', type: 'success'});
    }).catch((err) => {
      this.toast({html: err, classes: 'red', type: 'failure'});
  });

}

previewChanges(value){
  this.modelToPreview = {};
  this.modelToPreview.createdById = this.currentUser.uid;
  this.modelToPreview.productName = value.productName;
  this.modelToPreview.productDescription = value.productDescription;
  this.modelToPreview.price = value.price;
  this.modelToPreview.backgroundColor = value.backgroundColor;
  this.modelToPreview.imgLink = this.product.imgLink;
  this.modelToPreview.videoLink = '';
  this.modelToPreview.sharedLink = value.sharedLink;
  this.modelToPreview.shareWithPublic = value.shareWithPublic;
  this.modelToPreview.productId = value.productName.replace(/\s/g, '');
  this.modelToPreview.category = value.category;
  if (this.base64ImageMain){
    this.modelToPreview.imgLink = this.base64ImageMain;
  }
  if (this.attachedVideo){
    this.modelToPreview.videoLink = this.attachedVideo;
  }
  this.preview = true;
}

uploadPhoto() {
  this.mediaType = 'Photo';
}

uploadVideo() {
  this.mediaType = 'Video';
}


onImageUpload(response:ImageUploaderResponseModel) {
  if (response.error){
    this.imgErrorMsg = response.errorMessage
    return
  }

  this.binDoc = response.binDoc;
  this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
  this.mobileBase64Image = response.imgBase64;
  this.useBase64=true
  this.cropModalOpen = true;

}
fileChangeEvent(event){
  this.imageChangedEvent = event;
  // this.cropModalOpen = true;

  if (!this.validateImage(event[0].name)) {
    this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
    return;
  }
  this.binDoc = event[0];
  const reader = new FileReader();
  reader.readAsDataURL(event[0]);
  reader.onload = () => {
    this.base64Image = reader.result as string;
    this.useBase64 = true;
    this.processCroppedImage(this.base64Image);
  };
}
closeCroppingModal(){    
  this.cropModalOpen = false;
  this.useBase64=false
}

processCroppedImage(event){
  this.cropModalOpen = false;
  this.useBase64=false
  this.base64ImageMain = event;
  let base64result = this.base64ImageMain.split(',')[1];
  const img = new ImageModel();
  if(this.isMobile){
    img.imgBase64 = this.mobileBase64Image;
    this.mediaList.push({type:'Image', file: img});
  }else{
    img.imgBase64 = base64result; 
    this.mediaList.push({type:'Image', file: img});
  }
}
additionalLink(){
  this.productLinkClicked = true;
}
saveUpdateToCategoryUnder() {
  if(this.binDoc){
    let productUnderObj = {
      productTitle: this.productTitle.value,
      productUnderDescription: this.productUnderDescription.value,
      productPrice: this.productPrice.value
    }
    this.uploadImage(productUnderObj,true)
  } else{
    this.allProducts = this.allProducts.map((s) => 
    {
      if(s.productTitle == this.productCategoryUnderToBeEdited.productTitle){
        s.productTitle = this.productTitle.value;
        s.productUnderDescription = this.productUnderDescription.value;
        s.productPrice = this.productPrice.value;
        return s
      }
      return s
    }
    );
  }
  this.itemUnderCategoryForm.patchValue({
    productTitle: '',
    productUnderDescription: '',
    productPrice: ''
  })
  this.base64Image = '';
  this.editItemUnderProductModal = false;
}

cancelCategoryUnder() {
  this.createItemUnderProductModal = false;
  this.editItemUnderProductModal = false;
}

removeProductUnder(product) {
  this.allProducts = this.allProducts.filter(s => s.productTitle != product.productTitle);
}
editProductUnder(productUnder) {
  this.productCategoryUnderToBeEdited = productUnder;
  this.itemUnderCategoryForm.patchValue({
    productTitle: productUnder.productTitle,
    productUnderDescription: productUnder.productUnderDescription,
    productPrice: productUnder.productPrice,
    
  });
  this.editItemUnderProductModal = true;
}
clearUnderCategoryForm() {
  this.itemUnderCategoryForm.patchValue({
    productTitle: '',
    productUnderDescription: '',
    productPrice: '',
  });
}

addNewCategoryUnder() {
  if(this.binDoc){
    let productUnderObj = {
      productTitle: this.productTitle.value,
      productUnderDescription: this.productUnderDescription.value,
      productPrice: this.productPrice.value
    }
    this.uploadImage(productUnderObj,false)
  } else{
    this.allProducts.push({
      productTitle: this.productTitle.value,
      productUnderDescription: this.productUnderDescription.value,
      productPrice: this.productPrice.value,
      imgLink: ''
    });
  }
  this.base64Image = '';
  this.uploadedImageLink = '';
  this.createItemUnderProductModal = false;
  this.clearUnderCategoryForm();
}
addNewProductDetails(){
  this.createItemUnderProductModal = true;
  this.clearUnderCategoryForm();
}
pricingSet(price: string){
  this.pricing = price
}
handleImageInput(files: FileList) {
  if (!this.validateImage(files[0].name)) {
    this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
    return;
  }
  this.binDoc = files[0];
  this.imgErrorMsg = undefined;
  const reader = new FileReader();
  reader.readAsDataURL(files[0]);
  reader.onload = () => {
    this.base64Image = reader.result as string;
  };
}
uploadImage(prodUnderObje,isUpdate) {
  if (this.isMobile) {
    if (this.mobileBase64Image) {
      const img = new ImageModel();
      img.imgBase64 = this.mobileBase64Image;
      this.mediaService.uploadImage(img).subscribe((upload) => {
        // this.loading = true;
        if (upload) {
          // values.imgUrl = upload.imgLink;
          this.uploadFileModalOpen = false;
          this.uploadedImageLink = upload.imgLink
          if(isUpdate){
            this.allProducts = this.allProducts.map((s) => 
            {
              if(s.productTitle == this.productCategoryUnderToBeEdited.productTitle){
                s.productTitle = prodUnderObje.productTitle;
                s.productUnderDescription = prodUnderObje.productUnderDescription;
                s.productPrice = prodUnderObje.productPrice;
                s.imgLink = this.uploadedImageLink
                return s
              }
              return s
            }
            );
          }
          else{
            this.allProducts.push({
              productTitle: prodUnderObje.productTitle,
              productUnderDescription: prodUnderObje.productUnderDescription,
              productPrice: prodUnderObje.productPrice,
              imgLink: this.uploadedImageLink
            });
          }

          this.uploadedImageLink = '';
          return;
        }
        // this.loading = false;
      });
    }
  }
  else {
    if (this.binDoc) {
      this.mediaService.UploadBinImage(this.binDoc).subscribe(
        upload => {
          if (upload) {
            this.uploadFileModalOpen = false;
            this.uploadedImageLink = upload.imgLink;
            if(isUpdate){
              this.allProducts = this.allProducts.map((s) => 
              {
                if(s.productTitle == this.productCategoryUnderToBeEdited.productTitle){
                  s.productTitle = prodUnderObje.productTitle;
                  s.productUnderDescription = prodUnderObje.productUnderDescription;
                  s.productPrice = prodUnderObje.productPrice;
                  s.imgLink = this.uploadedImageLink
                  return s
                }
                return s
              }
              );
            }
            else{ 
              this.allProducts.push({
                productTitle: prodUnderObje.productTitle,
                productUnderDescription: prodUnderObje.productUnderDescription,
                productPrice: prodUnderObje.productPrice,
                imgLink: this.uploadedImageLink
              });
            }
  
            this.uploadedImageLink = '';
            this.binDoc = undefined;
            return;
          }
        }
      );
    }
  }
}
}
