<div class="flex flex-col-reverse mx-auto  md:space-y-0  md:flex-row">
  <!-- image
  <div  class="md:h-screen md:pt-32 px-3 space-y-6  py-12  justify-center w-full md:w-1/3"
  style='background-size: cover;' [ngStyle]="{'background-image': 'linear-gradient(rgb(33,158,188,0.5), rgb(192,72,72,0.5))'}"
  *ngIf="signupSettings && !loading">
      <div [innerHTML]="signupSettings.title | noSanitize"></div>
      <div [innerHTML]="signupSettings.description | noSanitize"></div>
</div> -->

<div  class="md:h-screen md:pt-32 space-y-6  py-12  justify-center w-full md:w-1/3"
style='background:linear-gradient(rgb(33,158,188,0.5), rgb(192,72,72,0.5)), url("../../../../assets/img/ryan-ancill-aJYO8JmVodY-unsplash.jpg");  background-size: cover;'
 *ngIf="!signupSettings && !loading">
  <h1 class=" text-3xl font-bold text-center px-12 text-white md:text-4xl ">
    An App for Everyone
  </h1>
  <p class=" text-center text-white px-12  ">
    With just three simple steps, you can give your small business or
    personal brand a competitive edge with {{currentAppName}}.
    {{currentAppName}} is business, socials, sales and more, all on a platform built just for you.</p>
</div>

  <!-- Signup input -->
  <div class="flex flex-col p-4 md:p-12 w-full items-center space-y-2 justify-center   ">

    <div class="flex justify-between w-full">
      <button class=" flex  items-center">
          <div [routerLink]="['/']">
            <i class="material-icons">keyboard_arrow_left</i>
          </div>
          <div [routerLink]="['/']" class="pl-2 ">
              <h4 class=" text-sm  font-bold">
                  Homepage
              </h4>
          </div>
      </button>
      <img [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="block w-32 justify-center cursor-pointer " alt="Logo ">
    </div>
<div class="bg-gray-100 md:py-12 md:px-16 py-6 px-4 w-full md:max-w-max">
  <p class="text-xl font-bold text-center opacity-100 md:text-3xl ">Create an account</p>
  <p class="text-base font-medium text-center text-customTeal md:pt-2 " *ngIf="model">{{model.welcomeMessage}}</p>
  <hr class="w-full border-t-1 border-lightGray mt-4 ">


  <form [formGroup]="requestTojoinForm" (ngSubmit)="SubmitRequest(requestTojoinForm.value)">
      <div class="flex flex-col pt-8 space-y-4 mb-4 md:flex-col  ">
          <div class="gap-x-8 gap-y-4 space-y-3 md:space-y-4">
              <div class="flex flex-col md:flex-row gap-x-8 space-y-3  md:space-y-0 ">
                <div class="flex space-y-2  md:w-96  flex-col">
                  <label for=" " class="text-sm text-gray-500 font-bold "> First Name <span class="text-red-400">*</span> </label>
                  <input maxlength="60" formControlName="firstName" class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " 
                  [ngClass]="{
                    'border-red-500': ((firstName.dirty || firstName.touched) && firstName.invalid),
                    'border-gray-200': !((firstName.dirty || firstName.touched) && firstName.invalid)
                  }"
                  type="text" placeholder="John" required>
                  <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                    <small *ngIf="firstName.errors.required" class="text-red-500 italic"> The First Name is required. </small>
                  </div>
              </div>
              <div class="flex space-y-2 md:w-96 flex-col ">
                  <label for=" " class="text-sm text-gray-500 font-bold "> Last Name <span class="text-red-400">*</span></label>
                  <input formControlName="lastName" class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " 
                  [ngClass]="{
                    'border-red-500': ((lastName.dirty || lastName.touched) && lastName.invalid),
                    'border-gray-200': !((lastName.dirty || lastName.touched) && lastName.invalid)
                  }"
                  type="text" placeholder="Jane" required>
                  <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                    <small *ngIf="lastName.errors.required" class="text-red-500 italic"> The Last Name is required. </small>
                  </div>
              </div>
              </div>
              <div class="flex flex-col md:flex-row gap-x-8 space-y-3  md:space-y-0 ">
                <div class="flex space-y-2 md:w-96 flex-col ">
                  <label for=" " class="text-sm text-gray-500 font-bold "> Email <span class="text-red-400">*</span></label>
                  <input
                  class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                  type="text" placeholder="JohnJane@gmail.com " 
                  [ngClass]="{
                    'border-red-500': ((email.dirty || email.touched) && email.invalid),
                    'border-gray-200': !((email.dirty || email.touched) && email.invalid)
                  }"
                  formControlName="email" required>
                  <div *ngIf="(email.dirty || email.touched) && email.invalid">
                    <small *ngIf="email.errors.required" class="text-red-500 italic"> The Email is required. </small>
                    <small *ngIf="email.errors.email" class="text-red-500 italic"> Wrong email format. </small>
                  </div>
              </div>
              <div class="flex space-y-2 md:w-96 flex-col ">
                  <label for=" " class="text-sm text-gray-500 font-bold "> Phone Number</label>
                  <input formControlName="cellPhone"   class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "  type="text" placeholder="000-0000-0000 ">
              </div>
              </div>
              <div class="flex space-y-2 md:w-full flex-col ">
                  <label for=" " class="text-sm text-gray-500 font-bold "> Message</label>
                  <input formControlName="message" class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "  type="text" placeholder="Message for admins ">
              </div>

              <!-- <div class="">
                <p class="text-xs text-gray-500 font-bold">Role*</p>
                <div class="flex">
                  <div class="w-1/2">
                    <label>
                      <input type="radio" class="checked:bg-customTeal mr-2 radio-sm mt-4" formControlName="userType" value='User' checked/>
                      <span>User</span>
                    </label>
                  </div>
                  <div class="w-1/2">
                    <label>
                      <input type="radio"  class="checked:bg-customTeal mr-2 radio-sm mt-4" formControlName="userType" value='Employee'/>
                      <span>Employee</span>
                    </label>
                  </div>
                </div>
              </div> -->

          </div>

          <!-- <div class="text-xs text-center text-gray-400 ">
            <p><strong>By Clicking <span class="green-text">Request To Join</span> I hereby :</strong></p>
          <p><strong>Agree and consent to the <a [routerLink]="['/eula']" target="_blank">User Agreement</a>, its
              policies and the
              <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a>.</strong> </p>
        </div> -->

          <button type="submit"  [disabled]="!requestTojoinForm.valid" 
          class="mx-auto px-6 py-2 border-2 font-bold rounded-lg bg-customTeal w-full md:w-96 text-white focus:outline-none"
          [ngClass]="{'bg-customTeal': requestTojoinForm.valid, 'bg-gray-400': !requestTojoinForm.valid}">
              Register
          </button>

      </div>
  </form>

  <p class="text-center ">Already have an account? <button type="button " [routerLink]="['/auth/login']" class="inline-block underline text-customTeal font-bold hover:text-darkBlue ">Login</button>
  </p>
</div>
  </div>

  <!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<div id="my-modal-alreadyRequested" class="modal" *ngIf="openRequestModal"
  [ngClass]="{'modal-open': openRequestModal, 'modal-action': !openRequestModal }">

  <div class="modal-box w-10/12 max-w-3xl">
    <div class="modal-content">
     
      <div class="modal-action flex justify-end m-4">
        <label (click)="closeRequestModal()" class="text-customTeal font-bold"><i class="material-icons px-2 py-2 rounded-full bg-customTeal text-white">close</i></label>
      </div>
      <div class=" text-center mt-12">
        <div class="text-customTeal text-xl md:text-4xl font-bold text-center px-4">Thank you for registering with
          {{appName}}
        </div>
        <br />
        <p class="md:mx-20 sm-mx-12 text-center font-bold px-4"> You already Requested and your application is
          in progress. The adminstrator will review the form and send you a
          registration link. Please follow the link when you recieve it
        </p>
        <br />
        <p class="font-bold px-4">Until then why not explore our landing page</p>
        <br />
        <button [routerLink]="['/home']" class="px-2 py-2 bg-customTeal items-center rounded-md text-white">Go to
          Landing Page</button>
        <br />
        <br />
      </div>


      <hr />

    </div>
  </div>
</div>

<div id="my-modal-alreadyRegistered" class="modal" *ngIf="openRegisteredModal"
  [ngClass]="{'modal-open': openRegisteredModal, 'modal-action': !openRegisteredModal }">

  <div class="modal-box w-10/12 max-w-3xl">
    <div class="modal-content">
    
      <div class="modal-action flex justify-end m-4">
        <label (click)="closeRegisteredModal()" class="text-customTeal font-bold"><i class="material-icons px-2 py-2 rounded-full bg-customTeal text-white">close</i></label>
      </div>
      <div class=" text-center mt-12">
        <div class="text-customTeal text-xl md:text-4xl font-bold text-center px-4">Thank you for signing up</div>
        <br />
        <p class="md:mx-20 sm-mx-12 text-center font-bold px-4"> But you have already registered to this app
            {{appName}}.  </p>
        <br />
        <p class="font-bold px-4">You already have an account so go ahead and login. If you forgot your password please <a [routerLink]="['/auth/change-password']" class=""><span class="text-blue-500 font-normal">Click here</span></a></p>
        <br />
        <button [routerLink]="['/login']" class="px-2 py-2 bg-customTeal items-center rounded-md text-white">Go to Login
            Page</button>
        <br />
        <br />
    </div>
      <hr />

    </div>
  </div>
</div>

<div id="my-modal-alreadyNewRegister" class="modal" *ngIf="openRegisterModal"
  [ngClass]="{'modal-open': openRegisterModal, 'modal-action': !openRegisterModal }">

  <div class="modal-box w-10/12 max-w-3xl">
    <div class="modal-content">
      <div class="modal-action flex justify-end m-4">
        <label (click)="closeNewRegister()" class="text-customTeal font-bold"><i class="material-icons px-2 py-2 rounded-full bg-customTeal text-white">close</i></label>
      </div>
      <div class=" text-center mt-12">
        <div class="text-customTeal text-xl md:text-4xl font-bold text-center px-4">Thank you for signing up with {{appName}}</div>
        <br />
        <p class="md:mx-20 sm-mx-12 text-center font-bold px-4"> Your application is in progress. The administrator will review the form and send you a registration link. Please follow the link when you recieve it.  </p>
        <br />
        <p class="font-bold px-4">Until then why not explore our landing page </p>
        <br />
        <button [routerLink]="['/login']" class="px-2 py-2 bg-customTeal items-center rounded-md text-white">Go to Login
            Page</button>
        <br />
        <br />
    </div>
      <hr />

    </div>
  </div>
</div>

</div>



