<!-- 
<button class="btn" (click)="showModal()">Open Modal</button>

<app-modal-box [isOpen]="isModalOpen" size="medium" (close)="isModalOpen = false" title="This is a Modal">
    <app-empty-page></app-empty-page>
    <div modal-footer>
        <button class="btn" (click)="isModalOpen = false">Close2</button>
      </div>
</app-modal-box> -->

<!-- {{checkAndRemoveOneName( ['appointment', 'employee'], this.selectedPageStatus, 'appointment')}} -->
<div class="md:px-24 pb-4 mx-auto md:mx-0 w-full flex justify-center md:justify-start">
<button  (click)="modalPageSelected=true" class="flex items-center rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] border border-customTeal">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
      </svg>
       Edit Page Selection
  </button>

</div>

<ng-container *ngIf="!pageSections.gettingStarted || !pageSections.gettingStarted.pageSelection || pageSections.gettingStarted.pageSelection.length == 0  ; then loader else list"></ng-container>

<button type="button"
    class="hidden rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
    data-te-toggle="modal"
    attr.data-te-target = "#{{modalPage}}"
    data-te-ripple-init
    data-te-ripple-color="light"
    id = "skipButton">
    Top Left
</button>

<ng-template #loader>
    <div class="flex flex-col md:justify-center w-full">
        <div class="flex flex-wrap  justify-center  md:justify-start md:mx-10 px-4 md:px-0 gap-x-5 gap-y-5">
            <div *ngFor="let number of getRange(1, 10)">
                <div class = "w-40 md:w-64 rounded-md h-36 animate-pulsing bg-gray-300 cursor-pointer hover:shadow-md"> 
                </div>
            </div>
        </div>
    </div>


</ng-template>

<!-- list of onboarding element -->
<ng-template #list>
    <div class="flex flex-row overflow-x-hidden w-full">
        <div class="w-1/12 hidden md:flex mt-14 justify-items-end" >
            <span class="w-full flex flex-col ml-5 justify-items-end">
                <span class="text-xl">START</span>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  height="18" width="80">
                    <line  class="cls-1" x1="0" y1="0" x2="170" y2="0" [ngStyle]="{'stroke': envService.getColor('primary')}"  style="stroke-width:100"/>
                </svg>
            </span>
        </div>
        <div class="flex flex-wrap  md:justify-start justify-center">
            <div *ngFor="let page of selectedPages  let i = index" class="w-44 md:w-60 lg:w-60 xl:w-80   ">
              <div  class="h-40 md:h-32 flex items-center justify-center mx-2 my-5 md:mx-0">
               
                <span class="w-11/12">
                    <app-list-item [pageName] = "getPageNameList(page)" [name]="page" id="page.originalName" [filled]="getStatus(page)" [isActivePage]="enabledPage(page)"  (pageSetup) = "pageSelected($event)"></app-list-item>
                </span>
                <span *ngIf="((selectedPages.length-1) != i)" class="hidden md:block w-1/12 " >
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  height="18" width="30">
                        <line  class="cls-1" x1="0" y1="0" x2="170" y2="0" [ngStyle]="{'stroke': envService.getColor('primary')}" style="stroke-width:100"/>
                    </svg>
                </span>
                 
              </div>
            </div>
            <div class="mt-12 hidden md:flex md:flex-col">
                <div class="ml-4 text-xl">FINISH</div>
                 <div>
                    <svg id="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="250" height="250" viewBox="0, 0, 400,400"><g id="svgg"><path id="path0" d="M123.704 15.149 L 123.704 30.000 61.852 30.000 L 0.000 30.000 0.000 41.481 L 0.000 52.963 61.848 52.963 L 123.695 52.963 123.792 67.784 L 123.889 82.604 153.326 62.251 C 169.516 51.057,182.813 41.745,182.876 41.558 C 182.960 41.304,130.976 5.009,124.760 0.982 L 123.704 0.298 123.704 15.149 "  
                        [ngStyle]="{'fill': envService.getColor('primary')}"  fill-rule="evenodd"></path></g></svg>
                </div>
            </div>
          </div>
    </div>
</ng-template>

<!-- Setup finish modal -->
<dialog id="my_modal_1" class="modal" [ngClass]="{'modal-open': gettingStartedPercentage == 100 && !closeFinalModal}">
    <form method="dialog" class="modal-box rounded-md shadow-md ">
        <h3 class="font-bold text-xl text-customTeal text-center">Congrats! you have made it through</h3>
        <p class="py-4 text-center text-base text-gray-500">You've completed the initial setup and unlocked all the sections, giving you the ability to customize your apps as you desire.</p>
        <div class="modal-action">
            <!-- if there is a button in form, it will close the modal -->
            <div class="flex space-x-4 items-center mx-auto">
                <button
                    type="button"
                    (click)="closeModal()"
                    class="inline-block rounded bg-white text-customTeal border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                    data-te-ripple-color="light" >
                    Close
                </button>
                <button 
                    class="inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
                    (click)="finishSetup(false)">All Done</button>
            </div>
        </div>
    </form>
</dialog>

<!-- On first login setup -->
<dialog id="my_modal_2" class="modal" [ngClass]="{'modal-open': pageSections.gettingStarted  ? ( !pageSections.gettingStarted.pageSelection ? true : pageSections.gettingStarted.firstLogin) : true}" >
    <form method="dialog" class="modal-box rounded-md shadow-md ">
      <h3 class="font-bold text-xl text-customTeal text-center">Welcome aboard!</h3>
      <p class="py-4 text-center  text-gray-500 ">
        Lets start building the foundation of your successful online presence !
To ensure a seamless experience, it's important to complete the app setup, which unlocks the full suite of functionalities.
        <br/>

    </p>
      <div class="modal-action">
        <!-- if there is a button in form, it will close the modal -->
      <div class="flex space-x-4 items-center mx-auto">
          <button
          type="button"
          class="inline-block rounded bg-customTeal text-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor text-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor text-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor text-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
          data-te-ripple-color="light"
          (click)="closeGetStartedModal()">
          Let’s begin
          </button>
      </div>
      </div>
    </form>
</dialog>

<!-- On Page Selection setup -->
<!-- <dialog id="my_modal_3" class="modal" [ngClass]="{'modal-open': getModalPageSelected() }" >
    <form method="dialog" class="modal-box rounded-md shadow-md max-w-6xl">
        <app-page-selection (pageSelected)="pageSelected('appSetup')" (pageUpdated)="reloadComponent(true)" (closeModal) =  'closePageSelectionModal()' [firstPageSelection]="firstSelection" ></app-page-selection>
    </form>
</dialog> -->

<app-modal-box [isOpen]="getModalPageSelected()" size="extraLarge" (close)="modalPageSelected = false" title="Please select the pages you would like to setup">
    <app-page-selection (pageSelected)="pageSelected('appSetup')" (pageUpdated)="reloadComponent(true)" (closeModal) =  'closePageSelectionModal()' [firstPageSelection]="firstSelection" ></app-page-selection>
</app-modal-box> 


<app-onboarding-modal [sectionName]="selectedPage" [allSteps]="currentStepItem" (skipPage)="skipPage($event)" (previousPage)="previousPage($event)" [displayName]="getPageNameList(selectedPage)" [isFinalPage]="isFinalPage" [originalName]="originalNameForOnboarding" [serviceType]="pageSections.serviceType" [appointmentType]="appointmentStatus()" (finalize)="goToSocial()" [finalStep]="selectedPages[selectedPages.length-1]"></app-onboarding-modal>




<!-- <app-preview-hompage class="fixed top-0 flex justify-end w-full z-[2300]" [modalOpen]='true' *ngIf="previewModal" (closeModal)="previewModal = false"></app-preview-hompage> -->