
<div class="w-full md:w-[650px] mx-auto rounded-md shadow-md border border-solid px-8 py-6 border-gray-300">
  <div class="py-4">
    <p class="text-gray-700 text-xl font-bold pb-1 ">Brand Setup</p>
    <div class="w-full py-0.5 bg-gray-200">
    </div>
  </div>

<form [formGroup]="appSettingsForm" (ngSubmit)="confirmUpdate(appSettingsForm.value)">
   
    <div>
        <!-- App name Section -->
        <div class="my-3  ">
          <label for="Title" class="block tracking-wide text-gray-700 text-base font-bold mb-2">
            {{envService.isNationApp ? 'App Name' : 'Business Name'}}
            <div class="tooltip" data-tip="This is what will show to the public">
              <span class="text-red-500">*</span>
              <button type="button"><i class="material-icons text-sm">help</i></button>
            </div>
          </label>
          <input
            class="input input-bordered rounded-md pt-2 lg:w-8/12 focus:outline-1 focus:outline-brandColor"
            id="Title" type="text" placeholder="Application Name" formControlName="appName"
            [ngClass]="{
              'border-red-500': (appName.dirty || appName.touched) && appName.invalid,
              'border-gray-300': !((appName.dirty || appName.touched) && appName.invalid)
            }"
            >
            <div *ngIf="(appName.dirty || appName.touched) && appName.invalid">
              <small *ngIf="appName.errors.required" class="text-red-500 italic"> App Name is required. </small>
              <small *ngIf="appName.errors.maxlength" class="text-red-500 italic"> Enter upto 30 characters. </small>
            </div>
        </div>
  
        <div class="my-2  ">
          <label for="Title" class="block tracking-wide text-gray-700 text-base font-bold mb-2"> Tagline </label>
          <input
            class="  appearance-none block input text-base py-[0.32rem] px-3 leading-[1.6] border-2 min-h-[auto] input-bordered rounded-md bg-white w-full  text-gray-700  focus:outline-none  focus:border-gray-500"
            id="welcomeMessage" type="text" placeholder="Welcome Message" formControlName="welcomeMessage">
         <div class="flex justify-between pt-2">
          <div class="text-blue-500 flex items-center text-xs space-x-2 w-3/4 "><i class="material-icons text-sm">info</i><span>Your
            tagline will show in your clients sign-up page</span></div>
            <div *ngIf="(welcomeMessage.dirty || welcomeMessage.touched) && welcomeMessage.invalid">
              <small *ngIf="welcomeMessage.errors.maxlength" class="text-red-500 italic"> Enter upto 100 characters. </small>
            </div>
        <div class="flex justify-end  space-x-2 w-1/2 ">
          <app-button class="justify-end items-end"
            [customConfig]="{ text: 'Use ChatGpt', type: 'Button', buttonType: 'secondaryButton' }"
            (click)="onUseChatGpt()"></app-button>
          <div class="tooltip tooltip-bottom my-2 text-white cursor-pointer"
            data-tip="ChatGPT is an invaluable tool for content creators seeking inspiration and assistance in generating high-quality content. With its vast knowledge and language capabilities, ChatGPT acts as a creative partner, helping to overcome writer's block and enhancing the content creation process. By simply engaging in a conversation, you can tap into the model's deep understanding of various topics, enabling you to brainstorm ideas, refine concepts, and explore new perspectives. Whether you need assistance with generating engaging headlines, crafting compelling narratives, or conducting research on a specific subject, ChatGPT provides valuable insights and suggestions to elevate your content to new heights. Its ability to adapt to different writing styles and tones ensures that the final output remains authentic and aligned with your vision. ChatGPT is a powerful ally, transforming the content creation experience into a collaborative and efficient journey.">
            <span class="font-bold text-lg text-customTeal">&#63;</span>
          </div>
        </div>
         </div>
        </div>
        <hr>
        <!-- Logo -->
        <div class="my-4">
          <div class="block tracking-wide text-gray-700 text-base font-bold ">Upload a logo for your application</div>

        <div class="flex  space-x-4 md:space-x-10">
          <div class="flex" *ngIf="!isMobile">
            <div class="flex items-center space-x-6">
         
              <div class="mt-2">
    
                <input (change)="fileChangeEvent($event.target.files)" accept="image/*"
                  class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                  type="file"
                  id="formFile" />
              </div>
            </div>

            <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
              <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
            </p>

          </div>

          <!-- For mobile -->
          <div class="" *ngIf="isMobile">
            <app-image-uploader class="justify-start items-start" (onImageUpload)=onImageUpload($event)
              [customConfig]="{'upload':false}"></app-image-uploader>
            <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
              <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
            </p>
          </div>

          <!-- Show Saved Logo -->
          <ng-container *ngIf="model">
            <div class="flex flex-wrap my-4 " *ngIf="model.logo && !base64Image">
              <div class="relative">
                <img loading="lazy" [src]="model.logo" class="w-32 border-2 shadow-md border-gray-100" alt="Image">
                <button (click)="removeSavedFile()"
                  class="absolute bottom-0 -right-5 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                  <i class="fa fa-trash text-md"></i>
                </button>
              </div>
            </div>
          </ng-container>

          <!-- Show Logo -->
          <div class="flex flex-wrap my-4" *ngIf="base64Image">


            <div class="relative" *ngIf="base64Image">
              <img loading="lazy" [src]="base64Image" class="w-32 border-2 border-gray-200 shadow-md" alt="Image">
              <button (click)="removeSavedFile()"
                class="absolute  bottom-0 left-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                <i class="fa fa-trash text-md"></i>
              </button>
            </div>

          </div>

        </div>

        <div class=""  *ngIf="(base64Image || (model && model.logo) && imageChangedEvent != '')">

          <button type="button" 
          class="flex space-x-2 items-center rounded border-2  border-brandColor-100 px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-brandColor-700 transition duration-150 ease-in-out hover:border-brandColor-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-brandColor-accent-100 focus:outline-none focus:ring-0 active:border-brandColor-accent-200 dark:text-brandColor-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          (click)="showPreview()">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM224 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM304 64H80V384H304V64z"/></svg>
          <span>Preview Logo for Mobile App</span></button>

        </div>
        </div>

        <hr>


        <!-- Theme color -->
        <div class="my-4">
          <p class="block tracking-wide text-gray-700 text-base font-bold mb-2">Theme color option</p>
          <a class=" col s12 btn bg-gray-100 border-none hover:bg-gray-200 text-black grey lighten-3 black-text"
            style="margin-bottom: 15px;">
            <label>Choose Color </label> <input formControlName="themeColor" type="color"
              class="themeColorSelector ml-5 h-10 w-10 float-right">
          </a>
        </div>
        <div class="w-full flex justify-end "><button     
          class="inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init
          [disabled]="!appSettingsForm.valid"
          [ngClass]="{'bg-[#cccccc] text-[#666666]': !appSettingsForm.valid}"
          data-te-ripple-color="light">Save</button></div>

      </div>


</form>



<div class="modal" id="previewToShow" *ngIf="previewToShow"
  [ngClass]="{'modal-open': previewToShow, 'modal-action': !previewToShow }">
  <div class="modal-box md:max-w-4xl rounded-md">
    <app-close-modal (closeBtnClicked)="previewToShow=false"></app-close-modal>

    <h2 class="font-bold text-lg text-center mb-2">Icons</h2>

    <div class="flex flex-col mt-4 items-center justify-center">
      <div class="flex md:flex-row flex-col space-x-10">
        <span class="flex-col"><img loading="lazy"  [src]="base64Image ? base64Image :model.logo "
            class="bg-black border-2 shadow-md  border-gray-100 rounded-lg object-cover" alt="Image" width="62" height="72">
          <p class="font-bold">72 X 72</p>
        </span>
        <span class="flex-col"><img loading="lazy" [src]="base64Image ? base64Image :model.logo "
            class="bg-black border-2 shadow-md border-gray-100 rounded-lg object-cover" alt="Image" width="76" height="86">
          <p class="font-bold">96 X 96</p>
        </span>
        <span class="flex-col"><img loading="lazy" [src]="base64Image ? base64Image :model.logo "
            class="bg-black  border-2  shadow-md border-gray-100 rounded-lg object-cover" alt="Image" width="108" height="108">
          <p class="font-bold"> 128 X 128</p>
        </span>
        <span class="flex-col"><img loading="lazy" [src]="base64Image ? base64Image :model.logo "
            class="bg-black shadow-md  border-2 border-gray-100 rounded-lg object-cover" alt="Image" width="152" height="152">
          <p class="font-bold">192 X 192</p>
        </span>
      </div>

      <div class="flex md:flex-row flex-col space-x-2 mt-4">
        <span class="flex-col"><img loading="lazy" [src]="base64Image ? base64Image :model.logo "
            class="bg-black  border-2 shadow-md border-gray-100 rounded-lg object-cover font-bold" alt="Image" width="216" height="216"> 256
          X 256</span>
        <span class="flex-col"><img loading="lazy" [src]="base64Image ? base64Image :model.logo "
            class="bg-black shadow-md  border-2 border-gray-100 rounded-lg object-cover font-bold" alt="Image" width="472" height="472"> 512
          X 512</span>

      </div>
    </div>
  </div>
</div>

</div>


<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [useBase64]="useBase64" [aspectRatio]="2 / 1"
  (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveUpdate()
  editedFromComponent='App Setup'></app-edit-confirmation>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteItem()
  (cancelDeleteClicked)="deleteModalOpen=false" deletedFromComponent='Section'></app-delete-confirmation>

<!-- Upload File Modal -->
<app-file-upload-modal *ngIf="uploadFileModalOpen" [mediaList]="mediaList"
  (returnSavedMediaURLs)="getTheUrlsSaved($event)"></app-file-upload-modal>

<!-- ChatGpt Modal -->
<app-chat-gpt *ngIf="chatGptModalOpen" [theInput]="selectedInput" [fromComponent]="'Tagline'"
  [requestMessage]="requestMessage" (onMessageSelect)="updateWelcomeMessage($event)"
  (closeChatGptModal)="closeChatGptModal()"></app-chat-gpt>