import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EnvironmentService } from 'src/app/services/helper/env';

@Component({
  selector: 'app-rearrange-cards',
  templateUrl: './rearrange-cards.component.html',
  styleUrls: ['./rearrange-cards.component.css'],

})
export class RearrangeCardsComponent implements OnInit {
  @Input() modalOpen;
  @Output() positionChange: EventEmitter<{ originalName: string; position: number }[]> = new EventEmitter();

   @Output() closeModal = new EventEmitter<string>();

  pageSections: any;
  cardElement = []
  position: number;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  defaultThemeColor = this.envService.getColor('primary');

  sortedCard = []
  rearrangePositions= []
  
  cardDesign =  {
    fontSize: '12px', 
    fontWeight: 'bold', 
    fontPosition: 'bottom', 
    cardShape: 'rectangle',
    imageShape: 'none', 
    imageStyle: 'full', 
    imageWidth: 60,
    imageHeight: 60,
    width: 160,
    height: 160, 
    boxColor: this.envService.getColor('primary'), 
    textColor: '#ffffff'
  }

  constructor(private settingsService: AppSettingsService,private envService: EnvironmentService,) { 
  }

  

  ngOnInit(): void {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          this.cardDesign = this.pageSections.cardDesign ? this.pageSections.cardDesign : this.cardDesign

          let index = 1
          let length = 1

          this.pageSections.appSections.forEach(section => {
    

            if(this.validSection(section) || section.isCustomSection){    
            
      
              let existingIndex = this.cardElement.findIndex(element => element.originalName == section.originalName);
              if (existingIndex !== -1) {
              
                // Element already exists, update it
                if( section.cardDetail && section.cardDetail.position){
      
                  this.cardElement[existingIndex] = {name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position ? section.cardDetail.position : null}
                }else if(section.cardDetail && !section.cardDetail.position ){
       
      
                  this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: index} 
                  index += 1
                  length +=1
                }else{
      
      
                  section.cardDetail = {
                    backgroundColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor : this.envService.getColor('primary'),
                    textColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.textColor:  '#FFFFFF',
                    borderRadius : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.borderRadius :  12,
                    boxWidth : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxWidth:  160,
                    boxHeight : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxHeight:  160,
                    backgroundImage :this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundImage:  '',
                    position: length
                  }
                  this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: length}
                  length +=1
                }
              } else {
      
      
                // Element doesn't exist, append it
                if(section.cardDetail && section.cardDetail.position){
      
      
                  this.cardElement.push({name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position}) 
                  length +=1
                }else if(section.cardDetail && section.cardDetail.position == 0){
      
                  this.cardElement.push({name:section.name,originalName:section.originalName, section: section, position: 0});
                  length +=1
                 }
                else if(section.cardDetail){
      
      
                  this.cardElement.push({name: section.name, originalName:section.originalName, section: section, position: index}) 
                  index += 1
                  length +=1
                }else{
      
      
                  section.cardDetail = {
                    backgroundColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor : this.envService.getColor('primary'),
                    textColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.textColor:  '#FFFFFF',
                    borderRadius : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.borderRadius :  12,
                    boxWidth : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxWidth:  160,
                    boxHeight : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxHeight:  160,
                    backgroundImage :this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundImage:  '',
                    position: length
                  }
                  this.cardElement.push({name: section.name, originalName:section.originalName, section: section, position: length}) 
                  length +=1
                }
              }
        
            }
         
          });
          this.cardElement = this.cardElement.slice().sort((a, b) => a.position - b.position)
      
    }}
  })
  }

  validSection(section) {
    let validSections = [
      "Login/Signup",
      "Events",
      "Blogs",
      "News",
      "Products",
      "Services",
      "About Us",
      "Footer",
      "faq"
    ];

    let chiefSections = ["First Nations Leaders"];
    return (
      (validSections.includes(section.originalName) ||
        (this.envService.isChiefApp && chiefSections.includes(section.originalName))) &&
      section.isEnabled || (section.isCustomSection)
    )
  }

  closeRearrangeModal(){
    this.closeModal.emit();

  }
  saveSettings(){

    for (const element of this.cardElement) {
      if(element.name == 'Login/Signup' && this.pageSections ){
          if(this.pageSections.loginCard){
            this.pageSections.loginCard={
              backgroundColor: this.pageSections.loginCard.backgroundColor , 
              textColor: this.pageSections.loginCard.textColor,
              boxWidth: this.pageSections.loginCard.boxWidth,
              boxHeight: this.pageSections.loginCard.boxHeight,
              borderRadius: this.pageSections.loginCard.borderRadius,
              backgroundImage: this.pageSections.loginCard.backgroundImage,
              position: element.position
            }
          }else{
            this.pageSections.loginCard = {
              backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor: this.defaultThemeColor,
              textColor: '#FFFFFF',
              borderRadius: 12,
              boxWidth: 160,
              boxHeight : 160,
              backgroundImage : '', 
              position: element.position
            }
          }
      

      }else {
           this.pageSections.appSections.forEach((section:any) => {
   
            if(element.name == section.name ){
              if(section && section.cardDetail){
                section.cardDetail ={
                  backgroundColor: section.cardDetail.backgroundColor, 
                  textColor: section.cardDetail.textColor,
                  boxWidth: section.cardDetail.boxWidth,
                  boxHeight: section.cardDetail.boxHeight,
                  borderRadius: section.cardDetail.borderRadius,
                  backgroundImage: section.cardDetail.backgroundImage,
                  position : element.position
                }
              }else if(!section.cardDetail && this.pageSections.defaultCardView){
                section.cardDetail ={
                  backgroundColor: this.pageSections.defaultCardView.backgroundColor, 
                  textColor: this.pageSections.defaultCardView.textColor,
                  boxWidth: this.pageSections.defaultCardView.boxWidth,
                  boxHeight: this.pageSections.defaultCardView.boxHeight,
                  borderRadius: this.pageSections.defaultCardView.borderRadius,
                  backgroundImage: this.pageSections.defaultCardView.backgroundImage,
                  position : element.position
                }
              }
              else{
                section.cardDetail = {
                  backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor: this.defaultThemeColor,
                  textColor: '#FFFFFF',
                  borderRadius: 12,
                  boxWidth: 160,
                  boxHeight : 160,
                  backgroundImage : '', 
                  position: element.position
                }
              }
            }
   
      });

    }
  }

  this.updateCard();
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.cardElement, event.previousIndex, event.currentIndex);
    const movedItem = this.cardElement[event.previousIndex];

    // Update position property for all elements
    this.updatePositions();
  }
  updatePositions(): void {
    this.cardElement.forEach((element, index) => {
      element.position = index;
    });
    this.notifyParent()
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
   
    }, 2000);
  }
  updateCard(){
    this.settingsService.updateAppSettings(this.pageSections).then(x => {
      this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
      this.closeRearrangeModal()
    });
  }

  notifyParent() {
    this.positionChange.emit(this.cardElement); // Emit the updated data to the parent
  }
}
