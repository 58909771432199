<div class="font-body min-h-screen max-h-max pt-6">

    <p class=" font-bold text-xl text-center text-customTeal my-2">Informations to be included in the Clients' Profile
    </p>

    <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4" *ngIf="!formExists">
        
        <form [formGroup]="skillsForm" (ngSubmit)="submitQuestions(skillsForm.value)">
            <!-- <form [formGroup]="skillsForm" (ngSubmit)="onSubmit()"> -->
            <div formArrayName="skills">
                <div *ngFor="let skill of skills.controls; let i=index">
                    <div [formGroupName]="i" class="border-2 border-customTeal mt-2 px-2 mb-2 ">

                        <div class="flex justify-end px-4 py-2 mt-2 bg-white " *ngIf="i > 0">
                            <a class="text-center" 
                                (click)="removeFile(i)"><i class="material-icons text-white flex justify-end bg-customTeal px-4 py-2 rounded-full">clear</i></a>
                        </div>     
                        <div class="flex space-y-2 md:w-full  flex-col mt-2">
                            <label class="font-bold text-sm">Question {{i+1}}: <span class="text-red-500">*</span>
                            </label>
                            <input
                                class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                id="question " type="text " placeholder="Question" formControlName="question">
                            <div *ngIf="(skill.controls.question.dirty ||
                            skill.controls.question.touched) &&
                            skill.controls.question.invalid">
                                <small *ngIf="skill.controls.question.errors.required" class="text-red-500"> The
                                    Question is required.
                                </small>
                            </div>
                        </div>
                        <br />

                        <div class="flex space-y-2 md:w-full flex-col mb-4">
                            <!-- <i class="material-icons text-teal ">add_task</i> -->
                            <label class="font-bold text-sm">Question Type: <span class="text-red-500">*</span> </label>
                            <select formControlName="answerType" required
                                class="validate py-3 px-4 select select-bordered w-full bg-veryLightGray"
                                (change)="answerTypeChange($event.target.value, i)">
                                <option value="" disabled selected>Please Select Answer Type</option>
                                <option *ngFor="let answer of answerTypes" [value]="answer.type" title="Tooltip">
                                    {{answer.type}}
                                    <span class="text-green-400"> ( {{answer.helpText}} )</span>
                                </option>
                            </select>

                            <div *ngIf="(skill.controls.answerType.dirty ||
                                        skill.controls.answerType.touched) &&
                                        skill.controls.answerType.invalid">
                                <small *ngIf="skill.controls.answerType.errors.required" class="text-red-500"> The
                                    Answer Type is required.
                                </small>
                            </div>
                        </div>

                        <div class="flex space-x-8 my-8" *ngIf="skill.controls.answerType.value === 'Scale'"
                            formGroupName="scales">
                            <div>
                                <!-- <i class="material-icons text-green-400">linear_scale</i> -->
                                <label for="start" class="font-bold">Start At</label>
                                <select formControlName="start"
                                    class="validate py-1 px-4 select select-bordered w-full bg-veryLightGray">
                                    <option value="0" class="px-2 py-4 text-center hover:bg-customTeal">0</option>
                                    <option value="1" class="px-2 py-4 text-center hover:bg-customTeal">1</option>
                                </select>
                            </div>

                            <div>
                                <!-- <i class="material-icons text-green-400">label</i> -->
                                <label for="startLabel" class="font-bold">Label</label>
                                <input id="startLabel" type="text" formControlName='startLabel'
                                    class="w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline" />
                            </div>

                            <div>
                                <!-- <i class="material-icons text-blue-500">linear_scale</i> -->
                                <label for="end" class="font-bold">End At</label>
                                <select formControlName="end"
                                    class="py-1 px-4 select select-bordered w-full bg-veryLightGray">
                                    <option *ngFor="let s of scales" [value]="s">{{s}}</option>
                                </select>
                            </div>

                            <div>
                                <!-- <i class="material-icons text-blue-500">label</i> -->
                                <label for="endLabel" class="font-bold">Label</label>
                                <input id="endLabel" type="text" formControlName='endLabel'
                                    class="w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline" />
                            </div>
                        </div>

                        <div *ngIf="skill.controls.answerType.value === 'Multiple Choice'">

                            <div class="row input-field col s12 m5 right">
                                <label class="flex">
                                    <input class="flex justify-end checkbox checked:bg-customTeal" type="checkbox"
                                        formControlName="allowMultipleAnswers" />
                                    <span>Allow Multiple Answers</span>
                                </label>
                            </div>
                            <br />
                            <div class="row input-field col s12" formArrayName="answers"
                                *ngFor="let ans of getAnswers(i).controls; let j = index;">
                                <div [formGroupName]="j">
                                    <div class="row input-field col s11">
                                        <!-- <i class="material-icons purple-text prefix">question_answer</i> -->
                                        <span class="font-bold text-sm">Choice {{j+1}}: </span>
                                        <input id="answer" type="text" formControlName="answer" required
                                            class="validate border mb-2 rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                            autocomplete="off" style="text-transform: capitalize;"
                                            placeholder="Answer" />
                                        <a *ngIf="j > 1" class="btn-flat waves-effect waves-light col s1 right"><i
                                                class="material-icons text-red-500"
                                                (click)="removeAnswer(i, j)">delete</i></a>
                                        <div
                                            *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                                            <small *ngIf="ans.controls.answer.errors.required" class="text-red-500">
                                                Answer {{j+1}} is
                                                required.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row col s12">
                                <button type="button" class="mb-4 bg-customTeal px-4 py-2 rounded-md"
                                    (click)="onAddAnswer(i)"
                                    [disabled]="!skillsForm.get('skills').controls[i].get('answers').valid"
                                    style="cursor: pointer;">
                                    <i class="material-icons text-white float-left">add_circle</i><span
                                        class="text-white">Add Option</span>
                                </button>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <button type="button" (click)="addQuestion()" [disabled]="!skillsForm.valid"
                    class="flex space-x-2 border-2 border-customTeal px-4 md:px-6 py-2 mt-2 text-customTeal rounded-lg w-max h-min text-sm md:text-base font-bold focus:outline-none  ">
                    <i class="material-icons text-customTeal">add_circle</i><span>Add Questions</span>
                </button>
            </div>


            <div class="pt-6 space-y-2 flex justify-between">
                <div class="">
                    <!-- <button type=""
                        class="flex space-x-2 px-4 md:px-6 pb-4 text-black rounded-lg w-max h-min text-sm md:text-base font-bold ">
                        <span>Cancel</span>
                    </button> -->
                </div>
                <div class="">
                    <button type="submit" [disabled]="!skillsForm.valid"
                        class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                        <span>Update Questions</span>
                    </button>
                </div>
            </div>
        </form>

    </div>

    <div>
        
    </div>

</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>