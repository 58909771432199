import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';

@Component({
  selector: 'app-guide-details',
  templateUrl: './guide-details.component.html',
  styleUrls: ['./guide-details.component.css']
})
export class GuideDetailsComponent implements OnInit {
  display: any;
  currentUser: any;
  isAdmin: boolean;
  model: any;

  defaultLogo= GlobalConstants.defaultLogo  
  currentAppName= GlobalConstants.currentAppName
  demoUrl= GlobalConstants.demoUrl

  constructor(private router: Router,
    private authService: AuthService,
    private settingService: AppSettingsService) {
    if (this.router.getCurrentNavigation().extras?.state != null) {
      this.display = this.router.getCurrentNavigation()
        .extras.state.displayHelp;
    }
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser && this.currentUser.role == "Admin") {
      this.isAdmin == true;
    }
  }

  ngOnInit(): void {

    this.settingService.getAppSettingsList().valueChanges().subscribe(settings => {
      if(settings){
        this.model = settings[0];
      }
    })
  }

}
  