
<br/>

<div class="container">

  <div class="row" *ngIf="NewsEventMenu">

    <div class="col s6 m4 l3" [@swing]="swing">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing">
      <a [routerLink]="['/news']">
        <div class="card pink hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">circle_notifications</i><br />
              <strong class="white-text">News</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3">
      <a [routerLink]="['/events-calendar']">
        <div class="card red darken-4 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">event_available</i>
              <br />
              <strong class="white-text">Events</strong>
            </div>
          </div>
        </div>
      </a>
    </div>




  </div>

<!-------------------------- Employers Only ------------------ -->
<div class="row" *ngIf="EmployersMenu">

  <div class="col s6 m4 l3" [@swing]="swing">
    <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
      <div class="card-content">
        <div class="row center">
          <i class="material-icons white-text">house</i>
          <br />
          <strong class="white-text">Back</strong>
        </div>
      </div>
    </div>
  </div>

  
  <div class="col s6 m4 l3" [@swing]="swing">
    <a [routerLink]="['/skills-databank']">
      <div class="card pink hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">search</i><br />
            <strong class="white-text">Search Skills Databank</strong>
          </div>
        </div>
      </div>
    </a>
  </div>



  <div class="col s6 m4 l3" [@swing]="swing">
    <a [routerLink]="['/create-account']">
      <div class="card teal hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">account_box</i><br />
            <strong class="white-text">Create Account</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s6 m4 l3" [@swing]="swing">
    <a [routerLink]="['/auth/employer-login']">
      <div class="card blue darken-2 " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">lock</i><br />
            <strong class="white-text">Employer Login</strong>
          </div>
        </div>
      </div>
    </a>
  </div>


</div>

<!-------------------------- Social Network ------------------ -->
<div class="row" *ngIf="socialNetworkMenu">
<!--
  <div class="col s6 m4 l3" [@swing]="swing">
    <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
      <div class="card-content">
        <div class="row center">
          <i class="material-icons white-text">house</i>
          <br />
          <strong class="white-text">Back</strong>
        </div>
      </div>
    </div>
  </div> -->

  <a class="col s12 m6" [href]="socialMedia.facebook" target="_blank">
    <div class="col s12">
      <div class="row card-panel white">
        <div class="col s12">
          <div class="col s2 center">
            <i class="fa fa-facebook blue-text"></i>
          </div>
          <div class="col s10">
            <strong class="black-text">Follow Us On Facebook</strong>
          </div>
        </div>
      </div>
    </div>
  </a>

  <a class="col s12 m6" [href]="socialMedia.twitter" target="_blank">
    <div class="col s12">
      <div class="row card-panel white">
        <div class="col s12">
          <div class="col s2 center">
            <i class="fa fa-twitter blue-text text-lighten-1"></i>
          </div>
          <div class="col s10">
            <strong class="black-text">Follow Us On Twitter</strong>
          </div>
        </div>
      </div>
    </div>
  </a>


  <a class="col s12 m6" [href]="socialMedia.instagram" target="_blank">
    <div class="col s12">
      <div class="row card-panel white">
        <div class="col s12">
          <div class="col s2 center">
            <i class="fa fa-instagram pink-text text-lighten-1"></i>
          </div>
          <div class="col s10">
            <strong class="black-text">Follow Us On Instagram</strong>
          </div>
        </div>
      </div>
    </div>
  </a>

  <a class="col s12 m6" [href]="socialMedia.youtube" target="_blank">
    <div class="col s12">
      <div class="row card-panel white">
        <div class="col s12">
          <div class="col s2 center">
            <i class="fa fa-youtube red-text text-darken-1"></i>
          </div>
          <div class="col s10">
            <strong class="black-text">Follow Us On YouTube</strong>
          </div>
        </div>
      </div>
    </div>
  </a>




</div>





<!-------------------------- Privacy & Terms ------------------ -->
<div class="row col s12" *ngIf="termsAndPolicy">

  <a class="col s12 m6" [routerLink]="['/terms-conditions']">
    <div class="col s12">
      <div class="row card-panel white">
        <div class="col s12">
          <div class="col s2 center">
            <i class="material-icons blue-text">description</i>
          </div>
          <div class="col s10">
            <strong class="black-text">Terms & Conditions</strong>
          </div>
        </div>
      </div> 
    </div>
  </a>

  <a class="col s12 m6" [routerLink]="['/privacy-policy']">
    <div class="col s12">
      <div class="row card-panel white">
        <div class="col s12">
          <div class="col s2 center">
            <i class="material-icons teal-text">security</i>
          </div>
          <div class="col s10">
            <strong class="black-text">Privacy Policy</strong>
          </div>
        </div>
      </div>
    </div>

  </a>

  <a class="col s12 m6" [routerLink]="['/eula']">
    <div class="col s12">
      <div class="row card-panel white">
        <div class="col s12">
          <div class="col s2 center">
            <i class="material-icons pink-text">article
            </i>
          </div>
          <div class="col s10">
            <strong class="black-text">End-User Licence Agreement</strong>
          </div>
        </div>
      </div>
    </div>

  </a>


  <!-- <div class="col s6 m4 l3" [@swing]="swing">
    <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
      <div class="card-content">
        <div class="row center">
          <i class="material-icons white-text">house</i>
          <br />
          <strong class="white-text">Back</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="col s6 m4 l3" [@swing]="swing">
    <a  [routerLink]="['/terms-conditions']">
      <div class="card purple darken-2 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">list</i><br />
            <strong class="white-text">Terms & Conditions</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s6 m4 l3" [@swing]="swing">
    <a [routerLink]="['/privacy-policy']">
      <div class="card teal hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">security</i><br />
            <strong class="white-text">Privacy Policy</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s6 m4 l3" [@swing]="swing">
    <a [routerLink]="['/eula']">
      <div class="card blue darken-2 " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">verified</i><br />
            <strong class="white-text">EULA</strong>
          </div>
        </div>
      </div>
    </a>
  </div> -->


</div>

<!-------------------------- Contact Us ------------------ -->
<div class="row" *ngIf="contactUs">

  <div class="col s6 m4 l3" [@swing]="swing">
    <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
      <div class="card-content">
        <div class="row center">
          <i class="material-icons white-text">house</i>
          <br />
          <strong class="white-text">Back</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="col s6 m4 l3" [@swing]="swing">
    <a  [routerLink]="['/contactus/chief']">
      <div class="card purple darken-2 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">list</i><br />
            <strong class="white-text">Chief</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s6 m4 l3" [@swing]="swing">
    <a  [routerLink]="['/contactus/councilor']">
      <div class="card pink darken-2 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">list</i><br />
            <strong class="white-text">Councillor</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

</div>


</div>
