import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare const gapi: any;
const hoursFromNow = (n) => new Date(Date.now() + n * 1000 * 60 * 60).toISOString();

@Injectable({
  providedIn: 'root'
})
export class CalendarAuthService {

  // private clientId = '976237079912-fi556ib89hrhlvffmio3ppgnmseg4k8f.apps.googleusercontent.com';
  // private apiKey = 'AIzaSyABUaTPm0cuDA0vd_ilh3_WzC91MgBU7WQ';
  private discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
  // private scopes = 'https://www.googleapis.com/auth/calendar';
  private redirectUri: "http://localhost:4200"

  private readonly clientId = '976237079912-fi556ib89hrhlvffmio3ppgnmseg4k8f.apps.googleusercontent.com';
  private readonly scope = 'https://www.googleapis.com/auth/calendar';
  public authInstance: any; // Holds the authentication instance
  private readonly apiKey = 'AIzaSyABUaTPm0cuDA0vd_ilh3_WzC91MgBU7WQ';

  user$: Observable<any>;
  calendarItems: any[];
  private isSignedIn = false;

  constructor() {
    // gapi.load('client:auth2', () => {
    //         gapi.auth2.init({
    //           clientId: this.clientId,
    //           scope: this.scope,
    //           apiKey: this.apiKey,
    //   // redirectUri: this.redirectUri
    //         }).then(() => {
    //           this.updateSignInStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    //           gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSignInStatus.bind(this));
    //         }).catch(x=> {
    //   });
    //       });

    this.initClient();
    // this.user$ = afAuth.authState;
  }

  handleSignIn() {
    gapi.auth2.getAuthInstance().signIn();
  }

  handleSignOut() {
    gapi.auth2.getAuthInstance().signOut();
  }

  updateSignInStatus(isSignedIn: boolean) {
    this.isSignedIn = isSignedIn;
  }

  initClient(): Promise<any> {
    return new Promise((resolve, reject) => {
      gapi.load('client:auth2', () => {
        gapi.client
          .init({
            apiKey: this.apiKey,
            clientId: this.clientId,
            discoveryDocs: this.discoveryDocs,
            scope: this.scope,
            redirectUri: this.redirectUri
          })
          .then(() => {
            // API client successfully initialized
            // resolve();
          })
          .catch((error: any) => {
            // Error occurred during API client initialization
            reject(error);
          });
      });
    });
  }

  login(): Promise<any> {
    return gapi.auth2.getAuthInstance().signIn();
  }

  // initClient(){
  //   gapi.load('client', () => {

  //     gapi.client.init({
  //       apiKey:'AIzaSyABUaTPm0cuDA0vd_ilh3_WzC91MgBU7WQ',
  //       clientId:'976237079912-fi556ib89hrhlvffmio3ppgnmseg4k8f.apps.googleusercontent.com',
  //       discoveryDocs:'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  //       scope:'https://www.googleapis.com/auth/calendar',
  //       redirectUri: 'http://localhost:4200'
  //     })

  //     gapi.client.load('calendar', 'v3', () => {});

  //   });
  // }

  async authorize() {
    // const googleAuth = gapi.auth2.getAuthInstance()
    // const googleUser = await googleAuth.signIn();

    // const token = googleUser.getAuthResponse().id_token;
    // const credential = auth.GoogleAuthProvider.credential(token);

    // await this.afAuth.signInAndRetrieveDataWithCredential(credential);


    // Alternative approach, use the Firebase login with scopes and make RESTful API calls
    // const provider = new auth.GoogleAuthProvider()
    // provider.addScope('https://www.googleapis.com/auth/calendar');
    // this.afAuth.signInWithPopup(provider)

    // this.afAuth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  logout() {
    // this.afAuth.signOut();
  }

  async getCalendar() {

    // const events = await gapi.client.calendar.events.list({

    //   calendarId: 'primary',
    //   timeMin: new Date().toISOString(),
    //   showDeleted: false,
    //   singleEvents: true,
    //   maxResults: 10,
    //   orderBy: 'startTime'
    // }).then(x=>{
    // }).catch(x=>{
    // })

    // this.calendarItems = events.result.items;

    var events = gapi.client.calendar.events.list({
      calendarId: 'primary',
      timeMin: new Date().toISOString(),
      showDeleted: false,
      singleEvents: true,
      maxResults: 10,
      orderBy: 'startTime'
    }).then(function (response) {
      var events = response.result.items;
      // Process the events here
    }).catch(function (error) {
      // console.error('Error fetching calendar events:', error);
    });

    this.calendarItems = events.result.items;
  }

  async insertEvent() {
    const insert = await gapi.client.calendar.events.insert({
      calendarId: 'primary',
      start: {
        dateTime: hoursFromNow(2),
        timeZone: 'America/Los_Angeles'
      },
      end: {
        dateTime: hoursFromNow(3),
        timeZone: 'America/Los_Angeles'
      },
      summary: 'Have Fun!!!',
      description: 'Do some cool stuff and have a fun time doing it'
    })

    await this.getCalendar();
  }

  createEvent() {
    if (!this.isSignedIn) {
      return;
    }

    const calendar = gapi.client.calendar;

    const event = {
      summary: 'Test Event',
      start: {
        dateTime: '2023-06-01T10:00:00',
        timeZone: 'UTC',
      },
      end: {
        dateTime: '2023-06-01T11:00:00',
        timeZone: 'UTC',
      },
    };

    calendar.events.insert({
      calendarId: 'primary',
      resource: event,
    }).then((response: any) => {
      // Handle the event creation response
    }).catch((error: any) => {
      // console.error('Error creating event:', error);
      // Handle the error
    });
  }


}
