import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MediaService } from 'src/app/services/api/media.service';
import { catchError, concatMap, from, map, Observable, of, throwError } from 'rxjs';
@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.css']
})
export class FileUploadModalComponent implements OnInit {

  @Input() mediaList;
  @Output() returnSavedMediaURLs = new EventEmitter<{ imageURL: string | null, videoUrl: string | null, pdfUrl: string | null}>();
  imageUrl = '';
  videoUrl = '';
  pdfUrl = '';
  uploadedFileTypes = [];

  constructor(private mediaService: MediaService,public router: Router,private elementRef: ElementRef, private renderer: Renderer2,
              private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.mediaList.forEach(element => {
      this.uploadedFileTypes.push(element.type);
    });
    
    // Create individual promises for video and image uploads
    let videoUploadPromise = new Promise<string | null>((resolve) => {
      const videoItem = this.mediaList.find(media => media.type === 'Video');
      if (videoItem) {
        this.mediaService.uploadVideo(videoItem.file).subscribe(
          upload => {
            if (upload) {
              this.videoUrl = upload.imgLink;
              resolve(this.videoUrl);
            } else {
              resolve(null);
            }
          },
          error => {
            resolve(null);
          }
        );
      } else {
        resolve(null);  // No video to upload
      }
    });
    
    let imageUploadPromise = new Promise<string | null>((resolve) => {
      const imageItem = this.mediaList.find(media => media.type === 'Image');
      if (imageItem) {
        this.mediaService.uploadImage(imageItem.file).subscribe(
          upload => {
            if (upload) {
              this.imageUrl = upload.imgLink;
              resolve(this.imageUrl);
            } else {
              resolve(null);
            }
          },
          error => {
            resolve(null);
          }
        );
      } else {
        resolve(null);  // No image to upload
      }
    });
    
    // Use Promise.all to run both upload processes in parallel
    Promise.all([videoUploadPromise, imageUploadPromise])
      .then(([videoUrl, imageUrl]) => {
        // Both uploads are completed (either success or null)
        this.videoUrl = videoUrl;
        this.imageUrl = imageUrl;
        this.returnSavedMediaURLList();
      })
      .catch(() => {
        // Handle any unexpected errors
        this.returnSavedMediaURLList();
      });
  }

  ngAfterViewInit() {
    this.setCustomColor();
  }
  private setCustomColor() {
    const elements = this.elementRef.nativeElement.getElementsByClassName('customColor');

    for (const element of elements) {
      this.renderer.setStyle(element, 'fill', '#2271b2');
    }
  }

  returnSavedMediaURLList(){
    this.returnSavedMediaURLs.emit({
      imageURL: this.imageUrl,
      videoUrl: this.videoUrl,
      pdfUrl: this.pdfUrl
    });
  }

}
