import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { Location } from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from './../../../../models/ImageModel';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { HelperService } from 'src/app/services/helper/helper';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { FCMService } from 'src/app/services/api/fcm.service';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { ChangeVisibilityService } from 'src/app/services/firebase/change-visibility.service';

@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.css']
})
export class CreateServiceComponent implements OnInit {
  @Input() calledFromEmptyDashboard = ''
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() serviceCreated = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();
  @Output() previewClosed = new EventEmitter<string>();
  @Output() previewSelected = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();

  createServiceForm: UntypedFormGroup;
  currentUser: any;
  model: any;
  binDoc = undefined;
  base64Image = undefined;
  imgErrorMsg: any;
  hasTimer = false;
  employees = []
  adminThemeColor: '';
  selectedEmployee = [];
  pricing = '';
  selectedEmployeeName = []
  videoLink: string = null;
  imageLink: string = null;
  videoUpload: File = null;
  attachedVideo: any;
  isMobile = false;
  mobileBase64Image: any;
  imgURL: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  step = 1;
  isList;
  servicesName;
  currency;
  notifSettings;
  permission: any;
  haveAccessPermission = false;
  preview = false;
  modelToPreview:any = {}
  service: any;
  cancelModalOpen = false;
  createModalOpen = false;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';
  numberPattern = /^[-+]?(\d*\.\d+|\d+\.|\.\d+|\d+)([eE][-+]?\d+)?$/;
  cropModalOpen = false;
  imageChangedEvent: any = '';

  permMember: any;
  permPES: any;
  permAnalytics: any;
  permSocial: any;
  permSpecial: any;
  perm: any;
  useBase64 = false
  uploadFileModalOpen = false;
  mediaList = [];
  newServiceValues: any = {};
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: ''
  };
  serviceRef: any;
  onLoading = false;
  createItemUnderServiceModal = false;
  editItemUnderServiceModal = false;
  allServices = [];
  serviceCategoryUnderToBeEdited: any;
  appSetting: any;
  addDetailToService = true;
  doesAnyoneHaveSchedule = false;
  employeeScheduleIDs = [];

  // to ask the admin if he/she wants to send notification
  showPushNotificationModal = false;
  serviceData;
  appSettings$: Subscription;
  canTakeAppointment = false;

  constructor(private fb: UntypedFormBuilder,
    private empService: EmployeeServicesService,
    private authService: AuthService,
    private location: Location,
    private appSettingsService: AppSettingsService,
    private userService: UserService,
    private productsServiceMenuService: ProductsServiceMenuService,
    private helperService: HelperService,
    private permissionService: PermissionService,
    private apiService: FCMService,
    private scheduleService: ScheduleService,
    private notificationPreferenceService: NotificationPreferenceService,
    private store: Store,
    private changeVisibilityService: ChangeVisibilityService
  ) {
    this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
      this.appSetting = this.helperService.cloneDeep(settings);
      this.serviceRef = this.appSetting.appSections.filter(s => s.originalName === 'Services')[0];
      this.canTakeAppointment = this.appSetting.takeAppointment;
    });
    if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web') {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.role === 'Employee') {
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe((perm: any) => {
          if (perm && perm.length > 0) {
            this.perm = perm[0];
            //this.permission = this.perm.permission;

            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];

            if (this.permPES.createServices) {
              this.haveAccessPermission = true;
            }
          }
        });
      }
      if (this.currentUser.role === 'Admin') {
        this.haveAccessPermission = true;
      }
    }
    this.createServiceForm = this.fb.group({
      createdById: [''],
      serviceName: ['', Validators.required],
      serviceDescription: [''],
      serviceHour: [0, [Validators.min(0), Validators.max(23)]],
      serviceMinute: [0, [Validators.min(0), Validators.max(59)]],
      titleColor: [''],
      backgroundColor: [''],
      hasPrice: [''],
      price: ['', [Validators.pattern(this.numberPattern)]],
      imgLink: [''],
      videoLink: [''],
      shareWithPublic: [, Validators.required],
      sharedLink: [''],
      showEmployees: [],
      assignedTo: this.fb.array([]),
      serviceOffers: this.fb.array([]),
      category: [''],
      hasAppointment: [],
      bufferTime: [0],
      hasSubServices: [, Validators.required],
      commentable: [true]
    });

    this.scheduleService.getAllSchedules().valueChanges().subscribe(schs => {
      if (schs.length > 0) {
        this.doesAnyoneHaveSchedule = true;
        schs.forEach((sch: any) => {
          this.userService.getUserById(sch.employeeId).valueChanges().subscribe((user: any) => {
            if(user.length > 0){
              if(this.employees.filter(emp => emp.uid==user[0].uid).length == 0){
                this.employees.push(user[0]);
                this.assignedTo.push(this.newEmployee(user[0]));
              }
            }
          })
          this.employeeScheduleIDs.push(sch.employeeId);
        })
      }
    })

    if (this.currentUser && (this.currentUser.role == 'Employee' || this.currentUser.role == 'Admin')) {
      this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.notifSettings = x[0]
        }
      })
    }

    this.productsServiceMenuService.servicesName.subscribe(p => {
      this.servicesName = p
    })

    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.createServiceForm){
      this.previewChanges(this.createServiceForm.value)
    }
  }

  get serviceName() { return this.createServiceForm.get('serviceName'); }
  get sharedLink() { return this.createServiceForm.get('sharedLink'); }
  get serviceDescription() { return this.createServiceForm.get('serviceDescription'); }
  get imgLink() { return this.createServiceForm.get('imgLink'); }
  get hasAppointment() { return this.createServiceForm.get('hasAppointment'); }
  get hasPrice() { return this.createServiceForm.get('hasPrice'); }
  get showEmployees() { return this.createServiceForm.get('showEmployees'); }
  get shareWithPublic() { return this.createServiceForm.get('shareWithPublic'); }
  get assignedTo() { return (<UntypedFormArray>this.createServiceForm.get('assignedTo')); }
  get serviceOffers() { return (<UntypedFormArray>this.createServiceForm.get('serviceOffers')); }
  get bufferTime() { return this.createServiceForm.get('bufferTime'); }
  get hasSubServices() { return this.createServiceForm.get('hasSubServices'); }
  get price() { return this.createServiceForm.get('price'); }

  newEmployee(employee, value = false) {
    return this.fb.group({
      employee: [employee],
      value: [value]
    });
  }

  addServieOffer() {
    this.serviceOffers.push(this.fb.control('', Validators.required));
  }
  removeOffer(index) {
    this.serviceOffers.removeAt(index);
  }
  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }

  getInnerText(htmlString) {
    this.helperService.getInnerText(htmlString);
  }

   // hide push notification modal
   cancelPushNotification() {
    this.showPushNotificationModal = false;
    this.backClicked();
  }

  sendPushNotification() {
    let title = this.truncateHTML(this.serviceData?.serviceName, '200')

    let notification = new NotificationModel()
    notification.title = 'Service'
    notification.body = this.truncateHTML(this.serviceData?.serviceName, '200')
    this.sendPushNotificationToUser(notification)
    this.cancelPushNotification()

    // send notification for the admin , if it is created by the employee
    if (this.notifSettings && this.currentUser && this.currentUser.role === "Employee") {
      this.sendPushNotificationToOwner(title)
    }
  }

  // send notification using role
  sendNotificationByRole(notification, role) {
    try {
      this.apiService
        .senFCMMessageToTopic(
          notification?.title,
          notification?.body,
          "",
          role === "User" ? "User_Notification" : "Owner_Notification"
        )
        .subscribe((data) => {
          // this.successMessage("Message sent successful");
        });
    } catch (err) {
      // this.errorMessage("Subscription failed");
    }

  }

  pricingSet(price: string) {
    this.pricing = price;
  }

  // send notification to user
  async sendPushNotificationToUser(notification) {

    if (this.notifSettings && this.currentUser && (this.currentUser.role === "Admin" || this.currentUser.role === "Employee")) {

      // check for whom to share the event with
      if (this.shareWithPublic?.value) {
        // share the event for users
        await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['User', 'Employee'], 'events')

      }
      else {
        // share the event for users
        await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['User'], 'events')

      }
    }
  }

  async sendPushNotificationToOwner(serviceName) {
    if (this.notifSettings && this.currentUser && this.currentUser.role === "Employee") {

      let createdByFullName = this.currentUser.GivenName + ' ' + this.currentUser.LastName
      let notification = new NotificationModel()
      notification.title = 'Employee Created a new Service'
      notification.body = `${createdByFullName} created a new service - ${serviceName}.`

      await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['Admin'], 'services')
    }
  }

  changeVisibility() {
    this.changeVisibilityService.updateAppSections('Services');
  }

  saveService(value: any) {
    this.createModalOpen = false;
    this.onLoading = true;
    if (this.allServices.length === 0) {
      // this.noItemAddedModal = true;
    }
    const model = {
      createdById: this.currentUser.uid,
      serviceName: value.serviceName,
      serviceDescription: value.serviceDescription,
      serviceMinute: value.serviceMinute,
      serviceHour: value.serviceHour,
      hasPrice: value.hasPrice,
      price: value.price,
      titleColor: value.titleColor || '',
      backgroundColor: value.backgroundColor || '',
      imgLink: this.imageLink,
      videoLink: this.videoLink,
      serviceTime: '',
      sharedLink: value.sharedLink,
      shareWithPublic: value.shareWithPublic,
      hasAppointment: value.hasAppointment,
      showEmployees: value.hasAppointment ? value.showEmployees : false,
      assignedTo: [],
      serviceId: value.serviceName.replace(/\s/g, ''),
      serviceOffers: value.serviceOffers,
      bufferTime: value.bufferTime ? value.bufferTime : 0,
      allServices: this.allServices,
      hasSubServices: value.hasSubServices ? value.hasSubServices : false,
      commentable: value.commentable,
      reactions: {
        likes: {
            count: 0,
            userId: [],
            usernames: []
          }
        },
        comments: [],
    };
    this.newServiceValues = model;

    if (this.pricing == 'paid') {
      model.price = value.price;
      model.hasPrice = true;
    } else if (this.pricing == 'dontInclude') {
      model.price = 0;
      model.hasPrice = false;
    }

    if (model.showEmployees) {
      model.assignedTo = value.assignedTo.reduce((res, p) => {
        if (p.value) { res.push(p.employee.uid); }
        return res;
      }, [])
    }

    if (value.serviceMinute === 0 && value.serviceHour === 0) {
      model.serviceTime = '0';
    } else {
      model.serviceTime = `${value.serviceHour}:${value.serviceMinute}`;
    }

    if (this.mediaList.length > 0) {
      this.uploadFileModalOpen = true;
    } else {
      this.createService(model)
    }

  }
  createService(model) {
    if(this.calledFromEmptyDashboard){
      this.changeVisibility();
    }

    this.empService.createService(model).then(() => {
      // if (model.shareWithPublic) {
      // if(this.calledFromEmptyDashboard){
        this.changeVisibility();
      // }
      // }      
      // set product data , so that oit can be accessble by the send push notificaton method
      this.showPushNotificationModal = true;
      this.serviceData = model;

      this.toast({ html: 'Successfully created a service', classes: 'green', type: 'success' });
      this.onLoading = false
      this.closeCollapseFromEmptydashboard()
      this.serviceCreated.emit('serviceCreated')
      this.goToTopOfNextPage.emit();
    }).catch((err) => {
      this.toast({ html: err, classes: 'red', type: 'failure' });
      this.onLoading = false

    });
  }

  closeCollapseFromEmptydashboard() {
    this.closeCollapse.emit('createService');
  }

  employeeSelected(employee) {
    if(employee.value.value){
      this.selectedEmployeeName.push(employee.value.employee);
    } else{
      this.selectedEmployeeName = this.selectedEmployeeName.filter(sen =>{
        return sen.uid != employee.value.employee.uid
      })
    }
  }

  backClicked() {
    this.location.back();
  }

  handleVideoInput(files: FileList) {
    this.binDoc = files[0];
    this.videoUpload = files[0]
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachedVideo = reader.result as string;
    };
    this.mediaList.push({ type: 'Video', file: this.videoUpload });

  }

  removeImage() {
    this.base64Image = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== 'Image') {
        return file;
      }
    })
  }
  removeVideo() {
    this.videoUpload = undefined;
    this.attachedVideo = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== 'Video') {
        return file;
      }
    })
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (this.calledFromEmptyDashboard != 'Service' && !this.showPushNotificationModal) {
        this.backClicked()
      }
    }, 2000);
  }

  closeCancelModal() {
    this.cancelModalOpen = false;
  }
  previewChanges(value) {
    // this.modelToPreview = {};
    this.modelToPreview.id = 'modeltopreviewid';
    this.modelToPreview.serviceName = value.serviceName;
    this.modelToPreview.serviceDescription = value.serviceDescription;
    this.modelToPreview.serviceMinute = value.serviceMinute;
    this.modelToPreview.serviceHour = value.serviceHour;
    this.modelToPreview.price = value.price
    this.modelToPreview.titleColor = value.titleColor || '';
    this.modelToPreview.backgroundColor = value.backgroundColor || '';
    this.modelToPreview.sharedLink = value.sharedLink;
    this.modelToPreview.hasAppointment = value.hasAppointment;
    this.modelToPreview.assignedTo = [];
    this.modelToPreview.serviceId = value.serviceName.replace(/\s/g, '');
    this.modelToPreview.serviceOffers = value.serviceOffers;
    this.modelToPreview.allServices = this.allServices? this.allServices : '';
    if (this.base64Image) {
      this.modelToPreview.imgLink = this.base64Image;
    }
    if (this.attachedVideo) {
      this.modelToPreview.videoLink = this.attachedVideo;
    }
    this.service = this.modelToPreview;
    // this.preview = true;
    if(this.calledFromEmptyDashboard == 'Service'){
      this.previewSelected.emit()
    }
  }
  
  closeCreateModal() {
    this.createModalOpen = false;
  }

  onImageUpload(response: ImageUploaderResponseModel) {
    if (response.error) {
      this.imgErrorMsg = response.errorMessage
      return
    }
    // this.binDoc = response.binDoc;
    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64 = true
    // this.cropModalOpen = true;
  }

  fileChangeEvent(event) {
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    // this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }
  validateImage(name: any) {
    return this.helperService.validateImage(name);
   }
  closeCroppingModal() {
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  getTheUrlsSaved(service) {
    this.returnedMediaLinks = service;
    this.uploadFileModalOpen = false;
    this.newServiceValues.imgLink = this.returnedMediaLinks.imageURL;
    this.newServiceValues.videoLink = this.returnedMediaLinks.videoUrl;
    this.createService(this.newServiceValues)
  }

  processCroppedImage(event) {
    this.cropModalOpen = false;
    this.useBase64 = false;
    this.base64Image = event;
    // this.binDoc = this.base64Image;
    let base64result = this.base64Image.split(',')[1];
    const img = new ImageModel();
    if (this.isMobile) {
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({ type: 'Image', file: img });
    } else {
      img.imgBase64 = base64result;
      this.mediaList.push({ type: 'Image', file: img });
    }
  }

  addNewServiceDetails() {
    this.selectedEmployeeName = [];
    this.createItemUnderServiceModal = true;
    this.clearUnderCategoryForm()
  }
  addNewCategoryUnder(event) {
    this.allServices.push(event);
    this.createItemUnderServiceModal = false;
    this.clearUnderCategoryForm();
  }
  saveUpdateToCategoryUnder(event) {
    // Filter the first one
    this.allServices = this.allServices.filter(s => s.serviceTitle != this.serviceCategoryUnderToBeEdited.serviceTitle);
    this.allServices.push(event);

    this.editItemUnderServiceModal = false;
  }

  cancelCategoryUnder() {
    this.createItemUnderServiceModal = false;
    this.editItemUnderServiceModal = false;
  }

  removeServiceUnder(service) {
    this.allServices = this.allServices.filter(s => s.serviceTitle != service.serviceTitle);
  }
  editServiceUnder(serviceUnder) {
    this.serviceCategoryUnderToBeEdited = serviceUnder;

    // this.clearUnderCategoryForm();
    this.editItemUnderServiceModal = true;
  }
  clearUnderCategoryForm() {
    this.assignedTo.clear();
    this.employees.forEach(p => {
      if (!this.assignedTo.value.some(x => x.employee.uid === p.uid)) {
        this.assignedTo.push(this.newEmployee(p));
      }

    });
  }

  hasSubServiceClicked(value) {
    if (value) {
      this.createServiceForm.controls["hasAppointment"].clearValidators();
      this.createServiceForm.controls["hasAppointment"].updateValueAndValidity();
    }
    else {
      if (this.appSetting.takeAppointment) {
        this.createServiceForm.controls["hasAppointment"].setValidators(Validators.required);
        this.createServiceForm.controls["hasAppointment"].updateValueAndValidity();
      }
    }
  }
  skipSetup(){
    this.skip.emit();
  }
  cancelCreatingService(){
    this.cancelModalOpen=false;
    this.backClicked();
  }

  closePreviewModal(){
    if(this.calledFromEmptyDashboard == 'Service'){
      this.previewClosed.emit()
    }
  }
  checkIfAppointment(hasSubServices, takeAppointment){
    return !hasSubServices && 
      takeAppointment && 
      ['yes', true].some(val => takeAppointment.includes(val));
    }
}
