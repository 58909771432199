<!-- USER PART -->
<div class="mb-10">
  <div class="flex flex-col md:flex-row justify-between gap-y-3 gap-x-8 p-4 md:p-12 ">
    <div class="flex flex-col space-y-4 md:w-2/3">

      <!-- DATE FILTER DROPDOWN -->
      <div class="flex justify-center md:justify-end">
        <div tabindex="0" class="ml-3 collapse collapse-arrow w:3/12 md:w-3/12 ">
          <input type="checkbox" [(ngModel)]="dateFilterActive" name="DateFilter" ngDefaultControl>
          <div class="collapse-title font-medium bg-white">
            <div class="flex flex-row ">

              <i class="material-icons mr-4 float-left">calendar_today</i>

              <span><b>Date</b></span>

            </div>

          </div>
          <div class="collapse-content bg-white">

            <div>
              <button (click)="dailyUsers()"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ">
                <span><b>Daily</b></span>
              </button>
              <hr />
            </div>
            <div>
              <button (click)="weeklyUsers()"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100">
                <span><b>Last 7 days</b></span>
              </button>
              <hr />
            </div>
            <div>
              <button (click)="monthlyUsers()"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                <span><b>Last 30 days</b></span>
              </button>
              <hr />
            </div>
            <div>
              <button (click)="customDateForUsers()"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                <span><b>Between dates</b></span>
              </button>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <!-- END DATE FILTER DROPDOWN -->

      <!-- NEW DATE PICKER START-->

      <div *ngIf="filterByDateUser">
        <div class="w-full  bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm">
          <div class="flex space-x-4 items-center">
            <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
            </svg>
            <div class="w-10/12">
              <p class="font-bold text-lg">Date </p>
              <p class="text-xs text-gray-500">Enter the start date, end date to filter the user</p>
            </div>
          </div>
          <div class="flex justify-center">
            <div class="w-full ">
              <div class=" w-full md:w-1/2">
                <div class="w-full px-3 md:px-0 md:mb-0">
                  <label class="label">
                    <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Start Date
                      <span class="text-red-500">*</span>
                    </span>
                  </label>
                </div>

                <div class="w-full  px-3 md:mb-0">
                  <div class=" bg-gray-100 p-1 rounded-lg" [formGroup]="createCalenderForm">
                    <input id="analyticsStartDate" formControlName="analyticsStartDate" type="date"
                      class="input w-full  rounded-sm bg-gray-100 text-gray-700" [ngClass]="{
                        'border-red-500': (analyticsStartDate.dirty || analyticsStartDate.touched) && analyticsStartDate.invalid,
                        'border-gray-200': !((analyticsStartDate.dirty || analyticsStartDate.touched) && analyticsStartDate.invalid)
                      }" (change)="dateChangeStartDate()">
                  </div>
                </div>
              </div>




            </div>

            <!-- Errors -->
            <!-- <div>
                <div *ngIf="(analyticsStartDate.dirty || analyticsStartDate.touched) && analyticsStartDate.invalid">
                  <small *ngIf="analyticsStartDate.errors.required" class="text-red-500 italic">Event start date is required.
                  </small>
                  <small *ngIf="analyticsStartDate.errors.invalidStartDate" class="text-red-500 italic">Event start date is invalid.
                  </small>
                </div>
               
              </div> -->
            <br />

            <div class="w-full">
              <div class="w-full md:w-1/2 ">
                <div class="w-full px-3 md:px-0 md:mb-0">
                  <label class="label">
                    <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">End Date
                      <span class="text-red-500">*</span>
                    </span>
                  </label>
                </div>
                <div class="w-full  px-3 md:mb-0">
                  <div class=" bg-gray-100 p-1 rounded-lg" [formGroup]="createCalenderForm">
                    <input id="analyticsEndDate" formControlName="analyticsEndDate" type="date"
                      class="input w-full leading-tight rounded-sm bg-gray-100 text-gray-700" [ngClass]="{
                  'border-red-500': (analyticsEndDate.dirty || analyticsEndDate.touched) && analyticsEndDate.invalid,
                  'border-gray-200': !((analyticsEndDate.dirty || analyticsEndDate.touched) && analyticsEndDate.invalid)
                }" (change)="dateChangeEndDate()">
                  </div>
                </div>
              </div>


            </div>

            <!-- Errors -->
            <div>
              <!-- s
                  <small *ngIf="analyticsEndDate.hasError('invalid')" class="text-red-500">End date must be after/the same as
                    start date! </small>
                </div> -->
            </div>

          </div>
          <p *ngIf="dateInvalid" class="text-red-600">Please enter the dates correctly. End date should be greater than
            Start date.</p>
          <div class="flex-wrap text-center  text-md rounded-full items-center justify-center mx-4 mt-6 ">
            <button
              class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
              (click)="customDateUpdateUsers(analyticsStartDate, analyticsEndDate)">Apply</button>
          </div>
        </div>
      </div>

      <!-- NEW DATE PICKER END -->

      <!-- SESSION INPUT FILTER -->
      <div class="pt-2 md:pt-0 flex flex-col-reverse md:flex-row  justify-between">
        <div class="flex-col md:flex-row">
          <label for="exampleNumber0" class="form-label inline-block text-sm text-gray-700">Sessions</label>
          <input type="number" class="
                form-control
                block
                w-full
                px-3
                py-3
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              " id="sessionValue" placeholder="Sessions" (keyup)="onKey($event, 'sessionValue')" />
        </div>

      </div>
      <div class=" bg-white w-full  md:w-3/12 rounded-md px-3 py-10 justify-center">
        <div class="flex-row  text-center px-7 ">

          <div class="tooltip " data-tip="Total amount of people who have accounts on your app.">
            <i class="material-icons flex justify-end">info</i>

          </div>
          <p class="font-bold text-customTeal text-lg md:text-md ">{{totalSignedInUsers}}</p>
          <p class="text-xs "> Subscribers</p>
          <br />

        </div>
        <div class="p-5">
          <hr />
        </div>
        <div class="flex-row  text-center px-7">

          <div class="tooltip" data-tip="Total number of users that visited your site without creating an account.">
            <i class="material-icons flex justify-end">info</i>

          </div>
          <p class="font-bold text-customTeal text-lg md:text-md">{{totalUnsignedInUsers}}</p>
          <p class="text-xs "> Non-subscribers</p>

        </div>
      </div>

      <div class="mx-2 lg:mx-2 mt-10"><strong>Subscribers Analytics</strong></div>

      <div>
        <!-- USER GRAPH -->
        <div class="flex mt-2">
          <div *ngIf="(startDate && endDate  && !isUndefinedFilterRes() && userIdAndName.length > 0)"
            class="w-full bg-white rounded-lg">

            <div>
              <ngx-charts-bar-vertical-2d
  [results]="dataVBC"
  [view]="viewVBC"
  [animations]="animationsVBC"
  [legend]="legendVBC"
  [xAxis]="xAxisVBC"
  [yAxis]="yAxisLabelVBC"
  [showYAxisLabel]="showYAxisLabelVBC"
  [yAxisLabel]="yAxisLabelVBC"
  [dataLabelFormatting]="dataLabelFormatterVBC"
  [barPadding]="20"
  class="custom-bar-chart">
</ngx-charts-bar-vertical-2d>
              <!-- <canvas baseChart chartType="bar" [datasets]="userchart1.datasets" [labels]="userchart1.labels"
                [options]="userchart1.options" legend="true">
              </canvas> -->
            </div>

          </div>
        </div>

        <div class="flex mt-2">

          <div *ngIf="(startDate && endDate && isUndefinedFilterRes() && userIdAndName == 0)"
            class="w-full bg-white rounded-lg">
            <div class="relative w-full h-auto  overflow-hidden"
              *ngIf="startDate && endDate && isUndefinedFilterRes() ">
              <p class="absolute py-2.5  inset-x-0 text-center mt-10   leading-4 ">Sorry, seems like there isn't
                enough data for analysis.</p>
              <p class="absolute py-10 inset-x-0 mt-10 text-center">Graph will be available after few visits.</p>
              <img

              class="object-cover w-full h-auto"
                          
              data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                          
              srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                          
              fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/graph.png"
                          
              alt="Null data, no data graph.">


            </div>
          </div>


        </div>

      </div>


      <!-- <ngx-charts-bar-vertical
  [results]="dataVBC"
  [view]="viewVBC"
  [animations]="animationsVBC"
  [legend]="legendVBC"
  [xAxis]="xAxisVBC"
  [yAxis]="yAxisVBC"
  [showYAxisLabel]="showYAxisLabelVBC"
  [yAxisLabel]="yAxisLabelVBC"
 
></ngx-charts-bar-vertical> -->

    </div>

    <div class="flex flex-col md:w-2/6 gap-y-4">
      <div class="bg-white rounded-md p-6 space-y-6">
        <p class="text-center text-lg font-bold">Top 10 Subscribers</p>
        <div class="flex justify-between pl-8" *ngIf="!isUndefinedTopUser() && topUsers.length > 0">

          <p class="text-xs ">Subscribers</p>

        </div>

        <!-- LIST OF SUBSCRIBERS DETAIL -->
        <div *ngIf="startDate && endDate && !isUndefinedTopUser() && topUsers.length > 0">
          <!-- <div *ngFor="let item of topUsers  | filter:search_text | 
           paginate: { itemsPerPage: 10, 
           currentPage: page,
           totalItems: totalLength}; let i=index"
            class="flex flex-row bg-white cursor-pointer items-center  mt-5 p-5  px-5 py-2 md:space-x-6"> -->
 
            <div *ngFor="let item of topUsers  | filter:search_text   let i=index"
             class="flex flex-row bg-white cursor-pointer items-center  mt-5 p-5  px-5 py-2 md:space-x-6">

             
            <div class="w-full space-y-3">
              <div class="flex ">
                <b>
                  <p class=" ">{{i+1}}</p>
                </b>
                <div class="flex justify-between pl-6  w-full ">
                  <b>
                    <p class=" ">{{item}}</p>
                  </b>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>
        <div class=" justify-center w-full mt-8 bg-white rounded-md p-6 space-y-6"
          *ngIf="startDate && endDate && isUndefinedTopUser() && topUsers.length == 0">
          <img
          loading="lazy"

          class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/searchEmoji.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/searchEmoji.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/searchEmoji.png"
                      
          alt="Search emoji image.">

          <p class="text-center">Top subscribers will show up here.</p>

        </div>
      </div>
      <div class="mx-2 lg:mx-8 mt-10"><strong>Top Countries</strong></div>
      <!-- CHECKBOK logged in vistors/ non-logged in visitors  -->

      <div class="flex md:flex-row flex-col px-6 py-2 justify-between">
        <div class="">

          <div>
            <input
              class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-2 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer"
              type="checkbox" value="" id="flexCheckDefault" (click)="loggedInStatusChecker()"
              [checked]="isLoggedInChecked">{{loggedInName}}
          </div>



        </div>


      </div>

      <!-- END visitors -->
      <div *ngIf="isLoggedInChecked">
        <!-- FOR LOGGED IN VISITORS - COUNTRIES  -->

        <div *ngIf="startDate && endDate && !isUndefinedTopLogCountries() && isLoggedInChecked"
          class="bg-white rounded-md p-6 space-y-6">

          <div>

            <!-- TOP COUNTRIES MAP - START -->
            <highcharts-chart id="container" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
              [options]="chartOptions" style="width: 100%; height: 400px; display: block;">
            </highcharts-chart>
            <!-- END -->
          </div>

        </div>


        <div class=" justify-center w-full mt-8 bg-white rounded-md p-6 space-y-6"
          *ngIf="startDate && endDate && isUndefinedTopLogCountries() && isLoggedInChecked ">
          <img
          loading="lazy"

          class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/country.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/country.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/country.png"
                      
          alt="A globe image">
        
          <p class="text-center">Major countries that use your site will show up here.</p>
        </div>

      </div>
      <div *ngIf="!isLoggedInChecked">
        <!-- FOR NON LOGGED IN VISITORS - COUNTRIES -->


        <div *ngIf="startDate && endDate && !isUndefinedTopNonLogCountries() && !isLoggedInChecked"
          class="bg-white rounded-md p-6 space-y-6">

          <div>

            <!-- TOP COUNTRIES MAP - START -->
            <highcharts-chart id="container" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
              [options]="chartOptions" style="width: 100%; height: 400px; display: block;">
            </highcharts-chart>
            <!-- END -->
          </div>

        </div>


        <div class=" justify-center w-full mt-8 bg-white rounded-md p-6 space-y-6"
          *ngIf="startDate && endDate && isUndefinedTopNonLogCountries() && !isLoggedInChecked">
          <img
          loading="lazy"

          class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/country.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/country.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/country.png"
                      
          alt="A globe image">
          
          <p class="text-center">Major countries that use your site will show up here.</p>
        </div>

      </div>


    </div>
    <!-- END -->
  </div>
  <!-- START SUBSCRIBERS DETAIL -->
  <!-------------------The header for the lists----------------->
  <div class="mx-10 my-5 lg:mx-10 mt-10"><strong>Subscribers Detail</strong></div>

  <div>
    <div class=" flex flex-col space-y-2 mx-6 md:mx-12 lg:mx-4"
      *ngIf="subscribers_arr.length > 0 && setTotal.length != 0">
      <div class="flex flex-row items-center mt-2 p-2 md:space-x-6" *ngIf="subscribers_arr.length > 0">
        <div class="w-7/12 lg:px-2 lg:w-2/12 ">
          <p class=" text-sm">Name</p>
        </div>
        <div class="w-3/12 hidden lg:block">
          <p class=" text-sm">Email</p>
        </div>
        <div class="w-3/12 hidden lg:block">
          <p class=" text-sm">Phone number</p>
        </div>
        <div class="w-3/12 lg:w-2/12">
          <p class=" text-sm">Sessions</p>
        </div>
        <div class="w:2/12 lg:w-2/12">
        </div>

      </div>
    </div>

    <div class="mt-2">
      <div *ngIf="subscribers_arr.length > 0  && setTotal.length != 0">
        <!-- <div *ngFor="let item of subscribers_arr  | filter:search_text | 
     paginate: { itemsPerPage: 10, 
     currentPage: page,
     totalItems: subscribers_arr.length}; let i=index"
          class="flex flex-row bg-white cursor-pointer items-center  mt-2 p-2 rounded-md mx-6  border-2 md:space-x-6"> -->
         
          <div *ngFor="let item of subscribers_arr  | filter:search_text  let i=index"
          class="flex flex-row bg-white cursor-pointer items-center  mt-2 p-2 rounded-md mx-6  border-2 md:space-x-6">
          <div class=" w-7/12 lg:w-2/12 flex">
            <div class="flex items-center space-x-3">
              <!-- start -->
              <div class="avatar placeholder">
                <div class="mask mask-squircle w-10 h-10 rounded-full border-2" *ngIf="item.img">
                  <img [src]="item.img" loading="lazy">
                </div>
                <div class="bg-neutral-focus text-neutral-content rounded-full w-10 h-10 " *ngIf="!item.img">
                  <span class="text-2xl">{{item.name[0] | uppercase}}</span>
                </div>

              </div>
              <!-- end -->
              <div>
                <div> {{item.name}} </div>
              </div>
            </div>
          </div>
          <div class="w-3/12 hidden lg:block">
            <p class=" text-sm">{{item.email}}</p>
          </div>
          <div class="w-3/12 hidden lg:block">
            <p class=" text-sm" *ngIf="item.cellPhone; else elseBlock">{{item.phone}}</p>
            <ng-template #elseBlock>(----) (---) (---)</ng-template>
          </div>
          <div class="w-2/12 lg:w-2/12">
            <p class="text-sm">{{item.session}}</p>
          </div>

          <div class="w-2/12">

            <div class="flex-col self-center md:flex-row mt-3 md:mr-10">
              <button (click)="openModal(item)"
                class="border rounded-lg py-2 px-3 text-white text-md bg-customTeal border-customTeal hidden lg:block">
                <i class="material-icons material-symbols-outlined w-1 h-1 pr-8  md:float-left">chat_bubble</i>
                Send Message</button>
              <button (click)="openModal(item)" *ngIf="isAdmin || (permissionEmp && permissionEmp.contactUsers)"
                class="  py-2 px-3   visible md:invisible">
                <i
                  class="material-icons material-symbols-outlined w-1 h-1 pr-8  md:float-left text-customTeal">chat_bubble</i>

              </button>
            </div>

            <!-- modal header start -->
            <div *ngIf="openContactModal">
              <!-- Modal header with radio buttons -->
              <div class="modal modal-open">
                <div class="modal-box max-w-2xl">
                  <div class="flex justify-end "(click)="openContactModal=false"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>

                  <h3 class="font-bold text-lg">Contact {{GivenName}} {{LastName}} </h3>
                  <p class="pt-4 text-gray-400 pl-1">You can contact {{GivenName}} using email or SMS if the user
                    registered
                    his/her phonenumber</p>
                  <div class="flex-col text-black px-2 mr-2">Contact with:</div>
                  <div class="flex">
                    <div>
                      <input type="radio" id="Email" value="Email" class="mx-2" [(ngModel)]="selectedOption" name="Email" ngDefaultControl
                        class="mr-2">
                      <label for="" class="mr-4">Email</label><br>
                    </div>
                    <div *ngIf="cellPhone != ''">
                      <input type="radio" id="Sms" value="SMS" class="mx-2" [(ngModel)]="selectedOption" class="mr-2" name="SMS" ngDefaultControl>
                      <label for="" class="mr-4">SMS</label><br>
                    </div>
                  </div>

                  <!-- (sentStatusEmitter)="statusIdentifier($event)" -->
                  <app-send-message [userId]="clickedId" [selectedOption]="selectedOption"
                    (closeClicked)="closeEventClicked($event)" (messageNotSent)="messageNotSentEvent($event)"
                    (emailNotSent)="emailNotSentEvent($event)" (email_Sent)="emailSentEvent($event)"
                    (message_Sent)="messageSentEvent($event)"></app-send-message>

                </div>

                <!-- Modal body -->
                <div>
                  <!-- Render the child form component based on the selected option -->


                </div>
              </div>
            </div>
            <!-- modal header end -->
          </div>

        </div>

        <br /><br />

        <!-- <pagination-controls class="text-right pr-20" (pageChange)="page = $event"></pagination-controls> -->
      </div>


      <div class=" flex flex-col mx-6 md:mx-12 lg:mx-4 bg-white rounded-md p-6 space-y-6"
        *ngIf="subscribers_arr.length == 0 || setTotal.length == 0">
        <img
          loading="lazy"

          class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/subscribers.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/subscribers.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/subscribers.png"
                      
          alt="five animated person profile image">

        <p class="text-center">Subscriber info will show up here when your site gets visited.</p>
      </div>
    </div>

  </div>

 



</div>