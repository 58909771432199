import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UserModel } from 'src/app/models/UserModel';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/models/GlobalConstants';

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.css']
})
export class AddScheduleComponent implements OnInit {
  currentUser: any;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  user: UserModel;
  scheduleForm: UntypedFormGroup;
  id: any;
  hasSchedule = false;
  employeeSchedule: any;
  date: boolean = false;
  clickedDate: any;
  selectedDate: any;
  selectedEmployeeId: any;
  isEditingEmployee = false;
  daysOfTheWeek: any[] = GlobalConstants.daysOfTheWeek;
  @Output() createClicked = new EventEmitter();
  @Output() updateClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();
  @Output() defaultScheduleDisplayed = new EventEmitter();
  @Input() calledFrom;

  constructor(private fb: UntypedFormBuilder, private router: Router, private authService: AuthService,
              private fireMemberService: FireMembersService, private scheduleService: ScheduleService,
              private location: Location, private route: ActivatedRoute)
              { }

  get schedules(){return this.scheduleForm.get('schedules') as UntypedFormArray; }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    this.route.paramMap.subscribe(params => {
      this.selectedEmployeeId = params.get('id');
    });
    if(!this.selectedEmployeeId){

    this.currentUser = this.authService.getCurrentUser();
      this.selectedEmployeeId = this.currentUser?.uid;
    }
    if (this.selectedEmployeeId){

      this.scheduleForm = this.fb.group({
        // schedules: this.fb.array([  ],)
      });
      this.fireMemberService.getMemberByID(this.selectedEmployeeId).valueChanges().subscribe(user => {
        if (user){
          if (user.role === 'Employee' || user.role === 'Admin'){
            this.currentUser = user;
            this.id = this.currentUser.uid;

            this.scheduleService.getScheduleByEmployeeId(this.id).valueChanges().subscribe(s=>{
              let controls;
              if (s.length > 0){
                this.employeeSchedule = s[0];
                this.hasSchedule = true;
                controls = this.employeeSchedule.schedule.map((sc: any) => {
                  this.defaultScheduleDisplayed.emit(this.scheduleForm);
                  return this.createFormGroup(sc);
                });
              }
              else{
                controls = this.daysOfTheWeek.map(sc => {
                  return this.createFormGroup(sc);
                });
              }
              this.scheduleForm.registerControl('schedules', new UntypedFormArray(controls));
              this.defaultScheduleDisplayed.emit(this.scheduleForm);
            });
          }
        }
      });
    }
  }

  createFormGroup(values){
    const startTime = values.unAvailable ? new UntypedFormControl({value: values.startTime, disabled: true}) :
                    new UntypedFormControl({value: values.startTime, disabled: false});
    const endTime = values.unAvailable ? new UntypedFormControl({value: values.endTime, disabled: true}) :
                    new UntypedFormControl({value: values.endTime, disabled: false});

    const formGroup =  this.fb.group({
      day: new UntypedFormControl({value: values.day, disabled: false}),
      startTime,
      endTime,
      unAvailable: new UntypedFormControl(values.unAvailable)
    });

    formGroup.setValidators(this.timeValidators());
    return formGroup;
  }

  timeValidators(): ValidatorFn{
    return(group: UntypedFormGroup) => {
          const errors = {};
          const startTime = group.controls['startTime'];
          const endTime = group.controls['endTime'];
          const unAvailable = group.controls['unAvailable'];

          if (startTime.value === null && endTime.value !== null){
            startTime.setErrors({notEquivalent: true});
          }
          if (endTime.value === null && startTime.value !== null){
            endTime.setErrors({notEquivalent: true});
          }

          if (unAvailable.value === false && startTime.value == null && endTime.value === null){
            endTime.setErrors({notEquivalent: true});
          }

          return null;

        };
  }

  updateDaySchedule(index){
    const schedule = this.schedules.at(index).value;
    const item = {
                day: this.daysOfTheWeek[index].day,
                startTime: schedule.startTime || '09:00',
                endTime: schedule.endTime || '17:00',
                unAvailable: !schedule.unAvailable
              };

    this.schedules.setControl(index, this.createFormGroup(item));
    this.scheduleChange(this.scheduleForm.value);
  }
  saveChanges(data){
    let updateData = {data: '', savedEmployeeSchedule: ''}
    this.scheduleChange(data);
    if (this.hasSchedule){
      updateData.data = data;
      updateData.savedEmployeeSchedule = this.employeeSchedule;
      this.updateClicked.emit(updateData);
    }else{
      this.createClicked.emit(data);
    }
  }

  scheduleChange(data) {
    let updateData = {data: '', savedEmployeeSchedule: ''}
    if (this.hasSchedule){
      updateData.data = data;
      updateData.savedEmployeeSchedule = this.employeeSchedule;
      if (this.calledFrom!='onboarding'){
        this.updateClicked.emit(updateData);
      }
      // 
    }else{
      if (this.calledFrom!='onboarding'){
        this.createClicked.emit(data);
      }
    }

  }

  day(item){
    this.date = true;
    this.clickedDate = item.day;
    this.selectedDate = item;
  }

  backClicked(){
    this.location.back();
  }
  closeModal(){
    this.cancelClicked.emit()
  }
}
