import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { ProductsService } from 'src/app/services/firebase/products.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, OnDestroy {

  products = [];
  settings: any;
  viewProduct = true;
  selectedModel: any;
  productCategoryForm: UntypedFormGroup;
  showMsg = false;
  currentUserId: any;
  selectedProductsUnderCategory: any;
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  filterOpen = false;

  productCategoryFilter = '';
  existingProducts = [];
  beforeFilter = [];
  currentUser: any;
  catagoryId;

  // permissions
  isAdmin: boolean;
  perm: any;
  permission: any;
  currency;

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  openMoveModal = false;
  appSettings$: Subscription;

  constructor(private productsService: ProductsService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private authService: AuthService,
              private productsServiceMenuService: ProductsServiceMenuService,
              private permissionService: PermissionService,
              private helperService: HelperService,
              private store: Store) { 
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { this.settings = settings;  });
              }

  ngOnDestroy(){
    this.appSettings$.unsubscribe();
  }
  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();

    if (this.currentUser && this.currentUser.role === 'Admin'){
      this.isAdmin = true;
    }

    if (this.currentUser){
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
        if(perm && perm.length != 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];

          this.permMember = this.perm.permission[0]
          this.permPES = this.perm.permission[1]
          this.permAnalytics = this.perm.permission[2];
          this.permSocial = this.perm.permission[3];
          this.permSpecial = this.perm.permission[4];
        }
      });
    }

    this.route.paramMap.subscribe(params => {
      this.catagoryId = params.get('id');
    });
    if (this.catagoryId){
      this.productsService.getProductByCategoryId(this.catagoryId).valueChanges().subscribe((products) => {
        if (products.length > 0 ){
          this.products = products;
          this.existingProducts = products;

          if (this.currentUser == null){
            this.products = this.products.filter(p => p.shareWithPublic === true);
          }

        }
      });
    }else{
        this.productsService.getProductsList().valueChanges().subscribe((products) => {
          if (products.length > 0 ){
            this.products = products;
            this.existingProducts = products;

            if (this.currentUser == null){
              this.products = this.products.filter(p => p.shareWithPublic === true);
            }
          }
    });
    }

    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });

  }

  backClicked(){
    this.location.back();
  }

  get name() { return this.productCategoryForm.get('name'); }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  toggleFilter(){
    this.filterOpen = !this.filterOpen;
  }

  clearFilters(){
    this.productCategoryFilter = 'all';
    this.products = this.existingProducts;
  }

navigate(product){
  if (this.currentUser){
  if (this.currentUser.role === 'User'){
    this.router.navigate(['/user/view-product', product.id]);
  }
  if (this.currentUser.role === 'Admin'){
    this.router.navigate(['/admin/products', product.id]);
  }
  if (this.currentUser.role === 'Employee'){
    this.router.navigate(['/employee/products', product.id]);
  }
}
// check if it is this one
  else{
      this.router.navigate(['/view-product', product.id]);
  }
}

truncateHTML(text: string, limit: string): string {
  return this.helperService.truncateHTML(text, limit);
}

getSortedList(unsortedElements: Array<any>){
  let sortedList = unsortedElements.sort((a:any, b:any ) => {
      return b.timeStamp- a.timeStamp;
  });
  return sortedList
}
getInnerText(text){
  return this.helperService.getInnerText(text);
}
}
