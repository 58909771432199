import { Location } from '@angular/common';
import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Form, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { ConversationsService } from 'src/app/services/firebase/conversations.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { ForwardedMessageModel, MessagesModel } from 'src/app/models/Messages.Model';
import { PrivateConversationsModel, GroupConversationsModel } from 'src/app/models/Conversations.Model';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UnreadCountModel } from 'src/app/models/UnreadCountModel';
import { MediaService } from 'src/app/services/api/media.service';
import { ActivatedRoute, Router } from '@angular/router';

// declare var M: any;
// declare var $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  id = ''; currentUser: any;
  privateChats = [];
  groupChats = [];
  scrWidth;
  selectedConver: any;
  chatSelected: any;
  search = false;
  newCircleForm: UntypedFormGroup;
  messageForm: UntypedFormGroup;
  editCircleInfoForm: UntypedFormGroup;
  AdminNewCircleForm: UntypedFormGroup;
  FilterMemberForm: UntypedFormGroup;
  newMessage = '';
  newMessageObj: MessagesModel;
  format = ''; url: any;
  group = false;
  searchQuery = '';
  searchForwardQuery = '';
  searchMemberQuery = '';
  imgLink = 'https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp';
  usersForSearch = [];
  chats = [];
  searchResult = [];
  messages = [];
  newConversation;
  loadingMessages = false;
  loadingChats = false;
  selectedImageLink = '';
  today = new Date();
  selectedMember: any = [];
  selectedMemeberName = '';
  groupProfileImg = 'https://api.goingmobile.app/media/personal-app-assets/img/webp/default-group.webp';
  searchCircleMember = false;
  previousMembersList: any = [];
  allMembers: any = [];
  binDoc: File; binDocList: any = [];
  groupUnreadCounts: any = [];
  noNotification = false;
  selectedFilesForDisplay: any = [];

  isAdmin = false;
  chatId: any;
  selectedChat: any;
  selectedMessage: any;
  editMessage = false;
  xPosTabMenu: any;
  yPosTabMenu: any;
  isHidden: any;
  selectedChatList: any = [];
  something: any;

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private fireMembersService: FireMembersService,
              private conversationsService: ConversationsService,
              private authService: AuthService,
              private messagingService: MessagingService,
              private mediaService: MediaService,
              private location: Location
    ) {
      this.selectedConver = this.router.getCurrentNavigation().extras.state;
     }

  ngOnInit(): void {
    this.messageForm = this.fb.group({
      attachFile: [null],
      message: ['', [Validators.required]]
    });

    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('id');
    });
    this.currentUser = this.authService.getGlobalUser();

    if (this.currentUser) {
      if (this.currentUser.role === 'Admin') {
        this.isAdmin = true;
      }
      this.id = this.currentUser.uid;
    }


    this.conversationsService.getPrivateChatByChatId(this.chatId).valueChanges().subscribe(chat => {
      if (chat){
        this.something  = "chat";
        this.selectedChatList.push(chat[0]);
        // this.selectedChat = chat[0];
        this.selectedConver = chat[0];

      }
    });

    // update unread count to zero
    this.conversationsService.removeUnreadCount(this.selectedConver.id, this.selectedConver.user_1.id);
    this.selectedConver = this.selectedConver;
    // initiate the retrival of message history if the chat is an already exsisting
    if (this.chatId) {
      this.messagingService.getMessageByChatId(this.chatId).valueChanges().subscribe(messages => {
        if (messages) {
          this.messages = messages.sort((a, b) => (a as any).timeStamp.toDate() - (b as any).timeStamp.toDate());
          this.loadingMessages = false;
          $(document).ready(() => {
            const container = $('#scrollMe')[0];
            const height = $(container).prop('scrollHeight') - $(container).prop('clientHeight');
            $(container).animate({ scrollTop: height}, 0);
          });
        }
        else {
          this.messages = [];
          this.loadingMessages = false;
        }
      });
    } else {
      this.messages = [];
      this.loadingMessages = false;
    }
    // $(document).ready(() => {
    //   $('.materialboxed').materialbox();
    //   $('.tabs').tabs();
    //   $('.modal').modal();
    //   $('.dropdown-trigger').dropdown();
    //   $('.slider').slider({
    //     height: 300
    //   });
    //   $('select').formSelect();


    // });

  }


         // on send message click
  send(value: any) {
    this.newMessage = value.message;
    if (!this.editMessage) {
      if (this.newMessage == null || this.newMessage.trim() === '') {
        return;
      }
      try {
        this.sendMessage(this.newMessage);
      } catch (err) {
        console.error(err);
      }
    } else {
      if (this.selectedMessage.message !== this.newMessage){
        this.selectedMessage.message = this.newMessage;
        this.messagingService.editMessage(this.selectedMessage);
        this.editMessage = false;
        const lastMessage = this.messages[this.messages.length - 1];
        if (lastMessage.id === this.selectedMessage.id) {
          this.selectedConver.lastMessage.message = this.newMessage;
          if (!this.group) {
            this.conversationsService.updatePrivatelastMessage(this.selectedConver.id, this.selectedConver.lastMessage);

          } else {
            this.conversationsService.updateGrouplastMessage(this.selectedConver.id, this.selectedConver.lastMessage);

          }
        }

        } else{
        this.editMessage = false;
        this.messageForm.reset();
        // M.textareaAutoResize($('.textarea'));
        return;
      }

      this.messageForm.reset();
      // M.textareaAutoResize($('.textarea'));
    }

  }

  // create a new message
  sendMessage(message: any) {

    this.messageForm.reset();
    // M.textareaAutoResize($('.textarea'));

    // create a new message object
    this.newMessageObj = new MessagesModel();
    this.newMessageObj.timeStamp = new Date();
    this.newMessageObj.message = message;
    this.newMessageObj.fromId = this.id;
    this.newMessageObj.conversationId = this.selectedConver.id;
    this.newMessageObj.attachment = { type: 'none', link: '', name: '' };
    if (this.group) {
      this.newMessageObj.toId = this.selectedConver.id;
    } else {
      this.newMessageObj.toId = this.selectedConver.user_2.id;
    }
    this.newMessageObj.edited = false;
    this.newMessageObj.likes = {
      count: 0,
      users: []
    };
    this.messagingService.createMessage(this.newMessageObj);
    // create last message for this conversation
    const lastMessage = { message: '', timeSent: null };
    lastMessage.timeSent = this.newMessageObj.timeStamp;


    if (!this.group) {
      lastMessage.message = this.newMessageObj.message;
      this.conversationsService.updatePrivatelastMessage(this.selectedConver.id, lastMessage);
      // unread notification to the other user
      this.conversationsService.addUnreadCount(this.selectedConver.id, this.selectedConver.user_2.id);

    }
  }

  // identify type of input given
  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    } else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return false;
    }
  }

  // Send media messages

  createMediaMessage(selectedConver: any, attachmentObject: any) {
    this.newMessageObj = new MessagesModel();
    this.newMessageObj.timeStamp = new Date();
    this.newMessageObj.message = '';
    this.newMessageObj.fromId = this.id;
    this.newMessageObj.conversationId = selectedConver.id;
    if (selectedConver.admin) {
      this.newMessageObj.toId = selectedConver.id;
    } else {
      this.newMessageObj.toId = selectedConver.user_2.id;
    }
    // create attachment object
    this.newMessageObj.attachment = attachmentObject;
    this.newMessageObj.edited = false;
    this.newMessageObj.likes = {
      count: 0,
      users: []
    };
    this.messagingService.createMessage(this.newMessageObj);

    // create last message for this conversation
    const lastMessage = { message: '', timeSent: null };
    lastMessage.timeSent = this.newMessageObj.timeStamp;

    if (!selectedConver.admin) {
      lastMessage.message = this.newMessageObj.attachment.type;
      this.conversationsService.updatePrivatelastMessage(selectedConver.id, lastMessage);
      // unread notification to the other user
      this.conversationsService.addUnreadCount(selectedConver.id, selectedConver.user_2.id);

    } else if (selectedConver.admin) {
      // add unread count for every user in group except sender
      lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${this.newMessageObj.attachment.type}`;
      this.conversationsService.updateGrouplastMessage(selectedConver.id, lastMessage);

    }
  }
  // when file is selected
  onSelectFile(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const FileSize = files[i].size / 1024 / 1024; // in MiB
      if (FileSize > 300) {
        // M.toast({ html: 'Selected file size exceeds 300 MiB.', classes: 'rounded' });
        return;
      } else {
        this.binDocList.push(files[i]);
        if (this.validateInput(files[i].name) === 'image') {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          const img = {
            name: 'image',
            result: null
          };
          reader.onload = () => {
            img.result = reader.result;
            this.selectedFilesForDisplay.push(img);
          };
        } else {
          this.selectedFilesForDisplay.push(files[i]);
        }
      }
    }
    // setTimeout(() => {
    //   $('.modal#uploadFilesViewer').modal('open');
    //   $('.materialboxed').materialbox();
    // }, 200);



  }
  cancelFile(i: any) {
    this.selectedFilesForDisplay.splice(i, 1);
    if (this.selectedFilesForDisplay.length === 0) {
      // $('.modal#uploadFilesViewer').modal('close');
    }
  }
  cancelUpload() {
    this.binDoc = undefined;
    this.selectedFilesForDisplay = [];
  }
  onSendFiles() {
    this.binDocList.forEach(file => {
      if (this.validateInput(file.name) === 'image') {
        const binDoc = file;
        if (binDoc) {
          const selectedConver = this.selectedConver;
          this.mediaService.UploadBinImage(binDoc).subscribe(
            upload => {
              if (upload) {
                const attachment = {
                  type: 'image',
                  link: upload.imgLink,
                  name: binDoc.name
                };
                this.createMediaMessage(selectedConver, attachment);
              }
            }
          );
        }

      } else if (this.validateInput(file.name) === 'video') {

        const binDoc = file;
        if (binDoc) {
          const selectedConver = this.selectedConver;
          this.mediaService.uploadVideo(binDoc).subscribe(
            upload => {
              if (upload) {
                const attachment = {
                  type: 'video',
                  link: upload.imgLink,
                  name: binDoc.name
                };
                this.createMediaMessage(selectedConver, attachment);
              }
            }
          );
        }

      } else if (this.validateInput(file.name) === 'pdf') {
        const binDoc = file;
        if (binDoc) {
          const selectedConver = this.selectedConver;
          this.mediaService.uploadPDF(binDoc).subscribe(
            upload => {
              if (upload) {
                const attachment = {
                  type: 'pdf',
                  link: upload.imgLink,
                  name: binDoc.name
                };
                this.createMediaMessage(selectedConver, attachment);
              }
            }
          );
        }
      }
    });

    this.binDocList = [];
    this.selectedFilesForDisplay = [];
  }

  backToChatList(){
    this.router.navigate(['user/chat-list']);
  }

  onOptionsClick(event, message){

    this.onRightClick(event, message);
    $('.right-click-menu').css('display', 'block');

  }
  onRightClick(event, message) {
    // this.cd.detectChanges();
    this.selectedMessage = message;
    event.preventDefault();
    // event.stopPropagation();
    this.xPosTabMenu = event.clientX;
    this.yPosTabMenu = event.clientY;
    this.isHidden = false;
    // return false;
  }
  forwardSelectedMessage(selectedMessage) {
    // $('.modal#forwardMessage').modal('open');
    this.isHidden = true;
  }

  sendForwardMessage(selectedMessage, converId, toId) {
    this.fireMembersService.getMemberByID(selectedMessage.fromId).valueChanges().subscribe(user => {
      if (user) {
        const forwardedMessage = new ForwardedMessageModel();
        forwardedMessage.timeStamp = new Date();
        forwardedMessage.message = selectedMessage.message;
        forwardedMessage.fromId = this.id;
        forwardedMessage.forwardedFrom = `${user.GivenName || user.FirstName} ${user.LastName}`;
        forwardedMessage.toId = toId;
        forwardedMessage.conversationId = converId;
        forwardedMessage.edited = false;
        forwardedMessage.likes = {
          count: 0,
          users: []
        };
        forwardedMessage.forwardedMessage = selectedMessage;
        forwardedMessage.attachment = forwardedMessage.forwardedMessage.attachment;

        this.messagingService.createForwardedMessage(forwardedMessage);
        const lastMessage = { message: '', timeSent: null };
        lastMessage.timeSent = forwardedMessage.timeStamp;

        if (converId !== toId) {
          if (forwardedMessage.forwardedMessage.attachment.type === 'none'){
            lastMessage.message = forwardedMessage.forwardedMessage.message;
          }else{
            lastMessage.message = forwardedMessage.forwardedMessage.attachment.type;
          }
          this.conversationsService.updatePrivatelastMessage(converId, lastMessage);
          // unread notification to the other user
          this.conversationsService.addUnreadCount(converId, toId);

        } else if (converId === toId) {
          // add unread count for every user in group except sender
          if (forwardedMessage.forwardedMessage.attachment.type === 'none'){
          lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:
           ${forwardedMessage.forwardedMessage.message}`;

          }else{
          lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${forwardedMessage.forwardedMessage.attachment.type}`;
          }
          this.conversationsService.updateGrouplastMessage(converId, lastMessage);

        }
      }
    });

  }
  editSelectedMessage(selectedMessage) {
    this.isHidden = true;
    this.editMessage = true;
    this.messageForm.patchValue({
      message: selectedMessage.message
    });

  }

  closeContextMenu() {
    this.isHidden = true;
  }

  copyMessage(message) {
    navigator.clipboard.writeText(message.message).then().catch(e => console.error(e));

  }
  likeMessage(id: any) {
    const currentMessage = this.messages.find(message => message.id === id);
    const index = this.messages.findIndex(message => message.id === id);
    if (currentMessage.likes.users.findIndex(user => user === this.id) > -1) {
      this.messages[index].likes.count -= 1;
      this.messages[index].likes.users.pop(this.id);
      this.messagingService.updateMessageLikes(this.messages[index]);
    } else {
      this.messages[index].likes.count += 1;
      this.messages[index].likes.users.push(this.id);
      this.messagingService.updateMessageLikes(this.messages[index]);
    }

  }
  replaceURLs(message) {
    if (!message) {return; }

    const urlRegex = /((http:\/\/|https:\/\/)?(www.)?(([a-zA-Z0-9-]){2,}\.){1,4}([a-zA-Z]){2,6}(\/([a-zA-Z-_\/\.0-9#:?=&;,]*)?)?)/ig;
    return message.replace(urlRegex, (url) => {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    });
  }

  backClicked(){
    this.location.back();
  }
}
