import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {

  constructor() { }

  @Input() title;
  @Input() description;
  @Input() image;

  mediaType: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  isMobile = false;
  model: any;
  imgURL: any;
  imgErrorMsg: any;
  pdfErrorMsg: any;
  profileImgLink: any;

  ngOnInit(): void {
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  uploadPhoto() {
    this.mediaType = 'Photo';
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    //this.imgLink.patchValue('');
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      //this.imgLink.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.image = this.base64Image;
    };
  }

}
