<div class="font-body min-h-screen max-h-max pt-6">

    <div class="flex justify-end w-full h-max rounded-lg" *ngIf="role == 'Employee' && currentUser.uid == this.id">
        <button (click)="openDeleteModalOne()"
            class="flex px-2 md:px-6  md:mr-28 py-2 bg-red-600 text-white rounded-lg w-max h-min text-sm md:text-base font-bold focus:outline-none ">
            <i class="material-icons material-symbols-outlined text-white w-1 h-1 pr-8 float-left">person_remove</i>
            <span>Delete Account</span>
        </button>
    </div>

    <div class="flex flex-col mx-4 md:mx-24 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4">
        <p class=" font-bold text-xl text-center text-customTeal ">Edit {{firstName.value | titlecase}} {{lastName.value
            | titlecase}}'s Profile </p>
        <p class="py-2 text-center">Edit this employee's profile below.</p>

        <form [formGroup]="employeeEditForm" (ngSubmit)="SubmitEditForm(employeeEditForm.value)">
            <div class="flex flex-col pt-12 space-y-4 mb-4 md:flex-col  ">
                <div class="px-4 md:px-0 space-y-4 md:space-y-0  md:grid grid-cols-2 gap-6">
                    <div class="flex space-y-2 md:w-80  flex-col">
                        <label for=" " class="text-xs text-gray-500 font-bold "> First Name <span
                                class="text-red-500">*</span> </label>
                        <input maxlength="60" formControlName="firstName"
                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
                            type="text" placeholder="John" required>
                        <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                            <small *ngIf="firstName.errors.required" class="text-red-500"> The First Name is required.
                            </small>
                        </div>
                    </div>

                    <div class="flex space-y-2 md:w-80 flex-col ">
                        <label for=" " class="text-xs text-gray-500 font-bold "> Last Name <span
                                class="text-red-500">*</span> </label>
                        <input formControlName="lastName"
                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
                            type="text" placeholder="Smith" required>
                        <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                            <small *ngIf="lastName.errors.required" class="text-red-500"> The Last Name is required.
                            </small>
                        </div>
                    </div>

                    <div class="flex space-y-2 md:w-96 flex-col ">
                        <label for=" " class="text-xs text-gray-500 font-bold "> {{firstName.value | titlecase}}'s
                            Mobile</label>
                        <input type="text" formControlName='cellPhone' 
                            id='cellPhone' placeholder="000-0000-0000 "
                            class=" w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " />
                    </div>
                    <div class="flex space-y-2 md:w-96 flex-col ">
                        <label for=" " class="text-xs text-gray-500 font-bold ">{{firstName.value | titlecase}}'s Job
                            Title </label>
                        <input formControlName="jobTitle"
                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
                            type="text" placeholder="Job title">
                    </div>
                </div>
                <hr />

                <div class="space-y-2 pt-2 md:flex md:justify-between">
                    <div>
                        <p class="font-bold">{{firstName.value | titlecase}}'s Work Phone </p>
                        <p class="text-xs text-darkGray">Please put in your Work Phone Number on the space provided.</p>
                    </div>

                    <!-- button -->
                    <div class="flex space-y-2 md:w-96 flex-col ">
                        <div class="flex justify-between space-x-2">
                            <input formControlName="workPhone" 
                                class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-1/2"
                                type="text" placeholder="000-0000-0000 ">
                            <input type="text" formControlName='extension' 
                                id='extension' placeholder="Ext"
                                class=" w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline  w-1/2" />
                        </div>
                    </div>
                </div>
                <br />
                <hr />

                <div class="space-y-2 pt-2">
                    <p class="font-bold">{{firstName.value | titlecase}}'s Biography </p>
                    <p class="text-xs text-darkGray">Please put in your Biography in the space provided below.</p>
                    <!-- button -->
                    <div class="input-field">

                        <app-editor [control]="biography" class="bg-white" [fromComponent]="'biography'"></app-editor>


                        <div *ngIf="(biography.dirty || biography.touched) && biography.invalid">
                            <small *ngIf="biography.errors.required" class="text-red-500 italic"> Biography is required.
                            </small>
                        </div>
                    </div>
                </div>
                <br />
                <hr>

                <!-- Notification -->
                <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
                    <!-- Description -->
                    <div class="md:w-1/2 space-y-2  ">
                        <p class="font-bold">
                            {{firstName.value | titlecase}}'s Appointment
                        </p>
                        <p class=" text-xs text-darkGray ">
                            Do you accept Appointments?
                        </p>
                    </div>

                    <!-- toggle & edit -->
                    <div class="flex justify-center align-middle space-x-8 items-baseline">
                        <!-- toggle -->
                        <div>
                            <div class="form-control">
                                <label class="label cursor-pointer">
                                    <span class="label-text pr-2 font-bold ">ON</span>
                                    <input (click)="toggleAppointment($event)" formControlName="appointment"
                                        type="checkbox" [checked]="appointmentChecked" class="toggle toggle-primary " />
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
                <app-add-schedule *ngIf="appointment.value" (createClicked)="createSchedule($event)" (updateClicked)="updateSchedule($event)" (defaultScheduleDisplayed)="initializeSchedule($event)"></app-add-schedule>
        
                <br />
                <hr>
                <ng-container *ngIf="appointment.value">
                    <div class="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0 ">
                        <div class="space-y-2 md:w-1/2 ">
                            <p class="font-bold">
                                Recieve appointment Email
                            </p>
                            <p class="text-xs text-darkGray">
                                Allows the employee to recieve emails about appointments and reschedules
                            </p>
                        </div>
    
                        <!-- toggle & edit -->
                        <div class="flex items-baseline justify-center space-x-8 align-middle">
                            <!-- toggle -->
                            <div>
                                <div class="form-control">
                                    <label class="cursor-pointer label">                
                                        <span class="label-text text-sm pr-2 font-bold "
                                        [ngClass]="{'invisible text-gray-400': recieveAppointmentEmail.value}">No</span>
                        
                                        <input formControlName="recieveAppointmentEmail" type="checkbox"
                                            class="toggle toggle-primary " />
                                            <span class="label-text text-sm pr-2 font-bold "
                                            [ngClass]="{'invisible text-customTeal': !recieveAppointmentEmail.value}">Yes</span>
                                    </label>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <hr  />
                    <br/>
                    <div class="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0 ">
                        <div class="space-y-2 md:w-1/2 ">
                            <p class="font-bold">
                                Recieve appointment SMS
                            </p>
                            <p class="text-xs text-darkGray">
                                Allows the employee to recieve SMS about appointments and reschedules
                            </p>
                        </div>
    
                        <!-- toggle & edit -->
                        <div class="flex items-baseline justify-center space-x-8 align-middle">
                            <!-- toggle -->
                            <div>
                                <div class="form-control">
                                    <label class="cursor-pointer label">                
                                        <span class="label-text text-sm pr-2 font-bold "
                                        [ngClass]="{'invisible text-gray-400': recieveAppointmentSMS.value}">No</span>
                        
                                        <input formControlName="recieveAppointmentSMS" type="checkbox"
                                            class="toggle toggle-primary " />
                                            <span class="label-text text-sm pr-2 font-bold "
                                            [ngClass]="{'invisible text-customTeal': !recieveAppointmentSMS.value}">Yes</span>
                                    </label>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </ng-container>
                <div class="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0 ">
                    <!-- Description -->
                    <div class="mt-2 space-y-2 md:w-1/2 ">
                        <p class="font-bold">
                            {{firstName.value | titlecase}} profile visibility? <span class="text-red-500">*</span>
                        </p>
                        <p class="text-xs text-darkGray">
                            Please choose your preference on how to show the employee is it to public, subscribe's only
                            or you dont want to show the employee at all.
                        </p>
                    </div>


                    <div class="flex items-baseline justify-center space-x-2 align-middle md:space-x-8">
                        <div class="form-control">
                            <label class="space-x-2 cursor-pointer label">
                                <input type="radio" class="radio checked:bg-customTeal"formControlName="showEmployee" value="public"
                                     />
                                <span class="label-text">Public</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="space-x-2 cursor-pointer label">
                                <input type="radio" class="radio checked:bg-customTeal" formControlName="showEmployee" value="subscriber" 
                                     />
                                <span class="label-text">Subscriber's Only</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="space-x-2 cursor-pointer label">
                                <input type="radio" class="radio checked:bg-customTeal" formControlName="showEmployee" value="no"
                                     />
                                <span class="label-text">Don't show</span>
                            </label>
                        </div>
                    </div>
                </div>
                <hr />

                <!-- Send content -->
                <div class="flex flex-col space-y-6 md:space-y-0 ">
                    <!-- Description -->
                    <div class="md:w-full space-y-2 mt-2 ">
                        <p class="font-bold">
                            Edit {{firstName.value | titlecase}} preference?<span class="text-red-500">*</span>
                        </p>
                        <p class=" text-xs text-darkGray ">
                            Please choose {{firstName.value | titlecase}} preference for {{firstName.value | titlecase}}
                            email, work phone or phonenumber to be shown on
                            public, to subscribers' only and not to show at all.
                        </p>
                    </div>

                    <div class="flex flex-col">
                        <div class="flex justify-center align-middle space-x-8 items-baseline mt-2">
                            <div class="form-control w-48">
                                <label class="label cursor-pointer space-x-2">
                                    <span class="label-text">Email</span>
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleEmail" value="public" 
                                    />

                                    <span class="label-text">Public</span>
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleEmail" value="subscriber"
                                        />
                                    <span class="label-text">Subscriber's Only</span>
                                </label>
                            </div>
                            <div class="form-control ">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleEmail" value="donotShow" 
                                      />
                                    <span class="label-text">Don't show</span>
                                </label>
                            </div>
                        </div>

                        <div class="flex justify-center align-middle space-x-8 items-baseline">
                            <div class="form-control w-48">
                                <label class="label cursor-pointer space-x-2">
                                    <span class="label-text">Phone Number</span>
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="togglePhoneNumber" value="public"
                                         />

                                    <span class="label-text">Public</span>
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="togglePhoneNumber" value="subscriber"
                                         />
                                    <span class="label-text">Subscriber's Only</span>
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="togglePhoneNumber" value="donotShow"
                                         />
                                    <span class="label-text">Don't show</span>
                                </label>
                            </div>
                        </div>

                        <div class="flex justify-center align-middle space-x-8 items-baseline">
                            <div class="form-control w-48">
                                <label class="label cursor-pointer space-x-2">
                                    <span class="label-text">Work Phone</span>
                                </label>
                            </div>

                            <div class="form-control">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleWork" value="public"
                                        />

                                    <span class="label-text">Public</span>
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleWork" value="subscriber"
                                         />
                                    <span class="label-text">Subscriber's Only</span>
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleWork" value="donotShow"
                                         />
                                    <span class="label-text">Don't show</span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <hr />

                <!-- employees push notification preference-->
                <div class="flex flex-col space-y-6 md:space-y-0 " *ngIf="notifSettings">
                    <!-- Description -->
                    <div class="md:w-full space-y-2 mt-2 ">
                        <p class="font-bold">
                            Edit {{firstName.value | titlecase}} notification preference?<span class="text-red-500">*</span>
                        </p>
                        <p class=" text-xs text-darkGray ">
                            Please choose {{firstName.value | titlecase}} preference for push notification, 
                            when do u want to recieve push notfication?
                        </p>
                    </div>

                    <div class="flex flex-col">
                        <div class="flex justify-center align-middle space-x-8 items-baseline mt-2">
                            <div class="form-control" *ngFor="let section of notifSettings?.settings">
                                <label class="label cursor-pointer space-x-2">
                                    <input type="checkbox" class="radio checked:bg-customTeal" [checked]="section.isEnabled" (change)="section.isEnabled = !section.isEnabled" />

                                    <span class="label-text">{{section?.displayName}}</span>
                                </label>
                            </div>

                        </div>

                    </div>

                </div>
                <hr />


                <div class="space-y-2 pt-2" *ngIf="role !== 'Admin' && (currentUser.uid === id)">
                    <div>
                        <p class="font-bold">What do you want to include on your signoff in an email? </p>
                        <p class="text-xs text-darkGray">Please put in your signoff on an email in the space provided
                            below.</p>
                    </div>
                    <div class="input-field w-full">

                        <app-editor [control]="signOff" class="bg-white" [fromComponent]="'signoff email'"></app-editor>

                    </div>
                    <br />
                    <hr/>
                </div>


                <div class="space-y-2 pt-2" *ngIf="role !== 'Admin' &&  (currentUser.uid === id)">
                    <div>
                        <p class="font-bold">What do you want to include on the SMS you want to send? </p>
                        <p class="text-md text-red-600 font-bold">Please note the SMS will be sent from a generic
                            number.</p>
                        <p class="text-xs text-red-600 font-bold">If you want your recipient to get back to you include
                            email or phone number in the SMS signature.</p>
                    </div>
                    <div class="input-field w-full">

                        <textarea id="message" formControlName="messageSMS"
                            class="border rounded py-4 px-3 mt-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline h-32 w-full "
                            placeholder="Message goes here...."></textarea>
                    </div>
                    <br />
                    <hr/>
                </div>


                <div class="mt-3 flex flex-col space-y-6  md:flex-row md:space-y-0 ">
                    <div class="md:w-1/2 space-y-2 mt-2 ">
                        <p class="font-bold">
                            Edit {{firstName.value | titlecase}}'s Profile Picture
                        </p>
                        <p class=" text-xs text-darkGray ">
                            Please upload your profile picture.
                        </p>
                    </div>


                    <div class="flex justify-center align-middle space-x-8 items-baseline">
                        <div class="form-control w-full max-w-x">
                            <div class="flex flex-row w-full">
                                <div *ngIf="!isMobile"
                                    class="flex justify-center content-center w-full h-44  bg-white rounded-lg border-2 gap-x-3">

                                    <label class="custom-file-upload  self-center flex flex-col">
                                        <i
                                            class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                                        <input type="file" type="file" accept="image/*"
                                            (change)="handleFileInput($event.target.files)">
                                        <span class="block font-bold pt-1 px-10">Upload Image</span>
                                    </label>
                                </div>
                                <div *ngIf="isMobile">
                                    <button type="button"
                                        class="py-2 px-16 modal-button text-black border-2 border-customTeal rounded-lg w-full"
                                        type="button" (click)="handleMobilePhotoInput()">
                                        Upload Image
                                    </button>
                                </div>
                                <div class=" mx-4" *ngIf="base64Image">
                                    <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Image</p>
                                    <div class="flex flex-wrap gap-8">

                                        <div class="relative">
                                            <img loading="lazy" [src]="base64Image" class="object-cover w-48 rounded-md cover"
                                                alt="Image">
                                            <button (click)="removeFile()"
                                                class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                                <i class="fa fa-trash text-md"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div class=" mx-4" *ngIf="userGlobal && userGlobal.profileImgLink">
                                    <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Previous
                                        Image</p>
                                    <div class="flex flex-wrap gap-8">

                                        <div class="relative">
                                            <img loading="lazy" [src]="userGlobal.profileImgLink"
                                                class="object-cover w-48 rounded-md cover" alt="Image">

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-6 space-y-2">
                    <div class="">
                    </div>
                    <div class=" flex justify-end w-full">
                        <button type="button" (click)="openConfirmationModal()"
                            class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                            <span>Update</span>
                        </button>
                    </div>
                </div>

                <div id="my-modal-confirmation" *ngIf="confirmationModal" class="modal"
                    [ngClass]="{'modal-open': confirmationModal, 'modal-action': !confirmationModal }">
                    <div class="modal-box">
                        <div class="modal-content">
                            <h3 class="font-bold text-lg text-center mb-2">{{userGlobal.GivenName | titlecase}}'s
                                Profile Update</h3>
                            <hr />
                            <p class="text-center mt-3" *ngIf="!userExists">Are you sure you want to update <span
                                    class="font-bold">{{userGlobal.GivenName | titlecase}}'s </span>Information?</p>
                            <div class="flex justify-between m-2">
                                <button type="button" (click)="closeConfirmationModal()"
                                    class="font-bold text-black border-none">Cancel</button>
                                <button class="px-3 py-2 text-customTeal font-bold border-none rounded-md" type="submit"
                                    [disabled]="!employeeEditForm.valid">Update</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>

    <!-- Modals -->
    <div id="my-modal-disable" *ngIf="updateModal" class="modal"
        [ngClass]="{'modal-open': updateModal, 'modal-action': !updateModal }">
        <div class="modal-box">
            <div class="modal-content">
                <h3 class="font-bold text-lg text-center mb-2">Employee Profile Update</h3>
                <hr />
                <p class="text-center mt-3" *ngIf="!userExists">Employee <span class="font-bold">{{userGlobal.GivenName
                        | titlecase}}</span> has been updated.</p>
            </div>
        </div>
    </div>

    <div id="my-modal-confirmDeleteModal" *ngIf="confirmDeleteModal" class="modal"
        [ngClass]="{'modal-open': confirmDeleteModal, 'modal-action': !confirmDeleteModal }">
        <div class="modal-box">
            <div class="modal-content">
                <h3 class="font-bold text-lg text-center mb-2">Delete My Account</h3>
                <hr />
                <p class="text-center mt-3"> <span class="font-bold">{{userGlobal.GivenName | titlecase}},</span> are
                    you sure you want
                    to delete
                    your account?</p>
                <p class="text-center mt-3">If you delete your account you will not be able to login again to
                    {{appName}}. <span class="font-bold">Do you want to proceed?</span></p>

                <div class="modal-action items-center justify-between m-4">
                    <label (click)="closeDeleteModalOne()"
                        class="py-2 px-4 text-black border-customTeal border-2 rounded-md ">No</label>
                    <label (click)="openSecondModalTwo()"
                        class="py-2 px-4 bg-red-500 text-white border-red-500 rounded-md ">Yes</label>
                </div>
            </div>
        </div>
    </div>

    <div id="my-modal-confirmSecondModal" *ngIf="confirmSecondModal" class="modal"
        [ngClass]="{'modal-open': confirmSecondModal, 'modal-action': !confirmSecondModal }">
        <div class="modal-box">
            <div class="modal-content">
                <h3 class="font-bold text-lg text-center mb-2">Delete My Account</h3>
                <hr />
                <p class="text-center mt-3"> Once you confirm you will not be able to log in to {{appName}}</p>
                <p class="text-center mt-3"> <span class="font-bold">Do you want to proceed?</span></p>

                <div class="modal-action items-center justify-between m-4">
                    <label (click)="closeSecondModalTwo()"
                        class="py-2 px-4 text-black border-customTeal border-2 rounded-md ">No</label>
                    <label (click)="openThreeModal()"
                        class="py-2 px-4 bg-red-500 text-white border-red-500 rounded-md ">Yes</label>
                </div>
            </div>
        </div>
    </div>

    <div id="my-modal-confirmThreeModal" *ngIf="confirmThreeModal" class="modal"
        [ngClass]="{'modal-open': confirmThreeModal, 'modal-action': !confirmThreeModal }">
        <div class="modal-box">
            <div class="modal-content">
                <h3 class="font-bold text-lg text-center mb-2">Delete My Account</h3>
                <hr />
                <p class="text-center mt-3"> Are you sure?</p>
                <div class="modal-action items-center justify-between m-4">
                    <label (click)="closeThreeModal()"
                        class="py-2 px-4 text-black border-customTeal border-2 rounded-md ">Cancel</label>
                    <label (click)="beforeDelete()"
                        class="py-2 px-4 bg-red-500 text-white border-red-500 rounded-md ">Delete</label>
                </div>
            </div>
        </div>
    </div>

    <div id="my-modal-delete" *ngIf="beforeDeleteModal" class="modal"
        [ngClass]="{'modal-open': beforeDeleteModal, 'modal-action': !beforeDeleteModal }">
        <div class="modal-box">
            <div class="modal-content">
                <h3 class="font-bold text-lg text-center mb-2">Your Account has been deleted</h3>
                <hr />
                <p class="text-center mt-3"> Have a great day.</p>
            </div>
        </div>
    </div>
</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
