import { Component, Input, Output, EventEmitter, OnInit, forwardRef  } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ]
})
export class ToggleComponent {
  @Input() checked: boolean = false; // Current state of the toggle
  @Input() disabled: boolean = false; // Disable the toggle
  @Input() label: string = ''; // Optional label
  @Input() styles: { [key: string]: string } = {}; // Inline styles
  @Input() classList: string = ''; // Custom CSS classes
  @Input() backgroundColor: string = '#4CAF50';
  @Output() toggleChange = new EventEmitter<boolean>(); // Emit state changes
  
  value: boolean = false; // Current value of the toggle
  onChange = (value: boolean) => {}; // Function to propagate changes
  onTouched = () => {}; // Function to mark as touched

  toggle() {
    if (!this.disabled) {
      this.value = !this.value;
      this.onChange(this.value); // Notify Angular form about the change
      this.onTouched(); // Mark as touched
    }
  }

    // ControlValueAccessor methods
    writeValue(value: boolean): void {
      this.value = value || false;
    }
  
    registerOnChange(fn: any): void {
      this.onChange = fn;
    }
  
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }
  
    setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }
}
