import { Component, Input, OnInit, } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
    selector: 'app-message-item',
    templateUrl: './message-item.component.html',

})
export class MessageItemComponent implements OnInit {


    @Input() data;
    imgLink
    openImageModal=false
    constructor(
        private helperService:HelperService
    ) { }


    ngOnInit() {
    }

    viewImage(link){
        this.imgLink=link
        this.openImageModal=true
    }
    closeModal(){
        this.imgLink=undefined
        this.openImageModal=false
    }
}
