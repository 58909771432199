<div>
  <div class="flex justify-between px-6 md:px-0 md:space-x-28 items-center md:justify-center ">
    <div class="flex-row text-center text-md items-center justify-center md:mx-6 ">
      <button (click)="togglePending()" [ngClass]="{'text-white bg-customTeal': isPending, 'text-black': !isPending}"
        class="font-medium border-2 border-customTeal cursor-pointer px-3 md:px-6 py-1 rounded-full md:font-bold">Pending
      </button>
    </div>
    <div class="flex-row text-center text-md">
      <button (click)="toggleAccept()" [ngClass]="{'text-white bg-customTeal': isAccepted, 'text-black': !isAccepted}"
        class="font-medium border-2 border-customTeal cursor-pointer px-3 md:px-6 py-1 rounded-full md:font-bold">Accepted</button>
    </div>
    <div class="flex-row text-center text-md">
      <button (click)="toggleDecline()" [ngClass]="{'text-white bg-customTeal': isDeclined, 'text-black': !isDeclined}"
        class="font-medium border-2 border-customTeal cursor-pointer px-3 md:px-6 py-1 rounded-full md:font-bold">Declined</button>
    </div>
  </div>

  <div>
    <div class=" flex flex-col space-y-2 mx-2 mt-2 md:mx-12 lg:mx-4"
      *ngIf="verificationRequest && verificationRequest.length != 0">
      <div class=" flex flex-row items-center mt-12 p-2 md:space-x-6">
        <div class="w-8/12 md:w-4/12 flex md:mx-4">
          <p class=" text-sm">Name</p>
        </div>
        <div class="w-3/12 hidden md:block ">
          <p class=" text-sm">Email</p>
        </div>
        <div class="w-2/12 hidden md:block md:mx-4">
          <p class=" text-sm">Date</p>
        </div>
        <div class="w-2/12">
          <p class=" text-sm">Status</p>
        </div>
        <div class="w-2/12 md:w-1/12">
        </div>
      </div>
    </div>

    <div class=" flex flex-col space-y-2 mx-2 mt-2 md:mx-12 lg:mx-4">
      <div *ngFor="let item of verificationRequest ; let i=index">
        <div (click)="viewClientProfile(item)"
         class="flex flex-row bg-white cursor-pointer items-center mt-2 p-2 rounded-md mx-2  border-2 md:space-x-6">
        <div class=" w-8/12 md:w-4/12 flex">
          <div class="flex items-center">
            <div class="avatar placeholder">
              <div class="bg-neutral-focus text-neutral-content rounded-full w-10 h-10 px-4 mx-4">
                <span class="text-lg">{{item.firstName[0] | titlecase}}{{item.lastName[0] |titlecase}}</span>
              </div>
            </div>
            <div>
              <div class="">{{item.firstName}} {{item.lastName}}</div>
            </div>
          </div>
        </div>
        <div class="w-4/12 hidden md:block">
          <p class=" text-sm">{{item.email}}</p>
        </div>

        <div class="w-2/12 hidden md:block">
          <p class=" text-sm">{{item.date.toDate() | date}}</p>
        </div>

        <div class="flex items-center w-2/12">
          <p class=" text-sm text-yellow-500" *ngIf="item.status == 'Pending'">{{item.status}}</p>
          <p class=" text-sm text-green-500" *ngIf="item.status == 'Accepted'">{{item.status}}</p>
          <p class=" text-sm text-red-500" *ngIf="item.status == 'Declined'">{{item.status}}</p>

        </div>

        <div class="w-2/12 md:w-1/12">
          <button type="button" (click)="viewClientProfile(item)">
            <i class="material-icons material-symbols-outlined float-left">arrow_circle_right </i>
          </button>
        </div>
      </div>

      </div>
    </div>
  </div>
  <br /> <br />

  <div class="mt-5" *ngIf="!verificationRequest || verificationRequest.length == 0">
    <div class="flex items-center justify-center">
      <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
        class="flex w-64 items-center justify-center">
    </div>
    <div class="text-customTeal text-lg text-center font-bold"
      *ngIf="!isPending && !isAccepted && !isDeclined && !verificationRequest">You have no Verification Requests.</div>
    <div class="text-customTeal text-lg text-center font-bold" *ngIf="isPending">You have no Pending Verification
      Requests.</div>
    <div class="text-customTeal text-lg text-center font-bold" *ngIf="isAccepted">You have no Accepted Verification
      Requests.</div>
    <div class="text-customTeal text-lg text-center font-bold" *ngIf="isDeclined">You have no Declined Verification
      Requests.</div>
    <div class="text-gray-400 text-sm text-center font-bold"> Check back later!</div>
  </div>


  <!-- <app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
     -->


</div>