<!-- For mobile -->
<div *ngIf="!isLogged && !(pageName=='Login' || pageName=='Register')"
  class="bg-white shadow-sm my-0 top-0 fixed lg:hidden navbar " style="z-index: 5">
  <div class="navbar-start pl-4">
    <button *ngIf="router.url != '/'" class="" (click)="backButton()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
        class="w-5 h-5"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
          d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
      </svg>
      <!-- Back -->
      <!-- {{pageName}} -->
    </button>
  </div>
  <div class="navbar-center font-bold" *ngIf="appSetting">
    <span [routerLink]="['/']" *ngIf="!appSetting.logo">{{appSetting.appName}}</span>
    <img loading="lazy" [routerLink]="['/']" *ngIf="appSetting.logo" [src]="appSetting.logo" class="w-32 h-20 object-contain" alt="Logo">
  </div>
  <div class="navbar-end ">
    <div class="dropdown dropdown-end bg-white">
      <label tabindex="0" class="btn btn-ghost btn-circle">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </label>
      <ul tabindex="0" class="menu menu-compact dropdown-content bg-white mt-3 p-2 shadow  rounded-box w-52 ">
        <ng-container *ngIf="nationOfuse=='Build'">
          <li class=" w-full hover:text-customTeal  hover:font-bold"><a href="https://goingmobile.app"
              onclick="document.activeElement.blur()">Home</a></li>
        </ng-container>
        <ng-container *ngIf="nationOfuse!='Build'">
          <li class=" w-full hover:text-customTeal hover:font-bold"><a [routerLink]="['/']"
              onclick="document.activeElement.blur()">Home</a></li>
        </ng-container>
        <li class=" hover:text-customTeal hover:font-bold">
          <ng-container *ngIf="appSetting && appSetting.appSections && nationOfuse!='Build'">
            <ng-container *ngFor="let section of appSetting.appSections">
              <ng-container *ngIf="section.isEnabled">
                <a *ngIf="section.originalName=='Services' && !isNationApp" (click)="redirectToServices()"
                  onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">{{section.name |
                  titlecase}}</a>
                <a *ngIf="section.originalName=='Products' && !isNationApp" (click)="redirectToProduct()"
                  onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">{{section.name |
                  titlecase}}</a>
                <a *ngIf="section.originalName=='Events'" [routerLink]="['/events']"
                  onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">{{section.name |
                  titlecase}}</a>
                <a *ngIf="section.originalName=='Blogs'" [routerLink]="['/blogs']"
                  onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">{{section.name |
                  titlecase}}</a>
                <a *ngIf="section.originalName=='About Us'" [routerLink]="['/about']"
                  onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">{{section.name |
                  titlecase}}</a>
                <a *ngIf="section.originalName=='Footer'" [routerLink]="['/contact-us']"
                     onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">{{section.name |
                     titlecase}}</a>
                <ng-container *ngIf="section.isCustomSection && section.addToMenu">
                  <a (click)="redirectToSectionPage(section)" onclick="document.activeElement.blur()"
                    class="hover:text-customTeal hover:font-bold z-50">{{section.name | titlecase}}</a>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </li>
        <li class=" hover:text-customTeal hover:font-bold" *ngIf="isChiefApp"><a  [routerLink]="['/nation-leaders']"
          onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">First Nation Leaders</a></li>
          <ng-container *ngIf="appSetting && appSetting.appSections && nationOfuse!='Build'">
            <ng-container *ngFor="let section of appSetting.appSections">
              <li *ngIf="section.originalName=='Login/Signup'" class=" hover:text-customTeal hover:font-bold"><a [routerLink]="['/auth/login']"
                onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">{{ section.name }}</a></li>
            </ng-container></ng-container>
        <!-- <li class=" hover:text-customTeal hover:font-bold"><a [routerLink]="['/registration-request']" onclick="document.activeElement.blur()"  class="hover:text-customTeal hover:font-bold">Signup</a></li> -->
        <li *ngIf="nationOfuse === 'Demo'" class="hover:text-customTeal hover:font-bold flex justify-end "><a
            [routerLink]="['/auth/help-center']" onclick="document.activeElement.blur()"
            class="hover:text-customTeal  hover:font-bold flex">
            Support</a></li>
        <!-- <li class="hover:text-customTeal hover:font-bold flex justify-end ">
          <app-download-pw-abutton></app-download-pw-abutton>
        </li> -->

        <li *ngIf="nationOfuse === 'Portal' || nationOfuse === 'FNLeaders'"
          class="hover:text-customTeal hover:font-bold  flex justify-end "><a [routerLink]="['/get-app']"
            onclick="document.activeElement.blur()" class="hover:text-customTeal hover:font-bold">Build your platform</a>
        </li>


      </ul>
    </div>
  </div>

  <!-- <div class="navbar-end">
  </div> -->
</div>

<nav class="container mx-auto  md:px-10 sm:px-16 py-4 border-b lg:block hidden"
  *ngIf="!isLogged && !(pageName=='Login' || pageName=='Register')">
  <div class="flex px-6 justify-between ">
    <!-- Logo -->
    <ng-container *ngIf="appSetting">
      <div class="mr-2 " *ngIf="appSetting.logo">
        <img loading="lazy" [routerLink]="['/']" *ngIf="appSetting.logo" [src]="appSetting.logo" class="w-44 h-16  object-contain" alt="Logo">
      </div>
    </ng-container>

    <!-- menu items -->
    <div class="hidden md:flex space-x-8 mt-2 md:items-center w-full justify-end">
      <ng-container *ngIf="nationOfuse!='Build'">
        <a [routerLink]="['/']" class="hover:text-customTeal  text-base "
        [ngClass]="{'border-b-2 border-solid border-customTeal font-bold text-customTeal': headerState =='Home', 'text-darkGray' :headerState !='Home' }"
        (click)="headerState='Home'">Home</a>
      </ng-container>
      <ng-container *ngIf="nationOfuse=='Build'">
        <a href="https://goingmobile.app" class="hover:text-customTeal text-darkGray text-base font-bold">Home</a>
      </ng-container>
      <ng-container *ngIf="appSetting && appSetting.appSections && nationOfuse!='Build'">
        <ng-container *ngFor="let section of appSetting.appSections">
          <ng-container *ngIf="section.isEnabled">
            <a *ngIf="section.originalName=='Services' && servicesArray && servicesArray.length > 0 && !isNationApp" (click)="redirectToServices()"
            (click)="headerState= section.originalName"
            [ngClass]="{'border-b-2 border-solid border-customTeal font-bold text-customTeal': headerState ==section.originalName, 'text-darkGray' :headerState !=section.originalName }"  
            class="hover:text-customTeal  text-base ">{{section.name | titlecase}}</a>
            <a *ngIf="section.originalName=='Products' && productsArray && productsArray.length > 0 && !isNationApp" (click)="redirectToProduct()"
            (click)="headerState= section.originalName"
            [ngClass]="{'border-b-2 border-solid border-customTeal font-bold text-customTeal': headerState ==section.originalName, 'text-darkGray' :headerState !=section.originalName }" 
              class="hover:text-customTeal  text-base ">{{section.name | titlecase}}</a>
            <a *ngIf="section.originalName=='Events' && upcomingEvent && upcomingEvent.length > 0" [routerLink]="['/events']"
            (click)="headerState= section.originalName"
            [ngClass]="{'border-b-2 border-solid border-customTeal font-bold text-customTeal': headerState ==section.originalName, 'text-darkGray' :headerState !=section.originalName }" 
              class="hover:text-customTeal text-base ">{{section.name | titlecase}}</a>
            <a *ngIf="section.originalName=='Blogs' && postsArray && postsArray.length > 0" [routerLink]="['/blogs']"
            (click)="headerState= section.originalName"
            [ngClass]="{'border-b-2 border-solid border-customTeal font-bold text-customTeal': headerState ==section.originalName, 'text-darkGray' :headerState !=section.originalName }" 
              class="hover:text-customTeal text-base ">{{section.name | titlecase}}</a>
            <a *ngIf="section.originalName=='About Us' && about && about.sections && (about.sections.length > 0 || (employeeGlobal.length > 0 && about.showEmployees) )" [routerLink]="['/about']"
            (click)="headerState= section.originalName"
            [ngClass]="{'border-b-2 border-solid border-customTeal font-bold text-customTeal': headerState ==section.originalName, 'text-darkGray' :headerState !=section.originalName }" 
              class="hover:text-customTeal text-base ">{{section.name | titlecase}} </a>
            <a *ngIf="section.originalName=='Footer'" [routerLink]="['/contact-us']"
            (click)="headerState= section.originalName"
            [ngClass]="{'border-b-2 border-solid border-customTeal font-bold text-customTeal': headerState ==section.originalName, 'text-darkGray' :headerState !=section.originalName }" 
              class="hover:text-customTeal text-base ">{{section.name | titlecase}}</a>
            <a *ngIf="section.originalName=='faq' && faqArray && faqArray.length > 0" [routerLink]="['/faq-list']"
            (click)="headerState= section.originalName"
            [ngClass]="{'border-b-2 border-solid border-customTeal font-bold text-customTeal': headerState ==section.originalName, 'text-darkGray' :headerState !=section.originalName }" 
              class="hover:text-customTeal text-base ">{{section.name | titlecase}}</a>    
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(nationOfuse=='Demo' || hasCustemSectionsMenu)&& (nationOfuse!=='Build') || isChiefApp">
          <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com --> 

          <div class="dropdown z-50 " style="z-index:99999;">
            <label tabindex="0" class="m-1 hover:text-customTeal text-base  text-darkGray">More <span><i
                  class="material-icons float-right">expand_more</i></span></label>
            <ul tabindex="0" 
              class="dropdown-content z-50 menu menu-compact p-2 shadow relative bg-white rounded-box w-52 ">
              <li class=" hover:text-customTeal text-base  hover:font-bold z-50">
                <a *ngIf="nationOfuse === 'Demo'" [routerLink]="['/auth/help-center']"
                  class=" invisible p-2 text-black rounded-md baseline md:visible flex"><i
                    class="material-icons pr-2 text-black float-left">help</i> Support</a>
              </li>
              <!-- if -->
              <li *ngIf="isChiefApp" class=" hover:text-customTeal text-base  hover:font-bold z-50" >
                <a [routerLink]="['/nation-leaders']" onclick="document.activeElement.blur()"
                class="hover:text-customTeal text-base  hover:font-bold z-50">First Nation Leaders</a>
              </li>
              <li class=" hover:text-customTeal text-base  hover:font-bold z-50">
                <ng-container *ngIf="hasCustemSectionsMenu">
                  <ng-container *ngFor="let section of appSetting.appSections">
                    <ng-container *ngIf="section.isEnabled">
                      <ng-container *ngIf="section.isCustomSection && section.addToMenu">
                        <a (click)="redirectToSectionPage(section)" onclick="document.activeElement.blur()"
                          class="hover:text-customTeal text-base  hover:font-bold z-50">{{section.name | titlecase}}</a>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>

              </li>

            </ul>
          </div>
          <!-- <div class="navbar-end">
              <div class="dropdown dropdown-end">
                <label>More</label>
                <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 ">
                  <li class=" w-full hover:text-customTeal hover:font-bold" ><a [routerLink]="['/']" onclick="document.activeElement.blur()" >Home</a></li>
        <li class=" hover:text-customTeal hover:font-bold">
                    
                    <ng-container *ngFor="let section of appSetting.appSections">
                      <ng-container *ngIf="section.isEnabled">
                        <ng-container *ngIf="section.isCustomSection && section.addToMenu">
                          <a  [routerLink]="['/contact-us']" onclick="document.activeElement.blur()"  class="hover:text-customTeal hover:font-bold">{{section.name | titlecase}}</a>
                        
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </li>
                </ul>
              </div>
            </div> -->
        </ng-container>


      </ng-container>
      <ng-container *ngIf="appSetting && appSetting.appSections && nationOfuse!='Build'">
        <ng-container *ngFor="let section of appSetting.appSections">
          <a *ngIf="section.originalName=='Login/Signup' && section.isEnabled" [routerLink]="['/auth/login']" class="hover:text-customTeal text-darkGray font-bold"
          class="flex items-center rounded bg-customTeal px-6 py-2 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
             {{ section.name }}</a>
            </ng-container></ng-container>

      <!-- <app-download-pw-abutton></app-download-pw-abutton> -->
    </div>
    <!-- button -->
    <a *ngIf="nationOfuse === 'Portal' || nationOfuse === 'FNLeaders'" [routerLink]="['/get-app']"
      class=" invisible p-2 px-6 text-customTeal border-2 border-customTeal rounded-md  baseline hover:bg-customTeal hover:text-white md:visible">Build
      Your Own Platform</a>

  </div>

</nav>

<div *ngIf="!isLogged && !(pageName=='Login' || pageName=='Register')" class="block lg:hidden h-16">
</div>