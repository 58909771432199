import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import MyCustomUploadAdapterPlugin from '../../../admin/uploadAdapter.js';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray, ValidatorFn, AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { EmailService } from 'src/app/services/api/email.service';
import { catchError, debounceTime, delay, distinctUntilChanged, map, Observable, of, Subject, switchMap } from 'rxjs';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})

export class EmployeeFormComponent implements OnInit {
  @Input() employeeFromHome: string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() employeeInvited = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();

  @Input() calledFromEmptyDashboard:string;

  //currentUser
  currentUser: any;

  //toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  //email to be sent for the invite
  emailInvite: any;
  userType = 'Employee';

  //invite
  inviteGlobal : any;
  invitationGlobal: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-from-invite/' : 'http://localhost:4200/register-from-invite/';

  //test
  users:any;
  invites: any;
  count : number = 0;

  //Email
  publicChecked: boolean = false;
  SubscribeChecked: boolean = false;
  donotShowChecked: boolean = false;
  EmployeePublicPreference = [];

  //phone
  publicPhoneChecked : boolean = false;
  SubscribePhoneChecked: boolean = false;
  donotShowPhoneChecked: boolean = false;

  //work
  publicWorkChecked : boolean = false;
  SubscribeWorkChecked: boolean = false;
  donotShowWorkChecked: boolean = false;

  publicShow : boolean = false;
  SubscribeShow : boolean = false;
  noShow : boolean = false;

  contact = [{Email:"donotShow", Phone:"donotShow", Work:"donotShow"}]
  imageUrl: any;
  //finish Modal
  finishModal: boolean = false;
  userExists: boolean = false;
  employeeSchedule: any;
  date: boolean = false;
  clickedDate: any;
  selectedDate: any;

    //image
    mediaType: any;
    binDoc = undefined;
    base64Image = undefined;
    mobileBase64Image: any;
    isMobile = false;
    model: any;
    imgURL: any;
    imgErrorMsg: any;
    pdfErrorMsg: any;
    profileImgLink: any;

    //Showing Employee
    showEmployee:any;
    newEmpData: any;

    //Phone Number
    inputText:any;
    checkboxDisabled: boolean = true;

    //name
    names: String = "";
    lname: String = "";
    daysOfTheWeek: any[] = [
      {
        day: 'Sunday',
        startTime: null,
        endTime: null,
        unAvailable: true
      },
      {
        day: 'Monday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Tuesday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Wednesday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Thursday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Friday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Saturday',
        startTime: null,
        endTime: null,
        unAvailable: true
      },
  
    ];
    useBase64 = false;
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public extensionMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ')',
   ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneNoPattern = '^[0-9]{10}$';
  employeeForm: UntypedFormGroup;
  editorConfig = {
    removePlugins: ['EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
    extraPlugins: [MyCustomUploadAdapterPlugin]
  };

  dtTrigger: Subject<any> = new Subject();

  addEmployeeModal : boolean  = false;

  cropModalOpen = false;
  imageChangedEvent: any = "";
  mediaList = [];
  uploadFileModalOpen = false;  
  newEventValues: any = {};
  returnedMediaLinks = {
    imageURL: ""
  };
//Permission


inviteEmp: boolean;
inviteSub: boolean;
enableDisableSub: boolean;
enableDisableEmp: boolean;
acceptRequest: boolean;
declineRequest: boolean;

createPosts: boolean;
editPost: boolean;
deletePost: boolean;

createEvents: boolean;
editEvents: boolean;
deleteEvents: boolean;

createServices: boolean;
editServices: boolean;
deleteServices: boolean;

createProducts: boolean;
editProducts: boolean;
deleteProducts: boolean;

viewAnalytics: boolean;
linkAccount: boolean;
unlinkAccount: boolean;

createSocialPost: boolean;
  editSocialPost: boolean;
  deleteSocialPost: boolean;

  socialViewAnalytics: boolean;

  landingPageSetup: boolean;
  aboutUsPageSetup: boolean;
  appSetup: boolean;
  accountVerification: boolean;
  editClients: boolean;
  editEmployees: boolean;
  subInfo: boolean;

  emailExists:boolean = false;

  permissionGlobal: any;
  allSpecialPriviledge: boolean;
  allChatPriviledge: boolean;

  permission = [{inviteSub: false, inviteEmp: false, enableDisableSub: false, enableDisableEmp: false, acceptRequest: false,
    declineRequest: false,resetPass: false, contactUsers: false, scheduleEmp:false},

    { createPosts : false, editPost: false, deletePost: false, createEvents: false, editEvents: false,
    deleteEvents: false, createServices: false, editServices: false, deleteServices: false, createProducts: false,
    editProducts: false, deleteProducts: false,  addCategoryProducts:false, editCategoryProducts:false,
      addServiceCategory: false, editServiceCategory: false},

      { viewAnalytics: false},

      { linkAccount: false, unlinkAccount: false, createSocialPost:false,
    editSocialPost: false, deleteSocialPost:false, socialViewAnalytics:false, createProfile: false, deleteProfile:false },
    
    {
      landingPageSetup:false, aboutUsPageSetup: false, appSetup: false,  
      accountVerification: false, editClients: false, editEmployees: false, 
      subInfo: false, permissionAccess: false, calendar: false, newPage:false, welcomePopup : false, 
      importAndDelete:false, editFAQ: false, deleteFAQ: false},
    
      {
        privateChat: false, groups: false, createGroup: false, newEmployee: false
      },
      {
        viewPage: false, createPage: false, editPage: false, deletePage: false, 
        viewItem: false, createItem: false, editItem: false, deleteItem: false
      }];

    //  const [permMember, permPES, perm, permSocial, permSpecial, permChat] = this.permission;

        permMember = this.permission[0];
        permPES = this.permission[1];
        perm = this.permission[2];
        permSocial = this.permission[3];
        permSpecial = this.permission[4];
        permChat = this.permission[5];

  permCustomPage = this.permission[6];

  permissionDisplay: any;
  // perm: any;
  //permSpecial: any;
  permissionEdit: any;
  permissionSpecialEdit: any;

  allPriviledge: any;
  allMemberPriviledge: any
  allPESPriviledge : any;
  allSocialPriviledge: any;
  allCustomPagePrivilege: any;

  isVerifyUsers:boolean;
  openMemberCollapse = false;
  openPostCollapse = false;
  openSocialCollapse = false;
  openAnalyticsCollapse = false;
  openSpecialCollapse = false;
  openChatCollapse = false;
  openCustomPageCollapse = false;

  role: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  allEmployeesEmail = [];
  allInvitedEmployeesEmail = [];  existingUser = [];

  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private inviteService: InviteService,
              private emailService: EmailService,
              private router: Router,
              private settingsService:AppSettingsService) { }
  
  get schedules(){return this.employeeForm.get('schedules') as UntypedFormArray; }

  ngOnInit(): void {

    let controls;
    this.currentUser = this.authService.getGlobalUser();
    this.role = this.currentUser.role;
    
    this.employeeForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      // email: ['', [Validators.required, Validators.email, this.validateEmail()]],
      email: ['', [Validators.required, this.validateEmail(),this.validateInvitedEmail(), Validators.email]], // Note async validator
      jobTitle: [''],
      extension: [''],
      cellPhone: ['', [Validators.pattern(this.phoneNoPattern)]],
      workPhone: ['', [Validators.pattern(this.phoneNoPattern)]],
      biography: [''],
      greetings: [''],
      signoff: [''],
      appointment: [false],
      imgLink: [''],
      toggleEmail:['',[Validators.required]],
      toggleWork:['',[Validators.required]],
      togglePhoneNumber:['',[Validators.required]],
      toggleShow:['',[Validators.required]],
      userType: ['Employee'],
      signOff:[''],
      messageSMS:[''],
      permission: {},
      recieveAppointmentSMS: [false],
      recieveAppointmentEmail: [false],
      
    });

    controls = this.daysOfTheWeek.map(sc => {
      return this.createFormGroup(sc);
    });
    this.userService.getAllEmployee().valueChanges().subscribe((employees: any) => {
      if (employees) {
        this.allEmployeesEmail = employees.map(employee => employee.Email);
      }
    })
    this.inviteService.getPendingInvites().valueChanges().subscribe((invitedEmployees: any) => {
      if (invitedEmployees) {
        this.allInvitedEmployeesEmail = invitedEmployees.map(employee => employee.inviteEmail);
      }
    })

    this.employeeForm.registerControl('schedules', new UntypedFormArray(controls));

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.isVerifyUsers = this.model.verifyUsers;
          }
        }
      }
    );
  }
  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }


  SaveEmpData(model){
  this.permission = [this.permMember, this.permPES, this.perm, this.permSocial, this.permSpecial, this.permChat, this.permCustomPage];
  this.EmployeePublicPreference[0] = {Email:model.toggleEmail};
  this.EmployeePublicPreference[1] = {Phone:model.togglePhoneNumber};
  this.EmployeePublicPreference[2] = {Work:model.toggleWork};

  this.contact[0].Email = model.toggleEmail;
  this.contact[0].Phone = model.togglePhoneNumber;
  this.contact[0].Work = model.toggleWork;

  // this.userService.getUsers().valueChanges().subscribe((users:any) => {
  //   if(users && users.length != 0){
  //     this.existingUser= users;
  //   }
  // })



  this.users = this.userService.getUserByEmail(model.email).valueChanges().subscribe(users => {
    if(users.length == 0){
      const timestamp = new Date();
      this.invites = this.inviteService.getInviteByEmail(model.email).valueChanges().subscribe(invite => {
          if (invite) {
            if (invite.length === 0) {
              this.inviteService.saveInvite({ 
                inviteEmail: model.email, 
                timestamp, 
                userType: this.userType, 
                firstName:model.firstName, 
                lastName:model.lastName,
                jobTitle:model.jobTitle, 
                greetings: model.greetings, 
                messageSMS: model.messageSMS,
                signOff: model.signOff, 
                cellPhone:model.cellPhone, 
                workPhone:model.workPhone, 
                appointment:model.appointment, 
                extension: model.extension, 
                contact:this.contact, 
                showEmployee: model.toggleShow, 
                profileImgLink: model.profileImgLink ? model.profileImgLink : '', 
                permission: this.permission,
                schedule: model.schedules,
                recieveAppointmentEmail: model.recieveAppointmentEmail,
                recieveAppointmentSMS: model.recieveAppointmentSMS
              }).then((invite) => {
              })   
              //this.invites.unsubscribe();
            }
              let hostname, email,appName, FirstName, LastName,
                jobtitle, extension,cellPhone,workPhone,appointment,
                permission, recieveAppointmentEmail, recieveAppointmentSMS
              //let profileImgLink
              this.inviteGlobal = invite;
              if(invite.length === 1){
              hostname = `${this.host}${this.inviteGlobal[0].id}`;
              email = model.email;
              appName = this.currentUser.GivenName ? this.currentUser.GivenName : '';
              FirstName = this.inviteGlobal[0].firstName
              LastName = this.inviteGlobal[0].lastName
              jobtitle = this.inviteGlobal[0].jobTitle
              extension = this.inviteGlobal[0].extension
              cellPhone = this.inviteGlobal[0].cellPhone
              workPhone = this.inviteGlobal[0].workPhone
              appointment = this.inviteGlobal[0].appointment
              permission = this.inviteGlobal[0].permission
              recieveAppointmentEmail = this.inviteGlobal[0].recieveAppointmentEmail,
              recieveAppointmentSMS = this.inviteGlobal[0].recieveAppointmentSMS
              this.invites.unsubscribe();
              this.emailService.sendEmployeeInvite({
                hostname, inviteEmail: email, appName, host: this.host, id: this.inviteGlobal[0].id, firstName : FirstName, lastName : LastName, jobTitle: jobtitle,
                cellPhone, workPhone:workPhone, appointment, extension, contact:this.EmployeePublicPreference,showEmployee: model.toggleShow,
                 recieveAppointmentEmail, recieveAppointmentSMS
                },this.currentUser);
              }
              this.users.unsubscribe();

              this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' });
             
            // }
          }
        });
        if(this.employeeFromHome == 'Employee'){
          this.closeCollapse.emit('addEmployee');
          this.employeeInvited.emit('employeeInvited')
        }
      }else{
        this.userExists = true;
        this.toast({ html: 'This User exists', classes: 'red', type: 'faliure' });
        if(this.employeeFromHome == 'Employee'){
          this.closeCollapse.emit('addEmployee');
          this.employeeInvited.emit('employeeInvited')
        }
      }
    this.users.unsubscribe();
    })
    this.openFinishModal();
  }

  SubmitForm(values){
    this.newEmpData = values;
    if (this.mediaList.length > 0) {
      this.uploadFileModalOpen = true;
    }

    if (this.mediaList.length === 0) {
      this.SaveEmpData(values);
    }
  }

  closeCollapseFromEmptydashboard(){
    this.closeCollapse.emit('addEmployee');
   
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  uploadPhoto() {
    this.mediaType = 'Photo';
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue('');
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }
  
  openFinishModal(){
    this.closeEmployeeModal();
    this.finishModal = true;
    setTimeout(() => {
      this.finishModal = false;
      // this.backClicked();
      if(this.employeeFromHome == 'Employee'){
        this.employeeInvited.emit('employeeInvited');
        this.closeCollapseFromEmptydashboard()
      }else{
        this.router.navigate(['/admin/employees']);
      }
    }, 2000);
  }
  closeFinishModal(){
    this.finishModal = false;
    if(this.employeeFromHome == 'Employee'){
      this.closeCollapseFromEmptydashboard()
      this.employeeInvited.emit('employeeInvited');
    }else{
      this.router.navigate(['/admin/search-users']);
    }
  }
  openEmployeeModal(){
    this.addEmployeeModal = true;
  }
  closeEmployeeModal(){
    this.addEmployeeModal = false;
  }

  togglePermission(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permMember[propertyName];
  }

  togglePermissionPES(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permPES[propertyName];
  }

  togglePermissionSocial(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permSocial[propertyName];
  }

  togglePermissionChat(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permChat[propertyName];
  }
  togglePermissionSpecial(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permSpecial[propertyName];
  }
  togglePermissionCustomPage(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permCustomPage[propertyName];
  }

  toggleAllChatPrivilege(){
    this.allChatPriviledge = !this.allChatPriviledge;
    if (this.allChatPriviledge) {
      this.permChat = {
       privateChat:true, groups:true, createGroup:true, newEmployee: true
      }
    }else{
      this.permChat = {
        privateChat:false, groups:false, createGroup:false, newEmployee: false

      }
    }
  }

  toggleViewAnalytics(){
    if (!this.permission[2].viewAnalytics){
      this.permission[2].viewAnalytics = true;
    }else{
      this.permission[2].viewAnalytics = false;
    }
  }

  toggleAllMemberPrivilege(){
    this.allMemberPriviledge = !this.allMemberPriviledge;
    if (this.allMemberPriviledge) {
      this.permMember = {inviteSub: true, inviteEmp: true, enableDisableSub: true, enableDisableEmp: true, acceptRequest: true,
          declineRequest: true,resetPass: true, contactUsers: true, scheduleEmp:true
      }
    }else{
      this.permMember = {inviteSub: false, inviteEmp: false, enableDisableSub: false, enableDisableEmp: false, acceptRequest: false,
        declineRequest: false,resetPass: false, contactUsers: false, scheduleEmp:false
       }
    }
  }

  allMemberPriviledgeChange(value){
    this.allMemberPriviledge=value 
  }
  toggleAllPESPrivilege(){
    this.allPESPriviledge = !this.allPESPriviledge;
    if (this.allPESPriviledge) {
      this.permPES = {
        createPosts: true, editPost: true, deletePost: true, createEvents: true, editEvents:true, deleteEvents: true, createServices: true, 
        editServices: true, deleteServices: true, createProducts: true, editProducts: true, deleteProducts: true, 
        addCategoryProducts: true, editCategoryProducts: true, addServiceCategory: true, editServiceCategory: true, 
      }
    }else{
      this.permPES = {
        createPosts: false, editPost: false, deletePost: false, createEvents: false, editEvents:false, deleteEvents: false, createServices: false, 
        editServices: false, deleteServices: false, createProducts: false, editProducts: false, deleteProducts: false, 
        addCategoryProducts: false, editCategoryProducts: false, addServiceCategory: false, editServiceCategory: false, 
      }
    }
  }
  toggleAllSocialPrivilege(){
    this.allSocialPriviledge = !this.allSocialPriviledge;
    if(this.allSocialPriviledge){
      this.permSocial = { linkAccount: true, unlinkAccount: true, createSocialPost:true,
        editSocialPost: true, deleteSocialPost:true, socialViewAnalytics:true, createProfile:true, deleteProfile:true }
    }else{
      this.permSocial = { linkAccount: false, unlinkAccount: false, createSocialPost:false,
        editSocialPost: false, deleteSocialPost:false, socialViewAnalytics:false, createProfile:false , deleteProfile:false}
    }
  }
  toggleAllCustomPagePrivilege() {
    this.allCustomPagePrivilege = !this.allCustomPagePrivilege;
    if (this.allCustomPagePrivilege) {
      this.permCustomPage = {
        viewPage: true, createPage: true, editPage: true, deletePage: true, 
        viewItem: true, createItem: true, editItem: true, deleteItem: true
      }
    } else {
      this.permCustomPage = {
        viewPage: false, createPage: false, editPage: false, deletePage: false, 
        viewItem: false, createItem: false, editItem: false, deleteItem: false
      }
    }
  }

  closeAllCollapse(){
    this.openMemberCollapse = false;
    this.openPostCollapse = false;
    this.openSocialCollapse = false;
    this.openAnalyticsCollapse = false;
    this.openSpecialCollapse = false;

  }

  selectCollapseToOpen(collapsable){
    if(collapsable==='Member'){
      if(this.openMemberCollapse===false){
        this.closeAllCollapse();
        this.openMemberCollapse = true;
      } else {
        this.openMemberCollapse = false;
      }
    }
    if(collapsable==='Post'){    
      if(this.openPostCollapse===false){
        this.closeAllCollapse();
        this.openPostCollapse = true;
      } else {
        this.openPostCollapse = false;
      }
    }
    if(collapsable==='Social'){
      if(this.openSocialCollapse===false){
        this.closeAllCollapse();
        this.openSocialCollapse = true;
      } else {
        this.openSocialCollapse = false;
      }
    }
    if(collapsable==='Analytics'){
      if(this.openAnalyticsCollapse===false){
        this.closeAllCollapse();
        this.openAnalyticsCollapse = true;
      } else {
        this.openAnalyticsCollapse = false;
      }
    }
    if(collapsable==='Special'){
      if(this.openSpecialCollapse===false){
        this.closeAllCollapse();
        this.openSpecialCollapse = true;
      } else {
        this.openSpecialCollapse = false;
      }
    }
    if(collapsable==='Chat'){
      if(this.openChatCollapse===false){
        this.closeAllCollapse();
        this.openChatCollapse = true;
      } else {
        this.openChatCollapse = false;
      }
    }
    if(collapsable==='CustomPage'){
      if(this.openCustomPageCollapse===false){
        this.closeAllCollapse();
        this.openCustomPageCollapse = true;
      } else {
        this.openCustomPageCollapse = false;
      }
    }
  }
  
  extractDigits(str: string): string {
    return str.replace(/\D/g, '');
  }
  
  checkInput(event: any) {
    this.inputText = event.target.value;
    this.checkboxDisabled = this.extractDigits(this.inputText).length <= 9 ? true : false
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }

  
//  validateEmail(): ValidatorFn {  
//   console.log("----->>>GETS HERE<<<<-----")
//   return (control: AbstractControl): { [key: string]: any } => {
//     let existingEmail = [];
//     // existingEmail = this.existingRequests.filter(req => req.email === control.value);
//     existingEmail = this.existingUser.filter(user =>{
//       console.log("The users", user.Email, "also", control.value)
//       return user.Email === control.value
//     });
//     if ("admin@goingmobile.app" === control.value) {
//       return { invalidEmail: true };
//     }
//     if(existingEmail.length > 0){
//       console.log("EMAIL EXISTS")
//       return { emailExists: true };
//     }
//     else {
//       return null;
//     }
//   };
// }

// validateEmail(): AsyncValidatorFn {
//   return (control: AbstractControl): Observable<ValidationErrors | null> => {
//     const email = control.value?.trim().toLowerCase();

//     if (!email) {
//       // Return null for empty input (no validation errors)
//       return of(null);
//     }


//     return of(this.existingUser).pipe(
//       debounceTime(300), // Prevent frequent checks for fast typing
//       distinctUntilChanged(), // Only trigger validation for actual changes
//       map(users => {
//         if (email === 'admin@goingmobile.app') {
//           return { invalidEmail: true }; // Restricted email
//         }

//         const emailExists = users.some(user => user.Email.toLowerCase() === email);
//         return emailExists ? { emailExists: true } : null; // Validation result
//       }),
//       catchError(() => of(null)) // Graceful error handling
//     );
//   };
// }

// validateEmail(): AsyncValidatorFn {
//   return (control: AbstractControl): Observable<ValidationErrors | null> => {
//     const email = control.value?.trim();
//     // Return early if the email is empty
//     if (!email) {
//       return of(null); // No validation error
//     }

//     this.userService.getUserByEmail(this.email.value).valueChanges().subscribe((user:any) => {
//       if(user && user.length != 0){
//         this.emailExists = false;
//         this.existingUser= user;
//         console.log("The email", this.existingUser)
//         const emailExists = true;
//         this.emailExists = true;
//         console.log("email exists",emailExists);
//         return emailExists ? { emailExists: true } : null;
//       }
//     })
   
//   };
// }

// validateEmail(): AsyncValidatorFn {
//   this.emailExists = false;
//   return (control: AbstractControl): Observable<ValidationErrors | null> => {
//     const email = control.value?.trim();
    
//     // Return early if the email is empty
//     if (!email) {
//       return of(null); // No validation error
//     }

//     // Return the Observable from your user service to check if email exists
//     return this.userService.getUserByEmail(email).valueChanges().pipe(
//       debounceTime(300),  // Add debounce time to reduce unnecessary requests
//       switchMap((user: any) => {
//         this.emailExists = false
//         // Check if the user already exists in the database
//         if (user && user.length !== 0) {
//           console.log("Email exists:", user);
//           this.emailExists = true;
//           return of({ emailExists: true });
//         } else {
//           this.emailExists = false;
//           return of(null); // No validation error if the email doesn't exist
//         }
//       }),
//       catchError(() => {
//         console.error("Error checking email");
//         return of(null); // Return null in case of an error
//       })
//     );
//   };
// }

get firstName() { return this.employeeForm.get('firstName'); }
  get lastName() { return this.employeeForm.get('lastName'); }
  get email(){ return this.employeeForm.get('email'); }
  get cellPhone() { return this.employeeForm.get('cellPhone'); }
  get workPhone() { return this.employeeForm.get('workPhone'); }
  get extension() { return this.employeeForm.get('extension')}
  get biography() { return this.employeeForm.get('biography'); }
  get appointment() { return this.employeeForm.get('appointment'); }
  get jobTitle() { return this.employeeForm.get('jobTitle'); }
  get imgLink() { return this.employeeForm.get('imgLink'); }
  get greetings() {return this.employeeForm.get('greetings');}
  get signOff() {return this.employeeForm.get('signOff');}
  get messageSMS() {return this.employeeForm.get('messageSMS')}
  get toggleEmail() {return this.employeeForm.get('toggleEmail')}
  get togglePhoneNumber() {return this.employeeForm.get('togglePhoneNumber')}
  get toggleWork() {return this.employeeForm.get('toggleWork')}
  get toggleShow() {return this.employeeForm.get('toggleShow')}
  get recieveAppointmentEmail() {return this.employeeForm.get('recieveAppointmentEmail')}
  get recieveAppointmentSMS() {return this.employeeForm.get('recieveAppointmentSMS')}

  //get permission () {return this.employeeForm.get('permission')}


  createFormGroup(values){
    const startTime = values.unAvailable ? new UntypedFormControl({value: values.startTime, disabled: true}) :
                    new UntypedFormControl({value: values.startTime, disabled: false});
    const endTime = values.unAvailable ? new UntypedFormControl({value: values.endTime, disabled: true}) :
                    new UntypedFormControl({value: values.endTime, disabled: false});

    const formGroup =  this.fb.group({
      day: new UntypedFormControl({value: values.day, disabled: false}),
      startTime,
      endTime,
      unAvailable: new UntypedFormControl(values.unAvailable)
    });

    formGroup.setValidators(this.timeValidators());
    return formGroup;
  }


  timeValidators(): ValidatorFn{
    return(group: UntypedFormGroup) => {
          const errors = {};
          const startTime = group.controls['startTime'];
          const endTime = group.controls['endTime'];
          const unAvailable = group.controls['unAvailable'];

          if (startTime.value === null && endTime.value !== null){
            startTime.setErrors({notEquivalent: true});
          }
          if (endTime.value === null && startTime.value !== null){
            endTime.setErrors({notEquivalent: true});
          }

          if (unAvailable.value === false && startTime.value == null && endTime.value === null){
            endTime.setErrors({notEquivalent: true});
          }


          return null;

        };
  }


  updateDaySchedule(index){
    const schedule = this.schedules.at(index).value;
    const item = {
                day: this.daysOfTheWeek[index].day,
                startTime: schedule.startTime || '09:00',
                endTime: schedule.endTime || '17:00',
                unAvailable: !schedule.unAvailable
              };

    this.schedules.setControl(index, this.createFormGroup(item));

  }

  validateSchedule(schedule){
    return schedule.map(day => {
      day.startTime = (day.unAvailable === true) ? null : day.startTime;
      day.endTime = (day.unAvailable === true) ? null : day.endTime;

      // day.unAvailable = (day.startTime=== null && day.endTime ===null) ? true : false
      return day;
    });

  }  

  day(item){
    this.date = true;
    this.clickedDate = item.day;
    this.selectedDate = item;
  }

  isdateClicked(item){
    if(item.day == this.clickedDate){
      return true
    }
  }

  fileChangeEvent(event) {
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;


    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imageUrl ='';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }

  onImageUpload(response: ImageUploaderResponseModel) {
    if (response.error) {
      this.imgErrorMsg = response.errorMessage;
      return;
    }
    this.binDoc = response.binDoc;
    this.imageChangedEvent = "data:image/jpeg;base64," + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64 = true;
    this.cropModalOpen = true;
  }

  removeImage() {
    this.base64Image = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== "Image") {
        return file;
      }
    });
  }
  
  getTheUrlsSaved(event) {
    this.returnedMediaLinks = event;
    this.uploadFileModalOpen = false;
    this.newEmpData.profileImgLink = this.returnedMediaLinks.imageURL;
    this.SaveEmpData(this.newEmpData);
  }

  processCroppedImage(event) {
    this.cropModalOpen = false;
    this.base64Image = event;
    this.useBase64 = false;
    this.binDoc = this.base64Image;
    let base64result = this.base64Image.split(",")[1];

    const img = new ImageModel();
    if (this.isMobile) {
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({ type: "Image", file: img });
    } else {
      img.imgBase64 = base64result;
      this.mediaList.push({ type: "Image", file: img });
    }
  }

  closeCroppingModal() {
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  skipSetup(){
    this.skip.emit();
  }
  private validateEmail(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } => {
      const email = control.value;
      if (this.allEmployeesEmail.includes(email)) {
        return {emailExists: true};
      }
      return null;
    }
  }
  private validateInvitedEmail(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } => {
      const email = control.value;
      if (this.allInvitedEmployeesEmail.includes(email)) {
        return {emailInviteExists: true};
      }
      return null;
    }
  }


}
