import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Posts, Comment } from 'src/app/models/PostsModel';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  constructor(private db: AngularFirestore, private authService: AuthService){ }

  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;

  createPost(model: Posts){
    const newContent = {
      id: this.db.createId(),
      createdById: model.createdById,
      postTitle: model.postTitle,
      sharedLink: model.sharedLink,
      shareWith: model.shareWith,
      message: model.message,
      mediaLink: model.mediaLink,
      videoLink: model.videoLink,
      timeStamp: model.timeStamp,
      reactions: model.reactions,
      comments: model.comments,
      archived: false,
      commentable: model.commentable,
      readingTime: model.readingTime,
      opened : model.opened ? model.opened : [],
    };
    return this.db.collection('Posts').doc(newContent.id).set(newContent);
  }

  getPostsByUserId(id, audienceId){
    if (audienceId === ''){
      this.postsListRef = this.db.collection('Posts', ref => ref.where('createdById', '==', id).where('archived', '==', false));
      return this.postsListRef;
    }else{
      this.postsListRef = this.db.collection('Posts', ref => ref.where('createdById', '==', id)
                          .where('audienceId', '==', audienceId));
      return this.postsListRef;
    }

  }
  getRecentPosts(){
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return this.db.collection('Posts', ref => ref.where('timeStamp', '>=', yesterday));

  }
  getAllPosts(){
    this.postsListRef = this.db.collection('Posts', ref => ref.where('archived', '==', false).limit(100));
    return this.postsListRef;
  }

  getUserPosts(){
    return this.db.collection('Posts', ref => ref.where('shareWith', 'in', ['Public','User']).where('archived', '==', false));

  }

  getPostsByShareType(type){
    return this.db.collection('Posts', ref => ref.where('shareWith', '==',type).where('archived', '==', false));
  }

  getPostById(postId: any){
    return this.db.collection('Posts', ref => ref.where('id', '==', postId));
  }

  getPostByMemberId(id){
    this.postsListRef = this.db.collection('Posts', ref => ref.where('audienceList', 'array-contains', id)
                        .where('archived', '==', false));
    return this.postsListRef;
  }

  updateReactionOfApost(post){
    return this.db.collection('Posts').doc(post.id).update({reactions: post.reactions});
  }

  addCommentToPost(post, newCommentModel: Comment){
    const newContent = {
      id: this.db.createId(),
      postId: newCommentModel.postId,
      userId: newCommentModel.userId,
      userName: newCommentModel.userName,
      comment: newCommentModel.comment,
      timestamp: newCommentModel.timestamp,
      likes: newCommentModel.likes,
      replies: newCommentModel.replies
  };
    post.comments.push(newContent);
    return this.db.collection('Posts').doc(post.id).update({comments: post.comments});
  }

  updateComments(post){
    return this.db.collection('Posts').doc(post.id).update({comments: post.comments});
  }

  editPost(post){
    return this.db.collection('Posts').doc(post.id).update(post);
  }

  getPostsByAudienceId(audienceId, adminId){
    this.postsListRef = this.db.collection('Posts', ref => ref.where('createdById', '==', adminId)
                        .where('audienceId', '==', audienceId));
    return this.postsListRef;

  }

  getSavedAudienceByUserId(adminId){
    return this.db.collection('UserSavedAudiences', ref => ref.where('adminId', '==', adminId).where('saved', '==', true));
  }

  archivePost(id){
    // return this.db.collection('Posts').doc(id).update({archived: true});
    return this.db.collection('Posts').doc(id).delete();
  }

  registerUserBlogs(model){
    const registered = {
      id: model.id,
      uid: model.uid,
    }
    return this.db.collection('UserRegisteredBlogs').doc(registered.id).set(registered);
  }
  getAllRegisteredUsers(){
    return this.db.collection('UserRegisteredBlogs');
  }
  getAllRegisteredUserByBlogsId(blogId){
    return this.db.collection('UserRegisteredBlogs', ref => ref.where('id', '==', blogId));
  }
  deleteRegisteredUser(blogId,userId){
    const event = this.db.collection('UserRegisteredBlogs', ref => ref.where('id', '==', blogId));
    // return this.db.collection('UserRegisteredEvent').doc(userId).delete();
  }

}
