<div class="">
  <div class="flex min-h-screen max-h-max">
    <div class="hidden md:block  bg-white" [ngClass]="{'md:w-2/12': !minimizedMenu, 'md:w-24': minimizedMenu}">
      <div *ngIf="!minimizedMenu" class="hidden fixed w-2/12 md:block">
        <div class="py-10 pt-2 flex w-8/12 mx-auto items-center">
          <img *ngIf="appLogo" loading="lazy" [src]="appLogo" class="object-cover w-full w-max-xl h-20" alt="Logo">
        </div>
        <div (click)="toggleMinimizeMenu()" class="float-right cursor-pointer w-10 h-10">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
            <rect x="6.42" width="1.05" height="10" style="fill:#231f20" />
            <polygon
              points="2.53 4.99 4.21 3.31 4.72 3.81 3.87 4.66 5.3 4.66 5.3 5.37 3.9 5.37 4.72 6.19 4.21 6.69 2.53 5.01 2.54 5 2.53 4.99"
              style="fill:#231f20" />
            <rect width="10" height="10" style="fill:none" />
          </svg>
        </div>
        <div class="md:px-10 lg:px-14">
          <div class="p-3 hover:text-customTeal hover:font-bold"><i class="material-icons mr-4 float-left">home</i><a
            (click)="navigate()">Home</a></div>
          <!-- <div class="p-3 hover:text-customTeal hover:font-bold"><i class="material-icons mr-4 float-left ">check</i><a
              (click)="showProfilesList()" class="">Profiles</a></div> -->
        </div>
        <div class="divider px-0"></div>

        <div class="md:px-10 lg:px-14" *ngIf="!showProfiles">
          <div class="p-3 hover:text-customTeal hover:font-bold flex"><i
              class="material-icons mr-4 float-left">dashboard</i><a [routerLink]="['dashboard']">Dashboard</a></div>
          <div class="p-3 hover:text-customTeal hover:font-bold flex"><i
              class="material-icons mr-4 float-left">post_add</i><a [routerLink]="['posts']">Posts</a></div>
          <div class="p-3 hover:text-customTeal hover:font-bold flex"><i
              class="material-icons mr-4 float-left">auto_graph</i><a [routerLink]="['analytics']">Analytics</a></div>
          <div class="p-3 hover:text-customTeal hover:font-bold flex"><i
              class="material-icons mr-4 float-left">settings</i><a [routerLink]="['settings']">Settings</a></div>

        </div>
      </div>
      <div *ngIf="minimizedMenu" class="hidden fixed md:block ">

        <ul class="menu py-4 overflow-y-auto">
          <div class="pt-2 flex w-full mx-auto items-center ">
            <img *ngIf="appLogo" loading="lazy" [src]="appLogo" class="object-cover w-full h-12" alt="Logo">
          </div>
          <li>
            <a (click)="toggleMinimizeMenu()" class="float-right ">
              <div class="w-10 h-10">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                  <rect x="2.53" width="1.05" height="10" style="fill:#231f20" />
                  <polygon
                    points="7.47 4.99 5.79 3.31 5.28 3.81 6.13 4.66 4.7 4.66 4.7 5.37 6.1 5.37 5.28 6.19 5.79 6.69 7.47 5.01 7.46 5 7.47 4.99"
                    style="fill:#231f20" />
                  <rect width="10" height="10" style="fill:none" />
                </svg>
              </div>
            </a>
          </li>
          <li>
            <a (click)="navigate()">
              <i class="material-icons mr-4 float-left">home</i>
            </a>
          </li>
          <!-- <li>
            <a (click)="showProfilesList()">
              <i class="material-icons mr-4 float-left">check</i>
            </a>
          </li> -->

          <hr />
          <div class="" *ngIf="!showProfiles">
            <li><a [routerLink]="['dashboard']" class="hover:text-customTeal hover:font-bold"><i
                  class="material-icons mr-4 float-left">dashboard</i></a></li>
            <li><a [routerLink]="['posts']" class="hover:text-customTeal hover:font-bold"><i
                  class="material-icons mr-4 float-left">post_add</i></a></li>
            <li ><a [routerLink]="['analytics']" class="hover:text-customTeal hover:font-bold"><i
                  class="material-icons mr-4 float-left">auto_graph</i></a></li>
            <li><a [routerLink]="['settings']" class="hover:text-customTeal hover:font-bold"><i
                  class="material-icons mr-4 float-left">settings</i></a></li>

          </div>

        </ul>
      </div>

      <div class="p-2 absolute bottom-5 cursor-pointer" (click)="back()">
        <div *ngIf="minimizedMenu">
          <i class="material-icons mr-3 ">arrow_back</i>
        </div>
        <div *ngIf="!minimizedMenu">
          <i class="material-icons mr-3 float-left">arrow_back</i>Back
        </div>

      </div>
    </div>


    <div class="drawer w-full md:w-10/12 bg-[#F1F1F1]" [ngClass]="{'md:w-10/12': !minimizedMenu}">
      <input id="my-drawer-3" (click)="toggleSideBar()" type="checkbox" [checked]="openSidebar" class="drawer-toggle" />
      <div class="drawer-content flex flex-col">
        <!-- Navbar -->
        <div class="w-full navbar bg-[#F1F1F1] md:hidden">
          <div class="flex-none ">
            <label for="my-drawer-3" class="btn btn-square btn-ghost">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                class="inline-block w-6 h-6 stroke-current">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16">
                </path>
              </svg>
            </label>
          </div>
        </div>
        <!-- Page content here -->
        <!-- Profiles -->
        <div class="flex justify-center w-full px-5 py-3 md:px-14 lg:py-8" *ngIf="loading">
          <progress class="progress progress-customTeal w-full"></progress>
        </div>

        <div class="w-full px-5 py-3 md:px-14 lg:py-8" *ngIf="!loading">

          <!-- <div class="block w-full flex justify-between pb-6 md:hidden">
          <div>
            <img src="../../../../assets/img/Sensuous logo-01.webp" class="object-cover h-16" style="width: 120px; height: 40px;" alt="Logo">
          </div>
          <div  *ngIf="!showProfiles">
           <a [routerLink]="['settings']"
           [state]="{ profileKey: profileKey}"
              ><i class="material-icons hover:text-customTeal hover:font-bold">settings</i></a>
          </div>
        </div> -->
          <!-- <app-social-media-profile (onProfileSelect)="profileSelect($event)" *ngIf="showProfiles">
          </app-social-media-profile> -->

          <div *ngIf="hasProfile">
            <!-- <div *ngIf="!showProfiles"> -->
            <div class="mb-2 md:mb-0">
              <p class='pb-7'>Hello. You're on the account for
                <span class="block text-customTeal font-bold text-lg">{{selectedProfile.title}}</span>
              </p>


              <div class="flex flex-col  md:flex-row gap-x-6 gap-y-3">
                <!-- <div class="">
                    <select (change)="profileChange($event)" class="select select-bordered w-full max-w-xs">
                        <option disabled selected>{{selectedProfile.title}}</option>
                        <option *ngFor="let p of userProfile;let li=index" [value]="li">{{p.title}}</option>
                      </select>
              </div> -->
                <!-- <div>
                    <button class="btn btn-ghost" (click)="openProfileModal()">  <i class="text-customTeal material-icons mr-3 float-left">add</i>New Profile </button>
                </div> -->

              </div>
            </div>
            <div>
              <router-outlet></router-outlet>
            </div>

          </div>



          <div *ngIf="!hasProfile">
            <div>
              <div *ngIf="user" class="font-bold text-xl">
                Welcome <span class="text-customTeal">{{user.GivenName}}</span>
                <span class="text-base font-normal"> we're glad your here</span>
              </div>
            </div>

            <div class="px-5 md:px-0 py-10 flex flex-col w-full jusify-center">
              <div class="w-full py-4 text-center text-bold">
                Seems like you don't have any profiles. Create your profile to start posting to your social accounts!
              </div>

            </div>

            <div class="flex justify-center">
              <button (click)="openProfileModal()"
                class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                <i class="material-icons mr-3 float-left">post_add</i>
                Create Profile
              </button>

            </div>


          </div>

          <div id="createProfileModal" *ngIf="profileModalOpen" class="modal"
            [ngClass]="{'modal-open': profileModalOpen, 'modal-action': !profileModalOpen }">
            <app-close-modal (closeBtnClicked)=closeProfileModal()></app-close-modal>

            <div class="modal-box  bg-white p-6 w-11/12 md:p-10 md:w-9/12 lg:w-5/12 max-w-5xl flex">
              <div class="modal-content w-full">
                <div class="pb-4 md:pb-6 ">
                  <h3 class="font-bold text-lg text-black">Create Profile</h3>
                  <p class="text-sm text-lightGray">Create your profile and start managing your social medias. </p>
                </div>


                <form [formGroup]="profileForm" (ngSubmit)="createUserProfile(profileForm.value)">
                  <div class="grid grid-cols-1 gap-y-5">
                    <div class="form-control w-full max-w-x">
                      <label class="label">
                        <span class="label-text text-black text-base font-bold leading-5">Title</span>
                      </label>
                      <input type="text" formControlName='title' placeholder="Type here"
                        class="input input-bordered input-info w-full" />

                    </div>

                    <div class="grid grid-cols-12 form-control w-full max-w-x" *ngIf="!addEmployee && employees">
                      <label class="label col-span-12">
                        <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Add employee to manage
                          this profile</span>
                      </label>
                      <button class="text-left col-span-8 md:col-span-6  btn btn-ghost text-customTeal" type="button"
                        (click)="addEmployee=true">
                        <i class=" material-icons mr-3 float-left">add_circle</i>Add Employee
                      </button>


                    </div>

                    <div class="form-control w-full" *ngIf="addEmployee && employees">
                      <label class="label">
                        <span class="label-text text-black text-base font-bold leading-5">Pick an employee</span>
                      </label>
                      <select class="select select-bordered w-full" formControlName='employeeId'>
                        <option disabled selected>Pick an employee</option>
                        <option *ngFor="let employee of employees;let li=index" [value]="employee.uid">
                          {{employee.GivenName}} {{employee.LastName}}</option>

                      </select>
                    </div>

                    <div class="flex flex-col space-y-4 pt-4">
                      <span class="text-sm text-gray-500 max-w-screen-md"> <input type="checkbox" class="checkbox checkbox-primary mr-2 w-4 h-4  rounded-sm float-left"
                        formControlName="terms" required /> By clicking Register, I agree to <a href="https://www.ayrshare.com/terms/"  target="__blank"
                        class="underline-offset-2 text-customTeal">Terms of Service </a></span>
                    <div *ngIf="terms && ((terms.dirty || terms.touched) && terms.invalid)">
                      <small *ngIf="terms.errors.required" class="text-error">This section is required you need to agree
                        to the terms and conditions and privacy policy to proceed.. </small>
                    </div>
                    <div class="flex justify-between ">
                      <button type="button" class="font-bold" (click)="closeProfileModal()">Cancel</button>
                      <button type="submit" [disabled]="!profileForm.valid"
                        class="font-bold py-3 px-5 border-none rounded-md bg-customTeal text-white cursor-pointer"
                        [ngClass]="{'bg-gray-300 !text-black !cursor-not-allowed':!profileForm.valid}">Create</button>

                    </div>
          
                    <!-- <button  [disabled]="!registrationForm.valid" class="mx-auto px-6 py-2 border-2 font-bold rounded-lg bg-customTeal w-full md:w-96 text-white focus:outline-none"   [ngClass]="{'bg-customTeal': registrationForm.valid, 'bg-gray-400': !registrationForm.valid}">
                      Register
                    </button> -->
                    </div>


                    <!-- <div class="flex justify-between py-8">
                      <button type="button" class="font-bold" (click)="closeProfileModal()">Cancel</button>
                      <button type="submit" [disabled]="!profileForm.valid"
                        class="font-bold py-3 px-5 border-none rounded-md bg-customTeal text-white">Create</button>
                    </div> -->

                  </div>
                </form>
              </div>

            </div>

          </div>


          <div id="profileCreatedModal" *ngIf="profileCreatedModalOpen" class="modal cursor-pointer"
            [ngClass]="{'modal-open': profileCreatedModalOpen, 'modal-action': !profileCreatedModalOpen }">
            <div class="modal-box  bg-white pt-6 pb-10 w-11/12 md:w-5/12 max-w-5xl px-0">
              <div class="modal-content ">
                <div class="pb-4 md:pb-6 ">
                  <h3 class="text-center font-bold text-xl text-black pt-6">You’ve created a profile
                  </h3>
                  <div class="divider px-0"></div>

                  <div class="text-base text-[#636363] text-center px-4 md:px-6">
                    Ayrshare is our partner that helps us make your social media management easy and convinent.
                    Please Link accounts you’d like to manage.
                  </div>
                </div>

                <div class="flex justify-center items-center  gap-5">
                  <app-link-account [link]="false" [new]="true" (click)="closeSuccessModal()"></app-link-account>
                  <button (click)="closeSuccessModal()"
                    class="font-bold py-3 px-5 border-2 rounded-md border-customTeal text-black">Got it</button>

                </div>


              </div>

            </div>

          </div>


        </div>
      </div>


      <div class="drawer-side">
        <label for="my-drawer-3" class="drawer-overlay"></label>
        <ul class="menu p-4 overflow-y-auto w-60 bg-white h-screen">
          <!-- Sidebar content -->
          <div class="pt-2 flex w-8/12 mx-auto items-center ">
            <img *ngIf="appLogo" loading="lazy" [src]="appLogo" class="object-cover w-full h-16" alt="Logo">

          </div>
          <div class="ml-3 p-2 mt-5"><i class="material-icons mr-4 float-left">home</i>
            <a [routerLink]="['/admin/dashboard']" (click)="toggleSideBar()"
              class="hover:text-customTeal hover:font-bold">Home</a>
          </div>
          <!-- <div class="ml-3 p-2" ><i class="material-icons mr-4 float-left">check</i>
            <a   (click)="showProfilesList()" class="hover:text-customTeal hover:font-bold">Profile</a>
          </div> -->

          <hr />
          <div class="ml-3 pt-2" *ngIf="!showProfiles">


            <div class="p-2"><i class="material-icons mr-4 float-left">dashboard</i><a [routerLink]="['dashboard']"
                (click)="toggleSideBar()" class="hover:text-customTeal hover:font-bold">Dashboard</a></div>
            <div class="p-2"><i class="material-icons mr-4 float-left">post_add</i><a [routerLink]="['posts']"
                (click)="toggleSideBar()" class="hover:text-customTeal hover:font-bold">Posts</a></div>
            <div class="p-2"><i class="material-icons mr-4 float-left">auto_graph</i><a [routerLink]="['analytics']"
                (click)="toggleSideBar()" class="hover:text-customTeal hover:font-bold">Analytics</a></div>
            <div class="p-2"><i class="material-icons mr-4 float-left">settings</i><a [routerLink]="['settings']"
                (click)="toggleSideBar()" class="hover:text-customTeal hover:font-bold">Settings</a></div>

          </div>

          <!-- <div class="p-2 absolute bottom-5 cursor-pointer bg-white rounded-full" (click)="[back(),toggleSideBar()]">
            <div>
              <i class="material-icons mr-3 float-left">arrow_back</i>Back</div>

          </div> -->
        </ul>

      </div>
    </div>



  </div>

</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>