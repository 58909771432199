import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { ProductsService } from 'src/app/services/firebase/products.service';
import { Router } from '@angular/router';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild,Renderer2 } from '@angular/core';
// import { EmbedVideoService } from 'ngx-embed-video';
import { map } from 'rxjs/internal/operators/map';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { PostsService } from 'src/app/services/firebase/posts.service';
import { environment } from 'src/environments/environment';
import { ViewportScroller } from '@angular/common';
import { ConfigService } from 'src/app/services/api/config.service';
import { HelperService } from 'src/app/services/helper/helper';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { AboutService } from 'src/app/services/firebase/about.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';

// declare var $:any;

    // Initialization for ES Users
    import {
      Carousel,
      initTE,
    } from "tw-elements";
import { EnvironmentService } from 'src/app/services/helper/env';
import { ViewPortService } from 'src/app/services/window-service/view-port.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectAppSettings } from 'src/app/state/app.selectors';
    
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  @Input() elementId = '';
  @Input() preview;
  @Input() view;
  @Input() landingPageSettings;
  @ViewChild('hero') hero: ElementRef;
  @ViewChild('myElementId', { static: false }) myElementRef: ElementRef;

  element: HTMLElement;
  model: any;
  eventsArray: any;
  postsArray: any;
  socialMediaModel: any;
  servicesArray: any;
  productsArray: any;
  iframehtml: any;
  landingPageMain: any;
  appStore: any;
  slideIndex = 1;
  sliderSection: any;
  sliderInterval: any;
  appSettings: any;
  playStore = environment.mobileAppLinks.playStore;
  appleStore = environment.mobileAppLinks.appleStore;
  signupModalOpen = false;
  nationOfuse = environment.firstNation.name;
  section: any;
  viewType = 'list'
  landingPageView ={
    web : 'list',
    mobile : 'card'
  }

  isWebView = false;
  isMobileView = false;
  currentDate = new Date().toISOString().split('T')[0];


  landingPageTemplate: string;
  allSectionValues
  iconPosition = 0
  aboutName
  showText = false;
  // signupModalTimeout = 20000;
  emptyHero = {
    title: 'Carousel Section',
    description: `This section help show our most important content and grab the user's attention
    in a visual way. Heroes/Carousels are positioned at the top of the page. Finding ways to make your
    brand stand out through this section can impact your site.
    You can be creative with the content
    of your hero section by including what you offer,
    how users can trust you through powerful headlines,
    subtitles and captivating images.
    `
  };

  // emptyServices = [{
  //   id: 'emptyservice1',
  //   serviceName: 'Business Services',
  //   serviceDescription: `services by business organizations  to conduct their business activities. create something that assists the business, like transportation, communication etc`,
  //   price: '0'
  // }, {
  //   id: 'emptyservice2',
  //   serviceName: 'Social Services',
  //   serviceDescription: `the aim is an accomplishment of a social goal, it could be providing education, healthy facility and many others    `,
  //   price: '0'
  // }, {
  //   id: 'emptyservice3',
  //   serviceName: 'Personal Services',
  //   serviceDescription: `is where everyone has a different experience. It could be tourism, restaurants etc`,
  //   price: '0'
  // }];

  emptyProducts = [{
    id: 'product1',
    productName: 'Product 1',
    productDescription: 'Product description will be here',
    category: '',
    categoryId: ''
  }, {
    id: 'product2',
    productName: 'Product 2',
    productDescription: 'Product description will be here',
    category: '',
    categoryId: ''
  }, {
    id: 'product3',
    productName: 'Product 3',
    productDescription: 'Product description will be here',
    category: '',
    categoryId: ''
  }];
  emptyEvent = [
    {
      id: 'emptyevent1',
      eventTitle: 'Your next upcoming event',
      eventStartDate: new Date(),
      eventPlace: 'location',
      eventStartTime: '',
      eventEndTime: ''
    },
    {
      id: 'emptyevent2',
      eventTitle: 'Are you having an anniversary',
      eventStartDate: new Date(),
      eventPlace: 'location',
      eventStartTime: '',
      eventEndTime: ''
    },
    {
      id: 'emptyevent3',
      eventTitle: 'An upcoming Occasion',
      eventStartDate: new Date(),
      eventPlace: 'location',
      eventStartTime: '',
      eventEndTime: ''
    }];

  emptyTimeStamp = new Date();
  emptyBlog = [{
    postTitle: 'What is a blog?',
    message: `An online journal where you can share
              and record your ideas, activities, beliefs, thoughts…..
              this is your world!`,
    id: 'emptyblog1'
  },
  {
    postTitle: 'How to write a blog?',
    message: `Write from the heart.
              Think of your topic,
              your audience and your message`,
    id: 'emptyblog2'
  }];
  isCarousel = false;
  currency: any;
  @Input() modelToPreview: any;
  blogsName;
  eventsName;
  servicesName;
  productsName;
  welcomeOpenedCount = 0;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false
  isIosDevice: boolean = false;
  customSectionRef: unknown;
  deferredPrompt: any;
  showButton: boolean = false;
  appNotSet = false;
  serviceType: any;
  cardElement = []
  listElement = []
  about: any;
  aboutVisibility: any;
  currentUser: any;
  customSectionCount = 0
  email: any;
  phoneNumber: any;
  contactVisibility: any;
  slides = [
    { image: 'https://mdbcdn.b-cdn.net/img/new/slides/042.webp', alt: 'Wild Landscape' },
    { image: 'https://mdbcdn.b-cdn.net/img/new/slides/041.webp', alt: 'Camera' },
    { image: 'https://mdbcdn.b-cdn.net/img/new/slides/043.webp', alt: 'Exotic Fruits' },
  ];


  activeIndex = 0;
  intervalId: any;
  isChiefApp = this.envService.isChiefApp



  currentSlideIndex = 0;
  number = 0
  slideElements: string[] = [];


  viewportSize: { width: number; height: number; };
  appSettings$: Subscription;
  constructor(private settingsService: AppSettingsService,
    private aboutService: AboutService,
    private newsEventsService: NewsEventService,
    private socialService: SocialMediaService,
    // private embedService: EmbedVideoService,
    private employeeServicesService: EmployeeServicesService,
    private postsService: PostsService,
    private productsService: ProductsService,
    private router: Router,
    private productsServiceMenuService: ProductsServiceMenuService,
    private viewportScroller: ViewportScroller,
    private helperService: HelperService,
    private customSectionService: CustomSectionService,
    private elementRef: ElementRef,
    private authService: AuthService, 
    private envService: EnvironmentService,
    private viewportService: ViewPortService,
    private store: Store,
    private userService: UserService,private renderer: Renderer2, private el: ElementRef) { 
      this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
        this.model = this.helperService.cloneDeep(settings) || { appSections: [] };
        this.appSettings = this.helperService.cloneDeep(settings) || { appSections: [] };
        if(!this.model || !this.model.appSections){
          this.appNotSet = true;
          this.router.navigate(['/login']);
        }
      });
    }

  ngOnInit(): void {

    this.viewportService.getViewportSizeObservable().subscribe((size) => {
      this.viewportSize = size;
      // Ensure viewportSize is defined before accessing its properties
    });

    window.scroll(0, 0);
    initTE({ Carousel });



    this.authService.afAuth.authState.subscribe(auth => {

      if (auth) {
        this.currentUser = this.userService.getUserById(auth.uid).valueChanges().subscribe(user=>{
          if(user && (user.length > 0)){
            this.currentUser = user[0]
            if(this.currentUser){
              if(this.currentUser.role=='Admin'){
                // this.router.navigate(['/admin/homepage'])
              } else if (this.currentUser.role=='User'){
                this.router.navigate(['/user/homepage'])
              } else if (this.currentUser.role=='Employee'){
                this.router.navigate(['/employee/dashboard'])
              }
            }
          }
        });
                
      }})

    // detect if the device is on iOS
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (this.model && this.model.appSections) {
          this.getCardPosition();
          this.getListElement()
          this.viewType = this.model.viewType ? this.model.viewType : this.viewType;
          this.landingPageView = this.model.landingPageView ? this.model.landingPageView : this.landingPageView;

          this.landingPageTemplate = this.model.landingPageTemplate ? this.model.landingPageTemplate : 'default'
          this.serviceType =  this.model.serviceType;

          if (this.modelToPreview) {
            this.model = this.modelToPreview;
          }
          this.model.appSections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))

          this.sliderSection = this.model.appSections.filter(s => (s.originalName === 'Slider') && s.isEnabled);
          if (this.sliderSection && this.sliderSection.length > 0) {
            this.sliderInterval = this.sliderSection[0].interval;
            this.sliderSection[0].options.forEach(element => {
              this.slideElements.push(element.imgUrl)
            });
          }
          const newsSection = this.model.appSections.filter(s => (s.originalName === 'Blogs') && s.isEnabled);
          const newsSection2 = this.model.appSections.filter(s => s.originalName === 'News' && s.isEnabled);
      
          this.appStore = this.model.appSections.filter(s => s.originalName === 'App Store');
          if (newsSection || newsSection2) {
            if (newsSection.length > 0 || newsSection2.length > 0) {
              this.postsService.getPostsByShareType('Public')
                .valueChanges().pipe(
                  map(news => news.map((n: any) => ({ ...n, iframe: '' })))
                )
                .subscribe(n => {
                  if (n) {
                    this.postsArray = n;
                    this.postsArray.forEach(element => {
                      if (element.type === 'Video') {
                       // element.iframe = this.embedService.embed(element.videoLink);
                      }

                    });

                  }
                });
            } else {
              this.postsArray = this.emptyBlog;
            }
          } else {
            this.postsArray = this.emptyBlog;
          }

          const eventSection = this.model.appSections.filter(s => s.originalName === 'Events' && s.isEnabled);
          if (eventSection) {
            if (eventSection.length > 0) {
              this.newsEventsService.getPublicEventsList().valueChanges().subscribe(n => {
                if (n) {
                  if (n.length > 0) {
                    this.eventsArray = n;
                    this.eventsArray = this.getFutureEventList(n)
                  }
                }
              });
            } else {
              this.eventsArray = this.emptyEvent;
            }
          } else {
            this.eventsArray = this.emptyEvent;
          }

          const socialSection = this.model.appSections.filter(s => s.originalName === 'Social Media' && s.isEnabled);
          if (socialSection) {
            if (socialSection.length > 0) {
              this.socialService.getSocialMedia().valueChanges().subscribe(n => {
                if (n) {
                  if (n.length > 0) {
                    this.socialMediaModel = n[0];
                  }
                }
              });
            }
          }

          const serviceSection = this.model.appSections.filter(s => s.originalName === 'Services' && s.isEnabled);
          if (serviceSection) {
            if (serviceSection.length > 0) {
              this.employeeServicesService.getPublicServices().valueChanges().subscribe((ser) => {
                if (ser) {
                  if (ser.length > 0) {
                    this.servicesArray = ser;
                  }

                }
              });
            }
          }

          const productSection = this.model.appSections.filter(s => s.originalName === 'Products' && s.isEnabled);
          if (productSection) {
            if (productSection.length > 0) {
              this.productsService.getProductsList().valueChanges().subscribe((prod) => {
                if (prod) {
                  if (prod.length > 0) {
                    this.productsArray = prod;
                  }

                }
              });
            } 
          } 

          const blogSection = this.model.appSections.filter(s => s.originalName === 'Blogs' && s.isEnabled);
          if (blogSection) {
            if (blogSection.length > 0) {
              this.postsService.getPostsByShareType('Public').valueChanges().subscribe((blog) => {
                if (blog) {
                  if (blog.length > 0) {
                    this.postsArray = blog;
                  } else {
                    this.postsArray = this.emptyBlog;
                  }
                }
              });
            } else {
              this.postsArray = this.emptyBlog;
            }
          } else {
            this.postsArray = this.emptyBlog;
          }

          const welcomePopupSection = this.model.appSections.filter(s => s.originalName === 'Welcome Popup');
          if (welcomePopupSection) {
            if (welcomePopupSection.length > 0) {
              if (welcomePopupSection[0].isEnabled) {
                if (localStorage.getItem("welcomeCount")) {
                  localStorage.setItem('welcomeCount', parseInt(localStorage.getItem("welcomeCount")).toString());
                } else {
                  localStorage.setItem("welcomeCount", this.welcomeOpenedCount.toString());

                }
                this.welcomeOpenedCount = parseInt(localStorage.getItem('welcomeCount'));
                setTimeout(() => {

                  if (this.welcomeOpenedCount < 2) {
                    this.openSignupModal();
                  }

                }, welcomePopupSection[0].openingTime * 1000);
              }

            }

          }

          if (!welcomePopupSection) {
            // Open signup modal after 10 seconds
            setTimeout(() => {
              this.openSignupModal();
            }, 10000);
          }

          setTimeout(() => {
            // $('.slider').slider();

            this.showSlide(this.slideIndex);
            this.automaticSlide();

          }, 0);
         
    } else{
        this.appNotSet = true;
        this.router.navigate(['/login']);
    }

    this.settingsService.getLandingPageSettings().valueChanges().subscribe(landingPageMain => {
      if (landingPageMain.length > 0) {
        this.landingPageMain = landingPageMain[0];
      }
    });

    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });

    this.productsServiceMenuService.blogsName.subscribe(b => {
      this.blogsName = b;
    });

    this.productsServiceMenuService.eventsName.subscribe(e => {
      this.eventsName = e;
    });
    this.productsServiceMenuService.servicesName.subscribe(s => {
      this.servicesName = s;
    });

    this.productsServiceMenuService.productsName.subscribe(p => {
      this.productsName = p;
    })
    this.aboutService.getAbout().valueChanges().subscribe((aboutA) => {
      if (aboutA) {
        if (aboutA.length > 0) {
          this.about = aboutA[0];
        
        }

      }
    });




    if(!this.model && this.appNotSet){
    }





    // check if the app is installed
    this.checkIfAppIsInstalled()

    // this.startInterval();
  }
  /*
  startCarousel() {
    this.intervalId = setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.slides.length;
      // this.renderer.setProperty(
      //   this.el.nativeElement.querySelector('[data-te-target="#carouselExampleIndicators"][data-te-slide="next"]'),
      //   'click',
      //   {}
      // );
    }, 1000); // Adjust the interval as needed (e.g., 5000 milliseconds = 5 seconds)
  }*/

  nextSlide() {
    this.number = (this.number + 1) % this.slideElements.length;
    this.resetInterval();
  }

  prevSlide() {
    this.number = (this.number - 1 + this.slideElements.length) % this.slideElements.length;
    this.resetInterval();
  }
  startInterval() {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, this.sliderInterval);
  }

  resetInterval() {
    clearInterval(this.intervalId);
    this.startInterval();
  }

  startCarousel() {
    this.intervalId = setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.slides.length;
      const targetElement = this.el.nativeElement.querySelector('[data-te-target="#carouselExampleIndicators"][data-te-slide="next"]');
      if (targetElement) {
        this.renderer.setProperty(targetElement, 'click', {});
      }
    }, 1000); // Adjust the interval as needed (e.g., 5000 milliseconds = 5 seconds)
  }

  pauseCarousel() {
    clearInterval(this.intervalId);
  }

  resumeCarousel() {
    this.startCarousel();
  }
  ngOnChanges(changes: SimpleChanges) {
    // Handle changes to yourInputData here
    if (changes.elementId) {
      // Code to run when yourInputData changes
      this.getElement()
    }
  }

  // check if the device is ios or not to show the install app button and another instrauctions
  checkIfAppIsInstalled() {

    // detect if the device is on iOS
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isIosDevice = /iphone|ipad|ipod/.test(userAgent);

    // check if the app is in standalone format or if it has been installed
    let isStandalone = window.matchMedia('(display-mode: standalone)').matches || document.fullscreenElement !== null;


    if (!this.isIosDevice) {
      window.addEventListener('beforeinstallprompt', (event: Event) => {
        event.preventDefault();
        this.deferredPrompt = event;
        this.showButton = true;
      });

      const isPwaInstalled = window.localStorage.getItem('isPwaInstalled');

      if (!isPwaInstalled && this.deferredPrompt) {
        this.showButton = true;
      }

    }
    else {
      // the device is ios , so check if the app is standalone, if it is , the pwa has been installed
      if (!isStandalone) {
        this.showButton = true;
      }
    }

  }
  customSectionIcon(): number {
    if (this.iconPosition === 5) {
      this.iconPosition = 1;
      return this.iconPosition;
    }
    this.iconPosition++;
    return this.iconPosition;
  }

  // customSectionIcon(): number {
  //   this.iconPosition = (this.iconPosition % 4) + 1;
  //   return this.iconPosition;
  // }
  // to download the pwa, check if it has neem downloaded before
  downloadPWA() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          window.localStorage.setItem('isPwaInstalled', 'true');
          this.showButton = false;
        } else {
        }
        this.deferredPrompt = null;
      });
    }
  }
  getCardPosition(){
    let index = 1
    let length = 1
    this.model.appSections.forEach(section => {
      if(section.isCustomSection && section.isEnabled){
        this.customSectionCount++
      }
      if(section.originalName === 'Events' || ['Blogs', 'News'].includes(section.originalName) || section.originalName === 'Products'|| section.originalName === 'Services'|| section.isCustomSection || section.originalName === 'About Us' || section.originalName === 'Footer' || section.originalName === 'faq' || section.originalName === 'Login/Signup'){
        let existingIndex = this.cardElement.findIndex(element => element.originalName === section.originalName);
        if (existingIndex !== -1) {
          // Element already exists, update it
          if( section &&  section.cardDetail &&  section.cardDetail.position){
            this.cardElement[existingIndex] = {name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position}
          }else if(section.cardDetail && !section.cardDetail.position ){
            this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: index} 
            index += 1
            length +=1

          }else{
            section.cardDetail = {
              backgroundColor: this.model.defaultCardView ? this.model.defaultCardView.backgroundColor: this.model.themeColor? this.model.themeColor : this.envService.getColor('primary'),
              textColor: this.model.defaultCardView ? this.model.defaultCardView.textColor:  '#FFFFFF',
              borderRadius : this.model.defaultCardView ? this.model.defaultCardView.borderRadius :  12,
              boxWidth : this.model.defaultCardView ? this.model.defaultCardView.boxWidth:  160,
              boxHeight : this.model.defaultCardView ? this.model.defaultCardView.boxHeight:  160,
              backgroundImage :this.model.defaultCardView ? this.model.defaultCardView.backgroundImage:  '',
              position: length
            }
            this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: length}
            length +=1
          }

        } else {
          // Element doesn't exist, append it
        
          if(section.cardDetail && section.cardDetail.position){
            this.cardElement.push({name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position}) 
            length +=1
          }else if(section.cardDetail && section.cardDetail.position == 0){
            this.cardElement.push({name:section.name, originalName:section.originalName, section: section, position: 0});
            length +=1
           }else if(section.cardDetail){
            this.cardElement.push({name: section.name, originalName:section.originalName, section: section, position: index}) 
            index += 1
          }else{        
            section.cardDetail = {
              backgroundColor: this.model.defaultCardView ? this.model.defaultCardView.backgroundColor: this.model.themeColor? this.model.themeColor :  this.envService.getColor('primary'),
              textColor: this.model.defaultCardView ? this.model.defaultCardView.textColor:  '#FFFFFF',
              borderRadius : this.model.defaultCardView ? this.model.defaultCardView.borderRadius :  12,
              boxWidth : this.model.defaultCardView ? this.model.defaultCardView.boxWidth:  160,
              boxHeight : this.model.defaultCardView ? this.model.defaultCardView.boxHeight:  160,
              backgroundImage :this.model.defaultCardView ? this.model.defaultCardView.backgroundImage:  '',
              position: length
            }
            this.cardElement.push({name: section.name, originalName: section.originalName, section: section, position: length}) 
            length +=1 
          }
        }
  
      } 
    });
    this.cardElement = this.cardElement.slice().sort((a, b) => a.position - b.position)
  }

  sectionIsValid(section) {
    if (this.isNationApp) {
      return ['Products', 'Services'].includes(section.originalName) ? false : true
    }
    return true
  }

  public getvideoIframe(videoLink: any): any {
    //return this.embedService.embed_youtube(videoLink);
  }

  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }


  // set the default active slide to the first one

  // change slide with the prev/next button
  moveSlide(moveStep) {
    this.showSlide(this.slideIndex += moveStep);
  }
  getElement() {
    this.element = this.myElementRef.nativeElement;
  }

  // change slide with the dots
  currentSlide(n) {
    this.showSlide(this.slideIndex = n);
  }
  automaticSlide() {
    const interval = this.sliderInterval ? this.sliderInterval : 2000;

    // setTimeout(() => {
    //   this.moveSlide(1);
    //   this.automaticSlide();

    // }, interval);
  }
  showSlide(n) {
    // this.automaticSlide();
    let i;
    const slides = document.getElementsByClassName('slide');
    const dots = document.getElementsByClassName('dot');

    if (n > slides.length) { this.slideIndex = 1; }
    if (n < 1) { this.slideIndex = slides.length; }

    // hide all slides
    for (i = 0; i < slides.length; i++) {
      slides[i].classList.add('hidden');
    }

    // remove active status from all dots
    for (i = 0; i < dots.length; i++) {
      dots[i].classList.remove('bg-customTeal');
      dots[i].classList.add('bg-gray-200');
    }

    // show the active slide
    if (slides[this.slideIndex - 1]) {
      slides[this.slideIndex - 1].classList.remove('hidden');
    }
    // highlight the active dot
    if (dots[this.slideIndex - 1]) {
      dots[this.slideIndex - 1].classList.remove('bg-gray-200');
      dots[this.slideIndex - 1].classList.add('bg-customTeal');
    }
  }
  closeSignupModal() {
    this.signupModalOpen = false;
  }

  changeViewType(){
    if(this.landingPageView['web'] == 'card'){
      this.landingPageView['web']  = 'list'
      this.landingPageView['mobile']  = 'list'
    }else if(this.landingPageView['web']  = 'list'){
      this.landingPageView['web']  = 'card'
      this.landingPageView['mobile']  = 'card'
    }else if(this.landingPageView['mobile']  = 'list'){
      this.landingPageView['web']  = 'card'
      this.landingPageView['mobile']  = 'card'
    }else if(this.landingPageView['mobile']  = 'card'){
      this.landingPageView['mobile']  = 'list'
      this.landingPageView['web']  = 'list'

    }
  
  }

  openSignupModal() {
    // this.signupModalTimeout += 20000;
    this.welcomeOpenedCount = parseInt(localStorage.getItem("welcomeCount")) + 1;
    localStorage.setItem('welcomeCount', this.welcomeOpenedCount.toString());
    if(localStorage.getItem("signupModalOpened")=="true"){
      this.signupModalOpen = false;
    } else{
      this.signupModalOpen = true;
      localStorage.setItem("signupModalOpened", "true");
    }


    // setTimeout(() => {
    //   this.openSignupModal();
    // }, this.signupModalTimeout);
  }
  navigateToAnotherPage(route) {
    this.signupModalOpen = false;
    this.router.navigate([route]);
  }
  redirectToServices() {
    this.router.navigate(['/services']);
  }
  redirectToProduct() {
    this.router.navigate(['/products']);
  }
  formatURL(link) {
    if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
      return link;
    } else {
      return 'http://' + link;
    }
  }

  scrolltoservicetest() {
    this.viewportScroller.scrollToAnchor('services');
  }
  getInnerText(html) {
    return this.helperService.getInnerText(html)
  }

  getCustomSectionList(section) {

    // let allSectionValues;
    this.allSectionValues = [{ title: "TITLE", description: "DESCription" }, { title: "TITLE2", description: "DESCription" }, { title: "TITLE3", description: "DESCription" }]

    // this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe(sec => {
    //   this.section = sec[0];
    //   if(this.section){
    //   this.customSectionService.getAllElementsBySectionId(this.section.id).valueChanges().subscribe((sections: any) => {
    //     this.allSectionValues = sections;
    //   })
    // }
    // })

    return this.allSectionValues;

  }
  getSortedList(unsortedElements: Array<any>) {
    let sortedList = unsortedElements.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    return sortedList
  }
  getSortedEventsList(unsortedElements: Array<any>){
    
    let sortedList = unsortedElements.sort((a:any, b:any ) => {
      a.eventStartDate = new Date(a.eventStartDate)
      b.eventStartDate = new Date(b.eventStartDate)
      
        return a.position - b.position;
    });
    return sortedList
  }

  getFutureEventList(unsortedElements: Array<any>){
    // Filter out past events
    const futureEvents = unsortedElements.filter(event => event.eventStartDate >= this.currentDate);
    // Sort the remaining events by date
    futureEvents.sort((a, b) => a.eventStartDate.localeCompare(b.eventStartDate));
    return futureEvents;
  }

  navigateTo(section) {
      if (section.isCustomSection){
        this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe((sec: any) => {
          if(sec.length > 0){
            this.customSectionRef = sec[0];
            if(this.customSectionRef){
              this.router.navigate(['/custom-section-list/', sec[0].id]);
            }
          }

        });
  
      }else{
        if(section.originalName =='Blogs'){
        this.router.navigate(['/blogs']);
      }
      else if (section.originalName == 'Events') {
        this.router.navigate(['/events']);
      }
      else if (section.originalName == 'Products') {
        this.redirectToProduct()
      }
      else if (section.originalName == 'Services') {
        this.router.navigate(['/services']);
      }
      else if (section.originalName == 'About Us') {
        this.router.navigate(['/about']);
      }
      else if (section.originalName == 'Footer') {
        this.router.navigate(['/contact-us']);
      }
      else if(section.originalName =='First Nations Leaders'){
        this.router.navigate(['/nation-leaders']);
      }
      else if(section.originalName =='faq'){
        this.router.navigate(['/faq-list']);
      }
    }

  }

  getListElement(){
    let index = 0
    this.model.appSections.forEach(section => {
      // Append the element to the appendedArray
      if((section.originalName == 'Events' || section.originalName == 'Blogs'  || section.originalName == 'Products'|| section.originalName == 'Services'|| section.isCustomSection || section.originalName == 'About Us' || section.originalName == 'faq')  && section.isEnabled){
       let existingIndex = this.listElement.findIndex(element => element.section.originalName == section.originalName);
       if (existingIndex !== -1) {
        this.listElement[existingIndex] = {name: section.name,section: section, position: section.listIndex }

       }else{
        this.listElement.push({name: section.name,section: section, position: section.listIndex }) 
       }
      }
      if(section.originalName == 'About Us' ){
        this.aboutName = section.name
        this.aboutVisibility = section.isEnabled
      }
      if(section.originalName=='Footer' ){
        this.email =  section.contactEmail;
        this.contactVisibility = section.isEnabled
        this.phoneNumber = section.phone;
      }
    }
    )
    this.listElement = this.listElement.slice().sort((a, b) => a.position - b.position)
   
  }
  
  navigateDetails(product){
    this.router.navigate(['/view-product', product.id])
  }
  navigateDetailsService(service){
    this.router.navigate(['/view-service', service.id])
  }
  navigateDetailsEvent(product){
    this.router.navigate(['/view-product', product.id])
  }

  getItemNumber(section){
    let value;
    value = section.itemOnHomepage ? section.itemOnHomepage : 3


    return value
  }
  // validSection(section){
  //   let validSection = true
  //     if(section.name == "First Nations Leaders"){
  //       validSection = this.isChiefApp
  //     }
  //     else if(this.fromFNLeaders){
  //       validSection = !['Services','Products'].includes(section.originalName)
  //     }
  //   return validSection
  // } 

}
