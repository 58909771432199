// import { toast } from 'materialize-css';
import { Router } from '@angular/router';
import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { ChartOptions, ChartType } from 'chart.js';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { CountersService } from 'src/app/services/firebase/counters.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { Modal, Ripple, initTE } from "tw-elements";
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Subscription, switchMap } from 'rxjs';
import moment from 'moment';
import { RoutMetaService } from 'src/app/services/helper/rout-meta.service';
import { Store } from '@ngrx/store';
import { addUser,logout,fetchUserData } from '../../../state/app.actions';
import { selectAppSettings } from 'src/app/state/app.selectors';

import * as AppActions from 'src/app/state/app.actions';
@Component({
  selector: 'app-employee-appointment',
  templateUrl: './employee-home.component.html',
  styleUrls: ['./employee-home.component.css'], animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class EmployeeHomeComponent implements OnInit {
  appSettings:any
  pageSections: any
  section:any
  id: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  sliderRef: any;
  blogsRef: any;
  eventRef: any;
  productRef: any;
  aboutRef: any;
  serviceRef: any;
  appStoreRef: any;
  socialMediaRef: any;
  footerRef: any;
  welcomeRef: any;
  model: any[];
  colors=['background: linear-gradient(90deg, #85D8CE 0%, #3094ca 100%);', 'background: linear-gradient(90deg, #FFEDBC  0%, #ED4264 100%);','background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);', 'background: linear-gradient(90deg, #ff8bb0 0%, #6ddaff 100%);', 'background: linear-gradient(90deg, #24C6DC 40%, #8d83ff 100%);', 'background: linear-gradient(90deg, #1CD8D2 0%, #93EDC7 100%);', 'background: linear-gradient(90deg, #6e9aff 0%, #a977fa 100%);', 'background: linear-gradient(90deg, #2ceba8 0%, #a8fac6 100%);', 'background: linear-gradient(90deg, #8bd4ff 0%, #6dffc0 100%);', 'background: linear-gradient(90deg, #22c1c3 0%, #fdbb2d 100%);',  'background: linear-gradient(90deg, #eb8b8b 0%, #febd63 100%);', 'background: linear-gradient(90deg, #ba7ee1 0%, #9dce78 100%);', 'background: linear-gradient(90deg, #f99bff 0%, #32afff 100%);', 'background: linear-gradient(90deg, #85D8CE 0%, #3094ca 100%);', 'background: linear-gradient(90deg, #FFEDBC  0%, #ED4264 100%);','background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);']
  currentUser: any;

  role: any;
  viewType;
  customSection: unknown;
  allSections: any;
  serviceType: any;

  themeColor: any;
  defaultThemeColor =  this.envService.getColor('primary');
  noCount : boolean = false;
  counts: any;
  doughnutChartLabels: any[]
  doughnutChartData: any = [];
  doughnutChartType: ChartType = 'doughnut';
  doughnutChartColors: any[];
  public doughnutChartLegend = true;
  public doughnutChartPlugins = [];
  public doughnutChartOptions: ChartOptions = {responsive: true,};
  SMSCounted: number = 0;
  EmailCounted: number = 0;
  ChatGptCounted: number = 0;
  SocialMediaCounted: number = 0;
  
  fromFNLeaders = this.envService.fromFNLeaders
  filteredActiveAppointments = []
  tabStatus = 'registration'
  verifyUsers: boolean;
  totalSubLength = 0;
  totalEmpLength = 0;
  totalReqLength = 0;
  notes: any;
  noteModal
  userNotes
  showHiddenSections = true;
  isFirstSetup = false;

  perm: any;
  permMember: any;
  permPES: any;
  permSocial: any;
  permAnalytics: any;
  permissionSpecial: any;
  permCustomPage: any;

  deleteCustomPageModalOpen = false;
  permitted = [];
  empId: string;
  appointmentsList: any = [];
  beforeTimeline = [];
  activeAppointments= [];
  activeAppointmentsCopy= [];
  tasks=[];
  totalAppointment: number;

  allAppointment = [];
  EmpToAppointment = [];
  userToAppointment = [];
  appointments: any;
  todayAppointment: number = 0;
  upcomingAppointment: number = 0;
  upcomingAppointments = []; //an array of appointments
  date: any = moment(); // this is the current date

  metaTagSettings: any;
  // loggedInUser$ = this.store.select('loggedInUser');
  appSettings$: Subscription;
    constructor( private router: Router,
                private settingsService: AppSettingsService, 
                private authService: AuthService, 
                private customSectionService: CustomSectionService,
                private envService: EnvironmentService,
                private counterService: CountersService,
                private permissionService: PermissionService,
                private fireMemberService: FireMembersService,
                private helperServices: HelperService,
                private appSettingsService: AppSettingsService,
                private routMeta: RoutMetaService,
                private store: Store) { 
          this.getUser();
          // this.currentUser = this.authService.getGlobalUser();
          // this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
          //   this.appSettings = helperServices.cloneDeep(settings);
          //   this.pageSections = helperServices.cloneDeep(settings);
          // });
          this.currentUser = this.authService.getCurrentUser();
    // this.getSettings();
  }

  ngOnInit(): void {
    // this.store.dispatch(addUser())
    initTE({ Modal, Ripple });
    if (!this.pageSections) {
      this.pageSections = GlobalConstants.EmptyPageSections;
      this.pageSections.appSections = GlobalConstants.AppSections;
  }

  const btn4 = document.getElementById('close');
  this.router.events.subscribe((val) =>{
    if(btn4){
      btn4.click()
    }
  })
  
  this.getMetaTagSettings();

  this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
    if (perm && perm.length != 0) {
      this.perm = perm[0];
      [this.permMember, this.permPES, this.permAnalytics, this.permSocial, this.permissionSpecial, ,this.permCustomPage ] = this.perm.permission;
    }
  });

  if(this.currentUser){
    this.role = this.currentUser.role;
  }
  this.settingsService.getAppSettingsList().valueChanges().subscribe(
    appSettings => {
      if (appSettings) {
        if (appSettings.length > 0) {
          this.appSettings = appSettings[0];
          this.pageSections = appSettings[0];
          if (this.appSettings && this.appSettings.appSections) {
            this.serviceType = this.appSettings.serviceType;
              this.viewType = this.appSettings.viewType ? this.appSettings.viewType : 'list';
              this.showHiddenSections = this.appSettings.showHiddenSections;
              this.serviceType =  this.appSettings.serviceType;
      
              this.appSettings.appSections.forEach((section:any) => {
                this.section = section
      
              });
          } else{
            this.isFirstSetup = true;
            this.appSettings = this.pageSections;
      
            this.serviceType = this.appSettings.serviceType;
            this.viewType = this.appSettings.viewType ? this.appSettings.viewType : 'list';
            this.showHiddenSections = this.appSettings.showHiddenSections;  
            this.serviceType =  this.appSettings.serviceType;
      
            if(this.appSettings.appSections){
              this.appSettings.appSections.forEach((section:any) => {
                this.section = section
              });
            }
          }
        }}});
    

  this.counterService.getCounter().valueChanges().subscribe((count)=>{
    if(count && count.length != 0){
      this.noCount = false;
      this.counts = count[0];
      this.SMSCounted = this.counts.SMSTotalCounter ? this.counts.SMSTotalCounter : 0;
      this.EmailCounted = this.counts.emailTotalCounter ? this.counts.emailTotalCounter : 0;
      this.ChatGptCounted = this.counts.chatGptCounter ? this.counts.chatGptCounter : 0;
      this.SocialMediaCounted = this.counts.socialMediaCount ? this.counts.socialMediaCount : 0;
      this.counterChart();

    }else{
      this.noCount = true;
          this.SMSCounted = 0;
          this.EmailCounted = 0;
          this.ChatGptCounted = 0;
          this.SocialMediaCounted = 0;
    }
  })

  }

  getSettings() {
    this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
      if (perm && perm.length != 0) {
        this.perm = perm[0];
        [this.permMember, this.permPES, this.permAnalytics, this.permSocial, this.permissionSpecial] = this.perm.permission;
        if (this.pageSections) {
          for(let section in this.pageSections.appSections){
            if(this.permissionSpecial.aboutUsPageSetup ){
              this.permitted.push(section);
            }else if(this.permissionSpecial.landingPageSetup){
              this.permitted.push(section);
            }

          }
          // this.backgroundColor = this.pageSections.themeColor;
          this.sliderRef = this.pageSections.appSections.filter(s => s.name === 'Slider')[0];
          this.blogsRef = this.pageSections.appSections.filter(s => s.originalName === 'Blogs')[0]; 
          this.eventRef = this.pageSections.appSections.filter(s => s.originalName === 'Events')[0]; 
          this.productRef = this.pageSections.appSections.filter(s => s.originalName === 'Products')[0]; 
          this.aboutRef = this.pageSections.appSections.filter(s => s.originalName === 'About Us')[0]; 
          this.serviceRef = this.pageSections.appSections.filter(s => s.originalName === 'Services')[0]; 
          this.appStoreRef = this.pageSections.appSections.filter(s => s.name === 'AppStore')[0]; 
          this.socialMediaRef = this.pageSections.appSections.filter(s => s.name === 'SocialMedia')[0]; 
          this.footerRef = this.pageSections.appSections.filter(s => s.originalName === 'Footer')[0]; 
          this.welcomeRef = this.pageSections.appSections.filter(s => s.name === 'WelcomePopup')[0];
        }
      }
    });

    this.settingsService.getLandingPageSettings().valueChanges().subscribe(
      lpSettings => {
        if (lpSettings) {
          if (lpSettings.length > 0) {
            this.model = lpSettings[0]; 
          }

        } 
      }
    );

    // this.getCardDetail()

  }

  checkedChange(section: any) {
    section.isEnabled = !section.isEnabled;
    
    if(this.isFirstSetup){
      this.settingsService.saveAppSettings(this.pageSections).then(() => {
        this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
      });
    }
    else {
      this.settingsService.updateAppSettings(this.pageSections).then(() => {
          this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
        });
    }
  }



  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  navigateTo(name) {
    if(name == 'Welcome Popup'){
      this.router.navigate(['/employee/welcome-setting']);
    }
    if(name == 'Slider'){
      this.router.navigate(['/employee/slider-setting']);
    }
    if(name == 'Footer'){
      this.router.navigate(['/employee/footer-setting']);
    }
    if(name == 'Social Media'){
      this.router.navigate(['/employee/contact-setting']);
    }
    else if(name =='Blogs' || name=='Blog'){
    this.router.navigate(['/employee/blogs']);
    }
    else if(name =='Events'){
    this.router.navigate(['/employee/events']);
    }
    else if(name =='Products'){
      this.router.navigate(['/employee/view-products']);
    }
    else if(name =='Services'){
      this.router.navigate(['/employee/services']);
    }
    else if(name =='About Us'){
      this.router.navigate(['/employee/about']);
    }
    else if(name =='Contact Us'){
      this.router.navigate(['/employee/contact-messages']);
    }
    else if(name =='faq'){
      this.router.navigate(['/employee/faq-list']);
    }
    }

    navigateToAppSetup(){
      this.router.navigate(['/employee/app-setup'])
    }

    customSectionRoute(section){
      this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe((sec : any) => {
        this.customSection = sec;  
        if(this.customSection){
          this.router.navigate(['/employee/custom-section-list', this.customSection[0].id])
       }      
      })
    }

    routeToElement(){
      if(this.customSection){
        this.router.navigate(['/employee/custom-section-list', this.customSection[0].id])
     }
    }

    newSection(){
      if(this.currentUser.role == 'Admin'){
        this.router.navigate(['/admin/create-new-section'])
      }else {
        this.router.navigate(['/employee/create-new-section'])
  
      }
    }

    manageApp(){
      if(this.currentUser.role == 'Admin'){
        this.router.navigate(['/admin/landing-page-settings'])
      }else {
        this.router.navigate(['/employee/landing-page-settings'])
  
      }
      
    }

    changeView(view: string){
      this.viewType = view
      this.pageSections.viewType = view
      this.settingsService.updateAppSettings(this.pageSections).then(() => {
        this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
        this.router.navigate(['/employee/landing-page-settings'])
      });
    }
    toggleSectionView(sec){
      this.showHiddenSections = sec;
      this.pageSections.showHiddenSections = sec;
      if(this.isFirstSetup){
        this.settingsService.saveAppSettings(this.pageSections)
      }
      else {
        this.settingsService.updateAppSettings(this.pageSections)
      }
    }
    checkProductAndServiceVisibility(sectionName){
      if(sectionName=='Services'){
        if(this.serviceType=='Both' || this.serviceType=='Services'){
          return true
        }
        return false;
      } else if (sectionName=='Products'){
        if(this.serviceType=='Both' || this.serviceType=='Products'){
          return true
        }
        return false;
      }else {
        return true;
      }
    }
    counterChart() {
      this.doughnutChartData = [[this.SMSCounted,this.EmailCounted,this.SocialMediaCounted,this.ChatGptCounted]]; 
      this.doughnutChartColors= [ { backgroundColor: [this.themeColor, '#58c4e8','#9fc543' ,'#e063d0'] } ];
      this.doughnutChartOptions= { 
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'right'
          },
          datalabels: {
            display: true,
            color: '#000000',
            font: {
              weight: 'bold'
            },
            formatter: (value, context) => {
              return context.chart.data.labels[context.dataIndex] + ': ' + value;
            }
          }
        }
      };
      this.doughnutChartLabels = [this.SMSCounted+' SMS' +" "+ (100 - this.SMSCounted)+' Left',
       this.EmailCounted+' Email' +" "+ (100 - this.EmailCounted)+' Left',
        this.SocialMediaCounted+' Social Media' +" "+ (100 - this.SocialMediaCounted)+' Left',
         this.ChatGptCounted+' ChatGpt' +" "+ (100 - this.ChatGptCounted)+' Left'];
      }

  tabStatusSet(status: string){
    this.tabStatus = status
    
  }
  addNote(){
    this.noteModal = true;
  }
  closeNote(){
    this.noteModal = false;
  }
  getUser() {
    this.id = this.authService.getCurrentUser().uid;
    this.fireMemberService.getMemberByID(this.id).valueChanges().subscribe(user => {
      if (user) {
        if (user.role === 'Employee') {
          this.currentUser = user;
          this.getSettings();
        }
      }
    });
  }
  openDeleteCustomPageModal(section){
    this.customSection = section;
    this.deleteCustomPageModalOpen = true
  }
  deleteCustomSection(customSection){
    this.pageSections.appSections = this.pageSections.appSections.filter((a) => {
      return a.originalName!=customSection.originalName}
      );
    this.settingsService.updateAppSettings(this.pageSections)

    this.deleteCustomPageModalOpen = false;
    this.customSectionService.getCustomSectionBySectionTitle(customSection?.originalName).valueChanges().subscribe((sec : any) => {
      if(sec.length> 0){
        this.customSection = sec[0];  
          this.customSectionService.deleteCustomSection(this.customSection).then(()=>{
          this.toast({ html: 'Page Successfully Deleted!!', classes: 'red', type: 'failure'});
        
        })
      }

  
         
    })

  }
  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
   }

   getMetaTagSettings() {
    this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
        this.routMeta.updateTitle(metaTags[0]?.appTitle);
        // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(metaTags[0]?.description);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(metaTags[0]?.keyWords ? metaTags[0]?.keyWords : "");
  
      }
    });
  }
}
