<div class="flex flex-col px-8 items-center space-y-2 md:w-full ">

  <div class="flex justify-between w-full">
    <img loading="lazy" [routerLink]="['/']" *ngIf="!appLogo" [src]="defaultLogo"
      class="block w-32 justify-center cursor-pointer" alt="Logo ">
    <img loading="lazy" [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="block w-32 justify-center cursor-pointer "
      alt="Logo ">
  </div>

  <div class="font-bold text-center text-customTeal text-lg">Forgot Password?</div>
  <div class="text-center text-gray-400 text-sm space-y-6 mx-4 md:mx-[33%]"> Please enter the email address you used when you
    joined and we'll send you instructions to reset your password.
  </div>
  <div class="flex justify-center pt-2 md:pt-6">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
      <div class="flex flex-col space-y-4 mb-4 lg:w-96 mx-auto">
        <label for=" " class="text-sm font-bold "> Email</label>
        <input type="email" formControlName='email' required autocomplete="off"
          class="focus:invalid:border-red-500 validate border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
          id="email " type="text " placeholder="Email ">
        <div *ngIf="(email.dirty || email.touched) && email.invalid">
          <small *ngIf="email.errors.required" class="text-red-500"> Email is required. </small>
          <small *ngIf="email.errors.email" class="text-red-500"> wrong email format. </small>
        </div>
        
        <button [disabled]="!resetPasswordForm.valid"
          class="px-6 py-2 border-2 rounded-lg bg-customTeal text-white focus:outline-none ">
          Reset Password
        </button>
      </div>
    </form>
  </div>
  <p class="text-center">Back to <span class="text-customTeal font-bold"><a
    [routerLink]= "['/auth/login']">Login</a></span></p>
    <br /> <br/>
</div>

<!-- <div class="alert alert-success shadow-lg fixed top-0 right-0 md:z-50"
[ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success': toastType=='success', 'alert-error': toastType=='failure' }"
>
  <div class="text-white font-bold">
    <span class="material-icons">check_circle</span>
    <span>{{toastMessage}}</span>
  </div>
</div> -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- 
<div class="row">
  <div class="col m1 l2 xl3"></div>
  <div class="col s12 m10 l8 xl6">
    <div class="card">
      <div class="card-content">
        <span class="card-title center">
          <h5>Reset Password</h5>
        </span>
        <form class="container" (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
          <div class="row">


            <div class="row">
              <div class="section"></div>
              <p>
                Please enter your email and Follow the link we will send to your inbox to reset your account password.
              </p>
              <div class="section"></div>
              <div class="input-field col s12">
                <input id="email" type="email" required formControlName='email' class="validate">
                <label for="email">Email</label>
                <div *ngIf="(email.dirty || email.touched) && email.invalid">
                  <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
                  <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
                </div>
              </div>
            </div>



            <div class="row">
              <div class="input-field col s12 center">
                <button type='submit' [disabled]="!resetPasswordForm.valid" name='btn_login'
                  class='col s12 btn btn-large waves-effect indigo'> <i class="material-icons left">compare_arrows</i>
                  Reset Password</button>
              </div>
            </div>
          </div>
        </form>


      </div>

    </div>
  </div>
  <div class="col m1 l2 xl3"></div>
</div> -->