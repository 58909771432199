<div class="flex shadow-lg rounded-[5px] max-w-full sm:max-w-[382px] min-w-[200px] px-2  bg-white">

  <div class="flex flex-col space-y-2 py-3 w-full ">
    <div class="flex gap-2 items-center">
      <!-- success -->
      <svg xmlns="http://www.w3.org/2000/svg" *ngIf="toastType=='success'" viewBox="0 0 24 24"
        class="w-6 h-6 fill-green-600">
        <path fill-rule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
          clip-rule="evenodd" />
      </svg>

      <!--  -->
      <div class="text-yellow-500 font-bold items-center" *ngIf="toastType=='warning'"><i
          class="material-icons text-[24px]">warning</i></div>
      <div class="text-blue-500 font-bold items-center" *ngIf="toastType=='information'"><i
          class="material-icons text-[24px]">info</i>
      </div>
      <div class="text-red-500 w-full h-max font-bold items-center" *ngIf="toastType=='failure'"><i
          class="material-icons text-[24px]">dangerous</i></div>
      <div class="text-red-500  font-bold items-center" *ngIf="toastType=='error'"><i
          class="material-icons ">dangerous</i></div>
      <div class="text-red-500 font-bold items-center" *ngIf="toastType=='declined'"><i
          class="material-icons text-[24px]">dangerous</i></div>
      <div class="text-red-500  font-bold items-center" *ngIf="toastType=='disable'"><i
          class="material-icons text-[24px]">dangerous</i> </div>

      <p class="text-base flex-1">{{toastMessage}}</p>

    </div>

  </div>

  <!-- <div class="flex justify-between mr-10 pt-4"><p></p><i class="material-icons w-8 text-3xl">close</i></div> -->

</div>