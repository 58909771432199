<div class="flex flex-col px-8 items-center space-y-2 md:w-full ">

    <!-- <div class="flex justify-between w-full">
      <img [routerLink]="['/']" *ngIf="!appLogo" src="../../../../assets/img/Going Mobile - 3.webp"
        class="block w-32 justify-center cursor-pointer" alt="Logo ">
      <img [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="block w-32 justify-center cursor-pointer "
        alt="Logo ">
    </div> -->
  
    <div class="font-bold text-center text-customTeal text-xl">Reset Password</div>
    <div class="text-center text-gray-400 text-md space-y-6 mx-4 md:mx-[33%]"> Please enter the New password and confirm the password.
    </div>
    <div class="flex justify-center pt-2 md:pt-6">
      <!-- <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
        <div class="flex flex-col space-y-4 mb-4 lg:w-96 mx-auto">
          <label for=" " class="text-sm font-bold "> Email</label>
          <input type="email" formControlName='email' required autocomplete="off"
            class="focus:invalid:border-red-500 validate border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
            id="email " type="text " placeholder="Email ">
          <div *ngIf="(email.dirty || email.touched) && email.invalid">
            <small *ngIf="email.errors.required" class="text-red-500"> Email is required. </small>
            <small *ngIf="email.errors.email" class="text-red-500"> wrong email format. </small>
          </div>
          
          <button [disabled]="!resetPasswordForm.valid"
            class="px-6 py-2 border-2 rounded-lg bg-customTeal text-white focus:outline-none ">
            Reset Password
          </button>
        </div>
      </form> -->

      <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword(resetPasswordForm.value)">
        <div class="flex justify-center flex-wrap mt-3 mx-14">

          <div class="flex space-y-2 md:w-full flex-col ">
            <label for=" " class="text-xs text-gray-500 font-bold "> New Password <span class="text-red-500">*</span></label>
            <div class="flex flex-row w-full">

              <input #passwordfield2 id="newPassword" type="password" formControlName='newPassword' required
                autocomplete="off"
                class="focus:invalid:border-red-500 border rounded py-3 px-3 my-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-full"
                placeholder="New Password" [ngClass]="{
                  'border-red-500': ((newPassword.dirty || newPassword.touched) && newPassword.invalid),
                  'border-black': !((newPassword.dirty || newPassword.touched) && newPassword.invalid)
                }">
              <span (click)="passwordfield2.type=passwordfield2.type=='password'?'text':'password'"
                class="justify-center w-1/12 fa fa-fw field-icon toggle-password bg-white pt-3 border border-l-0 p-2"
                [ngClass]="(passwordfield2.type=='password')?' fa-eye-slash':' fa-eye'">
              </span>
            </div>

            <div *ngIf="(newPassword.dirty || newPassword.touched) && newPassword.invalid">
              <small *ngIf="newPassword.errors.required" class="text-error"> New Password Is Required.
              </small>
              <small *ngIf="newPassword.errors.minlength" class="text-error"> Password Length Must Be At Least 6
                Characters . </small>
              <small *ngIf="newPassword.errors.maxlength" class="text-error"> Password Must Not Exceed 25
                Characters . </small>
            </div>
          </div>

          <div class="flex space-y-2 md:w-full flex-col ">
            <label for=" " class="text-xs text-gray-500 font-bold "> Confirm Password <span class="text-red-500">*</span></label>
            <div class="flex flex-row w-full">

            <input #passwordfield3 id="Password" type="password" formControlName='confirmPassword' required autocomplete="off"
              class="focus:invalid:border-red-500 border rounded py-2 px-3 my-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-full"
              id="Password "[ngClass]="{
                'border-red-500': ((confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid),
                'border-black': !((confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid)
              }" placeholder="Confirm Password ">

              <span (click)="passwordfield3.type=passwordfield3.type=='password'?'text':'password'"
              class="justify-center w-1/12 fa fa-fw field-icon toggle-password bg-white pt-2 border border-l-0 p-2"
              [ngClass]="(passwordfield3.type=='password')?' fa-eye-slash':' fa-eye'">
            </span>

              </div>

            <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
              <small *ngIf="confirmPassword.errors.required" class="text-error">Confirm Password is required. </small>
              <small *ngIf="confirmPassword.hasError('invalid')" class="text-error">Passwords Doesnt Match ! </small>
            </div>
          </div>
        </div>

        <div class="flex justify-between mx-8 my-2 ">
            <p class="text-center">Back to <span class="text-customTeal font-bold"><a
                [routerLink]= "['/auth/login']">Login</a></span></p>
            <!-- <label ><button type="button" (click)="cancel()"
                class="py-2 px-4 rounded-md text-red-500 border-none">Cancel</button></label> -->

          <label ><button type="submit"
              class="py-2 px-4 rounded-md text-white bg-customTeal border-none">Reset</button></label>

        </div>

    </form>








    </div>
    <!-- <p class="text-center">Back to <span class="text-customTeal font-bold"><a
      [routerLink]= "['/auth/login']">Login</a></span></p>
      <br /> <br/> -->
  </div>
  
  <!-- <div class="alert alert-success shadow-lg fixed top-0 right-0 md:z-50"
  [ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success': toastType=='success', 'alert-error': toastType=='failure' }"
  >
    <div class="text-white font-bold">
      <span class="material-icons">check_circle</span>
      <span>{{toastMessage}}</span>
    </div>
  </div> -->
  <app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
   [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
  
  