import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NewsEventService } from './../../../../services/firebase/news-event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location} from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { isBefore , isSameDay, isAfter} from 'date-fns';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { HelperService } from 'src/app/services/helper/helper';


@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent implements OnInit {
  editEventForm: UntypedFormGroup;
  mediaType: any;
  currentUser: any;
  model: any;
  binDoc: File;
  base64Image: any;
  showLogoInput = true;
  pdfErrorMsg: any;
  imgErrorMsg: any;
  shareVisibility: any;
  trackEventStartDate: any;
  eventId: any;
  event: any;
  imgLinkT = '';
  isMobile = false;
  mobileBase64Image: any;
  pricing= '';
  eventLinkClicked = false;
  youtubeLinkClicked = false;
  imageRemoved = false;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';
  timePattern = '((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))';
  numberPattern = /^[-+]?(\d*\.\d+|\d+\.|\.\d+|\d+)([eE][-+]?\d+)?$/;
  // modal
  deleteModalOpen = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  step = 1;
  isAdmin: boolean = false;
  isUser: boolean = false;
  perm: any;
  permission: any;
  cancelModalOpen = false;
  eventsName = 'Events';
  preview = false;
  modelToPreview: any;
  haveAccessPermission = false;
  editModalOpen = false;
  cropModalOpen = false;
  imageChangedEvent: any = '';
  imgURL: any;
  times = [ '12:00 AM','12:30 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM', '4:00 AM',
            '4:30 AM', '5:00 AM',  '5:30 AM', '6:00 AM','6:30 AM', '7:00 AM',  '7:30 AM', '8:00 AM','8:30 AM', '9:00 AM',
            '9:30 AM', '10:00 AM','10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM','12:30 PM','1:00 PM',
            '1:30 PM', '2:00 PM','2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM','4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM','6:30 PM', '7:00 PM',
            '7:30 PM', '8:00 PM','8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM','10:30 PM', '11:00 PM',  '11:30 PM']
  timesEnd = [];
  startTime = '3:00 AM';
  hasPrice = false;

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  useBase64=false

  uploadFileModalOpen = false;
  attachedVideo: File;
  pdfAttached: File;
  mediaList = [];  
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };

  receivedLong: any;
  receivedLat: any;
  receivedAddress: any;

  constructor(private fb: FormBuilder,
              private newsEventService: NewsEventService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private location: Location,
              private router: Router,
              private permissionService: PermissionService,
              private productsServiceMenuService: ProductsServiceMenuService,
              private helperService: HelperService
              ) {
                // if (typeof window['cordova'] !== 'undefined') {

                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
                  this.isMobile = true;
                }
              }

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if(this.currentUser.role == 'Admin'){
        this.haveAccessPermission = true;
        this.isAdmin = true;
      }
      if(this.currentUser.role == 'Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
          if(perm.length != 0){
            this.perm = perm[0];
            //this.permission = this.perm.permission[0];

            this.perm = perm[0];
            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];


            if (this.permPES.editEvents){
              this.haveAccessPermission = true;
            }
          }
        });
      }
      if(this.currentUser.role == 'User'){
      this.isUser = true;
      }
    }

    this.currentUser = this.authService.getGlobalUser();
    this.route.paramMap.subscribe(params => {
      this.eventId = params.get('id');
    });

    this. editEventForm = this.fb.group({
      eventTitle: ['', [Validators.required]],
      titleColor: ['#000000'],
      isOnline:[false],
      eventPlace: [''],
      eventLink: [''],
      eventStartDate: ['', [Validators.required, validateStartDate]],
      eventEndDate: ['', [validateStartAndEndDate]],
      eventStartTime: ['3:00 AM', Validators.pattern(this.timePattern)],
      eventEndTime: ['3:30 AM', Validators.pattern(this.timePattern)],
      description: ['', Validators.required],
      backgroundColor: ['#ffffff'],
      imgLink: [''],
      videoLink: [''],
      youtubeUrl: [''],
      pdfLink: [''],
      shareToFriends: [true],
      hasPrice: [false],
      sharedLink: ['', [ Validators.pattern(this.urlPattern)]],
      shareWith: ['User'],
      price: [''],
      registerRequired:[false],
      maxRegistration:[""],
      commentable: [true],
      opened: [''],
      latitude:[""],
      longitude:[""],
      // price: ['', [ Validators.pattern(this.numberPattern)]]
    });

    this.newsEventService.getEventById(this.eventId).valueChanges().subscribe(event => {
      if (event.length > 0){
        this.event = event[0];
        this.trackEventStartDate = this.event.eventStartDate;

        this.hasPrice = this.event.price ? true : false
        this.editEventForm.patchValue({
          eventTitle: this.event.eventTitle,
          titleColor: this.event.titleColor,
          eventPlace: this.event.eventPlace,
          eventLink: this.event.eventLink,
          eventStartDate: this.event.eventStartDate,
          eventEndDate: this.event.eventEndDate,
          eventStartTime: this.event.eventStartTime ? this.event.eventStartTime : '',
          eventEndTime: this.event.eventEndTime ? this.event.eventEndTime : '',
          description: this.event.description,
          backgroundColor: this.event.backgroundColor,
          isOnline: this.event.isOnline ? this.event.isOnline: '',
          imgLink: this.event.imgLink,
          videoLink: this.event.videoLink ? this.event.videoLink: '',
          pdfLink: this.event.pdfLink ? this.event.pdfLink: '',
          shareToFriends: this.event.shareToFriends,
          shareWith: this.event.shareWith,
          sharedLink: this.event.sharedLink,
          price: this.event.price ? this.event.price : '',
          registerRequired:this.event.registerRequired ? this.event.registerRequired : '',
          maxRegistration:this.event.maxRegistration ? this.event.maxRegistration : '',
          hasPrice: this.event.price ? true : false,
          youtubeUrl: this.event.youtubeUrl ? this.event.youtubeUrl : '',
          commentable: this.event.commentable ? this.event.commentable : true,
          opened: this.event.opened? this.event.opened : [],
          latitude: this.event.latitude ? this.event.latitude : '',
          longitude : this.event.longitude ? this.event.longitude : '',
          
        });
      }
    });

    const tomorrow = new Date();
    const minEndDate = new Date();
    const eventStartDate = new Date(this.trackEventStartDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    minEndDate.setDate(eventStartDate.getDate() + 1);

    this.productsServiceMenuService.eventsName.subscribe(e => {
      this.eventsName = e;
    });
  }

  dateChangeEventStartDate() {
    this.trackEventStartDate = $('#eventStartDate').val();
    if (this. editEventForm) {
      this. editEventForm.patchValue({ eventStartDate: $('#eventStartDate').val() }); 
    }
    const eventStartDate = new Date(this.trackEventStartDate);
    const minEndDate = new Date();
    minEndDate.setDate(eventStartDate.getDate() + 1);
    this.editEventForm.patchValue({eventEndDate: this.eventStartDate.value});
  }

  get eventTitle(){ return this. editEventForm.get('eventTitle'); }
  get sharedLink(){ return this. editEventForm.get('sharedLink'); }
  get description(){ return this. editEventForm.get('description'); }
  get imgLink(){ return this. editEventForm.get('imgLink'); }
  get pdfLink(){ return this. editEventForm.get('pdfLink'); }
  get eventPlace(){ return this. editEventForm.get('eventPlace'); }
  get eventLink(){ return this. editEventForm.get('eventLink'); }
  get eventStartDate() { return this.editEventForm.get('eventStartDate'); }
  get eventEndDate() { return this.editEventForm.get('eventEndDate'); }
  get shareWith() { return this.editEventForm.get('shareWith'); }
  get isOnline(){ return this.editEventForm.get('isOnline'); }
  get price() { return this.editEventForm.get('price'); }
  get maxRegistration() { return this.editEventForm.get('maxRegistration'); }
  get registerRequired() { return this.editEventForm.get('registerRequired'); }

  get eventStartTime() { return this.editEventForm.get('eventStartTime'); }
  get eventEndTime() { return this.editEventForm.get('eventEndTime'); }
  get youtubeUrl() { return this.editEventForm.get('youtubeUrl'); }
  get opened() { return this.editEventForm.get('opened'); }
  get latitude() {
    return this.editEventForm.get("latitude");
  }
  get longitude() {
    return this.editEventForm.get("longitude");
  }


  updateEvent(value: any){
    this.editModalOpen = false;
    this.event.eventTitle = value.eventTitle;
    this.event.titleColor = value.titleColor;
    this.event.eventPlace = value.eventPlace;
    this.event.eventLink = value.eventLink ? value.eventLink: '';
    this.event.eventStartDate = value.eventStartDate;
    this.event.eventEndDate = value.eventEndDate;
    this.event.eventStartTime = value.eventStartTime;
    this.event.eventEndTime = value.eventEndTime;
    this.event.description = value.description;
    this.event.backgroundColor = value.backgroundColor;
    this.event.imgLink = value.imgLink;
    this.event.videoLink = value.videoLink;
    this.event.pdfLink = value.pdfLink;
    this.event.sharedLink = value.sharedLink;
    this.event.shareToFriends = value.shareToFriends;
    this.event.shareWith = value.shareWith;
    this.event.price = value.price;
    this.event.maxRegistration = value.maxRegistration ? value.maxRegistration : '';
    this.event.registerRequired = value.registerRequired ? value.registerRequired : '';
    this.event.isOnline = value.isOnline;
    this.event.youtubeUrl = this.getUrl(value.youtubeUrl);
    this.event.commentable = value.commentable;
    this.event.opened = this.event.opened ? this.event.opened : [];
    this.event.longitude = this.event.longitude ? this.event.longitude : '';
    this.event.latitude = this.event.latitude ? this.event.latitude : '';
    this.event.reactions = this.event.reactions ? this.event.reactions: {    likes: {
      count: 0,
      userId: [],
      usernames: []
    }}
    this.event.comments = this.event.comments ? this.event.comments : []
    if(this.pricing == 'paid'){
      this.event.price = value.price
    }else if(this.pricing == 'free'){
      this.event.price = 'free'
    }else{
      this.event.price = 'dontInclude'
    }
    if(this.mediaList.length > 0){
      this.uploadFileModalOpen = true;
  }

  if(this.mediaList.length === 0){
    this.saveEventUpdate(this.event);
  }

  }

  saveEventUpdate(eventDetail){
    this.newsEventService.editEvent(eventDetail).then(() => {
      this.toast({html: 'Successfully  updated an event!', classes: 'green', type: 'success'});
    }).catch((err) => {
        this.toast({html: err, classes: 'red', type: 'failure'});
    });
  }

  backClicked(){
    this.location.back();
  }



  handlePDFInput(files: FileList) {
    this.imageRemoved = false;
    if (!this.validatePDF(files[0].name)) {
      this.pdfErrorMsg = 'Invalid Document Type, Please Select a PDF File';
      this.imgLink.patchValue('');
      return;
    }
    this.pdfErrorMsg = undefined;
    this.binDoc = files[0];
    this.pdfAttached = files[0];
    this.mediaList.push({type:'PDF', file: this.binDoc});

  }

  handleVideoInput(files: FileList) {
    this.imageRemoved = false;
    this.binDoc = files[0];
    this.attachedVideo = files[0];
    this.mediaList.push({type:'Video', file: this.binDoc});

  }



  removeSavedFile() {
    this.imageRemoved = true;
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue('');
    this.showLogoInput = true;
  }

  validateImage(name: any) {
    return this.helperService.validateImage(name);
  }

  pricingSet(price: string){
    this.pricing = price;
  }
  additionalLink(){
    this.eventLinkClicked = true;
  }
  addYoutubeLink(){
    this.youtubeLinkClicked = true;
  }
  validatePDF(name: any) {
    return this.helperService.validatePDF(name);
  }
  public shareSocialMediaEdit() {
  }

  deleteEvent(event: any){
    this.closeDeleteModal();
    this.newsEventService.deleteEvent(event).then(() => {
      this.toast({html: 'Event deleted successfully!', classes: 'red', type: 'failure'});
    }).catch((err) => {
      this.toast({html: 'Cant Delete event', classes: 'green', type: 'failure'});
      this.backClicked();
    });
  }

  getUrl(url) {
    return this.helperService.getUrl(url);
  }

  dataURItoBlob(dataURI) {
    return this.helperService.dataURItoBlob(dataURI);
  }

openDeleteModal(){
  this.deleteModalOpen = true;
}
closeDeleteModal(){
  this.deleteModalOpen = false;
}

setStartTime(item){
  this.timesEnd = [...this.times.slice(this.times.indexOf(item)),]
  item = item.trim();
  const timeSplited = item.split(':');
  this.eventStartTime.patchValue(item);
  let endingTime; 
  if(timeSplited[0]!=12){
    endingTime = (parseInt(timeSplited[0])+1).toString() +':'+ timeSplited[1];
    this.eventEndTime.patchValue(endingTime);
  }
  if(timeSplited[0] == 12 || timeSplited[0] == 11){
    const timeLetterSplit = item.split('');
    const aOrpIndex = timeLetterSplit.length - 2;
    // replace am with pm and vise versa
    if(timeLetterSplit[aOrpIndex]==='A' || timeLetterSplit[aOrpIndex]==='a' ){
      timeLetterSplit[aOrpIndex] = 'P';
    } else if(timeLetterSplit[aOrpIndex]==='P' || timeLetterSplit[aOrpIndex]==='p'){
      timeLetterSplit[aOrpIndex] = 'A'
     }
    let combinedLetterSplit = timeLetterSplit.join('').split(':');
    if(combinedLetterSplit[0]==11){
      combinedLetterSplit[0] = '12';
    } else if(combinedLetterSplit[0]==12){
      combinedLetterSplit[0] = '1';
    }
    endingTime = combinedLetterSplit[0] +':'+combinedLetterSplit[1];
    this.eventEndTime.patchValue(endingTime);
  }
}
setEndTime(item){
  this.eventEndTime.patchValue(item);
}

nextStep(){
  this.step =  this.step === 2 ? 1 : 2;
}
closeCancelModal(){
  this.cancelModalOpen = false;
}
openCancelModal(){
  this.cancelModalOpen = true;
}
closeEditModal(){
  this.editModalOpen = false;
}

removeImage(){  
  this.binDoc = undefined;
  this.base64Image = undefined;
  this.imgLink.patchValue('');
  this.imageRemoved = true;
  this.event.imgLink = '';
  this.mediaList = this.mediaList.filter((file)=> {
    if(file.type !== 'Image'){
      return file;
    }
  })
}

removeVideo(){
  if (this.modelToPreview){
    this.modelToPreview.videoLink = '';
  }
  if (this.event){
    this.event.videoLink = '';
  }
  if (this.model){
    this.model.videoLink = '';
  }    
  this.binDoc = undefined;
  this.attachedVideo = undefined;
  this.mediaList = this.mediaList.filter((file)=> {
    if(file.type !== 'Video'){
      return file;
    }
  })
  this.editEventForm.patchValue({videoLink: ''})
}

removePDF(){
  if (this.modelToPreview){
    this.modelToPreview.pdfLink = '';
  }
  if (this.event){
    this.event.pdfLink = '';
  }
  if (this.model){
    this.model.pdfLink = '';
  }
  this.pdfAttached = undefined;
  this.mediaList = this.mediaList.filter((file)=> {
    if(file.type !== 'PDF'){
      return file;
    }
  })
  this.editEventForm.patchValue({pdfLink: ''})
}
onImageUpload(response:ImageUploaderResponseModel) {
  if (response.error){
    this.imgErrorMsg = response.errorMessage
    return
  }

  // this.binDoc = response.binDoc;
  this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
  // this.mobileBase64Image = response.imgBase64;
  this.useBase64=true
  this.cropModalOpen = true;

}

// onEventLocationChange(event){
//   if(event.target.checked){
//     this.eventPlace.setValue('Online')
//   }else{
//     this.eventPlace.setValue('')
//     this.eventPlace.setValidators(Validators.required)
//   }
//   this.eventPlace.updateValueAndValidity()
// }

onEventLocationChange() {
  // if (event.target.checked) {
    this.eventPlace.setValue("Online");
  // } 
  // else {
  //   this.eventPlace.setValue(`${this.receivedAddress}`);
  //   this.eventPlace.setValidators(Validators.required);
  // }
  this.eventPlace.updateValueAndValidity();
}


onEventPlaceChange() {
  // if (event.target.checked) {
    this.eventPlace.setValue(this.receivedAddress);
  // } else {
  //   this.eventPlace.setValue("");
  //   this.eventPlace.setValidators(Validators.required);
  // }
  this.eventPlace.updateValueAndValidity();
}

closeCroppingModal(){    
  this.cropModalOpen = false;
  this.useBase64=false

}
// fileChangeEvent(event){
//   this.imageChangedEvent = event;
//   this.cropModalOpen = true;
// }
// processCroppedImage(event){
//   this.cropModalOpen = false;
//   this.useBase64=false
//   this.base64Image = event;
//   this.binDoc = this.base64Image;
//   this.event.imgLink = this.base64Image;
// }

uploadPhoto() {
  this.mediaType = 'Photo';
}

uploadVideo() {
  this.mediaType = 'Video';
}

uploadPdf() {
  this.mediaType = 'PDF';
}

fileChangeEvent(event){
  this.imageChangedEvent = event;
  // this.cropModalOpen = true;

  if (!this.validateImage(event[0].name)) {
    this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
    return;
  }
  this.binDoc = event[0];
  const reader = new FileReader();
  reader.readAsDataURL(event[0]);
  reader.onload = () => {
    this.base64Image = reader.result as string;
    this.useBase64 = true;
    this.processCroppedImage(this.base64Image);
  };
}

getTheUrlsSaved(event){
  this.returnedMediaLinks = event;
  this.uploadFileModalOpen = false;
  this.event.imgLink = this.returnedMediaLinks.imageURL != '' ? this.returnedMediaLinks.imageURL : this.event.imgLink;
  this.event.videoLink = this.returnedMediaLinks.videoUrl != '' ? this.returnedMediaLinks.videoUrl : this.event.videoLink;
  this.event.pdfLink = this.returnedMediaLinks.pdfUrl != '' ? this.returnedMediaLinks.pdfUrl : this.event.pdfLink;
    
  this.newsEventService.editEvent(this.event).then(() => {
    this.toast({html: 'Successfully  updated an event!', classes: 'green', type: 'success'});
  }).catch((err) => {
      this.toast({html: err, classes: 'red', type: 'failure'});
  });

}

processCroppedImage(event){
  this.cropModalOpen = false;
  this.base64Image = event;
  this.binDoc = this.base64Image;
  this.event.imgLink = this.base64Image;
  let base64result = this.base64Image.split(',')[1];
  this.useBase64 = false;
  const img = new ImageModel();
  if(this.isMobile){
    img.imgBase64 = this.mobileBase64Image;
    this.mediaList.push({type:'Image', file: img});
  }else{
    img.imgBase64 = base64result;
    this.mediaList.push({type:'Image', file: img});
  }
}

previewChanges(value){
  this.modelToPreview = {};
  this.modelToPreview.eventTitle = value.eventTitle;
  this.modelToPreview.titleColor = value.titleColor;
  this.modelToPreview.eventPlace = value.eventPlace;
  this.modelToPreview.eventLink = value.eventLink;
  this.modelToPreview.eventStartDate = value.eventStartDate;
  this.modelToPreview.eventEndDate = value.eventEndDate;
  this.modelToPreview.eventStartTime = value.eventStartTime;
  this.modelToPreview.eventEndTime = value.eventEndTime;
  this.modelToPreview.description = value.description;
  this.modelToPreview.backgroundColor = value.backgroundColor;
  this.modelToPreview.videoLink = value.videoLink;
  this.modelToPreview.pdfLink = value.pdfLink;
  this.modelToPreview.sharedLink = value.sharedLink;
  this.modelToPreview.shareToFriends = value.shareToFriends;
  this.modelToPreview.shareWith = value.shareWith;
  this.modelToPreview.price = value.price
  this.modelToPreview.maxRegistration = value.maxRegistration;
  this.modelToPreview.registerRequired = value.registerRequired;
  this.event.opened = this.event.opened ? this.event.opened : [];
  this.modelToPreview.latitude = this.event.latitude;
  this.modelToPreview.longitude = this.event.longitude;
  
  if (this.base64Image){
    this.modelToPreview.imgLink = this.base64Image;
  }
  this.preview = true;
}

toast(obj){
  this.toastMessage = obj.html;
  this.toastClass = obj.classes ? obj.classes : 'green' ;
  this.toastType = obj.type ? obj.type : 'success';
  this.openToast = true;
  setTimeout(() => {
    this.openToast = false;
    if (this.currentUser.role === 'Admin'){
      this.router.navigate(['/admin/events']);
    }
    if (this.currentUser.role === 'Employee'){
      this.router.navigate(['/employee/events']);
    }
  }, 2000);
}
toggleRegistration(){
  if (!this.registerRequired.value) {
    this.editEventForm.controls["maxRegistration"].clearValidators();
    this.editEventForm.controls["maxRegistration"].updateValueAndValidity();
  } else {
    this.editEventForm.controls["maxRegistration"].setValidators(Validators.required);
    this.editEventForm.controls["maxRegistration"].updateValueAndValidity();
  }
  
}
receiveLongitude(long){
  this.receivedLong = long;
}
receiveLatitude(lat){
  this.receivedLat = lat;
}
receiveAddress(location){
  this.receivedAddress = location;
  this.onEventPlaceChange();
}

}

function validateStartAndEndDate(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const startDate = c.parent.get('eventStartDate');
  const endDate = c.parent.get('eventEndDate');
  const eventStartDate = new Date(startDate.value);
  const eventEndDate = new Date(endDate.value);
  if (isBefore(eventStartDate, eventEndDate)  || isSameDay(eventStartDate, eventEndDate)){
    return;
  }
  if (!isBefore(eventStartDate, eventEndDate)){
    return { invalid: true };
  }
}
function validateStartDate(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const startDate = c.parent.get('eventStartDate');
  const eventStartDate = new Date(startDate.value);
  const today = new Date();
  if (isAfter(eventStartDate, today)  || isSameDay(eventStartDate, today)){
    return;
  }
  if (isBefore(eventStartDate, today)){
    return { invalidStartDate: true };
  }
}
