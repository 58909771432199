import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, QueryFn } from '@angular/fire/compat/firestore';
import { ClientAnswersService } from './client-answers.service';
import { map, take } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;


  constructor(private db: AngularFirestore,
    private clientAnswersService: ClientAnswersService) { }

  createID() {
    return this.db.createId();
  }

  saveUser(model: any) {
    return this.db.collection('Users').doc(model.uid).set(model);
  }

  // insert contacts
  insertUsers(usersList) {
    const collectionRef = this.db.collection('Users');


    const batch = this.db.firestore.batch();

    usersList.forEach((doc) => {
      const docRef = collectionRef.ref.doc(doc?.uid); // Generate a new document reference
      batch.set(docRef, { ...doc, });
    });

    return batch.commit()
      .then(() => {
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
      });
  }

  updateUser(model: any) {
    return this.db.collection('Users').doc(model.uid).update(model)
  }

  addContact(contacts: string, userId) {
    this.contentRef = this.db.doc('Users/' + userId);
    const item = this.contentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if (i) {
        i.contact.push(contacts);
        this.updateUser(i);
      }
    });
  }
  removeContact(contacts: string, userId) {
    this.contentRef = this.db.doc('Users/' + userId);
    const item = this.contentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if (i && i.contact) {
        if (i.contact.includes(contacts)) {
          const index = i.contact.indexOf(i.contact.find(selected => selected === contacts));
          i.contact.splice(index, 1);

          this.updateUser(i);
        }
      }
    });
  }

  getAllAdmins(): AngularFirestoreCollection<any[]> {
    return this.db.collection('Users', ref => ref.where('role', '==', 'Admin'));
  }

  getUserById(userId: string) {
    return this.db.collection('Users', ref => ref.where('uid', '==', userId));
  }

  getUserByEmail(email: string) {
    return this.db.collection('Users', ref => ref.where('Email', '==', email));
  }

  getUserByEmailWithOutSubscription(email: string): Promise<any> {
    const query = this.db.collection('Users', ref => ref.where('Email', '==', email)).get();
  
    return firstValueFrom(query).then((querySnapshot) => {
      if (querySnapshot.empty) {
        return null; // Handle the case when no documents are found
      }
  
      const users = querySnapshot.docs.map(doc => doc.data());
      return users.length === 1 ? users[0] : users; // Assuming you expect a single result
    });
  }
  
  getUserByEmailNew(email: string) {
    return this.db.collection('Users', ref => ref.where('Email', '==', email))
      .valueChanges() // Include { idField: 'id' } to include document IDs in the result
      .pipe(take(1))
      .toPromise();
  }

  getUserByCellPhone(phoneNumber: string) {
    return this.db.collection('Users', ref => ref.where('cellPhone', '==', phoneNumber));
  }

  deleteUserAccount(id: string) {
    return this.db.collection('Users').doc(id).delete();
  }

  getAllUsers(): any {
    // return this.db.collection('Users', ref => ref.where('role', '==', 'User').limit(100))
    //   .get();
    return this.db.collection('Users', ref => ref.where('role', '==', 'User'));
  }
  getAllEmployee(){
    return this.db.collection('Users', ref => ref.where('role', '==', 'Employee'));  
  }

  getPaginatedUsers(lastItem?: any, paginationType?: string, searchText?: string, selectedPreference?: string): any {
    let searchTerm = searchText ? searchText.charAt(0).toUpperCase() + searchText.slice(1).toLowerCase() : null;

    try {
      const queryBuilder: QueryFn = (ref) => {
        let query = ref.where('role', '==', 'User');

        if (searchTerm) {
          query = query.where('GivenName', '>=', searchTerm)
            .where('GivenName', '<=', searchTerm + '\uf8ff');
        }

        // selected preference
        if (selectedPreference && ["Email", "SMS"].includes(selectedPreference)) {
          query = query.where("contact", "array-contains", selectedPreference);
        }
        if (selectedPreference?.toLowerCase() === "both") {
          query = query.where("contact", "array-contains", "Email").where("contact", "array-contains", "SMS");
        }
        else if ((selectedPreference)?.toLowerCase() === "imported") {
          query = query.where("importedUser", "==", true);
        }
        else if ((selectedPreference)?.toLowerCase() === "verified") {
          query = query.where("status", "==", "Enabled");
          //this shall be changed to status accepted from the verification request collection
        }


        if (!lastItem) {
          query = query.limit(10);
        }


        if (lastItem) {

          if (paginationType === 'prev') {
            query = query.orderBy('uid')
              .endBefore(lastItem?.uid)
              .limitToLast(10);
          } else {
            query = query.orderBy('uid')
              .startAfter(lastItem?.uid)
              .limit(10);

          }

        }

        return query;
      };

      const usersCollection = this.db.collection('Users', queryBuilder);
      return usersCollection.get();
    } catch (err) {
      console.error('Error getting documents: ', err);
    }
  }

  // for pagination
  getAllUsersAfter(end: number) {
    return this.db.collection('Users', ref => ref.where('role', '==', 'User').limit(101).startAfter(end));
  }

  getUserByRole(role) {
    return this.db.collection('Users', ref => ref.where('role', '==', role,));
  }

  getInvitedUsers() {
    return this.db.collection('UserInvites', ref => ref.where('status', '==', "Pending",));
  }
  getAllInvitedEmployee() {
    return this.db.collection('UserInvites');
  }

  getUserByRoleForNotification(role) {

    const usersCollection: AngularFirestoreCollection<any> = this.db.collection('Users', ref => ref.where('role', '==', role));
    return usersCollection.get().pipe(
      map(querySnapshot => querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    );

  }

  getUserByRoles(roles) {

    const usersCollection: AngularFirestoreCollection<any> = this.db.collection('Users', ref => ref.where('role', 'in', roles));
    return usersCollection.get().pipe(
      map(querySnapshot => querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    );

  }

  getUsers() {
    return this.db.collection('Users', ref => ref.where('role', '==', 'User'));
  }

  // get user by role and who wants to recieve notification
  getUserByRoleAndNotification(role) {
    return this.db.collection('Users', ref => ref.where('role', '==', role).where('notification', '==', 'on'))
  }

  getUserByName(name: string) {
    return this.db.collection('Users', ref => ref.where('GivenName + LastName', '==', name))
  }

  // getAllUser(){
  //   return this.db.collection('Users', ref => ref.where('role','==','User' && 'role', '==', 'Employee'));
  // }

  getUserByShowEmployee() {
    return this.db.collection('Users', ref => ref.where('ShowEmployee', '==', 'Public'))
  }

  getUserFromList(list) {
    return this.db.collection('Users', ref => ref.where('uid', 'in', list))

  }

  // Admin registration requests
  requestToBeAdmin(model: any) {
    const newRequest = {
      id: this.createID(),
      requestDate: model.requestDate,
      email: model.email,
      status: 'Pending'
    };
    return this.db.collection('AdminRegRequests').doc(newRequest.id).set(newRequest);

  }

  getAdminRegReqByEmail(email: string) {
    return this.db.collection('AdminRegRequests', ref => ref.where('email', '==', email));
  }

  getPendingAdminRegRequests() {
    return this.db.collection('AdminRegRequests', ref => ref.where('status', '==', 'Pending'));
  }

  updateAdminRegRequest(model: any) {
    return this.db.collection('AdminRegRequests').doc(model.id).update(model);
  }
  deleteAdminRegRequest(model: any) {
    return this.db.collection('AdminRegRequests').doc(model.id).delete();
  }



}
