import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-oauth-registration',
  templateUrl: './oauth-registration.component.html',
  styleUrls: ['./oauth-registration.component.css']
})
export class OauthRegistrationComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  faceBookLogin()
  {
  //  this.authService.facebookLogin().then(x=> {});
  }
}
