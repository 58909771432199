import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Location } from '@angular/common';
import { UnlinkAccountModel } from 'src/app/models/SocialMediaPostModel';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MediaService } from 'src/app/services/api/media.service';
import { AyshareUserService } from 'src/app/services/firebase/ayrshare-social-media.service';
import { HttpClient } from '@angular/common/http';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { icons } from 'src/app/models/SocialMediaIcons';
import { analysisPlatforms} from 'src/app/models/SocialMediaPostModel';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';


@Component({
    selector: 'app-social-media-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css'], animations: [
        trigger('swing', [transition('* => *', useAnimation(bounceIn))])
    ],
})

export class SocialMediaSettingsComponent implements OnInit {

    constructor(private authService: AuthService,
        private router: Router,
        private settingsService: AppSettingsService,
        private location: Location,
        private http: HttpClient,
        private socialMediaService: AyrshareService,
        private userProfileService: AyshareUserService,
        private fb: UntypedFormBuilder,
        private mediaService: MediaService,
        private fireMembersService: FireMembersService,
        private activatedRoute: ActivatedRoute,
        private profieKeyService:ProfieKeyService,
        private permissionService: PermissionService



    ) {

    }
    availablePlatforms=[]
    allAccountsPosts=[]
    selectedAccountPosts=[]
    selectedAccount

    profileKey

    hasActiveAccounts = false
    userProfile
    socialMediaIcons=icons
    mediaAnalysisPlatforms=[...analysisPlatforms]
    // mediaAnalysisPlatforms=["facebook","instagram","twitter","youtube","pinterest"] //tiktok //linkedin -company
    loading=false 
    deleteModalOpen=false
    user

       // toast
   toastMessage: any;
   toastClass: any;
   toastType: any;
   openToast = false;
   platformToRemove
   removePlatformModal=false

   isAdmin: any;
   perm: any;
   permission: any;

   
  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;

    ngOnInit() {
        this.user = this.authService.getGlobalUser()
        
        if(this.user.role == "Admin"){
            this.isAdmin = true
          }
    
          this.permissionService.getPermissionByEmployeeId(this.user.uid).valueChanges().subscribe(perm =>{
            this.perm = perm[0];
            //this.permission = this.perm.permission[0];
            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];
          });

        this.profieKeyService.currentProfileKey.subscribe(p=>{
            this.profileKey=p
          }
          )
    
          this.getUserProfile()
        

    }

    getUserProfile() {

        if(this.profileKey){
            this.loading = true;
            this.socialMediaService.getUserByProfileKey(this.profileKey).toPromise()
            .then(data => {
                this.userProfile = data
                this.hasActiveAccounts = true ? data['activeSocialAccounts'] && data['activeSocialAccounts'].length > 0 : false
                this.loading = false;
            })
            .catch(err => {
                console.error(err)
                this.loading = false;
  
  
            })
  
  
        }
  
  
    }
    unlinkSocialMedia(){
        this.removePlatformModal=false

        let model = new UnlinkAccountModel()
        model.profileKey=this.profileKey
        model.platform=this.platformToRemove

        
        this.socialMediaService.unlinkAccount(model).toPromise().then(message=>{
            this.toast({ html: `${this.platformToRemove} account successsfully unlinked`, classes: 'green', type: 'success' });

            setTimeout(() => {
                // reload component
                // this.backToDashboard()
                this.ngOnInit();
            }, 2000);
        }).catch(err=>{
          console.error(err)
        })
    }

    removeAccount(platform){

        this.platformToRemove=platform
        this.removePlatformModal=true
    }

    deleteProfile(){
        let model = {
            profileKey:this.profileKey
          }
        //   this.user.profileKeys = this.user.profileKeys.filter(key=>{
        //     return  key.profileKey != this.profileKey
        //   })

          this.socialMediaService.deleteUserProfile(model).toPromise().then(message=>{
              this.userProfileService.deleteProfile(this.profileKey)

            this.toast({ html: 'User profile successsfully deleted', classes: 'green', type: 'success' });

            setTimeout(() => {
                this.backToDashboard()
              }, 2000);
          }).catch(err=>{
            console.error(err)
          })

    }

    backToDashboard(){
        this.router.navigate(['/admin/dashboard'],
            // {
            //     relativeTo: this.activatedRoute
            // }
            )
    
    
      }

      toast(obj) {

        this.toastMessage = obj.html;
        this.toastClass = obj.classes ? obj.classes : 'green';
        this.toastType = obj.type ? obj.type : 'success';
        this.openToast = true;
        this.deleteModalOpen=false
        setTimeout(() => {
          this.openToast = false;
        }, 2000);
      }


}
