<div *ngIf="isOpen" class="modal modal-open">
    <div class="modal-box rounded-md shadow-sm px-6"  [ngClass]="getModalSizeClass()">
   <div class="flex justify-between items-center pb-2">
    <h2 class="font-bold text-customTeal text-xl">{{title}}</h2>
    <div (click)="closeModal()" class="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
    </div>
   </div>
      <hr>
      <div class="py-4">
        <!-- Dynamic content goes here -->
        <ng-content></ng-content>
      </div>
      <div class="modal-action ">
        <ng-content select="[modal-footer]"></ng-content>
      </div>
    </div>
  </div>