import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { MemberAccountSettingsModel } from 'src/app/models/MemberAccountSettingsModel';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';



@Injectable({
  providedIn: 'root'
})
export class MemberAccountSettingsService {
  constructor(private db: AngularFirestore, private fireMemberService: FireMembersService) { }

  accoutSetting: AngularFirestoreDocument<any>;
  memberAccountSettings: AngularFirestoreCollection<any>;



  createAccountSetting(model: MemberAccountSettingsModel) {

    const newContent = {
        id: this.db.createId(),
        memberId: model.memberId,
        permissions: model.permissions
    };

    return this.db.collection('Member-Account-Settings').doc(newContent.id).set(newContent);
  }


  getAccoutSetting(uid: string):  AngularFirestoreCollection<any[]>{
    this.memberAccountSettings = this.db.collection('Member-Account-Settings', ref => ref.where('memberId', '==', uid));
    return this.memberAccountSettings;
  }

  updateAccountSetting(model:MemberAccountSettingsModel){
    const newContent = {
        id: model.id,
        memberId: model.memberId,
        permissions: model.permissions
    };
    return this.db.collection('Member-Account-Settings').doc(newContent.id).update(newContent);
  }

  getAccountsWithNominations(){
    this.memberAccountSettings = this.db.collection('Member-Account-Settings', ref => ref.where('permissions', "array-contains", { allowNomination: true }));
    return this.memberAccountSettings;
  }

  getAccountsWithChat(){
    this.memberAccountSettings = this.db.collection('Member-Account-Settings', ref => ref.where('permissions', "array-contains", { allowChat: true }));
    return this.memberAccountSettings;
  }

  getAccountsWithCandidateContact() {
    this.memberAccountSettings = this.db.collection('Member-Account-Settings',
    ref => ref.where('permissions', 'array-contains', { allowCandidateContact: true }));
    return this.memberAccountSettings;
  }

}
