import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  ChatGptFilterModel,
  ChatGptChatModel,
  ChatGptResponseModel,
} from "src/app/models/chatGptModel";
import { ChatGptService } from "src/app/services/api/chat-gpt.serivce";
import { ProductsServiceMenuService } from "src/app/services/api/data.service";
import { AuthService } from "src/app/services/firebase/auth.service";
import { CountersService } from "src/app/services/firebase/counters.service";
import { UserService } from "src/app/services/firebase/user.service";
import { HelperService } from "src/app/services/helper/helper";
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: "app-chat-gpt",
  templateUrl: "./chat-gpt.component.html",
  styleUrls: ["./chat-gpt.component.css"],
})
export class ChatGptComponent implements OnInit {
  chatForm: UntypedFormGroup;
  chatResponse = [];
  ayrshareResponse = "";
  selectedResponse: any;
  resLoading: boolean = false;
  maxResponseCount: number = 5;
  originalQuestion: string = '';

  chatCount: number = 0;

  generateDescription: any;
  generateHashTag: any;
  generateKeyword: any;


  @ViewChild('messagesContainer') private messagesContainer: ElementRef
  @ViewChild('messagesForm') private messagesForm: ElementRef



  @Input() inputType;
  @Input() theInput;
  @Input() fromComponent;
  @Input() requestMessage;
  @Input() hideButton = false;
  @Input() filter = new ChatGptFilterModel();

  @Output() onMessageSelect = new EventEmitter();
  @Output() closeChatGptModal = new EventEmitter();
  title;
  user;

  titleOptions = [
    "Ask me anything, I'm here to help you like a trusted friend.",
    "No question is too big or small, I'm here to provide answers to them all.",
    "Think of me as your personal information guru, ask away!",
    "There's no need to hesitate, ask me anything on your mind.",
    "From the simple to the complex, I'm ready to answer any question you ask.",
    "Don't hold back, ask me anything and let's get the answers you need.",
    "Your curiosity is welcome here, ask me anything and satisfy your thirst for knowledge.",
    "I'm your go-to source for information, so ask me anything that comes to mind.",
    "Your questions are my priority, ask away and I'll provide the answers you seek.",
  ];

  userCounter: any;
  currentDate = new Date();
  isLimitGPT: boolean = false;
  maxKeyWords: number = 3;
  appDescription: any;
  constructor(
    private appSettingsService: AppSettingsService,
    private chatGptService: ChatGptService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private helperService: HelperService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private counter: CountersService,
    private dataService: ProductsServiceMenuService

  ) { }

  ngOnInit(): void {

    this.user = this.authService.getGlobalUser();

    // Get the current month
    const currentMonth = this.currentDate.getMonth();

    // Move to the next day
    const nextDay = new Date(this.currentDate);
    nextDay.setDate(this.currentDate.getDate() + 1);

    // Check if the next day is in a different month
    const isNewMonth = nextDay.getMonth() !== currentMonth;
    this.counter.getCounter().valueChanges().subscribe((userCounter: any) => {
      console.log("Got counter", userCounter);

      if (userCounter && userCounter.length != 0) {
        this.userCounter = userCounter[0];
        this.chatCount = this.userCounter.chatGptCounter ? this.userCounter.chatGptCounter : 0;
        if (isNewMonth) {
          console.log('A new month has started.');
          this.chatCount = 0;

        } else {
          console.log('The month has not ended yet.');
          if (this.chatCount <= 100) {
            this.chatCount = this.userCounter.chatGptCounter ? this.userCounter.chatGptCounter : 0;
          }
          else {
            this.isLimitGPT = true;
            this.resLoading = true;
          }

        }
      } else {
        const counter = {
          id: '',
          chatGptCounter: this.chatCount,
          SMSTotalCounter: 0,
          emailTotalCounter: 0,
          socialMediaCounter: 0,
        }
        this.counter.saveCounter(counter);
      }
    });


    this.chatForm = this.fb.group({
      content: ["", [Validators.required]],
      rememberQuestion: [true],
      includeHashTag: this.filter.includeHashTag,
      numberOfHashTags: this.filter.numberOfHashTags,
      wordCount: this.filter.wordCount,
      maxWordCount: [],
      response: [""],
      keyWordQuery: [''],
      keyWords: this.fb.array([]),
    });

    if (this.filter.keyWords.length == 0) {
      this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
        if (metaTags && metaTags.length > 0) {
          if (metaTags[0].keyWords && metaTags[0].keyWords.length > 0) {
            if(Array.isArray(metaTags[0].keyWords)){
              this.keyWords.clear();
              metaTags[0].keyWords.forEach((word) => this.keyWords.push(this.fb.control(word)));
          }else{
            this.keyWords.clear();
            JSON.parse(metaTags[0].keyWords).forEach((word) => this.keyWords.push(this.fb.control(word)));
          }
        }
        }
      })
    } else {
      this.filter.keyWords.forEach((word) => this.keyWords.push(this.fb.control(word)))
    }

    if (this.requestMessage && typeof this.requestMessage !== "undefined") {
      this.chatForm.get("content").patchValue(this.requestMessage);
      this.originalQuestion = this.requestMessage
      this.submitChat(this.chatForm.value);
    }

    let index = Math.floor(Math.random() * this.titleOptions.length);
    this.title = this.titleOptions[index];

    let maxLength = this.inputType && this.inputType == "title" ? 15 : 500;
    this.content.setValidators([Validators.required, Validators.maxLength(maxLength)]);
    this.content.updateValueAndValidity()

    this.wordCount.setValidators([Validators.maxLength(maxLength)]);
    this.wordCount.updateValueAndValidity()

    this.maxWordCount.patchValue(maxLength);
    this.maxWordCount.updateValueAndValidity()
  }

  get keyWordQuery() { return this.chatForm.get('keyWordQuery') }

  get keyWords() { return this.chatForm.get('keyWords') as FormArray }

  get content() { return this.chatForm.get("content"); }

  get wordCount() { return this.chatForm.get("wordCount"); }

  get maxWordCount() { return this.chatForm.get("maxWordCount"); }

  get includeHashTag() { return this.chatForm.get("includeHashTag"); }


  selectKeyWord() {
    let wordExists = this.keyWords.value.some(w => w === this.keyWordQuery.value)
    console.log(this.keyWordQuery.value, 'the workd')
    if (wordExists) return
    this.keyWords.push(this.fb.control(this.keyWordQuery.value))
    // this.keyWords.push(this.keyWordQuery.value)
    this.keyWordQuery.patchValue('')
  }

  removeKeyWord(index) {
    this.keyWords.removeAt(index)
  }

  scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      this.messagesForm.nativeElement.scrollIntoView({ behavior: "smooth" })
    } catch (err) {
      console.log(err)
    }
  }

  concatQuestion(question) {
    this.originalQuestion = this.originalQuestion + '\n ' + question
  }

  generateContent(values) {

    if (values.keyWordQuery && values.keyWordQuery != '') {
      let wordExists = values.keyWords.some(w => w === values.keyWordQuery)
      if (!wordExists) values.keyWords.push(values.keyWordQuery)
      this.keyWordQuery.patchValue('')
    }

    if (values.rememberQuestion) {
      this.concatQuestion(values.content)
    } else {
      this.originalQuestion = values.content
    }


    let message = this.originalQuestion

    if (this.appDescription) {
      message = `my business industry is in ${this.appDescription}`
    }

    // if (values.wordCount) {
    //   this.generateDescription = message + `\n write a description from the above sentence using ${values.wordCount} words `;
    //   this.generateKeyword = message + `\n generate 5 keywords from the sentence above`;
    //   this.generateHashTag = message + '\n generate 5 hashtags from the sentence above'
    //   message = message + ` in ${values.wordCount} words`
    // }

    if (values.includeHashTag) {
      message = message + ` and include ${values.numberOfHashTags} HashTags`
    }

    if (values.keyWords.length > 0) {
      message = message + ` and include the following keywords: ${values.keyWords.join(', ')}`
    }


    console.log("The question to ask chatgpt", message);
    return [message, this.generateDescription, this.generateKeyword, this.generateHashTag]

  }
  submitChat(values) {
    let model = new ChatGptChatModel();
    model.message = this.generateContent(values)[0];
    console.log("Message:----", model.message, "Keywords----", this.generateKeyword, "HashTag----", this.generateHashTag)

    let role = this.user ? this.user.GivenName : "User";

    let userRequest = {
      message: {
        role: role,
        content: values.content,
      },
    };
    this.chatResponse.push(userRequest);

    this.resLoading = true;
    this.chatGptService.startChat(model.message).subscribe((res: any) => {
      this.resLoading = false;
      if (res) {
        let response = JSON.parse(JSON.parse(res));
        if (response.choices && response.choices.length > 0) {
          response.choices.map((choice) => this.chatResponse.push(choice));
          this.selectResponseChoice(
            this.chatResponse[this.chatResponse.length - 1],
            false
          );
        }
      }
    });
  }

  onMaxResponse() {
    return (
      this.chatResponse.filter((res) => res.message.role == "assistant")
        .length >= this.maxResponseCount
    );
  }

  selectResponseChoice(choice, useText: boolean) {
    this.selectedResponse = choice.message.content;
    if (useText) {
      this.selectMessage();
    }
  }

  selectMessage() {
    let response = new ChatGptResponseModel();
    response.input = this.theInput;
    response.message = this.selectedResponse;

    this.onMessageSelect.emit(response);
  }

  closeModal() {
    this.closeChatGptModal.emit();
  }

  copy(message, index) {
    this.helperService.copy(message.content);
    let el = document.getElementById(index);

    el.classList.add("tooltip");
    el.classList.add("tooltip-open");

    setTimeout(() => {
      el.classList.remove("tooltip");
      el.classList.remove("tooltip-open");
    }, 2000);
  }

  imageData(userType) {
    let img, text, style;
    let imgWidth = 8;
    switch (userType) {
      case "user":
        img = this.user.profileImgLink || "";
        text = this.user
          ? `${this.user.GivenName || this.user.FirstName} ${this.user.LastName
          }`
          : "User";
        style = "";
        style = "bg-gray-200 text-black rounded-full";
        break;
      case "chatgpt":
        img = "";
        text = "ChatGpt";
        style =
          "bg-white border border-solid border-customTeal font-bold rounded-full";

        break;
      default:
        img = "";
        text = "ChatGpt";
        style = "bg-customTeal text-neutral-content rounded-full";
    }
    return { img, imgWidth, text, style };
  }

  counterMessage() {
    let responseCount = this.chatResponse.filter((res) => res.message.role == "assistant").length
    let text;
    if (this.onMaxResponse()) {
      return 'Sorry, you have reached the maximum number of questions allowed.'
    }
    return text = `You have ${this.maxResponseCount - responseCount} questions left.`
  }
  counterCss() {
    let responseCount = this.chatResponse.filter((res) => res.message.role == "assistant").length
    let css = '';
    if (this.onMaxResponse()) {
      css = 'text-red-500'
    }
    else if (responseCount <= this.maxResponseCount / 2) {
      css = 'text-green-500'
    } else {
      css = 'text-yellow-500'
    }
    return css
  }
}
