
<div class="bg-gray-100 min-h-screen pt-2 max-h-max">

    <div *ngIf="sectionElement" class="mx-5 md:mx-12 md:space-x-8 flex flex-col space-y-4 md:flex-row md:space-y-0 p-6" [ngClass]="{'mx-4': calledFromEmptyDashboard && view=='mobile', 'md:mx-12': !calledFromEmptyDashboard || (modelToPreview && view=='web')}">

      <div class=" w-full  space-y-6 mx-auto rounded-md" [ngClass]="{'': calledFromEmptyDashboard && view=='mobile', 'md:w-3/4 ': !calledFromEmptyDashboard || (modelToPreview && view=='web')}">
        <!-- Section Image and Title -->
        <div class="bg-white flex rounded-md flex-col">

            <div class="w-full py-8 space-y-4 px-4  rounded-md " [ngClass]="{'': calledFromEmptyDashboard && view=='mobile', 'md:px-16': !calledFromEmptyDashboard || (modelToPreview && view =='web')}">
  
                <div class="flex justify-between text-base md:text-2xl font-bold ">
                    <ng-container *ngIf="sectionElement.title">
                        <span [innerHTML]="sectionElement.title| noSanitize"></span>
                    </ng-container>
  
                <div class="float-right text-base md:text-xl font-bold" *ngIf="currentUser && !modelToPreview">
                  <div class="dropdown dropdown-end" *ngIf="currentUser.role=='Admin' || (permCustomPage && (permCustomPage.editItem || permCustomPage.deleteItem))">
                    <label tabindex="0" class="m-1"><i class="material-icons gray-text-600">more_vert</i></label>
                    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-white rounded-box w-52 font-normal">
                      <li *ngIf="currentUser.role=='Admin' ||(permCustomPage && permCustomPage.editItem)" (click)="navigateEditElement(sectionElement)" ><a><i class="material-icons gray-text-600">edit</i>Edit</a></li>
                      <li (click)="openDeleteModal()" *ngIf="currentUser.role=='Admin' ||(permCustomPage && permCustomPage.deleteItem)"><a><i class="material-icons gray-text-600">delete</i>Delete</a></li>
                    </ul>
                  </div>
                </div>
  
                </div>
  
                <div class="flex flex-col space-y-2 " *ngIf="sectionElement.imgLink && sectionElement.link &&!sectionElement.videoLink">
                  <a [href]="sectionElement.link" target="_blank" class="hover:cursor-pointer">  
                    <img loading="lazy" [src]="sectionElement.imgLink" class="object-cover w-full rounded-md" alt="Event Image" >
                  </a>
                </div>
                <div class="flex flex-col space-y-2 " *ngIf="sectionElement.imgLink && !sectionElement.link && !sectionElement.videoLink">
                  <img loading="lazy" [src]="sectionElement.imgLink" class="object-cover w-full rounded-md" alt="Event Image">
                </div>
                    <!-- if video -->
              <div *ngIf="sectionElement.videoLink && sectionElement.videoLink!=''">
                <video autoplay controls *ngIf="sectionElement.videoLink != ''" [src]="sectionElement.videoLink" class="object-cover" alt="Logo"></video>
              </div>
            </div>
        </div>
  
        <!-- Section Description -->
        <div class="bg-white rounded-md" *ngIf="sectionElement.description">
          <div class="flex flex-col space-y-4  md:space-y-10">
              <div class=" bg-white w-full px-4  py-6   rounded-md "   [ngClass]="{'': calledFromEmptyDashboard && view=='mobile', 'md:px-16': !calledFromEmptyDashboard || (modelToPreview && view =='web')}">
                  <span [innerHTML]="sectionElement.description | noSanitize"></span>
              </div>
          </div>
          <div class="w-1/3">
  
          </div>
        </div>
        
        <!-- Comment section -->
        <div *ngIf="sectionElement.commentable" class="">
          <app-post-comment [post]="sectionElement"
          [sectionName]="sectionElement.title"
          postType="custom"
          (showCommentDeletedToast)="openDeleteToast()"
          >
          </app-post-comment>
        </div>
      </div>
  
    </div>
</div>
  
  
  <!-- Delete Modal -->
  <app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteSectionElement() (cancelDeleteClicked)="deleteModalOpen=false" deletedFromComponent='Item'></app-delete-confirmation>
  
  
  <!-- toast -->
  <app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
  