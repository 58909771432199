import { FaqService } from './../../../services/firebase/faq.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { FireMembersService } from './../../../services/firebase/fire-members.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { MediaService } from './../../../services/api/media.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ImageModel } from 'src/app/models/ImageModel';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
// import { EmbedVideoService } from 'ngx-embed-video';
import { map } from 'rxjs/internal/operators/map';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { AreasOfInterestService } from 'src/app/services/firebase/areas-of-interest.service';

// declare var navigator: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class SettingsComponent implements OnInit {
  displayMenu = true;
  currentUser: any;
  id: any;  appSettingsForm: UntypedFormGroup;
  FaqForm: UntypedFormGroup;
  model: any;
  themeColor: any;
  isSidebarMenu = false;
  isBottomMenu = false;
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  showLogoInput = true;
  showImageFromServer = false;
  oldUrl: any;
  isMobile = false;
  mobileBase64Image: any;
  editModel: any;
  isEdit = false;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  socialForm: UntypedFormGroup;
  hasSocial = false;
  socialID = '';
  sectionsSetting = true;
  pageSections: any;
  isNewSetting = true;
  sliderSettings = false;
  sliderRef: any;
  newsSettings = false;
  newsRef: any;
  newsArray: any;
  eventsSettings = false;
  eventRef: any;
  eventsModel: any;
  appStoreSettings = false;
  appStoreRef: any;
  socialMediaSettings = false;
  socialMediaRef: any;
  socialMediaModel: any;
  footerSettings = false;
  footerRef: any;
  slideRef: any;
  emptySlideOption = false;
  isPositionChanged = false;
  selectedNewPosition: any;
  preview = false;
  servicesArray: any;
  menu: any;
  isMenuSettings = true;
  isEditItem = false;
  selectedItem: any;
  selectedItemName: any;
  defaultThemeColor = environment.appTheme.themeColor;
  areasListModel: any[];
  selectedModel: any;
  areaOfInterestForm: UntypedFormGroup;
  showMsg = false;
  isBusinessApp = false;
  contactAndBasicInfoForm: UntypedFormGroup;
  collapsableIconApp = 'add_circle';
  collapsableIconSocial = 'add_circle';
  collapsableIconLanding = 'add_circle';
  collapsableIconAreas = 'add_circle';
  collapsableIconAdmin = 'add_circle';
  collapsableIconContact = 'add_circle';
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')' , ' ' , /[0-9]/, /[0-9]/, /[0-9]/, ' ' , '-' , ' ' ,
  /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  contactSetting: any;
  faqs: any;


  constructor(private authService: AuthService, private router: Router, private fireMemberService: FireMembersService,
              private fb: UntypedFormBuilder,
              private mediaService: MediaService,
              private location: Location,
              private newsEventService: NewsEventService,
              // private embedService: EmbedVideoService,
              private employeeServicesService: EmployeeServicesService,
              private areaService: AreasOfInterestService,
              private appSettingsService: AppSettingsService,
              private socialMediaService: SocialMediaService,
              private faqService: FaqService) {
                this.getSettings();
                this.getAdminSettings();

  }
  get facebook() { return this.socialForm.get('facebook'); }
  get twitter() { return this.socialForm.get('twitter'); }
  get instagram() { return this.socialForm.get('instagram'); }
  get youtube() { return this.socialForm.get('youtube'); }
  get appLogo() { return this.appSettingsForm.get('appLogo'); }
  get name() { return this.areaOfInterestForm.get('name'); }

  ngOnInit() {
    this.currentUser = this.authService.getGlobalUser();
    this.fireMemberService.getProfileByuserID(this.currentUser.uid).valueChanges().subscribe(x => {
        if (x) {
          if (x.length === 0) {
            this.fireMemberService.createNewProfile(this.currentUser.uid);
          }

        } });
    setTimeout(() => {
      // $('.tooltipped').tooltip();
    }, 50);
    // App settings
    this.appSettingsForm = this.fb.group({
      appName: ['', [Validators.required]],
      description: [''],
      appLogo: [''],
      buttonOption: [''],
      navbarOption: [''],
      themeColor: [''],
      rightOrLeftSidebar: [false],
      serviceType: ['Both'],
      takeAppointment: [false],
      isBusinessApp: [false]
    });
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.isBusinessApp = this.model.isBusinessApp;
            setTimeout(() => {
              // $('.tooltipped').tooltip();
            }, 50);
            this.appSettingsForm.patchValue({
              appName: this.model.appName ? this.model.appName : this.currentUser.GivenName,
              buttonOption: this.model.buttonOption ? this.model.buttonOption : 'rectangularbutton',
              navbarOption: this.model.navbarOption ? this.model.navbarOption : 'bottomnavbar',
              themeColor: this.model.themeColor ? this.model.themeColor : '',
              rightOrLeftSidebar: this.model.rightOrLeftSidebar ? this.model.rightOrLeftSidebar : false,
              description: this.model.description ? this.model.description : '',
              serviceType: this.model.serviceType ? this.model.serviceType : 'Both',
              appLogo: this.model.logo ? this.model.logo : '',
              takeAppointment: this.model.takeAppointment ? this.model.takeAppointment : false,
              isBusinessApp: this.model.isBusinessApp ? this.model.isBusinessApp : false
            });
            if (this.model.navbarOption === 'sidenavbar') {
              this.isSidebarMenu = true;
            }
            if (this.model.logo){
              this.showImageFromServer = true;
              this.oldUrl = this.model.logo ? this.model.logo: '';

            }

            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;

      }
    }
    });


    // social media settings
    this.socialForm = this.fb.group({
      facebook: ['', [ Validators.pattern(this.urlPattern)]],
      twitter: ['', [Validators.pattern(this.urlPattern)]],
      instagram: ['', [ Validators.pattern(this.urlPattern)]],
      youtube: ['', [ Validators.pattern(this.urlPattern)]],
    });

    this.socialMediaService.getSocialMedia().valueChanges().subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.socialForm.patchValue({
            facebook: x[0].facebook,
            twitter: x[0].twitter,
            instagram: x[0].instagram,
            youtube: x[0].youtube
          });

          this.hasSocial = true;
          this.socialID = x[0].id;

          setTimeout(() => {
            // M.updateTextFields();
          }, 50);
        }
      }
    });


    // LANDING PAGE
    if (!this.pageSections) {
      this.pageSections = {
        uid: '',
        sections: [
            {
              index: 0,
              isEnabled: false,
              name: 'Slider',
              interval: 1000,
              displayName: 'Main Slider',
              options: []
            },
            {
              index: 1,
              isEnabled: false,
              bgColor: '#FFFFFF',
              name: 'Events',
              displayName: 'Upcoming Events',
              displayNameColor: '#000000',
            },

            {
              index: 2,
              isEnabled: false,
              bgColor: '#FFFFFF',
              name: 'Products',
              displayName: 'Products',
              displayNameColor: '#000000',
              displayLimit: 3
            },
            {
              index: 3,
              isEnabled: false,
              bgColor: '#FFFFFF',
              textColor: '#000000',
              name: 'Services',
              displayName: 'Services',
            },
            {
              index: 4,
              isEnabled: false,
              bgColor: '#FFFFFF',
              displayNameColor: '#000000',
              name: 'SocialMedia',
              displayName: 'Social Media',
              facebookUrl: '',
              twitterUrl: '',
              youtubeUrl: '',
              linkedInUrl: '',
            },
            {
              index: 5,
              isEnabled: false,
              bgColor: '#FFFFFF',
              name: 'AppStore',
              displayName: 'Download From The App Store',
              googlePlayUrl: '',
              appleStoreUrl: '',
              displayNameColor: '#000000',
            },
          ]
      };
    }

    // setTimeout(() => {
    //   $('.modal').modal({
    //     onCloseStart: () => {
    //       $('.slider').slider({
    //         interval: this.sliderRef.interval,
    //         transition: 800,

    //       });
    //     }
    //   });
    //   $('.slider').slider({ });

    // }, 50);


    // AREAS OF INTEREST
    this.areaOfInterestForm = this.fb.group({
      name: ['', [Validators.required]],
    });

    this.areaService.getAreasOfInterest().valueChanges().subscribe(a => {
      if (a) {
        this.areasListModel = a;
        this.showMsg = true;
      }
    });


    // ADMIN MENU
    if (!this.menu) {
      this.menu = {
        id: '',
        items: [
          {
            index : 0,
            name: 'RegistrationRequest',
            displayName: 'Registration Request',
            bgColor: '#2196F3',
            textColor: '#FFFFFF',
            icon: 'person_add',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Membership',
          },
          {
            index: 1,
            name: 'RegisteredAdmins',
            displayName: 'Registered Admins',
            bgColor: '#00796B',
            textColor: '#FFFFFF',
            icon: 'group_add',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Membership',
          },
          {
            index: 2,
            name: 'Reports',
            displayName: 'Reports',
            bgColor: '#512DA8',
            textColor: '#FFFFFF',
            icon: 'report_problem',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Membership',
          },
          {
            index: 3,
            name: 'VerificationRequests',
            displayName: 'Verification Requests',
            bgColor: '#558B2F',
            textColor: '#FFFFFF',
            icon: 'verified',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Membership',
          },
          {
            index: 4,
            name: 'InviteUsers',
            displayName: 'Invite Users',
            bgColor: '#212121',
            textColor: '#FFFFFF',
            icon: 'person_add',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Membership',
          },
          {
            index: 5,
            name: 'Services',
            displayName: 'Services',
            bgColor: '#DC143C',
            textColor: '#FFFFFF',
            icon : 'miscellaneous_services',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Main',
          },
          {
            index: 6,
            name: 'ManageUsers',
            displayName: 'Manage Users',
            bgColor: '#D81B60',
            textColor: '#FFFFFF',
            icon : 'manage_accounts',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Membership',
          },
          {
            index: 7,
            name: 'News',
            displayName: 'News',
            bgColor: '#7CB342',
            textColor: '#FFFFFF',
            icon: 'newspaper',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'NewsEvents',
          },
          {
            index: 8,
            name: 'Events',
            displayName: 'Events',
            bgColor: '#546E7A',
            textColor: '#FFFFFF',
            icon: 'event',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'NewsEvents',
          },
          {
            index: 9,
            name: 'Settings',
            displayName: 'Settings',
            bgColor: '#AB47BC',
            textColor: '#FFFFFF',
            icon: 'settings',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Settings',
          },
          {
            index: 10,
            name: 'Logout',
            displayName: 'Logout',
            bgColor: '#DC143C',
            textColor: '#FFFFFF',
            icon : 'sync',
            iconColor: '#FFFFFF',
            isEnabled: true,
            buttonType: 'Main',
          },
        ]
      };
    }


    // CONTACT INFO
    this.contactAndBasicInfoForm = this.fb.group({
      email: ['', Validators.required],
      mobilePhone: [''],
      workPhone1: [''],
      workLocation: ['', ],
      website: [''],
    });

    // GET CONTACT INFO
    this.appSettingsService.getContactSettingsList().valueChanges().subscribe((contact: any) => {
      if (contact.length > 0){
        this.contactSetting   = contact[0];
        this.contactAndBasicInfoForm.patchValue({
          email: contact[0].contactAndBasicInfo.email,
          mobilePhone: contact[0].contactAndBasicInfo.mobilePhone,
          workPhone1: contact[0].contactAndBasicInfo.workPhone1,
          workLocation: contact[0].contactAndBasicInfo.workLocation,
          website: contact[0].contactAndBasicInfo.website
        });
      }

    });

    // FAQ
    this.FaqForm = this.fb.group({
      question: [''],
      answer: ['']
    });

    // GET FAQs
    // this.faqService.getFAQs().valueChanges().subscribe(faqs => {
    //   if (faqs.length > 0){
    //     this.faqs = faqs;
    //   }
    // });

 }

 signOut() {
   this.authService.signOut();
 }

 backClicked(){
   this.location.back();
 }


 // APP Settings
  saveAppSettings(modelValues: any){      // update option

  // $('.collapsible').collapsible('close', 0);
  this.model.buttonOption = modelValues.buttonOption;
  this.model.navbarOption = modelValues.navbarOption;
  this.model.themeColor = modelValues.themeColor;
  this.model.appName = modelValues.appName;
  this.model.description = modelValues.description;
  this.model.rightOrLeftSidebar = modelValues.rightOrLeftSidebar;
  this.model.serviceType = modelValues.serviceType;
  this.model.takeAppointment = modelValues.takeAppointment;
  this.model.isBusinessApp = modelValues.isBusinessApp;
  // updating
  if (this.model){
    if (this.isMobile){
      if (this.mobileBase64Image){
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;
        this.mediaService.uploadImage(img).subscribe((upload) => {
          if (upload){
            this.model.logo = upload.imgLink;
            this.appSettingsService.updateAppSettings(this.model).then(() => {
              // toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
              // this.backClicked();
            }).catch(err => {
              // toast({html: err.message, classes: 'red'});
            });
          }
        });

      } else {
        this.model.logo = '';
        this.appSettingsService.updateAppSettings(this.model).then(() => {
          // toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
          // $('.collapsible').collapsible('close');
          // this.backClicked();
        }).catch(err => {
          // toast({html: err.message, classes: 'red'});
          // $('.collapsible').collapsible('close');
        });
      }
    } else {
      if (this.binDoc && this.oldUrl){
        this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(upload => {
          if (upload){
            this.model.logo = upload.imgLink;
            this.appSettingsService.updateAppSettings(this.model).then(() => {
              // $('.collapsible').collapsible('close');
              // toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
              // this.backClicked();
            }).catch(err => {
              // toast({html: err.message, classes: 'red'});
            });
          }
        });

      } else if (this.binDoc && !this.oldUrl){
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            if (upload){
              this.model.logo = upload.imgLink;
              this.appSettingsService.updateAppSettings(this.model).then(() => {
                // toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
                // $('.collapsible').collapsible('close');
                // this.backClicked();
              }).catch(err => {
                // toast({html: err.message, classes: 'red'});
                // $('.collapsible').collapsible('close');
              });
            }
          }
        );
      } else if (!this.binDoc && this.oldUrl){
        this.model.logo = this.oldUrl;
        this.appSettingsService.updateAppSettings(this.model).then(() => {
          // toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
          // $('.collapsible').collapsible('close');
          // this.backClicked();
        }).catch(err => {
          // $('.collapsible').collapsible('close');
          // toast({html: err.message, classes: 'red'});
        });
      } else{
        this.model.logo = '';
        this.appSettingsService.updateAppSettings(this.model).then(() => {
          // $('.collapsible').collapsible('close');
          // toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
          // this.backClicked();
        }).catch(err => {
          // $('.collapsible').collapsible('close');
          // toast({html: err.message, classes: 'red'});
        });
      }
    }

  }
  // creating new setting
  else {
    if (this.isMobile && this.mobileBase64Image){
      const img = new ImageModel();
      img.imgBase64 = this.mobileBase64Image;
      this.mediaService.uploadImage(img).subscribe(upload => {
        if (upload){
          this.model.logo = upload.imgLink;
          this.appSettingsService.saveAppSettings(modelValues).then(
            () => {
            // toast({ html: 'App Settings Successfully Saved!', classes: 'green' });
            // $('.collapsible').collapsible('close');
            // this.backClicked();
          }).catch(err => {console.error(err); });

        }
      });
    }
    else if (!this.isMobile && this.binDoc){
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            if (upload){
              this.model.logo = upload.imgLink;
              this.appSettingsService.saveAppSettings(modelValues).then(
                () => {
                // toast({ html: 'App Settings Successfully Saved!', classes: 'green' });
                // $('.collapsible').collapsible('close');
                // this.backClicked();
              }).catch(err => {console.error(err); });

            }
          }
        );
    }
      else {
      this.model.logo = '';
      this.appSettingsService.saveAppSettings(modelValues).then(
        () => {
        // toast({ html: 'App Settings Successfully Saved!', classes: 'green' });
        // $('.collapsible').collapsible('close');
        // this.backClicked();
      }).catch(err => {console.error(err); });

    }

  }

  }

  handleImageInput(files: FileList) {
  if (!this.validateImage(files[0].name)) {
    this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
    this.appLogo.patchValue('');
    return;
  }
  this.binDoc = files[0];
  this.imgErrorMsg = undefined;
  const reader = new FileReader();
  reader.readAsDataURL(files[0]);
  reader.onload = () => {
    this.base64Image = reader.result as string;
  };
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.appLogo.patchValue('');
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = this.model.logo;
    this.model.logo = '';
    this.appLogo.patchValue('');
    this.showImageFromServer = false;
    this.showLogoInput = true;
  }

  handleMobilePhotoInput(){
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //   this.binDoc = imageFile;
    //   this.base64Image = 'data:image/jpeg;base64,' + image;
    //   this.mobileBase64Image = image;

    // }, (error) => {
    //   alert(error);
    // },
    // {
    //   quality: 50,
    //   destinationType: navigator.camera.DestinationType.DATA_URL,
    //   encodingType: navigator.camera.EncodingType.JPEG,
    //   mediaType: navigator.camera.MediaType.PICTURE,
    //   sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    // }
    // );
  }
  onAppTypeChange(){
    this.isBusinessApp = this.appSettingsForm.value.isBusinessApp;
    // $('.tooltipped').tooltip();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  // SOCIAL MEDIA SETTING
  submitSocialForm(value: any){

    // $('.collapsible').collapsible('close', 1);
    if (value.facebook){
      const facebookPrefix = value.facebook.split(':')[0].toLowerCase();
      if (!(facebookPrefix === 'http' || facebookPrefix === 'https')){
        value.facebook = 'https://' + value.facebook;
      }
    }
    if (value.twitter){
      const twitterPrefix = value.twitter.split(':')[0].toLowerCase();
      if (!(twitterPrefix === 'http'  || twitterPrefix === 'https')){
        value.twitter = 'https://' + value.twitter;
    }
    }
    if (value.instagram){
      const instaPrefix = value.instagram.split(':')[0].toLowerCase();
      if (!(instaPrefix === 'http'  || instaPrefix === 'https')){
        value.instagram = 'https://' + value.instagram;
    }
    }
    if (value.youtube){
      const youtubePrefix = value.youtube.split(':')[0].toLowerCase();
      if (!(youtubePrefix === 'http'  || youtubePrefix === 'https')){
        value.youtube = 'https://' + value.youtube;
    }
    }
    const model =  {
      facebook: value.facebook ? value.facebook : '',
      twitter: value.twitter ? value.twitter : '',
      instagram: value.instagram ? value.instagram : '',
      youtube: value.youtube ? value.youtube : '',
      id: ''
    };

    if (!this.hasSocial) {
      this.socialMediaService.saveSocialMedia(model).then(resp => {
        // toast({ html: 'Social Media Links Successfully Saved!', classes: 'green' });
      });
    }

    if (this.hasSocial) {
      model.id = this.socialID;
      this.socialMediaService.updateSocialMedia(model).then(resp => {
        // toast({ html: 'Social Media Links Successfully Updated!', classes: 'green' });
      });
    }
  }


  // LANDING PAGE SETTINGS
  sliderClicked(index){
    this.slideRef = index;
    // setTimeout(() => {
    //   M.updateTextFields();
    //   $('.modal').modal('open');
    //   $('.slider').slider('pause');

    // }, 25);



  }

  checkedChange(section: any) {
    section.isEnabled = !section.isEnabled;
  }

  closeModal(){
    // setTimeout(() => {
    //   $('.modal').modal('close');
    //   $('.slider').slider({
    //     interval: this.sliderRef.interval,
    //     transition: 800,
    //   });
    // }, 25);
    if (this.isNewSetting || this.emptySlideOption){
      this.sliderRef.options.pop();
      this.landingPageBackClicked();

    }

  }

  intervalChange(interval){
    this.sliderRef.interval = interval * 1000;
    // $('.slider').slider({
    //   interval: this.sliderRef.interval,
    //   transition: 800,

    // });
  }

  editSection(section: any) {
    if (section.name === 'Slider') {
      this.sliderRef = section;
      this.slideRef = 0;

      // setTimeout(() => {
      //   M.updateTextFields();
      //   $('.slider').slider({
      //     interval: this.sliderRef.interval,
      //     transition: 800,
      //   });
      //   $('.modal').modal({
      //     onCloseStart: () => {
      //       $('.slider').slider({
      //         interval: this.sliderRef.interval,
      //         transition: 800,
      //       });
      //       }
      //   });
      // }, 25);

      if (this.isNewSetting) {
        this.emptySlideOption = true;
        this.addSlider();
      }
      else {
        if (this.sliderRef.options.length > 0) {
          this.slideRef = 0;
          this.sliderRef.options.forEach(element => {
            element.title = element.title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            element.text = element.text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
          });
          setTimeout(() => {
            // M.updateTextFields();
            // $('.slider').slider();
          }, 25);
        } else {
          this.emptySlideOption = true;
          this.addSlider();
        }
      }
      this.sectionsSetting = false;
      this.sliderSettings = true;
    }
    if (section.name === 'News') {

      // tslint:disable-next-line:radix
      this.newsEventService.getLimitedNewsList(parseInt(section.displayLimit)).valueChanges().subscribe(n => {
        if (n) {
          this.newsArray = n;
          this.newsRef = section;
          this.sectionsSetting = false;
          this.newsSettings = true;
          setTimeout(() => {
            // M.updateTextFields();
          }, 25);
        }
      });
    }
    if (section.name === 'Events') {
      this.eventRef = section;
      this.newsEventService.getPublicEventsList().valueChanges().subscribe(n => {
        if (n) {
          this.eventsModel = n;
          this.sectionsSetting = false;
          this.eventsSettings = true;
          setTimeout(() => {
            // M.updateTextFields();
          }, 25);
        }
      });
    }
    if (section.name === 'AppStore') {
      this.appStoreRef = section;
      this.sectionsSetting = false;
      this.appStoreSettings = true;
      setTimeout(() => {
        // M.updateTextFields();
      }, 25);
    }
    if (section.name === 'SocialMedia') {
      this.socialMediaService.getSocialMedia().valueChanges().subscribe(sm => {
        if (sm) {
          if (sm.length > 0) {
            this.socialMediaModel = sm[0];
            this.socialMediaRef = section;
            this.sectionsSetting = false;
            this.socialMediaSettings = true;
            setTimeout(() => {
              // M.updateTextFields();
            }, 25);
          }
        }
      });

    }
    if (section.name === 'Footer') {
      this.footerRef = section;
      this.sectionsSetting = false;
      this.footerSettings = true;
      setTimeout(() => {
        // M.updateTextFields();
      }, 25);

    }
  }

  handleFileInput(files: FileList, slider: any) {
    if (files) {
      this.mediaService.UploadBinImage(files[0]).subscribe(upload => {
        if (upload) {
          slider.imgUrl = upload.imgLink;
          setTimeout(() => {
            // $('.slider').slider();
          }, 25);
        }
      });
    }
  }

  addSlider() {

    // setTimeout(() => {
    //   $('.slider').slider({
    //     interval: this.sliderRef.interval,
    //     transition: 800,
    //   });
    //   $('.modal').modal({
    //     onCloseStart: () => {
    //       $('.slider').slider({
    //         interval: this.sliderRef.interval,
    //         transition: 800,
    //       });
    //       }
    //   });
    // }, 25);
    if (this.sliderRef) {
      const slide = {
        // index: this.sliderRef.options.length + 1,
        index: this.sliderRef.options.length ,
        title: '',
        titleColor: '#FFFFFF',
        text: '',
        textColor: '#FFFFFF',
        imgUrl: '',
        link: ''
      };
      if (this.isNewSetting){

      }
      this.sliderRef.options.push(slide);
      const index = this.sliderRef.options.length - 1;
      this.sliderClicked(index);
    }
  }

  moveSlider(element, position: number) {
    var index = this.sliderRef.options.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.sliderRef.options.length) return; //Already at the top or bottom.
    var indexes = [index, newIndex].sort(); //Sort the indixes
    this.sliderRef.options.splice(indexes[0], 2, this.sliderRef.options[indexes[1]], this.sliderRef.options[indexes[0]]); //Replace from lowest index, two elements, reverting the order
    this.sliderRef.options.forEach(element => {
      element.index = this.sliderRef.options.indexOf(element);
    });
  }

  moveSection(element, position: number) {
    const index = this.pageSections.sections.indexOf(element);
    const newIndex = index + position;
    if (newIndex < 0 || newIndex === this.pageSections.sections.length) {return; } // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indixes
    // Replace from lowest index, two elements, reverting the order
    this.pageSections.sections.splice(indexes[0], 2, this.pageSections.sections[indexes[1]], this.pageSections.sections[indexes[0]]);
    this.pageSections.sections.forEach(element => {
      element.index = this.pageSections.sections.indexOf(element);
    });
  }

  positionChanged(position: any) {
  this.isPositionChanged = true;
  this.selectedNewPosition = position
  }

  saveNewPositionAndChanges(element){

    if (this.isPositionChanged) {
      let newIndex = this.selectedNewPosition; // parseInt((document.getElementById("position") as HTMLInputElement).value);
      let oldIndex = element.index;

      if (newIndex == oldIndex) return; // Already at the top or bottom.

      if (newIndex >= this.sliderRef.options.length){
        newIndex = this.sliderRef.options.length - 1;
      }

      let el = this.sliderRef.options.splice(oldIndex, 1)[0];
      this.sliderRef.options.splice(newIndex, 0, el);

      this.sliderRef.options.forEach(ele => {
        ele.index = this.sliderRef.options.indexOf(ele);
      });


    }

    this.saveChanges();
    // setTimeout(() => {
    //   M.updateTextFields();
    //   $('.modal').modal('close');
    //   $('.slider').slider({
    //     interval: this.sliderRef.interval,
    //     transition: 800,
    //   });

    // }, 25);


  }

  removeSlider(slider: any) {
    // const index = this.sliderRef.options.indexOf(slider);
    // if (index !== -1) {
    //   this.sliderRef.options.splice(index, 1);
    //   this.sliderRef.options.forEach(element => {
    //     element.index = this.sliderRef.options.indexOf(element);
    //   });
    // }
    if  (this.sliderRef.options.length > 0){
      const index = this.sliderRef.options.indexOf(slider);
      if (index !== -1) {
        this.sliderRef.options.splice(index, 1);
        this.sliderRef.options.forEach(element => {
          element.index = this.sliderRef.options.indexOf(element);
        });


        // setTimeout(() => {
        //   M.updateTextFields();
        //   $('.modal').modal('close');
        //   $('.slider').slider({
        //     interval: this.sliderRef.interval,
        //     transition: 800,
        //   });

        // }, 25);

      }
      else{
        this.emptySlideOption = true;
        // setTimeout(() => {
        //   M.updateTextFields();
        //   $('.modal').modal('close');
        //   $('.slider').slider('destroy');

        // }, 25);
      }


    }
  }

  newsNumberChange(num: any) {
    if(!num) return;
    this.newsEventService.getLimitedNewsList(parseInt(num)).valueChanges().subscribe(n => {
      if (n) {
        this.newsArray = n;
      }
    });
  }

  saveChanges() {
    // $('.collapsible').collapsible('close', 2);
    if (this.pageSections && this.isNewSetting) {
      this.appSettingsService.saveHomePageSettings(this.pageSections).then(x => {
        // M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.getSettings();
      });
    }
    if (this.pageSections && !this.isNewSetting) {
      // this.pageSections.sections.splice(6, 0,
      //   {
      //     index: 6,
      //     isEnabled: false,
      //     bgColor: '#FFFFFF',
      //     displayNameColor: '#000000',
      //     name: "SocialMedia",
      //     displayName: "Social Media",
      //     facebookUrl: '',
      //     twitterUrl: '',
      //     youtubeUrl: '',
      //     linkedInUrl: '',
      //   }
      //   );

      this.appSettingsService.updateHomePageSettings(this.pageSections).then(x => {
        // M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.getSettings();
      });
    }
  }

  previewChanges(){
    if (!this.preview){
      const sliderSection = this.pageSections.sections.filter(s => s.name === 'Slider' && s.isEnabled);
      if (sliderSection && sliderSection.length > 0){
        // setTimeout(() => {
        //   M.updateTextFields();
        //   $('.slider').slider({
        //     interval: sliderSection[0].interval,
        //     transition: 800,

        //   });
        // }, 25);
      }

      const newsSection = this.pageSections.sections.filter(s => s.name === 'News' && s.isEnabled);

      if (newsSection) {

        if (newsSection.length > 0) {
          this.newsEventService.getLimitedNewsList(parseInt(newsSection[0].displayLimit))
          .valueChanges().pipe(
            map(news => news.map((n: any) => ({... n, iframe: '' })))
          )
          .subscribe(n => {
            if (n) {
              this.newsArray = n.filter(news => news.visibility === 'Public' && news.published === true);

              this.newsArray.forEach(element => {
                if (element.type === 'Video') {
                  // element.iframe = this.embedService.embed(element.videoLink);
                }

              });

            }
          });
        }
      }

      const eventSection = this.pageSections.sections.filter(s => s.name === 'Events' && s.isEnabled);
      if (eventSection) {
        if(eventSection.length > 0) {
          this.newsEventService.getPublicEventsList().valueChanges().subscribe(n => {
            if (n) {
              this.eventsModel = n;
            }
          });
        }
      }

      const socialSection = this.pageSections.sections.filter(s => s.name === 'SocialMedia' && s.isEnabled);
      if (socialSection) {
        if (socialSection.length > 0) {
          this.socialMediaService.getSocialMedia().valueChanges().subscribe(n => {
            if (n) {
              if (n.length > 0) {
                this.socialMediaModel = n[0];
              }
            }
          });
        }
      }

      const serviceSection = this.pageSections.sections.filter(s => s.name === 'Services' && s.isEnabled);
      if (serviceSection){
        if (serviceSection.length > 0){
          this.employeeServicesService.getPublicServices().valueChanges().subscribe((ser) => {
            if (ser){
              if (ser.length > 0){
                this.servicesArray = ser;
              }
            }
          });
        }
      }


  }else{
    if (this.sliderRef){
      // setTimeout(() => {
      //   $('.slider').slider({
      //     interval: this.sliderRef.interval,
      //     transition: 800,
      //   });
      //   $('.modal').modal({
      //     onCloseStart: () => {
      //       $('.slider').slider({
      //         interval: this.sliderRef.interval,
      //         transition: 800,
      //       });
      //       }
      //   });
      // }, 25);
    }
    // setTimeout(() => {
    //   M.updateTextFields();

    // }, 25);
  }
    this.preview = !this.preview;

  }

  getSettings() {
    this.appSettingsService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          this.isNewSetting = false;
          if (this.sliderSettings) {
            this.sliderRef = this.pageSections.sections.filter(s => s.name === 'Slider')[0];
            // setTimeout(() => {
            //   $('.slider').slider();
            // }, 25);
           }
          if (this.newsSettings) { this.newsRef = this.pageSections.sections.filter(s => s.name === 'News')[0]; }
          if (this.eventsSettings) { this.eventRef = this.pageSections.sections.filter(s => s.name === 'Events')[0]; }
          if (this.appStoreSettings) { this.appStoreRef = this.pageSections.sections.filter(s => s.name === 'AppStore')[0]; }
          if (this.socialMediaSettings) { this.socialMediaRef = this.pageSections.sections.filter(s => s.name === 'SocialMedia')[0]; }
          if (this.footerSettings) { this.footerRef = this.pageSections.sections.filter(s => s.name === 'Footer')[0]; }
          // setTimeout(() => {
          //   M.updateTextFields();
          // }, 25);
        }
      }
    });
  }

  landingPageBackClicked(){
    if (this.sliderSettings) {this.sliderSettings = false; }
    if (this.newsSettings) { this.newsSettings = false; }
    if (this.eventsSettings) { this.eventsSettings = false; }
    if (this.appStoreSettings) { this.appStoreSettings = false; }
    if (this.socialMediaSettings){  this.socialMediaSettings = false; }
    if (this.footerSettings){ this.footerSettings = false; }
    this.sectionsSetting = true;
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }


  // MANAGE AREA OF INTEREST
  addArea(){
    // $('.collapsible').collapsible('close', 3);
    this.selectedModel = undefined;
    this.areaOfInterestForm.patchValue({name: ''});
    // setTimeout(() => {
    //   $('.modal').modal();
    //   $('#editModal').modal('open');
    // }, 25);
  }

  selectArea(area: any) {
    if (area) {
      this.selectedModel = area;
      this.areaOfInterestForm.patchValue({name: area.name});
      // setTimeout(() => {
      //   $('.modal').modal();
      //   M.updateTextFields();
      //   $('#editModal').modal('open');
      // }, 25);

    }
  }

  manageAreasOfInterest(model: any) {
      // if ! then add
    if (!this.selectedModel) {
      const newArea = {
        id: '',
        name: model.name,
        membersList: []
      };

      this.areaService.saveAreasOfInterest(newArea).then( saved => {
        // toast({ html: 'Area Of Interest Successfully Saved', classes: 'green' });
        // $('#editModal').modal('close');
      });

    } else {
      // if === then update
      const newArea = {
        id: this.selectedModel.id,
        name: model.name,
        membersList: this.selectedModel.membersList
      };

      this.areaService.updateAreasOfInterest(newArea).then( saved => {
        // toast({ html: 'Area Of Interest Successfully Saved', classes: 'green' });
        // $('#editModal').modal('close');
      });
    }

  }


  // ADMIN MENU
  getAdminSettings() {
    this.appSettingsService.getAdminMenuSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.menu = hp[0];
          this.isNewSetting = false;
          if (this.selectedItemName === 'RegistrationRequest') { this.selectedItem = this.menu.items.filter(s => s.name === 'RegistrationRequest')[0]; }
          if (this.selectedItemName === 'RegisteredAdmins') { this.selectedItem = this.menu.items.filter(s => s.name === 'RegisteredAdmins')[0]; }
          if (this.selectedItemName === 'Reports') { this.selectedItem = this.menu.items.filter(s => s.name === 'Reports')[0]; }
          if (this.selectedItemName === 'VerificationRequests') { this.selectedItem = this.menu.items.filter(s => s.name === 'VerificationRequests')[0]; }
          if (this.selectedItemName === 'InviteUsers') { this.selectedItem = this.menu.items.filter(s => s.name === 'InviteUsers')[0]; }
          if (this.selectedItemName === 'ManageUsers') { this.selectedItem = this.menu.items.filter(s => s.name === 'ManageUsers')[0]; }
          if (this.selectedItemName === 'News') { this.selectedItem = this.menu.items.filter(s => s.name === 'News')[0]; }
          if (this.selectedItemName === 'Events') { this.selectedItem = this.menu.items.filter(s => s.name === 'Events')[0]; }
          if (this.selectedItemName === 'Services') { this.selectedItem = this.menu.items.filter(s => s.name === 'Services')[0]; }
          if (this.selectedItemName === 'Settings') { this.selectedItem = this.menu.items.filter(s => s.name === 'Settings')[0]; }
          if (this.selectedItemName === 'Logout') { this.selectedItem = this.menu.items.filter(s => s.name === 'Logout')[0]; }
          // setTimeout(() => {
          //     M.updateTextFields();
          //   }, 25);
          }
      }
    });
  }

  checkedChangeAdminMenu(section: any) {
    section.isEnabled = !section.isEnabled;
  }

  moveSectionAdminMenu(element, position: number) {
    var index = this.menu.items.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.menu.items.length) return; // Already at the top or bottom.

    this.menu.items.splice(index, 1);
    this.menu.items.splice(newIndex, 0, element);
    this.menu.items.forEach(elt => {
      elt.index =  this.menu.items.indexOf(elt);
    });

  }

  editSectionAdminMenu(section) {
    this.selectedItem = section;
    this.selectedItemName = section.name;
    this.isMenuSettings = false;
    this.isEditItem = true;
    setTimeout(() => {
      // M.updateTextFields();
    }, 25);
  }

  saveChangesAdminMenu() {
    // $('.collapsible').collapsible('close', 4);
    if (this.menu && this.isNewSetting) {
      this.appSettingsService.saveAdminMenuSettings(this.menu).then(x => {
        // M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.getSettings();
      });
    }

    if (this.menu && !this.isNewSetting) {
      this.appSettingsService.updateAdminMenuSettings(this.menu).then(x => {
        // M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.getSettings();
      });
    }
  }

  backClickedAdminMenu(){
    this.selectedItem = undefined;
    this.isEditItem = false;
    this.isMenuSettings = true;
  }


  // CONTACT
  addContactAndBasicInfo(value){
    // $('.collapsible').collapsible('close', 5);
    if (value.mobilePhone === undefined){
      value.mobilePhone = '';
    }
    if (this.contactSetting && this.contactSetting.id){
      this.contactSetting.contactAndBasicInfo = value;
      this.appSettingsService.updateContactSettings(this.contactSetting).then(() => {

        // toast({html: 'Contact info updated successfully!', classes: 'green'});
      }).catch((err) => {
        // toast({html: err, classes: 'red'});
      });

    } else {
      this.contactSetting.contactAndBasicInfo = value;
      this.appSettingsService.saveContactSettings(this.contactSetting).then(() => {
        // toast({html: 'Contact Info Added Successfully', classes: 'green'});
      }).catch((err) => {
        // toast({html: err, classes: 'red'});
      });
    }

  }

}
