import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from './../../../services/firebase/products.service';
import { CartService } from 'src/app/services/checkout/cart.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';
import {
  Modal,
  Collapse,
  Ripple,
  initTE,
} from "tw-elements";
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {
  @Input() calledFrom;

  productId: any;
  product: any;
  isMobile = false;
  currentUser: any;

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  role: any;
  isAdmin: any;
  perm: any;
  permission: any;

  settings: any;
  productsName;
  currency;
  @Input() modelToPreview: any;
  @Input() view


  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  productDescription: any;
  textLimit = true
  selectedProduct: any;
  appSettings$;

  constructor(private route: ActivatedRoute, private location: Location,
              private productsService: ProductsService,
              private authService: AuthService,
              private cartService: CartService,
              private productsServiceMenuService: ProductsServiceMenuService,
              public settingsService: AppSettingsService,
              public helperService: HelperService,
              private permissionService: PermissionService,
              private store: Store) {
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { this.settings = settings;  });
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    initTE({  Modal, Collapse, Ripple });
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      this.role = this.currentUser.role;
      if (this.role === 'Admin'){
        this.isAdmin = true;
      }
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
        this.perm = perm[0];
        //this.permission = this.perm.permission[0];

        this.permMember = this.perm?.permission[0]
        this.permPES = this.perm?.permission[1]
        this.permAnalytics = this.perm?.permission[2];
        this.permSocial = this.perm?.permission[3];
        this.permSpecial = this.perm?.permission[4];
      });

    }

    this.productsServiceMenuService.productsName.subscribe(p => {
      this.productsName = p;
    });

    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });
    this.currentUser = this.authService.getGlobalUser();
    if (this.modelToPreview){
      this.product = this.modelToPreview;
    }
    this.route.paramMap.subscribe(params => {
      this.productId = params.get('id');
    });
    if (this.productId === 'product1'){
      this.product = {
        id: 'product1',
        productName: 'Product 1',
        productDescription: 'Product description will be here',
        category: '',
        categoryId: ''
      };
    }
    if (this.productId === 'product2'){
      this.product = {
        id: 'product2',
        productName: 'Product 2',
        productDescription: 'Product description will be here',
        category: '',
        categoryId: ''
      };
    }
    this.productsService.getProductById(this.productId).valueChanges().subscribe(products => {
      if (products.length > 0 ){
        this.product = products[0];
        this.productDescription = this.product?.productDescription;
        if (this.modelToPreview){
          this.product = this.modelToPreview;
        }
      }
    });

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.product = this.modelToPreview
    if(this.modelToPreview){
      this.productDescription = this.modelToPreview.productDescription
    }
  }
  backClicked(){
    this.location.back();
  }
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text?.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text?.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  AddToCart(productID: any) {
    this.cartService.addProductToCart(productID, 1);
    this.toast({html: 'Product Successfully added to cart', classes: 'green', type: 'success',redirect:true});
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if(obj.redirect){
        this.backClicked();
      }
    }, 2000);
  }
  isValidUrl(url){
    return this.helperService.isValidURL(url)
  }
  openDeleteToast(){
    this.toast({html: 'Comment Deleted!', classes: 'red', type: 'failure', redirect: false});
  }
}
