import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Injectable, 
  ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { UserService } from 'src/app/services/firebase/user.service';
import { AppointmentService } from '../../../services/firebase/employee-appointment.service';
import { isSameYear, isSameWeek, isToday, addMinutes, getDay,format } from 'date-fns';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { formatDate } from '@angular/common';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import {
  CalendarEvent, CalendarView,
 CalendarDateFormatter, DateFormatterParams, CalendarWeekViewBeforeRenderEvent, CalendarEventAction, CalendarMonthViewDay, CalendarEventTimesChangedEvent, CalendarMonthViewBeforeRenderEvent, CalendarDayViewBeforeRenderEvent, DateAdapter
} from 'angular-calendar';

import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { Subject } from 'rxjs';
import { startOfDay, isAfter, isBefore, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth,  } from 'date-fns';
import {
  subMonths, addMonths, addWeeks, subWeeks, startOfMonth,
  startOfWeek, endOfWeek,
} from 'date-fns';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';

import { EventColor, ViewPeriod } from 'calendar-utils';
import { UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { UserNotesService } from 'src/app/services/firebase/user-notes.service';
import { HelperService } from 'src/app/services/helper/helper';
import { TaskService } from 'src/app/services/firebase/task.service';
import { RRule } from 'rrule';  
import { switchMap } from 'rxjs/operators';
import { CalendarAuthService } from 'src/app/services/firebase/calendar-auth.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
const CALENDAR_RESPONSIVE = {
  small: {
    breakpoint: '(max-width: 576px)', //for mobile
    daysInWeek: 1,
  },
  medium: {
    breakpoint: '(max-width: 768px)',
    daysInWeek: 3,
  },
  large: {
    breakpoint: '(max-width: 960px)',
    daysInWeek: 5,
  },
};
interface RecurringEvent {
  title: string;
  color: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
  };
}

//moment.tz.setDefault('Utc');

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  constructor(dateAdapter: DateAdapter) {
    super(dateAdapter);
  }
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'h:mm a', locale);
  }
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }
}

type CalendarPeriod = 'day' | 'week' | 'month';
function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths,
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

function endOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[period](date);
}

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
        .cal-week-view .cal-time-events .cal-day-column {
          margin-right: 10px;
        }

        .cal-week-view .cal-hour {
          width: calc(100% + 10px);
        }
        .bg-pink {
          background-color: pink;
        }
        .cal-open{
          color: #ffffff !important;
        }
        .my-custom-class span {
          color: #ffffff !important;
        }
      `,
  ],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: CustomDateFormatter,
  }]
})

export class AppointmentComponent implements OnInit {
  daysInWeek = 7;
  private destroy$ = new Subject<void>();
  // For the dates
  datevalue: any;
  dayAndmonth = [];
  match_dayAndmonth = [];
  date: any = moment(); // this is the current date
  weekno = Math.ceil(this.date.date() / 7);
  day_month: any;
  
  //Filter
  filterOpen = false;
  appointmentTimelineFilter = '';
  beforeTimeline = [];

  allAppointment = [];
  EmpToAppointment = [];
  userToAppointment = [];
  appointments: any;
  totalAppointment: any;
  search_text: any;
  page: number = 1;
  dateAdded: any;

  todayAppointment: number = 0;
  upcomingAppointment: number = 0;
  upcomingAppointments = []; //an array of appointments
  isClicked: boolean = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  view: CalendarView = CalendarView.Week;
  viewday: CalendarView = CalendarView.Day;
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  isCalendarView = true;
  weekStartsOn = getDay(new Date());
  minDate: Date = subDays(new Date(), 1);
  maxDate: Date = addMonths(new Date(), 1);
  minStartingTime = 9;
  maxEndingTime = 17;
  prevBtnDisabled = false;
  nextBtnDisabled = false;
  activeAppointmentsCopy = [];

  loggedUser: any;
  currentUser: any;
  loading = false;
  employeeId: any;
  employeeSchedule: any;
  excludeDays: any;
  appointmentsList: any = [];

  employeeServices: any;
  selectedServiceTime: any;
  unAvailableTimeSlot: any;
  unAvailableTimes = [];
  selectedAppointment: any;
  selectedService: any;
  appointmentMadeBy: any;
  deleteModalOpen = false;
  editModalOpen = false;
  createAppointmentForm: UntypedFormGroup;
  activeAppointments = [];
  notes = [];

  //added
  activeDayIsOpen: boolean = true;
  selectedDayViewDate: any;
  openCreateTaskModal = false;
  isAddNote: boolean = true;
  //notesForm: FormGroup;
  tasksForm: UntypedFormGroup;
  tasks = [];
  eventObj: any;
  eventEditObj : any;
  noteGlobal  : any;
  taskGlobal: any;
  taskUnsubscribe: any;
  noteGlobalEdit: any;
  taskGlobalEdit:any;
  settings: any = {};

  //for unsubscribe
  taskEdit : any;

  times = [ '08:00 AM','08:30 AM', '09:00 AM','09:30 AM', '10:00 AM','10:30 AM', '11:00 AM', '11:30 AM',
  '12:00 PM','12:30 PM','01:00 PM','01:30 PM', '02:00 PM','02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM',
  '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM','06:30 PM','07:00 PM','07:30 PM', '08:00 PM','08:30 PM',
  '09:00 PM', '09:30 PM', '10:00 PM','10:30 PM', '11:00 PM', '11:30 PM', '12:00 AM','12:30 AM', '01:30 AM',
  '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM', '04:00 AM','04:30 AM', '05:00 AM',  '05:30 AM', '06:00 AM',
  '06:30 AM', '07:00 AM',  '07:30 AM',]
timesEnd = [];
 //startTime = '3:00 AM';
 timePattern = '((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))';

 deleteIsOpen: boolean = false;

  actions: CalendarEventAction[] = [
    {
      //label: '<i class="material-icons text-sm space-x-4 ">edit</i> <span class="text-lg"></span>',
       label:' ',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.openEditModal(event);
        //this.handleEvent('Edit', event);
      },
    },
    {
      label:' ',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
      },
    },
  ];

  recurringEvents: RecurringEvent[] = [
    {
      title: 'Recurs on the 5th of each month',
      color: colors.yellow,
      rrule: {
        freq: RRule.MONTHLY,
        bymonthday: 5,
      },
    },
    {
      title: 'Recurs yearly on the 10th of the current month',
      color: colors.blue,
      rrule: {
        freq: RRule.YEARLY,
        bymonth: moment().month() + 1,
        bymonthday: 10,
      },
    },
    {
      title: 'Recurs weekly on mondays',
      color: colors.red,
      rrule: {
        freq: RRule.WEEKLY,
        byweekday: [RRule.MO],
      },
    },
  ];
  subscribers: any;
  remindMe: boolean = false;
  userId: any;
  trackStartReminderDate: any;
  userName : any;
  employeeName : any;
  userLastName:any;
  employeeLastName : any;
  addStartTime: any;
  editStartTime : any;
  viewPeriod: ViewPeriod;
  viewRender : any;
  rule : any;
  employees:any;
  empId = '';
  selectedEmployee: any;
  nonEditable: boolean = false;

  //for the calender
  calendarItems: any;
  userCalendar : any;
  appSettings: any;
  appSettings$: Subscription;
 constructor(
    private appointmentService: AppointmentService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private empService: EmployeeServicesService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private userNotesService: UserNotesService,
    private helperService: HelperService,
    private taskService: TaskService,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef,
    private auth: CalendarAuthService,
    private store: Store
  ) {
    this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
      this.appSettings = settings;
    });

    this.loggedUser = this.authService.getGlobalUser();
    this.currentUser = this.authService.getCurrentUser();
    if (this.currentUser) {

      this.employeeId = this.currentUser.uid;
      this.empId = this.currentUser.uid;
      this.getEmployeeSchedule();

    }

    this.refresh.next();

  }
  private initializeUserAndCalendar(): void {
    this.loggedUser = this.authService.getGlobalUser();
    this.calendarItems = this.auth.calendarItems;
  }

  private initializeForms(): void {
    this.tasksForm = this.fb.group({
      taskDescription: ['', [Validators.required]],
      reminder: ['', [Validators.required, validateStartDate]],
      reminderTime: ['', [Validators.pattern(this.timePattern), validateStartTime]],
      endReminder: ['', [Validators.required, validateStartAndEndDate]],
      endReminderTime: ['', [Validators.pattern(this.timePattern), validateStartTimeAndEndTime]],
    });

    this.createAppointmentForm = this.fb.group({
      service: [, [Validators.required]],
      startTime: [, [Validators.required]],
      endTime: [, [Validators.required]]
    });
  }

  private setupResponsiveBreakpoints(): void {
    const breakpoints = Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint);
    
    this.breakpointObserver
      .observe(breakpoints)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE)
          .find(({ breakpoint }) => state.breakpoints[breakpoint]);

        this.daysInWeek = foundBreakpoint ? foundBreakpoint.daysInWeek : 7;
        this.cdr.markForCheck();
      });
  }

  private loadAppointmentSettings(): void {
    this.appointmentService.getAppointmentSetting()
      .valueChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings: any) => {
        if (settings && settings.length) {
          this.settings = settings[0];
        }
      });
  }

  private loadUsersByRole(role: string, property: 'subscribers' | 'employees'): void {
    this.userService.getUserByRole(role)
      .valueChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => {
        if (users && users.length) {
          this[property] = users;
        }
      });
  }


  ngOnInit(): void {
    this.initializeUserAndCalendar();
    this.initializeForms();
    this.setupResponsiveBreakpoints();
    this.loadAppointmentSettings();
    this.loadUsersByRole('User', 'subscribers');
    this.loadUsersByRole('Employee', 'employees');
    this.selectEmployee({ value: '' });
    this.refresh.next();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
  //redirect us to the appointment detail
  viewAppointment(userModel: any) {
    if(this.loggedUser){
      if(this.loggedUser.role=='Employee'){
        this.router.navigate(['/employee/appointment-detail/', userModel.id]);
      }
      if(this.loggedUser.role=='Admin'){
        this.router.navigate(['/admin/appointment-detail/', userModel.id]);
      }
      if(this.loggedUser.role==='User'){
        this.router.navigate(['/user/appointment-detail/', userModel.id]);
      }
    }
  }

  getweeks(currentDate) {
    this.dayAndmonth = [];
    this.weekno = Math.ceil(this.date.date() / 7);
    for (var i = 0; i <= 6; i++) {
      var day_month = moment(currentDate).add(i, 'days');
      this.dayAndmonth.push({ day_month });
    }
  }

  toggleFilter() {
    this.filterOpen = !this.filterOpen;
  }

  buttonClicked(day_months) {
    this.activeAppointments = [...this.activeAppointmentsCopy];
    this.activeAppointments = this.activeAppointments.filter((appointment) => {
      let appointments = isSameDay(new Date(day_months.toDate()), new Date(appointment.appointment.startTime.toDate()));
      return appointments;
    });

  }

  //filters the timeline
  onTimeframeChange() {
    this.activeAppointments = [...this.activeAppointmentsCopy];

    if (this.appointmentTimelineFilter === 'allTime') {
      this.activeAppointments = [...this.activeAppointmentsCopy];
    }
    if (this.appointmentTimelineFilter === 'thisYear') {
      this.activeAppointments = this.activeAppointments.filter((appointment) => {
        return isSameYear(new Date(appointment.appointment.startTime.toDate()), new Date());
      });
    }

    if (this.appointmentTimelineFilter === 'thisWeek') {
      this.activeAppointments = this.activeAppointments.filter((appointment) => {
        return isSameWeek(new Date(appointment.appointment.startTime.toDate()), new Date());
      });
    }

    if (this.appointmentTimelineFilter === 'thisMonth') {
      this.activeAppointments = this.activeAppointments.filter((appointment) => {
        return isSameMonth(new Date(appointment.appointment.startTime.toDate()), new Date());
      });
    }

    if (this.appointmentTimelineFilter === 'today') {
      this.activeAppointments = this.activeAppointments.filter((appointment) => {
        return isToday(new Date(appointment.appointment.startTime.toDate()));
      });
    }


  }

  //select Employee to view their schedule
  selectEmployee(employee){

    this.empId = employee.value;
    this.getEmployeeSchedule()
    if(this.empId != ''){
    this.userService.getUserById(this.empId).valueChanges().subscribe((employee) => {
      if(employee && employee.length != 0){
        this.selectedEmployee = employee[0];
      }
    })
  }else{
    this.selectedEmployee = false;
  }
  }

  //creates the appointment to viewed on the appointment
  createEvent(appointment) {
    let xx = new Date(format(appointment.startTime.toDate(), "yyyy-MM-dd'T'HH:mm"))
    let yy = new Date(format(appointment.endTime.toDate(), "yyyy-MM-dd'T'HH:mm"))

    const data = {
      // start: new Date(format(appointment.startTime.toDate(), "yyyy-MM-dd'T'HH:mm")),
      // end: new Date(format(appointment.endTime.toDate(), "yyyy-MM-dd'T'HH:mm")),
      start: xx,
      end: yy,
      title: `${appointment.serviceName}`,
      serviceId: `${appointment.serviceId}`,
      appointmentId: `${appointment.id}`,
      color: { 
        primary:`${this.settings.appointmentBgApptColor ? this.settings.appointmentBgApptColor : '#4c58d9'}`,
        secondary: `${this.settings.appointmentBgApptColor ? this.settings.appointmentBgApptColor : '#4c58d9'}`
      },
      cssClass: 'my-custom-class', 
    };
    return data;

  }

  //creates the Notes Reminders to viewed on the appointment
  createNote(note) {
    const data = {
      start: note.reminderTime ? new Date(note.reminder + 'T' + note.reminderTime) : new Date(note.reminder),
      end: note.reminderTime ? addMinutes(new Date(note.reminder + 'T' + note.reminderTime), 15) : new Date(note.reminder),
      title: `<p class="${this.settings.appointmentFontSize}"> ${this.truncateHTML(note.Message, '23')} </p>`,
      appointmentId: `${note.id}`,
      isNote: true,
      allDay: note.reminderTime ? false : true,
      color: {
        primary:`${this.settings.appointmentBgNoteColor ? this.settings.appointmentBgNoteColor : '#79e868'}`,
        secondary: `${this.settings.appointmentBgNoteColor ? this.settings.appointmentBgNoteColor :'#79e868'}`
      },
      cssClass: 'my-custom-class' ,
    };
    return data;
  }

  //creates the tasks to viewed on the appointment
  addTask(task) {
    const data = {
      start: task.reminderTime ? new Date(task.reminder + 'T' + task.reminderTime) : new Date(task.reminder),
      end: task.endReminderTime ?  addMinutes(new Date(task.endReminder + 'T' + task.endReminderTime),15)  : new Date(task.endReminder),
      title: `<p class=" ${this.settings.appointmentFontSize} "> Task :- ${this.truncateHTML(task.taskDescription, '23')} </p>`,
      appointmentId: `${task.id}`,
      isTask: true,
      allDay: task.reminderTime && task.endReminderTime ? false : true,
      color: {
        primary:`${this.settings.appointmentBgTaskColor ? this.settings.appointmentBgTaskColor : '#ff3d7f'}`,
        //primary: '#e3bc08',
        secondary: `${this.settings.appointmentBgTaskColor ? this.settings.appointmentBgTaskColor : '#ff3d7f'}`,
      },
      cssClass: 'my-custom-class ',
      actions: this.actions,

    };
    return data;
  }

  handleEvent(action: string, event :CalendarEvent): void {
    
    if (action == 'Delete') {
      if (this.eventEditObj.isTask) {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.taskService.deleteTaskById(this.eventEditObj.appointmentId);
        this.toast({ html: 'The task has been deleted successfully', classes: 'red', type: 'error' });
        this.closeDeleteModal();
      } 
    }
    if (action === 'Edit') {
       if(this.eventEditObj.isTask){
        var reminderTimeEdit = this.convertTime12to24(this.reminderTime.value);
        var endReminderTimeEdit = this.convertTime12to24(this.endReminderTime.value);
        this.taskGlobalEdit.taskDescription = this.taskDescription.value;
        this.taskGlobalEdit.reminder = this.reminder.value;
        this.taskGlobalEdit.endReminder = this.endReminder.value;
        this.taskGlobalEdit.reminderTime = reminderTimeEdit;
        this.taskGlobalEdit.endReminderTime = endReminderTimeEdit;
        this.taskService.updateTask(this.taskGlobalEdit);
        this.toast({ html: 'The task has been updated successfully', classes: 'green', type: 'success' });
        this.cancelTaskModal();
        //this.tasksForm.reset();
        this.cancelTaskModal();
      }
    }
  }

  openDeleteModal() {
    this.deleteModalOpen = true;
    this.cancelTaskModal();
  }
  closeDeleteModal() {
    this.deleteModalOpen = false;
    this.tasksForm.reset();
  }
  openEditModal(event) {
    this.eventEditObj = event;
    // if (this.eventEditObj.isTask) {
      this.openCreateTaskModal = true;
      this.nonEditable = true;
      this.taskUnsubscribe = this.taskService.getTasksById(this.eventEditObj.appointmentId).valueChanges().subscribe((task: any) => {
        if (task && task.length != 0) {
          this.taskGlobalEdit = task[0];
          var reminderTime = this.convertTime24to12(this.taskGlobalEdit.reminderTime);
          var endReminderTime = this.convertTime24to12(this.taskGlobalEdit.endReminderTime);
          this.tasksForm.patchValue({
            taskDescription: this.taskGlobalEdit.taskDescription,
            reminderTime: reminderTime,
            reminder: this.taskGlobalEdit.reminder,
            endReminder: this.taskGlobalEdit.endReminder,
            endReminderTime: endReminderTime,
          })
        }
        this.taskUnsubscribe.unsubscribe();
      })
    // }
  }

  closeEditModal() {
    this.editModalOpen = false;
  }

  getEmployeeSchedule() {
    this.loading = true;
    if(this.empId == ''){
    this.appointmentService.getAllAppointments().valueChanges().subscribe(s => {
      if (s.length > 0) {
        this.appointmentsList = s;
        this.beforeTimeline = [...s];
        const today = new Date().getTime();
        s.forEach((appt: any) => {
          this.userService.getUserById(appt.employeeId).valueChanges().subscribe(user => {
            // if (user.length > 0 && appt.endTime.toDate().getTime() >= today) {
            if (user.length > 0) {
              this.activeAppointments.push({ user: user[0], appointment: appt });
              this.activeAppointmentsCopy.push({ user: user[0], appointment: appt });
            }
          });
          return;
        });

        this.events = [...this.events];
        this.recreateEventsList();
        this.cdr.detectChanges();

      } else {
        this.appointmentsList = [];
      }
    });

    this.taskService.getTasks().valueChanges().subscribe((tasks: any) => {
      if (tasks.length > 0) {
        this.tasks = tasks;
        this.recreateEventsList();
        this.events = [...this.events];
        this.cdr.detectChanges();
      }
    })
            
    this.userNotesService.getNotesByAdminId(this.currentUser.uid).valueChanges().subscribe((notes: any) => {
      if (notes && notes.length > 0) {
        this.notes = notes;
        this.recreateEventsList();
        this.events = [...this.events];
        this.cdr.detectChanges();
      }
    })

  }else{
    this.appointmentService.getAppointmentByEmployeeId(this.empId).valueChanges().subscribe((appt) => {
      if(appt && appt.length){
        this.appointmentsList = appt;
        this.recreateEventsList();
        this.events = [...this.events];
        this.cdr.detectChanges();
      }else{
        this.appointmentsList = [];
        this.recreateEventsList();
      }
    })
    this.taskService.getTasksMadeTo(this.empId).valueChanges().subscribe((taskEmp) => {
      if(taskEmp && taskEmp.length != 0){
      this.tasks = taskEmp;
      this.notes = [];
      this.recreateEventsList();
      this.events = [...this.events];
      this.cdr.detectChanges();  
      }else{
        this.tasks = [];
        this.recreateEventsList();
      }
    })

    this.notes = [];
  }
  

  this.appointmentService.getAllAppointments().valueChanges().pipe(
    switchMap(appointments => {
      if (appointments.length === 0) {
        return [];
      }
  
      this.totalAppointment = appointments.length;
      this.allAppointment = [...appointments];
      this.appointments = appointments;
  
      this.todayAppointment = appointments.filter((appointment: any) =>
        appointment.startTime.toDate() === this.date
      ).length;
  
      this.upcomingAppointments = appointments.filter((appointment: any) =>
        appointment.startTime.toDate() > this.date
      );
  
      return this.scheduleService.getScheduleByEmployeeId(this.employeeId).valueChanges();
    }),
    switchMap(schedules => {
      if (!schedules || schedules.length === 0) {
        return [];
      }
  
      this.loading = false;
      this.employeeSchedule = schedules[0];
  
      return this.empService.getServicesWithTime().valueChanges();
    })
  ).subscribe((services: any) => {
    if (services.length > 0) {
      this.employeeServices = services;
    }
  
    this.loading = false;
    this.cdr.detectChanges();
  });
  

    this.loading = false;


  }

  recreateEventsList() {
    this.events = [];
    this.appointmentsList.forEach(appointment => {
      const event = this.createEvent(appointment);
      this.events.push(event);
    });

    this.notes.forEach(note => {
     
      if (note.reminder != '') {
        const noteC = this.createNote(note);
        this.events.push(noteC);
      }
    })
    this.tasks.forEach(task => {
      if (task.reminder != '') {
        const taskCopy = this.addTask(task);
        this.events.push(taskCopy);
      }
    });

    this.cdr.detectChanges();
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  cancelTaskModal() {
    this.openCreateTaskModal = false;
    this.tasksForm.reset();
   
  }

  clickReminder() {
    this.isAddNote = true;
  }
  clickAppointment() {
    this.isAddNote = false;
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  //to here

  eventClicked({ event }): void {
    if (event.isNote) {
      this.userNotesService.getNoteById(event.appointmentId).valueChanges().subscribe((note: any) => {
        if (note.length > 0) {
          this.router.navigate(['admin/client-detail', note[0].userId]);
        }
      });
    } else if (event.isTask && !(this.deleteModalOpen)) {
      this.taskEdit = this.taskService.getTasksById(event.appointmentId).valueChanges().subscribe((tasks: any) => {
        if (tasks && tasks.length > 0) {
          this.openEditModal(event);
          this.openCreateTaskModal = true;

        }
        this.taskEdit.unsubscribe();
      })
    }
    else {
      
      this.empService.getServiceById(event?.serviceId).valueChanges().subscribe(service => {
        if (service) {
          if (service.length > 0) {
            this.selectedService = service[0];
          }
        }
      });

      this.appointmentService.getAppointmentById(event.appointmentId).valueChanges().subscribe((appointment: any) => {
        if (appointment) {
          if (appointment.length > 0) {
            this.selectedAppointment = appointment[0];
            this.userService.getUserById(appointment[0].userId).valueChanges().subscribe((user) => {
              if (user.length > 0) {
                this.appointmentMadeBy = user[0];
                // $('#appointmentDetailsModal').modal('open');
              }
            });

            this.cdr.detectChanges();
          }
        }
      });
      if(this.loggedUser){
        if(this.loggedUser.role==='Admin'){
          this.router.navigate(['/admin/appointment-detail/', event.appointmentId]);
        }
        if(this.loggedUser.role==='Employee'){
          this.router.navigate(['/employee/appointment-detail/', event.appointmentId]);
        }
      }
    }

  }

  hourSegmentClicked(date: Date): void {
    this.selectedDayViewDate = date;
    this.openCreateTaskModal = true;
    this.eventEditObj = {};

    var dateFormat = this.formatDate(this.selectedDayViewDate.date);

    var hours = this.selectedDayViewDate.date.getHours() ; // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    if(Math.floor(hours / 10) == 0){
      hours = '0' + hours
    }
    var minutes = this.selectedDayViewDate.date.getMinutes() ;
    if(minutes == 0){
      minutes = minutes + '0';
    }
    var timeFormat = hours + ":" + minutes + " " + AmOrPm;

    this.setStartTime(timeFormat);

    this.tasksForm.patchValue({
      reminder: dateFormat,
      reminderTime: timeFormat,
      endReminder: dateFormat,

    });

  }

  //saves the tasks
  createTask(model) {
    const timeStamp = new Date();

    var reminderTime = this.convertTime12to24(model.reminderTime);
    var endReminderTime = this.convertTime12to24(model.endReminderTime);

    this.taskService.saveTasks({
      id: '',
      taskDescription: model.taskDescription,
      timeStamp,
      reminder: model.reminder,
      finished: false,
      taskMadeBy: this.currentUser.uid,
      taskMadeFor : this.empId != '' ? this.empId : this.currentUser.uid,
      reminderTime: reminderTime,
      endReminderTime: endReminderTime,
      endReminder: model.endReminder
    });

    this.cancelTaskModal();
    //this.tasksForm.reset();


  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  checkDateForAppointment(startTime, endTime) {
    const l = this.appointmentsList.filter(a => endTime.getDay() === a.startTime.toDate().getDay());
    const unBookableTimes = l.filter(a => isAfter(a.startTime.toDate().getTime(), startTime.getTime())
      && isBefore(a.startTime.toDate().getTime(), endTime.getTime()));
    return unBookableTimes;

  }

  beforeViewRender(body: CalendarWeekViewBeforeRenderEvent): void {
    if (this.employeeSchedule && this.employeeSchedule.schedule.length > 0) {
      const availableDays = this.employeeSchedule.schedule.filter(day => !day.unAvailable && day.day !== undefined);
      body.hourColumns.forEach(hourCol => {
        const dayIndex = hourCol.date.getDay();
        const day = availableDays.find(availableDay => availableDay.day === dayIndex);
        if (day) {
          hourCol.hours.forEach(hour => {
            hour.segments.forEach(segment => {
              if (segment.date.getDay() === 2) {
                segment.cssClass = 'bg-pink';
              } else {
                segment.cssClass = 'unavailable';
              }
            });
          });
        }
      });
    }
  }

  //this is to put the input values uneditable 
  editTask(){
    this.nonEditable = false;
  }

  //toggle
  toggleView() {
    this.isCalendarView = !this.isCalendarView;
  }


  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }

  //next
  increment(): void {

    if(this.daysInWeek == 7){
    this.changeDate(addPeriod(this.view, this.viewDate, 1));
    }else if(this.daysInWeek == 1){
      this.changeDate(addPeriod(this.viewday, this.viewDate, 1));
    }

  }

  //previous
  decrement(): void {
    if(this.daysInWeek == 7){
    this.changeDate(subPeriod(this.view, this.viewDate, 1));
    }else if(this.daysInWeek == 1){
      this.changeDate(subPeriod(this.viewday, this.viewDate, 1));
    }
  }


  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    this.prevBtnDisabled = !this.dateIsValid(
      endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
    );
    this.nextBtnDisabled = !this.dateIsValid(
      startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
    );
    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  //today
  today(): void {
    this.changeDate(new Date());
  }

  //sets the view to month, week or day
  setView(view: CalendarView) {
    this.view = view;
  }

  //formats the start time 
  setStartTime(item){
    this.timesEnd = [...this.times.slice(this.times.indexOf(item)),]
    item = item.trim();
    const timeSplited = item.split(':');
    this.reminderTime.patchValue(item);
    let endingTime;
    const timeSplitedAm = timeSplited[1].split(' ')

    if(timeSplitedAm[0] <= 9){
      endingTime = (timeSplited[0]) + ':' + '0' + (parseInt(timeSplitedAm[0])+5).toString() + ' ' +timeSplitedAm[1];
      this.endReminderTime.patchValue(endingTime);
    }

    else if(timeSplitedAm[0] <= 55){
      endingTime = (timeSplited[0]) + ':' + (parseInt(timeSplitedAm[0])+5).toString() + ' ' +timeSplitedAm[1];
      this.endReminderTime.patchValue(endingTime);
    }

    else if(timeSplitedAm[0] > 55 && timeSplited[0] < 11){
      endingTime = (parseInt(timeSplited[0])+1).toString() + ':' + (parseInt(timeSplitedAm[0])+4).toString() + ' ' +timeSplitedAm[1];
    }
   
    if((timeSplited[0] == 12 || timeSplited[0] == 11) && timeSplitedAm[0] > 55){
      const timeLetterSplit = item.split('');
      const aOrpIndex = timeLetterSplit.length - 2;
      // replace am with pm and vise versa
      if(timeLetterSplit[aOrpIndex]==='A' || timeLetterSplit[aOrpIndex]==='a' ){
        timeLetterSplit[aOrpIndex] = 'P';
      } else if(timeLetterSplit[aOrpIndex]==='P' || timeLetterSplit[aOrpIndex]==='p'){
        timeLetterSplit[aOrpIndex] = 'A'
       }
      let combinedLetterSplit = timeLetterSplit.join('').split(':');
      if(combinedLetterSplit[0]==11 && timeSplitedAm[0] > 55){
        combinedLetterSplit[0] = '12';
        endingTime = combinedLetterSplit[0] +':'+ combinedLetterSplit[1];
      } else if(combinedLetterSplit[0]==12 && timeSplitedAm > 55){
        combinedLetterSplit[0] = '01';
        endingTime = combinedLetterSplit[0] +':'+ combinedLetterSplit[1];
      }else if((combinedLetterSplit[0]==11 || combinedLetterSplit[0] == 12) && timeSplitedAm[0] <= 9){
        endingTime = combinedLetterSplit[0] + ":" + '0' + (parseInt(combinedLetterSplit[1])+5).toString() +' ' + timeSplitedAm[1];

      } else if(combinedLetterSplit[0]==11 && timeSplitedAm[0] <= 55){
        endingTime = combinedLetterSplit[0] + ":" + (parseInt(combinedLetterSplit[1])+5).toString();
      }
      else if(combinedLetterSplit[0]==12 && timeSplitedAm[0] <= 55){
        endingTime = combinedLetterSplit[0] + ":" + (parseInt(combinedLetterSplit[1])+5).toString();
      }
      this.endReminderTime.patchValue(endingTime);
    }
  }

  //to render it on the modal patchs the changed value
  setEndTime(item){
    this.endReminderTime.patchValue(item);
  }

  //changes the end startdate addes one day to the startdate
  dateChangeReminderStartDate() {
    this.trackStartReminderDate = $('#reminder').val();
    if (this.tasksForm) {
      this.tasksForm.patchValue({ reminder: $('#reminder').val() }); }
    const reminder = new Date(this.trackStartReminderDate);
    const minEndDate = new Date();
    minEndDate.setDate(reminder.getDate() + 1);
  this.tasksForm.patchValue({endReminder: this.reminder.value});
  }

  //converts time from 12 hours to 24
  convertTime12to24 = time12h => {
    const [time, modifier] = time12h.split(" ");
   
    let [hours, minutes] = time.split(":");
   
    if (hours === "12") {
      hours = "00";
    }
   
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
      if(Math.floor(hours / 10) == 0){
        hours = '0'+ hours;  
      }
    }
    return `${hours}:${minutes}`;
  };

  //converts time from 24 hours to 12
  convertTime24to12(time24){
    var hours = time24.slice(0,-3) ; // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    if(Math.floor(hours / 10) == 0){
      hours = '0' + hours

    }
    var minutes = time24.slice(-2) ;
    var timeFormat = hours + ":" + minutes + " " + AmOrPm;
    return timeFormat;
  }

  syncGoogle(){
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  
  /*updateCalendarEvents(
    viewRender:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ): void {
    this.viewRender = viewRender;

    if (
      !this.viewPeriod
       ||
      !moment(this.viewPeriod.start).isSame(viewRender.period.start) ||
      !moment(this.viewPeriod.end).isSame(viewRender.period.end)
    ) {
      this.viewPeriod = viewRender.period;
      this.events = [];
      this.recurringEvents.forEach((event) => {
        const rule: RRule = new RRule({
          ...event.rrule,
          dtstart: moment(viewRender.period.start).startOf('day').toDate(),
          until: moment(viewRender.period.end).endOf('day').toDate(),
        });
        const { title, color } = event;

        rule.all().forEach((date) => {
          this.events.push({
            title,
            color,
            start: moment(date).toDate(),
          });
        });
      });

    }
  }*/
    
  
  /*beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-open';
      }
    });
  }*/

    // sortTime(arr) {
  //   return arr.reduce((a, b) => a <= b ? b : a);
  // }

  // sortTimeAscending(arr) {
  //   return arr.reduce((a, b) => a >= b ? b : a);
  // }

  // toggleRemind() {
  //   this.remindMe = !this.remindMe;
  // }

  get service() { return this.createAppointmentForm.get('service'); }
  get startTime() { return this.createAppointmentForm.get('startTime'); }
  get endTime() { return this.createAppointmentForm.get('endTime'); }
  get taskDescription() { return this.tasksForm.get('taskDescription'); }
  get reminder() { return this.tasksForm.get('reminder'); }
  get reminderTime() { return this.tasksForm.get('reminderTime'); }
  get endReminder() { return this.tasksForm.get('endReminder'); }
  get endReminderTime() { return this.tasksForm.get('endReminderTime'); }
  get subscriber() { return this.tasksForm.get('subscriber'); }


  //calendar
  login(){
    this.auth.handleSignIn();
  }
  logout(){
    this.auth.handleSignOut();
  }
  insertEvent(){
    this.auth.createEvent();
  }
  getCalendar(){
    this.auth.getCalendar();
  }
  navigateToSettings(routeTo){
    if(this.loggedUser.role=='Admin'){
      this.router.navigate([`/admin/${routeTo}`])
    }
    if(this.loggedUser.role=='Employee'){
      this.router.navigate([`/employee/${routeTo}`])
    }
  }
  navigateToServices(){
    if(this.loggedUser){
      if(this.loggedUser.role=='Employee'){
        this.router.navigate(['/employee/services']);
      } else if(this.loggedUser.role=='Admin'){
        this.router.navigate(['/admin/services']);
      }
    }
  }



}

//validates the start date
function validateStartDate(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const startDate = c.parent.get('reminder');
  const eventStartDate = new Date(startDate.value);
  const today = new Date();

  if (isAfter(eventStartDate, today)  || isSameDay(eventStartDate, today)){
    return;
  }

  if (isBefore(eventStartDate, today)){
    return { invalidStartDate: true };
  }
}

//validates the start time 
function validateStartTime(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const startDate = c.parent.get('reminder');
  const eventStartDate = new Date(startDate.value);
  const startTime = c.parent.get('reminderTime');
  const today = new Date();

  var hours = today.getHours() ; // gives the value in 24 hours format
  var AmOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = (hours % 12) || 12;
  if(Math.floor(hours / 10) == 0){
    var theHours = '0' + hours
  }else{
    theHours = hours.toString();
  }
  var minutes = today.getMinutes() ;
  if(minutes == 0){
    var theMinutes = minutes + '0';
  }else{
    var theMinutes = minutes.toString();
  }

  if(startTime.value !== null && startTime.value !== undefined){

  var timeSplit = startTime.value.split(':');

  var hoursTwo = timeSplit[0];
  var AmorPmTwo = timeSplit[0] >= 12 ? 'PM' : 'AM';
  hoursTwo = (hoursTwo % 12) || 12;

  
  if(Math.floor(hoursTwo / 10) == 0){
    hoursTwo = '0' + hoursTwo
  }
  var timeSplitTwo = startTime.value.split(':');
  var minutesTwo = timeSplitTwo[1].split(' ')[0];
  }



  if(isSameDay(eventStartDate,today) && (theHours > hoursTwo) && (AmOrPm == AmorPmTwo)){

    return {invalid : true};
  }
  else if(isSameDay(eventStartDate,today) && (theHours == hoursTwo) && (theMinutes > minutesTwo) && (AmOrPm == AmorPmTwo)){
    return {invalid : true};
  }
  else if(isSameDay(eventStartDate,today) && (theHours == hoursTwo) && (theMinutes > minutesTwo) && (AmOrPm != AmorPmTwo) && (AmOrPm == 'PM')){
    return {invalid : true};
  }
  else if(isSameDay(eventStartDate,today) && (theHours > hoursTwo)  && (AmOrPm != AmorPmTwo) && (AmOrPm == 'PM')){
    return {invalid : true};
  }

}

//validates the start and end date like the end date can't be before the start date
 function validateStartAndEndDate(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const startDate = c.parent.get('reminder');
  const endDate = c.parent.get('endReminder');
  const eventStartDate = new Date(startDate.value);
  const eventEndDate = new Date(endDate.value);


  const startTime = c.parent.get('reminderTime');
  const endTime = c.parent.get('endReminderTime');
  const eventStartTime = new Date(startTime.value);
  const eventEndTime = new Date(endTime.value);
  if (isBefore(eventStartDate, eventEndDate)  || isSameDay(eventStartDate, eventEndDate)){
    return;
  }
  if (!isBefore(eventStartDate, eventEndDate)){
    return { invalid: true };
  }
  if (isSameDay(eventStartDate, eventEndDate) && !isBefore(eventStartTime, eventEndTime)){
    return { invalid: true };
  }
}

//validates the start and end time like the end time can't be before the start time
function validateStartTimeAndEndTime(c: AbstractControl): any {
  if(!c.parent || !c){return; }
  const startDate = c.parent.get('reminder');
  const endDate = c.parent.get('endReminder');
  const eventStartDate = new Date(startDate.value);
  const eventEndDate = new Date(endDate.value);
  const startTime = c.parent.get('reminderTime');
  const endTime = c.parent.get('endReminderTime');


  if(startTime.value !== null && startTime.value !== undefined){
  var timeSplit = startTime.value.split(':');
  var hoursTwo = timeSplit[0];
  var AmorPmTwo = timeSplit[0] >= 12 ? 'PM' : 'AM';
  hoursTwo = (hoursTwo % 12) || 12;
  if(Math.floor(hoursTwo / 10) == 0){
    hoursTwo = '0' + hoursTwo
  }
  var timeSplitTwo = startTime.value.split(':');
  var minutesTwo = timeSplitTwo[1].split(' ')[0];
  var timeSplitEnd = endTime.value.split(':');
  var hoursTwoEnd = timeSplitEnd[0];
  var AmorPmTwoEnd = timeSplitEnd[0] >= 12 ? 'PM' : 'AM';
  hoursTwoEnd = (hoursTwoEnd % 12) || 12;
  if(Math.floor(hoursTwoEnd / 10) == 0){
    hoursTwoEnd = '0' + hoursTwoEnd
  }
  var timeSplitTwoEnd = endTime.value.split(':');
  var minutesTwoEnd = timeSplitTwoEnd[1].split(' ')[0];

}

  if (isSameDay(eventStartDate, eventEndDate) && (AmorPmTwo == 'PM' && AmorPmTwoEnd == 'AM') ){
    return { invalid: true };
  }
  else if(isSameDay(eventStartDate, eventEndDate) && (hoursTwo > hoursTwoEnd)){
    return { invalid: true};
  }
  else if(isSameDay(eventStartDate,eventEndDate) && (hoursTwoEnd == hoursTwo) && (minutesTwo > minutesTwoEnd)){
    return {invalid: true};
  }


  
}
