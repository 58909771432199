
<div class="flex bg-gray-100 min-h-screen max-h-max mt-2" 
    [ngClass]="{'flex flex-col' : modelToPreview && view=='mobile', 'flex ' : (modelToPreview && view=='web') ||  !modelToPreview}"
    *ngIf="service">

  <!-- side drawer -->
  <div class="bg-white hidden  h-screen justify-center w-24 hover:w-64 sticky md:display ">
      logo
      <ul class="menu bg-base-100 w-24 p-2 rounded-box">
          <li>
              <a>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
              </a>
          </li>
          <li>
              <a>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
              </a>
          </li>
          <li>
              <a>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                    </svg>
              </a>
          </li>
          <li>
              <a>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                    </svg>
              </a>
          </li>
          <li>
              <a>
                  <i class="fa-solid fa-chart-line"></i>
              </a>
          </li>
          <li>
              <a>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
              </a>
          </li>
          <li>

        </li>
      </ul>
      
  </div>

  <!-- profile cards -->
  <div class="  py-5 w-full space-y-4"
      [ngClass]="{'md:px-4' : modelToPreview && view=='mobile', 'md:px-12' : (modelToPreview && view=='web') ||  !modelToPreview}"
  >
      <div class=" md:mx-auto  md:gap-x-4 gap-y-4  md:space-y-0 "
      [ngClass]="{'md:w-full p-2 flex flex-col' : modelToPreview && view=='mobile', 'md:max-w-7xl p-6 flex' : (modelToPreview && view=='web') ||  !modelToPreview}"
      >
          <!-- list and service about -->
          <div class=" bg-white w-full px-4  py-8 space-y-6 md:mx-auto max-h-max rounded-md " 
          [ngClass]="{'md:max-w-5xl': (!service.hasAppointment && !service.hasPrice && employees.length == 0) || ((!service.hasAppointment && !service.hasPrice && employees.length == 0 ) &&   (modelToPreview && view=='mobile')), 'md:w-2/3': ((service.hasAppointment || service.hasPrice || employees.length > 0) && !modelToPreview), 'md:w-full': ((service.hasAppointment || service.hasPrice || employees.length > 0) && modelToPreview && view == 'mobile'),  'md:px-4' : modelToPreview && view=='mobile', 'md:px-16' : (modelToPreview && view=='web') ||  !modelToPreview}"
          
          >
              <!-- Service list -->
              <div class="border-b-2 text-base md:text-2xl font-bold">
                <!-- {{service.serviceName}} -->
                <div class="break-words text-center ">
                  <span [innerHTML]="service.serviceName | noSanitize"></span>
                </div>
                  <!-- edit -->
                  <div class="float-right text-base break-words md:text-xl font-bold " *ngIf="currentUser  && !modelToPreview">
                    <div class="dropdown dropdown-end z-50" *ngIf="isAdmin ||(permPES && (permPES.createServices || permPES.editServices || permPES.deleteServices))">
                      <label tabindex="0" class="m-1"><i class="material-icons gray-text-600">more_vert</i></label>
                      <ul tabindex="0" class="dropdown-content menu p-2 shadow text-base bg-white rounded-box w-52 font-normal">
                        <li  (click)="navigateEdit(service)" *ngIf="isAdmin || (permPES && permPES.editServices)"><a><i class="material-icons gray-text-600" >edit</i>Edit</a></li>
                        <li (click)="openDeleteModal()" *ngIf="isAdmin || (permPES && permPES.deleteServices)"><a><i class="material-icons gray-text-600" >delete</i>Delete</a></li>
                      </ul>
                    </div>
                  </div>
              </div>

              <div class="flex flex-col space-y-2 " *ngIf="service.imgLink && !service.sharedLink&& (!service.videoLink|| service.videoLink=='')">
                <img loading="lazy" [src]="service.imgLink" class=" md:max-w-6xl w-full rounded-md " alt="Service Image">
              </div>
              <div *ngIf="service.imgLink && service.imgLink!=''">

            
  
                <div class="flex flex-col space-y-2 " *ngIf="service.imgLink && service.sharedLink && (!service.videoLink|| service.videoLink=='')">
                  <a [href]="service.sharedLink" target="_blank" class="hover:cursor-pointer">  
                    <img loading="lazy" [src]="service.imgLink" class=" md:max-w-6xl w-full rounded-md" alt="Service Image">
                  </a>
                </div>
              </div>

              <div *ngIf="service.videoLink && service.videoLink!=''">
                <ng-container *ngIf="!service.imgLink">
                  <video autoplay controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Service Image"></video>
                </ng-container>

                <ng-container *ngIf="service.imgLink">
                  <video autoplay controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Service Image" [poster]="service.imgLink"></video>
                </ng-container>
              </div>

              <!-- Services included -->
              <ng-container *ngIf="service.allServices">

              <div  class="flex flex-col space-y-2 " *ngIf="service.allServices.length != 0">
                <div  *ngFor="let serviceFeature of service.allServices" class=" flex flex-row  items-center rounded-md border-2 ">
                  <div class="flex flex-col w-full ">
                    <div tabindex="0" class="w-full collapse collapse-plus rounded-sm border border-base-300 shadow-sm bg-gray-100 ">
                      <input type="checkbox" class="peer" />
                      <div  class="collapse-title cursor-pointer font-bold text-customTeal" >
                        <div class="break-words">
                        
                            {{serviceFeature.serviceTitle}}
                      
                        </div>
                      </div>
                      <div *ngIf="currentUser"  class="collapse-content break-words">
                        <p>{{serviceFeature.servicePrice | currency: currency:'symbol-narrow'}}</p> 
                        <div class=" break-words">
                          <p class="italic">{{serviceFeature.serviceUnderDescription}}</p>       
                        </div>       
                        <button *ngIf="serviceFeature.hasAppointment==true && checkIfAppointment(serviceFeature.hasAppointment, appSetting.takeAppointment)" class="btn-custom-solid float-right" (click)="BookServiceAppointment(service,serviceFeature)">Book</button>
                      </div>
                      <div *ngIf="!currentUser" class="collapse-content">
                        <p>$ {{serviceFeature.servicePrice}}</p>
                        <div class=" break-words">
                          <p class="italic">{{serviceFeature.serviceUnderDescription}}</p>       
                        </div>   
                        <button *ngIf="serviceFeature.hasAppointment==true && checkIfAppointment(serviceFeature.hasAppointment, appSetting.takeAppointment)" class="btn-custom-solid float-right" (click)="OpenLoginPopup()">Book</button>
                      </div>
                    </div>
                  </div>

           
                </div>
              </div>

            </ng-container>
              <!-- about service -->
              <div class="md:pt-8 space-y-2 break-words">
                  <div class="text-justify text-sm md:text-base" [innerHTML]="service.serviceDescription | noSanitize"></div>
              </div>

          </div>
          <!-- service detail -->
          <div [ngClass]="{'w-full' : ((service.hasAppointment || service.hasPrice || employees.length > 0) &&  (modelToPreview && view=='mobile')) ,'w-full md:w-1/3': (service.hasAppointment || service.hasPrice || employees.length > 0) && ((modelToPreview && view=='web') ||  !modelToPreview) }">
            <div class="flex flex-col  w-full space-y-4 md:space-y-8 md:flex-col " >

              <div class=" h-max bg-white p-4 space-y-6 rounded-md md:p-8 shadow-sm" *ngIf="service.hasAppointment==true || (service.hasPrice && service.price && service.price != '') ">
          
                  <div class="flex flex-col space-y-4">

                      <!-- Price -->
                      <div class="space-y-2" *ngIf=" service.price">
                        <div class="flex space-x-2 items-center" >
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-darkGray" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                        </svg>
                          <p class=" text-base  text-darkGray ">
                            Price 
                        </p>
                        </div>
                        <hr>
                          <div class=" flex flex-row  items-center">
                          
                              <div class="">
                                  <h4 class=" text-base font-bold text-customTeal " *ngIf="service.price && service.price != 'dontInclude'">
                                    {{service.price | currency: currency:'symbol-narrow'}}
                                  </h4>
                              </div>
                          </div>
                      </div>

                      <!-- Duration -->
                      <div class="space-y-2" *ngIf="service.hasAppointment==true">
                        <div class="flex space-x-2 items-center" >
                          <i class="fa fa-hourglass text-darkGray"></i>
                          <p class=" text-base  text-darkGray ">
                            Duration 
                        </p>
                        </div>
                        <hr>
                          <div class=" flex flex-row  items-center">
                            
                              <div class="">
                                  <p class=" text-base font-bold text-customTeal " *ngIf="service.serviceHour || service.serviceMinute ">
                                    <span *ngIf="service.serviceHour">{{service.serviceHour}}h</span>
                                    <span *ngIf="service.serviceMinute"> {{service.serviceMinute}}m</span>
                                  </p>
                              </div>
                          </div>

                          <div *ngIf="currentUser" class="space-y-2 pt-4">
                            <p *ngIf="(service.serviceHour > 0 || service.serviceMinute > 0) && (currentUser.role=='User' || currentUser.role=='Admin' || currentUser.role=='Employee')">
                              <button 
                              *ngIf="appSetting.takeAppointment=='yes'|| appSetting.takeAppointment==true" 
                              type="button" (click)="BookAppointment(service)" 
                                  class="flex items-center rounded bg-customTeal px-6 py-2 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                              
                              Book Appointment
                            </button>
                            </p>
                          </div>
                          <div *ngIf="!currentUser" class="space-y-2">
                            <p>
                              <button type="button" 
                              *ngIf="appSetting.takeAppointment=='yes'|| appSetting.takeAppointment==true" 
                              class="flex items-center rounded bg-customTeal px-6 py-2 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                              (click)="OpenLoginPopup()">
                              Book Appointment
                            </button>
                            </p>
                          </div>
                      </div>
                  </div>
                
              </div>

            <div>
              <p class="text-sm text-darkGray  font-bold pb-3" id="employee_detail" *ngIf="employees.length > 0">Employee Detail</p>
              <div *ngIf="employees.length > 0" class="flex flex-col gap-y-2">
                <div *ngFor="let employee of employees" class="w-full bg-white rounded-md border p-4  border-solid border-gray-200">
                  <div class="  flex  items-center space-x-4">
                    <div class="avatar">
                        <div class="w-12 rounded-full">
                          <img
                          loading="lazy"

                          *ngIf="!employee.profileImgLink" 
            
                          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                          
                          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                          
                          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                          
                          alt="ananymous profile picture."> 

                          <img loading="lazy" *ngIf="employee.profileImgLink" [src]="employee.profileImgLink" />
                        </div>
                    </div>
                    <div class="flex flex-col space-y-2">
                        <p class=" text-base font-bold text-center ">
                            {{employee.GivenName}} {{employee.LastName}}
                        
                        </p>
                        <div  *ngIf="employees.length > 0" >
                          <button (click)="viewEmployeeSchedule = true" (click)="selectedEmployee = employee" class="flex items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-3 h-3 fill-customTeal"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"/></svg>
                            <span class="font-bold text-customTeal text-base">View Schedule </span>
                          </button>
                
                        </div>
                    </div>
                </div>
                </div>
              </div>
            </div>

              <!-- Shared files-->
              <div *ngIf="service.pdfLink" class="">

                <div class="  h-max  bg-white p-8 space-y-6 rounded-md">
                    <p class=" text-sm font-bold text-darkGray">
                      Shared Files
                    </p>
                    <div>
                      <!-- if video -->
                      <!-- <div *ngIf="service.videoLink && service.videoLink!=''">
                        <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo"></video>
                      </div> -->
                      <!-- if PDF -->
                      <div class="mt-4" *ngIf="service.pdfLink && service.pdfLink!=''" >
                        <div *ngIf="service.pdfLink" class="font-bold text-darkGray mb-4">
                          Shared Document
                        </div>
                        <div class=" text-white">
                          <div class=" bg-customTeal p-1">
                            <a class="download-icon" [href]="service.pdfLink" target="_blank" download>
                              <span class="material-icons float-left mr-2">
                                feed
                              </span>
                              <span>Open File</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
            </div>

              </div>
          </div>

          </div>
          </div>
          <!-- comments section -->
          <div *ngIf="service.commentable && currentUser" class="md:max-w-7xl md:mx-auto  md:space-x-4 md:space-y-0 p-8">
              <app-post-comment [post]="service"
              [sectionName]="servicesName"
              postType="service"
              (showCommentDeletedToast)="openDeleteToast()"
              >
              </app-post-comment>
          </div>

  </div>
</div>


<!-- schedule Modal -->
 <!-- On first login setup -->
 <dialog id="my_modal_2" class="modal" [ngClass]="{'modal-open': viewEmployeeSchedule}" >
  <form method="dialog" class="modal-box rounded-md shadow-md max-w-5xl">

    <div class="flex flex-col md:flex-row  space-y-4 md:space-y-0 md:items-center md:space-x-10 " *ngIf="selectedEmployee">
      <div class="flex flex-row gap-x-4  md:flex-col items-center md:space-y-2 md:w-1/4 h-full ">
          <div class="avatar">
              <div class="md:w-24 w-12 rounded-full">
                <img
                loading="lazy"

                *ngIf="!selectedEmployee.profileImgLink" 
  
                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                
                alt="ananymous profile picture."> 

                <img loading="lazy" *ngIf="selectedEmployee.profileImgLink" [src]="selectedEmployee.profileImgLink" />
              </div>
          </div>
          <div>
              <p class=" text-base font-bold text-center ">
                  {{selectedEmployee.GivenName}} {{selectedEmployee.LastName}}
              </p>
          </div>
      </div>


      <div class=" flex flex-col space-y-4 " *ngIf="firstEmpSchedule">

        <p class=" text-base font-bold  text-darkGray ">
          {{selectedEmployee.GivenName}}'s Schedule
        </p>
        <hr>
       <div class="flex flex-wrap gap-6 ">
        <div class=" flex   flex-wrap space-x-4 items-center" *ngFor="let empSchd of firstEmpSchedule">
          <div class="flex space-x-2 items-center">
            <div>
              <i class="material-icons right text-sm">perm_contact_calendar
                </i>
          </div>
          <div class="pl-4">
              <p class=" text-base font-bold ">
                  {{empSchd.day}},{{empSchd.startTime}} - {{empSchd.endTime}}
              </p>
          </div>
          </div>
      </div>
       </div>
      <div class="flex flex-row-reverse md:flex-row gap-x-4 pt-4">
        <button
        type="button"
        class="inline-block rounded justify-end bg-customTeal text-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
        data-te-ripple-color="light"
        (click)="BookAppointment(service, selectedEmployee.uid)">
        <!-- (click)="viewEmployeeSchedule=false"> -->
        Book an Appointment
        </button>

        <button
        type="button"
        class="inline-block rounded justify-end bg-gray-100 border border-solid border-gray-300 text-gray-800 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal  shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
        data-te-ripple-color="light"
        (click)="viewEmployeeSchedule=false">
        Cancel
        </button>
      </div>
    </div>


  </div>
   

    <div class="modal-action">
      <!-- if there is a button in form, it will close the modal -->
    <div class="flex space-x-4  w-full justify-end">
  

    </div>
    </div>
  </form>
</dialog>


<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteService() (cancelDeleteClicked)="deleteModalOpen=false" [deletedFromComponent]=servicesName></app-delete-confirmation>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>



<div class="modal " class="modal" id="signupFirstModal"
[ngClass]="{'modal-open ': signupFirstModalOpen, 'modal-action': !signupFirstModalOpen }">
  <div class="modal-box max-w-xl">
    <form method="dialog">
      <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" (click)="signupFirstModalOpen=false">✕</button>
    </form>
    <div class=" text-center mt-10">
      <div>
        <i class="material-icons  text-5xl">warning</i>
        <br/><br/>
      </div>
      <div class="text-customTeal text-xl md:text-xl text-center px-4">
        Please login or create an account  to book an appointment
      </div>
     
      <br />
      <button [routerLink]="['/login']" class="px-2 py-2 bg-customTeal items-center rounded-md text-white">Go to Login
          Page</button>
      <br />
      <br />
  </div>
  </div>
</div>
