import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { take } from 'rxjs';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeServicesService {
  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore, private authService: AuthService){ }

  createService(model: any){
    const newContent = {
      id: this.db.createId(),
      createdById: model.createdById,
      serviceName: model.serviceName,
      serviceId: model.serviceId,
      serviceDescription: model.serviceDescription,
      serviceTime: model.serviceTime,
      serviceMinute: model.serviceMinute,
      serviceHour: model.serviceHour,
      showEmployees: model.showEmployees,
      assignedTo: model.assignedTo,
      serviceOffers: model.serviceOffers,
      imgLink: model.imgLink,
      videoLink: model.videoLink,
      sharedLink: model.sharedLink,
      shareWithPublic: model.shareWithPublic,
      price: model.price,
      hasPrice: model.hasPrice,
      titleColor: model.titleColor,
      backgroundColor: model.backgroundColor,
      timeStamp: new Date(),
      hasAppointment: model.hasAppointment,
      bufferTime: model.bufferTime,
      allServices: model.allServices,
      hasSubServices: model.hasSubServices,
      commentable: model.commentable,
      reactions: model.reactions,
      comments: model.comments
    };
    return this.db.collection('Services').doc(newContent.id).set(newContent);
  }

  getServiceByUserId(id){
    return this.db.collection('Services', ref => ref.where('createdById', '==', id));
  }

  getAllServices(){
    return this.db.collection('Services');
  }
  getServiceByCategoryId(categoryId){
    return this.db.collection('Services', ref => ref.where('categoryId', '==', categoryId));
  }

  getServiceById(serviceId: any){
    return this.db.collection('Services', ref => ref.where('id', '==', serviceId));
  }
  getServiceByServiceName(serviceName: any){
    return this.db.collection('Services', ref => ref.where('serviceName', '==', serviceName));
  }

  updateService(service: any){
    return this.db.collection('Services').doc(service.id).update(service);
  }
  deleteService(service: any){
    return this.db.collection('Services').doc(service.id).delete();
  }

  saveServiceToEmployee(model: any){
    const newContent = {
      id: this.db.createId(),
      services: model.services,
      userId: model.userId
    };
    return this.db.collection('EmpServices').doc(newContent.id).set(newContent);
  }

  updateEmployeeService(service: any){
    return this.db.collection('EmpServices').doc(service.id).update(service);
  }

  getServiceByEmpId(userId: any){
    return this.db.collection('EmpServices', ref => ref.where('userId', '==', userId));
  }

  getAllEmpServices(){
    return this.db.collection('EmpServices');
  }

  getPublicServices(){
    return this.db.collection('Services', ref => ref.where('shareWithPublic', '==', true));
  }
  getServicesWithTime(){
    return this.db.collection('Services', ref => ref.where('serviceTime', '!=', 0));
  }
  addEmployeeToService(id:any, employeeId){
    this.documentRef = this.db.doc('Services/'+ id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if(i){
        i.assignedTo.push(employeeId);
        this.updateService(i);
      }
    })
  }
  removeEmployeeToService(id: any, employeeId){
    this.documentRef = this.db.doc('Services/'+ id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if(i && i.assignedTo.includes(employeeId)){
        const index = i.assignedTo.indexOf(i.assignedTo.find(selected => selected === id));
        i.assignedTo.splice(index, 1);
        this.updateService(i);
      }
    })
  }

}
