import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PostsService } from './../../../../services/firebase/posts.service';
import { Component, OnInit } from '@angular/core';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.css']
})
export class EditBlogComponent implements OnInit {

  blog: any;
  blogId: any;
  editBlogForm: UntypedFormGroup;
  binDoc: File;
  isMobile = false;
  mobileBase64Image: any;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  selectedFilesForDisplay: any = [];
  binDocList: any = [];
  currentUser: any;
  id: any;
  base64Image: any;
  imgErrorMsg;
  // modal
  deleteModalOpen = false;
  cancelModalOpen = false;
  attachmentFile: any;
  loading = false;
  blogsName = 'Blogs';
  isAdmin: boolean = false;
  isUser: boolean = false;
  perm: any;
  permission: any;
  preview = false;
  blogToPreview: any;
  editModalOpen = false;
  mediaType: any;
  showLogoInput = false;
  attachedVideo: any;
  cropModalOpen = false;
  imageChangedEvent: any = '';

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  useBase64=false

  blogLinkClicked = false;
  uploadFileModalOpen = false;
  mediaList = [];
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };  
  constructor(private postsService: PostsService,
              private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private location: Location,
              private authService: AuthService,
              private permissionService: PermissionService,
              private router: Router,
              private productsServiceMenuService: ProductsServiceMenuService,
              private helperervice: HelperService) { 
                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {

                  this.isMobile = true;
                }
              }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.blogId = params.get('id');
    });

    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if (this.currentUser.role === 'Admin'){
        this.isAdmin = true;
      }
      if (this.currentUser.role === 'User') {
        this.isUser = true;
      }
      if (this.currentUser.role === 'Employee') {
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
          if (perm.length > 0){
            this.perm = perm[0];
            //this.permission = this.perm.permission[0];

            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];
          }
        });
      }
    }

    this.editBlogForm = this.fb.group({
      postTitle: ['',Validators.required],
      message: ['', Validators.required],
      sharedLink: [''],
      commentable: [true],
      readingTime: [1],
      shareWith: ['User'],
      opened: ['']
    });

    this.postsService.getPostById(this.blogId).valueChanges().subscribe((blog) => {
      if (blog.length > 0) {
        this.blog = blog[0];
        this.blog.mediaLink = this.blog.mediaLink ? this.blog.mediaLink : '';
        this.blog.videoLink = this.blog.videoLink ? this.blog.videoLink : '';
        this.blog.pdfLink = this.blog.pdfLink ? this.blog.pdfLink : '';
        this.blog.opened = this.blog.opened ? this.blog.opened : [];
        
        this.blogToPreview = blog[0];
        this.editBlogForm.patchValue({
          postTitle: this.blog.postTitle,
          message: this.blog.message,
          commentable: this.blog.commentable,
          shareWith: this.blog.shareWith,
          readingTime: this.blog.readingTime,
          sharedLink: this.blog.sharedLink ? this.blog.sharedLink : ''
        });
      }
    });

    this.productsServiceMenuService.blogsName.subscribe((blogsname) => {
      this.blogsName = blogsname;
    });
  }

  get message(){ return this.editBlogForm.get('message'); }
  get postTitle(){ return this.editBlogForm.get('postTitle'); }
  get shareWith(){ return this.editBlogForm.get('shareWith'); }
  get commentable(){ return this.editBlogForm.get('commentable'); }
  get mediaLink(){ return this.editBlogForm.get('mediaLink'); }
  get sharedLink() { return this.editBlogForm.get('sharedLink'); }
  get opened () { return this.editBlogForm.get('opened'); }

  updateBlog(value) {
    this.editModalOpen = false;
    this.blog.postTitle = value.postTitle;
    this.blog.sharedLink = value.sharedLink;
    this.blog.message = value.message;
    this.blog.shareWith = value.shareWith;
    this.blog.timeStamp = new Date();
    this.blog.commentable = value.commentable;
    this.blog.readingTime = this.calculateWordCount(value.message);
      
    if(this.mediaList.length > 0){
      this.uploadFileModalOpen = true;
    }
    if(this.mediaList.length === 0){
      this.saveBlogUpdate(this.blog);
    }
  }

  saveBlogUpdate(blogDetail){
    this.postsService.editPost(blogDetail).then(() => {
      this.toast({html: 'Successfully  updated a blog!', classes: 'green', type: 'success'});
    }).catch(err => {
      this.toast({html: 'Can\'t update blog!', classes: 'red', type: 'failure'});
    });
  }       
                                                                              
  archivePost() {
    this.closeDeleteModal();
    this.postsService.archivePost(this.blog.id).then(() => {
    this.toast({html: 'Blog deleted successfully.', type: 'failure', redirectBack: true});
    });
  }

  cancelFile(i: any) {
    this.selectedFilesForDisplay.splice(i, 1);
  }

  // identify type of input given
  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    } else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else {
      return false;
    }
  }

  onSelectFile(file: string){
    this.selectedFilesForDisplay = [];
    this.selectedFilesForDisplay.push(file);
  }

  // when file is selected
  onSelectFiles(files: FileList) {
    const FileSize = files[0].size / 1024 / 1024; // in MiB
    if (FileSize > 300) {
      this.toast({ html: 'Selected file size exceeds 300 MiB.', classes: 'red', type: 'failure' });
      return;
    }

    const ext = this.validateInput(files[0].name);

    if (!ext){
      return;
    }
    const attachment = {type: ext, name: files[0].name };
    if (ext === 'image'){
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.base64Image = reader.result as string;
        attachment['link'] = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
        this.selectedFilesForDisplay.push(attachment);
      };
    } else {
      attachment['link'] = files[0];
      this.selectedFilesForDisplay.push(attachment);
      if (ext === 'video'){
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          this.attachmentFile = reader.result as string;
        };
      }
    }
  }
  onImageUpload(response:ImageUploaderResponseModel) {
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      this.mediaLink.patchValue('');
      return
    }

    this.binDoc = response.binDoc;
    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64=true
    this.cropModalOpen = true;

 }

  handleVideoInput(files: FileList) {
    this.base64Image = undefined;
    this.binDoc = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachedVideo = reader.result as string;
    };
    this.mediaList.push({type:'Video', file: this.binDoc});
  }

  validateImage(name: any) {
   return this.helperervice.validateImage(name);
  }

  dataURItoBlob(dataURI) {
    return this.helperervice.dataURItoBlob(dataURI);
  }

  backClicked() {
    this.location.back();
  }

  removeImage() {
    this.blog.mediaLink = '';
    this.attachmentFile = '';
    this.base64Image = '';
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Image'){
        return file;
      }
    })
  }

  removeVideo(){
    this.attachedVideo = undefined;
    this.binDoc = undefined;
    this.blog.videoLink = '';
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Video'){
        return file;
      }
    })
  }

  additionalLink(){
    this.blogLinkClicked = !this.blogLinkClicked;
  }

  calculateWordCount(text) {
    return this.helperervice.calculateWordCount(text);
  }

  openDeleteModal(){
    this.deleteModalOpen = true;
  }

  closeDeleteModal(){
    this.deleteModalOpen = false;
  }

  closeCancelModal(){
    this.cancelModalOpen = false;
  }

  closeEditModal(){
    this.editModalOpen = false;
  }
  
  closeCroppingModal(){    
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  uploadPhoto() {
    this.mediaType = 'Photo';
  }

  uploadVideo() {
    this.mediaType = 'Video';
  }

  fileChangeEvent(event){
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }
  
  getTheUrlsSaved(blogUrls){
    this.returnedMediaLinks = blogUrls;
    this.uploadFileModalOpen = false;
    this.blog.mediaLink = this.returnedMediaLinks.imageURL != '' ? this.returnedMediaLinks.imageURL : this.blog.mediaLink;
    this.blog.videoLink = this.returnedMediaLinks.videoUrl != '' ? this.returnedMediaLinks.videoUrl : this.blog.videoLink;
    this.blog.pdfLink = this.returnedMediaLinks.pdfUrl != '' ? this.returnedMediaLinks.pdfUrl : this.blog.pdfLink;

    this.saveBlogUpdate(this.blog);
  }

  processCroppedImage(event){
    this.cropModalOpen = false;
    this.base64Image = event;
    let base64result = this.base64Image.split(',')[1];
    this.useBase64 = false;
    const img = new ImageModel();
    if(this.isMobile){
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({type:'Image', file: img});
    }else{
      img.imgBase64 = base64result; 
      this.mediaList.push({type:'Image', file: img});
    }
  }

  previewChanges(){
    this.blogToPreview.postTitle = this.postTitle.value;
    this.blogToPreview.mediaLink = this.blog.mediaLink;
    this.blogToPreview.message = this.message.value;
    this.blogToPreview.shareWith = this.shareWith.value;
    this.blogToPreview.commentable = this.commentable.value;
    if (this.base64Image){
      this.blogToPreview.mediaLink = this.base64Image;
    }
    if (this.attachmentFile){
      this.blogToPreview.mediaLink = this.attachmentFile;
    }
    this.preview = true;
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.router.navigate(['/admin/blogs']);
    }, 2000);
  }
}

