import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import 'firebase/firestore';
@Injectable({
  providedIn: 'root'
})
export class NewsEventService {
  constructor(private db: AngularFirestore){ }
  async createEvent(model: any){
    const newContent = {
      id: this.db.createId(),
      createdById: model.createdById,
      eventTitle: model.eventTitle,
      titleColor: model.titleColor,
      eventPlace: model.eventPlace,
      eventStartDate: model.eventStartDate,
      eventEndDate: model.eventEndDate,
      eventStartTime: model.eventStartTime,
      eventEndTime: model.eventEndTime,
      description: model.description,
      backgroundColor: model.backgroundColor,
      isOnline: model.isOnline,
      imgLink: model.imgLink,
      videoLink: model.videoLink,
      sharedLink: model.sharedLink,
      shareToFriends: model.shareToFriends,
      shareWith: model.shareWith,
      archived: false,
      timeStamp: new Date(),
      price: model.price,
      users:model.users,
      commentable: model.commentable,
      reactions: model.reactions,
      comments: model.comments,
      registerRequired: model.registerRequired,
      maxRegistration: model.maxRegistration,
      opened : model.opened ? model.opened : [],
      latitude: model.latitude ? model.latitude : '',
      longitude: model.longitude ? model.longitude : '',
      eventLink : model.eventList ? model.eventList : '',
      invitedSubs : model.invitedSubs ? model.invitedSubs : '',
    };
    await this.db.collection('Events').doc(newContent.id).set(newContent);
    return newContent.id;
  }

  updateReactionOfAnEvent(event){
    return this.db.collection('Events').doc(event.id).update({reactions: event.reactions});
  }
  updateComments(event){
    return this.db.collection('Events').doc(event.id).update({comments: event.comments});
  }
  registerUserEvent(model){
    const registered = {
      id: model.id,
      uid: model.uid,
    }
    return this.db.collection('UserRegisteredEvent').doc(registered.id).set(registered);
  }
  updateRegisteredUserEvent(model){
    const registered = {
      id: model.id,
      uid: model.uid,
    }
    return this.db.collection('UserRegisteredEvent').doc(registered.id).update(registered)
  }
  getAllRegisteredUsers(){
    return this.db.collection('UserRegisteredEvent');
  }
  getAllRegisteredUserByEventId(eventId){
    return this.db.collection('UserRegisteredEvent', ref => ref.where('id', '==', eventId));
  }

  deleteRegisteredUser(eventId: string, userId: string) {
    const eventDoc = this.db.collection('UserRegisteredEvent').doc(eventId);
    eventDoc.update({
      //uid: firebase.firestore.FieldValue.arrayRemove(userId)
    }).then(() => {
    }).catch((error) => {
      console.error("Error removing userId from the uid array field: ", error);
    });
  }
  editEvent(event){
    return this.db.collection('Events').doc(event.id).update(event);
  }

  getEventById(eventId: any){
    return this.db.collection('Events', ref => ref.where('id', '==', eventId));
  }

  getAllEvents(){
    return this.db.collection('Events');
  }

  getEventsByShareType(type){
    return this.db.collection('Events', ref => ref.where('shareWith', '==', type));
  }

  getUserEvents(){
    return this.db.collection('Events', ref => ref.where('shareWith', 'in', ['Public','User']));
  }

  deleteEvent(eventModel: any){
    return this.db.collection('Events').doc(eventModel.id).delete();
  }

  getLimitedNewsList(limit: number): AngularFirestoreCollection<any[]> {
    return this.db.collection('News', ref => ref.where('shareWith', '==', 'Public').limit(limit));
    // return this.db.collection('News', ref => ref.where('shareWith', '==', 'Public').orderBy('timeStamp', 'desc').limit(limit));

  }

  getPublicEventsList(): AngularFirestoreCollection<any[]> {
    return this.db.collection('Events', ref => ref.where('shareWith', '==', 'Public'));
  }

  saveRecords(records: any[], collectionName: string): Promise<void> {
    const batch = this.db.firestore.batch();
    records.forEach(record => {
      const docRef = this.db.collection(collectionName).doc(record.id).ref;
      batch.set(docRef, record);
    });

    return batch.commit();
  }
}
