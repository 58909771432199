<label [ngClass]="classList" [ngStyle]="styles" class="toggle-container">
    <input
    [ngStyle]="{
        '--button-bg-color': backgroundColor,
    }"
      type="checkbox"
      [checked]="value"
      [disabled]="disabled"
      (change)="toggle()"
    />
    <span class="slider"     
    [ngStyle]="{
        '--button-bg-color': backgroundColor,
    }"></span>
    <span *ngIf="label" class="label-text">{{ label }}</span>
  </label>
  