<div *ngIf="model "  class=" min-h-screen z-0 bg-gray-50  md:pb-5" >
  <div *ngFor="let section of model.appSections">
    <ng-container *ngIf="sectionIsValid(section)">
      <!-- Main view -->
      <section id="carousel" class="relative z-0" *ngIf="section.originalName === 'Slider' && section.isEnabled">

        <!-- If landing page is empty -->
        <div *ngIf="!section.options || section.options.length<=0">
      

          <div class="flex flex-wrap mx-auto" [ngClass]="{' max-w-4xl': (preview && view == 'web') || !preview, ' w-full p-4': preview && view == 'mobile'}">
            <h1 class="max-w-lg text-2xl font-bold text-center mb-5  md:text-4xl ">
              {{emptyHero.title}}

            </h1>
            <p class="text-darkGray  text-justify">
              {{emptyHero.description}}
            </p>
          </div>
    
        </div>

        <!-- Implement the carousel(for both mobile and desktop versions) -->
        <div class="block " *ngIf="section.options && section.options.length > 0">

          <app-slider [slides]="slideElements" [intervalDuration]="sliderInterval" [preview]="preview" [view]="view" [landingPageSettings]="landingPageSettings"> </app-slider>
          

        </div>
      </section>


      <!-- Footer -->
      <div *ngIf="section.originalName === 'Footer' && section.isEnabled">
        <!-- <app-footer -->
      </div>
    </ng-container>
  </div>

  <!-- FAB - list view/ card view button  -->
  <div class="fixed z-50 bottom-10 right-8 ">
    <div class="icon-container" (mouseenter)="showText = true" (mouseleave)="showText = false">
      <div class="bg-black rounded-full flex shadow-md px-4 py-2 space-x-2" (click)="changeViewType()">
        <div>
          <div *ngIf="(( viewportSize?.width >= 768) &&  landingPageView['web'] == 'card') || (( viewportSize?.width <= 768) &&  landingPageView['mobile'] == 'card')" class="w-6 h-6">
            <i class="material-icons  text-white"  >view_module</i>
          </div>
          <div  *ngIf="(( viewportSize?.width > 768) &&  landingPageView['web'] == 'list') || (( viewportSize?.width < 768) &&  landingPageView['mobile'] == 'list')" class="w-6 h-6">
            <i class="material-icons text-white"  >list</i>
          </div>
      </div>
        <span [class.hidden]="!showText">   <div>
          <p *ngIf="(( viewportSize?.width >= 768) &&  landingPageView['web'] == 'card') || (( viewportSize?.width <= 768) &&  landingPageView['mobile'] == 'card')"class="text-white">List View</p>
          <p  *ngIf="(( viewportSize?.width > 768) &&  landingPageView['web'] == 'list') || (( viewportSize?.width < 768) &&  landingPageView['mobile'] == 'list')" class="text-white">Card View</p>
      </div>
      </span>
      </div>
    
    </div>
  </div>

  <!-- Welcome Popup Modal -->
  <div *ngFor="let section of model.appSections">
    <app-welcome-popup *ngIf="signupModalOpen && (section.originalName=='Welcome Popup' || section.name=='WelcomePopup')" 
    [section]="section" [nationOfuse]="nationOfuse"
    (closeClicked)=closeSignupModal() 
    (navigateToRegisterPage)="navigateToAnotherPage('/register')" 
    (navigateToBuildPage)="navigateToAnotherPage('/get-app')"></app-welcome-popup>
  </div>

  <div *ngIf="(( viewportSize?.width >= 768) &&  landingPageView['web'] == 'card') || (( viewportSize?.width < 768) &&  landingPageView['mobile'] == 'card')">
    <ng-container *ngTemplateOutlet="cardView"></ng-container>
  </div>
  
  <div *ngIf="(( viewportSize?.width >= 768) &&  landingPageView['web'] == 'list') || (( viewportSize?.width < 768) &&  landingPageView['mobile'] == 'list')">
    <ng-container *ngTemplateOutlet="listView"></ng-container>
  </div>

</div>

<ng-template #myTemplate>
    <div *ngFor="let section of listElement">
          {{section.name}}
  </div>
</ng-template>

<div *ngIf="!model && appNotSet" class=" min-h-screen">
  <div class="w-full flex flex-col md:flex-row justify-between items-center">
    <div class="md:w-1/2 w-full bg-brandColor center ">
      <div class="px-8 py-6 md:py-12 flex flex-col justify-center mx-auto ">
        <img class="md:block hidden w-4/5 mx-auto"
         data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/demo/FrontPageImg.webp"           
         srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/demo/FrontPageImg.webp"
         fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/demo/FrontPageImg.png"
         alt="A girl with laptop."> 
      </div>
    </div>
    <div class=" md:w-1/2 w-full flex flex-col space-y-4 text-center px-4 md:px-6 py-8">
      <p class="text-brandColor font-bold text-3xl ">Welcome to Your own progressive app</p>
      <p class="text-brandColor text-2xl font-bold">
        Your content will appear here.
      </p>
      <p class="text-brandColor">Come back to check it out once you have finished the initial setup after your first login.</p>
      <div>
        <button class="btn btn-outline border border-white text-white bg-brandColor px-10" [routerLink]="['/auth/login']">
          Login
        </button>
      </div>
    </div>
  </div>
</div>


<!-- Card View -->
<ng-template #cardView>
  <app-card-view-elements [cardElement] = "cardElement" [model]="model"></app-card-view-elements>

</ng-template>


<!-- List View -->
<ng-template #listView>
  <div *ngFor="let section of listElement">
    <div >
      <!-- Services -->
      <section class="" id="services" *ngIf="section.section.originalName === 'Services' && section.section.isEnabled && servicesArray">
        <div class=" max-w-6xl   px-5 mx-auto mt-5 w-full  flex flex-col justify-center ">
          <p class="text-2xl font-bold text-center pt-6 mt-6">{{section.section.name}}</p>
          
          <app-service-list-default *ngIf="servicesArray && servicesArray.length > 1"  [itemNumber] = "getItemNumber(section.section)"  [services] = "getSortedList(servicesArray)"></app-service-list-default>
          <app-service-list-minimal *ngIf="servicesArray && servicesArray.length == 1" class="w-full" [preview]="preview" [view]="view"  [itemNumber] = "getItemNumber(section.section)" [services] = "getSortedList(servicesArray)"></app-service-list-minimal>


          <a *ngIf="servicesArray && servicesArray.length > 1" (click)="redirectToServices()"
          class="md:mr-32 block text-md text-customTeal font-bold  text-right ">View More</a>
        
        </div>

      </section>

      <!-- Products 2-->
      <section id="products" *ngIf="section.section.originalName === 'Products' && section.section.isEnabled && productsArray">
        <div class=" max-w-6xl   px-5 mx-auto mt-5 w-full  flex flex-col justify-center ">
          <p class="text-2xl font-bold text-center  pt-6 mt-6">{{section.section.name}}</p>
          
          <div class="mt-5">
            <div class="flex flex-wrap gap-x-4  w-full  md:gap-y-8 gap-y-4 px-4 md:gap-x-6 justify-center  mx-auto  " *ngIf="productsArray && productsArray.length > 1">
              <!-- <app-product-list-minimal *ngIf="landingPageTemplate == 'minimal'" [itemNumber] = "getItemNumber(section.section)" [products] = 'getSortedList(productsArray)'></app-product-list-minimal> -->
              <app-product-list-default  [itemNumber] = "getItemNumber(section.section)" [products] = 'getSortedList(productsArray)'></app-product-list-default>
              <!-- <app-product-list-dark *ngIf="landingPageTemplate == 'dark'" [itemNumber] = "getItemNumber(section.section)" [products] = 'getSortedList(productsArray)'></app-product-list-dark> -->
            
            </div>
            <div *ngIf="productsArray && productsArray.length == 1" class="w-full">
              <app-product-list-minimal [itemNumber] = "getItemNumber(section.section)" [products] = 'getSortedList(productsArray)'  [preview]="preview" [view]="view"></app-product-list-minimal>
            </div>
          </div>
          
          <a *ngIf="productsArray && productsArray.length > 1" (click)="redirectToProduct()"
          class="md:mr-32 block md:pt-2 text-md text-customTeal font-bold  text-right ">View More</a>
   
        </div>



      </section>

      <!-- Events  -->
      <section id="events" *ngIf="section.section.originalName === 'Events' && section.section.isEnabled &&  eventsArray && eventsArray.length > 0">
        <div class="  max-w-6xl   px-5 mx-auto mt-5 w-full  flex flex-col justify-center ">
          <!-- Heading  -->
          <h2 class="text-2xl font-bold  text-center" *ngIf="eventsArray != emptyEvent"> {{section.section.name}} </h2>
        
            <!-- event 1 -->
            <div *ngIf="eventsArray && eventsArray.length > 1" class="flex flex-wrap gap-y-16 md:mt-5 md:flex-row baseline md:gap-x-16 justify-center pt-16 ">
              <div 
              *ngFor="let event of getSortedEventsList(eventsArray) | slice:0:getItemNumber(section.section) ;" [routerLink]="['/view-event',event.id]"
              class="w-full md:w-[280px] lg:w-[340px] md:h-[360px] "
              >
              <ng-container *ngIf="event.imgLink">
                <div class="flex  flex-col bg-white rounded-xl md:h-[360px]  h-max drop-shadow-md hover:scale-105 hover:ease-in hover:duration-300">
                  <div class="w-full  md:h-48 rounded-xl">
                    <div class="">
                      <div class=" top-0 ml-3 md:ml-6 -mt-9 md:-mt-12 items-center  w-12 md:w-16">
                        <span class="flex items-center justify-center p-2 md:p-3 bg-white border border-customTeal rounded-lg shadow-lg">
                            <div class="flex flex-col">
                                <h5 class="text-base md:text-2xl font-bold  text-customTeal">{{event.eventStartDate | date: 'dd'}}</h5>
                                <h5 class="text-xs md:text-base text-customTeal">{{event.eventStartDate | date: 'MMM'}}</h5>
                            </div>
                        </span>
                    </div>
                    </div>
                    <div class="flex justify-center   md:h-48 " >
                      <img [src]="event.imgLink" class="md:w-56 h-full w-full rounded-lg md:object-contain" >
                    </div>
                  </div>
                  
                  <div class="p-4 space-y-4 pt-8">
                    <div class="w-full md:w-[250px] lg:w-[310px] break-words">
                      <h1 class="h-max content-end font-bold first-letter text-xl ">{{truncateHTML(getInnerText(event.eventTitle), 45)}}</h1>
                    </div>
                    <div class="flex flex-row space-x-1 text-gray-500 items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 md:h-4 md:w-4 " viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                          </svg>
                          <span class="text-xs md:text-base text-center  ">{{truncateHTML(event.eventPlace, 30)}}</span>
                      </div>
                      <div class="flex flex-row space-x-1 text-gray-500 items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 md:h-4 md:w-4" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                            </svg>
                          <span class="text-xs md:text-base text-center ">{{event.eventStartTime }} - {{event.eventEndTime}}</span>
                      </div>
                  </div>
                </div>
              </ng-container>
      
              <ng-container *ngIf="!event.imgLink" >
                <div class="w-full">
                <div class="flex flex-col rounded-xl w-full bg-white md:h-[360px] drop-shadow-md hover:scale-105 hover:ease-in hover:duration-300">
                  <div class=" top-0 ml-3 md:ml-6 -mt-9 md:-mt-12 items-center  w-12 md:w-16">
                      <span class="flex items-center justify-center  p-2 md:p-3    bg-white border border-customTeal rounded-lg shadow-lg">
                          <div class="flex flex-col">
                              <h5 class="text-base md:text-2xl font-bold text-customTeal">{{event.eventStartDate | date: 'dd'}}</h5>
                              <h5 class="text-xs md:text-base text-customTeal">{{event.eventStartDate | date: 'MMM'}}</h5>
                          </div>
                      </span>
                  </div>
      
                  <div class="p-4 space-y-4">
                    <div class="w-full md:w-[250px] lg:w-[310px] break-words">
                      <h1 class="h-max content-end  font-bold first-letter text-xl ">{{truncateHTML(getInnerText(event.eventTitle), 45)}}</h1>
                    </div>
                    <div class="flex flex-row space-x-1 text-gray-500 items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 md:h-4 md:w-4 " viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                          </svg>
                          <span class="text-xs md:text-base text-center f ">{{event.eventPlace}}</span>
      
                      </div>
                      <div class="flex flex-row space-x-1 text-gray-500 items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 md:h-4 md:w-4" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                            </svg>
                          <span class="text-xs md:text-base text-center ">{{event.eventStartTime }} - {{event.eventEndTime}}</span>
                      </div>
                  </div>
                </div>
                </div>
      
              </ng-container>
            </div>
            </div>
          <div *ngIf="eventsArray && eventsArray.length == 1" [ngClass]="{'w-full ': preview && view=='mobile'}">
            <app-event-list-minimal class="w-full" [preview]="preview" [view]="view" [itemNumber] = "getItemNumber(section.section)" [events] = "getSortedEventsList(eventsArray)"></app-event-list-minimal>
          </div>
            <!-- <div class="pt-4">
                <app-event-list-minimal  *ngIf="landingPageTemplate == 'minimal'" [itemNumber] = "getItemNumber(section.section)" [events] = "getSortedEventsList(eventsArray)"></app-event-list-minimal>
                <app-event-list-dark  *ngIf="landingPageTemplate == 'dark'" [itemNumber] = "getItemNumber(section.section)" [events] = "getSortedEventsList(eventsArray)"></app-event-list-dark>

              </div> -->
        
          <div *ngIf="eventsArray != emptyEvent && eventsArray.length > 1" class="w-full flex justify-end px-10 mt-20">
            <div class="h-5"></div>
            <a [routerLink]="['/events']" class="md:mr-32 block pt-6 text-md text-customTeal font-bold  text-right "
            >View More</a>          
          </div>
          <!-- <br/> -->
        </div>

      </section>

      <!-- About -->
      <section id="about" *ngIf="section.section.originalName === 'About Us' && section.section.isEnabled && about && about.sections">
        <div class=" max-w-6xl px-5 mx-auto mt-5 w-full  flex flex-col justify-center " *ngIf="aboutVisibility ">
          <p *ngIf="about && about.sections && about.sections.length > 1 " class="text-2xl font-bold text-center  pt-6 mt-6">{{aboutName}}</p>
          <div *ngIf="about && about.sections && about.sections.length > 1 " class="flex flex-wrap gap-x-4  w-full   md:gap-y-8 gap-y-4 px-4 md:gap-x-6 justify-center  mx-auto my-5 ">
            <div *ngFor="let section of about.sections | slice:0:getItemNumber(section.section);"
            class="w-full md:w-min"
            >
          
            <div *ngFor="let item of section.items " [routerLink]="['/about']" class="cursor-pointer flex flex-col shadow-md rounded-md items-center w-full md:w-[341px] md:h-[311px] p-6  bg-white  ">
                <div  *ngIf="item.imgUrl && item.imgUrl != ''" class="w-full md:h-48 rounded-t-xl bg-white cursor-pointer">
                  <div class="flex justify-center md:h-48 ">
                    <img [src]="item.imgUrl" alt="" class="md:w-56 h-full w-full rounded-lg mt-2 object-contain ">
                  </div>
                </div>
            
                <div class=" flex-col p-4 space-y-5 md:space-y-0 md:w-9/12 w-full break-words">
                  <h2 class="pt-4 text-base text-center md:text-lg text-customTeal font-bold">
                    {{truncateHTML(getInnerText(section.sectionTitle),40) | titlecase }} 
                  </h2>
                </div>
            </div>
          </div>
              
      
          </div>

          <div class="py-4 flex justify-center " *ngIf="about && about.sections  && about.sections.length == 1">
              <app-about-list-minimal class="w-full " *ngIf="aboutVisibility" [name]="aboutName" [sections] = "about.sections"></app-about-list-minimal>
          </div>
        </div>
      </section>
      
      <!-- Blog -->
      <section class="relative py-4 " id="posts"
        *ngIf="(section.section.originalName=='Blogs') && section.section.isEnabled && postsArray">
        <span class=" py-4" aria-hidden="true"></span>
        <div class=" max-w-6xl   px-5 mx-auto mt-5 w-full  flex flex-col justify-center ">
          <ng-container *ngIf="postsArray != emptyBlog">
            <!-- Section name -->
            <h2 class="text-2xl font-bold  text-center text-black" *ngIf="postsArray != emptyBlog">
              {{section.section.name}}
      
            </h2>
            <!-- Blogs items -->
            <div class="mt-5 lg:mt-8 flex flex-wrap w-full justify-center" *ngIf="postsArray != emptyBlog ">
            <div *ngIf="postsArray.length > 1 &&( getItemNumber(section.section) > 1)" class="mt-5 lg:mt-8 flex flex-wrap w-full justify-center"  >
              <div  [routerLink]="['/view-blog', post.id]" class="w-full  mb-7"
              [ngClass] = "{'w-1/2 ': preview && view == 'web', 'lg:w-1/2 ': !preview}"
                *ngFor="let post of getSortedList(postsArray)  | slice:0:getItemNumber(section.section);">
                <div 
                
                  class="md:mr-7 flex  flex-col-reverse md:flex-row border h-max shadow-sm rounded-lg md:h-48 bg-white  hover:shadow-lg hover:underline">
  
                  <div class="flex flex-col p-4 space-y-2 md:space-y-0 md:w-9/12 w-full  break-words">
                    <h2 class="text-base md:text-lg text-customTeal font-bold">
                      {{truncateHTML(getInnerText(post.postTitle),60) | titlecase }} </h2>
                    <p class="text-xs pt-1 text-gray-500 " *ngIf="post.timeStamp">{{post.timeStamp.toDate() | date:
                      'MMM.dd.yyyy'}}</p>
                    <p class="text-xs pt-1 text-gray-500 " *ngIf="!post.timeStamp">{{emptyTimeStamp | date:
                      'MMM.dd.yyyy'}}</p>
                    <div class=" w-full break-words">
                      <p class="text-sm pt-3 ">{{truncateHTML(getInnerText(post.message), 90)}} </p>
                    </div>
                  </div>

                  <div *ngIf="post.mediaLink && !post.videoLink" class="md:w-3/12 " >
                    <img [src]="post.mediaLink" class="  md:h-48 p-4 w-full object-contain" alt="">
                  </div>

                  <div *ngIf="post.mediaLink && post.videoLink" class="md:w-3/12 " >
                    <img [src]="post.mediaLink" class="  md:h-48 p-4 w-full object-contain" alt="">
                    <video controls playsinline preload="none" class="  md:h-48 p-4 w-full object-contain" [poster]="post.mediaLink">
                      <source [src]="post.videoLink | safeUrl"  >
                      Your browser does not support HTML video.
                    </video>
                  </div>

                  <div *ngIf="!post.mediaLink && post.videoLink" class="md:w-3/12 " >
                    <img [src]="post.mediaLink" class="  md:h-48 p-4 w-full object-contain" alt="">
                  </div>

                </div>
  
              </div>
            
            </div>
            <div *ngIf="postsArray.length == 1 || (getItemNumber(section.section)==1 )" [ngClass]="{'w-full ': preview && view=='mobile'}">
              <app-blog-list-minimal class="w-full" [preview]="preview" [view]="view" [itemNumber] = "getItemNumber(section.section)"   [posts] = "getSortedList(postsArray)"></app-blog-list-minimal>
            </div>
            </div>
            <!-- view more -->
            <a *ngIf="postsArray && (postsArray != emptyBlog) && postsArray.length > 1" [routerLink]="['/blogs']"
            class="block text-md  font-bold text-right">View More</a>
          </ng-container>
        </div>
      </section>

      <!-- Custom Sections -->
      <section *ngIf="section.section.isCustomSection && section.section.isEnabled">
        <div class=" max-w-6xl   px-5 mx-auto mt-5 w-full  flex flex-col justify-center ">
          <app-view-custom-lists [itemNumber]="getItemNumber(section.section)" [sectionDetail]="section.section" view="list" homepage="false"></app-view-custom-lists>
        </div>
      </section>


    </div>
  </div>
  <div *ngFor="let section of listElement">
    <!-- FAQ -->
    <section id="faq" class="md:pb-24" *ngIf="section.section.originalName === 'faq' && section.section.isEnabled">
      <div class=" max-w-6xl   px-5 mx-auto mt-5 w-full  flex flex-col justify-center ">
        <h2 class="text-2xl font-bold text-center mb-5"> {{section.section.name}} </h2>
        <app-faq-list calledFromComponent="landingpage" [itemNumber]="getItemNumber(section.section)" [preview]="preview" [view]="view" ></app-faq-list>
      </div>
    </section>
  </div>
  <div class="flex w-full justify-center ">
    <div [ngClass]="{'max-w-2xl ': preview && view == 'web' , ' w-80 ': preview && view == 'mobile', 'w-full flex justify-center': !preview}" >
      <!-- contact us -->
    <app-contact-us *ngIf=" contactVisibility" [preview] = 'view ? true : false' class="w-full" ></app-contact-us>
    </div>
  </div>
  
</ng-template>