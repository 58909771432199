import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-preview-hompage',
  templateUrl: './preview-hompage.component.html',
  styleUrls: ['./preview-hompage.component.css']
})
export class PreviewHompageComponent implements OnInit {

  @Input() modalOpen;
  @Input() view;

  @Output() closeModal = new EventEmitter<string>();
  @Output() viewType = new EventEmitter<string>();




  constructor() { }

  ngOnInit(): void {
  }

  closePreviewModal(){
    this.closeModal.emit();
  }
  changeViewType(view){
    this.viewType.emit(view);

  }

}
