import { ProductsServiceMenuService } from "src/app/services/api/data.service";
import { Location } from "@angular/common";
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, ElementRef, AfterViewInit  } from "@angular/core";
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/firebase/auth.service";
import { NewsEventService } from "./../../../../services/firebase/news-event.service";
import { ImageModel, ImageUploaderResponseModel } from "src/app/models/ImageModel";
import { environment } from "src/environments/environment";
import { isBefore, isSameDay, isAfter } from "date-fns";
import { FCMPushNotificationsService } from "src/app/services/firebase/FCMPushNotifications.service";
import { HelperService } from "src/app/services/helper/helper";
import { AppSettingsService } from "src/app/services/firebase/app-settings.service";
import { PermissionService } from "src/app/services/firebase/permission.service";
import { ShareDataModel } from "src/app/models/SocialMediaPostModel";
import { NotificationModel } from "src/app/models/Messages.Model";
import { CountersService } from "src/app/services/firebase/counters.service";
import { FCMService } from "src/app/services/api/fcm.service";
import { NotificationPreferenceService } from "src/app/services/firebase/notification-preference.service";
import { CustomAdminEmailModel, EmailModel } from 'src/app/models/emailModel';
import { EmailService } from "src/app/services/api/email.service";
import { ScrollToTopService } from 'src/app/services/helper/scroll-to-top.service'
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { ChangeVisibilityService } from "src/app/services/firebase/change-visibility.service";

@Component({
  selector: "app-create-event",
  templateUrl: "./create-event.component.html",
  styleUrls: ["./create-event.component.css"],
})
export class CreateEventComponent implements OnInit {
  @Input() calledFromEmptyDashboard = "";
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() eventCreated = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();
  @Output() skip = new EventEmitter<string>();
  @ViewChild('topSection') targetElement: ElementRef;  @Output() previewClosed = new EventEmitter<string>();
  @Output() previewSelected = new EventEmitter<string>();

  createEventForm: UntypedFormGroup;
  mediaType: any;
  currentUser: any;
  model: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  imgErrorMsg: any;
  isMobile = false;
  trackEventStartDate: any;
  pricing = "";
  eventLinkClicked = false;
  urlPattern = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?";
  timePattern = "((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))";
  numberPattern = /^[-+]?(\d*\.\d+|\d+\.|\.\d+|\d+)([eE][-+]?\d+)?$/;
  videoUpload: File = null;
  videoLink: string = null;
  imageLink: string = null;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  attachedVideo: any;
  step = 1;
  isList;
  eventsName = "Events";
  currency;
  cancelModalOpen = false;
  notifSettings;
  permission: any;
  haveAccessPermission = false;
  modelToPreview:any = {}
  preview = false;
  createModalOpen = false;
  cropModalOpen = false;
  imageChangedEvent: any = "";

  shareToSocialMedia = false;
  sendPushNotfication = false;
  shareData: ShareDataModel = new ShareDataModel();
  useBase64 = false;
  pageSections: any;
  imageUrl = '';
  times = [
    "12:00 AM",
    "12:30 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];
  timesEnd = [];
  startTime = "9:00 AM";
  hasPrice = false;
  perm: any;
  permMember: any;
  permSocial: any;
  permPES: any;
  permSpecial: any;
  permAnalytics: any;
  mediaList = [];
  uploadFileModalOpen = false;
  newEventValues: any = {};
  returnedMediaLinks = {
    imageURL: "",
    videoUrl: "",
    pdfUrl: "",
  };
  eventRef: any;
  onLoading = false;

  socialMediaCount: number = 0;
  adminCounter: any;
  currentDate = new Date();
  limitSocialMediaShare: boolean = false;
  shareEventWith: any = null
  eventModel: any = null

  receivedLong: any;
  receivedLat: any;
  receivedAddress: any;

  inviteModal : boolean = false;
  invitedSubscribers: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false
  appSettings$: Subscription;
  constructor(
    private fb: FormBuilder,
    private newsEventService: NewsEventService,
    private authService: AuthService,
    private location: Location,
    private pushNotificationsService: FCMPushNotificationsService,
    private productsServiceMenuService: ProductsServiceMenuService,
    private appSettingsService: AppSettingsService,
    private helperervice: HelperService,
    private permissionService: PermissionService,
    private counter: CountersService,
    private apiService: FCMService,
    private emailService : EmailService,
    private notificationPreferenceService: NotificationPreferenceService,
    private scrollService: ScrollToTopService,
    private store: Store,
    private changeVisibilityService: ChangeVisibilityService
  ) {
    this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
      this.pageSections = settings;
      this.eventRef = this.pageSections.appSections.filter((s) => s.originalName === "Events")[0];
    });
    if (
      typeof window["Capacitor"] !== "undefined" &&
      window["Capacitor"]["platform"] !== "web"
    ) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.scrollService.scrollToTopOnNavigation();
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.role === "Employee") {
        this.permissionService
          .getPermissionByEmployeeId(this.currentUser.uid)
          .valueChanges()
          .subscribe((perm: any) => {
            if (perm.length > 0) {
              this.perm = perm[0];
              this.permMember = this.perm.permission[0];
              this.permPES = this.perm.permission[1];
              this.permAnalytics = this.perm.permission[2];
              this.permSocial = this.perm.permission[3];
              this.permSpecial = this.perm.permission[4];
              if (this.permPES.createEvents) {
                this.haveAccessPermission = true;
              }
            }
          });
      }
      if (this.currentUser.role === "Admin") {
        this.haveAccessPermission = true;

        // Get the current month
        const currentMonth = this.currentDate.getMonth();

        // Move to the next day
        const nextDay = new Date(this.currentDate);
        nextDay.setDate(this.currentDate.getDate() + 1);

        // Check if the next day is in a different month
        const isNewMonth = nextDay.getMonth() !== currentMonth;

        this.counter
          .getUserById(this.currentUser.uid)
          .valueChanges()
          .subscribe((counterAdmin) => {
            if (counterAdmin && counterAdmin.length != 0) {
              this.adminCounter = counterAdmin[0];
              this.socialMediaCount = this.adminCounter.socialMediaCount
                ? this.adminCounter.socialMediaCount
                : 0;

              if (isNewMonth) {
                this.socialMediaCount = 0;
              } else {
                if (this.socialMediaCount <= 10000) {
                  this.socialMediaCount = this.adminCounter.socialMediaCount;
                } else if (this.socialMediaCount > 10000) {
                  this.shareToSocialMedia = false;
                  this.limitSocialMediaShare = true;
                }
              }
            } else {
              const counter = {
                id: this.currentUser.uid,
                SMSTotalCounter: 0,
                emailTotalCounter: 0,
                socialMediaCount: 0,
              };
              this.counter.saveCounter(counter);
            }
          });
      }
    }

    this.createEventForm = this.fb.group({
      createdById: [""],
      eventTitle: ["", [Validators.required]],
      titleColor: ["#000000"],
      isOnline: [false],
      eventLink: [""],
      eventUrl: [""],
      eventPlace: [""],
      eventStartDate: ["", [Validators.required, validateStartDate]],
      eventEndDate: ["", [validateStartAndEndDate]],
      eventStartTime: ["9:00 AM", Validators.pattern(this.timePattern)],
      eventEndTime: ["9:30 AM", Validators.pattern(this.timePattern)],
      description: [""],
      backgroundColor: ["#ffffff"],
      imgLink: [""],
      videoLink: [""],
      pdfLink: [""],
      shareToFriends: [true],
      hasPrice: [false],
      shareWith: ["", Validators.required],
      sharedLink: ["", [Validators.pattern(this.urlPattern)]],
      price: ["", [Validators.pattern(this.numberPattern)]],
      registerRequired:[false],
      maxRegistration:[""],
      opened:[""],
      commentable:[true],
      latitude:[""],
      longitude:[""],
      invitedSubs: [""],
    });

    const tomorrow = new Date();
    const minEndDate = new Date();
    const eventStartDate = new Date(this.trackEventStartDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    minEndDate.setDate(eventStartDate.getDate() + 1);

    if (this.currentUser && (this.currentUser.role == "Employee" || this.currentUser.role == "Admin")) {
      this.appSettingsService
        .getPushNotificationSettingsList()
        .valueChanges()
        .subscribe((x) => {
          if (x && x.length > 0) {
            this.notifSettings = x[0];
          }
        });
    }

    this.productsServiceMenuService.currencyName.subscribe((c) => {
      this.currency = c;
    });

    this.productsServiceMenuService.eventsName.subscribe((e) => {
      this.eventsName = e;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.createEventForm && this.createEventForm.value){
      this.previewChanges(this.createEventForm.value)
    }
  }
  receiveLongitude(long){
    this.receivedLong = long;
  }
  receiveLatitude(lat){
    this.receivedLat = lat;
  }
  receiveAddress(location){
    this.receivedAddress = location;
    this.onEventPlaceChange();
  }

  changeVisibility() {
    this.changeVisibilityService.updateAppSections("Events");
  }

  onEventLocationChange(event) {
     if (event.target.checked) {
      this.eventPlace.setValue("Online");
    }else{
      this.eventPlace.setValue("")
    } 
    // else {
    //   this.eventPlace.setValue(`${this.receivedAddress}`);
    //   this.eventPlace.setValidators(Validators.required);
    // }
    this.eventPlace.updateValueAndValidity();
  }

  
  onEventPlaceChange() {
    // if (event.target.checked) {
      this.eventPlace.setValue(this.receivedAddress);
    // } else {
    //   this.eventPlace.setValue("");
    //   this.eventPlace.setValidators(Validators.required);
    // }
    this.eventPlace.updateValueAndValidity();
  }

  dateChangeEventStartDate() {
    this.trackEventStartDate = $("#eventStartDate").val();
    if (this.createEventForm) {
      this.createEventForm.patchValue({
        eventStartDate: $("#eventStartDate").val(),
      });
    }
    const eventStartDate = new Date(this.trackEventStartDate);
    const minEndDate = new Date();
    minEndDate.setDate(eventStartDate.getDate() + 1);
    this.createEventForm.patchValue({
      eventEndDate: this.eventStartDate.value,
    });
  }

  get eventTitle() {
    return this.createEventForm.get("eventTitle");
  }
  get sharedLink() {
    return this.createEventForm.get("sharedLink");
  }
  get description() {
    return this.createEventForm.get("description");
  }
  get imgLink() {
    return this.createEventForm.get("imgLink");
  }
  get pdfLink() {
    return this.createEventForm.get("pdfLink");
  }
  get eventPlace() {
    return this.createEventForm.get("eventPlace");
  }
  get eventStartDate() {
    return this.createEventForm.get("eventStartDate");
  }
  get eventEndDate() {
    return this.createEventForm.get("eventEndDate");
  }
  get shareWith() {
    return this.createEventForm.get("shareWith");
  }
  get isOnline() {
    return this.createEventForm.get("isOnline");
  }
  get price() {
    return this.createEventForm.get("price");
  }
  get registerRequired() {
    return this.createEventForm.get("registerRequired");
  }
  get maxRegistration() {
    return this.createEventForm.get("maxRegistration");
  }
  get opened() {
    return this.createEventForm.get("opened");
  }
  get eventStartTime() {
    return this.createEventForm.get("eventStartTime");
  }
  get eventEndTime() {
    return this.createEventForm.get("eventEndTime");
  }
  get latitude() {
    return this.createEventForm.get("latitude");
  }
  get longitude() {
    return this.createEventForm.get("longitude");
  }
  get invitedSubs() {
    return this.createEventForm.get("invitedSubs");
  }

  truncateHTML(text: string, limit: string): string {
    return this.helperervice.truncateHTML(text, limit);
  }

  additionalLink() {
    this.eventLinkClicked = true;
  }

  async sendPushNotification() {

    let title = this.truncateHTML(this.eventModel.eventTitle, "200");
    let notification = new NotificationModel();
    notification.title = "Event";
    notification.body = this.truncateHTML(this.eventModel.eventTitle, "200");
    notification.click_action = environment.firstNation.website + "/view-event/" + this.eventModel.uid

    // check if admin wants to send notification when an event is created, for the admin
    await this.sendPushNotificationToUser(this.eventModel, notification)

    // show the share post modal
    this.shareToSocialMedia = true;
    this.sendPushNotfication = false;

    // if the event is create by an employee, notify the admin
    if (
      this.notifSettings &&
      this.currentUser &&
      this.currentUser.role === "Employee"
    ) {
      await this.sendPushNotificationToOwner();
    }

  }


  // send notification using role
  sendNotificationByRole(notification, role) {
    try {
      this.apiService
        .senFCMMessageToTopic(
          notification?.title,
          notification?.body,
          "",
          role === "Admin" ? "Owner_Notification" : "User_Notification"
        )
        .subscribe((data) => {
          // this.successMessage("Message sent successful");
        });
    } catch (err) {
      // this.errorMessage("Subscription failed");
    }

  }

  // send push notfication to user
  async sendPushNotificationToUser(model, notification) {


    if (this.currentUser && (this.currentUser.role === "Admin" || this.currentUser.role === "Employee")) {

      if (model.shareWith.toLowerCase() === "public") {

        let roles = ['Admin','User','Employee'];

        // share the event for users
        await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, roles, 'events')

      }
      if (model.shareWith.toLowerCase() === "user") {
        // share the event for users
        this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['User'], 'events');

      }
      else if (model.shareWith.toLowerCase() === "employee") {
          this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['Employee'], 'events')
      }
    }


  }

  sendPushNotificationToOwner() {
    let createdByFullName = this.currentUser.GivenName + " " + this.currentUser.LastName;
    let notification = new NotificationModel();
    notification.title = "Employee Created an Event.";
    notification.body = `${createdByFullName} created a new event.`;

    this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['Admin'], 'events');

  }

  postEvent(value: any) {
    this.createModalOpen = false;
    this.onLoading = true;
    const model = {
      createdById: this.currentUser.uid,
      eventTitle: value.eventTitle,
      titleColor: value.titleColor,
      eventPlace: value.eventPlace,
      eventStartDate: value.eventStartDate,
      eventEndDate: value.eventEndDate,
      eventStartTime: value.eventStartTime,
      eventEndTime: value.eventEndTime,
      description: value.description,
      backgroundColor: value.backgroundColor,
      isOnline: value.isOnline,
      imgLink: this.imageLink,
      videoLink: this.videoLink,
      sharedLink: value.sharedLink,
      shareToFriends: value.shareToFriends,
      shareWith: value.shareWith,
      price: value.price,
      registerRequired: value.registerRequired ? value.registerRequired : false,
      maxRegistration: (value.maxRegistration && value.registerRequired) ? value.maxRegistration : '',
      opened: value.opened ? value.opened : '',
      users: [],
      pdfLink: this.pdfLink.value,
      commentable: value.commentable,
      latitude: this.receivedLat ? this.receivedLat : '',
      longitude :this.receivedLong ? this.receivedLong : '',
      invitedSubs : this.invitedSubscribers ? this.invitedSubscribers : [],
      reactions: {
        likes: {
            count: 0,
            userId: [],
            usernames: []
          }
        },
        comments: [],
    };

    if (this.pricing == "paid") {
      model.price = value.price;
    } else if (this.pricing == "free") {
      model.price = "free";
    } else {
      model.price = "dontInclude";
    }
    this.newEventValues = model;
    if (this.mediaList.length > 0) {
      this.uploadFileModalOpen = true;
    }

    if (this.mediaList.length === 0) {
      this.createEvent(this.newEventValues);
    }
  }

  sendEmails(){
    for(let i = 0; i < this.invitedSubscribers.length; i++){
      const emailModel = new CustomAdminEmailModel();

      emailModel.subject = `Invitation to ${this.eventTitle.value}`;
      emailModel.isNationApp = this.isNationApp;

      emailModel.body = '<span style="color:blue; margin-right: 15px;">Event Title : </span> ' + this.eventTitle.value + ' <br/> ';
      emailModel.body += '<span style="color:blue; margin-right: 15px;">Details : </span> ' + this.description.value + ' <br/> ';
      // emailModel.body += '<br/><br/> For More Details Please Check The Event Page at : <a href="' + environment.firstNation.website + '/events" target="_blank">mynation.app</a>';
      emailModel.body += '<br/> Thank You';
      // emailModel.emailList = this.invitedSubscribers;
      emailModel.emailList = this.invitedSubscribers;
      emailModel.isNationApp = this.isNationApp;
      // emailModel.role = 'Admin';
      // custom send email used
      emailModel.host = "smtp.gmail.com",
      emailModel.adminEmail = "mkproject.app@gmail.com",
      emailModel.appPassword = "ytklyidomvqfmtky",
      this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
        if (res) {
          this.toast({ html: 'Email sent Successfully', classes: 'green', type: 'success' });                    
        }
      });
    }
  }

  closeCollapseFromEmptydashboard() {
    this.closeCollapse.emit("createEvent");
  }

  createEvent(model) {
    if(this.calledFromEmptyDashboard){
      this.changeVisibility();
    }

    this.newsEventService
      .createEvent(model)
      .then((res) => {
        model.uid = res;
        // this.sendEmails();

        // set event data
        this.eventModel = model;

        // this.sendPushNotification(model);
        if (model.shareWith.toLowerCase() === "public") {
          // if(this.calledFromEmptyDashboard){
            this.changeVisibility();
          // }
          this.shareToSocialMedia = false;
          this.sendPushNotfication = true;
          this.sharePost(model);
        }

        this.toast({
          html: "Successfully created an event",
          classes: "green",
          type: "success",
        });
        // this.sendEmails();

        this.onLoading = false;
        if (this.calledFromEmptyDashboard == "Event") {
          this.closeCollapseFromEmptydashboard();
          this.eventCreated.emit("eventCreated");
          this.goToTopOfNextPage.emit();
        }

      })
      .catch((err) => {

        this.toast({ html: err, classes: "red", type: "failure" });
        this.onLoading = false;
      });
  }


  sharePost(newEvent) {
    this.shareData.share = true;
    this.shareData.post = newEvent.eventTitle;
    if (newEvent.videoLink && newEvent.videoLink !== "") {
      this.shareData.videoUrls = [newEvent.videoLink];
    }
    if (newEvent.imgLink && newEvent.imgLink !== "") {
      this.shareData.imageUrls = [newEvent.imgLink];
    }
  }

  cancelShare() {
    this.shareToSocialMedia = false;
    if (this.calledFromEmptyDashboard != "Event") {
      this.backClicked();
    }
  }


  // hide push notification modal
  cancelPushNotification() {
    this.sendPushNotfication = false;
    this.shareToSocialMedia = true;
  }

  backClicked() {
    this.location.back();
  }
  skipSetup(){
    this.skip.emit();
  }

  handleVideoInput(files: FileList) {
    this.binDoc = files[0];
    this.videoUpload = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachedVideo = reader.result as string;
    };
    this.mediaList.push({ type: "Video", file: this.videoUpload });
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue("");
  }
  removeImage() {
    this.base64Image = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== "Image") {
        return file;
      }
    });
  }
  removeVideo() {
    this.videoUpload = undefined;
    this.attachedVideo = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== "Video") {
        return file;
      }
    });
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue("");
  }

  validateImage(name: any) {
    return this.helperervice.validateImage(name);
  }

  pricingSet(price: string) {
    this.pricing = price;
  }

  dataURItoBlob(dataURI) {
    return this.helperervice.dataURItoBlob(dataURI);
  }

  onShareChange(userType) {
    if(userType === 'Invited'){
      this.inviteModal = true;
    }
    // set share event with for the push notifcation
    this.shareEventWith = userType
  }

  closeInviteModal(){
    this.inviteModal = false;
  }
  invited(event){
    this.invitedSubscribers = event;
    this.closeInviteModal();

  }

  setStartTime(item) {
    this.timesEnd = [...this.times.slice(this.times.indexOf(item))];
    item = item.trim();
    const timeSplited = item.split(":");
    this.eventStartTime.patchValue(item);
    let endingTime;
    if (timeSplited[0] != 12) {
      endingTime =
        (parseInt(timeSplited[0]) + 1).toString() + ":" + timeSplited[1];
      this.eventEndTime.patchValue(endingTime);
    }
    if (timeSplited[0] == 12 || timeSplited[0] == 11) {
      const timeLetterSplit = item.split("");
      const aOrpIndex = timeLetterSplit.length - 2;
      // replace am with pm and vise versa
      if (
        timeLetterSplit[aOrpIndex] === "A" ||
        timeLetterSplit[aOrpIndex] === "a"
      ) {
        timeLetterSplit[aOrpIndex] = "P";
      } else if (
        timeLetterSplit[aOrpIndex] === "P" ||
        timeLetterSplit[aOrpIndex] === "p"
      ) {
        timeLetterSplit[aOrpIndex] = "A";
      }
      let combinedLetterSplit = timeLetterSplit.join("").split(":");
      if (combinedLetterSplit[0] == 11) {
        combinedLetterSplit[0] = "12";
      } else if (combinedLetterSplit[0] == 12) {
        combinedLetterSplit[0] = "1";
      }
      endingTime = combinedLetterSplit[0] + ":" + combinedLetterSplit[1];
      this.eventEndTime.patchValue(endingTime);
    }
  }

  setEndTime(item) {
    this.eventEndTime.patchValue(item);
  }

  hasPriceClicked(value) {
    this.hasPrice = value;
  }

  nextStep() {
    this.step = this.step === 2 ? 1 : 2;

    this.goToTopOfNextPage.emit()
  }

  

  openCancelModal() {
    this.cancelModalOpen = true;
  }

  closeCancelModal() {
    this.cancelModalOpen = false;
  }

  closeCreateModal() {
    this.createModalOpen = false;
  }

  closeCroppingModal() {
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  uploadPhoto() {
    this.mediaType = "Photo";
  }

  uploadVideo() {
    this.mediaType = "Video";
  }

  fileChangeEvent(event) {
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;
    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imageUrl ='';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }

  getTheUrlsSaved(event) {
    this.returnedMediaLinks = event;
    this.uploadFileModalOpen = false;
    this.newEventValues.imgLink = this.returnedMediaLinks.imageURL;
    this.newEventValues.videoLink = this.returnedMediaLinks.videoUrl;
    this.newEventValues.pdfLink = this.returnedMediaLinks.pdfUrl;
    this.createEvent(this.newEventValues);
  }

  onImageUpload(response: ImageUploaderResponseModel) {
    if (response.error) {
      this.imgErrorMsg = response.errorMessage;
      return;
    }
    if (response.isMobile){
      this.binDoc = response.binDoc;
      this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
      this.mobileBase64Image = response.imgBase64;
      this.useBase64=true
      // this.cropModalOpen = true;
    }
    else{
      this.base64Image = response.imgBase64;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    }
  }

  processCroppedImage(event) {
    this.cropModalOpen = false;
    this.base64Image = event;
    this.useBase64 = false;
    this.binDoc = this.base64Image;
    let base64result = this.base64Image.split(",")[1];

    const img = new ImageModel();
    if (this.isMobile) {
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({ type: "Image", file: img });
    } else {
      img.imgBase64 = base64result;
      this.mediaList.push({ type: "Image", file: img });
    }
  }

  previewChanges(value) {
    this.modelToPreview = {};
    this.modelToPreview.eventTitle = value.eventTitle;
    this.modelToPreview.titleColor = value.titleColor;
    this.modelToPreview.eventPlace = value.eventPlace;
    this.modelToPreview.eventStartDate = value.eventStartDate;
    this.modelToPreview.eventEndDate = value.eventEndDate;
    this.modelToPreview.eventStartTime = value.eventStartTime;
    this.modelToPreview.eventEndTime = value.eventEndTime;
    this.modelToPreview.description = value.description;
    this.modelToPreview.backgroundColor = value.backgroundColor;
    this.modelToPreview.pdfLink = value.pdfLink;
    this.modelToPreview.sharedLink = value.sharedLink;
    this.modelToPreview.shareToFriends = value.shareToFriends;
    this.modelToPreview.shareWith = value.shareWith;
    this.modelToPreview.price = value.price;
    this.modelToPreview.maxRegistration = value.maxRegistration;
    this.modelToPreview.opened = value.opened;
    this.modelToPreview.latitude = this.receivedLat ? this.receivedLat : '';
    this.modelToPreview.longitude = this.receivedLong ? this.receivedLong : '';
    this.modelToPreview.invitedSubs = this.invitedSubscribers ? this.invitedSubscribers : '';
    
    if (this.base64Image) {
      this.modelToPreview.imgLink = this.base64Image;
    }
    if (this.attachedVideo) {
      this.modelToPreview.videoLink = this.attachedVideo;
    }
    if(this.calledFromEmptyDashboard == 'Event'){
      this.previewSelected.emit()
    }
    // this.preview = true;
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : "green";
    this.toastType = obj.type ? obj.type : "success";
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;

      if (!this.shareToSocialMedia && !this.sendPushNotfication) {
        this.backClicked();
      }
    }, 2000);
  }

  toggleRegistration(){
    if (!this.registerRequired.value) {
      this.createEventForm.controls["maxRegistration"].clearValidators();
      this.createEventForm.controls["maxRegistration"].updateValueAndValidity();
    } else {
      this.createEventForm.controls["maxRegistration"].setValidators(Validators.required);
      this.createEventForm.controls["maxRegistration"].updateValueAndValidity();
    }
    
  }

  closePreviewModal(){
    if(this.calledFromEmptyDashboard == 'Event'){
      this.previewClosed.emit()
    }
  }

}

function validateStartAndEndDate(c: AbstractControl): any {
  if (!c.parent || !c) {
    return;
  }
  const startDate = c.parent.get("eventStartDate");
  const endDate = c.parent.get("eventEndDate");
  const eventStartDate = new Date(startDate.value);
  const eventEndDate = new Date(endDate.value);

  if (
    isBefore(eventStartDate, eventEndDate) ||
    isSameDay(eventStartDate, eventEndDate)
  ) {
    return;
  }
  if (!isBefore(eventStartDate, eventEndDate)) {
    return { invalid: true };
  }
}

function validateStartDate(c: AbstractControl): any {
  if (!c.parent || !c) {
    return;
  }
  const startDate = c.parent.get("eventStartDate");
  const eventStartDate = new Date(startDate.value);
  const today = new Date();
  if (isAfter(eventStartDate, today) || isSameDay(eventStartDate, today)) {
    return;
  }
  if (isBefore(eventStartDate, today)) {
    return { invalidStartDate: true };
  }
}
