import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { ApiKeyManagerService } from '../api/apiKeyManager.Service';
import { FCMTokenService } from './FCMToken.service';
import { UserService } from './user.service';
import { FCMService } from '../api/fcm.service';
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: 'root'
})
export class FCMPushNotificationsService {

  fcmServer =  'https://fcm.googleapis.com/fcm/send';
  fcmTokensList: any;
  membersDeviceTokens: any[];

  constructor(
    private fcmTokens: FCMTokenService,
    private httpClient: HttpClient,
    private keyManager: ApiKeyManagerService,
    private userService: UserService,
    private apiService: FCMService,
    private toastr: ToastrService,

  ) {
  }

  notificationPayload = {

    registration_ids: undefined,
    to: undefined,

    name: 'notification',
    notification: {
      body: '',
      title: '',
      image: '',
      click_action: ''
    },
    //   webpush: {
    //     notification: {
    //         requireInteraction: true,
    //         icon: '/icons/notification.png'
    //     },
    //     fcm_options: {
    //         link: 'https://stackoverflow.com/questions/64686203/firebase-push-notifications-click-does-not-work'
    //     }
    // },
    android: {
      collapse_key: 'a',
      priority: 'high',
      ttl: '3600s',
      notification: {
        channel_id: 'Personal_app_default_channel',
        imageUrl: ''
      }
    },
    apns: {
      headers: {
        'apns-priority': '10',
      },
      payload: {
        aps: {
          badge: 1,
          sound: 'default'
        }
      }
    },
    data: {
      notification_foreground: 'true',
    }
  };



  sendNotificationToAllTheMembers(title: any, body: any, topic: any) {
    let notificationTopic = '/topics/' + topic
    this.notificationPayload.notification.title = title;
    this.notificationPayload.notification.body = body;
    this.notificationPayload.to = notificationTopic
    delete this.notificationPayload.registration_ids


    const headers = new HttpHeaders({
      Authorization: this.keyManager.getFCMKey(),
      'Content-Type': 'application/json;'
    });

    this.httpClient.post<any>(this.fcmServer, JSON.stringify(this.notificationPayload), { headers }).subscribe(o => {

    });

  }

  sendNotificationByRole(notification: NotificationModel, role: string) {
    this.userService.getUserByRoleForNotification(role).subscribe((users: any) => {

      if (users && users.length > 0) {
        let uids = users.map(u => u.uid)

        this.sendNotificationToUsers(notification, uids)
      }
    });
  }

  // send  notification by multiple roles
  async sendNotificationByRoles(notification: NotificationModel, roles: string[]) {


    this.userService.getUserByRoles(roles).subscribe((users) => {
      if (users && users.length > 0) {
        let uids = users.map(u => u.uid)

        this.sendNotificationToUsers(notification, uids)
      }
    });


  }


  sendNotificationToUsers(message: NotificationModel, users: any) {
    let groupedUsers = users.reduce((acc, currentValue) => {
      let lastGroup = acc[acc.length - 1]
      if (lastGroup.length < 10) {
        lastGroup.push(currentValue)
      } else {
        acc.push([currentValue])
      }
      return acc
    }, [[]])


    groupedUsers.forEach(usersIds => {
      this.sendNotification(message, usersIds)
    });
  }

  sendNotification(message:NotificationModel, users:any){
    
    this.fcmTokens.getUsersToken(users).subscribe(tokens=>{
      if (tokens && tokens.length > 0){

        let t = tokens.map(t=>t.token)  
        t = [...new Set(t)];

        this.notificationPayload.registration_ids = t;
        this.notificationPayload.notification.title = message.title;
        this.notificationPayload.notification.body = message.body;
        this.notificationPayload.notification.image = message.image;
        this.notificationPayload.notification.click_action = message.click_action;
        this.notificationPayload.android.notification.imageUrl = message.image;

        for(let key in message.data){
          this.notificationPayload.data[key] = message.data[key]
        }
        this.notificationPayload.data['title']=message.title
        this.notificationPayload.data['body']=message.body
  
        const headers = new HttpHeaders({
          Authorization: this.keyManager.getFCMKey(),
          'Content-Type': 'application/json;'
        });
  
        this.httpClient.post<any>(this.fcmServer,JSON.stringify(this.notificationPayload), { headers }).subscribe(o => {

        });
      }
    })
  }  

  // subscribe to topic
   subscribeTokensToTopic(notification:any,topic: any, tokens: any) {
    try {
      this.apiService.subscribeTokensToTopic(topic, tokens).subscribe(async (data) => {
        // this.successMessage("Topic subscription is successful");
        await this.sendNotificationUsingTopic(notification, topic);

        // unsubscribe registration tokens for a topic
        this.unSubscribeTokensFromTopic(topic, tokens);
      }, (error) => {
      });
    } catch (err) {
      this.errorMessage("Subscription failed");
    }
  }

  unSubscribeTokensFromTopic(topic: any, tokens: any) {
    try {
      this.apiService.unsubscribeRegistrationTokensFromTopic(topic, tokens).subscribe((data) => {

        // this.successMessage("Topic subscription is successful");
      });
    } catch (err) {
      this.errorMessage("Subscription failed");
    }
  }


    // send notification for users using topic
    sendNotificationUsingTopic(message: NotificationModel, topic: any){
      let notificationData: any = {
        topic: null,
        notification:{
          title: "",
          body:"",
          image:null
        },
        data:{
          title: null,
          body: null
        }
      };
      notificationData.topic = topic;
      notificationData.notification.title = message.title;
      notificationData.notification.body = message.body;
      notificationData.notification.image = message.image;
      // notificationData.notificationData.click_action = message.click_action;
      for (let key in message.data) {
        notificationData.data[key] = message.data[key]
      }
      notificationData.data['title'] = message.title
      notificationData.data['body'] = message.body
  
      // // make an api call to get authentication token
    
      // const headers = new HttpHeaders({
      //   'Authorization': 'Bearer ' + "ya29.a0AfB_byCS13K-uII3kG8PCr9s5eV9Ati-g76ilQyZ9DSWsqwK47RC0cXxYF4g2sc89Efza_IuREdoX9YVmSb9yGo-7I_Rc2hGmzbm9t5HZihegFer6DlVFASXxUalLzLSnSlIdyOe9nS82Jxw4C_ZQtZqUQnUUCKDmVvmCvwaCgYKAWkSARESFQHsvYlsbYHHllXfrVkLhGgr34ebXg0174",
      //   'Content-Type': 'application/json;',
      //   "x-goog-user-project":"demopersonalapp"
      // });
      
      // this.httpClient.post<any>(this.fcmServer, JSON.stringify({message: notificationData}), { headers }).subscribe(o => {
      // });   

       // make an api call to get authentication token
    this.apiService.senFCMMessageToTopic(message.title,message.body, message.image, topic).subscribe(()=>{
      this.successMessage("Notification Message sent successfully!")
    }, (error) => {
      this.errorMessage("Notification message failed!")
    });
  
    }

    // toast message handler
    successMessage(message) {
      this.toastr.success(message, "Success");
    }
  
    errorMessage(message) {
      this.toastr.error(message, "Error");
    }

}
