<div *ngIf="setupDone && !deletedProject"
  class="bg-white md:max-w-5xl w-full md:mx-auto mx-2 h-max mt-6 border-solid border-2 p-4 border-gray-200 shadow-sm rounded-md gap-y-2">
  <div class="flex flex-col md:flex-row gap-x-4 space-y-2 md:space-y-0">
    <p class="font-bold text-xl">{{storedAppName}} {{currentAppName}} progress</p>
  </div>



  <div class="modal-action flex justify-between mx-2 ">
  </div>

  <div>
    <p>Backend configured name :- <b> {{ backendConfName }} </b></p><br />
    <!-- <p>Analytics account :- <b>{{defaultAccName}}</b></p><br /> -->
    <p class="text-sm">App setup is done.</p>
    <p> <span class="text-green-600">Browse to {{storedAppName}} {{currentAppName | lowercase}} app by clicking the link
        below.</span>

      <br />
      <a class="underline text-blue-600
                     hover:text-grey-900
                     visited:text-grey-700" href={{getLink}} target="_blank">
        {{storedAppName}}
      </a>
    </p>

    <!-- delete project -->
    <div class="flex gap-2">
      <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded w-64"
        type="button" (click)="deleteProject()">Delete project</button><br />
      <!-- <button *ngIf="!newRequest && projectId" class="bg-green-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded w-64"
        type="button" (click)="activateBillingCopy()">Migrate data</button><br /> -->

    </div>
  </div>
</div>

<div *ngIf="deletedProject"
  class="bg-white md:max-w-5xl w-full md:mx-auto mx-2 h-max mt-6 border-solid border-2 p-4 border-gray-200 shadow-sm rounded-md gap-y-2">
  <div class="flex flex-col md:flex-row gap-x-4 space-y-2 md:space-y-0">
    <p class="font-bold text-xl">{{storedAppName}} {{currentAppName}} progress</p>
  </div>

  <div class="modal-action flex justify-between mx-2 ">
  </div>

  <div>
    <p>Backend configured name :- <b> {{ backendConfName }} </b></p><br />
    <!-- <p>Analytics account :- <b>{{defaultAccName}}</b></p><br /> -->
    <p class="text-sm">App setup is done.</p>
    <p> <span class="text-green-600">Browse to {{storedAppName}} {{currentAppName | lowercase}} by clicking the link
        below.</span>
      <br />
      <a class="underline text-blue-600
                     hover:text-grey-900
                     visited:text-grey-700" href={{getLink}}>
        {{storedAppName}}
      </a>
    </p>

    <!-- deleted project -->
    <p class="text-red font-bold"> This project configuration is removed. </p>
  </div>
</div>

<div *ngIf="!setupDone"
  class="bg-white md:max-w-5xl w-full md:mx-auto mx-2 h-max mt-6 border-solid border-2 p-4 border-gray-200 shadow-sm rounded-md gap-y-2">
  <!-- title -->
  <div class="flex flex-col md:flex-row gap-x-4 space-y-2 md:space-y-0">
    <p class="font-bold text-xl">{{appMainName}} {{currentAppName | lowercase}} progress</p>
    <div class="border-solid border-2 border-yellow-500 px-4 py-1 w-max  rounded-md ">
      <p class="text-sm font-bold text-yellow-500">{{appStatus}}</p>
    </div>

  </div>

  <div class="modal-action flex justify-between  ">
    <button (click)="openAcceptRequestModal()" class="font-bold text-teal-700 text-sm">Select Analytics Account</button>  

    <!-- <p class="float-right text-green-800">
        <button (click)="navigateToBilling()" class="font-bold text-teal-700 text-sm">Select Billing Account</button>
    </p> -->

  </div>

  <div>

    <br />
    <p>Url :- <b> {{ deployedUrl }}.{{extentionDomain}} </b></p>
    <p>Backend configured name :- <b> {{ appMainName }} </b></p><br />
    <p class="text-sm">You can check manually the configurations by going to the created project by the name in the
      above specific name.</p>
  </div>
  <p class="pt-4"><span class="font-bold">{{percent}}% Complete</span> <span class="text-sm px-5">{{currentTaskName}}
    </span></p>
  <!-- progress bar -->
  <div>
    <progress class="progress progress-success w-full" value="{{percent}}" max="100"></progress>
    <a href="" class="font-bold text-teal-700 text-sm flex justify-end">End Process</a>
  </div>

  <!-- tasks -->
  <div class="mt-2">

    <div class="flex flex-wrap">
      <div class="w-full">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row tabs">
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              (click)="toggleTabs(1)"
              [ngClass]="{'text-pink-600 bg-white': openTab !== 1, 'text-white bg-pink-600': openTab === 1}">
              Pending tasks
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              (click)="toggleTabs(2)"
              [ngClass]="{'text-pink-600 bg-white': openTab !== 2, 'text-white bg-pink-600': openTab === 2}">
              Successful tasks
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              (click)="toggleTabs(3)"
              [ngClass]="{'text-pink-600 bg-white': openTab !== 3, 'text-white bg-pink-600': openTab === 3}">
              Failed tasks
            </a>
          </li>
        </ul>
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div class="px-4 py-5 flex-auto">
            <div class="tab-content tab-space">
              <div [ngClass]="{'hidden': openTab !== 1, 'block': openTab === 1}">

                <!-- list of tasks --- Start -->
                <div class="space-y-4 mt-4 ">

                  <div *ngIf="valid">

                    <p class="text-sm">It might take a while so please wait until it finishes the execution.
                      You can see the progress bar and the error that will be displayed to know the status of the
                      process.</p>
                    <br />
                    <p class="text-sm">Click the below button to start the setup.</p>
                    <div *ngIf="!cloudProjectCalled">
                      <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded w-64"
                        type="button" (click)="cloudProject()">Start</button><br />
                    </div>

                    <div *ngIf="retry">
                      <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded w-64"
                        type="button" (click)="appendNewId()">Retry</button><br />
                    </div>


                  </div>

                  <p *ngIf="!valid" class="text-blue-500">In order to start the exectution please select
                    other default analytics account <br /> or create a new one by going to <a
                      routerLink="/admin/analytics-setup"><b>Select Analytics Account</b></a> .</p>

                  <!-- <p *ngIf="!valid" class="text-blue-500">Please select
                    other default billing account by going to <a
                      routerLink="/admin/billing-setup"><b>Select Billing Account</b></a> .</p> -->


                  <p *ngIf="deploySuccess"> <span class="text-green-600">Browse to {{appName}} goingmobile app by
                      clicking the link below.</span>

                    <br />
                    <a class="underline text-blue-600
                                   hover:text-grey-900
                                   visited:text-grey-700" href={{link}}>
                      {{appName}}
                    </a>
                  </p>

                </div>

                <!-- list of tasks --- end -->

              </div>
              <div [ngClass]="{'hidden': openTab !== 2, 'block': openTab === 2}">

                <!-- list of successful tasks --- start  -->

                <div *ngIf="successTasks">
                  <div *ngFor="let s of successTasks" class="flex w-full items-center px-4 py-2 space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="green" viewBox="0 0 512 512">
                      <path
                        d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                    <p>{{s}}</p>
                    <hr>

                  </div>
                </div>

                <div *ngIf="successTasks.length === 0">
                  <p>No successful tasks.</p>
                </div>
                <!-- list of successful tasks --- end -->
              </div>
              <div [ngClass]="{'hidden': openTab !== 3, 'block': openTab === 3}">

                <!-- list of failed tasks --- start -->

                <div *ngIf="failedTasks">
                  <div *ngFor="let f of failedTasks; let i = index;"
                    class="flex w-full items-center px-4 py-2 space-x-2">
                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24"
                      stroke="currentColor" aria-hidden="false">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <p>{{f}}</p>
                    <hr>

                  </div>

                </div>

                <div *ngIf="failedTasks.length === 0">

                  <p>No failed tasks.</p>

                </div>
                <!-- list of failed tasks --- end -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div type="checkbox" *ngIf="acceptOpenModal" class="modal"
  [ngClass]="{'modal-open': acceptOpenModal, 'modal-action': !acceptOpenModal }">
  <div class="modal-box">
    <div class="modal-content">

      <div class="flex justify-end "(click)="closeModal()"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>

      <h3 class="font-bold text-lg text-center mb-1">App info</h3>
      <hr />

      <!-- my form start -->

      <div class="w-full max-w-xs">
        <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 items-center">
          <div class="items-center ">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="appname">
              App name
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="appName" type="text" placeholder={{appName}} value={{appName}} disabled={{disabled}}>
          </div>
          <div class="mb-6">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="backConfName">
              Backend Configured Name
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="accountId" type="text" placeholder={{appMainName}} value={{appMainName}} disabled={{disabled}}>
          </div>
          <!-- dropdown start -->

          <div class="mb-6">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="backConfName">
              Analytics Account Name
            </label>

            <!-- <p *ngIf="isUndefiendSelected" class="text-red-600">Please select to proceed.</p> -->
            <p *ngIf="listOfValidAccountIds.length == 0">
              1. <a href="https://analytics.google.com/analytics/web/" class="text-slate-500 hover:text-blue-600">Please
                go the google analytics dashboard and create a new account. </a> <br />
              2. Add the email <span
                class="text-slate-500 hover:text-blue-600">service-manager&#64;resource-manager-373817.iam.gserviceaccount.com</span>
              in the
              <span class="underline hover:underline-offset-1"> admin setting ===> Account Access Management </span>
              with a full control an Administrator role.<br />
              3. If done correctly you will find the new added account on the list of accountIds down below.
            </p>
            <select *ngIf="listOfValidAccountIds.length != 0" (change)="selectOption($event.target.value)">
              <p>{{selected}}</p>
              <!-- <p *ngIf="selected.length == 0">Select the account id</p> -->
              <option hidden>Choose</option>
              <option *ngFor="let acc of listOfValidAccountIds" [value]="acc.accountId">
                {{acc.name}}
              </option>
            </select>
          </div>

        </form>

      </div>

      <div class="modal-action items-center justify-between mx-6">
        <label for="my-modal-acceptPending" (click)="closeModal()"
          class="font-bold text-customTeal border-none">Cancel</label>
        <label for="my-model">
          <button type="button" class="py-2 px-4 bg-green-400 text-white border-red-400 rounded-md"
            (click)="updateConfigSetting()">Save</button></label>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-users" *ngIf="declineOpenModal" class="modal"
  [ngClass]="{'modal-open': declineOpenModal, 'modal-action': !declineOpenModal }">
  <div class="modal-box">
    <div class="modal-content">
      <div class="flex justify-end "(click)="closeModal()"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>

      <h3 class="font-bold text-lg text-center mb-1">Reject Account</h3>
      <hr />
      <h3 class="font-bold text-md text-center mt-2">Are you sure you want to Reject this Account?</h3>
      <p class="py-4 text-center">Users who have rejected accounts won't be able to signup.</p>
      <form [formGroup]="declinedForm" (ngSubmit)="declineRequest(declinedForm.value)">
        <p class="font-bold">Reason <span class="text-red-500">*</span></p>
        <textarea id="reason" name="reason" type="text" formControlName="reason"
          class="border rounded py-1 w-full h-24 resize-y text-gray-700  bg-veryLightGray"></textarea>

        <div class="modal-action items-center justify-between mx-6">
          <button (click)="closeModal()" class="font-bold text-customTeal border-none">Cancel</button>

        </div>
      </form>
    </div>
  </div>
</div>