import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatGptResponseModel } from 'src/app/models/chatGptModel';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { ChatGptService } from 'src/app/services/api/chat-gpt.serivce';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { HelperService } from 'src/app/services/helper/helper';
import { RoutMetaService } from 'src/app/services/helper/rout-meta.service';
import { KeyWordsHighlight } from '../../../../Pipes/KeyWordsHighlightPipe';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-seo-setting',
  templateUrl: './seo-setting.component.html',
  styleUrl: './seo-setting.component.css'
})
export class SeoSettingComponent implements OnInit {
  @Output() seoCreated = new EventEmitter<string>();

  appSettingsForm: FormGroup;
  metaTagSettings: any = null
  changeMetaTagSetting: boolean = false;
  maxKeyWords: number = GlobalConstants.maxChatGptKeywords
  maxHashTags: number = GlobalConstants.maxChatGptHashTags
  appSections = GlobalConstants.AppSections;
  model: any = {};
  editModalOpen = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  //generate
  generateDescription: any;
  generateKeyword: any;
  generateHashTag: any;

  chatResponse = [];
  resLoading: boolean = false;
  selectedResponse: any;
  user: any;
  questionArray: any = [];

  @Input() theInput;
  @Output() onMessageSelect = new EventEmitter();
  generatedQuestion: string = '';
  chatGlobal: any;
  flatArray: any;
  questionAnswerArray = []; // This will store the question-answer pairs

  saveSuggest: boolean = false;
  saveButton = false;
  appSettings: any
  fetchedSuggestion: boolean = false;

  meta: any;

  seoStep = 0
  firstStep: boolean;
  secondStep: boolean;
  metasetting

  allEmpty: boolean = false;


  constructor(private fb: FormBuilder,
    public appSettingsService: AppSettingsService,
    private routMeta: RoutMetaService,
    private helperService: HelperService,
    private envService: EnvironmentService,
    private chatGptService: ChatGptService,
  ) {

  }
  ngOnInit(): void {

    // fetch exsting metatag
    this.getMetaTagSettings();


    this.appSettingsForm = this.fb.group({
      appTitle: [''],
      description: [''],
      keyWords: this.fb.array([]),
      keyWordQuery: [''],
      hashTagsQuery: [''],
      hashTags: this.fb.array([]),
      quesAns: this.fb.array([]),
      answers: this.fb.array([], Validators.required),
      questions: this.fb.array([]),

    });

    //gets the metadata to render 
    this.appSettingsService.getMetadata().valueChanges()
      .pipe(take(1))
      .subscribe({
        next: (meta: any) => {
          if (Array.isArray(meta) && meta.length > 0) {
            const model = meta?.[0];
            this.meta = model;
            this.model = model;
            console.log("Model Answers", model.answers)
            this.appSettingsForm.patchValue({
              appTitle: model?.appTitle || 'title',
              description: model?.description || 'Description',
              answers: model?.answers || '',
            });
          }
        },
        error: (error) => {
          console.error('Error fetching metadata:', error);
        }
      });

    // this.metasetting = this.appSettingsService.getMetadata().valueChanges().subscribe((meta: any) => {
    //   if (meta && meta.length > 0) {
    //     this.meta = meta[0];
    //     this.model = meta[0];
    //     if (this.model) {
    //       if (this.model.keyWords && this.model.keyWords.length > 0) {
    //         this.appSettingsForm.patchValue({
    //           appTitle: this.model.appTitle ? this.model.appTitle : 'title',
    //           description: this.model.description ? this.model.description : 'Description',
    //           answers: this.model.answers ? this.model.answers : '',
    //         })

    //       }
    //     }
    //   }
    //   this.metasetting.unsubscribe();
    // })

  }

  suggest(values) {
    this.saveButton = true;
    let message = this.helperService.truncateHTML(this.description.value, '300')

    if (message) {
      this.generateDescription = message ? `Write a description of the sentence below using exactly 25 words` + message : 'hello';
      // this.generateKeyword = message + `\n generate only 5 keywords from the sentence above and make it inside an array`;
      // this.generateHashTag = message + '\n generate only 5 hashtags from the sentence above'
      message = message + ` in ${values.wordCount} words`

      console.log("Generate Description", this.generateDescription)
      this.chatGptService.startChat(this.generateDescription).subscribe(
        (res: any) => {
          try {
            const response = JSON.parse(res);  // Single parsing instead of nested
            const choices = response?.choices || [];

            if (choices.length > 0) {
              // Append all choices to chatResponse
              choices.forEach((choice) => this.chatResponse.push(choice));

              // Get the last response and select it
              const lastResponse = this.chatResponse[this.chatResponse.length - 1];
              this.selectResponseChoice(lastResponse, false);

              // Update the generated description with the last message content
              this.generateDescription = lastResponse.message?.content || this.generateDescription;
            }

          } catch (error) {
            console.error('Error parsing the response:', error);
            // Handle the error accordingly, maybe show a message to the user
          }
        },
        (err) => {
          this.resLoading = false;
          console.error('Error during request:', err);
          // Handle the request error
        }
      );

      console.log("is all empty", this.allEmpty);

      this.onSubmit();
    }
  }

  getRange(start: number, end: number): number[] {
    const range = [];
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  }

  selectResponseChoice(choice, useText: boolean) {
    this.selectedResponse = choice.message.content;
    if (useText) {
      this.selectMessage();
    }
  }

  selectMessage() {
    let response = new ChatGptResponseModel();
    response.input = this.theInput;
    response.message = this.selectedResponse;

    this.onMessageSelect.emit(response);
  }

  confirmUpdate(appSettings) {

    this.editModalOpen = true;

    // Build the question-answer array from flatArray and answers
    const questionAnswerArray = this.flatArray.map((question, index) => ({
      question: question,
      answer: this.answers.value[index] || "",// Default to empty string if no answer is provided
    }));

    // Check if model exists and is not empty
    if (this.model && this.model.length > 0) {

      // Update appSettings object with metadata
      appSettings.id = this.model[0].id;
      appSettings.description = this.generateDescription;
      appSettings.keyWords = this.generateKeyword;
      appSettings.hashTags = this.generateHashTag;
      appSettings.quesAns = questionAnswerArray;

      // Update metadata settings
      this.appSettingsService.updateMetaDataSettings(appSettings).then((response) => {
        this.seoCreated.emit('seoCreated');
        this.getMetaTagSettings();
      });

    } else {

      // Save new metadata settings
      this.appSettingsService.saveMetaDataSettings(appSettings).then((response) => {
        this.getMetaTagSettings();
        this.seoCreated.emit('seoCreated');
      });

    }
  }

  getMetaTagSettings() {
    this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.appTitle)
        this.routMeta.updateTitle(this.appSettingsForm.get('appTitle').value);
        this.appSettingsForm.get('description').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(this.appSettingsForm.get('description').value);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(this.appSettingsForm.get('keyWords').value);

      }
    });
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }

  selectKeyWord() {
    let wordExists = this.keyWords.value.some(w => w === this.keyWordQuery.value)
    console.log("The keyword", this.keyWordQuery.value, "Word exists is", wordExists)
    if (wordExists) return
    this.generateKeyword.push(this.keyWordQuery.value)
    this.keyWordQuery.patchValue('')
  }
  selectHashTags() {
    let wordExists = this.hashTags.value.some(w => w === this.hashTagsQuery.value)
    console.log("The hashtag", this.hashTagsQuery.value, "Word exists is", wordExists)
    if (wordExists) return
    this.generateHashTag.push(this.hashTagsQuery.value)
    this.hashTagsQuery.patchValue('')
  }

  removeKeyWord(index) {
    console.log("The keyword to be removed", this.generateKeyword[index], "****>the index", index)
    // this.generateKeyword.removeAt(index)
    this.generateKeyword.splice(index, 1);

  }
  removeHashTags(index) {
    console.log("The Hashtag to be removed", this.generateHashTag[index], "--->>the index", index)
    // this.generateHashTag.removeAt(index)
    this.generateHashTag.splice(index, 1);

  }

  closeEditModal() {
    this.editModalOpen = false;
  }

  onSubmit() {
    this.resLoading = true
    if (this.description) {
      if (environment.firstNation.Origin != 'FNLeaders') {
        this.chatGlobal = this.chatGptService.generateQuestion(this.description.value).subscribe(response => {
          this.generatedQuestion = response.choices[0].message.content.trim();
          this.questionArray = [];
          this.questionArray.push(this.generatedQuestion)

          // Convert each string into an array
          const parsedArrays = this.questionArray.map(str => JSON.parse(str));

          // If you want to flatten them into a single array of questions
          this.flatArray = parsedArrays.flat();

          this.answers.clear();
          this.flatArray.forEach(() => {
            this.answers.push(this.fb.control(''));  // Add an empty control for each answer
            console.log("the answer length is", this.answers.length, "and the value is", this.answers.value)
          });

          // Initialize an empty array to hold the question-answer objects
          this.seoStep = 1
          this.firstStep = true
          this.chatGlobal.unsubscribe();
          this.resLoading = false;

        });
      } else {
        console.log("It is here")
        this.flatArray = ["What are your core values as a leader, and how do they influence your decisions for the community?",
          "What is your vision for the future of your Nation, and how would you like the website to support that vision?",
          "What achievements or initiatives are you most proud of as a leader?",
          "How would you like the website to reflect your personal connection to the community and its culture?"];
        this.answers.clear();
        this.flatArray.forEach(() => {
          this.answers.push(this.fb.control(''));  // Add an empty control for each answer
          console.log("the answer length is", this.answers.length, "and the value is", this.answers.value)
        });
        // this.questionArray = this.flatArray;
        // Initialize an empty array to hold the question-answer objects
        this.seoStep = 1
        this.firstStep = true
        //  this.chatGlobal.unsubscribe();
        this.resLoading = false;
      }
      console.log("and here", this.flatArray)
    }
    console.log("the all empty", this.allEmpty)
  }
  close() {
    this.saveSuggest = false;
  }

  allTextareasFilled(): boolean {
    return this.answers.controls.every(control => control.value.trim() !== '');
  }

  saveSuggestion() {
    // this.allEmpty = false;
    // if (this.allTextareasFilled()) {
    //     this.allEmpty = true;
    // }
    // else{
    this.fetchedSuggestion = true;
    const fetchMetaInfo = "Based on the sentences in the array below and the description please generate 5 hashtags and put them in an array format. The array: " + this.answers.value + 'and' + this.description
    this.chatGptService.startChat(fetchMetaInfo).subscribe((res: any) => {
      if (res) {
        let response = JSON.parse(JSON.parse(res));
        this.generateHashTag = response.choices[0].message.content;
        console.log("This is how generateHashtag look like", this.generateHashTag)
        console.log("And this is the length", this.generateHashTag.length)
        if (Array.isArray(this.generateHashTag) && this.generateHashTag.length > 0) {
          this.generateHashTag.forEach((word) => this.hashTags.push(this.fb.control(this.helperService.truncateHTML(word, '100'))));
        } else if (this.isString(this.generateHashTag)) {
          // this.generateHashTag = JSON.parse(this.generateHashTag);

          // var arrayData = this.generateHashTag.split(' ').map(tag => tag.trim())
          // var stringData = arrayData;
          // var joinedString = stringData.join(''); // Join all the strings to form a valid JSON array
          // this.generateHashTag = JSON.parse(joinedString); 
          const arrayData = this.generateHashTag
            .split(' ') // Split by spaces
            .filter(tag => tag.trim() !== '') // Remove empty strings
            .map(tag => this.helperService.truncateHTML(tag.trim(), '100')); // Trim each tag
          this.generateHashTag = arrayData; // Assign back as an array 
          console.log("GenerateHashtag", this.generateHashTag)

        }
        // else if(this.isString(this.generateHashTag)){
        //   this.generateHashTag = JSON.parse(this.generateKeyword);
        // } 
        else {
          JSON.parse(this.generateHashTag).forEach((word) => this.hashTags.push(this.fb.control(this.helperService.truncateHTML(word, '100'))));
        }
      }
    })
    //-------
    const fetchMetaKeyword = "Based on the sentences in the array below and the description please generate 5 keywords and put them in an array format. The array: " + this.answers.value + 'and' + this.description
    this.chatGptService.startChat(fetchMetaKeyword).subscribe((res: any) => {
      if (res) {
        let response = JSON.parse(JSON.parse(res));
        this.generateKeyword = response.choices[0].message.content
        if (Array.isArray(this.generateKeyword) && this.generateKeyword.length > 0) {
          this.generateKeyword.forEach((word) => this.keyWords.push(this.fb.control(this.helperService.truncateHTML(word, '100'))));
        } else if (this.isString(this.generateKeyword)) {
          this.generateKeyword = JSON.parse(this.generateKeyword);
          if (Array.isArray(this.generateKeyword) && this.generateKeyword.length > 0) {
            this.generateKeyword.forEach((word) => this.keyWords.push(this.fb.control(this.helperService.truncateHTML(word, '100'))));
          }
          // this.generateKeyword = this.generateKeyword.split(',').map(tag => tag.trim())
        } else {
          JSON.parse(this.generateKeyword).forEach((word) => this.keyWords.push(this.fb.control(this.helperService.truncateHTML(word, '100'))));
        }
      }
    })



    // }
    this.seoStep = 2
    this.secondStep = true
  }
  nextStep(number) {
    if (this.firstStep && number == 1) {
      this.seoStep = 1
    } else if (this.secondStep && number == 2) {

      this.seoStep = 2
    }
  }
  saveAllSuggestions() {
    if (this.generateHashTag && this.generateKeyword) {
      this.model.hashTags = this.generateHashTag ? this.generateHashTag : [];
      this.model.keyWords = this.generateKeyword ? this.generateKeyword : [];
      this.model.answers = this.answers.value;
      this.model.questions = this.flatArray;
      this.model.description = this.generateDescription ? this.generateDescription : ''
      this.model.appTitle = this.appTitle.value;

      this.appSettingsService.getMetadata().valueChanges().subscribe((data) => {
        if (data && data.length > 0) {
          this.appSettingsService.updateMetaDataSettings(this.model);
          this.seoCreated.emit('seoCreated')
        } else {
          this.appSettingsService.saveMetaDataSettings(this.model);
          this.seoCreated.emit('seoCreated')
        }
      })
    }


  }

  reset() {
    this.questionArray = []
  }
  isString(value: any): boolean {
    return typeof value === 'string';
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text?.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text?.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';

  }

  get appTitle() { return this.appSettingsForm.get('appTitle') };
  get description() { return this.appSettingsForm.get('description') };
  get keyWords() { return this.appSettingsForm.get('keyWords') as FormArray }
  get hashTags() { return this.appSettingsForm.get('hashTags') as FormArray }
  get answers() { return this.appSettingsForm.get('answers') as FormArray; }
  get questions() { return this.appSettingsForm.get('questions') as FormArray; }
  get keyWordQuery() { return this.appSettingsForm.get('keyWordQuery') }
  get hashTagsQuery() { return this.appSettingsForm.get('hashTagsQuery') }

}
