import { UserService } from './../../../services/firebase/user.service';
import { FriendsService } from 'src/app/services/firebase/friends.service';
import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Form, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { ConversationsService } from 'src/app/services/firebase/conversations.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { ForwardedMessageModel, MessagesModel } from 'src/app/models/Messages.Model';
import { PrivateConversationsModel, GroupConversationsModel } from 'src/app/models/Conversations.Model';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UnreadCountModel } from 'src/app/models/UnreadCountModel';
import { MediaService } from 'src/app/services/api/media.service';
import { environment } from 'src/environments/environment';
import { CommunityService } from 'src/app/services/api/community.service';
UserService
// declare var M: any;
// declare var $: any;

@Component({
  selector: 'app-group-chats-list',
  templateUrl: './group-chats-list.component.html',
  styleUrls: ['./group-chats-list.component.css'],
  host: {
    "(window:click)": "closeContextMenu()"
  }
})
export class GroupChatsListComponent implements OnInit, AfterViewChecked {

  // @ViewChild('scrollMe', { static: false }) scrollMe: ElementRef;


  id = '';
  currentUser: any;
  privateChats = [];
  groupChats = [];
  scrWidth;
  selectedConver: any;
  chatSelected: any;
  search = false;
  newCircleForm: UntypedFormGroup;
  messageForm: UntypedFormGroup;
  editCircleInfoForm: UntypedFormGroup;
  AdminNewCircleForm: UntypedFormGroup;
  FilterMemberForm: UntypedFormGroup;
  newMessage = '';
  newMessageObj: MessagesModel;
  format = ''; url: any;
  group = false;
  searchQuery = '';
  searchForwardQuery = '';
  searchMemberQuery = '';
  imgLink = 'https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp';
  usersForSearch = [];
  chats = [];
  searchResult = [];
  messages = [];
  newConversation;
  loadingMessages = false;
  loadingChats = false;
  loadingGroupChats = false;
  selectedImageLink = '';
  today = new Date();
  selectedMember: any = [];
  selectedMemeberName = '';
  groupProfileImg = 'https://api.goingmobile.app/media/personal-app-assets/img/webp/default-group.webp';
  searchCircleMember = false;
  groupMembersList: any = [];
  publicGroupsList: any = [];
  previousMembersList: any = [];
  allMembers: any = [];
  binDoc: File; binDocList: any = [];
  groupUnreadCounts: any = [];
  noNotification = false;
  selectedFilesForDisplay: any = [];

  isAdmin = false;

  useAgeRange = false;
  useGender = false;
  useCommunityName = false;

  isHidden: Boolean = true;
  xPosTabMenu = 0;
  yPosTabMenu = 0;

  selectedMessage: any;
  editMessage = false;

  nationOfUse = environment.firstNation.displayName;
  nationCommunities: any = [];
  isCandidate = false;
  everyone = true;
  specificAudience = false;
  allUnfilteredMembers: any = [];
  allFilteredCandidateContact: any = [];
  searchAndAdd = true;

  constructor(private fb: UntypedFormBuilder,
              private fireMembersService: FireMembersService,
              private conversationsService: ConversationsService,
              private authService: AuthService,
              private messagingService: MessagingService,
              private mediaService: MediaService,
              private communityService: CommunityService,
              private cd: ChangeDetectorRef,
              private friendsService: FriendsService,
              private userService: UserService
    ) {
      this.getScreenSize();
    }

  ngOnInit() {
    // this.scrollToBottom();
    this.loadingChats = true;
    this.loadingGroupChats = true;

    this.messageForm = this.fb.group({
      attachFile: [null],
      message: ['', [Validators.required]]
    });

    this.newCircleForm = this.fb.group({
      circleName: ['', [Validators.required]],
      isPrivateCircle: [false, [Validators.required]],
      profileImage: ['']

    });
    this.editCircleInfoForm = this.fb.group({
      circleName: ['', [Validators.required]],
      isPrivateCircle: [false, [Validators.required]],
      profileImage: ['']
    });

    this.AdminNewCircleForm = this.fb.group({
      circleName: ['', [Validators.required]],
      profileImage: [''],
      minAge: ['', Validators.min(0)],
      maxAge: [''],
      gender: [''],
      community: ['']
    });
    this.FilterMemberForm = this.fb.group({
      minAge: ['', Validators.min(0)],
      maxAge: [''],
      gender: [''],
      community: ['']
    });

    this.currentUser = this.authService.getGlobalUser();

    if (this.currentUser) {
      if (this.currentUser.role == "Admin") {
        this.isAdmin = true;
      }
      this.id = this.currentUser.uid;


      // this.id = "IVv2L1TYhJTSSuh37scURdDczX73";

    }

    // get list of chats
    if (this.id) {
      this.conversationsService.getPrivateChatsByUserId1(this.id).valueChanges().subscribe(chats => {
        if (chats) {
          chats.forEach(element => {
            let index = this.privateChats.findIndex(chat => chat.id === element.id);
            if (index >= 0) {
              this.privateChats.splice(index, 1);
            }
            this.privateChats.push(element);
          });
          this.privateChats.forEach(element => {
            if (element.user_2.id === this.id) {
              [element.user_1, element.user_2] = [element.user_2, element.user_1];
            }
          });
          // Sort out the list whenever there has been any change
          this.privateChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());

        }
      });

      this.conversationsService.getPrivateChatsByUserId2(this.id).valueChanges().subscribe(chats => {
        if (chats) {

          chats.forEach(element => {
            let index = this.privateChats.findIndex(chat => chat.id === element.id);
            if (index >= 0) {
              this.privateChats.splice(index, 1);
            }
            this.privateChats.push(element);
          });
          this.privateChats.forEach(element => {
            if (element.user_2.id === this.id) {
              [element.user_1, element.user_2] = [element.user_2, element.user_1];
            }
          });
          // Sort out the list whenever there has been any change
          this.privateChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
        }
        this.loadingChats = false;
        this.loadingGroupChats = false;
      });

      // get groups of this user
      this.conversationsService.getGroupChatsByUserId(this.id).valueChanges().subscribe(chats => {
        if (chats) {
          this.groupChats = [];
          chats.forEach(element => {
            this.groupChats.push(element);
          });
          this.groupChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
          this.messagingService.getUnreadCountByUserId(this.id).valueChanges().subscribe(unreadCounts => {
            if (unreadCounts) {
              this.groupUnreadCounts = unreadCounts;
              if (this.groupChats) {
                this.groupChats = this.groupChats.map(groupChat => ({ ...groupChat, ...(({ unreadCount }) => ({ unreadCount }))(this.groupUnreadCounts.find(elm => groupChat.id == elm.ConverId)) }));
              }
            }
          });
        }
      });
    }
    const defaultgroupProfile = this.groupProfileImg;
    // $(document).ready(() => {
    //   $('.materialboxed').materialbox();
    //   $('.tabs').tabs();
    //   $('.modal').modal();
    //   $('.dropdown-trigger').dropdown();
    //   $('.slider').slider({
    //     height: 300
    //   });
    //   $('select').formSelect();

    //   $('.modal#addGroup').modal({

    //     onCloseStart() {
    //       $('.modal#addGroup .profile-picture').attr("src", defaultgroupProfile);
    //       // $('#addGroup form').trigger("reset");
    //     }
    //   });

    // });

  }

  ngAfterViewChecked() {
    // to ignore changes because of context menu
    // if (this.chatSelected && this.isHidden) {
    //   this.scrollToBottom();
    // }
  }

  // // for chat history container
  // scrollToBottom(): void {
  //   try {
  //     this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
  //   } catch (err) {
  //   }
  // }

  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }


  // ********************************** Common Functionalities **************


  // on send message click
  send(value: any) {

    this.newMessage = value.message;
    if (!this.editMessage) {
      if (this.newMessage == null || this.newMessage.trim() === '') {
        return;
      }
      try {
        this.sendMessage(this.newMessage)
      } catch (err) {
        console.error(err);
      }
    } else {
      if(this.selectedMessage.message != this.newMessage){
        this.selectedMessage.message = this.newMessage;
        this.messagingService.editMessage(this.selectedMessage);
        this.editMessage = false;
        let lastMessage = this.messages[this.messages.length - 1];
        if (lastMessage.id == this.selectedMessage.id) {
          this.selectedConver.lastMessage.message = this.newMessage;
          if (!this.group) {
            this.conversationsService.updatePrivatelastMessage(this.selectedConver.id, this.selectedConver.lastMessage)

          } else {
            this.conversationsService.updateGrouplastMessage(this.selectedConver.id, this.selectedConver.lastMessage)

          }
        }

        } else{
        this.editMessage = false;
        this.messageForm.reset();
        // M.textareaAutoResize($('.textarea'));
        return;
      }

      this.messageForm.reset();
      // M.textareaAutoResize($('.textarea'));
    }

  }

  // create a new message
  sendMessage(message: any) {

    this.messageForm.reset();
    // M.textareaAutoResize($('.textarea'));

    // create a new message object
    this.newMessageObj = new MessagesModel();
    this.newMessageObj.timeStamp = new Date();
    this.newMessageObj.message = message;
    this.newMessageObj.fromId = this.id;
    this.newMessageObj.conversationId = this.selectedConver.id;
    this.newMessageObj.attachment = { type: 'none', link: '', name: '' };
    if (this.group) {
      this.newMessageObj.toId = this.selectedConver.id;
    } else {
      this.newMessageObj.toId = this.selectedConver.user_2.id;
    }
    this.newMessageObj.edited = false;
    this.newMessageObj.likes = {
      count: 0,
      users: []
    }; this.messagingService.createMessage(this.newMessageObj);
    // create last message for this conversation
    let lastMessage = { message: '', timeSent: null };
    lastMessage.timeSent = this.newMessageObj.timeStamp;


    if (!this.group) {
      lastMessage.message = this.newMessageObj.message;
      this.conversationsService.updatePrivatelastMessage(this.selectedConver.id, lastMessage);
      // unread notification to the other user
      this.conversationsService.addUnreadCount(this.selectedConver.id, this.selectedConver.user_2.id);

    } else if (this.group) {
      lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${this.newMessageObj.message}`;
      this.conversationsService.updateGrouplastMessage(this.selectedConver.id, lastMessage);
      this.groupMembersList.forEach(element => {
        if (element.uid !== this.id) {
          this.messagingService.updateUnreadCount(element.uid, this.selectedConver.id, true);
        }
      });
    }
  }

  // identify type of input given
  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    } else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return false;
    }
  }

  // Send media messages

  createMediaMessage(selectedConver: any, attachmentObject: any) {
    this.newMessageObj = new MessagesModel();
    this.newMessageObj.timeStamp = new Date();
    this.newMessageObj.message = '';
    this.newMessageObj.fromId = this.id;
    this.newMessageObj.conversationId = selectedConver.id;
    if (selectedConver.admin) {
      this.newMessageObj.toId = selectedConver.id;
    } else {
      this.newMessageObj.toId = selectedConver.user_2.id;
    }
    // create attachment object
    this.newMessageObj.attachment = attachmentObject;
    this.newMessageObj.edited = false;
    this.newMessageObj.likes = {
      count: 0,
      users: []
    };
    this.messagingService.createMessage(this.newMessageObj);

    // create last message for this conversation
    let lastMessage = { message: '', timeSent: null };
    lastMessage.timeSent = this.newMessageObj.timeStamp;

    if (!selectedConver.admin) {
      lastMessage.message = this.newMessageObj.attachment.type;
      this.conversationsService.updatePrivatelastMessage(selectedConver.id, lastMessage);
      // unread notification to the other user
      this.conversationsService.addUnreadCount(selectedConver.id, selectedConver.user_2.id);

    } else if (selectedConver.admin) {
      // add unread count for every user in group except sender
      lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${this.newMessageObj.attachment.type}`;
      this.conversationsService.updateGrouplastMessage(selectedConver.id, lastMessage);
      this.groupMembersList.forEach(element => {
        if (element.uid !== this.id) {
          this.messagingService.updateUnreadCount(element.uid, selectedConver.id, true);
        }
      });
    }
  }
  // when file is selected
  onSelectFile(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      let FileSize = files[i].size / 1024 / 1024; // in MiB
      if (FileSize > 300) {
        // M.toast({ html: 'Selected file size exceeds 300 MiB.', classes: 'rounded' });
        return;
      } else {
        this.binDocList.push(files[i])
        if (this.validateInput(files[i].name) == 'image') {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          let img = {
            name: 'image',
            result: null
          }
          reader.onload = () => {
            img.result = reader.result;
            this.selectedFilesForDisplay.push(img);
          }
        } else {
          this.selectedFilesForDisplay.push(files[i]);
        }
      }
    }
    // setTimeout(() => {
    //   $('.modal#uploadFilesViewer').modal('open');
    //   $('.materialboxed').materialbox();
    // }, 200);



  }
  cancelFile(i: any) {
    this.selectedFilesForDisplay.splice(i, 1);
    if (this.selectedFilesForDisplay.length == 0) {
      // $('.modal#uploadFilesViewer').modal('close');
    }
  }
  cancelUpload() {
    this.binDoc = undefined;
    this.selectedFilesForDisplay = [];
  }
  onSendFiles() {
    this.binDocList.forEach(file => {
      if (this.validateInput(file.name) == 'image') {
        let binDoc = file;
        if (binDoc) {
          let selectedConver = this.selectedConver;
          this.mediaService.UploadBinImage(binDoc).subscribe(
            upload => {
              if (upload) {
                let attachment = {
                  type: 'image',
                  link: upload.imgLink,
                  name: binDoc.name
                }
                this.createMediaMessage(selectedConver, attachment);
              }
            }
          );
        }

      } else if (this.validateInput(file.name) == 'video') {

        let binDoc = file;
        if (binDoc) {
          let selectedConver = this.selectedConver;
          this.mediaService.uploadVideo(binDoc).subscribe(
            upload => {
              if (upload) {
                let attachment = {
                  type: 'video',
                  link: upload.imgLink,
                  name: binDoc.name
                }
                this.createMediaMessage(selectedConver, attachment);
              }
            }
          );
        }

      } else if (this.validateInput(file.name) == 'pdf') {
        let binDoc = file;
        if (binDoc) {
          let selectedConver = this.selectedConver;
          this.mediaService.uploadPDF(binDoc).subscribe(
            upload => {
              if (upload) {
                let attachment = {
                  type: 'pdf',
                  link: upload.imgLink,
                  name: binDoc.name
                }
                this.createMediaMessage(selectedConver, attachment);
              }
            }
          );
        }
      }
    });

    this.binDocList = [];
    this.selectedFilesForDisplay = [];
  }

  myPosts(){
    this.group = false;
    this.chatSelected = false;
    this.selectedConver = [];
  }
  // *********************************** For Private Chats ******************

  // when a private chat is selected
  selectPrivateChat(selectedChat) {
    // $(document).ready(function () {
    //   $('.dropdown-trigger').dropdown({ alignment: 'right' });
    // })

    this.messageForm.reset();

    this.group = false;
    if (this.selectedConver !== selectedChat) {
      this.messages = [];
      this.loadingMessages = true;
    }

    this.getScreenSize();

    if (this.scrWidth > 992) {
      this.chatSelected = true;

    } else {
      // for medium and small screens
      this.chatSelected = true;
      // $('.left-col-list').css('display', 'none');
      // $('.right-col-list').css('display', 'block');
    }
    this.selectedConver = selectedChat;
    // update unread count to zero
    this.conversationsService.removeUnreadCount(this.selectedConver.id, this.selectedConver.user_1.id);

    // initiate the retrival of message history if the chat is an already exsisting
    if (selectedChat.id) {
      this.messagingService.getMessageByChatId(selectedChat.id).valueChanges().subscribe(messages => {
        if (messages) {
          this.messages = messages.sort((a, b) => (a as any).timeStamp.toDate() - (b as any).timeStamp.toDate());
          this.loadingMessages = false;
          // $(document).ready(function () {
          //   // $('.materialboxed').materialbox();
          //   // let container = $('#scrollMe')[0];
          //   let height = $(container).prop("scrollHeight") - $(container).prop("clientHeight");
          //   $(container).animate({ scrollTop: height}, 0);
          // });
        }
        else {
          this.messages = [];
          this.loadingMessages = false;
        }
      });
    } else {
      this.messages = [];
      this.loadingMessages = false;
    }
    // update last visited time for current user
    // this.selectedConver.user_1.lastVisited = new Date();
  }

  // suggest users during search
  openUserSuggestion(id: any) {
    this.usersForSearch = [];
    if (this.usersForSearch.length < 1) {
      this.userService.getAllUsers().valueChanges().subscribe((friends: any) => {
        if (friends.length > 0){
          friends.forEach(friend => {
            this.usersForSearch.push(friend);
          });
        }
      });
      // only for member accounts
      if(this.currentUser.role != 'Admin'){
        this.conversationsService.getPublicGroups().valueChanges().subscribe(groups => {
        if (groups) {
          // add public groups
          this.publicGroupsList = groups;
          // add private groups to search from user's group list
          this.groupChats.forEach(group => {
            if (group.isPrivate) {
              this.publicGroupsList.push(group);
            }
          });
        }
      });
      }

    }
  }

  searchChat() {
    if (this.searchQuery.length > 0) {
      this.searchResult = [];
      this.usersForSearch.find(user => {
        if (user.GivenName.toLowerCase().includes(this.searchQuery.toLowerCase()) || user.LastName.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          this.searchResult.push(user);
        }
      });
      this.publicGroupsList.find(group => {
        if (group.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          this.searchResult.push(group);
        }
      })
      // $('#chats').css('display', 'none');
      // $('#groups').css('display', 'none');

      this.search = true;
    }
    if (this.searchQuery.length == 0) {
      this.search = false;
      this.usersForSearch = [];
      this.initTabs();
      // $('#chats').css('display', 'block');
    }
  }

  // when user is selected from search
  selectedFromSearch(user: any) {

    this.search = false;
    this.searchQuery = '';
    //check if there's an already exsisting conversation
    let checkConversation = this.privateChats.find(chat => chat.user_2.id == user.uid);
    if (!checkConversation) {
      // create new conversation
      this.newConversation = new PrivateConversationsModel();
      this.newConversation.dateCreated = new Date();
      this.newConversation.user_1 = {
        GivenName: this.currentUser.GivenName || this.currentUser.FirstName,
        LastName: this.currentUser.LastName,
        id: this.id,
        profileImgLink: this.currentUser.profileImgLink || '',
        lastVisited: new Date(),
        unreadCount: 0
      };
      this.newConversation.user_2 = {
        GivenName: user.GivenName || user.FirstName,
        LastName: user.LastName,
        id: user.uid,
        profileImgLink: user.profileImgLink || '',
        lastVisited: new Date(),
        unreadCount: 0

      };
      let lastMessage = { message: '', timeSent: null };
      lastMessage.message = '';
      lastMessage.timeSent = new Date();
      this.newConversation.lastMessage = lastMessage;
      this.selectPrivateChat(this.conversationsService.createPrivateConversation(this.newConversation));
    } else {
      this.selectPrivateChat(checkConversation);
    }
    this.selectTab('chats');
  }

  // *********************************** For Group Chats ******************

  // when group chat is selected
  selectedGroup(group) {

    this.messageForm.reset();
    this.search = false;
    this.searchQuery = '';
    this.selectTab('groups');
    this.group = true;
    this.loadingMessages = true;
    this.getScreenSize();
    this.editCircleInfoForm.patchValue({
      circleName: group.name,
      isPrivateCircle: group.isPrivate,
    })


    // $(document).ready(function () {
    //   $('.dropdown-trigger').dropdown({ alignment: 'right' });
    //   let container = $('#scrollMe');
    //   container.scrollTop(container.prop("scrollHeight"));

    //   $('.modal#editCircle').modal({
    //     onOpenStart() {
    //       M.updateTextFields();
    //     },
    //     onCloseStart() {
    //       $('.modal#editCircle .profile-picture').attr("src", group.profilePicture);
    //     }
    //   });
    // });


    if (this.scrWidth > 992) {
      this.chatSelected = true;

    } else {
      this.chatSelected = true;
      // for medium and small screens
      // $('.left-col-list').css('display', 'none');
      // $('.right-col-list').css('display', 'block');
    }

    this.selectedConver = group;
    this.groupMembersList = []; this.previousMembersList = [];
    //update unread count to zero now that the conversation has been opened
    this.messagingService.updateUnreadCount(this.id, this.selectedConver.id, false);

    // get user object for each previous members
    if (this.selectedConver.previousMembers.length) {
      this.selectedConver.previousMembers.forEach(userId => {
        this.fireMembersService.getMemberByID(userId).valueChanges().subscribe(user => {
          if (user) {
            this.previousMembersList.push(user);
          }
        })
      })
    }
    // get user object for each group members
    this.selectedConver.users.forEach(userId => {
      this.fireMembersService.getMemberByID(userId).valueChanges().subscribe(user => {
        if (user) {
          this.groupMembersList.push(user);
        }
      })
    })


    // get chat history
    if (group.id) {
      this.messagingService.getMessageByChatId(group.id).valueChanges().subscribe(messages => {
        if (messages && this.group) {
          this.messages = messages.sort((a, b) => (a as any).timeStamp.toDate() - (b as any).timeStamp.toDate());
          this.loadingMessages = false;
          // $(document).ready(function () {
          //   $('.materialboxed').materialbox();
          //   let container = $('#scrollMe')[0];
          //   let height = $(container).prop("scrollHeight") - $(container).prop("clientHeight");
          //   $(container).animate({ scrollTop: height}, 0);
          // });
          if (!this.groupMembersList) {
            this.allMembers = this.groupMembersList;
            if (this.previousMembersList) {
              this.allMembers = [...this.groupMembersList, ...this.previousMembersList];
            }
            // add user info to messages objects to display
            if (this.allMembers.length) {
              this.messages = this.messages.map(messageItm => ({ ...messageItm, ...({ userIndex: ((this.allMembers.findIndex(elm => elm.uid == messageItm.fromId) % 8) + 1) }), ...(({ GivenName = '', FirstName = '', LastName = '', profileImgLink = '' }) => ({ GivenName, FirstName, LastName, profileImgLink }))(this.allMembers.find(elm => elm.uid == messageItm.fromId)) }));
            }
          }
        }
        else {
          this.messages = [];
          this.loadingMessages = false;
        }
      });
    } else {
      this.messages = [];
      this.loadingMessages = false;
    }
  }

  setForm(group) {
    this.editCircleInfoForm.setValue({
      circleName: group.name,
      isPrivateCircle: group.isPrivate,
    })
    // M.updateTextFields();
  }

  searchForCircleMember() {
    if (this.searchMemberQuery.length > 0) {
      this.searchResult = [];
      this.usersForSearch.find(user => {
        // check for user name same as input
        if (user.GivenName.toLowerCase().includes(this.searchMemberQuery.toLowerCase()) || user.LastName.toLowerCase().includes(this.searchMemberQuery.toLowerCase())) {
          this.searchResult.push(user);
        }
      });
      this.searchCircleMember = true;
    }
    if (this.searchMemberQuery.length == 0) {
      this.searchCircleMember = false;
      this.usersForSearch = [];
    }
  }


  // create New Circle
  async createNewCircle(value) {
    let newConver;
    if (this.binDoc) {
      this.mediaService.UploadBinImage(this.binDoc).subscribe(
        upload => {
          if (upload) {
            this.newConversation = new GroupConversationsModel();
            this.newConversation.dateCreated = new Date();
            this.newConversation.name = value.circleName;
            this.newConversation.users = [this.id];
            this.newConversation.profilePicture = upload.imgLink;
            this.newConversation.isPrivate = value.isPrivateCircle;
            let lastMessage = { message: '', timeSent: null };
            lastMessage.message = '';
            lastMessage.timeSent = new Date();
            this.newConversation.lastMessage = lastMessage;
            this.newConversation.admin = this.id;
            this.newConversation.previousMembers = [];
            newConver = this.conversationsService.createGroupConversation(this.newConversation);
            this.selectedGroup(newConver);
            // create unread count
            this.newUnreadCount(this.id, newConver.id);
          }
        });
    } else {
      this.newConversation = new GroupConversationsModel();
      this.newConversation.dateCreated = new Date();
      this.newConversation.name = value.circleName;
      this.newConversation.users = [this.id];
      this.newConversation.profilePicture = 'https://api.mysabenefits.ca/Media/Demo/Images/7b4c439d-46c2-4601-9749-4d940459c50a-default-group.png';
      this.newConversation.isPrivate = value.isPrivateCircle;
      let lastMessage = { message: '', timeSent: null };
      lastMessage.message = '';
      lastMessage.timeSent = new Date();
      this.newConversation.lastMessage = lastMessage;
      this.newConversation.admin = this.id;
      this.newConversation.previousMembers = [];
      newConver = this.conversationsService.createGroupConversation(this.newConversation);
      this.selectedGroup(newConver);
      // create unread count
      this.newUnreadCount(this.id, newConver.id);
    }

    this.newCircleForm.reset({
      circleName: '',
      isPrivateCircle: false
    });
  }

  // create new unread count for group members
  newUnreadCount(userId, converId) {
    let unreadCount: UnreadCountModel = new UnreadCountModel();
    unreadCount.ConverId = converId;
    unreadCount.userId = userId;
    unreadCount.unreadCount = 0;
    this.messagingService.createUnreadCount(unreadCount);
  }

  // When user is selected to be added to a group
  selectedGroupMember(user) {
    this.searchMemberQuery = ''; this.searchResult = [];
    if ((this.selectedMember.findIndex(member => member.uid == user.uid)) == -1) {
      this.selectedMember.push(user);
      if (this.selectedMemeberName == '') {
        this.selectedMemeberName = `${user.GivenName} ${user.LastName}`
      } else {
        this.selectedMemeberName = this.selectedMemeberName + `, ${user.GivenName} ${user.LastName}`

      }
    }
  }

  // Add members to group
  addMembersToGroup() {
    let selectedMembers = [];
    selectedMembers = this.selectedMember;

    let users = this.selectedConver.users;
    selectedMembers.forEach(element => {
      this.selectedConver.users.push(element.uid);
      this.groupMembersList.push(element);
      this.newUnreadCount(element.uid, this.selectedConver.id);
      if (this.selectedConver.previousMembers.length) {
        this.selectedConver.previousMembers.find(perviousMember => {
          if (perviousMember == element.uid) {
            this.selectedConver.previousMembers.splice(this.selectedConver.previousMembers.findIndex(member => member == perviousMember), 1);

            // this.selectedConver.previousMembers.pop(perviousMember);
            this.previousMembersList.pop(element);
          }
        })
      }
      setTimeout(() => {
        if (element.uid != this.id) {
          this.createFlagMessage('add', element);
        } else {
          this.createFlagMessage('join', {})
        }
      }, 2000);

    });

    this.conversationsService.updateGroupInformation(this.selectedConver);

    this.selectedMemeberName = ''; this.searchMemberQuery = '';
    this.selectedMember = [];
  }
  // delete group / circle

  deleteCircle(id: any) {
    this.conversationsService.deleteGroupChat(id);
    // remove all unread counts of users

    // this.messagingService.getUnreadCountByConverId(id).valueChanges().subscribe(unreadCounts => {
    //   if (unreadCounts) {
    //     unreadCounts.forEach(element => {
    //       this.messagingService.deleteUnreadCount(element.id);
    //     });
    //   }
    // })
    this.getScreenSize();
    if (this.scrWidth < 992) {
      this.backClicked();
    }

    this.group = false;
    this.chatSelected = false;
    this.selectedConver = [];
  }

  isMember(group: any, userId: string) {
    if (group.users.indexOf(userId) !== -1) {
      return true;
    } else {
      return false;
    }
  }
  joinGroup() {
    this.selectedMember.push(this.currentUser);
    this.addMembersToGroup();
    this.selectedGroup(this.selectedConver);
  }

  // edit circle
  editCircleInfo(value: any) {
    let updatedGroupInfo = new GroupConversationsModel();
    let previousName = this.selectedConver.name;
    if (this.binDoc) {
      this.mediaService.UploadBinImage(this.binDoc).subscribe(
        upload => {
          if (upload) {
            updatedGroupInfo = this.selectedConver;
            updatedGroupInfo.name = value.circleName;
            updatedGroupInfo.isPrivate = value.isPrivateCircle;
            updatedGroupInfo.profilePicture = upload.imgLink;
            this.conversationsService.updateGroupInformation(updatedGroupInfo);

          }
        });
    }
    else {
      updatedGroupInfo = this.selectedConver;
      updatedGroupInfo.name = value.circleName;
      updatedGroupInfo.isPrivate = value.isPrivateCircle;
      this.conversationsService.updateGroupInformation(updatedGroupInfo);
    }

    if (value.circleName != previousName) {
      this.createFlagMessage('name-change', {});
    }

  }

  // leave circle for a single user
  leaveCircle(userId) {

    let user;
    if (userId != this.id) {
      user = this.groupMembersList.find(user => user.uid == userId);
      this.previousMembersList.push(user);
    }
    this.selectedConver.previousMembers.push(userId);
    this.selectedConver.users.splice(this.selectedConver.users.findIndex(user => user == userId), 1);
    this.groupMembersList.splice(this.groupMembersList.findIndex(member => member.uid == userId), 1);
    this.conversationsService.updateGroupInformation(this.selectedConver);
    this.messagingService.deleteUnreadCountByConverId(this.selectedConver.id, userId);
    // if user is leaving group
    if (userId == this.id) {
      this.group = false;
      this.chatSelected = false;
      this.getScreenSize();
      if (!this.noNotification) {
        this.createFlagMessage('leave', {});
      }


      if (this.scrWidth < 992) {
        this.backClicked();
      }
      this.selectedConver = []; this.groupMembersList = []
    } else {
      // if user is being removed by admin
      this.createFlagMessage('remove', user);
    }


  }

  createFlagMessage(type: any, user) {
    let message;
    if (type == 'join') {
      message = `${this.currentUser.GivenName || this.currentUser.FirstName} joined circle`
    } else if (type == 'add') {
      message = `${this.currentUser.GivenName || this.currentUser.FirstName} added ${user.GivenName || user.FirstName}`

    } else if (type == 'leave') {
      message = `${this.currentUser.GivenName || this.currentUser.FirstName} left circle`

    } else if (type == 'remove') {
      message = `${this.currentUser.GivenName || this.currentUser.FirstName} removed ${user.GivenName || user.FirstName}`
    } else if (type == 'name-change') {
      message = `Group name is changed to ${this.selectedConver.name}`
    }
    // create a new message object
    this.newMessageObj = new MessagesModel();
    this.newMessageObj.timeStamp = new Date();
    this.newMessageObj.message = message;
    this.newMessageObj.fromId = this.id;
    this.newMessageObj.conversationId = this.selectedConver.id;
    this.newMessageObj.attachment = { type: 'flag', link: '', name: '' };
    this.newMessageObj.edited = false;
    this.newMessageObj.likes = {
      count: 0,
      users: []
    };
    this.newMessageObj.toId = this.selectedConver.id;

    this.messagingService.createMessage(this.newMessageObj);
    // create last message for this conversation
    let lastMessage = { message: message, timeSent: this.newMessageObj.timeStamp };
    this.conversationsService.updateGrouplastMessage(this.selectedConver.id, lastMessage);
    this.groupMembersList.forEach(element => {
      if (element.uid !== this.id) {
        this.messagingService.updateUnreadCount(element.uid, this.selectedConver.id, true);
      }
    });



  }

  // ************************ Common ******************\

  likeMessage(id: any) {
    let currentMessage = this.messages.find(message => message.id == id);
    let index = this.messages.findIndex(message => message.id == id);
    if (currentMessage.likes.users.findIndex(user => user == this.id) > -1) {
      this.messages[index].likes.count -= 1;
      this.messages[index].likes.users.pop(this.id);
      this.messagingService.updateMessageLikes(this.messages[index])
    } else {
      this.messages[index].likes.count += 1;
      this.messages[index].likes.users.push(this.id);
      this.messagingService.updateMessageLikes(this.messages[index])
    }

  }

  replaceURLs(message) {
    if (!message) return;

    var urlRegex = /((http:\/\/|https:\/\/)?(www.)?(([a-zA-Z0-9-]){2,}\.){1,4}([a-zA-Z]){2,6}(\/([a-zA-Z-_\/\.0-9#:?=&;,]*)?)?)/ig;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

  // reinitialize tabs
  private initTabs() {
    // $(document).ready(function () {
    //   $('.tabs').tabs();
    // });
  }

  private selectTab(tabId: string) {
    // $(document).ready(function () {
    //   $('.tabs').tabs();
    //   $('.tabs').tabs('select', tabId);

    // });
  }

  onSelectFiles(files: FileList, formName: string) {
    if (this.validateInput(files[0].name) == 'image') {
      // this.selectedTemporaryPP = window.URL.createObjectURL(files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (formName == 'editCircle') {
          // $('.modal#editCircle .profile-picture').attr("src", reader.result);

        } else if (formName == 'newGroup') {
          // $('.modal#addGroup .profile-picture').attr("src", reader.result);

        }
      };
      this.binDoc = files[0];

    }
  }


  onOptionsClick(event, message){

    this.onRightClick(event, message)
    // $('.right-click-menu').css('display', 'block');

  }

  onRightClick(event, message) {
    // this.cd.detectChanges();
    this.selectedMessage = message;
    event.preventDefault();
    // event.stopPropagation();
    this.xPosTabMenu = event.clientX;
    this.yPosTabMenu = event.clientY;
    this.isHidden = false;
    // return false;
  }

  forwardSelectedMessage(selectedMessage) {
    // $('.modal#forwardMessage').modal('open');
    this.isHidden = true;
  }

  sendForwardMessage(selectedMessage, converId, toId) {
    this.fireMembersService.getMemberByID(selectedMessage.fromId).valueChanges().subscribe(user => {
      if (user) {
        let forwardedMessage = new ForwardedMessageModel();
        forwardedMessage.timeStamp = new Date()
        forwardedMessage.message = selectedMessage.message;
        forwardedMessage.fromId = this.id;
        forwardedMessage.forwardedFrom = `${user.GivenName || user.FirstName} ${user.LastName}`;
        forwardedMessage.toId = toId;
        forwardedMessage.conversationId = converId;
        forwardedMessage.edited = false;
        forwardedMessage.likes = {
          count: 0,
          users: []
        };
        forwardedMessage.forwardedMessage = selectedMessage;
        forwardedMessage.attachment = forwardedMessage.forwardedMessage.attachment;

        this.messagingService.createForwardedMessage(forwardedMessage);
        let lastMessage = { message: '', timeSent: null };
        lastMessage.timeSent = forwardedMessage.timeStamp;

        if (converId != toId) {
          if(forwardedMessage.forwardedMessage.attachment.type == 'none'){
            lastMessage.message = forwardedMessage.forwardedMessage.message;
          }else{
            lastMessage.message = forwardedMessage.forwardedMessage.attachment.type;
          }
          this.conversationsService.updatePrivatelastMessage(converId, lastMessage);
          // unread notification to the other user
          this.conversationsService.addUnreadCount(converId, toId);

        } else if (converId == toId) {
          // add unread count for every user in group except sender
          if(forwardedMessage.forwardedMessage.attachment.type == 'none'){
          lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${forwardedMessage.forwardedMessage.message}`;

          }else{
          lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${forwardedMessage.forwardedMessage.attachment.type}`;
          }
          this.conversationsService.updateGrouplastMessage(converId, lastMessage);
          this.groupMembersList.forEach(element => {
            if (element.uid !== this.id) {
              this.messagingService.updateUnreadCount(element.uid, converId, true);
            }
          });
        }
      }
    })

  }
  editSelectedMessage(selectedMessage) {
    this.isHidden = true;
    this.editMessage = true;
    this.messageForm.patchValue({
      message: selectedMessage.message
    });

  }

  closeContextMenu() {
    this.isHidden = true;
  }

  copyMessage(message) {
    navigator.clipboard.writeText(message.message).then().catch(e => console.error(e));

  }
  backClicked() {
    // $('.left-col-list').css('display', 'block');
    // $('.right-col-list').css('display', 'none');
    this.initTabs();
  }


  useAgeRangeSelected() {
    if (this.useAgeRange) {
      this.useAgeRange = false;
      this.FilterMemberForm.get('minAge').clearValidators();
      this.FilterMemberForm.get('minAge').patchValue('');
      this.FilterMemberForm.get('maxAge').clearValidators();
      this.FilterMemberForm.get('maxAge').patchValue('');
    } else {
      this.useAgeRange = true;
      this.FilterMemberForm.get('minAge').setValidators([Validators.required, Validators.min(0)]);
      this.FilterMemberForm.get('maxAge').setValidators(Validators.required);
      // M.updateTextFields();

    }
  }
  useGenderSelected() {
    if (this.useGender) {
      this.useGender = false;
      this.FilterMemberForm.get('gender').clearValidators();
      this.FilterMemberForm.get('gender').patchValue('');

    } else {
      this.useGender = true;
      this.FilterMemberForm.get('gender').setValidators(Validators.required);
      // M.updateTextFields();

    }
  }
  useCommunityNameSelected() {

    if (this.useCommunityName) {
      this.useCommunityName = false;
      this.FilterMemberForm.get('community').clearValidators();
      this.FilterMemberForm.get('community').patchValue('');
    } else {
      this.FilterMemberForm.get('community').setValidators(Validators.required);
      this.useCommunityName = true;
      this.nationCommunities = [];
      setTimeout(() => {
        // $('select').formSelect({ dropdownOptions: { belowOrigin: true, constrainWidth: true } });
      }, 2);
      if (this.nationOfUse === 'Batc' || this.nationOfUse === 'Pbcn') {
        this.communityService.getNationCommunities().subscribe(
          com => {
            if (com.length > 0) {
              com.forEach(element => {
                this.nationCommunities.push(element);
                const option = new Option(element.community, element.ID);
                // $('select#communityName').append(option);
              });
              // $('select').formSelect({ dropdownOptions: { belowOrigin: true, constrainWidth: true } });
            }
          });
      } else if (this.nationOfUse !== 'Batc' && this.nationOfUse !== 'Pbcn') {
        this.communityService.getCommunity().subscribe(
          com => {
            if (com.length > 0) {
              com.forEach(element => {
                this.nationCommunities.push(element);
                const option = new Option(element.community, element.ID);
                // $('select#communityName').append(option)

              });
              // $('select').formSelect({ dropdownOptions: { belowOrigin: true, constrainWidth: true } });
            }
          });
      }


    }
  }

  allMembersSelected() {
    this.everyone = true;
    this.specificAudience = false;
    this.useGender = true; this.useGenderSelected();
    this.useAgeRange = true; this.useAgeRangeSelected();
    this.useCommunityName = true; this.useCommunityNameSelected();
  }

  specificAudienceSelected() {
    this.specificAudience = true;
    this.everyone = false;

  }

  toggleAddingPreference(value: boolean) {
    this.searchAndAdd = !value;
  }

  addMembersToCircle(value: any) {
    this.closeAddMemberModal();
    const newMembers = [];
    const newFilteredMembers = [];
    if (this.everyone) {
      this.allFilteredCandidateContact.forEach((member: any) => {
        if (!this.selectedConver.users.includes(member.uid) && (member.uid !== this.id)) {
          newMembers.push(member);
        }
      });

      if (newMembers.length > 0) {
        this.selectedMember = newMembers;
        this.addMembersToGroup();
      }

    }
    if (this.specificAudience) {
      this.allFilteredCandidateContact.forEach(member => {
        if ((Boolean(this.filterStatmentBasedOnSelection(member, value)) === true) &&
            (!this.selectedConver.users.includes(member.uid)) && (member.uid !== this.id)) {
          newFilteredMembers.push(member);
        }
      });
      if (newFilteredMembers.length > 0) {
        this.selectedMember = newFilteredMembers;
        this.addMembersToGroup();
      }
    }
  }



  filterStatmentBasedOnSelection(user, value) {
    let filterStatment = ``;
    if (this.useAgeRange) {
      let today = new Date();
      let birthDate = new Date(user.DOB);
      let age = today.getFullYear() - birthDate.getFullYear();
      filterStatment = `${value.minAge <= age <= value.maxAge}`;

    }
    if (this.useGender) {
      if (filterStatment.length < 1) {
        filterStatment = `${value.gender === user.Sex}`;
      } else {
        filterStatment += `&& ${value.gender === user.Sex}`;
      }
    }
    if (this.useCommunityName && user.community) {

      if (filterStatment.length < 1) {
        filterStatment = `${value.communityName === user.community}`;
      } else {
        filterStatment += `&& ${value.communityName === user.community}`;
      }

    }

    filterStatment = Function('"use strict";return ' + filterStatment)();
    return filterStatment;

  }
  closeAddMemberModal() {

    // $('.modal#addMember').modal('close');
  }

}
