import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, Injectable, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { Location, formatDate} from '@angular/common';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import {
  CalendarEvent, CalendarView,  DAYS_OF_WEEK, CalendarDateFormatter, DateFormatterParams, CalendarWeekViewBeforeRenderEvent
} from 'angular-calendar';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { EmployeeAppointmentModel } from 'src/app/models/AppointmentModel';
import { isAfter, isBefore } from 'date-fns';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'h:mm a', locale);
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }
}

@Component({
  selector: 'app-employee-appointment',
  templateUrl: './employee-appointment.component.html',
  styleUrls: ['./employee-appointment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
        .cal-week-view .cal-time-events .cal-day-column {
          margin-right: 10px;
        }

        .cal-week-view .cal-hour {
          width: calc(100% + 10px);
        }
      `,
    ],
  providers: [{
  provide: CalendarDateFormatter,
  useClass: CustomDateFormatter,
  }]
})
export class EmployeeAppointmentComponent implements OnInit {

  loggedUser: any;
  loading = false;
  employeeId: any;
  employeeSchedule: any;
  excludeDays: any;
  appointmentsList: any;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  employeeServices: any = [];
  createAppointmentForm: UntypedFormGroup;
  selectedService = { serviceName: 'Click to select a service' };
  selectedServiceTime: any;
  unAvailableTimeSlot: any;
  unAvailableTimes = [];

  constructor(
    private location: Location,
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private empService: EmployeeServicesService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef

  ) {
    this.loggedUser = this.authService.getGlobalUser();
    this.getEmployeeSchedule();
    this.refresh.next();
  }

  ngOnInit(): void {
    this.createAppointmentForm = this.fb.group({
      service: [, [Validators.required]],
      startTime: [, [Validators.required]],
      endTime: [, [Validators.required]]
    });
    this.refresh.next();
  }

  get service() { return this.createAppointmentForm.get('service'); }
  get startTime() { return this.createAppointmentForm.get('startTime'); }
  get endTime() { return this.createAppointmentForm.get('endTime'); }

  openServiceDropdown() {

  }
  serviceSelected(service) {
    this.selectedService = service;
    const endTime = this.getEndTIme(this.startTime.value, service.serviceTime);
    const unAvaiableTimes = this.checkDateForAppointment(this.selectedServiceTime, endTime);
    const day = this.employeeSchedule.schedule.filter((day, index) => index === endTime.getDay());
    const dayEndTime = day[0].endTime.split(':');
    const [dayEndTimeHour, dayEndTimeMinute] = [parseInt(dayEndTime[0]) ,parseInt(dayEndTime[1])];

    const dayy = new Date(endTime);
    dayy.setHours(dayEndTimeHour, dayEndTimeMinute, 0, 0);

    if (isAfter(endTime, dayy)){
      this.unAvailableTimeSlot = true;
      this.endTime.patchValue('');
    }
    else if (unAvaiableTimes.length > 0) {
      this.unAvailableTimeSlot = true;
      this.endTime.patchValue('');
    } else {
      this.unAvailableTimeSlot = false;
      this.endTime.patchValue(endTime);
    }
    this.service.patchValue(service.serviceName);
  }

  getEndTIme(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  createEvent(appointment) {
    const data = {
      start: appointment.startTime.toDate(),
      end: appointment.endTime.toDate(),
      title: `${appointment.service}`,
      colors: {
          primary: '#1e90ff',
          secondary: '#D1E8FF',
          },
      // title: `${appointment.service}: ${appointment.endTime.toDate().toLocaleTimeString('en-US')} -
      // ${appointment.endTime.toDate().toLocaleTimeString('it-IT')}`
    };
    return data;
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
  }

  hourSegmentClicked(event): void {
    const date = event.date;
    if (this.unAvailableTimes.includes(date)){
      // toast({ html: 'Employee not available at that time', classes: 'red' });
      return;
    }
    const today = new Date();
    // today.setHours(0, 0, 0, 0)
    if (isBefore(date, today)){
      // toast({ html: 'Can\'t create appointment for past date!', classes: 'red' });
      return;
    }

    const scheduleExists = this.appointmentsList.filter(a => date.getTime() >= (a.startTime.toDate().getTime() - 1000)
     && date.getTime() + 1000 <= a.endTime.toDate().getTime());

    if (scheduleExists.length <= 0) {
      this.selectedServiceTime = event.date;
      this.startTime.patchValue(this.selectedServiceTime);
    } else {
      // toast({ html: 'Appointment already exists', classes: 'red' });
    }
  }

  dateOutsideSchedule(date){
    const day = this.employeeSchedule.schedule.filter((day, index) => index === date.getDay());
    const startTime = day[0].startTime.split(':');
    const [scheduleHour, scheduleMinute] = [parseInt(startTime[0]), parseInt(startTime[1])];

    if (date.getHours() < scheduleHour){
      return true;
    }else if ((date.getHours() === scheduleHour) && date.getMinutes() < scheduleMinute){
      return true;
    }
    return false;

  }

  checkDateForAppointment(startTime, endTime) {
    const l = this.appointmentsList.filter(a => endTime.getDay() === a.startTime.toDate().getDay());
    const unBookableTimes = l.filter(a => isAfter(a.startTime.toDate().getTime(), startTime.getTime())
     && isBefore(a.startTime.toDate().getTime(), endTime.getTime()));

    return unBookableTimes;

  }

  beforeViewRender(body: CalendarWeekViewBeforeRenderEvent): void {

    body.hourColumns.forEach(hourCol => {
      if (this.employeeSchedule) {
        if (this.employeeSchedule.schedule.length > 0) {

          hourCol.hours.forEach(hour => {

            hour.segments.forEach(segment => {

              if (isBefore(segment.date, new Date())){
              this.unAvailableTimes.push(segment.date);
              segment.cssClass = 'unavailable';
            }
            }
          );

          });
        }}});

    body.hourColumns.forEach(hourCol => {

      if (this.employeeSchedule) {
        if (this.employeeSchedule.schedule.length > 0) {
          const day = this.employeeSchedule.schedule.filter((day, index) => index === hourCol.date.getDay());
          const startTime = day[0].startTime.split(':');
          const [scheduleHour, scheduleMinute] = [parseInt(startTime[0]) ,parseInt(startTime[1])];
          const endTime = day[0].endTime.split(':');
          const [endScheduleHour, endScheduleMinute] = [parseInt(endTime[0]) ,parseInt(endTime[1])];

          hourCol.hours.forEach(hour => {

            hour.segments.forEach(segment => {
              if (segment.date.getHours() < scheduleHour){
                this.unAvailableTimes.push(segment.date);
                segment.cssClass = 'unavailable';
              }
              else if ((segment.date.getHours() === scheduleHour) && (segment.date.getMinutes() < scheduleMinute)){
                this.unAvailableTimes.push(segment.date);
                segment.cssClass = 'unavailable';
              }
              if (segment.date.getHours() > endScheduleHour){
                this.unAvailableTimes.push(segment.date);
                segment.cssClass = 'unavailable';
              }
              else if ((segment.date.getHours() === endScheduleHour) && (segment.date.getMinutes() >= endScheduleMinute)){
                this.unAvailableTimes.push(segment.date);
                segment.cssClass = 'unavailable';
              }
            });
          });
        }
      }
    });
  }

  getEmployeeSchedule() {
    this.loading = true;
    this.route.paramMap.subscribe(params => {
      this.employeeId = params.get('id');
    });

    this.scheduleService.getScheduleByEmployeeId(this.employeeId).valueChanges().subscribe(s => {
      if (s && s.length > 0) {
        this.loading = false;
        this.employeeSchedule = s[0];
        this.excludeDays = this.employeeSchedule.schedule.map((day, index) => {
          if (day.unAvailable !== true) { return false; }
          return index;
        }).filter(day => day !== false);

        this.appointmentService.getAppointmentByEmployeeId(this.employeeId).valueChanges().subscribe(a => {
          if (a.length > 0) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            this.appointmentsList = a;
            this.appointmentsList = this.appointmentsList.filter(a => a.startTime.toDate() >= today);
            this.recreateEventsList();

          } else {
            this.appointmentsList = [];
          }
        });
        this.events = [...this.events];

        // employee services
        this.empService.getServicesWithTime().valueChanges().subscribe((services: any) => {
          if (services.length > 0) {
            services.forEach(service => {
              if (service.assignedTo.includes(this.employeeId)){
                this.employeeServices.push(service);
              }
            });
            // this.employeeServices = services;
          }
        });
        this.cdr.detectChanges();
      }
      this.loading = false;
    });
    this.loading = false;

  }

  recreateEventsList() {
    this.events = [];
    this.appointmentsList.forEach(appointment => {
      const event = this.createEvent(appointment);

      this.events.push(event);

    });
    this.cdr.detectChanges();
  }
  closeModal() {
    this.service.patchValue('');
    this.startTime.patchValue('');
    this.endTime.patchValue('');
  }

  addAppointment(value) {
    const newAppointment = new EmployeeAppointmentModel();
    newAppointment.employeeId = this.employeeId;
    newAppointment.startTime = value.startTime;
    newAppointment.endTime = value.endTime;
    newAppointment.serviceId = value.service;
    newAppointment.userId = this.loggedUser.uid;
    newAppointment.message = value.message;

    this.appointmentService.createEmployeeAppointment(newAppointment).then(appointment => {
      // this.toast({ html: 'Employee Schedule Successfully Created!', classes: 'green' });

    }).catch(err => {
      // this.toast({ html: 'Error Creating Appointment', classes: 'red' });

    });

  }


  backClicked() {
    this.location.back();
  }

  }
