import { BrowserModule } from '@angular/platform-browser';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Modules
import { AdminModule } from './comp/admin/admin.module';
import { UserModule } from './comp/user/user.module';
import { SharedModule } from './comp/shared/shared.module';
import { AuthModule } from './comp/auth/auth.module';
import {DragDropModule} from '@angular/cdk/drag-drop'

// Component
import { HeaderComponent } from './comp/navigation/header/header.component';
import { MemberService } from './services/api/member.service';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { LoaderInterceptorService } from './interceptors/loader-interceptor.service';
import { UserAuthGuard } from './guards/user-auth.guard';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { KeyWordsHighlight1 } from './Pipes/KeyworkdHighLight1';
import { RegistrationRequestComponent } from './comp/app/registration-request/registration-request.component';
import { EmailService } from './services/api/email.service';
import { HomePageComponent } from './comp/app/home/home-page/home-page.component';
import { Platform } from '@angular/cdk/platform';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { TermsConditionsComponent } from './comp/app/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './comp/app/privacy-policy/privacy-policy.component';
import { FCMpushService } from './services/firebase/FCMpush.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeSubMenuComponent } from './comp/app/home/home-sub-menu/home-sub-menu.component';
import { MoreMenuComponent } from './comp/app/more-menu/more-menu.component';
import { SafeUrlPipe } from './Pipes/SafeUrlPipe';
import { RegisterComponent } from './comp/app/register/register.component';
import { EulaComponent } from './comp/app/eula/eula.component';
import { ShortNumberPipe } from './Pipes/short-number.pipe';
import { EventCalendarComponent } from './comp/app/event-calandar/event-calendar.component';
import { EmployeeModule } from './comp/employee/employee.module';
import { ServiceComponent } from './comp/app/service/service.component';
import { ContactComponent } from './comp/app/contact/contact.component';
import { AboutComponent } from './comp/app/about/about.component';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { ProductsComponent } from './comp/app/products/products.component';
import { ConfirmationComponent } from './comp/app/confirmation/confirmation.component';
import { EnduserAgreementComponent } from './comp/app/enduser-agreement/enduser-agreement.component';

import { SignatureModule } from '@syncfusion/ej2-angular-inputs';
import { ImageCropperModule } from  'ngx-image-cropper';
import { ResetPasswordComponent } from './comp/app/reset-password/reset-password.component';

import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';

import { PaginationComponent } from './comp/shared/pagination/pagination.component';
// import { PushNotificationComponent } from './comp/share/push-notification/push-notification.component';
// import { DownloadPwAButtonComponent } from './comp/common/download-pw-abutton/download-pw-abutton.component';
// import { PaginationModule } from './comp/shared/pagination/pagination.module'
import { FilterPipe } from './filter.pipe';

import { StoreModule } from '@ngrx/store';
import { appSettingsReducer, userReducer, scheduleReducer } from './state/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './state/app.effects'; // Adjust the import path
import { FirstLetterPipe } from './Pipes/firstLetter.pipe';

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        KeyWordsHighlight1,
        RegistrationRequestComponent,
        HomePageComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        HomeSubMenuComponent,
        MoreMenuComponent,
        RegisterComponent,
        EulaComponent,
        EventCalendarComponent,
        ServiceComponent,
        ContactComponent,
        AboutComponent,
        ProductsComponent,
        ConfirmationComponent,
        EnduserAgreementComponent,
        ResetPasswordComponent,
        // FilterPipe
    ],
    exports: [
        HeaderComponent,
        AboutComponent
    ],
    bootstrap: [AppComponent],
    
    imports: [
        GuidedTourModule,
        BrowserModule,
        DragDropModule,
        SignatureModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        StoreModule.forRoot({ user: userReducer, settings: appSettingsReducer, schedule: scheduleReducer }),
        EffectsModule.forRoot([AppEffects]),
        AngularFireFunctionsModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        AdminModule,
        EmployeeModule,
        UserModule,
        AuthModule,
        BrowserAnimationsModule,
        ImageCropperModule,
        ClipboardModule,
        CommonModule], providers: [
        GuidedTourService,
        DataTablesModule,
        MemberService, UserAuthGuard, AdminAuthGuard, EmailService, Platform, FCMpushService, SafeUrlPipe, ShortNumberPipe,
        FirstLetterPipe, [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: LoaderInterceptorService,
                multi: true,
            },
        ],
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

  // constructor(library: FaIconLibrary) {
  //   library.addIconPacks(fas);
  //   library.addIconPacks(far);
  //   library.addIconPacks(fab);
  // }

  constructor() {}
 }
