import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
@Component({
  selector: 'app-appointment-setting',
  templateUrl: './appointment-setting.component.html',
  styleUrls: ['./appointment-setting.component.css']
})
export class AppointmentSettingComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() settingSaved = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();

  apptSettingsForm: UntypedFormGroup;
  appSettings: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  rescheduleOption: any;
  preferedEmailReminderOption: any;
  preferedSMSReminderOption: any;
  reminderOption: any;
  cancelOption: any;
  openSave: boolean = false;
  sortedFontSize : { key: number, value: string }[] = [];
  // fontSize = {16: 'text-2xl', 14: 'text-xl', 12 : 'text-lg', 10 : 'text-md', 8 : 'text-sm', 6 : 'text-xs'}
  fontSize = {  6 : 'text-xs', 8 : 'text-sm', 10 : 'text-md', 12 : 'text-lg',  14: 'text-xl', 16: 'text-2xl'}

  constructor(private appointmentService: AppointmentService,
              private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.sortedFontSize = Object.keys(this.fontSize)
    .map(key => ({ key: +key, value: this.fontSize[key] }))
    .sort((a, b) => a.key - b.key);
    this.apptSettingsForm = this.fb.group({
      canReschedule: [false],
      canCancel: [false],
      sendEmailConfirmation: [false],
      sendEmailReminder: [false],
      sendSMSConfirmation: [false],
      sendSMSReminder: [false],
      canCancelBeforeHr: [24],
      sendReminderBeforeHr: [''],
      canRescheduleBeforeHr: [24],
      rescheduleOption: [],
      cancelOption: [],
      preferedEmailReminderOption: [],
      preferedSMSReminderOption: [],
      appointmentBgTaskColor:[''],
      appointmentBgNoteColor:[''],
      appointmentBgApptColor:[''],
      appointmentTextColor: [''],
      sendReminderNTABeforeHr:[''],
      sendNTAEmailReminder:[''],
      sendNTASMSReminder:[''],  
      appointmentFontSize:['']
    });

    this.appointmentService.getAppointmentSetting().valueChanges().subscribe(apptSetting => {
      if (apptSetting){
        if (apptSetting.length > 0){
          this.appSettings = apptSetting[0];
          if (this.appSettings.canRescheduleBeforeHr >= 24){
            this.rescheduleOption = 'Days';
          }
          if (this.appSettings.canRescheduleBeforeHr < 24){
            this.rescheduleOption = 'Hours';
          }
          if (this.appSettings.canCancelBeforeHr >= 24){
            this.cancelOption = 'Days';
          }
          if (this.appSettings.canCancelBeforeHr < 24){
            this.cancelOption = 'Hours';
          }

          if (this.appSettings.sendReminderBeforeHr >= 24){
            this.preferedEmailReminderOption = 'Days';
          }
          if (this.appSettings.sendReminderBeforeHr < 24){
            this.preferedEmailReminderOption = 'Hours';
          }          
          if (this.appSettings.sendReminderNTABeforeHr >= 24){
            this.preferedSMSReminderOption = 'Days';
          }
          if (this.appSettings.sendReminderNTABeforeHr < 24){
            this.preferedSMSReminderOption = 'Hours';
          }
          this.apptSettingsForm.patchValue({
            canReschedule: this.appSettings.canReschedule,
            sendEmailReminder: this.appSettings.sendEmailReminder,
            sendSMSReminder: this.appSettings.sendSMSReminder,
            sendEmailConfirmation: this.appSettings.sendEmailConfirmation,
            sendSMSConfirmation: this.appSettings.sendSMSConfirmation,
            sendNTAEmailReminder: this.appSettings.sendNTAEmailReminder ? this.appSettings.sendNTAEmailReminder : 0,
            sendNTASMSReminder: this.appSettings.sendNTASMSReminder ? this.appSettings.sendNTASMSReminder: 0,
            sendReminderNTABeforeHr: this.appSettings.sendReminderNTABeforeHr ? this.appSettings.sendReminderNTABeforeHr : '',
            canCancel: this.appSettings.canCancel,
            canCancelBeforeHr: this.appSettings.canCancelBeforeHr,
            sendReminderBeforeHr: this.appSettings.sendReminderBeforeHr ? this.appSettings.sendReminderBeforeHr: '',
            canRescheduleBeforeHr: this.appSettings.canRescheduleBeforeHr,
            rescheduleOption: this.appSettings.canRescheduleBeforeHr > 24 ? 'Days' : 'Hours',
            cancelOption: this.appSettings.canCancelBeforeHr > 24 ? 'Days' : 'Hours',
            preferedEmailReminderOption: this.appSettings.sendReminderBeforeHr > 24 ? 'Days' : 'Hours',
            preferedSMSReminderOption: this.appSettings.sendReminderNTABeforeHr > 24 ? 'Days' : 'Hours',
            appointmentBgTaskColor: this.appSettings.appointmentBgTaskColor ? this.appSettings.appointmentBgTaskColor : '#ff3d7f',
            appointmentBgNoteColor: this.appSettings.appointmentBgNoteColor ? this.appSettings.appointmentBgNoteColor : '#79e868',
            appointmentBgApptColor: this.appSettings.appointmentBgApptColor ? this.appSettings.appointmentBgApptColor : '#4c58d9',
            appointmentTextColor: this.appSettings.appointmentTextColor ? this.appSettings.appointmentTextColor : ' ',
            appointmentFontSize: this.appSettings.appointmentFontSize ? this.appSettings.appointmentFontSize : ' '
          });
        }
      }
    });
  }
  get canCancel(){ return this.apptSettingsForm.get('canCancel'); }
  get canReschedule(){ return this.apptSettingsForm.get('canReschedule'); }
  get sendEmailReminder(){ return this.apptSettingsForm.get('sendEmailReminder'); }
  get sendSMSReminder(){ return this.apptSettingsForm.get('sendSMSReminder'); }
  get canCancelBeforeHr(){ return this.apptSettingsForm.get('canCancelBeforeHr'); }
  get sendReminderBeforeHr(){ return this.apptSettingsForm.get('sendReminderBeforeHr'); }
  get canRescheduleBeforeHr(){ return this.apptSettingsForm.get('canRescheduleBeforeHr'); }
  get appointmentBgTaskColor() {return this.apptSettingsForm.get('appointmentBgTaskColor')}
  get appointmentBgNoteColor() {return this.apptSettingsForm.get('appointmentBgNoteColor')}
  get appointmentBgApptColor() {return this.apptSettingsForm.get('appointmentBgApptColor')}
  get appointmentTextColor() {return this.apptSettingsForm.get('appointmentTextColor')}
  get sendNTAEmailReminder() {return this.apptSettingsForm.get('sendNTAEmailReminder')}
  get sendNTASMSReminder() {return this.apptSettingsForm.get('sendNTASMSReminder')}
  get sendReminderNTABeforeHr() {return this.apptSettingsForm.get('sendReminderNTABeforeHr')}
  get appointmentFontSize() {return this.apptSettingsForm.get('appointmentFontSize')}

  saveAppointmentSettings(value){
    if (this.appSettings){
      value.id = this.appSettings.id;
      this.appointmentService.updateAppointmentSetting(value).then(() => {
        this.toast({ html: 'Appointment Settings Successfully Updated!', classes: 'green', type: 'success'});
        this.closeSaveModal();
      });
    } else {
      this.appointmentService.createAppointmentSetting(value).then(() => {
        this.toast({ html: 'Appointment Settings Successfully Saved!', classes: 'green', type: 'success'});
        this.closeSaveModal();
      });
    }
    if(this.calledFromEmptyDashboard == 'Appointment Setting'){
      this.closeCollapse.emit('appointment');
      this.settingSaved.emit('appointmentSetting')
    }
    this.closeSaveModal();
  }
  onRescheduleChange(option){
    this.rescheduleOption = option;
  }
  onCancelChange(option){
    this.cancelOption = option;
  }
  onSMSReminderChange(option){
    this.preferedSMSReminderOption= option
  }
  onEmailReminderChange(option){
    this.preferedEmailReminderOption = option
  }
  openSaveModal(){
    this.openSave = true;
  }
  closeSaveModal(){
    this.openSave = false;
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  onToggleChange(isChecked: boolean) {
    console.log('Toggle state:', isChecked);
  }
}
