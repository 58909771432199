import { Component, OnInit, Input } from '@angular/core';
import { PrivateConversationsModel } from 'src/app/models/Conversations.Model';
import { CustomAdminEmailModel } from 'src/app/models/emailModel';
import { MessagesModel, NotificationModel } from 'src/app/models/Messages.Model';
import { EmailService } from 'src/app/services/api/email.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ConversationsService } from 'src/app/services/firebase/conversations.service';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';
import { PushNotificationSettingsService } from 'src/app/services/firebase/push-notification-settings.service';

import { environment } from 'src/environments/environment';

declare var M: any;
declare var $: any;

@Component({
    selector: 'app-private-messages',
    templateUrl: './private-messages.component.html',
    styleUrls: ['./private-messages.component.css'],

})
export class PrivateMessagesComponent implements OnInit {

    isAdmin
    currentUser
    userId
    showMessages = false
    selectedConv
    chatSelected = false

    privateChats = []

    // admin notification settings
    adminNotificationSettings;
    employeeNotificationSettings;
    storeEmail;

    isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
    domain = environment.firstNation.name;

    domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  


    @Input() directMessage
    @Input() userToMessage
    constructor(
        private authService: AuthService,
        private messagingService: MessagingService,
        private conversationsService: ConversationsService,
        private fcmPushService: FCMPushNotificationsService,
        private emailService: EmailService,
        private notificationPreferenceService: NotificationPreferenceService

    ) { }


    ngOnInit() {
        this.currentUser = this.authService.getGlobalUser();

        if (this.currentUser) {
            if (this.currentUser.role == "Admin") {
                this.isAdmin = true;
            }
            this.userId = this.currentUser.uid;
        }

        if (this.directMessage) {
            this.conversationsService.getPrivateChatsByUserId1(this.userId).valueChanges().subscribe(chats => {
                if (chats) {
                    chats.forEach(element => {
                        let index = this.privateChats.findIndex(chat => chat.id === element.id);
                        if (index >= 0) {
                            this.privateChats.splice(index, 1);
                        }
                        this.privateChats.push(element);
                    });
                    this.privateChats.forEach(element => {
                        if (element.user_2.id === this.userId) {
                            [element.user_1, element.user_2] = [element.user_2, element.user_1];
                        }
                    });
                    // Sort out the list whenever there has been any change
                    this.privateChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());

                }
            });

            this.conversationsService.getPrivateChatsByUserId2(this.userId).valueChanges().subscribe(chats => {
                if (chats) {
                    chats.forEach(element => {
                        let index = this.privateChats.findIndex(chat => chat.id === element.id);
                        if (index >= 0) {
                            this.privateChats.splice(index, 1);
                        }
                        this.privateChats.push(element);
                    });
                    this.privateChats.forEach(element => {
                        if (element.user_2.id === this.userId) {
                            [element.user_1, element.user_2] = [element.user_2, element.user_1];
                        }
                    });
                    // Sort out the list whenever there has been any change
                    this.privateChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
                }
                this.sendDirectMessageToUser(this.userToMessage)

            });

        }
    }

    onShowMessages() {
        this.showMessages = !this.showMessages
    }

    onChatSelect(event) {
        this.selectedConv = event.chat
        this.chatSelected = true
    }
    onMobileChatSelect(event) {
        this.showMessages = true
        this.onChatSelect(event)
    }
    onBack(event) {
        this.showMessages = false
    }

    sendDirectMessageToUser(user) {
       
        let existingChat = this.privateChats.find(chat => chat.user_2.id == user.uid);

        if (!existingChat) {
            let newConversation = new PrivateConversationsModel();
            newConversation.dateCreated = new Date();
            newConversation.user_1 = {
                GivenName: this.currentUser.GivenName || this.currentUser.FirstName,
                LastName: this.currentUser.LastName,
                id: this.userId,
                profileImgLink: this.currentUser.profileImgLink || '',
                lastVisited: new Date(),
                unreadCount: 0
            };
            newConversation.user_2 = {
                GivenName: user.GivenName || user.FirstName,
                LastName: user.LastName,
                id: user.uid,
                profileImgLink: user.profileImgLink || '',
                lastVisited: new Date(),
                unreadCount: 0

            };
            let lastMessage = { message: '', timeSent: null };
            lastMessage.message = '';
            lastMessage.timeSent = new Date();
            newConversation.lastMessage = lastMessage;


            this.selectedConv = this.conversationsService.createPrivateConversation(newConversation)
            this.chatSelected = true

        } else {
            this.selectedConv = existingChat;
            this.chatSelected = true
        }
    }

    onMessageSent(values) {

        let newMessageObj = new MessagesModel();
        newMessageObj.timeStamp = new Date();
        newMessageObj.message = values.message;
        newMessageObj.fromId = this.currentUser.uid
        newMessageObj.conversationId = this.selectedConv.id
        newMessageObj.attachment = { type: 'none', link: '', name: '' };

        newMessageObj.toId = this.selectedConv.id

        newMessageObj.edited = false;
        newMessageObj.likes = {
            count: 0,
            users: []
        };
        newMessageObj.attachment = values.attachment

        this.messagingService.createMessage(newMessageObj);


        const emailModel = new CustomAdminEmailModel();
        emailModel.subject = `You got New Message`;
        emailModel.body = `You got New Message from  ${this.currentUser.GivenName} `;
        emailModel.isNationApp = this.isNationApp;
        this.storeEmail = ['biya@mynation.app'];
        // this.storeEmail.push(this.currentUser.email);
        emailModel.emailList = this.storeEmail;
        // this.cancelSendBtnClicked();
  
        // custom send email used
        emailModel.host = "smtp.gmail.com";
        emailModel.adminEmail = "mkproject.app@gmail.com";
        emailModel.appPassword = "ytklyidomvqfmtky";

        this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
            if (res) {    
            }
            else {
            }
          })


        // create last message for this conversation
        let lastMessage = { message: '', timeSent: null };
        lastMessage.timeSent = newMessageObj.timeStamp;
        lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${newMessageObj.message}`;
        this.selectedConv.lastMessage = lastMessage
        if (this.selectedConv.user_1.id == this.currentUser.uid) {
            this.selectedConv.user_2.unreadCount += 1;
        } else if (this.selectedConv.user_2.id == this.currentUser.uid) {
            this.selectedConv.user_1.unreadCount += 1;
        }
        this.conversationsService.updatePrivateChat(this.selectedConv)

        // let toId = this.selectedConv.user_2.id ? this.selectedConv.user_2.id !== this.currentUser.uid : this.selectedConv.user_1.id
        // this.conversationsService.addUnreadCount(this.selectedConv.id, toId);
        this.sendNotification(newMessageObj)


    }

    async sendNotification(newMessageObj) {
        let notification = new NotificationModel();
        let selectedUser;

        notification.title = `${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`
        notification.body = newMessageObj.message
        notification.data = { chatType: 'private', notificationType: 'Message' }
        let users = []
        if (this.selectedConv.user_1.id == this.currentUser.uid) {
            users.push(this.selectedConv.user_2.id)

            //keep track of 
            selectedUser = this.selectedConv.user_2;
        } else if (this.selectedConv.user_2.id == this.currentUser.uid) {
            users.push(this.selectedConv.user_1.id);
            selectedUser = this.selectedConv.user_1;
        }

        if (newMessageObj.attachment.type && newMessageObj.attachment.type === 'image') {
            notification.image = newMessageObj.attachment.link
        }


        //    check if the selected user wants to recieve push notification and also check the role of the user
        if ((selectedUser?.role).toLowerCase() == "admin") {
            await this.notificationPreferenceService.getAdminNotificationPreference(notification, users)
        }
        else if ((selectedUser?.role).toLowerCase() == "user") {
            await this.notificationPreferenceService.getSubscribersNotificationPreference(selectedUser?.id,notification, users)
        }
    }



}
