import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollToTopService {

  constructor(private router: Router,  private route: ActivatedRoute) { }

  scrollToTopOnNavigation() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const element = document.querySelector("#" + fragment);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
      }
    });
  }

  scrollToTop() {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
  }

  scrollToElementByID(elementID : string){
    const element =document.getElementById(elementID)
    if(element){
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }
}
