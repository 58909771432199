import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { PushNotificationSettingsService } from './push-notification-settings.service';
import { FCMPushNotificationsService } from './FCMPushNotifications.service';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { FCMService } from '../api/fcm.service';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class NotificationPreferenceService {

  adminNotificationSettings;
  clientNotificationSettings;
  employeeNotificationSettings;
  fcmServer = 'https://fcm.googleapis.com/v1/projects/demopersonalapp/messages:send';

  notificationPayload = {

    registration_ids: undefined,
    to: undefined,
    topic: null,
    name: 'notification',
    notification: {
      body: '',
      title: '',
      image: '',
      click_action: ''
    },
    data: {
      notification_foreground: 'true',
    }
  };

  constructor(
    private appSettingsService: AppSettingsService,
    public notificationSettingService: PushNotificationSettingsService,
    private fcmPushService: FCMPushNotificationsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private fcmService: FCMService,
    private toastr: ToastrService
  ) { }

  // grab admin notification preference
  getAdminNotificationPreference(notification, users) {
    this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x => {

      if (x && x.length > 0) {
        this.adminNotificationSettings = x[0]


        if (this.adminNotificationSettings?.settings) {
          // check if the admin wants to recieve direct message .....
          let directMessagePreference = this.adminNotificationSettings?.settings.find((setting) => setting?.name === "chat");


          if (directMessagePreference.isEnabled) {
            this.fcmPushService.sendNotificationToUsers(notification, users)

          }

        }


      }
    })
  }

  // grab subscribers notification preference
  getSubscribersNotificationPreference(uid, notification, users) {
    this.notificationSettingService.getClientPushNotificationSettingsList(uid).valueChanges().subscribe(x => {

      if (x && x.length > 0) {
        this.clientNotificationSettings = x[0]


        if (this.clientNotificationSettings?.settings) {
          // check if the admin wants to recieve direct message .....
          let directMessagePreference = this.clientNotificationSettings?.settings.find((setting) => setting?.name === "chat");


          if (directMessagePreference.isEnabled) {
            this.fcmPushService.sendNotificationToUsers(notification, users)

          }

        }


      }
    })
  }


  // grab employees notification preference
  getEmployeesNotificationPreference(uid, notification, users) {
    this.notificationSettingService.getPushNotificationSettingsList(uid).valueChanges().subscribe(x => {

      if (x && x.length > 0) {
        this.employeeNotificationSettings = x[0]


        if (this.employeeNotificationSettings?.settings) {
          // check if the admin wants to recieve direct message .....
          let directMessagePreference = this.employeeNotificationSettings?.settings.find((setting) => setting?.name === "chat");


          if (directMessagePreference.isEnabled) {
            this.fcmPushService.sendNotificationToUsers(notification, users)

          }

        }


      }
    })
  }

  async sendNotificationForListOfUsers(notification, users) {
    let notifyUsers = [];

    // Iterate over the users array
    for (let i = 0; i < users?.length; i++) {
      if (users[i]?.role == "Admin") {
        // Make the API call using await
        await new Promise<void>((resolve, reject) => {
          this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x => {
            // Your code logic
            if (x && x.length > 0) {
              this.adminNotificationSettings = x[0]

              if (this.adminNotificationSettings?.settings) {
                // check if the admin wants to recieve direct message .....
                let directMessagePreference = this.adminNotificationSettings?.settings.find((setting) => setting?.name === "chat");


                if (directMessagePreference.isEnabled) {
                  notifyUsers.push(users[i]?.uid);

                }
              }
            }

            resolve();
          }, error => {
            reject(error);
          });
        });
        // Implement your logic after each iteration
      } else if (users[i]?.role == "User") {
        // Make the API call using await
        await new Promise<void>((resolve, reject) => {
          this.notificationSettingService.getClientPushNotificationSettingsList(users[i]?.uid).valueChanges().subscribe(x => {

            if (x && x.length > 0) {
              this.clientNotificationSettings = x[0]

              if (this.clientNotificationSettings?.settings) {
                // check if the admin wants to recieve direct message .....
                let directMessagePreference = this.clientNotificationSettings?.settings.find((setting) => setting?.name === "chat");


                if (directMessagePreference.isEnabled) {
                  notifyUsers.push(users[i]?.uid);

                }

              }


            }
            // Your code logic
            resolve();
          }, error => {
            reject(error);
          });
        });
        // Implement your logic after each iteration
      } else {
        // Make the API call using await
        await new Promise<void>((resolve, reject) => {
          this.notificationSettingService.getPushNotificationSettingsList(users[i]?.uid).valueChanges().subscribe(x => {
            // Your code logic
            if (x && x.length > 0) {
              this.employeeNotificationSettings = x[0]

              if (this.employeeNotificationSettings?.settings) {
                // check if the admin wants to recieve direct message .....
                let directMessagePreference = this.employeeNotificationSettings?.settings.find((setting) => setting?.name === "chat");

                if (directMessagePreference.isEnabled) {
                  notifyUsers.push(users[i]?.uid);

                }

              }


            }
            resolve();
          }, error => {
            reject(error);
          });
        });
        // Implement your logic after each iteration
      }
    }

    if (notifyUsers?.length) {
      // send the notifications for the users
      this.fcmPushService.sendNotificationToUsers(notification, notifyUsers);

    }

  }

  // send notification for group of users based on their role
  async sendNotificationForGroupOfUsers(notification, roles, notificationType) {

    // iterate over the selected roles and send push notification using the concept of topic
    // for(let i = 0; i < roles?.length; i++){
    //   let role = roles[i];

    //   if(role === "User"){
    //     await this.sendNotificationUsingTopic(notification, "User_Notification");
    //   }
    //   else if(role === "Admin"){
    //     await this.sendNotificationUsingTopic(notification, "Owner_Notification");

    //   }
    //   else if(role === "Employee"){
    //     await this.sendNotificationUsingTopic(notification, "Employee_Notification");

    //   }

    // }

    // return;
    // fetch users based on roles
    this.userService.getUserByRoles(roles).subscribe((users) => {

      if (users && users.length > 0) {
        let uids = [];


        // check if they have preference for that specfic notification
        for (let i = 0; i < users?.length; i++) {
          // check if the user wants to recieve notification for that specfic notification type like events, news or so on ..
          // if (users[i]?.allowPushNotification) {
            //  if the user wants to receive notification, the push his/her user id
            uids.push(users[i]?.uid);
          // }

        }


        // check the length of user ids length
        if (uids?.length) {
          this.fcmPushService.sendNotificationToUsers(notification, uids);

        }


      }
    });
  }

  // send notification for users using topic
  sendNotificationUsingTopic(message: NotificationModel, topic: any) {
    let notificationData: any = {
      topic: null,
      notification: {
        title: "",
        body: "",
        image: null
      },
      data: {
        title: null,
        body: null
      }
    };

    notificationData.topic = topic;
    notificationData.notification.title = message.title;
    notificationData.notification.body = message.body;
    notificationData.notification.image = message.image;
    // notificationData.notificationData.click_action = message.click_action;
    for (let key in message.data) {
      notificationData.data[key] = message.data[key]
    }
    notificationData.data['title'] = message.title
    notificationData.data['body'] = message.body

    this.fcmService.senFCMMessageToTopic(message.title, message.body, message.image, topic).subscribe(() => {
      this.successMessage("Notification Message sent successfully!")
    }, (error) => {
      this.errorMessage("Notification message failed!")
    });

  }

  // select the choosen user notification preference
  selectTheChoosenNotificationPreference(notifSettings) {
    let receiveNotificationFor = [];

    // iterate over the default notification and check the admin preference
    for (let i = 0; i < notifSettings?.settings?.length; i++) {
      let setting = notifSettings?.settings[i];

      // check which notifcation user wants to recieve
      if (setting && !setting?.settings?.length && setting?.isEnabled) {
        receiveNotificationFor.push(setting)
      }
      else if (setting?.settings?.length) {
        let nestedPreference;

        // iterate over the settings array and grab the enabled one.
        for (let i = 0; i < setting?.settings?.length; i++) {

          let preference = setting.settings[i];

          if (preference?.isEnabled) {
            // if the preference is enabled set it on the nested preference
            if (!nestedPreference) {
              nestedPreference = {
                "displayName": setting?.displayName,
                "name": setting?.name,
                "isEnabled": setting?.isEnabled,
                "settings": [preference]
              }
            }
            else {
              nestedPreference.settings = [...nestedPreference?.settings, preference]
            }
          }
        }

        // set it to the receive notification for
        if (nestedPreference) {
          receiveNotificationFor.push(nestedPreference);

        }


      }
    }

    return receiveNotificationFor;
  }


  // toast message handler
  successMessage(message) {
    this.toastr.success(message, "Success");
  }

  errorMessage(message) {
    this.toastr.error(message, "Error");
  }

}
