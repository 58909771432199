import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-smsverification',
  templateUrl: './smsverification.component.html',
  styleUrls: ['./smsverification.component.css']
})
export class SMSVerificationComponent implements OnInit {
  public phoneNumberMask = [
    /[0-9]/," ","-"," ",
    /[0-9]/," ","-"," ",
    /[0-9]/," ","-"," ",
    /[0-9]/," ","-"," ",
    /[0-9]/," ","-"," ",
    /[0-9]/,
  ];
  code:string = '';
  @Output() cancelVerification = new EventEmitter();
  @Output() verify = new EventEmitter();
  @Output() resend = new EventEmitter();
  @Input() wrongCode;


  constructor() { }

  ngOnInit(): void {
    this.code='';
    this.wrongCode = false;

  }

  cancelVerifying() {
    this.code = '';
    this.cancelVerification.emit();
  }
  verifyClicked(code : any) {
    this.verify.emit(code);
    this.code = '';
  }
  resendCode(code: any){
    this.resend.emit(code);
    this.code = '';

  }

  // onInputChange(inputNumber: number, event: Event): void {
  //   const target = event.target as HTMLInputElement;
  //   if (target.value.length === 1) {
  //     this.focusNextInput(inputNumber);
  //   }
  // }

  // onInputFocus(inputNumber: number): void {
  //   this.clearInputs(inputNumber);
  // }

  // private focusNextInput(inputNumber: number): void {
  //   if (inputNumber < 6) {
  //     const nextInput = this['input' + (inputNumber + 1)];
  //     if (nextInput) {
  //       nextInput.nativeElement.focus();
  //     }
  //   }
  // }

  // private clearInputs(inputNumber: number): void {
  //   for (let i = 1; i <= 6; i++) {
  //     if (i !== inputNumber) {
  //       const input = this['input' + i];
  //       if (input) {
  //         input.nativeElement.value = '';
  //       }
  //     }
  //   }
  // }

}


