import { NotificationsService } from './../../../services/firebase/notifications.service';
import { Component, OnInit } from '@angular/core';
import {   selectUserData } from 'src/app/state/app.selectors';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-admin-notifications',
  templateUrl: './admin-notifications.component.html',
  styleUrls: ['./admin-notifications.component.css']
})
export class AdminNotificationsComponent implements OnInit {

  user: any;
  user$: Subscription;
  notifications: any;
  constructor(private notificationsService: NotificationsService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.user$ = this.store.select(selectUserData).subscribe((user) => { this.user = { ...user }; });
    this.notificationsService.getMyNotifications(this.user.uid).valueChanges().subscribe(notifications => {
      if(notifications.length > 0 ){
        this.notifications = notifications;
      }
    });
  }
  ngOnDistroy() { 
    this.user$.unsubscribe();
  }

}
