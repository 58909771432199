<div class="w-full md:w-[650px] mx-auto rounded-md shadow-md border border-solid px-8 py-6 border-gray-300">
  <div class="py-4">
    <p class="text-gray-700 text-xl font-bold pb-1 ">General Settings</p>
    <div class="w-full py-0.5 bg-gray-200">
    </div>
  </div>
  <form [formGroup]="appSettingsForm" (ngSubmit)="confirmUpdate(appSettingsForm.value)">
    <div>
        <div>
    <!-- Admin email -->
    <div class="py-2">
        <label for="email" class=" tracking-wide flex items-center space-x-2 text-gray-700 text-base font-bold mb-2"> <span>Admin Email</span>
          <div class="tooltip" data-tip="Add an admin email to recieve notification">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
            </svg>
            
          </div>
        </label>
     
        <div formArrayName="adminEmail" *ngFor="let inviteEmail of adminEmail.controls; let i=index">
        <div class="flex flex-col">
            <div class="">
            <input type="email"
            class="  appearance-none block input text-base py-[0.32rem] px-3 leading-[1.6] border-2 min-h-[auto] input-bordered rounded-md bg-white w-full  text-gray-700  focus:outline-none  focus:border-gray-500"
                [ngClass]="{
            'border-red-500': (inviteEmail.dirty || inviteEmail.touched) && inviteEmail.invalid
            }" placeholder="e.g. email@test.com" [formControlName]="i">
            <div *ngIf="(inviteEmail.dirty || inviteEmail.touched) && inviteEmail.invalid">
              <small *ngIf="inviteEmail.errors.email" class="text-red-500 italic"> Wrong email format. </small>
            </div>
            </div>
            <div class="justify-start">
            <button (click)="removeAdminEmail(i)" class="mt-2 text-xs flex items-center space-x-2  font-semibold"><i class="material-icons text-sm">delete</i>Remove Email</button>
            </div>
        </div>
        </div>
        <div class="flex  w-full justify-end">
        <div class="">
            <a class="add-email-btn text-customTeal font-bold" (click)="addAdminEmail()">
            <i class="material-icons float-left mr-2">add_circle</i>Add Email</a>
        </div>
        </div>
    </div>
    <hr>
    <!-- Offerring -->
    <div class="flex flex-col my-4 space-y-4" *ngIf="!envService.fromFNLeaders">
        <div class="w-full ">
        <div>
            <div class="text-base font-bold mb-1">What are you offering? <span class=" text-red-500">*</span></div>
            <p class="hidden md:block text-sm text-gray-500">Choose what you provide for your customers/users.
            You can choose both Product and Service or
            one of them.</p>
        </div>
        </div>

        <div class="w-full  flex flex-col md:flex-row md: justify-start">

        <div class="w-full md:w-3/12 flex">
            <div>
            <div class="form-control">
                <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                    value="Product" (change)="onOfferChange()" />
                <span class="label-text pl-2 font-bold ">Products</span>
                </label>
            </div>
            </div>
        </div>

        <div class="w-full md:w-3/12 flex">
            <div>
            <div class="form-control">
                <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                    value="Service" (change)="onOfferChange()" />
                <span class="label-text pl-2 font-bold ">Services</span>
                </label>
            </div>
            </div>
        </div>

        <div class="w-full md:w-3/12 flex">
            <div>
            <div class="form-control">
                <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                    value="Both" (change)="onOfferChange()" />
                <span class="label-text pl-2 font-bold ">Both</span>
                </label>
            </div>
            </div>
        </div>

        <div class="w-full md:w-3/12 flex">
            <div>
            <div class="form-control">
                <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                    value="None" (change)="onOfferChange()" />
                <span class="label-text pl-2 font-bold ">None</span>
                </label>
            </div>
            </div>
        </div>
        </div>

    </div>
    <hr />
   
    <!-- Currency -->
    <div class="flex flex-col  my-4 space-y-2"
        *ngIf="(serviceType.value !='None' && serviceType.value !='') && !envService.fromFNLeaders">
        <div class="w-full ">
        <div>
            <div class="text-base font-bold mb-1">Currency?</div>
            <p class="hidden md:block text-sm text-gray-500">Choose the currency you will be using
            </p>
        </div>
        </div>

        <div class="w-full flex ">
        <!-- toggle -->
        <div>
            <div class="form-control">
            <label class="label cursor-pointer ">
                <select formControlName="currency" class="select select-bordered w-full border-2 bg-veryLightGray">
                <option disabled selected>Select Currency</option>
                <option value={{currency.id}} *ngFor="let currency of currencies">{{currency.name}}</option>
                </select>
            </label>
            </div>
        </div>

        </div>

    </div>
    <hr />
<div class="flex flex-wrap gap-4 mt-4">
      

    <!-- Appointments -->
       <div class=" flex flex-col w-full md:w-full bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4" *ngIf="!envService.fromFNLeaders && (serviceType.value !='None' && serviceType.value !='')">
        <div class="w-full ">
        <div class="flex">
            <div class="text-base font-bold mb-4">Do you take appointments?<span class=" text-red-500">*</span></div>
            <span class="tooltip" data-tip="Please specify if you take appointments or not for your services.">
              <i class="material-icons">info</i>
            </span>
        </div>
        </div>

        <div class="w-full  flex ">

        <div>
            <div class="form-control">
            <!-- <label class="label cursor-pointer space-x-2">
                <span *ngIf="!takeAppointment.value" class="label-text text-sm pr-2 font-bold "
                [ngClass]="{' text-gray-400': takeAppointment.value}">No</span>

                <input type="checkbox" class="toggle checked:bg-customTeal" checked
                formControlName="takeAppointment" required (change)="addScheduleModal = true && takeAppointment.value" />
                <span *ngIf="takeAppointment.value" class="label-text text-sm pr-2 font-bold "
                [ngClass]="{' text-customTeal': !takeAppointment.value}">Yes</span>
            </label> -->
            <div class="w-full  flex flex-col md:flex-row md: justify-start">

              <div class="w-full md:w-1/2 flex">
                  <div>
                  <div class="form-control">
                      <label class="label cursor-pointer">
                      <input type="radio" class="radio checked:bg-customTeal" formControlName="takeAppointment"
                          value="yes"
                          (change)="addScheduleModal=true" />
                      <span class="label-text pl-2 font-bold ">Yes</span>
                      </label>
                  </div>
                  </div>
              </div>
      
              <div class="w-full md:w-1/2 flex">
                  <div>
                  <div class="form-control">
                      <label class="label cursor-pointer">
                      <input type="radio" class="radio checked:bg-customTeal" formControlName="takeAppointment"
                          value="no" />
                      <span class="label-text pl-2 font-bold ">No</span>
                      </label>
                  </div>
                  </div>
         </div>
      </div>

            <div *ngIf="takeAppointment.value == '' && !appSettingsForm.valid" class="text-xs italic text-red-600">
              you must select an option to proceed  
            </div>
            </div>

            

            <button
            *ngIf="takeAppointment.value == 'yes'"
            type="button"
            class="inline-block rounded border border-solid border-customTeal justify-end  text-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal  shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
            data-te-ripple-color="light"
            (click)="addScheduleModal=true"
            >
            Update Schedule 
            </button>
          <!-- On first login setup -->
          <dialog id="my_modal_2" class="modal" [ngClass]="{'modal-open': addScheduleModal && takeAppointment.value=='yes'}" >
            <form method="dialog" class="modal-box rounded-md shadow-md max-w-5xl">
              <app-add-schedule *ngIf="takeAppointment.value=='yes'" (createClicked)="createSchedule($event)" (updateClicked)="updateSchedule($event)" (defaultScheduleDisplayed)="initializeSchedule($event)" calledFrom='onboarding' (cancelClicked)="addScheduleModal=false"></app-add-schedule>
            </form>
          </dialog>

        </div>
        </div>

    </div>

  </div>

</div>
    <div class="w-full flex justify-end pt-6"><button 
      type="submit"
      [disabled]="!appSettingsForm.valid "
        class="inline-block rounded  px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        data-te-ripple-init
        data-te-ripple-color="light"
              [ngClass]="{'bg-[#cccccc] text-[#666666]': !appSettingsForm.valid, 'bg-customTeal': appSettingsForm.valid }"
        >Save</button></div>

    </div>
</form>

</div>



<!-- toast -->
<app-alert class="fixed top-0 flex  w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveUpdate()
  editedFromComponent='App Setup'></app-edit-confirmation>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteItem()
  (cancelDeleteClicked)="deleteModalOpen=false" deletedFromComponent='Section'></app-delete-confirmation>
