    <div 
    class="flex md:max-w-6xl justify-center w-full flex-wrap items-center mx-auto  gap-y-4 my-8">
      <!--card view  -->
      <div *ngFor="let section of cardElement; let i = index "
      class="flex justify-end "
      [ngClass]="{'hidden' : (section.originalName == 'Services' && (serviceType == 'Product' || serviceType =='None'))  ||  (section.originalName =='Products' && (serviceType == 'Service' || serviceType =='None')) }">

      <div >
        <!-- card item-->
        <div
          *ngIf="section.section.isEnabled"
          class="cursor-pointer  mx-2">
          <div (click)="navigateTo(section.section)">
            <app-card-element [cardDesign]="cardDesign" [section]="section.section"></app-card-element>

          </div>
        </div>
      </div>
 
      </div>

    </div>