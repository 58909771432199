import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.css', 
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string = ''; // Optional label
  @Input() disabled: boolean = false; // Optional disabled state
  @Input() styles: { [key: string]: string } = {}; // Optional custom styles
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>(); // Event emitter to notify parent component

  value: boolean = false; // Current value of the checkbox
  onChange = (value: boolean) => {
    this.valueChanged.emit(this.value);
  }; // Propagate changes
  onTouched = () => {}; // Mark as touched

  toggleCheckbox() {
    if (!this.disabled) {
      this.value = !this.value;
      this.onChange(this.value); // Notify Angular form about the change
      this.onTouched(); // Mark as touched
    }
  }

  // ControlValueAccessor methods
  writeValue(value: boolean): void {
    this.value = value || false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
