import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { InviteService } from './../firebase/invite.service';
import { Injectable } from '@angular/core';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomAdminEmailModel, EmailModel } from 'src/app/models/emailModel';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FireMembersService } from '../firebase/fire-members.service';
import { SMSModel } from 'src/app/models/SMSModel';
//import { M } from 'angular-mydatepicker';
import { EnvironmentService } from '../helper/env';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  appName: any;
  appLogo: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false
  is_NationApp = environment.firstNation.name == "FNLeaders" ? true : false
  isDemoApp = environment.firstNation.name == "Demo" ? true : false
  url = environment.firstNation.apiUrl + 'api/Email/';
  website = environment.firstNation.website;

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService,
    private fireMembersService: FireMembersService, private inviteService: InviteService,
    private appSettingsService: AppSettingsService,
    private envService: EnvironmentService) {
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((setting: any) => {
      if (setting) {
        if (setting.length > 0) {
          this.appName = setting[0].appName;
          this.appLogo = setting[0].logo;
        }
      }
    });
  }

  sendEmail(model: EmailModel): Observable<any> {    
    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(model), { headers });
    
  }

  // sendEmail(model: CustomAdminEmailModel): Observable<any> {    
  //   const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
  //   return this.httpClient.post<any>(this.url + 'customAdminEmail/', JSON.stringify(model), { headers });
    
  // }

  sendCustomEmail(model: CustomAdminEmailModel): Observable<any> {
    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'customAdminEmail/', JSON.stringify(model), { headers });
  }

  sendEmailSign(model: EmailModel): Observable<any> {
    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(model), { headers });
  }

  sendContactAdminEmail(clientUID: string, email: EmailModel) {
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
      if (x) {
        let admins: any[];
        let member: any;
        admins = x.filter(e => e.role === 'Admin');
        member = x.filter(e => e.uid === clientUID);
        email.emailList = [];
        admins.forEach(o => {
          email.emailList.push(o.Email);
        });
        const subject = email.subject;
        const body = email.body;
        email.subject = 'You have a new message from ' + member[0].GivenName + ' ' + member[0].LastName;
        email.body = 'From : ' + member[0].Email + ' <br/> Subject : <strong> ' + subject + ' </strong> <br/> <p>' + body + '</p>';
        email.isNationApp = this.isNationApp;

        const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(email), { headers });
      }
    });
  }

  sendContactUsNotification(model) {
    let email = new EmailModel();
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
      if (x) {
        let admins: any[];
        admins = x.filter(e => e.role === 'Admin');
        email.emailList = [];
        admins.forEach(o => {
          email.emailList.push(o.Email);
        });
        email.subject = 'Contact Us';
        email.isNationApp = this.isNationApp;

        email.body = '<strong>From : </strong>' + model.name + ' <br/><strong> Email:</strong> ' + model.email + '<br/><strong>Message :</strong> <p>' + model.message + '</p>';
        const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(email), { headers });
      }
    });
  }

  sendNewsNotification(model: any) {
    model.title = model.title.replace(/<[^>]*>/g, '');
    model.body = model.body.replace(/<[^>]*>/g, '');
    const nation = environment.firstNation.displayName;
    let clients: any[];
    const mail = new EmailModel();
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
      if (x) {
        clients = x.filter(e => e.role === 'Member');
        mail.emailList = [];
        clients.forEach(o => {
          if (o.Email) { mail.emailList.push(o.Email); }
        });
        mail.subject = 'Your Nation Shared a News';
        mail.isNationApp = this.isNationApp;

        mail.body = '<span style="color:blue; margin-right: 15px;">News Title : </span> ' + model.title + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Details : </span> ' + model.body + ' <br/> ';
        mail.body += '<br/><br/> For More Details Please Check The News Page at : <a href="' + environment.firstNation.website + '/news" target="_blank">mynation.app</a>';
        mail.body += '<br/> Thank You';

        const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
        });
      }
    });
  }

  sendAppointmentConfirmation(model: any, Email: any) {

    const mail = new EmailModel();
    mail.emailList = [Email];
    mail.subject = 'Appointment Confirmation Email ' + this.appName;
    mail.isNationApp = this.isNationApp;

    mail.body = '<div style="font-family: Helvetica, Arial, Sans-Serif;">';
    mail.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
    mail.body += '<center>';
    if (this.appLogo) {
      mail.body += `<img
      src="${this.appLogo}"
      width="150" height="150" style=" display: block; alt="Logo">`;
    }
    mail.body += '</center>';
    mail.body += `<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px;">  Hello ${model.appointedFor}</h2>`;
    mail.body += '<hr> ';
    mail.body += '<div style="display: block; margin-left: auto; margin-right: auto; width: max-content;">';
    mail.body += `<p> Your Service ${model.serviceName} is confirmed for date ${model.selectedServiceTime.getDate()}/${model.selectedServiceTime.getMonth() + 1}/${model.selectedServiceTime.getFullYear()} from ${model.selectedServiceTime.getHours()}:${model.selectedServiceTime.getMinutes()} to  ${model.selectedServiceEndTime.getDate()}/${model.selectedServiceEndTime.getMonth() + 1}/${model.selectedServiceEndTime.getFullYear()} and ${model.selectedServiceEndTime.getHours()}:${model.selectedServiceEndTime.getMinutes()}. </p>`;
    mail.body += '<div padding-left: 20px;">';
    mail.body += `<p> If you’re unable to make this appointment or would like to change your appointment to a different date or time, please call contact the administrator. </p>`;
    mail.body += `<p  style="margin-top:3%"> Thank you </p>`;
    mail.body += '</div>';
    mail.body += '</div>';
    mail.body += '</div>';
    mail.body += '</div>';

    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
    });
  }

  sendEventNotification(model: any) {
    model.title = model.title.replace(/<[^>]*>/g, '');
    model.body = model.body.replace(/<[^>]*>/g, '');
    const nation = environment.firstNation.displayName;
    let clients: any[];
    const mail = new EmailModel();
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
      if (x) {
        clients = x.filter(e => e.role === 'Member');
        mail.emailList = [];
        clients.forEach(o => {
          if (o.Email) { mail.emailList.push(o.Email); }
        });
        mail.subject = 'New Event On ' + model.eventDate;
        mail.isNationApp = this.isNationApp;

        mail.body = '<span style="color:blue; margin-right: 15px;">Event Title : </span> ' + model.title + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Event Date : </span> ' + model.eventDate + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Event Place : </span> ' + model.eventPlace + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Description : </span> ' + model.body + ' <br/> ';
        mail.body += '<br/><br/> For More Details Please Check The Event Calendar at : <a href="' + environment.firstNation.website + '/member/events-calendar" target="_blank">mynation.app</a>';
        mail.body += '<br/> Thank You';
        const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
          if (o) {
            // M.toast()
          }
        });
      }
    });
  }

  sendCongratulationEmail(model: EmailModel): Observable<any> {
    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'sendCongratulationEmail/', JSON.stringify(model), { headers });
  }

  sendFilledApplicationNotification(application: any, employees: any) {
    const mail = new EmailModel();
    mail.emailList = [];
    if (employees && application) {
      mail.emailList = employees;
      mail.subject = `${application} Application filled`;
      mail.body = `The ${application} application has been filled please review.`;
      mail.isNationApp = this.isNationApp;

      const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers });
    }
  }

  sendUserInvite(model: any, currentUser) {
    const mail = new EmailModel();
    model.appName = this.appName ? this.appName : model.appName;
    mail.emailList = [model.inviteEmail];
    mail.subject = 'Invitation to join ' + model.appName;
    mail.isNationApp = this.isNationApp;

    mail.body = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
    mail.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
    mail.body += '<center>';
    if (this.appLogo) {
      mail.body += `<img
    src="${this.appLogo}"
    width="150" height="150" style=" display: block; alt="Logo">`;
    }
    mail.body += '</center>';
    mail.body += '<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px;">  Hello </h2>';
    mail.body += '<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px;">' +
      model.appName + ' invited you to create an account  . Get started by clicking on the link below. </p>';

    mail.body += '<div style="display: block; margin-left: auto; margin-right: auto; width: max-content;">';
    mail.body += `<a href="${model.hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 15px 32px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: white; ">Register Now</a>`;
    mail.body += '</div>';
    mail.body += '<hr> ';
    mail.body += '</div>';
    mail.body += '<div style="background-color: #e5e5e5; padding-left: 20px;">';
    mail.body += '<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 10px;">' + currentUser.GivenName +
      ' ' + currentUser.LastName + '</p>';
    mail.body += `<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 5px; padding-bottom: 10px;">${currentUser.Email}</p>`;
    if (currentUser.jobTitle != undefined) {
      mail.body += '<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 5px; padding-bottom: 10px;">' + currentUser.jobTitle ? currentUser.jobTitle : '' + '</p>';
    }
    if (currentUser.cellPhone && currentUser.role == 'Admin') {
      mail.body += '<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 5px; padding-bottom: 10px;">' + currentUser.cellPhone ? currentUser.cellPhone : '' + '</p>';
    }
    mail.body += '</div>';
    mail.body += '</div>';

    // custom send email used
    // mail.host = "smtp.gmail.com"
    // mail.adminEmail = "mkproject.app@gmail.com"
    // mail.appPassword = "ytklyidomvqfmtky"

    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
    });
  }

  sendEmployeeInvite(model: any, currentUser) {
    model.appName = this.appName ? this.appName : model.appName;
    const mail = new EmailModel();
    mail.emailList = [model.inviteEmail];
    mail.subject = 'Invitation to join ' + model.appName;
    mail.isNationApp = this.isNationApp;

    mail.body = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
    mail.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
    mail.body += '<center>';
    if (this.appLogo) {
      mail.body += `<img
      src="${this.appLogo}"
      width="150" height="150" style=" display: block; border-radius: 50%" alt="Logo">`;
    }
    mail.body += '</center>';
    mail.body += '<center>'
    mail.body += '<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px; color: #077373;">  Welcome to ' + model.appName + ' </h2></center>';
    mail.body += '<h3 style="text-transform: capitalize; padding-left: 20px;">  Dear ' + model.firstName + ' , </h3>';
    mail.body += '<center><p style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px; text-align: justify">' + 'This is ' + '<span>' + currentUser.GivenName + '</span>' + ' from ' + 
    '<span style="font-weight: bold">' + model.appName + '</span>' +
      ', we are thrilled to have you on our team! we would like to invite you to create your account on our platform and confirm your credentials.</p>';
    mail.body += '</center>';

    mail.body += '<div style="display:flex; justify-content: center; padding-left: 20px; padding-right: 40px; justify-content: center;">';
    if (model.jobTitle) {
      mail.body += '<div style="padding:20px; width: max-content;">';
      mail.body += '    <div style="display:inline-block; vertical-align: middle; " >';
      mail.body += '       <p style="color:grey; margin: 0; ">Job Title</p>';
      mail.body += '       <p style="font-weight:bold; font-size: 16px; margin: 0; ">' + model.jobTitle + '</p>';
      mail.body += '   </div>';
      mail.body += '    </div>';
    }

    mail.body += '</div>';
    mail.body += '<div style="display: block; margin-left: auto; margin-right: auto; width: max-content;">';
    mail.body += `<a href="${model.hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 15px 32px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: white; ">Create Account</a>`;
    mail.body += '</div>';
    mail.body += '<hr> ';
    mail.body += '<div style="background-color: #e5e5e5; padding-left: 20px;">';
    mail.body += '<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 10px;">' +
      currentUser.GivenName + ' ' + currentUser.LastName + '</p>';
    mail.body += `<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 5px; padding-bottom: 10px;">${currentUser.Email}</p>`;
    if (currentUser.jobTitle != undefined) {
      mail.body += '<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 5px; padding-bottom: 10px;">' + (currentUser.jobTitle ? currentUser.jobTitle : '') + '</p>';
    }
    if (currentUser.cellPhone && currentUser.role == 'Admin') {
      mail.body += '<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 5px; padding-bottom: 10px;">' + (currentUser.cellPhone ? currentUser.cellPhone : '') + '</p>';
    }
    mail.body += '</div>';
    mail.body += '</div>';

    // custom send email used
    // mail.host = "smtp.gmail.com"
    // mail.adminEmail = "mkproject.app@gmail.com"
    // mail.appPassword = "ytklyidomvqfmtky"

    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
    });

  }

  sendAppointmentNotification(appointmentModel: any, hostname, service, admin, currentUser) {
    appointmentModel.appName = this.appName ? this.appName : appointmentModel.appName;
    const mail = new EmailModel();

    mail.emailList = [currentUser.Email];
    mail.subject = 'Appointment Confirmation';
    mail.isNationApp = this.isNationApp;

    mail.body = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
    mail.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
    mail.body += '<center>';
    if (this.appLogo) {
      mail.body += `<img
      src="${this.appLogo}"
      width="150" height="150" style=" display: block; alt="Logo">`;
    }
    mail.body += '</center>';
    mail.body += '<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px;">  Hello ' + currentUser.GivenName + ', </h2>';
    mail.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px;">
    This is ${admin.GivenName} from ${this.appName}. I just wanted to
    send you confirmation that your appointmnet has been scheduled for ${service.serviceName}
    on ${appointmentModel.startTime}.</p>`;
    mail.body += `<br/>Looking forward to seeing you!<br/>
    Visit our site to reschedule or cancel the appointment. `;
    mail.body += '<div style="display: block; margin-left: auto; margin-right: auto; width: max-content;">';
    mail.body += `<a href="${hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 15px 32px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: white; ">Go to site</a>`;
    mail.body += '</div>';
    mail.body += '<hr><br/> ';
    mail.body += `${admin.GivenName} ${admin.LastName}`;
    mail.body += '<br/></div>';

    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
    });

  }

  sendAppRequestEmail(model: any, adminEmail) {
    const mail = new EmailModel();

    let lastOfGoingApp = ".goingmobile.app"
    let lastOfNationApp = ".mynation.app"
    let extenstion, name
    let link = environment.firstNation.website

    if (this.isDemoApp) {
      extenstion = lastOfGoingApp
      name = "Demo"
    }
    else if (this.isNationApp) {
      extenstion = lastOfNationApp
      name = "First Nation Leaders"
    }

    // info@goingmobile.app
    mail.emailList = this.is_NationApp ? ['noreply@mynation.app'] : ['info@goingmobile.app'];
    mail.subject = 'App Request Recieved';
    mail.isNationApp = this.isNationApp;

    mail.body = '<span>Hello</span><br/>';
    mail.body += `<span>An app user with the following information has requested to have their own app from <a href=${link}/auth/login/>${link}/auth/login</a> platform. Please check their application.</span><br/>`;
    mail.body += `<span><strong>Full name: </strong> ${model.fullName}</span><br/>`;
    mail.body += `<span><strong>Email: </strong> ${model.email}</span><br/>`;
    // mail.body += `<span><strong>App Name: </strong> ${model.appName}</span><br/>`;
    mail.body += `<span><strong>URL: </strong> ${model.url}${extenstion}</span><br/>`;
    mail.body += `<span><strong>Mobile number: </strong> ${model.mobileNumber}</span><br/><br/>`;
    mail.body += '<br/> Thank you.';
    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
    });
  }

  myAppRequest(model: any) {
    // In the meantime, please check
    // that the information provided below is accurate.
    // If it is, then no further action is required.
    // If any changes are required, please respond to this
    // email and let us know.
    const mail = new EmailModel();
    mail.emailList = [model.email];
    mail.subject = 'App Request Sent';
    mail.isNationApp = this.isNationApp;

    mail.body = `Hello  ${model.fullName} Thank you for registering your custom mobile app with Going Mobile.
    Your request has been sent to one of our app administrators and you will be contacted
    soon with further instruction.<br/>
    `;
    mail.body += `<span><strong>Full name: </strong> ${model.fullName}</span><br/>`;
    mail.body += `<span><strong>Email: </strong> ${model.email}</span><br/>`;
    // mail.body += `<span><strong>App Name: </strong> ${model.appName}</span><br/>`;
    mail.body += `<span><strong>URL: </strong> ${model.url}.goingmobile.app</span><br/>`;
    mail.body += `<span><strong>Mobile number: </strong> ${model.mobileNumber}</span><br/>`;
    mail.body += '<br/> Looking forward to connecting soon.';
    mail.body += '<br/><br/> – Your Going Mobile Team';
    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
    });

  }

  loadImages(imagePaths, model) {
    const base64Images: string[] = [];

    for (const imagePath of imagePaths) {
      this.httpClient.get(imagePath, { responseType: 'blob' })
        .subscribe(response => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            base64Images.push(base64data);

            // Check if all images are loaded
            if (base64Images.length === imagePaths.length) {
              // this.sendEmailWithImages(model, base64Images);
            }
          };
          reader.readAsDataURL(response);
        });
    }
  }

  sendEmailWithImages(model) {
    let body = '', subject

    let link = 'https://firstnationleaders.app'
    let loginLink = link + '/auth/login'
    let condition = model.isNationMember && model.isInOffice && ['Chief','Councillor'].includes(model.typeOfOffice)
    let name = model.fullName.split(' ')
    let title = condition ? `${model.typeOfOffice}  ${name[0]}` : `Dear ${name[name.length - 1]}`

    let appEmail = this.is_NationApp ? "noreply@mynation.app" : "info@goingmobile.app";


    body += `
    <div style="padding: 0; margin: 0; background-color: white; ">
  <div style="text-align: center; padding: 0">
    <p
      style="
        font-style: italic;
        font-size: 64px;
        font-weight: bold;
        margin: 20px;
      "
    >
      WELCOME!
    </p>
  </div>

  <table cellpadding="0" cellspacing="0" border="0" width="100%">
    <tr
      style="
        background: linear-gradient(
          to bottom,
          #d28227 0%,
          #d28227 50%,
          white 50%,
          white 100%
        );
      "
    >
      <td align="center" style="width: 30%; padding-top: 3%">
        <img
          src="https://api.goingmobile.app/Media/FNLeaders/Images/88642de1-0ba9-475b-94d9-6cd02c7aec34.png"
          alt="Image 1"
          width="200px"
          height="200px"
          style="display: block"
        />
      </td>
      <td align="center" style="width: 30%; padding-top: 3%">
        <img
          src="https://api.goingmobile.app/Media/FNLeaders/Images/f24f712f-c338-4a37-a59c-acfa1e1ef18b.png"
          alt="Image 2"
          width="200px"
          height="200px"
          style="display: block"
        />
      </td>
      <td align="center" style="width: 30%; padding-top: 3%">
        <img
          src="https://api.goingmobile.app/Media/FNLeaders/Images/0489371f-bcf3-4595-aef4-4c3aaeecfed6.png"
          alt="Image 3"
          width="200px"
          height="200px"
          style="display: block"
        />
      </td>
    </tr>
  </table>

  <p style="padding-left: 1%; font-style:bold;color: #0e7b7e;">${title}, </p>

  <div style="text-align: center; padding: 2% 10px">
    <p style="font-style: italic; font-size: 24px; margin: 0">
      WELCOME TO YOUR PLATFORM
      <!-- <span style="display: block">LETS MAKE IT YOUR OWN</span> -->
    </p>
  </div>

  <div style="padding: 0 3%; text-align: left; color: #0e7b7e">
    <p>
      Thank you for choosing us to build your progressive web app. By joining
      us, you are taking a significant step towards enhancing your online
      presence and empowering your community.
    </p>
    <p style="font-weight: bold">Whats Next?</p>
    <p>
      Now that you have created your account, it's time to get started! Here's a
      quick overview of the next steps:
    </p>
    <ul>
      <li>Login to Your Account by following this link <a href="${loginLink}">${link}</a> </li>
      <li>View your profile</li>
      <li>
        Under manage your apps, you will find your pending apps and approved
        apps
      </li>
      <li>
        Once your app has been approved, you will recieve an email to start
        customizing your platform
      </li>
      <li>
        Follow the easy to follow steps and you are ready to publish
        automatically.
      </li>
    </ul>

    <p>
      We are honored to have you as part of our community. Together, let's
      harness the power of technology to create a brighter future for First
      Nations leaders and their communities.
    </p>

    <p>
      If you have any questions or need assistance, please don't hesitate to
      reach out. We're here to support you. ${this.envService.supportEmail}
    </p>

    <div style="padding-right: 2%;margin-bottom: 1% ;">
      <p>Warm Best regards <span style="display: block">Mynation Team</span></p>

      <a
      style="
        background-color: #d28227;
        border: none;
        color: white;
        padding: 8px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-style: normal;
        cursor: pointer;
        float: right;
      "
      href="${loginLink}"
      class="button"
      >Login</a
    >

    </div>
  </div>
</div>
  
    `

    const mail = new EmailModel();
    mail.emailList = [model.email];
    mail.subject = 'App Request Sent';
    mail.body = body
    mail.isNationApp = this.isNationApp;

    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
    });

  }

  myAppRequestWelcome(model: any) {
    // In the meantime, please check
    // that the information provided below is accurate.
    // If it is, then no further action is required.
    // If any changes are required, please respond to this
    // email and let us know.

    if (this.isNationApp) {
      // const imagePaths = [
      //   'assets/img/nationLeadersEmail-1.png',
      //   'assets/img/nationLeadersEmail-2.png',
      //   'assets/img/nationLeadersEmail-3.png',
      // ];

      // return this.loadImages(imagePaths,model)
      return this.sendEmailWithImages(model)
    }
    if (this.website.includes('#')) {
      this.website = this.website.replace('#', '');
    }
    const mail = new EmailModel();
    mail.emailList = [model.email];
    mail.subject = 'App Request Sent';
    mail.isNationApp = this.isNationApp;

    let appEmail = this.is_NationApp ? "noreply@mynation.app" : "info@goingmobile.app";

    let app = this.is_NationApp ? "MyNation.app" : "GoingMobile.app";

    mail.body = `
    <!-- preheaderBlock -->
    <table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#3f3f3f" id="" style="background-color: #3f3f3f">
      <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">
      <div style="font-size: 1px; line-height: 1px; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden"></div>
      <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--
      --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; width: 100%; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px" width="570" class="vb-row">
        
        <tbody><tr>
      <td align="left" valign="top" style="font-size: 0; padding: 0 9px"><div style="width: 100%; max-width: 552px; -mru-width: 0px"><!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="552"><tr><![endif]--><!--
        --><!--
          --><!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276"><![endif]--><!--
      --><!--[if (gte mso 9)|(lte ie 8)]></td><![endif]--><!--
          --><!--[if (gte mso 9)|(lte ie 8)]><td align="left" valign="top" width="276" class="mobile-hide"><![endif]--><!--
      --><!--[if (gte mso 9)|(lte ie 8)]></td><![endif]--><!--
        --><!--
      --><!--[if (gte mso 9)|(lte ie 8)]></tr></table><![endif]--></div></td>
    </tr>
      
      </tbody></table></div><!--
    --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
    </td></tr>
    </tbody></table>
    <!-- /preheaderBlock -->
    <table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#ffffff" id="ko_logoBlock_1" style="background-color: #ffffff">
      <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">
      <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--
      --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="9" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px" width="570" class="vb-row">
        
        <tbody><tr>
      <td align="center" valign="top" style="font-size: 0"><div style="vertical-align: top; width: 100%; max-width: 184px; -mru-width: 0px"><!--
        --><table role="presentation" class="vb-content" border="0" cellspacing="9" cellpadding="0" width="184" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px">
          
        <tbody><tr><td width="100%" valign="top" align="center" class="links-color"><!--[if (lte ie 8)]><div style="display: inline-block; width: 166px; -mru-width: 0px"><![endif]--><img border="0" hspace="0" align="center" vspace="0" width="166" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; width: 100%; max-width: 166px; height: auto;" src="https://mosaico.io/srv/f-o2ib7hq/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2Fo2ib7hq%2FLogo%2520without%2520text.png&amp;method=resize&amp;params=166%2Cnull"><!--[if (lte ie 8)]></div><![endif]--></td></tr>
        </tbody></table></div></td>
    </tr>
      
      </tbody></table></div><!--
    --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
      
    </td></tr>
    </tbody></table>

    <table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#ffffff" id="ko_singleArticleBlock_1" style="background-color: #ffffff">
      <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">
      <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--
      --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="9" bgcolor="#ffffff" width="570" class="vb-row" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px">
        
        <tbody><tr>
      <td align="center" valign="top" style="font-size: 0"><div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px"><!--
        --><table role="presentation" class="vb-content" border="0" cellspacing="9" cellpadding="0" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px" width="552">
          
          <tbody><tr><td width="100%" valign="top" align="center" class="links-color" style="padding-bottom: 9px"><!--[if (lte ie 8)]><div style="display: inline-block; width: 534px; -mru-width: 0px"><![endif]--><img border="0" hspace="0" align="center" vspace="0" width="534" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #3f3f3f; font-size: 14px; font-family: Arial, Helvetica, sans-serif; width: 100%; max-width: 534px; height: auto;" src="https://mosaico.io/srv/f-20sfw6j/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2F20sfw6j%2FYour%2520App%2520%25281%2529.png&amp;method=resize&amp;params=534%2Cnull"><!--[if (lte ie 8)]></div><![endif]--></td></tr>
          <tr>
      <td width="100%" valign="top" align="left" style="font-weight: normal; color: #3f3f3f; font-size: 18px; font-family: Arial, Helvetica, sans-serif; text-align: left"><span style="font-weight: normal">Hi ${model.fullName},</span></td>
    </tr>
          <tr><td class="long-text links-color" width="100%" valign="top" align="left" style="font-weight: normal; color: #3f3f3f; font-size: 14px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal"><p class="poppins-paragraph" style="margin: 1em 0px; margin-top: 0px;">Great news - we’ve received your application! Your custom mobile web app journey is underway.</p>
          <p style="margin: 1em 0px;">Your next steps are as follows:</p>
          <ol>
          <li>Check Your Application Status:</li>
          Log in to your account to keep an eye on the progress of your application.
          <li>Explore Our Features:</li>
          Discover the array of features and customization options that await you.
          <li>Need Assistance?</li>
          We're here for you. Reach us at ${appEmail} for any queries or assistance.</ol>
          <p style="margin: 1em 0px; margin-bottom: 0px;">We are excited to see the incredible mobile web app you’ll build and the heights your online presence will reach with ${app}</p></td></tr>
                    
                  
        </tbody></table></div></td>
    </tr>
      
      </tbody></table></div><!--
    --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
    </td></tr>
    </tbody></table>

    <table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#ffffff" id="ko_textBlock_1" style="background-color: #ffffff">
      <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">
      <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--
      --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="18" bgcolor="#ffffff" width="570" class="vb-container" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px">
        
        <tbody><tr><td class="long-text links-color" width="100%" valign="top" align="left" style="font-weight: normal; color: #3f3f3f; font-size: 14px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal"><p style="margin: 1em 0px; margin-top: 0px;">To New Beginnings,</p>
<p style="margin: 1em 0px; margin-bottom: 10px;"><strong>The Going Mobile Team</strong></p>
<a href="${this.website}"
style="background-color: #03989e; text-align: center; margin: 1em 0px;  font-weight: normal; padding: 6px; padding-left: 18px; padding-right: 18px;  color: #f2f2f2; font-size: 13px; font-family: Arial, Helvetica, sans-serif; border-radius: 4px"
>Login Now</a>
</td></tr>

      </tbody></table></div><!--
    --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
    </td></tr>
    </tbody></table>

    <table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#ffffff" id="ko_bigSocialBlock_1" style="background-color: #ffffff">
      <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">
      <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--
      --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="18" bgcolor="#ffffff" width="570" class="vb-container links-color socialLinks mobile-textcenter" style="font-size: 6px; border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 18px; border-spacing: 18px; max-width: 570px; -mru-width: 0px;">
        
        <tbody>
        
        <tr><td width="100%" valign="top" style="font-size: 6px; font-weight: normal; text-align: center;" align="center" class="links-color socialLinks mobile-textcenter">
          
            &nbsp;<a style="display: inline-block; border-radius: 50px;" target="_new" href="https://www.facebook.com/goingmobileapp?mibextid=LQQJ4d"><img border="0" src="https://mosaico.io/templates/versafix-1/img/icons/fb-rdcol-96.png" width="32" height="32" alt="Facebook" style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px;"></a>

            &nbsp;<a style="display: inline-block; border-radius: 50px;" target="_new" href="https://www.linkedin.com/company/81902758/admin/feed/posts/"><img border="0" src="https://mosaico.io/templates/versafix-1/img/icons/in-rdcol-96.png" width="32" height="32" alt="Linkedin" style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px;"></a>

            &nbsp;<a style="display: inline-block; border-radius: 50px;" target="_new" href="https://www.instagram.com/goingmobileapp/?igshid=YmMyMTA2M2Y%3D"><img border="0" src="https://mosaico.io/templates/versafix-1/img/icons/inst-rdcol-96.png" width="32" height="32" alt="Instagram" style="border: 0px; display: inline-block; vertical-align: top; padding-bottom: 0px;"></a>
          
            
          
        </td></tr>
        
      
      </tbody></table></div><!--
    --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
    </td></tr>
    </tbody></table>
        <!-- footerBlock -->
  <table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#3f3f3f" id="" style="background-color: #3f3f3f">
    <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">
  <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--
    --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; width: 100%; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px" width="570" class="vb-row">
      
    <tbody><tr>
    <td align="center" valign="top" style="font-size: 0; padding: 0 9px"><div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px"><!--
      --><table role="presentation" class="vb-content" border="0" cellspacing="9" cellpadding="0" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px" width="552">
        
      <tbody><tr><td class="long-text links-color" width="100%" valign="top" align="center" style="font-weight: normal; color: #919191; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: center"><p style="margin: 1em 0px; margin-bottom: 0px; margin-top: 0px;">Email sent to <a href="mailto:[mail]">[mail]</a></p></td></tr>
      <tr><td width="100%" valign="top" align="center" style="font-weight: normal; color: #ffffff; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: center"><a href="https://myaccount.goingmobile.app/auth/login" style="color: #ffffff; text-decoration: underline" target="_new">Unsubscribe</a></td></tr>
    
      </tbody></table></div></td>
  </tr>
  
    </tbody></table></div><!--
  --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
</td></tr>
  </tbody></table>

    `;

    // mail.body += '<br/> Warm Regards,';
    // mail.body += '<br/>Going Mobile Team';

    // <span><strong>App Name: </strong> ${model.appName}</span><br/>


    // mail.body += `<span><strong>Full name: </strong> ${model.fullName}</span><br/>`;
    // mail.body += `<span><strong>Email: </strong> ${model.email}</span><br/>`;
    // mail.body += `<span><strong>App Name: </strong> ${model.appName}</span><br/>`;
    // mail.body += `<span><strong>URL: </strong> ${model.url}.goingmobile.app</span><br/>`;
    // mail.body += `<span><strong>Mobile number: </strong> ${model.mobileNumber}</span><br/>`;
    // mail.body += '<br/> Warm Regards,';
    // mail.body += '<br/>Going Mobile Team';
    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {
    });

  }

  sendAccountUpdate(model: any) {
    const mail = new EmailModel();

    mail.emailList = [model.inviteEmail];
    mail.subject = 'Account Update ';
    mail.isNationApp = this.isNationApp;

    mail.body = '<span style="margin-right: 15px;">Hello </span> <br/> ';
    mail.body += '<span style="margin-right: 15px;">Your account has been upgraded to an employee. </span> <br/> ';
    mail.body += '<span style="font-weight: bold; margin-right: 15px;">Please Use your previous credentials to log into the app. </span> <br/> ';
    mail.body += `<a style="color:blue; margin-right: 15px;" href="${model.hostname}">${model.hostname} </a> <br/> `;
    mail.body += '<br/> Thank You.';

    const headers = new HttpHeaders({ 'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), { headers }).subscribe(o => {

    });
  }
}
