<div class="mx-4 md:px-10">
  <!-- <div class="flex w-full justify-end ">
    <button (click)="openContactsForm()"
    *ngIf="!selectedSubscribers?.length || (selectedSubscribers?.length && selectedPreference != 'imported')"
    class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] border border-customTeal"
    ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-white">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg><span>Import Contact two</span></button>
  </div> -->

  <div class="flex flex-col items-center w-full gap-y-3 md:flex-row md:justify-between">


    <!-- Search Button -->
    <div class="flex flex-col w-full space-y-2 md:w-4/6" *ngIf="createdFrom !== 'event'">
      <label for="" class="flex flex-col gap-1 text-xs font-bold text-gray-500 ">
        Search
        <!-- <span class="flex text-[8px]">Please input the user's first name and press the enter key.</span> -->
      </label>


      <label class="relative items-center w-full">
        <span class="sr-only">Search</span>

        <span class="absolute inset-y-0 left-0 flex items-center md:pl-4">
          <i
            class="absolute w-8 h-8 py-2 text-gray-800 transform -translate-y-1/2 pointer-events-none material-icons top-1/2 left-3 hover:text-customTeal">search</i>
        </span>
        <input
          class="block w-full py-3 pl-10 pr-3 bg-white border rounded-md shadow-sm placeholder:italic placeholder:text-slate-400 border-slate-300 md:pl-10 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
          placeholder="Search using first name" type="text" name="search" [(ngModel)]="search_text"
          (keyup.enter)="filterUsersList()" />
      </label>
    </div>

    <div class="flex flex-col w-full space-y-2 md:w-72 " *ngIf="createdFrom !== 'event'">
      <label for="" class="text-xs font-bold text-gray-500 ">Select an Option </label>
      <select class="w-full select select-bordered bg-veryLightGray " (change)="selectPreference($event.target.value)">

        <option *ngFor="let option of preferenceOptions" [selected]="option == selectedPreference">{{option}}</option>

      </select>
    </div>

  </div>


  <!-- To click the email, sms , both and verified subscribers -->
  <div class="flex flex-col justify-between py-2 md:flex-row" *ngIf="createdFrom !== 'event'">


    <!---------------Contact Clients using email or sms---------------------->

    <div class="flex justify-between gap-2">
      <button (click)="openContactUser()"
        class="px-2 py-2 text-white border rounded-md bg-customTeal modal-button h-fit flex gap-3 flex-wrap justify-center"
        *ngIf="selectedSubscribers.length > 0 && ( isAdmin || permMember && permMember.contactUsers)"><i
          class="float-left w-2 h-2 pr-8 material-icons">group</i>
        Contact Subscribers</button>

      <button (click)="openContactsForm()"
        *ngIf="(!selectedSubscribers?.length && !selectedImportedSubscribers?.length )|| (selectedSubscribers?.length && (selectedPreference)?.toLowerCase() != 'imported')"
        class="px-2 py-2 text-white border rounded-md modal-button bg-customTeal h-fit flex gap-3 flex-wrap justify-center"><i
          class="float-left w-2 h-2 pr-8 material-icons">add_circle</i>Import Contact</button>

      <button (click)="openClientRegistrationForm()"
        *ngIf="!(selectedImportedSubscribers?.length) && !(selectedSubscribers?.length)"
        class="px-2 py-2 text-white border rounded-md modal-button bg-customTeal h-fit flex gap-3 flex-wrap justify-center"><i
          class="float-left w-2 h-2 pr-8 material-icons">add_circle</i>Register Client</button>


      <!-- <button (click)="openClientRegistrationForm()"
      class="px-2 py-2 text-white border rounded-md modal-button bg-customTeal h-fit flex gap-3 flex-wrap justify-center"><i
        class="float-left w-2 h-2 pr-8 material-icons">add_circle</i>Add Client</button> -->

      <button (click)="deleteContactOpenModal=true"
        *ngIf="selectedImportedSubscribers?.length && (selectedPreference)?.toLowerCase() === 'imported'"
        class="px-2 py-2 text-white border rounded-md modal-button bg-customTeal h-fit"><i
          class="float-left w-2 h-2 pr-8 material-icons">delete</i>Delete Imported Contacts</button>

      <button (click)="showDropdownButton = !showDropdownButton"
        *ngIf="selectedSubscribers?.length && (selectedPreference)?.toLowerCase()  === 'imported'"
        class="relative px-2 py-2 text-white border rounded-md modal-button bg-customTeal h-fit"><i
          class="fa fa-ellipsis-h"></i>
        <!-- Dropdown menu -->
        <div *ngIf="showDropdownButton"
          class="absolute left-0 z-10 flex mt-4 bg-white divide-y divide-gray-100 rounded-lg shadow bg-red w-44">
          <ul class="w-full py-2 text-sm text-gray-700 dark:text-gray-200">
            <div class="w-full flex flex-col "
              *ngIf="selectedSubscribers?.length && (selectedPreference)?.toLowerCase()  === 'imported'">
              <li (click)="sendInvitation('email')">
                <p class="w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Invite via
                  email</p>
              </li>
              <li (click)="sendInvitation('sms')">
                <p class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Invite
                  via
                  sms</p>
              </li>
            </div>

            <!-- <li (click)="openClientRegistrationForm()">
              <p class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                Register Client</p>
            </li> -->

          </ul>
        </div>
      </button>
    </div>

    <div id="my-modal-users" *ngIf="contactUserModal" class="modal"
      [ngClass]="{'modal-open': contactUserModal, 'modal-action': !contactUserModal }">
      <form [formGroup]="contactForm"
        (ngSubmit)="sendSubscribers(contactForm.value.subject, contactForm.value.emailbody, contactForm.value.messageSMS)"
        class="flex flex-col justify-between gap-1 w-[80%] md:w-[80%] lg:w-[800px] rounded-md overflow-x-hidden h-[90%] p-3 bg-white">
        <div class="flex flex-col space-y-2">
          <div class="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6 stroke-gray-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
            </svg>

            <h3 class="text-xl font-bold text-gray-600">Contact {{isNationApp ? 'Members' : 'Subscribers'}}</h3>
          </div>
          <hr>
        </div>

        <!-- form section -->
        <div class="flex-1 justify-center  overflow-y-auto max-h-[60%] app-scrollbar">
          <div class="flex flex-col space-y-2 py-4">
            <p class=" font-bold text-base text-gray-600">Contact with:</p>
            <div class="flex  items-center  space-x-8 ">

              <div>
                <input type="radio" id="pub" name="fav_language" value="Public" class="mx-2"
                  [checked]="notificationType == 'email'" (click)="SMSselected('email')" [disabled]="isLimitEmail">
                <label for="pub">Send Email</label><br>
              </div>
              <div>
                <input type="radio" id="User" name="fav_language" value="Users" class="mx-2"
                  [checked]="notificationType == 'sms'" (click)="SMSselected('sms')" [disabled]="disable || isLimitSMS">
                <label for="User">Send SMS</label><br>
              </div>
              <div *ngIf="selectedPreference !== 'imported'">
                <input type="radio" id="User" name="fav_language" value="Users" class="mx-2"
                  [checked]="notificationType == 'push'" (click)="SMSselected('push')">
                <label for="User">Send Push Notification</label><br>
              </div>
            </div>

          </div>
          <!-- checlbox -->
          <div class="flex items-center px-2 py-4 space-x-2 " *ngIf="selectedPreference === 'imported'">
            <input id="default-checkbox" type="checkbox" value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              [checked]="sendingInvitation" (click)="toggleInvitation()">
            <label for="default-checkbox" class="text-sm font-semibold text-gray-900 dark:text-gray-300">Are you
              going to send an invitation</label>
          </div>
          <span *ngIf="disable" class="text-error">This User doesn't have a phone number.</span>

          <div class="flex flex-col">
            <p class="flex text-base text-gray-600 font-bold" *ngIf="notificationType == 'email'">Selected Emails
            </p>
            <p class="flex text-base text-gray-600 font-bold" *ngIf="notificationType == 'sms'">Selected Phone Numbers
            </p>
            <p class="flex text-base text-gray-600 font-bold" *ngIf=" notificationType == 'push'">Selected Users
            </p>
          </div>

          <div class="flex flex-wrap px-4 bg-gray-100 rounded-md max-h-[100px] overflow-y-auto app-scrollable"
            *ngIf="selectedSubscribers.length != 0 && !isLimitEmail">
            <div *ngFor="let selected of selectedSubscribers" class="flex py-2 ">
              <div *ngIf="selected.Email != '' && notificationType == 'email'" class="flex items-center space-x-2">
                <div class="p-1 rounded-full ml-2"
                  [ngClass]="{'bg-green-500': (selected.contact.includes('SMS') && selected.contact.includes('Email')), 'bg-red-500': (!selected.contact.includes('SMS') && !selected.contact.includes('Email')),  'bg-yellow-300' : (selected.contact.includes('SMS') && !(selected.contact.includes('Email'))), 'bg-yellow-300' : (!selected.contact.includes('SMS') && selected.contact.includes('Email'))}">
                </div>
                <p class="text-black">{{selected.Email}},</p>
              </div>
            </div>

            <div *ngFor="let selected of selectedSubscribers" class="flex py-2">
              <div *ngIf="(selected.cellPhone != '' && notificationType == 'sms') && !isLimitSMS"
                class="flex items-center space-x-2">
                <div class="p-1 rounded-full ml-2"
                  [ngClass]="{'bg-green-500': (selected.contact.includes('SMS') && selected.contact.includes('Email')), 'bg-red-500': (!selected.contact.includes('SMS') && !selected.contact.includes('Email')),  'bg-yellow-300' : (selected.contact.includes('SMS') && !(selected.contact.includes('Email'))), 'bg-yellow-300' : (!selected.contact.includes('SMS') && selected.contact.includes('Email'))}">
                </div>
                <p class="text-black">{{selected.cellPhone}} ,</p>
              </div>
            </div>

            <div *ngFor="let selected of selectedSubscribers" class="flex py-2">
              <div *ngIf="(notificationType == 'push')" class="flex items-center space-x-2">
                <div class="p-1 rounded-full ml-2"
                  [ngClass]="{'bg-green-500': (selected.token && selected.contact.includes('InApp')), 'bg-red-500': ((!selected.token && !selected.contact.includes('InApp')) || (!selected.token && selected.contact.includes('InApp'))),  'bg-yellow-300': (selected.token && !selected.contact.includes('InApp'))}">
                </div>
                <p class="text-black">{{selected.GivenName}} , </p>
              </div>
            </div>
          </div>

          <div *ngIf="(notificationType == 'sms' && !disable) && !isLimitSMS">
            <div class="flex space-x-2 items-center ">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4 stroke-red-500">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
              </svg>
              <span class="text-base text-red-500">
                Some Users don't have their mobile number installed therefore wont recieve an SMS
              </span>
            </div>

            <div class="w-full mt-2">
              <label for=" " class="mb-2 text-base text-gray-600 font-bold "> Message: </label>
              <textarea id="message" formControlName='messageSMS'
                class="w-full h-32  py-4 mt-2 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline "
                placeholder="Message"></textarea>
            </div>

            <!-- <div class="flex justify-between m-2">
                <button type="button" (click)="closeContactUser()"
                  class="flex items-center space-x-2 border border-solid border-customTeal rounded text-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                  Cancel</button>
                <div class="flex gap-3">
                  <button type="button"
                    class="p-2 text-white border-none rounded-md bg-customTeal hover:bg-gray-100 hover:text-customTeal"
                    *ngIf="sendingInvitation" (click)="invitationSmsTemplate()">Show Preview</button>

                  <button
                    class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    type="submit" *ngIf="!sendingInvitation">Send Message</button>
                  <button
                    class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    type="submit" *ngIf="sendingInvitation">Send Invitation</button>
                </div>
              </div> -->

            <div class="flex items-center justify-center w-full p-6 bg-gray-50 "
              *ngIf="notificationType == 'sms' && sendingInvitation">
              <div id="">
                {{smsBody}}
              </div>
            </div>
          </div>

          <div *ngIf="(notificationType == 'email' || disable) && !isLimitEmail">
            <div class="w-full px-2 mt-2 overflow-hidden">
              <div>
                <label for=" " class="mb-2 text-base text-gray-600 font-bold "> Subject: </label>
                <input type="email" name="subject" formControlName='subject'
                  class="block w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm form-control border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 sm:text-sm focus:ring-1"
                  placeholder="Subject" />
                <br />
              </div>
              <label for=" " class="mb-2 text-base text-gray-600 font-bold "> Message: </label>
              <div class="flex w-[99%]">
                <app-editor [control]="emailbody" [model]="emailMessage" class="bg-white" required
                  [fromComponent]="'message'" (onModelChange)="onChangeText($event)"></app-editor>
              </div>

            </div>

            <!-- <div class="flex justify-between mt-6">
                <button type="button" (click)="closeContactUser()"
                  class="flex items-center space-x-2 border border-solid border-customTeal rounded text-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                  Cancel</button>

                <div class="flex gap-3">
                  <button type="button"
                    class="p-2 text-white border-none rounded-md bg-customTeal hover:bg-gray-100 hover:text-customTeal"
                    *ngIf="sendingInvitation" (click)="invitationEmailTemplate()">Show Preview</button>

                  <button
                    class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    type="submit" *ngIf="!sendingInvitation" type="submit">Send Message</button>
                  <button
                    class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    type="submit" *ngIf="sendingInvitation" type="submit">Send Invitation</button>
                </div>

              </div> -->

            <div class="flex items-center justify-center w-full p-6 bg-gray-50 "
              *ngIf="notificationType == 'email' && sendingInvitation">
              <div id="" [innerHtml]="emailHtmlBody | noSanitize">
              </div>
            </div>
          </div>

          <!-- Push notfication body section -->
          <div *ngIf="(notificationType == 'push')">
            <div class="w-full px-2 mt-2 ">

              <div class="flex space-x-2 items-center ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-4 h-4 stroke-red-500">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>
                <span class="text-base text-red-500">
                  Some users may not be logged in to the system, which could prevent them from receiving notifications
                </span>
              </div>
              <div class="w-full px-2 mt-2 overflow-hidden">
                <div>
                  <label for=" " class="mb-2 text-sm font-bold "> Title: </label>
                  <input type="email" name="subject" formControlName='subject'
                    class="block w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm form-control border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 sm:text-sm focus:ring-1"
                    placeholder="Subject" />
                  <br />
                </div>
                <label for=" " class="mb-2 text-sm font-bold "> Message: </label>
                <div class="flex w-[99%] ">
                  <app-editor [control]="emailbody" class="bg-white" required [fromComponent]="'message'"></app-editor>
                </div>
              </div>
            </div>
            <!-- 
              <div class="flex justify-between mt-6">
                <button type="button" (click)="closeContactUser()"
                  class="flex items-center space-x-2 border border-solid border-customTeal rounded text-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                  Cancel</button>
                <button
                  class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  type="submit" *ngIf="!sendingInvitation" type="submit">Send Message</button>
                <button
                  class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  type="submit" *ngIf="sendingInvitation" type="submit">Send Invitation</button>
              </div> -->
          </div>
        </div>

        <div class="flex flex-col gap-1">
          <div class="flex justify-between m-2">
            <button type="button" (click)="closeContactUser()"
              class="flex items-center space-x-2 border border-solid border-customTeal rounded text-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
              Cancel</button>

            <div class="flex gap-3">
              <button type="button"
                class="p-2 text-white border-none rounded-md bg-customTeal hover:bg-gray-100 hover:text-customTeal"
                *ngIf="sendingInvitation && (notificationType !== 'push')"
                (click)="sendInvitationForUsers(notificationType)">Show Preview</button>

              <button
                class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                type="submit" *ngIf="!sendingInvitation">Send Message</button>
              <button
                class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                type="submit" *ngIf="sendingInvitation">Send Invitation</button>
            </div>
          </div>
          <div class="py-4 mx-2 font-bold text-blue-600 underline underline-offset-2 text sm"
            [routerLink]="['/admin/app-setup']">Create Signature</div>
        </div>

      </form>
    </div>
  </div>


  <!-- To show the colors   -->
  <div class="flex flex-col items-center md:flex-row gap-y-2 md:justify-between md:py-2"
    *ngIf="createdFrom !== 'event'">

    <div class="flex flex-wrap mr-4">
      <div class="flex items-center space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-4 h-4 text-gray-400">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
        </svg>
        <span class="text-base text-gray-600" *ngIf="!isLimitSMS">Total Sent SMS Count: <span
            class="px-2 mx-2 text-base font-bold text-customTeal">
            {{adminSMSCounter}}</span></span>
        <span class="mx-4 font-bold text-red-500" *ngIf="isLimitSMS && !isLimitEmail">You reached your SMS limit for
          this
          month </span>
      </div>

      <div class="flex items-center space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-4 h-4 text-gray-400">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
        </svg>

        <span class="text-base text-gray-600" *ngIf="!isLimitEmail"> Total Sent Email Count: <span
            class="px-2 mx-2 text-base font-bold text-customTeal">
            {{adminEmailCounter}}</span> </span>
      </div>


      <span class="mx-4 text-base font-bold text-red-500" *ngIf="isLimitEmail && !isLimitSMS"> You reached your Email
        limit for
        this month </span>
      <span class="mx-4 text-base font-bold text-red-500" *ngIf="isLimitEmail && isLimitSMS"> You reached your limit for
        both SMS
        and Email for this month </span>

      <div class="flex items-center space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-4 h-4 text-gray-400">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
        </svg>

        <span class="text-base text-gray-600"> {{isNationApp ? 'Members' : 'Subscribers'}} <span
            class="px-2 mx-2 text-base font-bold text-customTeal">
            {{this.subscribers?.length}}</span></span>
      </div>
    </div>

    <hr>

    <div class="flex flex-wrap items-center p-4  mb-2 md:flex-row md:space-x-4 md:mx-12 lg:mx-4 bg-white rounded-sm "
      *ngIf="subscribers && subscribers.length != 0">
      <div class="flex items-center">
        <div class="w-3 h-3 bg-green-500 rounded-full"></div> <span class="px-2 text-base">SMS and Email allowed.</span>
      </div>
      <div class="flex items-center">
        <div class="w-3 h-3 bg-yellow-200 rounded-full"></div> <span class="px-2 text-base">Email or SMS</span>
      </div>
      <div class="flex items-center">
        <div class="w-3 h-3 bg-red-500 rounded-full"></div> <span class="px-2 text-base">Do not Contact </span>
      </div>
    </div>

  </div>

  <!-- contact button -->
  <!-- <div class="py-4">
    <button (click)="openContactUser()"
  class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] border border-customTeal"
    *ngIf="selectedSubscribers.length > 0 && ( isAdmin || permMember && permMember.contactUsers)">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 stroke-white">
      <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
    </svg>
    <span>Contact Subscribers one</span></button>
  </div> -->

  <!-- The column name for the list of the subscribers -->
  <div class="flex flex-col bg-gray-200" *ngIf="subscribers && subscribers.length != 0">
    <div class="flex flex-row items-center p-2" *ngIf="subscribers.length > 0">
      <div class="w-7/12 space-x-4 lg:w-4/12">
        <div class="form-check">
          <input
            class="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
            type="checkbox" value="" id="flexCheckDefault" (click)="selectedAllSubscribers()"
            [checked]=selectAllchecked>
        </div>
        <p class="pl-8 text-sm">Name</p>
      </div>
      <div class="hidden w-3/12 pl-3 lg:block ">
        <p class="text-sm ">Email</p>
      </div>
      <div class="hidden w-3/12 lg:block">
        <p class="text-sm ">Phone Number</p>
      </div>
      <div class="hidden w-3/12 lg:block">
        <p class="text-sm ">Last Login</p>
      </div>
      <div class="w-2/12 lg:w-1/12">

      </div>

      <div class="w-1/12">
      </div>
    </div>
  </div>

  <!-- The list of the subscribers -->
  <div class="flex items-center justify-center w-full space-x-4 " *ngFor="let item of subscribers  ; let i=index">
    <span>
      <input
        class="float-left w-4 h-4 mt-2 ml-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
        (click)="selectedUser(item)" [checked]="checkUser" type="checkbox" value="" id="flexCheckDefault">
    </span>

    <!-- The list of the subscribers -->
    <div class="w-full">
      <div (click)="viewSubscriberProfile(item)"
        class="flex flex-row items-center w-full p-2 bg-white border-2 cursor-pointer md:space-x-6">
        <div class="flex w-7/12 lg:w-4/12">
          <div class="flex items-center space-x-3">
            <div class="form-check">

            </div>
            <div class="avatar placeholder">
              <div class="w-10 h-10 border-2 rounded-full mask mask-squircle" *ngIf="item.profileImgLink">
                <img loading="lazy" [src]="item.profileImgLink">
              </div>
              <div
                class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
                *ngIf="!item.profileImgLink">
                <span class="font-medium text-gray-600 dark:text-gray-300">{{item.GivenName[0] | uppercase}}</span>
              </div>
              <!-- <div class="w-10 h-10 rounded-full bg-neutral-focus text-neutral-content " >
            <span class="text-2xl"></span>
          </div> -->
            </div>
            <div>
              <div class="text-base font-bold"> {{item.GivenName | titlecase}} {{item.LastName | titlecase}}</div>
            </div>
          </div>
        </div>
        <div class="hidden w-3/12 lg:block">
          <p class="text-base text-gray-500" *ngIf="item.email != ''">{{item.Email}}</p>
          <p class="text-base text-gray-500" *ngIf="item.email == ''">{{item.cellPhone}}</p>

        </div>
        <div class="hidden w-3/12 lg:block">
          <p class="text-base text-gray-500">{{item.cellPhone}}</p>

        </div>
        <div class="hidden w-3/12 lg:block">
          <p class="text-base text-gray-500" *ngIf="item.DateAdded">{{item.DateAdded.toDate() | date:'medium'}}</p>
        </div>
        <div class="w-2/12 lg:w-1/12">

          <label for="my-modal-enableE" *ngIf="item.contact?.includes('SMS') && !(item.contact?.includes('Email'))"
            class="pl-2 text-yellow-300 modal-button"> <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
          </label>

          <label for="my-modal-enableE" *ngIf="!(item.contact?.includes('SMS')) && item.contact?.includes('Email')"
            class="pl-2 text-yellow-300 modal-button"> <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
          </label>

          <label for="my-modal-enableE" *ngIf="item.contact?.includes('SMS') && item.contact?.includes('Email')"
            class="pl-2 text-green-500 modal-button"> <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
          </label>

          <label for="my-modal-enableE" *ngIf="!(item.contact?.includes('SMS')) && !(item.contact?.includes('Email'))"
            class="pl-2 text-red-500 modal-button"> <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
          </label>

        </div>
        <div class="w-1/12 lg:1/12">
          <button (click)="viewSubscriberProfile(item)" type="button">
            <i class="float-left text-gray-400 material-icons material-symbols-outlined">arrow_circle_right </i>
          </button>
        </div>
      </div>
      <hr>
    </div>

    <!-- If there is no subscribers -->
    <div *ngIf="subscribers">
      <div *ngIf="subscribers.length == 0" class="">
        <div class="flex items-center justify-center">
          <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
            class="flex items-center justify-center block w-64">
        </div>
        <div class="text-lg font-bold text-center text-customTeal">There are no {{search_text}} {{isNationApp ?
          'Members'
          : 'Subscribers'}} registered.
        </div>
      </div>
    </div>
  </div>

  <!-- prev and next button -->
  <div class="inline-flex justify-end w-full p-3 mt-2 rounded xs:mt-0"
    [ngClass]="{'hidden': !allUsers?.length || (allUsers?.length < 10 && currentPage <= 1)}">

    <!-- loader -->
    <button class="p-3 bg-gray-500 rounded" [ngClass]="{'hidden':(!isLoading), 'flex':(isLoading)}">
      <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin fill-customTeal" viewBox="0 0 100 101"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill" />
      </svg>
    </button>

    <!-- prev and next button -->
    <div class="flex" [ngClass]="{'hidden':(isLoading), 'flex':(!isLoading)}">
      <button
        class="flex items-center justify-center h-10 gap-2 px-4 text-base font-medium rounded-s hover:bg-gray-900 "
        [ngClass]="{' border border-customTeal text-customTeal':(currentPage <= 1), ' bg-customTeal text-white':(currentPage > 1)}"
        (click)="fetchUsersData('prev')" [disabled]="currentPage <= 1">

        <svg class="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 14 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M13 5H1m0 0 4 4M1 5l4-4" />
        </svg>
        Prev
      </button>
      <button
        class="flex items-center justify-center h-10 gap-2 px-4 text-base font-medium text-white border-0 border-gray-700 border-s rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        [ngClass]="{'  border border-customTeal text-customTeal':(!nextPageAvailable), ' bg-customTeal text-white':(nextPageAvailable)}"
        (click)="fetchUsersData('next')" [disabled]="!nextPageAvailable">
        Next
        <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 14 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M1 5h12m0 0L9 1m4 4L9 9" />
        </svg>
      </button>
    </div>

  </div>

  <div class=" w-full flex justify-between" *ngIf="createdFrom === 'event'">
    <label (click)="cancelClicked()"
      class="font-bold p-2 text-customTeal hover:border-customTeal hover:rounded-md hover:font-normal hover:border-2 hover:p-2">
      Cancel
    </label>
    <label>
      <button (click)="invite()" class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md"
        type="button">Invite</button>
    </label>
  </div>


  <!--- No User and No Employee -->
  <div *ngIf="!subscribers || subscribers.length == 0 ">
    <div class="flex items-center justify-center">
      <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
        class="flex items-center justify-center block w-64">
    </div>
    <div class="text-lg font-bold text-center text-customTeal">There are no {{isNationApp ? 'Members' : 'Subscribers'}}
      registered.</div>
  </div>
  <br /><br />

</div>
<!-- toast -->
<app-alert class="fixed top-0 flex justify-center w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
<br /><br />
<br /> <br />
<br /> <br />

<app-modal modalTitle="Upload contacts list" btnName="Yes" *ngIf="showFileUploadModal"
  (cancelBtnClicked)="closeContactsForm()" (btnClicked)="uploadFile()">
  <hr />
  <div class="flex flex-col items-center justify-center w-full">
    <!-- upload file -->
    <div class="w-full">
      <label
        class="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
        <span class="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
          </svg>
          <span class="font-medium text-gray-600">
            Drop files to Attach, or
            <span class="text-blue-600 underline">browse</span>
          </span>
        </span>
        <input type="file" name="file_upload" class="hidden"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          (change)="csvImport($event)">
      </label>
    </div>

    <!-- Pleader -->
    <!-- <p>The format of the excel should have Name, Phone and Address. If this information are not included ,
          the contacts will not be imported</p> -->
  </div>

</app-modal>

<!-- Delete Modals -->
<!-- <app-modal modalTitle="Delete Contacts" btnName="Yes" *ngIf="deleteContactOpenModal"
    (cancelBtnClicked)="deleteContactOpenModal=false" (btnClicked)="deleteContacts()">
    <p class="mb-1 text-center text-md">Are you sure you want to delete?</p>
    <p class="mb-1 text-center text-md">Comments archived can be found in the archived sections</p>
</app-modal> -->

<div class="modal modal-open" *ngIf="deleteContactOpenModal">
  <div class="modal-box">
    <h3 class="mb-2 text-lg font-bold text-center">Delete Contacts</h3>

    <!-- Modal Body -->
    <div class="modal-body">
      <p class="mb-1 text-center text-md">Are you sure you want to delete?</p>
      <p class="mb-1 text-center text-md">Once the record is deleted, it is not possible to recover the data.</p>
    </div>

    <div class="items-center justify-between modal-action">
      <label (click)="deleteContactOpenModal=false"
        class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal">
        Cancel
      </label>
      <label>
        <button
          class="p-2 font-bold text-customTeal hover:border-customTeal hover:rounded-md hover:font-normal hover:border-2 hover:p-2"
          type="button" (click)="deleteContacts()">Delete</button>
      </label>
    </div>
  </div>
</div>