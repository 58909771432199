import { Injectable } from "@angular/core";
import { Platform } from "@angular/cdk/platform";
import { Subject } from "rxjs";
import { AuthService } from "./auth.service";
import { FCMTokenModel } from "src/app/models/FCMTokenModel";
import { FCMTokenService } from "./FCMToken.service";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiKeyManagerService } from "../api/apiKeyManager.Service";
import { Location } from "@angular/common";
import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";

// for subscribing to FCM
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import firebase from "firebase/compat/app";
import { environment } from "src/environments/environment";
import { FCMService } from "../api/fcm.service";
import { ToastrService } from "ngx-toastr";

// declare var $: any;
// declare var FirebasePlugin: any;

@Injectable({
  providedIn: "root",
})
export class FCMpushService {
  private messaging;
  isCordova = false;
  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();

  user;
  // user = this.authService.getCurrentUser()
  private mynationChannel = {
    id: "Personal_app_default_channel",
    name: "Personal_app_default_channel",
    sound: "blackberry",
    vibration: [300, 200, 300],
    lightColor: "0xFF0000FF",
    importance: 4,
    badge: true,
    visibility: 1,
  };
  routes = {
    Message: {
      Admin: "/admin/messages",
      Employee: "/employee/chat-list",
      User: "/user/chat-list",
    },
  };

  constructor(
    private platform: Platform,
    private authService: AuthService,
    private FcmTokenService: FCMTokenService,
    private router: Router,
    private keyManager: ApiKeyManagerService,
    private httpClient: HttpClient,
    private location: Location,
    private fcmMessaging: AngularFireMessaging,
    private apiService: FCMService,
    private toastr: ToastrService
  ) {
    this.user =
      this.authService.getGlobalUser() || this.authService.getCurrentUser();
    // if (typeof window['cordova'] !== 'undefined') {
    if (
      typeof window["Capacitor"] !== "undefined" &&
      window["Capacitor"]["platform"] !== "web"
    ) {
      this.isCordova = true;
    } else {
      // this.messaging = firebase.messaging();
    }

    if (this.isCordova && platform.ANDROID) {
      // FirebasePlugin.createChannel(this.mynationChannel ,
      //   () => {
      //   },
      //   (error) => {
      //      alert('Create channel error: ' + error);
      //   });
    }
  }


  registerPushNotification() {

    if (this.isCordova) {
      PushNotifications.requestPermissions()
        .then((permission) => {
          if (permission.receive == "granted") {
            PushNotifications.register();
          }
        })
        .catch((err) => {
        });

      PushNotifications.addListener("registration", (token) => {
        this.sendTokenToServer(token.value, Capacitor.getPlatform());
      });

      PushNotifications.addListener("registrationError", (err) => {
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          // this.handleNotificationMessage(message);
        }
      );
    }
  }

  sendTokenToServer(token: string, platform: string) {

    this.user = this.authService.getGlobalUser();
    this.FcmTokenService.checkIfTokenExist(token, this.user.uid)
      .subscribe((checkedToken) => {
        if (checkedToken && checkedToken.length > 0) {
        } else {
          const model: FCMTokenModel = {
            uid: "",
            userId: this.user.uid,
            // userId: this.authService.getCurrentUser().uid,
            token: token,
            platform: platform,
            date: new Date(),
          };
          this.FcmTokenService.saveUserToken(model);
        }
        if (this.isCordova) {
          if (this.user.role === "User") {
            this.subscribeToPushNotification(token, "User_Notification");
          } else if (this.user.role === "Admin")
            this.subscribeToPushNotification(token, "Owner_Notification");
        }
      });
  }

  subscribeUserToTopic(uid: string, topic: string) {
    this.FcmTokenService.getTokenByUserId(uid)
      .valueChanges()
      .subscribe((token) => {
        if (token && token.length > 0) {
          token.forEach((t) =>
            t.platform === "Browser"
              ? this.subscribeToPushNotificationAngular(t, topic)
              : this.subscribeToPushNotification(t, topic)
          );
        }
      });
  }

  subscribeToPushNotification(token, topic) {
    // FirebasePlugin.subscribe(topic,function(){
    // },function(error){
    //   alert("Error subscribing to topic: " + error)
    // })
  }

  subscribeToPushNotificationAngular(token, topic) {
    let url =
      "https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic;
    const headers = new HttpHeaders({
      Authorization: this.keyManager.getFCMKey(),
      "Content-Type": "application/json;",
    });

    this.httpClient.post<any>(url, null, { headers }).subscribe((o) => {
    });
  }

  onMessageReceived() {
    // if (this.isCordova) {
    //   try {
    //     return FirebasePlugin.onMessageReceived(message => {
    //       FirebasePlugin.hasPermission(hasPermission => {
    //         if (hasPermission) {
    //           if (this.platform.IOS) {this.setBadgeNumber(); }
    //           this.handleNotificationMessage(message);
    //                 }
    //       });
    //     });
    //   } catch (error) {
    //     alert('Failed receiving message ' + error.message);
    //   }
    // }
    // else{
    //   this.messaging.onMessage(payload=>{
    //   })
    // }
  }

  setBadgeNumber() {
    // if (this.platform.IOS) {
    //   FirebasePlugin.getBadgeNumber(current => {
    //     const badgeNum = current + 1;
    //     FirebasePlugin.setBadgeNumber(badgeNum);
    //   }, error => {
    //   });
    // }
  }

  private handleNotification(notification: any) {
    if (notification.data) {
      let notificationType = notification.data.notificationType;
      if (notificationType == "Events") {
      } else if (notificationType == "Service") {
      } else if (notificationType == "Products") {
      } else if (notificationType == "Notifications") {
        // this.navigate('/admin/notifications')
      } else if (notificationType == "Message") {
        let url = `${this.routes[notificationType][this.user.role]}/${notification.data.chatType
          }`;
        this.navigate(url);
      }
    }
  }

  private handleNotificationMessage(message: any) {
    let title;
    if (message.title) {
      title = message.title;
    } else if (message.notification && message.notification.title) {
      title = message.notification.title;
    } else if (message.aps && message.aps.alert && message.aps.alert.title) {
      title = message.aps.alert.title;
    }

    let body;
    if (message.body) {
      body = message.body;
    } else if (message.notification && message.notification.body) {
      body = message.notification.body;
    } else if (message.aps && message.aps.alert && message.aps.alert.body) {
      body = message.aps.alert.body;
    }
    title = title.toString();
    body = body.toString();

    let msg = title + " \n" + body;

    let notificationType = message.notificationType;

    if (typeof message.tap !== "undefined") {
      setTimeout(() => {
        if (notificationType == "Events") {
        } else if (notificationType == "Service") {
        } else if (notificationType == "Products") {
        } else if (notificationType == "Notifications") {
          // this.navigate('/admin/notifications')
        } else if (notificationType == "Message") {
          let url = `${this.routes[notificationType][this.user.role]}/${message.chatType
            }`;
          this.navigate(url);
        }
      }, 200);
    }
  }

  private navigate(url: string) {
    this.location.go(url);
    this.router.navigate([url]);
    // this.location.back()/
  }


  /**   new implementation of FCM */

  // to listen to fcm message observables
  listen() {
    const messaging = firebase.messaging();

    // fcmMessaging.
    messaging.onMessage((payload) => {
      // this.message=payload;
    });

    messaging.onBackgroundMessage((payloda) => {
    })
  }

  // subscribe to a specfic topic using registration token
  subscribeToTopic(topic: any, token: any) {
    try {
      this.apiService.subscribeToTopic(topic, token).subscribe((data) => {
        // this.successMessage("Topic subscription is successful");
      });
    } catch (err) {
      this.errorMessage("Subscription failed");
    }
  }

  unSubscribeToTopic(topic: any, token: any) {
    try {
      this.apiService.unsubscribeToTopic(topic, token).subscribe((data) => {
        // this.successMessage("Topic subscription is successful");
      });
    } catch (err) {
      this.errorMessage("Subscription failed");
    }
  }

  // request permission and get FCM registration token

  requestPermission() {
    const messaging = firebase.messaging();

    // grab the user data
    this.user = this.authService.getGlobalUser();

    messaging
      .getToken({ vapidKey: environment.firebase.vapidFcmKey })
      .then((currentToken) => {
        if (currentToken) {

          // set current token
          this.authService.setUserCurrentToken(currentToken);

          // check if the token already exists
          this.updateRegistrationToken(currentToken);


        } else {
          
        }
      })
      .catch((err) => {
      });
  }

  // update registration token
  updateRegistrationToken(currentToken) {
    // check if the token already exists
    this.FcmTokenService.checkIfTokenExist(currentToken, this.user.uid)
      .subscribe(async (checkedToken) => {
        if (checkedToken && checkedToken.length > 0) {
        } else {
          const model: FCMTokenModel = {
            uid: "",
            userId: this.user.uid,
            // userId: this.authService.getCurrentUser().uid,
            token: currentToken,
            platform: Capacitor.getPlatform(),
            date: new Date(),
          };
          await this.FcmTokenService.saveUserToken(model);


          // subscribe user to a topic
          // if (this.user.allowPushNotification) {
          //   if (this.user.role === "User") {
          //     this.subscribeToTopic("User_Notification", currentToken);
          //   } else if (this.user.role === "Admin")
          //     this.subscribeToTopic("Owner_Notification", currentToken);
          //   else if (this.user.role === "Employee")
          //     this.subscribeToTopic("Employee_Notification", currentToken);

          // }

        }

      });
  }

  // toast message handler
  successMessage(message) {
    this.toastr.success(message, "Success");
  }

  errorMessage(message) {
    this.toastr.error(message, "Error");
  }
}
