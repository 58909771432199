import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { HelperService } from 'src/app/services/helper/helper';
import { selectAppSettings } from 'src/app/state/app.selectors';

@Component({
  selector: 'app-card-view-elements',
  templateUrl: './card-view-elements.component.html',
  styleUrl: './card-view-elements.component.css'
})
export class CardViewElementsComponent implements OnInit{
@Input() cardElement;
@Input() cardDesign
@Input() preview
@Input() model
cardDesignPlaceholder =  {
    fontSize: '12px', 
    fontWeight: 'bold', 
    fontPosition: 'bottom', 
    cardShape: 'rectangle',
    imageShape: 'none', 
    imageStyle: 'full', 
    imageWidth: 60,
    imageHeight: 60,
    width: 160,
    height: 160
}
customSectionRef: unknown;
appSettings: any;
boxStyle: {}
appSettings$: Subscription;
constructor(private customSectionService: CustomSectionService, private router: Router,
  private helperService: HelperService, private store: Store
){
  this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
    this.appSettings = this.helperService.cloneDeep(settings);
    this.cardDesign = this.appSettings.cardDesign ? this.appSettings.cardDesign : this.cardDesignPlaceholder
  
  });

}
ngOnInit(): void {
}
ngOnChanges(changes: SimpleChanges): void {
  if (changes.cardElement) {
  }
}

navigateTo(section) {
  if (section.isCustomSection){
    this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe((sec: any) => {
      if(sec.length > 0){
        this.customSectionRef = sec[0];
        if(this.customSectionRef){
          this.router.navigate(['/custom-section-list/', sec[0].id]);
        }
      }

    });

  }else{
    if(section.originalName =='Blogs'){
    this.router.navigate(['/blogs']);
  }
  else if (section.originalName == 'Events') {
    this.router.navigate(['/events']);
  }
  else if (section.originalName == 'Products') {
    this.router.navigate(['/products']);

  }
  else if (section.originalName == 'Services') {
    this.router.navigate(['/services']);
  }
  else if (section.originalName == 'About Us') {
    this.router.navigate(['/about']);
  }
  else if (section.originalName == 'Footer') {
    this.router.navigate(['/contact-us']);
  }
  else if(section.originalName =='First Nations Leaders'){
    this.router.navigate(['/nation-leaders']);
  }
  else if(section.originalName =='faq'){
    this.router.navigate(['/faq-list']);
  }
}
}

getBoxStyle(boxStyle){
  switch(boxStyle){
    case 'square':
      this.boxStyle = {

        'border-radius':'0',


      }
      break;
    case 'circle':
      this.boxStyle = {

        'border-radius':'50%',


      }
      break;
    case 'rectangle':
      this.boxStyle = {

        'border-radius':'0',
  
      }
      break;
    default:
      this.boxStyle = {

      }

  }
}

getStyle(type){
  if(type==='image'){
    this.getBoxStyle(this.cardDesign.imageShape)
    return this.boxStyle
  }else if(type==='card'){
    this.getBoxStyle(this.cardDesign.cardShape)
    return this.boxStyle
  }
  
}

}
