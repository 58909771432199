import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class RoutMetaService {
  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
  }
  
  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }
  
  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateKeywords(key: string) {
    this.meta.updateTag({ name: 'keywords', content: key });
  }
}
