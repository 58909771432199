<div *ngIf="sliderRef && hasAccess" class="space-y-4 z-10 bg-white mx-3 md:mx-20 m p-5 mt-3 rounded-md">
    <div class="font-bold text-lg ">
        <div class="flex justify-between">
            <div>Landing Page Carousel</div>
        </div>
        <div class="w-full border-b-2 border-solid border-gray-200 p-1">
        </div>
    </div>

    <div class="pt-5">
        <div class="input-field col s12 ">
            <label for="interval" class="block tracking-wide text-base mb-2 font-semibold">
              Interval between images (seconds)</label>

            <input id="interval"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
                onkeypress="isNumberKey(event)" type="number" min="1" [value]="sliderRef.interval/1000"
                (input)="intervalChange($event.target.value)">
        </div>
    </div>
    <hr>
    <div class="flex flex-col-reverse md:flex-row justify-center md:justify-start ">
        <div *ngIf="sliderRef.options.length >0" class="w-10/12">
            <p class="tracking-wide text-gray-700 text-base mb-6 font-semibold">Your Current Slides</p>
            <div class="flex flex-wrap md:justify-start justify-center gap-8">
                <div class="relative" *ngFor="let item of sliderRef.options let i = index;">
                    <img loading="lazy" [src]="item.imgUrl" (click)="currentSlide(i+1)"
                        class="object-cover z-10 w-24 h-24 rounded-md cover hover:cursor-pointer" alt="Image">
                    <button (click)="sliderClicked(i)"
                        class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                        <i class="fa fa-pencil text-md"></i>
                    </button>
                </div>

            </div>
        </div>
        <div *ngIf="sliderRef.options.length == 0" class="py-6 mr-3">
            No Slides Create a New Slide
        </div>
    </div>

    <div class="pt-4">
        <button
        (click)="addSlider()" 
        type="button"
        class="inline-block rounded border-2 border-customTeal text-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
        data-te-ripple-init>
        <i class="fa fa-plus-circle mr-2 text-customTeal float-left"></i>
        Add Carousel Image
      </button>
    </div>

    <hr>
    <div class="row py-5" *ngIf=" sliderRef.options.length > 0">
        <p class="tracking-wide text-gray-700 text-base mb-6 font-semibold">
          Please click on the image to edit.
        </p>

        <div class="row">
            <app-slider [slides]="slideElements" [intervalDuration]="sliderRef.interval" (carouselImageSelected)="sliderClicked($event) "> </app-slider>

            <br>

        </div>

    
        <div class="py-10 flex space-x-4 w-full justify-end">
            <button
            (click)="backBtnClicked()"
                *ngIf="calledFromEmptyDashboard != 'Slider'"
                type="button"
                class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init>
                <span>
                Cancel
                </span>
            </button>

            <button
            *ngIf="calledFromEmptyDashboard == 'Slider'"
            (click)="skipSetup()"
            type="button"
            class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                >
            <span>
            Skip
            </span>
        </button>
            <button
            (click)="landingPageConfirmationUpdate()"
            type="button"
            class="inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            data-te-ripple-init
            data-te-ripple-color="light">
            Save
        </button>               

        </div>
    </div>
</div>


<!-- edit slide modal -->
<div id="editSliderModal" class="modal z-40"
    [ngClass]="{'modal-open': editModalOpen, 'modal-action': !editModalOpen }"
    *ngIf="editModalOpen && sliderRef && sliderRef.options.length > 0">

    <div class="modal-box bg-white  w-11/12 rounded-md md:w-8/12 max-w-5xl flex flex-col  px-10 py-6">
      <app-close-modal (closeBtnClicked)="editModalOpen=false"></app-close-modal>
        <div>
            <div class="modal-content flex gap-y-5 flex-col " *ngIf="sliderRef.options[slideRef]">
                <h3 class="font-bold text-xl">Edit Slider</h3>
                <hr>
                <div class="flex space-x-4 items-center">
                    <div class="w-10/12 flex space-x-4 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
                          </svg>
                          
                       <div>
                        <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Replace Image</span>
                        <p class="text-xs text-gray-500">This image is what the users will see in the landing page.</p>
                       </div>
                    </div>
                </div>
                <div class="flex flex-col-reverse md:flex-row justify-start gap-2">
                    <div class="flex md:flex-row flex-col">
                        <!-- Image -->
                        <div class="w-full mt-5 md:w-1/3">
                            <button type="button">
                            <label class="flex flex-col py-5 items-center justify-center space-y-2 px-10 shadow-md border border-solid border-gray-200 bg-gray-50 rounded-lg">
                            <svg id="Layer_1" class="w-8 h-8" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7704.74 6791.12"><circle cx="2373.6" cy="2638.32" r="695.57" transform="translate(-1413.99 1788.05) rotate(-45)" style="fill:#231f20"/><path d="M7671.44,940c-178.58-178.61-416-276.93-668.57-276.93H1189.17c-252.56,0-490,98.32-668.61,276.93s-276.93,416-276.93,668.57V6508.7c0,252.56,98.36,490,276.93,668.57s416.05,276.93,668.61,276.93h5813.7c252.56,0,490-98.36,668.57-276.93s276.93-416,276.93-668.57V1608.58C7948.37,1356,7850,1118.59,7671.44,940ZM7386.2,6508.7c0,9.58-.34,19.13-1.06,28.56q-582.86-730.58-1077.85-1430.1C5530.21,4014.71,5164.73,3357.57,4738.42,3418.77c-198.19,28.45-370.5,253-715.09,702.14-455.58,593.83-525,924.41-737.08,924-300.22-.57-401.22-663.59-746.59-678.79-230.42-10.15-415.18,274.9-509.58,419.25-325.6,497.92-721.5,1073.86-1219.91,1781.25a381.07,381.07,0,0,1-4.37-57.88V1608.58c0-211.4,172-383.33,383.37-383.33h5813.7c211.36,0,383.33,171.93,383.33,383.33Z" transform="translate(-243.63 -663.08)" style="fill:#231f20"/></svg>
                            <input type="file" type="file" accept="image/*" (change)="fileChangeEventEditSlider($event)" >
                            <span class="block font-bold pt-1 text-center">Replace Image</span>
                            </label>
                            </button>
                            <!-- <div *ngIf="isMobile">
                                <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                            </div> -->
                        </div>
                        <!-- Image upload Preview  -->
                        <div class="pt-5  mt-5 ml-10 md:pt-0 md:pl-2">
                            <div class="flex flex-wrap gap-8">
                                <div class="relative drop-shadow-sm px-2">
                                <ng-container *ngIf="base64ImageEditSlider" >
                                    <img loading="lazy" [src]="base64ImageEditSlider" class="object-contain h-24 w-40 rounded-md z-10" alt="Image">
                                    <button (click)="removeImage()" type="button"
                                    class="absolute top-0 translate-x-16 -translate-y-4 rounded-full z-10 bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                    <i class="fa fa-trash text-md"></i>
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="sliderRef.options[slideRef].imgUrl && !base64ImageEditSlider">
                                <img loading="lazy" [src]="sliderRef.options[slideRef].imgUrl"
                                    class="object-contain h-24 w-40 rounded-md z-10" alt="Hero Image">
                                <button (click)="removeSliderImage(sliderRef.options[slideRef])" type="button"
                                    class="absolute top-0 translate-x-16 -translate-y-4 rounded-full z-10 bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                    <i class="fa fa-trash text-md"></i>
                                </button>
                                </ng-container>
                                </div>
                
                            </div>
                        </div>
                    </div>
                
                  

                </div>
                <hr>
                <div>

                    <div class="flex items-center space-x-4">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
                          </svg>
                          
                        <span class="text-[#4F4F4F] text-base font-bold leading-5">Select position</span>
                    </div>

                    <div class="flex flex-wrap justify-start space-x-5">
                        <label *ngFor="let s of sliderRef.options; let i = index;" class="label cursor-pointer">
                            <input name="group1" type="radio" class="radio checked:bg-customTeal"
                                [checked]="sliderRef.options[slideRef].index === i" (click)="positionChanged(i)" />
                                <span class="pl-1">{{i+1}}</span>
                            <span class="padding-left: 24px;"></span>
                        </label>
                    </div>
                </div>

                <hr>
             

                <div class="form-control w-full max-w-x space-y-2">
                    <div class="flex space-x-4 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                          </svg>
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Link (Optional)</span>
                    </div>
                    <div class="flex gap-2 justify-start">
                        <input
                            class="appearance-none block input text-base py-[0.32rem] px-3 leading-[1.6] border-2 min-h-[auto] input-bordered rounded-md bg-white w-full md:w-7/12 text-gray-700  focus:outline-none  focus:border-gray-500"
                            [(ngModel)]=" sliderRef.options[slideRef].url" 
                            placeholder="www.placeholderlink.com"/>
                        <!-- <input id="titleColor" [(ngModel)]=" sliderRef.options[slideRef].urlColor" type="color" class="themeColorSelector ml-3 h-10 w-10"> -->
                    </div>

                </div>
                <!-- <div class="form-control w-full">
                    <label class="label">
                    <span class="label-text text-black text-base font-bold leading-5">Pick an text position</span>
                    </label>
                    <select class="select select-bordered w-8/12" [(ngModel)]=" sliderRef.options[slideRef].textPosition">
                    <option *ngFor="let item of textPositions | keyvalue" [value]="item.value">
                        {{item.key}}</option>
                    </select>
                </div> -->
            </div>

            <div class="modal-action items-center justify-between">

                <div class="gap-2 ">
                    <label>
                        <button 
                        class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                            type="button" (click)="editModalOpen=false">
                            Cancel
                        </button>
                    </label>
                   
                </div>
              <div class="flex space-x-4 ">
                <label>
                    <button class="py-2 px-8 font-bold text-red-700 flex items-center hover:bg-red-700 hover:text-white hover:rounded-md hover:font-normal border-none"
                        type="button" (click)="deleteModalOpen=true">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                          </svg>
                        <span>
                            Delete
                        </span>
                    </button>
                </label>
                <button (click)="updateSlider(sliderRef.options[slideRef]);"
                [disabled]="sliderRef.options[slideRef].imgUrl == '' && (this.mediaList.length === 0)"
                            [ngClass]="{'bg-[#cccccc] text-[#666666]': sliderRef.options[slideRef].imgUrl == '' && (this.mediaList.length === 0)}"
                    class="py-2 px-8 bg-customTeal text-white border-customTeal rounded-md">Save
                    Update</button>
              </div>
            </div>
        </div>
    </div>
</div>
<!-- add new slide -->
<div id="sliderModal" class="modal z-40" *ngIf="sliderRef && createModalOpen"
    [ngClass]="{'modal-open': createModalOpen, 'modal-action': !createModalOpen}">

    <div class="modal-box  bg-white  w-11/12  md:w-8/12 max-w-5xl flex flex-col pl-10 pb-6">
      <app-close-modal (closeBtnClicked)=closeCreateSliderModal()></app-close-modal>
        <form [formGroup]="newSlideForm" (ngSubmit)="saveNewslide(newSlideForm.value)">

            <div class="modal-content flex flex-col gap-y-6">
                <h3 class="font-bold text-xl">Add a new slider</h3>
                <hr>
                <div class="flex flex-col-reverse md:flex-row justify-start gap-2">
                    <div
                        class=" flex content-center w-1/4 h-32  rounded-lg border-2 gap-x-3">
                        <button type="button" *ngIf="!isMobile">
                            <label class="flex flex-col py-5 justify-center items-center space-y-2 px-10 shadow-md border border-solid border-gray-200 bg-gray-50 rounded-lg">
                            <svg id="Layer_1" class="w-8 h-8" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7704.74 6791.12"><circle cx="2373.6" cy="2638.32" r="695.57" transform="translate(-1413.99 1788.05) rotate(-45)" style="fill:#231f20"/><path d="M7671.44,940c-178.58-178.61-416-276.93-668.57-276.93H1189.17c-252.56,0-490,98.32-668.61,276.93s-276.93,416-276.93,668.57V6508.7c0,252.56,98.36,490,276.93,668.57s416.05,276.93,668.61,276.93h5813.7c252.56,0,490-98.36,668.57-276.93s276.93-416,276.93-668.57V1608.58C7948.37,1356,7850,1118.59,7671.44,940ZM7386.2,6508.7c0,9.58-.34,19.13-1.06,28.56q-582.86-730.58-1077.85-1430.1C5530.21,4014.71,5164.73,3357.57,4738.42,3418.77c-198.19,28.45-370.5,253-715.09,702.14-455.58,593.83-525,924.41-737.08,924-300.22-.57-401.22-663.59-746.59-678.79-230.42-10.15-415.18,274.9-509.58,419.25-325.6,497.92-721.5,1073.86-1219.91,1781.25a381.07,381.07,0,0,1-4.37-57.88V1608.58c0-211.4,172-383.33,383.37-383.33h5813.7c211.36,0,383.33,171.93,383.33,383.33Z" transform="translate(-243.63 -663.08)" style="fill:#231f20"/></svg>
                            <input type="file" type="file" accept="image/*" (change)="fileChangeEvent($event)" >
                            <span class="block font-bold pt-1 text-center">Upload Image</span><br/>
                            </label>
                        </button>   
                        <app-image-uploader *ngIf="isMobile" class="justify-start items-start"  (onImageUpload)=onModalImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                    </div>

                    <div class="relative drop-shadow-sm px-2" *ngIf="imgUrl.value && !base64Image">
                        <img loading="lazy" [src]="imgUrl.value" class="object-contain h-24 w-40 rounded-md z-10" alt="Image">
                        <button (click)="removeImage()"
                            class="absolute top-0 translate-x-16 -translate-y-4 rounded-full z-10 bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                            <i class="fa fa-trash text-md"></i>
                        </button>
                    </div>
                    <div class="relative drop-shadow-sm px-2" *ngIf="base64Image">
                        <img loading="lazy" [src]="base64Image" class="object-contain h-24 w-40 rounded-md  z-10" alt="Image">
                        <button (click)="removeImage()"
                            class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                            <i class="fa fa-trash text-md"></i>
                        </button>
                    </div>

                </div>

                <div>
                    <label class="label">
                        <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Select position</span>
                    </label>

                    <div class="flex flex-wrap justify-start space-x-5">
                        <label
                            *ngFor="let s of [].constructor(sliderRef.options.length +1); let i = index; let last = last"
                            class="label cursor-pointer">
                            <input name="position" type="radio" class="radio checked:bg-customTeal" [checked]="last"
                                (click)="positionChanged(i)" />
                                <span class="pl-1">{{i+1}}</span>
                            <span class="padding-left: 24px;"></span>
                        </label>
                    </div>
                </div>
          
            </div>

            <div class="modal-action items-center justify-between">
                <label>
                    <button 
                    class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10" 
                    type="button" (click)="closeCreateSliderModal()">
                    Cancel
                    </button>
                </label>

                <label>
                    <button class="py-2 px-8 bg-customTeal text-white border-customTeal rounded-md" type="submit"
                     [disabled]="this.mediaList.length === 0"
                     [ngClass]="{'bg-[#cccccc] text-[#666666]': this.mediaList.length === 0}"
                     >
                        Save
                    </button>
                </label>
            </div>
        </form>

    </div>
</div>
<!-- add new slide -->
<div id="sliderModal" class="modal z-40 " *ngIf="addMoreModal"
    [ngClass]="{'modal-open': addMoreModal, 'modal-action': !addMoreModal}">

    <div class="modal-box rounded-md flex flex-col justify-center">
        <h3 class="font-bold text-xl text-center mb-2 text-customTeal">Add more</h3>
        <p class="py-4 text-center"> Do you want to add more carousel images?</p>
        <div class="modal-action flex items-center justify-center gap-x-6 ">
            <label (click)="closeAddMoreModal()"
                class="flex items-center rounded border border-solid border-gray-200  bg-gray-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                No, Close
            </label>
            <label>
                <button 
                (click)="addSlider()"
                class="flex items-center rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                type="button"
                >Yes, Add more</button>
            </label>
        </div>
    </div>
</div>


<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [aspectRatio]="13 / 7" [useBase64]="useBase64" (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Edit slide Image Modal -->
<app-crop-image *ngIf="cropModalOpenEditSlider" [theImage]="imageChangedEventEditSlider" [useBase64]="useEditModalBase64" [aspectRatio]="13 / 7" (returnCroppedImage)="processCroppedImageEditSlider($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Edit Slider Confirmation Modal -->
<app-edit-confirmation *ngIf="editConfirmationModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveLandingPageUpdate() editedFromComponent='Slider Setting'></app-edit-confirmation>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=removeSlider(sliderRef.options[slideRef]) (cancelDeleteClicked)="deleteModalOpen=false" deletedFromComponent='Carousel'></app-delete-confirmation>

<!-- Upload File Modal -->
<app-file-upload-modal *ngIf="uploadFileModalOpen" [mediaList]="mediaList" (returnSavedMediaURLs)="getTheUrlSlide($event)"></app-file-upload-modal>

<!-- Upload File Modal -->
<app-file-upload-modal *ngIf="uploadFileEditModalOpen" [mediaList]="mediaList" (returnSavedMediaURLs)="getTheUrlSlideEdit($event)"></app-file-upload-modal>
