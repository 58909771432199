import { UserService } from './../../../services/firebase/user.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import * as firebase from 'firebase/compat/app';
import { PushNotificationSettingsService } from 'src/app/services/firebase/push-notification-settings.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GetAppService } from 'src/app/services/firebase/get-app.service';
// declare var $: any;

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('newPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

@Component({
  selector: 'app-settings-user',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {
  
  userId: '';
  subscriberId: string;
  currentUser: any;
  allowChat; allowEmail; allowNomination = false; allowNotifications; allowSearch; contactMe; shareLocation;
  loggedInUser: any;

  SMSchecked: boolean = false;
  Emailchecked: boolean = false;
  Inappchecked: boolean = false;

  toggleNotfication: boolean = true;
  confirmationModal: boolean = false;
  userContactPreference = [];

  isMobile = false;
  // model: any;
  allowPushNotification= false;

  changePasswordModal: boolean;
  changePasswordForm: UntypedFormGroup;

  //toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  reopenModal: boolean = false;
  requestModal: boolean = false;
  updateModal: boolean = false;

  // default notification preference
  notifSettings
  newSettings: boolean
  defaultNotifications = {
    id: '',
    userId: '',
    settings: [
      {
        "displayName": "News/Events",
        "name": "events",
        "isEnabled": false
      },
      {
        "displayName": "Appointment Reminder",
        "name": "appointment",
        "isEnabled": false
      },
      {
        "displayName": "Direct Message",
        "name": "chat",
        "isEnabled": false
      }
    ],
  }

  hasAnApp: boolean;

  constructor(private authService: AuthService,
    private userService: UserService,
    private location: Location,
    private fb: UntypedFormBuilder,
    public router: Router,
    private route: ActivatedRoute, 
    public getAppService : GetAppService,
    public notificationSettingService: PushNotificationSettingsService,
    public notificationPreferenceService: NotificationPreferenceService) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
    this.route.paramMap.subscribe(params => {
      this.subscriberId = params.get('id');
    });

  }

  ngOnInit() {   
    this.userId = this.authService.getGlobalUser().uid;
    this.currentUser = this.authService.getGlobalUser();

    this.changePasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
    });

    this.userService.getUserById(this.subscriberId).valueChanges().subscribe(user => {
      if (user && user.length > 0) {
        this.loggedInUser = user[0];
        this.userContactPreference = this.loggedInUser?.contact;
        this.loggedInUser?.contact?.forEach(contactType => {
          if (contactType === 'SMS') this.SMSchecked = true;
          if (contactType === 'Email') this.Emailchecked = true;
          if (contactType === 'InApp') this.Inappchecked = true;
        });
        this.toggleNotfication = this.loggedInUser.notification === "on";
        this.allowPushNotification = this.loggedInUser?.allowPushNotification ?? false;

        this.toggleNotfication = this.SMSchecked && this.Emailchecked && this.allowPushNotification;
      }
    });

    this.getAppService.getRequestByEmail(this.currentUser.email).valueChanges().subscribe((user) => {
      if (user && user.length > 0) {
        this.hasAnApp = true;
      }
      else {
        this.hasAnApp = false;
      }
    })

    // check if allow push notification is in the user object
    this.allowPushNotification = this.currentUser?.allowPushNotification ?? false;

    // fetch default notification settings
    this.fetchPushNotificationSettings();

    this.toggleNotfication = true;
  }
  // fetch app settings
  fetchPushNotificationSettings() {

    this.notificationSettingService.getClientPushNotificationSettingsList(this.subscriberId).valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.notifSettings = x[0]
        this.newSettings = false
      } else {
        this.newSettings = true
        this.notifSettings = this.defaultNotifications
      }
    })
  }

  // save notification related to settings
  save() {
    
    this.notifSettings.userId = this.subscriberId;
    if (this.newSettings) {
      this.notificationSettingService.saveClientPushNotificationSettings(this.notifSettings).then(async x => {
        this.routeTo();
        // success toast
        this.toast({ html: 'Successfully  Updated Push Notification!', classes: 'green', type: 'success' });
      })
    } else {
      this.notificationSettingService.updateClientPushNotificationSettings(this.notifSettings).then(async x => {
        this.routeTo();
        // success toast
        this.toast({ html: 'Successfully  Updated Push Notification!', classes: 'green', type: 'success' });
      })
    }
  }
  routeTo(){
    if(this.hasAnApp){
      this.router.navigate(['/user/manage-app',this.subscriberId]);    
      }else{
        this.router.navigate(['/user/homepage']);    
      }
  }

  finish() {
    this.loggedInUser.contact = this.userContactPreference;
    this.loggedInUser.accountSetup = 'filled'
    // to toggle if the user wants to receive push notfication or not .. 
    this.loggedInUser.allowPushNotification = this.allowPushNotification;

    if (this.allowNotifications) {
      this.loggedInUser.notification = 'on';
    }
    else {
      this.loggedInUser.notification = 'off';
    }
    this.confirmationModal = true;
  }

  toggleSMS(event) {
  this.SMSchecked = !this.SMSchecked;
  if (this.SMSchecked) {
    if (!this.userContactPreference.includes('SMS')) {
      this.userContactPreference.push('SMS');
    }
  } else {
    const SMSIndex = this.userContactPreference.indexOf('SMS');
    if (SMSIndex !== -1) {
      this.userContactPreference.splice(SMSIndex, 1);
    }
  }
}

  toggleEmail(event) {
    this.Emailchecked = !this.Emailchecked;
    if (this.Emailchecked && !(this.userContactPreference.includes('Email'))) {
      this.userContactPreference.push('Email');
    }
    else {
      const Email = this.userContactPreference.indexOf('Email');//get  "sms" index
      this.userContactPreference.splice(Email, 1);
    }

  }

  toggleInapp(event) {
    this.Inappchecked = !this.Inappchecked;
    if (!(this.userContactPreference.includes('InApp')) && this.Inappchecked) {
      this.userContactPreference.push('InApp');
    }
    else {
      var InApp = this.userContactPreference.indexOf('InApp');//get  "sms" index
      this.userContactPreference.splice(InApp, 1);
    }
  }

  toggleNotificationButton(event) {
    this.toggleNotfication = !this.toggleNotfication;
    if (event.target.checked) {
      this.allowNotifications = true;
      this.Inappchecked = true;
      this.SMSchecked = true;
      this.Emailchecked = true;
      this.allowPushNotification = true;

      // update the logged in user array
      this.userContactPreference=['SMS','Email','InApp'];
      this.allowPushNotification= true;
      
    }
    else {
      this.allowNotifications = false;
      this.Inappchecked = false;
      this.SMSchecked = false;
      this.Emailchecked = false;
      this.userContactPreference=[];
      this.allowPushNotification = false;

      // update the logged in user array
      // this.userContactPreference=['SMS','Email','Inapp'];
      // this.allowPushNotification = true;

    }
  }

  backClicked() {
    this.location.back();
  }

  openConfirmationModal() {
    this.confirmationModal = true;
  }

  closeModal(){
    this.confirmationModal = false;
  }

  closeConfirmationModal() {
    this.confirmationModal = false;

    let receiveNotificationFor = this.notificationPreferenceService.selectTheChoosenNotificationPreference(this.notifSettings)
    this.loggedInUser.accountSetup = "filled" 
    // update the current user info receive notification for section
    if (this.loggedInUser.receiveNotificationFor) {
      this.loggedInUser.receiveNotificationFor = receiveNotificationFor;
    }
    else {
      this.loggedInUser['receiveNotificationFor'] = receiveNotificationFor;
    }
    this.userService.updateUser(this.loggedInUser).then(res => {
      // save clients notification preference
      this.save();

      // this.router.navigate(['/user/manage-app',this.currentUser.uid]);    

    }).catch((err) => {
    })

  }

  openChangePasswordModal() {
    this.changePasswordModal = true;
  }
  closeChangePasswordModal() {
    this.changePasswordModal = false;
  }

  changePassword(model) {

    const user = firebase.default.auth().currentUser;
    const credentials = firebase.default.auth.EmailAuthProvider.credential(user.email, model.oldPassword);
    user.reauthenticateWithCredential(credentials)
      .then(() => {
        user.updatePassword(model.newPassword).then(function () {
          this.openUpdateModal();
          this.toast({ html: 'You have successfully updated your password ', classes: 'green', type: 'success' });
        }).catch(error => {
          this.toast({ html: 'Something went wrong when updating password.', classes: 'red', type: 'error' });
          this.reopenModal = true;
        });
        this.openUpdateModal();

      }).catch(error => {
        this.toast({ html: 'The Current password does not match our records.', classes: 'red', type: 'error' });
        this.reopenModal = true;
      });

  }
  
  passwordReset() {
    this.requestModal = true;
    this.changePasswordModal = false;
  }

  closePasswordReset() {
    this.requestModal = false;
  }

  resetPassword() {
    this.currentUser.resetPassword = true;
    this.closePasswordReset();
    this.authService.resetPassword(this.currentUser.Email).then((res) => {
    
      this.toast({ html: 'A link is sent to your email to reset your password', classes: 'green', type: 'success' });

    }).catch((error) => {
    
      this.toast({ html: 'An error occured reseting the password.', classes: 'red', type: 'error' });
    }
    );
  }
  openUpdateModal() {
    this.closeChangePasswordModal();
    this.updateModal = true;
    setTimeout(() => {
      this.updateModal = false;
      this.location.back();
      //this.router.navigate(['/admin/employees']);

    }, 3000);
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

  get newPassword() { return this.changePasswordForm.get('newPassword'); }
  get confirmPassword() { return this.changePasswordForm.get('confirmPassword'); }
  get oldPassword() { return this.changePasswordForm.get('oldPassword'); }
  
}
