import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientQuestion, Question } from 'src/app/models/PollsAndSurveyModel';
import { EmailService } from 'src/app/services/api/email.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientAnswersService } from 'src/app/services/firebase/client-answers.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/firebase/user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import * as firebase from 'firebase/compat/app';
import { LoadingService } from 'src/app/services/loader/loading.service';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';

@Component({
  selector: 'app-edit-client-info',
  templateUrl: './edit-client-info.component.html',
  styleUrls: ['./edit-client-info.component.css']
})
export class EditClientInfoComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  surveyForm: UntypedFormGroup;
  answersArray: UntypedFormArray;
  visibleStepIndex = 0;
  totalQuestions = 0;
  submitDisabled = false;
  showComment = false;
  leaveComment = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  //added
  form: any;
  model: any;
  userGlobal: any;
  names: any;

  currentUser: any;
  userFilled: boolean = false;

  //to unsubscribe
  clientGlobal: any;

  invitationPopup: boolean = false;
  id: any;

  inviteGlobal: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-from-invite/' : 'http://localhost:4200/register-from-invite/';

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  client: any;
  forms: any;
  answer = '';
  participation: any;
  invite: any;
  value: any;
  clientPhone: any;
  emailExists: boolean = false; //the email has already been entered so it can't be modified
  multipleOne: any;
  multipleTwo: any;
  userForEmail: any;
  count: number = 0;
  exists: boolean; // the email is already inside the database

  phoneExists: boolean = false;
  confirmationPopup: boolean = false;
  role: any;

  deleteModal: boolean = false;
  userModel: any;

  appName: any;
  updateModal: boolean = false;
  beforeDeleteModal: boolean;
  confirmDeleteModal: boolean;
  confirmSecondModal: boolean;
  confirmThreeModal: boolean;

  emailChangeModal: boolean = false;
  emailChanged: string = '';
  password: any;
  emailTobeChanged: any;
  baseFields: any[] = []
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  base64Image: any;
  mediaType: any;
  imgErrorMsg: any;
  binDoc: any;
  pdfErrorMsg: any;

  imgURL: any;
  loggedUser: any;
  mobileBase64Image: any;
  isMobile: boolean;

  constructor(private fb: UntypedFormBuilder,
    private clientFormService: ClientFormService,
    private clientAnswerService: ClientAnswersService,
    private appSettingsService: AppSettingsService,
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private inviteService: InviteService,
    private emailService: EmailService,
    private location: Location,
    public afAuth: AngularFireAuth,
    private loaderService: LoadingService,
    private mediaService: MediaService,
    private contactService: ContactService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.role = this.currentUser.role;

    this.surveyForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email]],
      cellPhone: [''],
      answerItems: this.fb.array([]),
      imgLink: [''],
    });

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings: any) => {
      this.appName = settings[0].appName;
    })

    this.userService.getUserById(this.id).valueChanges().subscribe((user: any) => {

      if (user && user.length != 0) {
        this.userModel = user[0];

        this.emailTobeChanged = user[0].Email;

        if (this.userModel.Email != '') {
          this.emailExists = true;
        }

        this.surveyForm.patchValue({
          firstName: this.userModel.GivenName ? this.userModel.GivenName : '',
          lastName: this.userModel.LastName ? this.userModel.LastName : '',
          email: this.userModel.Email ? this.userModel.Email : '',
          cellPhone: this.userModel.cellPhone ? this.userModel.cellPhone : '',
          imgLink: this.userModel.profileImgLink ? this.userModel.profileImgLink : '',
        });

        // update the values of the dynamic fields
        for (let i = 0; i < this.baseFields?.length; i++) {
          this.surveyForm.patchValue[this.baseFields[i]?.key] = this.userModel[this.baseFields[i]?.key];
        }
      }

    })


    /*this.clientAnswerService.getFormParticipationByClientID(this.id).valueChanges().subscribe(clients => {
      if (clients && clients.length != 0) {
        this.client = clients[0];

        this.clientGlobal = this.clientFormService.getFormList().valueChanges().subscribe(form => {
          this.model = form[0];
          if (this.model) {
            this.totalQuestions = this.model.questions.length;

            this.model.questions.forEach((question, answer) => {
              if (this.client?.answers == undefined) {
                this.client.answers = question;
                this.answer = '';
              }
              if (this.client.answers[answer] == undefined) {
                this.client.answers[answer] = question
                this.client.answers[answer].answer = '';
              }
              else if ((this.client.answers != undefined || this.client.answers[answer] != undefined) && question.answerType != "Multiple Choice") {
                //if(this.client.answers[0].answer != undefined){
                this.answer = this.client.answers[answer].answer;
              }
              // else if(this.client.answers[answer].answer == '' && question.answerType == "Multiple Choice"){
              //   this.answer = '';
              // }
              else if ((this.client.answers != undefined || this.client.answers[answer] != undefined) && question.answerType == "Multiple Choice") {
                if (!question.allowMultipleAnswers) {
                  this.multipleOne = this.client.answers[answer].answer;
                } else {
                  // this.client.answers[answer].answer.split(',').forEach(ans => {
                  //   this.multipleTwo = ans;
                  // });
                  this.multipleTwo = this.client.answers[answer].answer.split(',');
                }
              }
              else {
                this.answer = '';
              }

              if (question.answerType === 'Short Answer') { //Open Ended
                this.addAnswer(question);
              } else {
                this.addAnswer2(question);
              }

            });
          }
          this.clientGlobal.unsubscribe();
        })
      }
    })*/
    this.fetchContactHeaders();
  }

  addAnswer(questionModel: ClientQuestion) {
    this.answersArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer(questionModel));
  }

  createAnswer(questionModel: ClientQuestion) {
    this.forms = this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      required: [questionModel.required],
      answer: [this.answer],
    });
    return this.forms;
  }

  addAnswer2(questionModel: Question) {
    this.answersArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer2(questionModel));
  }

  createAnswer2(questionModel: Question) {
    let answers = this.fb.array([]);
    questionModel.answers.forEach(x => {
      answers.push(this.addAnswer3(x.answer));
    });

    this.forms = this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      required: [questionModel.required],
      allowMultipleAnswers: [questionModel.allowMultipleAnswers],
      answers: answers,
      answer: [this.answer],
    });

    return this.forms;

  }

  addAnswer3(value) {
    return this.fb.group({
      answer: [value]
    });
  }

  answerSelected(question_index, value) {
    const answerArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    if (answerArray.controls[question_index].get('questionType').value === 'Multiple Choice' && !answerArray.controls[question_index].get('allowMultipleAnswers').value) {
      answerArray.controls[question_index].get('answer').patchValue(value);

    }
    if (answerArray.controls[question_index].get('questionType').value === 'Multiple Choice' && answerArray.controls[question_index].get('allowMultipleAnswers').value) {
      if (answerArray.controls[question_index].get('answer').value == "") {
      }
      var answer = answerArray.controls[question_index].get('answer').value;
      if (answer == "") {
        answerArray.controls[question_index].get('answer').patchValue(value);
      } else {
        const answers: string[] = answer.split(',');
        if (answers.includes(value)) {
          const index = answers.indexOf(value);
          answers.splice(index, 1);
          value = answers.join(' , ');
          //value = [answer].join(',');
        } else {
          value = [answer, value].join(',');
        }
      }
      answerArray.controls[question_index].get('answer').patchValue(value);
    }
  }

  closeEmailChangeModal() {
    this.emailChangeModal = false;
  }

  emailChange(event) {
    this.emailChanged = event.target.value;

    this.userService.getUserByEmail(event.target.value).valueChanges().subscribe((user: any) => {
      if (user && user.length != 0) {
        if (user[0].uid != this.id) {
          this.toast({ html: 'This Client has already registered with this email address!', classes: 'red', type: 'error' });
        } else {
          this.openConfirmation();
        }
      } else {
        this.emailChangeModal = true;
      }
    })
  }

  saveNewEmail() {
    const newEmail = this.emailChanged;
    this.userModel.Email = newEmail;
    const userModel = this.userModel
    const participation = this.participation;

    firebase.default.auth()
      .signInWithEmailAndPassword(this.emailTobeChanged, this.password)
      .then(function (userCredential) {
        userCredential.user.updateEmail(newEmail).then(() => {
        });

        this.userService.updateUser(userModel).then(x => {
          this.clientAnswerService.updateFormParticipationByClient(participation).then(x => {
            this.toast({ html: 'Your Client Information has Successfully Updated!', classes: 'green', type: 'success' });
          });
        })
        this.openUpdateModal();

      }).catch((error) => {
        this.toast({ html: `${error.message}`, classes: 'red', type: 'error' });
        return;
      });

    this.closeEmailChangeModal();
    //this.openConfirmation();
  }


  submitSurvey(value: any) {

    this.emailChanged = value.email;
    // check the following section if the user updates their email
    if ((this.userModel?.Email || value.email) && this.userModel?.Email != value.email) {
      this.userService.getUserByEmail(value.email).valueChanges().subscribe((user: any) => {
        if (user && user.length != 0) {
          if (user[0].uid != this.id) {- 
            this.toast({ html: 'This Client has already registered with this email address!', classes: 'red', type: 'error' });
          } else {
            this.openConfirmation();
          }
        } else {
          this.emailChangeModal = true;
        }
      })
    } else {
      this.openConfirmation();
    }


    value.answerItems.forEach(element => {
      const answer = {
        questionNumber: element.questionNumber,
        question: element.question,
        questionType: element.questionType,
        required: element.required,
        answer: element.answer,
      };
      this.participation.answers.push(answer);
    });
  }

  EditClientInfo() {
    this.userService.updateUser(this.userModel);
    this.clientAnswerService.updateFormParticipationByClient(this.participation).then(x => {
      this.toast({ html: 'Your Client Information has Successfully Updated!', classes: 'green', type: 'success' });
      //this.router.navigate(['/admin/search-users']);
    })
    setTimeout(() => {
      this.location.back();
    }, 1000);
  }

  getOptions(index) {
    return this.model.questions[index].answers;
  }

  backToList() {
    this.router.navigate(['/user/view-form']);
  }
  closeModal() {
    this.invitationPopup = false;
  }

  update() {
    this.userModel.profileImgLink = this.imgURL

    Object.keys(this.surveyForm?.value).forEach((key) => {
      if (this.userModel.hasOwnProperty(key) || this.surveyForm?.value[key]) {
        (this.userModel as any)[key] = (this.surveyForm?.value as any)[key];
      }
    });

    if (this.surveyForm.value['firstName']) this.userModel.GivenName = this.surveyForm.value['firstName']
    if (this.surveyForm.value['lastName']) this.userModel.LastName = this.surveyForm.value['lastName']

    this.userService.updateUser(this.userModel).then(x => {
      if(this.participation){
        this.clientAnswerService.updateFormParticipationByClient(this.participation).then(x => {
          this.toast({ html: 'Your Client Information has Successfully Updated!', classes: 'green', type: 'success' });
        });
      }
    })

    this.openUpdateModal();
  }

  openConfirmation() {
    if (this.surveyForm.valid) {
      this.confirmationPopup = true;
    }

  }

  cancelConfirmation() {
    this.confirmationPopup = false;
  }

  openDeleteModal() {
    this.deleteModal = true;
  }

  closeDeleteModal() {
    this.deleteModal = false;
  }

  openDeleteModalOne() {
    this.confirmDeleteModal = true;
  }
  closeDeleteModalOne() {
    this.confirmDeleteModal = false;
  }
  openSecondModalTwo() {
    this.closeDeleteModalOne();
    this.confirmSecondModal = true;
  }
  closeSecondModalTwo() {
    this.confirmSecondModal = false;
  }
  openThreeModal() {
    this.closeSecondModalTwo();
    this.confirmThreeModal = true;
  }
  closeThreeModal() {
    this.confirmThreeModal = false;
  }

  beforeDelete() {
    this.closeThreeModal();
    this.beforeDeleteModal = true
    setTimeout(() => {
      this.beforeDeleteModal = false
      this.deleteUser(this.currentUser);
    }, 2500);
  }

  deleteUser(currentUser) {

    this.closeDeleteModal();
    this.afAuth.currentUser.then(user => {
      this.userService.deleteUserAccount(currentUser.uid);

      user?.delete();
      setTimeout(() => {
        this.router.navigate(['/'])
      }, 800)
    }).catch((error) => {
      this.toast({ html: `${{ error }}`, classes: 'red', type: 'failure' });
    });
  }

  inviteClient() {

    this.invite = this.inviteService.getInviteByEmail(this.value.email).valueChanges().subscribe(invite => {
      if (invite) {
        if (invite.length === 0) {
          const timestamp = new Date();
          this.clientAnswerService.newFormParticipation(this.participation).then(x => {
          })
          this.inviteService.saveInvite({ inviteEmail: this.value.email, timestamp, userType: 'User', firstName: this.value.firstName, lastName: this.value.lastName });
        }
        let hostname;
        let newUserInviteEmail;
        let appName;
        let FirstName
        let LastName
        let jobtitle
        if (invite.length === 1) {
          this.inviteGlobal = invite;
          hostname = `${this.host}${this.inviteGlobal[0].id}`;
          newUserInviteEmail = this.value.email;
          appName = this.currentUser.GivenName ? this.currentUser.GivenName : '';
          FirstName = this.inviteGlobal[0].firstName
          LastName = this.inviteGlobal[0].lastName
          jobtitle = this.inviteGlobal[0].jobTitle
          this.invite.unsubscribe();

          this.emailService.sendUserInvite({
            hostname, inviteEmail: newUserInviteEmail, appName, host: this.host, id: this.inviteGlobal[0].id
          }, this.currentUser);

          this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' });
        }
      }
    });
    this.router.navigate(['/admin/search-users']);

  }

  openUpdateModal() {
    this.updateModal = true;
    setTimeout(() => {
      this.cancelConfirmation();
      this.updateModal = false;
      //this.location.back();
      this.router.navigate(['/admin/search-users']);

    }, 3000);
    // this.router.navigate(['/user/edit-clientInfo',this.id]);
  }


  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

  // fetch existing contact headers
  fetchContactHeaders() {

    this.showLoader()
    this.contactService.getContactHeaderNew().subscribe((querySnapshot) => {
      let contacts = [];

      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() });
      });


      let existingFormFields = contacts;

      // filter fields which are not the default one
      let headers = existingFormFields?.filter((header) => !header.default)
      this.baseFields = headers;

      for (let i = 0; i < headers?.length; i++) {
        let validators = [];

        if (headers?.[i]?.required) {
          validators.push(Validators.required);
        }

        if (headers?.[i]?.type === 'email') {
          validators.push(Validators.email)
        }
        else if (headers?.[i]?.type === 'phone') {
          validators.push(this.northAmericanPhoneNumberValidator())
        }

        if (headers?.[i]?.type == 'date') {
          if (headers?.[i]?.minLength) {
            validators.push(this.minDateValidator(new Date(headers[i].minLength)));
          }

          if (headers?.[i]?.maxLength) {
            validators.push(this.maxDateValidator(new Date(headers[i].maxLength)));
          }
        }
        else {
          if (headers?.[i]?.type === 'number') {
            if (headers?.[i]?.minLength) {
              validators.push(Validators.min(headers[i].minLength));
            }

            if (headers?.[i]?.maxLength) {
              validators.push(Validators.max(headers[i].maxLength));
            }
          }
          else {
            if (headers?.[i]?.minLength) {
              validators.push(Validators.minLength(headers[i].minLength));
            }

            if (headers?.[i]?.maxLength) {
              validators.push(Validators.maxLength(headers[i].maxLength));
            }
          }
        }

        let headerValue = headers[i].type === 'checkbox'
          ? this.userModel[headers[i].key] || null
          : this.userModel[headers[i].key] || '';

        const newControl = new FormControl(headerValue, validators);
        this.surveyForm.addControl(headers[i]?.key, newControl);
      }
      this.hideLoader();
    }, (err) => {
      this.hideLoader();
    });
  }

  // Custom validator functions for min and max date
  minDateValidator(minDate: Date) {
    return (control: any) => {
      const inputDate = new Date(control.value);
      return inputDate < minDate ? { 'minDate': { value: control.value, expected: minDate } } : null;
    };
  }
  maxDateValidator(maxDate: Date) {
    return (control: any) => {
      const inputDate = new Date(control.value);
      return inputDate > maxDate ? { 'maxDate': { value: control.value, expected: maxDate } } : null;
    };
  }

  // phone number validation
  northAmericanPhoneNumberValidator() {
    const phoneNumberPattern = /^(?:\+1\s?)?(?:\(?[2-9][0-9]{2}\)?[\s.-]?)?[2-9][0-9]{2}[\s.-]?[0-9]{4}$/;
    return (control) => {
      // Check if the value is null or undefined
      if (control.value === null || control.value === undefined || control.value === '') {
        return null; // No error if value is null or empty
      }

      // Proceed with phone number validation if value is present
      const isValid = phoneNumberPattern.test(control.value);
      return isValid ? null : { 'invalidPhoneNumber': { value: control.value } };
    };
  }


  getMinLengthErrorMessage(control: any): string {
    let controlKey = control?.key;
    let controlType = control?.type;
    const controlErrors = this.surveyForm.controls[controlKey].errors;
    if (controlErrors && controlErrors.minlength) {
      const requiredLength = controlErrors.minlength.requiredLength;
      return `The minimum ${controlType === 'number' ? 'number' : 'characters'} required is ${requiredLength}`;
    }
    else if (controlErrors && controlErrors.min) {
      const requiredLength = controlErrors?.min?.min;
      return `The minimum ${controlType === 'number' ? 'number' : 'characters'} required is ${requiredLength}`;
    }
    return '';
  }

  getMaxLengthErrorMessage(control: any): string {
    let controlKey = control?.key;
    let controlType = control?.type;
    const controlErrors = this.surveyForm.controls[controlKey].errors;
    if (controlErrors && controlErrors.maxlength) {
      const requiredLength = controlErrors.maxlength.requiredLength;
      return `The maximum ${controlType === 'number' ? 'number' : 'characters'} required is ${requiredLength}`;
    }
    else if (controlErrors && controlErrors.max) {
      const requiredLength = controlErrors.max?.max;
      return `The minimum ${controlType === 'number' ? 'number' : 'characters'} required is ${requiredLength}`;
    }
    return '';
  }



  // show Loader
  showLoader() {
    this.loaderService.show()
  }

  // hide loader
  hideLoader() {
    this.loaderService.hide();
  }

  private validateImage(name: any) {

    const ext = name.substring(name.lastIndexOf('.') + 1);

    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  uploadPhoto() {
    this.mediaType = 'Photo';
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue('');
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0]?.name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      // this.imgLink.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  // upload profile pic
  uploadClientInfo() {
    if (!this.base64Image) {
      this.imgURL = null
      this.update();
    } else {
      if (this.isMobile) {
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;
        this.mediaService.uploadImage(img).subscribe(upload => {
          if (upload) {
            this.currentUser.profileImgLink = upload.imgLink;
            this.imgURL = upload.imgLink;


            this.update();
          }
        });
      } else {
        if (this.binDoc) {
          const reader = new FileReader();
          reader.readAsDataURL(this.binDoc);
          reader.onload = () => {
            this.base64Image = reader.result as string;
          };
          // setTimeout(() => {
          const img = new ImageModel();
          img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
          this.mediaService.UploadBinImage(this.binDoc).subscribe(
            upload => {
              this.currentUser.profileImgLink = upload.imgLink;
              this.imgURL = upload.imgLink;

              this.update();
            });

          // }, 30);
        }
      }

    }



  }

  tosubmitImage() {
    if (this.isMobile) {
      const img = new ImageModel();
      img.imgBase64 = this.mobileBase64Image;
      this.mediaService.uploadImage(img).subscribe(upload => {
        if (upload) {
          this.userModel.profileImgLink = upload.imgLink;
          this.imgURL = upload.imgLink;
        }
      });
    } else {
      if (this.binDoc) {
        const reader = new FileReader();
        reader.readAsDataURL(this.binDoc);
        reader.onload = () => {
          this.base64Image = reader.result as string;
        };
        // setTimeout(() => {
        const img = new ImageModel();
        img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            this.userModel.profileImgLink = upload.imgLink;
            this.imgURL = upload.imgLink;
            this.loggedUser = this.currentUser;
            this.loggedUser = this.userModel;
            this.userService.updateUser(this.loggedUser).then(res => {
              // wont get here since it reloads db/refreshed
              this.toast({ html: 'Your Profile has been Updated!', classes: 'green', type: 'success' });
            });
          });

        // }, 30);
      }
    }
    // }

    if (this.userModel) {
      this.userService.updateUser(this.userModel).then(res => {
        // wont get here since it reloads db/refreshed
        this.toast({ html: 'Your Profile has been Updated!', classes: 'green', type: 'success' });
      });
    }
  }
  removeModal() {
    this.base64Image = '';
    // this.mediaList = this.mediaList.filter((file) => {
    //   if (file.type !== "Image") {
    //     return file;
    //   }
    // });
  }


  get firstName() { return this.surveyForm.get('firstName'); }
  get lastName() { return this.surveyForm.get('lastName'); }
  get email() { return this.surveyForm.get('email'); }
  get cellPhone() { return this.surveyForm.get('cellPhone'); }
  get imgLink() { return this.surveyForm.get('imgLink'); }
  get answers() { return (this.surveyForm.get('answerItems') as UntypedFormArray).controls; }
  get answerItems(): UntypedFormArray { return this.surveyForm.get("answerItems") as UntypedFormArray }

}


