import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SMSModel } from 'src/app/models/SMSModel';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { HelperService } from 'src/app/services/helper/helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent implements OnInit {

  id: any;
  selectedContact: any;
  GivenName = "Sample"
  LastName = "Sample 2"
  Email = "email@gmail.com"
  cellPhone = "9876541234"
  openContactModal: boolean = false;
  contactForm: UntypedFormGroup;
  contactMsg: String = ' ';
  SMSStatus: boolean = false
  EmailStatus: boolean = false
  storeEmail = [];
  userLogged: any = {};



  //for Email
  setUp: any
  greetings: any;
  signOff: any;
  email: any;
  messageForSMS: any;
  availablePhone: any;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  appName: any;
  appLogo: any;
  domain = environment.firstNation.name;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/reset-password/' : 'http://localhost:4200/reset-password/';


  constructor(private route: ActivatedRoute, private contactService: ContactService, private fb: UntypedFormBuilder,
    private emailService: EmailService,
    private sMSService: SMSService, private appSettingsService: AppSettingsService,
    private helperService: HelperService) { }

  ngOnInit(): void {

    this.contactForm = this.fb.group({
      contact: [],
      subject: [''],
      emailbody: [''],
      messageSMS: ['']
    });

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');

      // contact informatation
      this.getContactDetailInfo();
    });

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(setup => {
      this.setUp = setup[0]
      this.appName = this.setUp.appName;
      this.appLogo = this.setUp.logo;
      this.greetings = this.setUp.greetings ? this.setUp.greetings : '';
      this.signOff = this.setUp.signOff ? this.setUp.signOff : '';
      this.email = this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff;
      this.messageForSMS = '\n \n' + this.setUp.messageSMS ? this.setUp.messageSMS : '';
      this.availablePhone = this.setUp.availablePhone ? this.setUp.availablePhone : '';
      this.contactForm.patchValue({
        emailbody: this.email,
        messageSMS: this.messageForSMS
      });
    });
  }


  // get contacts information
  getContactDetailInfo() {

    this.contactService.getContactbyId(this.id).valueChanges().subscribe((res) => {
      this.selectedContact = res;
      this.checkEmailAndPhone(this.selectedContact);


    }, (err) => {
    });

  }

  generatePascalCase(value) {
    if (!value) return '';

    // Convert the string to Pascal case
    const words = value.toLowerCase().split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join('');
  }



  getCreatedDate(date: any) {
    return new Date(date).toDateString()
  }

  openModal() {
    this.openContactModal = true;
  }

  cancelSendBtnClicked() {
    this.openContactModal = false;
  }

  // check if a contact contains email or phone 
  checkEmailAndPhone(contact) {
    let both = false;
    let phone = false;
    let email = false;
    let currentStatus = null

    // use object keys
    Object.keys(contact).map((key) => {
      if (contact[key]?.isEmail) {
        email = true;
        this.userLogged.Email = contact[key]?.value;
      }
      else if (contact[key]?.isPhoneNumber) {
        phone = true;
        this.userLogged.cellPhone = contact[key]?.value;
      }

    })

    // check the available contact methods - email/phone
    if (phone) {
      this.SMSStatus = true
    }
    if (email) {
      this.EmailStatus = true
    }


  }

  sendClient(models) {

    if ((this.contactMsg == 'Email' && this.EmailStatus)) {
      const emailModel = new CustomAdminEmailModel();
      emailModel.subject = models.subject;
      emailModel.body = models.emailbody;
      emailModel.isNationApp = this.isNationApp;

      emailModel.body += '<p>';
      if (this.appLogo) {
        emailModel.body += `<img src="${this.appLogo}"
        width="100" height="100" style=" display: block; alt="Logo">`;
      }
      emailModel.body += '</p>';
      
      if (environment.firstNation.name !== "nationalchief") {
        emailModel.body += `<p> To stop receiving SMS please login to <a href="${this.domainName}">${this.domainName}</a></p>`
        emailModel.body += `<p> Go to your preferences and disable Email, SMS or Both.</p>`
      }
      this.storeEmail = [];
      this.storeEmail.push(this.userLogged.Email);
      emailModel.emailList = this.storeEmail;
      // this.cancelSendBtnClicked();

      // custom send email used
      emailModel.host = "smtp.gmail.com",
      emailModel.adminEmail = "mkproject.app@gmail.com",
      emailModel.appPassword = "ytklyidomvqfmtky",

      // this.emailService.sendEmail(emailModel).subscribe((res) => {
      //   if (res) {
      //     // this.userEmailCounter++;
      //     // this.adminEmailCounter++;
      //     // this.adminCounter.emailTotalCounter = this.adminEmailCounter;
      //     // this.userCounter.emailTotalCounter = this.userEmailCounter;

      //     // this.counter.updateCounter(this.adminCounter);
      //     // this.counter.updateCounter(this.userCounter);

      //     this.sendBtnClicked({ html: 'Email sent sucessfully ', classes: 'green', type: 'success' });
      //     // this.cdr.detectChanges();
      //     this.reset(models);
      //     // this.cancelSendBtnClicked();

      //     // this.toast({ html: 'Email sent sucessfully ', classes: 'green', type: 'success' });
      //   }
      //   else {
      //   }
      // })

      this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
        if (res) {
          // this.userEmailCounter++;
          // this.adminEmailCounter++;
          // this.adminCounter.emailTotalCounter = this.adminEmailCounter;
          // this.userCounter.emailTotalCounter = this.userEmailCounter;

          // this.counter.updateCounter(this.adminCounter);
          // this.counter.updateCounter(this.userCounter);

          this.sendBtnClicked({ html: 'Email sent sucessfully ', classes: 'green', type: 'success' });
          // this.cdr.detectChanges();
          this.reset(models);
          // this.cancelSendBtnClicked();

          // this.toast({ html: 'Email sent sucessfully ', classes: 'green', type: 'success' });
        }
        else {
        }
      })
    }
    else if ((this.contactMsg == 'Email' && !this.EmailStatus)) {

      // this.sendBtnClicked({ html: 'The Subscribers doesnt allow the Email Preference ', classes: 'red', type: 'failure' });
      // this.cancelSendBtnClicked();

      // this.toast({ html: 'The Subscribers doesnt allow the Email Preference ', classes: 'red', type: 'failure' });

    } else if ((this.contactMsg == 'SMS' && this.SMSStatus)) {

      const smsModel = new SMSModel();
      if(this.setUp.general === 'buyNum'){
        smsModel.body = this.setUp.greetingMessage + ", \n";
      }else{
        smsModel.body = this.userLogged.GivenName + ", \n";
      }
      smsModel.body += this.helperService.truncateHTML(models.messageSMS, "1000");
      if(this.setUp.links && this.setUp.general === 'buyNum' && this.setUp.linkSocial){
        smsModel.body += this.setUp.links + "\n";
      }
    
      if (environment.firstNation.name !== "nationalchief" && this.setUp.general === 'existingNumber') {
        smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
        smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
      }else if(this.setUp.general === 'buyNum' && this.setUp.customizeUnsubscribe){
        smsModel.body += this.setUp.unSubscribe;
      }
      /*
      smsModel.body = this.helperService.truncateHTML(models.messageSMS, "1000");

      if (environment.firstNation.name !== "nationalchief") {
        smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
        smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
      }*/

      smsModel.from = this.availablePhone;
      smsModel.phoneNumberList.push(this.userLogged.cellPhone);
      // this.cancelSendBtnClicked();
      this.sMSService.sendSMS(smsModel).subscribe((res) => {
        if (res) {
          // this.sentSMSCount++;
          this.sendBtnClicked({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });

          // this.counter.updateCounter(this.userCounter);

          this.reset(models);
          this.sendBtnClicked({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
          // this.cdr.detectChanges();
          // this.cancelSendBtnClicked();
          // this.toast({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
        }
        else {
          this.sendBtnClicked({ html: 'Error occured sending SMS', classes: 'red', type: 'failure' });
          // this.cdr.detectChanges();
          // this.toast({ html: 'Error occured sending SMS', classes: 'red', type: 'failure' });
        }
      });
    } else if ((this.contactMsg == 'SMS' && this.SMSStatus)) {
      this.sendBtnClicked({ html: 'The Subscribers doesnt allow the SMS Preference ', classes: 'red', type: 'failure' });
      // this.cdr.detectChanges();
      // this.cancelSendBtnClicked();
      // this.toast({ html: 'The Subscribers doesnt allow the SMS Preference ', classes: 'red', type: 'failure' });

    }

  }

  get emailbody() { return this.contactForm.get('emailbody'); }
  get subject() { return this.contactForm.get('subject'); }
  get messageSMS() { return this.contactForm.get('messageSMS'); }
  get contact() { return this.contactForm.get('contact'); }

  sendBtnClicked(event) {
    // this.toast({ html: 'User Updated Successfully.', classes: 'green', type: 'success' });
    // this.emitted = event;
    // 
    this.toast(event);
    this.openContactModal = false;
  }


  reset(models) {
    this.contactForm.patchValue({
      subject: '',
      emailbody: this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff,
      messageSMS: this.setUp.messageSMS,
    });

  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

}
