<div class="flex flex-col w-full bg-white p-4 space-y-8 rounded-md">
    <div class="space-y-4">
        <p class="text-md text-darkGray font-bold">Admin rules</p>
        <div class="grid gap-4 w-full" >
            <div class="col-span-4">
               
                <label class="label cursor-pointer flex space-x-1 justify-start items-start">
                <!-- <input type="checkbox" class="w-8 checkbox checked:bg-customTeal" [checked]="allowPushNotification" (change)="allowPushNotification = !allowPushNotification"/> -->
                <input type="checkbox" class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                [checked]="allowPushNotification" (change)="allowPushNotification = !allowPushNotification"/>
                <span class="w-11/12 label-text text-left">Receive push notification</span> 
                </label>

                <!-- <div  *ngIf="n.name == 'employeeChange' && n.isEnabled" class="grid grid-cols-3 " >
                    <div *ngFor="let section of n.settings" class="pl-8">
                        <label class="label cursor-pointer space-x-1 flex">
                            <input type="checkbox" class="w-1/12 checkbox checked:bg-customTeal" [checked]="section.isEnabled" (change)="section.isEnabled = !section.isEnabled"/>            
                            <span class="label-text w-11/12">{{section.displayName}}</span> 
                        </label>
                    </div>
                </div>

                <div class="grid grid-cols-3" *ngIf="n.name == 'adminChange' && n.isEnabled">
                    <div *ngFor="let section of n.settings" class="pl-8 ">
                        <label class="label cursor-pointer space-x-1 flex">
                            <input type="checkbox" class="w-1/12 checkbox checked:bg-customTeal" [checked]="section.isEnabled" (change)="section.isEnabled = !section.isEnabled"/>            
                            <span class="label-text w-11/12">{{section.displayName}}</span> 
                        </label>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
    <app-button class="justify-end items-end" (click)="updateUser()" [customConfig]="{'text':'Save'}"></app-button>
</div>

<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
