<div class=" pt-6 font-body max-h-max pb-6 p-4 md:px-0">
  <div class="flex ">

    <!-- <button type="button" (click)="openDeleteModalOne()" class="flex justify-end px-4 py-2 my-4 text-white bg-red-500 border rounded-lg "
      >
      Delete Account
    </button></div></span> -->

    <form [formGroup]="surveyForm" (ngSubmit)="submitSurvey(surveyForm.value)" class="w-full">
      <div class="flex flex-col px-4 py-8 mx-auto bg-white rounded-md shadow-md md:px-10 md:w-2/3  ">
        <div class="mt-4 text-lg font-bold text-brandColor md:mt-0 flex space-x-2 py-2 space-y-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            class="w-6 h-6 stroke-customTeal ">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
          </svg>

          Edit {{names}}'s Account Information
        </div>
        <hr>


        <div class=" pt-4">
          <!-- Basic Inf0 -->
          <div>
            <div class="w-full grid-cols-2 gap-6 px-4 space-y-4 md:px-0 md:space-y-0 md:grid">
              <!-- First Name -->

              <div class="flex flex-col space-y-2">
                <label for=" " class="text-xs font-bold text-gray-500 "> First Name<span class="text-red-500">*</span>
                </label>
                <div class="relative flex flex-wrap items-stretch w-full mb-4">
                  <span
                    class="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                  </span>
                  <input [(ngModel)]="names" formControlName="firstName" type="text" placeholder="Jane"
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    maxlength="60" aria-label="Firstname" aria-describedby="basic-addon1" />
                </div>
                <!-- <input maxlength="60" [(ngModel)]="names"
              class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded focus:outline-gray-200 focus:shadow-outline w-96"
              formControlName="firstName" type="text" placeholder="Jane"> -->

                <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                  <small *ngIf="firstName.errors.required" class="text-red-500"> The First Name is required.
                  </small>
                </div>
              </div>

              <!-- Last Name -->
              <div class="flex flex-col space-y-2 ">
                <label for=" " class="text-xs font-bold text-gray-500 "> Last Name <span class="text-red-500">*</span>
                </label>
                <div class="relative flex flex-wrap items-stretch w-full mb-4">
                  <span
                    class="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                  </span>
                  <input formControlName="lastName" type="text" placeholder="John"
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    maxlength="60" aria-label="Lastname" aria-describedby="basic-addon1" />
                </div>
                <!-- <input
              class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded focus:outline-gray-200 focus:shadow-outline w-96"
              formControlName="lastName" type="text" placeholder="John"> -->

                <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                  <small *ngIf="lastName.errors.required" class="text-red-500"> The Last Name is required.
                  </small>
                </div>
              </div>
              <!-- Phone Number -->
              <div class="flex flex-col space-y-2">
                <label for=" " class="text-xs font-bold text-gray-500 "> Phone Number
                </label>
                <div class="relative flex flex-wrap items-stretch w-full mb-4">
                  <span
                    class="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    </svg>
                  </span>
                  <input type="text" id='cellPhone' formControlName="cellPhone" placeholder="000-000-0000"
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    aria-label="Phonenumber" aria-describedby="basic-addon1" />
                </div>
                <!-- <input type="text" [textMask]="{mask:phoneNumberMask}" id='cellPhone'
              class="w-full px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded focus:outline-gray-200 focus:shadow-outline w-96"
              formControlName="cellPhone" placeholder="000-000-0000" /> -->
                <!-- <div *ngIf="(cellPhone.dirty || cellPhone.touched) && cellPhone.invalid">
              <small *ngIf="cellPhone.errors.required" class="text-red-500"> Cell Phone is required.
              </small>
            </div> -->
                <small *ngIf="phoneExists" class="text-red-500"> The phone number already exists.
                </small>
                <small *ngIf="!phoneExists" class="text-red-500"></small>
              </div>
              <!-- Email  -->
              <div class="flex flex-col space-y-2 ">
                <label for=" " class="text-xs font-bold text-gray-500 "> Email
                </label>
                <div class="relative flex flex-wrap items-stretch w-full mb-4">
                  <span
                    class="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                  </span>
                  <input [readonly]=emailExists formControlName="email" type="text" placeholder="johnjane@gmail.com"
                    
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    aria-label="Email" aria-describedby="basic-addon1" />
                </div>
                <!-- <input  [readonly]=emailExists 
              class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded focus:outline-gray-200 focus:shadow-outline w-96"
              formControlName="email" type="text" placeholder="johnjane@gmail.com" required> -->
                <!-- <small *ngIf="exists" class="text-red-500"> The Email you entered already exists.
            </small> -->
                <div *ngIf="(email.dirty || email.touched) && email.invalid">
                  <small *ngIf="email.errors.required" class="text-red-500"> The Email is required.
                  </small>
                </div>
              </div>
            </div>
            <!-- Image -->
            <div class="form-control w-full  justify-between">
              <div class="flex flex-col px-4 py-8 md:flex-row w-full">
                <div class="flex flex-col space-y-4 ">
                  <span for=" " class="text-xs font-bold text-gray-500 "> Profile Picture</span>

                  <div *ngIf="!isMobile"
                    class="flex w-64 mt-2 justify-center h-32 bg-white rounded-lg border-dashed border-gray-200 border-2 gap-x-3">
                    <label class="custom-file-upload  self-center flex flex-col">
                      <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                      <input type="file" type="file" accept="image/*" (change)="handleFileInput($event.target.files)">
                      <span class="block font-bold text-base pt-1">Upload Image</span>
                    </label>

                  </div>

                  <div class="w-full" *ngIf="isMobile">
                    <button type="button"
                      class="py-4 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg w-full"
                      type="button" (click)="handleMobilePhotoInput()">
                      Upload Images
                    </button>
                  </div>
                </div>

                <div class="mx-8 mt-2" *ngIf="base64Image">
                  <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">
                    Image</p>
                  <div class="flex flex-wrap gap-8">

                    <div class="relative">
                      <img loading="lazy" [src]="base64Image" class="object-cover  w-48 h-28 rounded-md cover"
                        alt="Image">
                      <button (click)="removeModal()"
                        class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                        <i class="fa fa-trash text-md"></i>
                      </button>
                    </div>

                  </div>
                </div>

                <div class=" mx-8 mt-2" *ngIf="userModel && userModel.profileImgLink">
                  <!-- <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Previous Image</p> -->
                  <div class="flex flex-col ">
                    <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">
                      <span *ngIf="base64Image">Previous </span>Image
                    </p>
                    <div class="relative">
                      <img loading="lazy" [src]="userModel.profileImgLink"
                        class="object-cover w-48 h-28 rounded-md cover" alt="Image">
                      <button (click)="removeModal()" *ngIf="!base64Image"
                        class="absolute top-0 translate-x-40 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                        <i class="fa fa-trash text-md"></i>
                      </button>
                    </div>

                  </div>
                </div>
              </div>


              <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
                <strong><small><i class="material-icons">error_outline</i>
                    {{imgErrorMsg}}</small></strong>
              </p>
            </div>
          </div>

          <ng-container *ngIf="baseFields.length > 0 ">
            <!-- additional fields  -->
            <div class="flex flex-col space-y-4 pt-6">
              <div class="mt-4 text-lg font-bold text-brandColor md:mt-0 flex items-center space-x-2 ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  class="w-6 h-6 stroke-customTeal ">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>

                <span class="mt-4 text-lg font-bold text-brandColor md:mt-0 flex space-x-2">Additional Info</span>
              </div>
              <hr>
              <div class="w-full grid-cols-2 gap-6 px-4 space-y-4 md:px-0 md:space-y-0 md:grid">
                <div class="flex flex-col space-y-2 items-center justify-center" *ngFor="let field of baseFields">
                  <div class="flex w-full"
                    [ngClass]="{'flex-row-reverse justify-end items-center gap-1' : field.type === 'checkbox', 'flex-col space-y-2' : field.type != 'checkbox'}">
                    <label for=" " class="text-xs font-bold text-gray-500 "> {{field?.name}}<span class="text-red-500"
                        *ngIf="field?.required">*</span> </label>
                    <div class="flex " [ngClass]="{'flex-wrap items-stretch w-full mb-4' : field.type !== 'checkbox'}">
                      <!-- dropdown -->
                      <select *ngIf="field.type === 'dropdown'"
                        class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]"
                        [formControlName]="field?.key" [placeholder]="field?.name">
                        <option *ngFor="let option of field.options" [value]="option">{{ option?.option }}
                        </option>
                      </select>


                      <!-- text area -->
                      <textarea *ngIf="field.type == 'textarea'"
                        class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]"
                        [formControlName]="field?.key" [placeholder]="field?.name"></textarea>

                      <input type="checkbox" *ngIf="field.type == 'checkbox'" [formControlName]="field?.key"
                        id="acceptTerms" class="form-check-input" />

                      <input *ngIf=" field.type !='dropdown' && field.type !='textarea' && field.type !='checkbox'"
                        class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]"
                        [formControlName]="field?.key" [type]="field?.type" [placeholder]="field?.name">
                    </div>
                    <!-- <input [formControlName]="field?.key" [type]="field?.type" [placeholder]="field?.name"
                      class="m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.40rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary" /> -->
                  </div>
                  <div *ngIf="(surveyForm.controls[field?.key].touched)" class="w-full">
                    <small *ngIf="surveyForm.controls[field?.key].errors?.required" class="text-red-500 w-full "> The
                      {{field?.name}} is required.
                    </small>
                    <small
                      *ngIf="surveyForm.controls[field?.key].errors?.minlength || surveyForm.controls[field?.key].errors?.min"
                      class="text-red-500 w-full"> {{
                      getMinLengthErrorMessage(field) }}
                    </small>
                    <small
                      *ngIf="surveyForm.controls[field?.key].errors?.maxlength || surveyForm.controls[field?.key].errors?.max"
                      class="text-red-500 w-full"> {{
                      getMaxLengthErrorMessage(field) }}
                    </small>
                    <small *ngIf="(surveyForm.controls[field?.key].errors?.email)" class="text-red-500 w-full">
                      Please add valid email!
                    </small>
                    <small *ngIf="(surveyForm.controls[field?.key].errors?.invalidPhoneNumber)"
                      class="text-red-500 w-full">
                      Please add valid phone number!
                    </small>
                    <small *ngIf="surveyForm.controls[field?.key].errors?.minDate" class="text-red-500 w-full"> The date
                      must
                      be after {{ surveyForm.controls[field?.key].errors?.minDate?.value }}.
                    </small>
                    <small *ngIf="surveyForm.controls[field?.key].errors?.maxDate" class="text-red-500 w-full "> The
                      date must
                      be before {{ surveyForm.controls[field?.key].errors?.maxDate?.value }}.
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>


        </div>



        <div formArrayName="answerItems">

          <div *ngFor="let item of answers; let i = index;">

            <div [formGroupName]="i">

              <div class="mt-4">
                <div class="row card-content">
                  <span class="mb-2 text-xs font-bold text-gray-500">
                    <!-- <strong class="text-blue-500">
                      {{item.controls.questionNumber.value}}
                      : </strong>  -->
                    {{item.controls.question.value | titlecase}} <span class="text-red-500"
                      *ngIf="item.controls.required.value">*</span> :
                  </span>
                  <br />

                  <div class="flex flex-col space-y-2 md:w-1/2"
                    *ngIf="item.controls.questionType.value === 'Short Answer'">
                    <!-- <div class="flex flex-col space-y-2 md:w-96"> -->
                    <input id="answer" type="text" formControlName="answer" [required]="item.controls.required.value"
                      class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded validate focus:outline-gray-200 focus:shadow-outline w-96"
                      autocomplete="off" />

                    <div *ngIf="(item.controls.answer.dirty ||
                                  item.controls.answer.touched) &&
                                  item.controls.answer.invalid">
                      <small *ngIf="item.controls.answer.errors.required" class="text-red-500">
                        {{item.controls.question.value}} is
                        required.
                      </small>
                    </div>
                    <!-- </div> -->
                  </div>

                  <div class="flex flex-col space-y-2 md:w-1/2" *ngIf="item.controls.questionType.value === 'Date'">
                    <!-- <div class="flex flex-col mt-2 space-y-2 md:w-96"> -->
                    <input type="date" id="birthday" name="birthday" formControlName="answer"
                      [required]="item.controls.required.value"
                      class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded focus:outline-gray-200 focus:shadow-outline w-96">

                    <div *ngIf="(item.controls.answer.dirty ||
                                  item.controls.answer.touched) &&
                                  item.controls.answer.invalid">
                      <small *ngIf="item.controls.answer.errors.required" class="text-red-500">
                        {{item.controls.question.value}} is
                        required.
                      </small>
                    </div>
                    <!-- </div> -->
                  </div>


                  <div class="flex flex-col space-y-2 md:w-1/2" *ngIf="item.controls.questionType.value === 'Number'">
                    <!-- <div class="flex flex-col mt-2 space-y-2 md:w-96"> -->
                    <input type="number" id="quantity" name="quantity" formControlName="answer"
                      [required]="item.controls.required.value"
                      class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded focus:outline-gray-200 focus:shadow-outline w-96">

                    <div *ngIf="(item.controls.answer.dirty ||
                                  item.controls.answer.touched) &&
                                  item.controls.answer.invalid">
                      <small *ngIf="item.controls.answer.errors.required" class="text-red-500">
                        {{item.controls.question.value}} is
                        required.
                      </small>
                    </div>
                    <!-- </div> -->
                  </div>


                  <div *ngIf="item.controls.questionType.value === 'Open Ended'">
                    <div class="flex flex-col mt-2 space-y-2 md:w-full">
                      <textarea type="text" id="longAnswer" formControlName="answer"
                        [required]="item.controls.required.value"
                        class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded h-14 focus:outline-gray-200 focus:shadow-outline "></textarea>
                      <div *ngIf="(item.controls.answer.dirty ||
                                  item.controls.answer.touched) &&
                                  item.controls.answer.invalid">
                        <small *ngIf="item.controls.answer.errors.required" class="text-red-500">
                          {{item.controls.question.value}} is required.
                        </small>
                      </div>
                    </div>
                  </div>


                  <div *ngIf="item.controls.questionType.value === 'Multiple Choice'">

                    <div formArrayName="answers"
                      *ngFor="let ans of surveyForm.get('answerItems').controls[i].get('answers').controls; let j = index;">

                      <div class="flex mb-2 space-x-8 space-y-4" [formGroupName]="j"
                        *ngIf="!model.questions[i]?.allowMultipleAnswers">
                        <label class="flex space-x-8">
                          <!-- [checked]="answer"-->
                          <input name="answer" value="{{ans.controls.answer.value}}"
                            [checked]="multipleOne == ans.controls.answer.value"
                            (change)="answerSelected(i,ans.controls.answer.value)" type="radio"
                            class="radio checked:bg-customTeal" />
                          <span><strong>{{ans.controls.answer.value | titlecase}}</strong></span>
                        </label>
                      </div>

                      <div class="flex mb-2 space-x-8 space-y-4" [formGroupName]="j"
                        *ngIf="model.questions[i].allowMultipleAnswers">
                        <label class="flex space-x-8">
                          <!-- [checked]="multipleTwo[j] == ans.controls.answer.value" -->
                          <input name="answer" value="{{ans.controls.answer.value}}"
                            (change)="answerSelected(i,ans.controls.answer.value)" type="checkbox"
                            class="checkbox checked:bg-customTeal" />
                          <span><strong>{{ans.controls.answer.value | titlecase}}</strong></span>
                        </label>
                      </div>
                    </div>
                  </div>



                </div>

                <!-- <div class="card-action">
                  <div class="flex justify-between">
                    <div class="" *ngIf="i === 0">
                      <button type="button" class="px-4 py-2 my-4 bg-white border rounded-lg text-customTeal border-customTeal" (click)="backToList()">
                        <i class="float-left material-icons">cancel</i> Cancel
                      </button>
                    </div>
                    <div class="" *ngIf="i !== 0 && i != (totalQuestions - 1)">
                      <button type="button" class="px-4 py-2 my-4 bg-white border rounded-lg text-customTeal border-customTeal" (click)="previous(i)">
                        <i class="float-left material-icons">arrow_back_ios</i> Previous
                      </button>
                    </div>
      
                    <div class="" *ngIf="i < totalQuestions - 1">
                      <button type="button" class="px-4 py-2 my-4 bg-white border rounded-lg text-customTeal border-customTeal" (click)="continue(i)"
                        [disabled]="!item.valid">
                        Continue <i class="float-right material-icons">arrow_forward_ios</i>
                      </button>
                    </div>  
                  </div>
                </div> -->

              </div>
            </div>
          </div>

        </div>
        <div class="row card white col s12">

          <div class="card-action">
            <div class="flex justify-between">
              <!-- <div class="">
                        <button type="button" class="px-4 py-2 my-4 bg-white border rounded-lg text-customTeal" (click)="prev()">
                            <i class="float-left material-icons">arrow_back_ios</i> Previous
                        </button>
                    </div> -->

              <!-- <div  *ngIf="currentUser.role == 'Admin'" class="flex justify-end space-x-2">
                <button type="submit" class="flex justify-end px-4 py-2 my-4 text-white border rounded-lg bg-customTeal"
                  [disabled]="!surveyForm.valid && exists">
                  Update Client 
                </button>
              </div> -->
              <div *ngIf="role == 'Admin'" class="flex justify-end w-full space-x-2">
                <button type="submit"
                  class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                  Update Client
                </button>
              </div>
              <div *ngIf="role === 'User'" class="flex justify-end w-full space-x-2">
                <button type="submit"
                  class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  [disabled]="!surveyForm.valid && exists">
                  Update Profile
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- && !exists && !phoneExists && (role == 'Admin') && !invitationPopup -->
      <div id="my-modal-confirmationPopup" *ngIf="confirmationPopup && surveyForm.valid" class="modal"
        [ngClass]="{'modal-open': confirmationPopup, 'modal-action': !confirmationPopup }">
        <div class="modal-box">

          <div class="modal-content">
            <h3 class="mb-2 text-lg font-bold text-center">Update Client</h3>
            <hr />
            <p class="mt-3 text-center">Are you sure you want to update {{names}}'s profile?</p>

            <div class="items-center justify-between m-4 modal-action">
              <label (click)="cancelConfirmation()" [disabled]="!surveyForm.valid"
                class="px-4 py-2 text-black border-2 rounded-md border-customTeal ">Cancel</label>
              <button type="submit" [disabled]="!surveyForm.valid" (click)="uploadClientInfo()"
                class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal ">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>




<!-- <div id="my-modal-deleteModal" *ngIf="deleteModal" class="modal"
  [ngClass]="{'modal-open': deleteModal, 'modal-action': !deleteModal }">
  <div class="modal-box">

    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Delete My Account</h3>
      <hr />
      <p class="mt-3 text-center"> <span class="font-bold">{{names | titlecase}},</span> are you sure you want to delete your account?</p>
      <p class="mt-3 text-center" *ngIf="!exists">If you delete your account you will not be able to login again to {{appName}}. <span class="font-bold">Do you want to proceed?</span></p>

      <div class="items-center justify-between m-4 modal-action">
        <label (click)="closeDeleteModal()" 
          class="px-4 py-2 text-black border-2 rounded-md border-customTeal ">Cancel</label>
        <label (click)="beforeDelete()" 
          class="px-4 py-2 text-white bg-red-500 border-red-500 rounded-md ">Delete Account</label>
      </div>
    </div>
  </div>
</div> -->

<!-- Modals -->
<div id="my-modal-disable" *ngIf="updateModal" class="modal"
  [ngClass]="{'modal-open': updateModal, 'modal-action': !updateModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Subscribers Profile Update</h3>
      <hr />
      <p class="mt-3 text-center"><span class="font-bold">{{userModel.GivenName |
          titlecase}} </span> have successfully updated profile .</p>
    </div>
  </div>
</div>

<!-- <div id="my-modal-delete" *ngIf="beforeDeleteModal" class="modal"
  [ngClass]="{'modal-open': beforeDeleteModal, 'modal-action': !beforeDeleteModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Your profile has been deleted</h3>
      <hr />
      <p class="mt-3 text-center"><span class="font-bold">You wont be able to login into the account. </span>Hope you will get back.</p>
    </div>
  </div>
</div> -->


<div id="my-modal-confirmDeleteModal" *ngIf="confirmDeleteModal" class="modal"
  [ngClass]="{'modal-open': confirmDeleteModal, 'modal-action': !confirmDeleteModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Delete My Account</h3>
      <hr />
      <p class="mt-3 text-center"> <span class="font-bold">{{names | titlecase}},</span> are you sure you want to
        delete
        your account?</p>
      <p class="mt-3 text-center">If you delete your account you will not be able to login again to {{appName}}.
        <span class="font-bold">Do you want to proceed?</span>
      </p>

      <div class="items-center justify-between m-4 modal-action">
        <label (click)="closeDeleteModalOne()"
          class="px-4 py-2 text-black border-2 rounded-md border-customTeal ">No</label>
        <label (click)="openSecondModalTwo()"
          class="px-4 py-2 text-white bg-red-500 border-red-500 rounded-md ">Yes</label>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-confirmSecondModal" *ngIf="confirmSecondModal" class="modal"
  [ngClass]="{'modal-open': confirmSecondModal, 'modal-action': !confirmSecondModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Delete My Account</h3>
      <hr />
      <p class="mt-3 text-center"> Once you confirm you will not be able to log in to {{appName}}</p>
      <p class="mt-3 text-center"> <span class="font-bold">Do you want to proceed?</span></p>

      <div class="items-center justify-between m-4 modal-action">
        <label (click)="closeSecondModalTwo()"
          class="px-4 py-2 text-black border-2 rounded-md border-customTeal ">No</label>
        <label (click)="openThreeModal()" class="px-4 py-2 text-white bg-red-500 border-red-500 rounded-md ">Yes</label>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-confirmThreeModal" *ngIf="confirmThreeModal" class="modal"
  [ngClass]="{'modal-open': confirmThreeModal, 'modal-action': !confirmThreeModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Delete My Account</h3>
      <hr />
      <p class="mt-3 text-center"> Are you sure?</p>
      <div class="items-center justify-between m-4 modal-action">
        <label (click)="closeThreeModal()"
          class="px-4 py-2 text-black border-2 rounded-md border-customTeal ">Cancel</label>
        <label (click)="beforeDelete()"
          class="px-4 py-2 text-white bg-red-500 border-red-500 rounded-md ">Delete</label>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-emailChangeModal" *ngIf="emailChangeModal" class="modal"
  [ngClass]="{'modal-open': emailChangeModal, 'modal-action': !emailChangeModal }">
  <div class="max-w-2xl modal-box">

    <div (click)="closeEmailChangeModal()" class="flex justify-end cursor-pointer"><i
        class="px-3 py-3 bg-gray-200 rounded-full material-icons">close</i></div>

    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Change Email</h3>
      <hr />
      <p class="mt-3 text-center"> In order to change your email you have to input your password.</p>
      <div class="flex flex-col items-center w-full modal-action">
        <div class="flex flex-col mb-2 space-y-2 md:w-1/2">
          <label class="font-bold">New Email</label>
          <input maxlength="60" [(ngModel)]="emailChanged" [readOnly]=true
            class="px-3 py-3 my-2 leading-tight text-gray-700 bg-gray-100 border rounded md:w-96 focus:outline-gray-200 focus:shadow-outline w-96"
            type="text" placeholder="JaneJohn@gmail.com">
        </div>

        <div class="flex flex-col space-y-2 md:w-1/2">
          <label class="font-bold">Your Password</label>
          <input [(ngModel)]="password"
            class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded md:w-96 focus:outline-gray-200 focus:shadow-outline w-96"
            type="text" placeholder="********">
          <!-- <input type="password" #passwordfield
               placeholder="*************"
               required 
               autocomplete="off"
               [(ngModel)]='password' required 
               class="w-11/12 input input-bordered"
               [ngClass]="{
                 'border-red-500': (password.dirty || password.touched) && password.invalid,
                 'border-gray-200': !((password.dirty || password.touched) && password.invalid)
               }"
                 />
               <span (click)="passwordfield.type=passwordfield.type=='password'?'text':'password'"
                 class="items-center justify-center w-1/12 p-2 pt-3 bg-white border-gray-200 fa fa-fw field-icon toggle-password"
                 [ngClass]="(passwordfield.type=='password')?' fa-eye-slash':' fa-eye'">
               </span> -->
        </div>

      </div>
      <div class="flex justify-end"><button (click)="saveNewEmail()"
          class="px-4 py-2 text-white bg-customTeal">Update</button></div>
    </div>
  </div>
</div>

<div id="my-modal-delete" *ngIf="beforeDeleteModal" class="modal"
  [ngClass]="{'modal-open': beforeDeleteModal, 'modal-action': !beforeDeleteModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="mb-2 text-lg font-bold text-center">Your Account has been deleted</h3>
      <hr />
      <p class="mt-3 text-center"> Have a great day.</p>
    </div>
  </div>
</div>



<app-settings-user></app-settings-user>