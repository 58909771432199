import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { FCMTokenModel } from "src/app/models/FCMTokenModel";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FCMTokenService {
  tokenListRef: AngularFirestoreCollection<any>;
  tokenRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) {}

  saveUserToken(model: FCMTokenModel) {
    model.uid = this.db.createId();
    this.db.collection("FCMNewTokens").doc(model.uid).set(model);
  }

  getTokenList(): AngularFirestoreCollection<FCMTokenModel> {   
    this.tokenListRef = this.db.collection("FCMNewTokens");
    return this.tokenListRef;
  }

  getUsersTokenList(userIds): AngularFirestoreCollection<FCMTokenModel> {

  // Divide the userIds array into groups of 20
  const chunkSize = 20;
  const userIdsChunks = [];
  for (let i = 0; i < userIds.length; i += chunkSize) {
    userIdsChunks.push(userIds.slice(i, i + chunkSize));
  }

  // Query for each chunk separately
  const tokenListRefs = [];
  for (const userIdsChunk of userIdsChunks) {
    const tokenListRef = this.db.collection("FCMNewTokens", (ref) => ref.where("userId", "in", userIdsChunk));
    tokenListRefs.push(tokenListRef);
  }

  // Merge the results
  const mergedTokenListRef = tokenListRefs.reduce(
    (acc, cur) => acc ? acc.ref.firestore._delegate._query.concat(cur.ref.firestore._delegate._query) : cur,
    null
  );
  
  return mergedTokenListRef;
  }


  getTokenByUserId(id: string): AngularFirestoreCollection<FCMTokenModel> {

    
    this.tokenListRef = this.db.collection("FCMNewTokens", (ref) =>
      ref.where("userId", "==", id)
    );
    return this.tokenListRef;
  }

  checkIfTokenExist(token: string, userId: string) {
    this.tokenListRef = this.db.collection('FCMNewTokens', ref => ref.where("token", "==", token).where("userId", "==", userId));

    return  this.tokenListRef.get().pipe(
      map(querySnapshot => querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data() })))
    );

    
  }

  // delete users token when user logs out 
  deleteUserFCMToken(token: string) {
    const query = this.db.collection('FCMNewTokens', ref => ref.where('token', '==', token));
    query.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(documentSnapshot => {
        documentSnapshot.ref.delete();
      });
    }).catch(error => {
      console.error('Error removing documents:', error);
    });
  }

  // 
  getUsersToken(users): any {

    this.tokenListRef = this.db.collection('FCMNewTokens', ref => ref.where("userId", "in", users));
    return this.tokenListRef.get().pipe(
      map(querySnapshot => querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data() })))
    );
  }

  updateUserRegistrationToken(uuid: any,tokens: any) {
    this.db.collection("Users").doc(uuid).update({
      registrationToken:tokens
    });
  }

  addUserRegistrationToken(uuid: any,tokens: any) {
    this.db.collection("FCMNewTokens").doc(uuid).set({
      registrationToken:tokens
    });
  }
}
