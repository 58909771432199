import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { GetAppService } from './../../../../services/firebase/get-app.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { addDays } from 'date-fns';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/services/helper/helper';
import { EnvironmentService } from 'src/app/services/helper/env';

@Component({
  selector: 'app-app-request-detail',
  templateUrl: './app-request-detail.component.html',
  styleUrls: ['./app-request-detail.component.css']
})
export class AppRequestDetailComponent implements OnInit {

  requestId: any;
  request: any;
  acceptOpenModal = false;
  declineOpenModal = false;
  declinedForm: UntypedFormGroup;
  acceptanceForm: UntypedFormGroup;
  acceptanceFormModalOpen = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  isAcceptingPayment;
  // isNationApp = this.envService.isNationApp
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  showAppConfig=false;
  extension = this.envService.link

  constructor(private route: ActivatedRoute,
              private getAppService: GetAppService,
              private location: Location,
              private fb: UntypedFormBuilder,
              private helperService:HelperService,
              private envService: EnvironmentService,
    ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.requestId = params.get('id');
    });
    this.declinedForm = this.fb.group({
      reason: ['', [Validators.required]],
    });
    this.acceptanceForm = this.fb.group({
      acceptsPayment: [, Validators.required],
      paymentPeriod: [''],
      amount: [''],
      paymentDate: [''],
      trialPeriod: [''],
      paymentStartDate: [''],
    });

    this.getAppService.getRequestById(this.requestId).valueChanges().subscribe((requests) => {
      if (requests.length > 0){
        this.request = requests[0];
        if (this.request.status === 'Accepted'){
          this.showAppConfig = true;
          this.isAcceptingPayment = this.request.acceptsPayment;
          this.acceptanceForm.patchValue({
            acceptsPayment: this.request.acceptsPayment,
            paymentPeriod: this.request.paymentPeriod,
            amount: this.request.amount,
            paymentDate: this.request.paymentDate,
            trialPeriod: this.request.trialPeriod ? this.request.trialPeriod : '',
            paymentStartDate: this.request.paymentStartDate ? this.request.paymentStartDate : ''
          });
        }
      }
    });
  }
  get reason(){ return this.declinedForm.get('reason'); }
  get trialPeriod(){ return this.acceptanceForm.get('trialPeriod'); }

  backClicked(){
    this.location.back();
  }
  addToPaymentStartDate(){
    const today = new Date();
    const afterTrial = addDays(today, this.trialPeriod.value);
    this.acceptanceForm.patchValue({
      paymentStartDate: this.formatDate(afterTrial),
      amount: 3
    });
  }
  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  acceptRequest(){
    this.request.status = 'Accepted';
    // close accept modal and open the form
    this.acceptOpenModal = false;
    this.acceptanceFormModalOpen = true;
  }
  submitAcceptanceForm(value){
    this.request.acceptsPayment = value.acceptsPayment;
    this.request.paymentPeriod = value.paymentPeriod;
    this.request.amount = value.amount;
    this.request.paymentDate = value.paymentDate;
    this.request.trialPeriod = value.trialPeriod;
    this.request.paymentStartDate = value.paymentStartDate;
    this.getAppService.updateRequest(this.request).then(() => {
      this.closeModal();
      this.toast({html: 'App info saved successfully!', classes: 'green', type: 'success'});

    });
  }
  declineRequest(value){
    this.request.status = 'Rejected';
    this.request.declineMessage = value.reason;
    this.getAppService.updateRequest(this.request).then(() => {
      this.closeModal();
      this.toast({html: 'App request declined!', classes: 'red', type: 'failure'});
    });
  }

  openAcceptRequestModal(){
    this.acceptOpenModal = true;
  }

  openDenyRequestModal(){
    this.declineOpenModal = true;
  }

  closeModal(){
    this.acceptOpenModal = false;
    this.declineOpenModal = false;
    this.acceptanceFormModalOpen = false;
  }

toast(obj){
  this.toastMessage = obj.html;
  this.toastClass = obj.classes ? obj.classes : 'green' ;
  this.toastType = obj.type ? obj.type : 'success';
  this.openToast = true;
  setTimeout(() => {
    this.openToast = false;
  }, 2000);
}
}
