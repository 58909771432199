import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private httpClient: HttpClient,private keyManager: ApiKeyManagerService) { }

  
  updateApps(): any {
    //const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    this.httpClient.get<any[]>( 'https://api.goingmobile.app/api/AppManagment/updateApps').subscribe(x => {
      if(x) {
        return x;        
      } 
    });    
  }

   
  createApp(): any {
     var request = { 
      "content": "what is the name of the us president",
      "model": "",
  }
  
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    headers.append('API-KEY', this.keyManager.getKey(),)
    this.httpClient.post<any[]>( 'https://localhost:44318/api/OpenAI/StartChat',JSON.stringify(request), {headers}).subscribe(x => {
      if(x) {        
        return x;        
      } 
    });


  }
}
