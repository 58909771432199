import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { Location } from '@angular/common';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { MenuSettingsService } from 'src/app/services/firebase/menu-settings.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { environment } from 'src/environments/environment';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';


@Component({
  selector: 'app-employee-main',
  templateUrl: './employee-main.component.html',
  styleUrls: ['./employee-main.component.css']
})
export class EmployeeMainComponent implements OnInit {
  scrWidth: number;
  route: string;
  id: any;
  currentUser: any;
  darkTheme: any;
  openSidebar = false;
  minimizedMenu = false;
  user: any;
  appLogo: any;
  settings:any;
  permission: any;
  perm: any;
  subscriptionsActive: any;
  menu;
  servicesName;
  productsName;
  model: any;
  nationOfuse = environment.firstNation.name;
  foundSpecial: boolean = false;
  form: any;
  clientGlobal : any;

  openSubscribersViewCollapse = false;
  openManageAppCollapse = false;
  openOrganizationCollapse = false;
  openSubscribersMgtCollapse = false;
  // constructor(private session: SessionsMonitorService, private fCMpushService: FCMpushService) {
  //   if (typeof window['cordova'] !== 'undefined') {
  //     this.fCMpushService.getToken();
  //     this.fCMpushService.onTokenRefresh();
  //   }
  //  }

  permMember= [];
  permPES = [];
  permSocial= [];
  permAnalytics= [];
  permChat= [];
  permissionSpecial= [];
  theme = 'light';
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false
  constructor(private session: SessionsMonitorService,
              public router: Router,
              private location: Location,
              private authService: AuthService,
              private userService: UserService,
              public menuService: MenuSettingsService,
              private productsServiceMenuService: ProductsServiceMenuService,
              private appSettingService: AppSettingsService,
              private permissionService: PermissionService,
              private clientFormService: ClientFormService,
              private envService: EnvironmentService,
              private customSectionService: CustomSectionService
    ) {

    // this.router.events.subscribe((_: NavigationEnd) => this.route = _.url);
    // this.scrWidth = window.innerWidth;
    this.router.events.subscribe((_: NavigationEnd) => this.route = _.url);
    this.scrWidth = window.innerWidth;
    // this.id = this.authService.getGlobalUser().uid;
    this.id = this.authService.getCurrentUser().uid;
   }

  ngOnInit() {
    if (this.id){
      this.userService.getUserById(this.id).valueChanges().subscribe(user => {
        this.currentUser = user[0];
        this.user = this.currentUser;
        this.darkTheme = this.currentUser.darkTheme ? this.currentUser.darkTheme : false;
      });

        this.permissionService.getPermissionByEmployeeId(this.id).valueChanges().subscribe(perm => {
          if (perm && perm.length !== 0){
            this.perm = perm[0];
            //this.permission = this.perm.permission[0];

            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permissionSpecial = this.perm.permission[4];
            this.permChat = this.perm.permission[5];
          }
        });

        this.clientGlobal = this.clientFormService.getFormList().valueChanges().subscribe(form => {
          this.form = form[0];
          this.clientGlobal.unsubscribe();
        })
      

      this.appSettingService.getAppSettingsList().valueChanges().subscribe(menu => {
        if (menu && menu.length > 0){
          this.model = menu[0];
          this.settings = menu[0];
          this.appLogo = this.settings.logo ? this.settings.logo : '';

          if (this.settings.isBusinessApp){
            this.productsServiceMenuService.productsName.subscribe(p => {
              this.productsName = p;
            });
            this.productsServiceMenuService.servicesName.subscribe(p => {
              this.servicesName = p;
            });
          }
        }
      });

    }
  }
  toggleSideBar(){
    this.openSidebar = !this.openSidebar;
  }
  toggleMinimizeMenu(){
    this.minimizedMenu = !this.minimizedMenu;
  }
  back(){
    this.location.back();
  }
  forward(){
    this.location.forward();
  }

  signOut() {
    this.authService.signOut();
  }

  // closeSideBar(){
  //   this.openSidebar = false;
  // }
  backButton(){
    this.location.back();
  }
  navigate(){
    if(!this.form){
      this.router.navigate(['/employee/subscriber-form'])

    }else{
      this.router.navigate(['/employee/edit-subscriber'])

    }
  }
  closeSideBar(){
    this.openSidebar = false;
    // this.communicationsActive = false;
    this.subscriptionsActive = false;
  }
  redirectToProduct(){
    this.router.navigate(['/employee/view-products']);
  }

  redirectToServices(){
    this.router.navigate(['/employee/services']);
  }

  navigateVerification(){
    this.router.navigate(['/employee/verify-account-request']);
  }
  editProfile(){
    this.router.navigate(['/employee/employee-edit', this.id])
  }

  closeAllCollapse() {
    this.openSubscribersViewCollapse = false;
    this.openManageAppCollapse = false;
    this.openOrganizationCollapse = false;
    this.openSubscribersMgtCollapse = false;
  }
  selectCollapseToOpen(setupName) {
    if (setupName === 'SubscribersView') {
      if (this.openSubscribersViewCollapse === false) {
        this.closeAllCollapse();
        this.openSubscribersViewCollapse = true;
      } else {
        this.openSubscribersViewCollapse = false;
      }
    }
    if (setupName === 'ManageApp') {
      if (this.openManageAppCollapse === false) {
        this.closeAllCollapse();
        this.openManageAppCollapse = true;
      } else {
        this.openManageAppCollapse = false;
      }
    }
    if (setupName === 'Organization') {
      if (this.openOrganizationCollapse === false) {
        this.closeAllCollapse();
        this.openOrganizationCollapse = true;
      } else {
        this.openOrganizationCollapse = false;
      }
    }
    if (setupName === 'SubscribersManagement') {
      if (this.openSubscribersMgtCollapse === false) {
        this.closeAllCollapse();
        this.openSubscribersMgtCollapse = true;
      } else {
        this.openSubscribersMgtCollapse = false;
      }
    }
  }
  redirectToSectionPage(section){
    this.customSectionService.updateFromMenu(section.originalName);
    this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe((customSection: any) => {
      if (customSection.length > 0) {
        this.router.navigate(['/employee/custom-section-list/', customSection[0].id])
      }
    })
  }
}
