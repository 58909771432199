import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';
import { RequesttojoinService } from 'src/app/services/firebase/requesttojoin.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { HelperService } from 'src/app/services/helper/helper';
import { environment } from 'src/environments/environment';


// import { toast } from 'materialize-css';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

@Component({
  selector: 'app-register-contact-from-invite',
  templateUrl: './register-contact-from-invite.component.html',
  styleUrls: ['./register-contact-from-invite.component.css']
})
export class RegisterContactFromInviteComponent implements OnInit {
  isPrivacy: boolean;
  id: any;
  invitedUserEmail: any;

  registrationForm: UntypedFormGroup;
  inviteCodeForm: UntypedFormGroup;
  canadianNumber = ['^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$'];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  hasCode = false;
  invitation: any;
  invitedUserRole: any;
  invitedcellPhone: String = '';

  appName: any;
  model: any;
  themeColor: any;
  appDescription = '';
  appLogo: any;
  noAdmin = true;
  admins = [];
  defaultThemeColor = environment.appTheme.themeColor;

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  requestId: any;
  requestCopy = [];
  isVerifyUsers: boolean;

  //Added for employee
  jobTitle: any;
  extension: any;
  firstNameInvite: any;
  lastNameInvite: any;
  inviteId: any;
  appointment: any;
  biography: any;
  workPhone: any;
  contact: any;
  profileImgLink: any;
  showEmployee: any;

  //Added from Subscribers
  DOB: any;

  greetings: any;
  signOff: any;
  messageSMS: any;
  nationOfUse = environment.firstNation.name;

  // for the inputs to be editable
  isEditable = false;
  // visible
  showPassword = false;
  notifSettings

  signupSettings;
  sideImage;
  backDropColor;
  user;
  permission: any;
  inputvalue = '';
  inputLastvalue = '';
  empSchedule: [];
  constructor(private inviteService: InviteService,
    private route: ActivatedRoute,
    public router: Router,
    private fb: UntypedFormBuilder, private authService: AuthService,
    private appSettingsService: AppSettingsService,
    private settingsService: AppSettingsService,
    private requesttojoin: RequesttojoinService,
    private helperervice: HelperService,
    private userService: UserService,
    private notificationPreferenceService: NotificationPreferenceService
  ) {

    // get all admins
    this.userService.getAllAdmins().valueChanges().subscribe(admins => {
      if (admins) {
        if (admins.length > 0) {
          this.noAdmin = false;
          this.admins = admins;
        }
      }
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.appName = this.model.appName;
            this.appDescription = this.model.description;
            this.appLogo = this.model.logo;
            this.isVerifyUsers = this.model.verifyUsers;
          }
        }
      }
    );
    this.settingsService.getSignupSettingsList().valueChanges().subscribe(s => {
      if (s) {
        if (s.length > 0) {
          this.signupSettings = s[0]
          this.sideImage = this.signupSettings.logo && this.signupSettings.logo != '' ? this.signupSettings.logo : ""
          this.backDropColor = this.signupSettings.backDropColor
        }
      }
    })
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    // this.inviteCodeForm = this.fb.group({
    //   inviteCode: ['', [Validators.required]]
    // });

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((appSettings: any) => {
      if (appSettings.length > 0) {
        this.appName = appSettings[0].appName;
      }
    });

    this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.notifSettings = x[0]
      }
    })

    this.registrationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      middleName: [''],
      cellPhone: [''],
      gender: [''],
      email: ['', [Validators.required, Validators.email]],
      // address: [''],
      // city: [''],
      // officeNumber: [''],
      // takeAppointment: [''],
      //isBusinessAccount: [false],
      createPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
      privacyPolicy: [false, [Validators.required]],
    });

    if (this.id) {
      this.userService.getUserById(this.id).valueChanges().subscribe((user: any) => {
        if (user.length > 0) {
          this.hasCode = true;
   
          this.invitation = user[0];
          this.registrationForm.patchValue({
            firstName: this.invitation?.GivenName ? this.invitation?.GivenName  : '',
            lastName: this.invitation?.LastName ? this.invitation?.LastName  : '',
            email: this.invitation?.Email ? this.invitation?.Email  : '',
            cellPhone:this.invitation?.cellPhone ? this.invitation?.cellPhone  : '',
          });
        }
      });


    }

  }
  get firstName() { return this.registrationForm.get('firstName'); }
  get middleName() { return this.registrationForm.get('middleName'); }
  get lastName() { return this.registrationForm.get('lastName'); }
  get email() { return this.registrationForm.get('email'); }
  get cellPhone() { return this.registrationForm.get('cellPhone'); }
  get createPassword() { return this.registrationForm.get('createPassword'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }
  get privacyPolicy() { return this.registrationForm.get('privacyPolicy'); }
  
  register(data: any) {
    const postObj = {
      // cellPhone: this.invitedcellPhone ? this.invitedcellPhone :data.cellPhone,
      cellPhone: data.cellPhone,
      Email: data.email,
      GivenName: data.firstName,
      verified: false,
      LastName: data.lastName,
      contact: this.contact,
    };
      // this.userService.getUserByEmail(data.email).valueChanges().subscribe((user) => {  
      //           if(user && user.length != 0){
      //             this.user = user;
      //             this.toast({ html: "The user already exists", classes: 'red', type: 'failure' });

      //           }    
      // })          

      this.authService.emailSignUp(postObj, data.createPassword).then(async () => {
        // UPDATE INVITATION
        let fullName = data.firstName + ' ' + data.lastName

        // this.sendPushNotificationToOwner(data, fullName)

        if (this.invitation && this.id) {
          // this.inviteService.updateInvite(this.invitation);
          this.requesttojoin.getRegistrationRequestByEmail(this.email.value).valueChanges().subscribe((request) => {
            if (request.length > 0) {
              this.requestCopy = request;
              this.requestId = this.requestCopy[0].id;
              // this.requesttojoin.deleteRequestByID(this.requestCopy[0].id);
              // this.inviteService.updateInvite(this.invitation);
            }
          });
        }

        // after sign up, delete the column with contacts

        
        await this.userService.deleteUserAccount(this.id).then((res) => {
        }).catch(() => {
        })


      }).catch(error => {
        this.toast({ html: error.message, classes: 'red', type: 'failure' });
      });
    
  }
  togglePrivacy() {
    this.isPrivacy = !this.isPrivacy;
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }


  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

  async sendPushNotificationToOwner(data, fullName) {
    if (this.notifSettings) {
      let conditions = ["signUp"]
      if (this.helperervice.checkNotificationForOwner(this.notifSettings, conditions)) {

        let notification = new NotificationModel()
        notification.title='New User Registration'
        notification.body=`${fullName} has registered to ${environment.firstNation.website}.`
        // this.pushNotificationsService.sendNotificationByRole(notification,'Admin')
        await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['Admin'], 'signUp')

       
      }
    }
  }

  changeToSentenceCase(event) {
    this.inputvalue = event.replace(/\b\w/g,
      event => event.toLocaleUpperCase());

    // (ngModelChange)="inputLastvalue = $event[0].toUpperCase()" 
  }
  
  changeToSentenceCaseLast(event) {
    this.inputLastvalue = event.replace(/\b\w/g,
      event => event.toLocaleUpperCase());
  }

  // registerWithCode(data: any){
  //   this.inviteService.getInviteById(data.inviteCode).valueChanges().subscribe((invite: any) => {
  //     if (invite.length > 0 ){
  //       this.id = data.inviteCode;
  //       this.hasCode = true;
  //       this.invitedUserEmail = invite[0].inviteEmail;
  //       this.invitedUserRole = invite[0].userType;
  //       this.invitation = invite[0];
  //     } else {
  //       // toast({html: 'Wrong Invite Code', classes: 'red'});
  //     }
  //   });
  // }

}
