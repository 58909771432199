import { AngularEditorConfig } from "@kolkov/angular-editor";
import { environment } from 'src/environments/environment';

export class GlobalConstants {
  public static maxChatGptKeywords: number = 5;
  public static maxChatGptHashTags : number = 5;
  public static TextPositions: any = {
    "top-left": "top-0 left-0 text-left",
    "top-right": "top-0 right-0 text-right",
    center: "top-[90px] text-center",
    "bottom-right": "bottom-0 right-0 text-right",
    "bottom-left": "bottom-0 left-0 text-left",
  };

  public static EditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "200",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "book-antiqua", name: "Book Antiqua" },
      { class: "bookman", name: "Bookman" },
      { class: "georgia", name: "Georgia" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "hoefler-text", name: "Hoefler Text" },
      { class: "lucida-bright", name: "Lucida Bright" },
      { class: "courier", name: "Courier" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
      { class: "candara", name: "Candara" },
    ],
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      [
        "insertImage",
        "subscript",
        "superscript",
        "insertUnorderedList",
        "insertOrderedList",
        "link",
        "unlink",
        "backgroundColor",
        "insertVideo",
        "insertHorizontalRule",
        "heading",
      ],
    ],
  };

  public static landingPageView ={
    web : 'list',
    mobile : 'card'
  }
  public static cardDesign =  {
    fontSize: '12px', 
    fontWeight: 'bold', 
    fontPosition: 'bottom', 
    cardShape: 'rectangle',
    imageShape: 'none', 
    imageStyle: 'partial', 
    imageWidth: 60,
    imageHeight: 60,
    width: 160,
    height: 160, 
    boxColor: '#077373', 
    textColor: '#ffffff',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    overlayOpacity: 0.5
  }
  public static AppSections = [
    {
      name: "WelcomePopup",
      originalName: "Welcome Popup",
      displayName: "Welcome Popup",
      isEnabled: false,
      imgUrl: "",
      title: "",
      description: ""
    },
    {
      name: "Login/Signup",
      originalName: "Login/Signup",
      displayName: "Login/Signup",
      isEnabled: true,
      imgUrl: "",
      title: "",
      description: ""
    },
    {
      index: 0,
      isEnabled: false,
      name: "Slider",
      originalName: "Slider",
      isCarousel: false,
      interval: 5000,
      displayName: "Landing Page Carousel",
      options: [],
    },
    {
      index: 1,
      isEnabled: false,
      bgColor: "#FFFFFF",
      name: "Blogs",
      originalName: "Blogs",
      displayName: "Blogs",
      displayNameColor: "#000000",
      displayLimit: 3,
    },
    {
      index: 2,
      isEnabled: false,
      bgColor: "#FFFFFF",
      name: "Events",
      originalName: "Events",
      displayName: "Upcoming Events",
      displayNameColor: "#000000",
    },
    {
      index: 3,
      isEnabled: false,
      bgColor: "#FFFFFF",
      name: "AppStore",
      originalName: "App Store",
      displayName: "Download From The App Store",
      googlePlayUrl: "",
      appleStoreUrl: "",
      displayNameColor: "#000000",
    },
    {
      index: 4,
      isEnabled: false,
      bgColor: "#FFFFFF",
      displayNameColor: "#000000",
      name: "SocialMedia",
      originalName: "Social Media",
      displayName: "Social Media",
      facebookUrl: "",
      twitterUrl: "",
      youtubeUrl: "",
      linkedInUrl: "",
    },
    {
      index: 5,
      isEnabled: false,
      bgColor: "#FFFFFF",
      textColor: "#000000",
      name: "Services",
      originalName: "Services",
      displayName: "Services",
    },
    {
      index: 6,
      isEnabled: false,
      bgColor: "#FFFFFF",
      textColor: "#000000",
      name: "Products",
      originalName: "Products",
      displayName: "Products",
    },
    {
      index: 7,
      isEnabled: false,
      bgColor: "#FFFFFF",
      textColor: "#000000",
      name: "About Us",
      originalName: "About Us",
      displayName: "About Us",
    },
    {
      index: 8,
      isEnabled: false,
      bgColor: "#FFFFFF",
      textColor: "#000000",
      name: "Contact Us",
      originalName: "Footer",
      displayName: "Footer",
    },
    {
      index: 9,
      name: 'IOSPwaApp',
      originalName: 'IOS Popup',
      displayName: 'IOS Popup',
      isEnabled: false,
      options: [
        {
          imgUrl: '',
          title: 'Upgrade Your Exprience',
          titleColor: '#000000',
          description: 'Upgrade Your Exprience.',
          descriptionColor: '#000000'
        }
      ]
    },
    {
      index: 10,
      name: 'AndroidPwaApp',
      originalName: 'Android Popup',
      displayName: 'Android Popup',
      isEnabled: false,
      options: [
        {
          imgUrl: '',
          title: 'Upgrade Your Exprience',
          titleColor: '#000000',
          description: 'Upgrade Your Exprience.',
          descriptionColor: '#000000'
        }
      ]
    },
        {
      index: 11,
      isEnabled: false,
      bgColor: "#FFFFFF",
      textColor: "#000000",
      name: "First Nations Leaders",
      originalName: "First Nations Leaders",
      displayName: "First Nations Leaders",
    },
    {
      index: 12,
      isEnabled: false,
      bgColor: "#FFFFFF",
      textColor: "#000000",
      name: "FAQ",
      originalName: "faq",
      displayName: "FAQ",
      displayNameColor: "#000000",
      displayLimit: 3,
    }
  ];

  public static Currencies = [
    { id: "USD", name: "US Dollar(USD)" },
    { id: "CAD", name: "Canadian Dollar (CAD)" },
    { id: "EUR", name: "Euro(EUR)" },
    { id: "GBP", name: "British Pound Sterling (GBP)" },
    { id: "AUD", name: "Australian Dollar (AUD)" },
    { id: "JPY", name: "Japanese Yen (JPY)" },
    { id: "CNY", name: "Chinese Yuan (Renminbi; CNY)" },
    { id: "AED", name: "United Arab Emirates Dirham(AED)" },
    { id: "CHF", name: "Swiss Franc (CHF)" },
    { id: "SEK", name: "Swedish Krona (SEK)" },
    { id: "NZD", name: "New Zealand Dollar (NZD)" },
    { id: "MXN", name: "Mexican Peso (MXN)" },
    { id: "INR", name: "Indian Rupee(INR)" },
  ];

    public static PhoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    
    public static PostalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  
    public static EmptyPageSections = {
        uid: '',
        appSections: GlobalConstants.AppSections,
        appName: '',
        buttonOption: '',
        navbarOption: 'bottomnavbar',
        themeColor: '',
        description: '',
        serviceType: 'Both',
        appLogo: '',
        takeAppointment: '',
        hasEmployees: false,
        isBusinessApp: true,
        serviceName: 'Services',
        productName: 'Products',
        general: '',
        landingPageView: GlobalConstants.landingPageView,
        cardDesign: GlobalConstants.cardDesign,
        verifyUsers: false,
        welcomeMessage: '',
        currency: 'CAD',
        adminEmail: [],
        greetings: '',
        signOff: '',
        messageSMS: '',
        viewType: 'list',
        showHiddenSections: true
      };

    public static daysOfTheWeek =  [
      {
        day: 'Sunday',
        startTime: null,
        endTime: null,
        unAvailable: true
      },
      {
        day: 'Monday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Tuesday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Wednesday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Thursday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Friday',
        startTime: '09:00',
        endTime: '17:00',
        unAvailable: false
      },
      {
        day: 'Saturday',
        startTime: null,
        endTime: null,
        unAvailable: true
      },
  
    ];
    
    public static provinces= [{ name: 'Alberta', code: 'AB' },
    { name: 'British Columbia', code: 'BC' },
    { name: 'Manitoba', code: 'MB' },
    { name: 'New Brunswick', code: 'NB' },
    { name: 'Newfoundland and Labrador', code: 'NL' },
    { name: 'Nova Scotia', code: 'NS' },
    { name: 'Ontario', code: 'ON' },
    { name: 'Prince Edward Island', code: 'PE' },
    { name: 'Quebec', code: 'QC' },
    { name: 'Saskatchewan', code: 'SK' }
    ];

    public static states =  [
      { name:'Alabama', code: 'AL' },
      { name:'Alaska', code: 'AK'},
      { name:'American Samoa', code: 'AS'},
      { name:'Arizona', code: 'AZ'},
      { name:'Arkansas', code: 'AR'},
      { name:'California', code: 'CA'},
      { name:'Colorado', code: 'CO'},
      { name:'Connecticut', code: 'CT'},
      { name:'Delaware', code: 'DE'},
      { name:'District of Columbia', code: 'DC'},
      { name:'States of Micronesia', code: 'FM'},
      { name:'Florida', code: 'FL'},
      { name:'Georgia', code: 'GA'},
      { name:'Guam', code: 'GU'},
      { name:'Hawaii', code: 'HI'},
      { name:'Idaho', code: 'ID'},
      { name:'Illinois', code: 'IL'},
      { name:'Indiana', code: 'IN'},
      { name:'Iowa', code: 'IA'},
      { name:'Kansas', code: 'KS'},
      { name:'Kentucky', code: 'KY'},
      { name:'Louisiana', code: 'LA'},
      { name:'Maine', code: 'ME'},
      { name:'Marshall Islands', code: 'MH'},
      { name:'Maryland', code: 'MD'},
      { name:'Massachusetts', code: 'MA'},
      { name:'Michigan', code: 'MI'},
      { name:'Minnesota', code: 'MN'},
      { name:'Mississippi', code: 'MS'},
      { name:'Missouri', code: 'MO'},
      { name:'Montana', code: 'MT'},
      { name:'Nebraska', code: 'NE'},
      { name:'Nevada', code: 'NV'},
      { name:'New Hampshire', code: 'NH'},
      { name:'New Jersey', code: 'NJ'},
      { name:'New Mexico', code: 'NM'},
      { name:'New York', code: 'NY'},
      { name:'North Carolina', code: 'NC'},
      { name:'North Dakota', code: 'ND'},
      { name:'Northern Mariana Islands', code: 'MP'},
      { name:'Ohio', code: 'OH'},
      { name:'Oklahoma', code: 'OK'},
      { name:'Oregan', code: 'OR'},
      { name:'Palau', code: 'PW'},
      { name:'Pennsilvania', code: 'PA'},
      { name:'Puerto Rico', code: 'PR'},
      { name:'Rhode Island', code: 'RI'},
      { name:'South Carolina', code: 'SC'},
      { name:'South Dakota', code: 'SD'},
      { name:'Tennessee', code: 'TN'},
      { name:'Texas', code: 'TX'},
      { name:'Utah', code: 'UT'},
      { name:'Vermont', code: 'VT'},
      { name:'Virgin Islands', code: 'VI'},
      { name:'Virginia', code: 'VA'},
      { name:'Washington', code: 'WA'},
      { name:'West Virginia', code: 'WV'},
      { name:'Wisconsin', code: 'WI'},
      { name:'Wyoming', code: 'WY'}
    ]

    public static tribalNames = [
      {
        tribalName: "AGENCY CHIEFS TRIBAL COUNCIL",
        province: "SK",
      },
      {
        tribalName: "BATTLEFORDS AGENCY TRIBAL CHIEFS INC",
        province: "SK",
      },
      {
        tribalName: "FILE HILLS QU'APPELLE TRIBAL COUNCIL INC.",
        province: "SK",
      },
      {
        tribalName: "MLTC PROGRAM SERVICES INC.",
        province: "SK",
      },
      {
        tribalName: "NORTHWEST PROFESSIONAL SERVICES CORP.",
        province: "SK",
      },
      {
        tribalName: "PADC MANAGEMENT COMPANY LTD.",
        province: "SK",
      },
      {
        tribalName: "SASKATOON TRIBAL COUNCIL",
        province: "SK",
      },
      {
        tribalName: "TOUCHWOOD AGENCY TRIBAL COUNCIL INC.",
        province: "SK",
      },
      {
        tribalName: "YORKTON TRIBAL ADMINISTRATION INC.",
        province: "SK",
      },
      {
        tribalName: "CARRIER CHILCOTIN TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "CARRIER-SEKANI TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "FIRST NATION OF THE MAA-NULTH TREATY SOCIETY",
        province: "BC",
      },
      {
        tribalName: "FRASER THOMPSON INDIAN SERVICES SOCIETY",
        province: "BC",
      },
      {
        tribalName: "GITKSAN LOCAL SERVICES SOCIETY",
        province: "BC",
      },
      {
        tribalName: "KTUNAXA NATION COUNCIL SOCIETY",
        province: "BC",
      },
      {
        tribalName: "KWAKIUTL DISTRICT COUNCIL",
        province: "BC",
      },
      {
        tribalName: "LILLOOET TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "LOWER STL'ATL'IMX TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "MUSGAMAGW DZAWADA'ENUXW TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "NAUT'SA MAWT TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "NORTHERN SHUSWAP TRIBAL COUNCIL SOCIETY",
        province: "BC",
      },
      {
        tribalName: "NUU-CHAH-NULTH TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "OKANAGAN NATION ALLIANCE",
        province: "BC",
      },
      {
        tribalName: "SCW’EXMX TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "SECRETARIAT OF THE HAIDA NATION",
        province: "BC",
      },
      {
        tribalName: "SHUSWAP NATION TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "STO:LO NATION",
        province: "BC",
      },
      {
        tribalName: "STO:LO TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "TREATY 8 TRIBAL ASSOCIATION",
        province: "BC",
      },
      {
        tribalName: "TSILHQOT'IN NATIONAL GOVERNMENT",
        province: "BC",
      },
      {
        tribalName: "WUIKINUXV KITASOO NUXALK TRIBAL COUNCIL",
        province: "BC",
      },
      {
        tribalName: "ANISHINAABE AGOWIDIIWINAN SECRETARIAT INC",
        province: "MB",
      },
      {
        tribalName: "DAKOTA OJIBWAY TRIBAL COUNCIL INC.",
        province: "MB",
      },
      {
        tribalName: "INTERLAKE RESERVES TRIBAL COUNCIL INC.",
        province: "MB",
      },
      {
        tribalName: "ISLAND LAKE TRIBAL COUNCIL INC.",
        province: "MB",
      },
      {
        tribalName: "KEEWATIN TRIBAL COUNCIL INC.",
        province: "MB",
      },
      {
        tribalName: "SOUTHEAST RESOURCE DEVELOPMENT COUNCIL CORP.",
        province: "MB",
      },
      {
        tribalName: "SWAMPY CREE TRIBAL COUNCIL INCORPORATED",
        province: "MB",
      },
      {
        tribalName: "WEST REGION TRIBAL COUNCIL INC",
        province: "MB",
      },
      {
        tribalName: "ANISHINAABEG OF KABAPIKOTAWANGAG RESOURCE COUNCIL INC.",
        province: "ON",
      },
      {
        tribalName: "BIMOSE TRIBAL COUNCIL",
        province: "ON",
      },
      {
        tribalName: "INDEPENDENT FIRST NATIONS ALLIANCE",
        province: "ON",
      },
      {
        tribalName: "KEEWAYTINOOK OKIMAKANAK/NORTHERN CHIEFS COUNCIL",
        province: "ON",
      },
      {
        tribalName: "MAMAWESWEN, THE NORTH SHORE TRIBAL COUNCIL",
        province: "ON",
      },
      {
        tribalName: "MATAWA FIRST NATIONS MANAGEMENT",
        province: "ON",
      },
      {
        tribalName: "MUSHKEGOWUK COUNCIL",
        province: "ON",
      },
      {
        tribalName: "NOKIIWIN TRIBAL COUNCIL INC.",
        province: "ON",
      },
      {
        tribalName: "OGEMAWAHJ TRIBAL COUNCIL",
        province: "ON",
      },
      {
        tribalName: "PWI-DI-GOO-ZING NE-YAA-ZHING ADVISORY SERVICES",
        province: "ON",
      },
      {
        tribalName: "SHIBOGAMA FIRST NATIONS COUNCIL",
        province: "ON",
      },
      {
        tribalName: "SOUTHERN FIRST NATIONS SECRETARIAT",
        province: "ON",
      },
      {
        tribalName: "UNITED CHIEFS & COUNCILS OF MNIDOO MNISING",
        province: "ON",
      },
      {
        tribalName: "WAABNOONG BEMJIWANG ASSOCIATION OF FIRST NATIONS",
        province: "ON",
      },
      {
        tribalName: "WABUN TRIBAL COUNCIL",
        province: "ON",
      },
      {
        tribalName: "WINDIGO FIRST NATIONS COUNCIL",
        province: "ON",
      },
      {
        tribalName: "ALGONQUIN ANISHINABEG NATION TRIBAL COUNCIL",
        province: "QC",
      },
      {
        tribalName: "ALGONQUIN NATION PROGRAMS AND SERVICES SECRETARIAT",
        province: "QC",
      },
      {
        tribalName: "ATIKAMEKW SIPI - CONSEIL DE LA NATION ATIKAMEKW",
        province: "QC",
      },
      {
        tribalName: "CONSEIL TRIBAL MAMUITUN",
        province: "QC",
      },
      {
        tribalName: "GRAND CONSEIL DE LA NATION WABAN-AKI INC.",
        province: "QC",
      },
      {
        tribalName: "MI'GMAWEI MAWIOMI SECRETARIAT",
        province: "QC",
      },
      {
        tribalName: "REGROUPEMENT MAMIT INNUAT INC.",
        province: "QC",
      },
      {
        tribalName: "CONFEDERACY OF MAINLAND MI'KMAQ",
        province: "ATL",
      },
      {
        tribalName: "EPEKWITK ASSEMBLY OF COUNCILS INC.",
        province: "ATL",
      },
      {
        tribalName: "MAWIW COUNCIL INCORPORATED",
        province: "ATL",
      },
      {
        tribalName: "NORTH SHORE MICMAC DISTRICT COUNCIL",
        province: "ATL",
      },
      {
        tribalName: "SICKADOMEC FIRST NATION INC",
        province: "ATL",
      },
      {
        tribalName: "UNION OF NOVA SCOTIA MI'KMAQ-ADVISORY SERVICES",
        province: "ATL",
      },
      {
        tribalName: "WOLASTOQEY TRIBAL COUNCIL INC.",
        province: "ATL",
      },
      {
        tribalName: "AKAITCHO TERRITORY GOVERNMENT",
        province: "NWT",
      },
      {
        tribalName: "COUNCIL OF KASKA CHIEFS",
        province: "NWT",
      },
      {
        tribalName: "DEH CHO FIRST NATIONS",
        province: "NWT",
      },
      {
        tribalName: "GWICH'IN TRIBAL COUNCIL",
        province: "NWT",
      },
      {
        tribalName: "SAHTU DENE COUNCIL",
        province: "NWT",
      },
      {
        tribalName: "SOUTHERN TUTCHONE TRIBAL COUNCIL",
        province: "NWT",
      },
      {
        tribalName: "TETLIT GWICH'IN COUNCIL DGO",
        province: "NWT",
      },
    ];
    public static quickGuide = [
      {
        id:0, 
        title: 'Setup Carousel',
        image: '',
        link: 'heroCarosuel',
        description: 'A carousel is a series of images that automatically rotate or can be manually scrolled through. A hero is a large banner or image prominently displayed at the top of the page.',
      },
      {
        id:1, 
        title: 'Add welcome popup window',
        image: '',
        link: 'popup',
        description: 'A popup window is a small window that appears on top of the main content, typically containing a message, offer, or advertisement. It can be triggered after a set time.',
      },
      {
        id:2, 
        title: 'Contact Information/ Footer',
        image: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360"><rect x="164.76" y="110.97" width="161.43" height="117.85" rx="8.26" style="fill:#6d6e71"/><circle cx="207.64" cy="243.39" r="5.64" style="fill:#6d6e71"/><circle cx="230.84" cy="243.39" r="5.64" style="fill:#d1d3d4"/><circle cx="254.04" cy="243.39" r="5.64" style="fill:#6d6e71"/><circle cx="277.24" cy="243.39" r="5.64" style="fill:#6d6e71"/><path d="M242.22,207.26H196.56c-4.11,0-5-1.24-5.38-5.33-.59-5.93,1.37-10.36,5.87-14.28,5.07-4.41,9.58-9.45,14.35-14.2,2.39-2.38,4.33-2.4,6.72,0,2.9,2.84,5.73,5.73,8.61,8.59,2.67,2.64,4.69,2.6,7.17-.17Q246,168.29,258,154.74c2.78-3.12,4.84-3.12,7.58,0,8.18,9.2,16.33,18.43,24.56,27.59a10.65,10.65,0,0,1,3,7.87c-.13,3.82,0,7.65,0,11.47,0,4.53-1.1,5.61-5.72,5.61Z" style="fill:#d1d3d4"/><path d="M201,160.29a9.82,9.82,0,0,1,.06-19.64,9.82,9.82,0,1,1-.06,19.64Z" style="fill:#d1d3d4"/><rect x="34.27" y="137.68" width="97.01" height="5.77" rx="2.88" style="fill:#d1d3d4"/><rect x="34.27" y="149.97" width="97.01" height="5.77" rx="2.88" style="fill:#d1d3d4"/><rect x="34.27" y="162.26" width="97.01" height="5.77" rx="2.88" style="fill:#d1d3d4"/><rect x="33.82" y="174.55" width="97.01" height="5.77" rx="2.88" style="fill:#d1d3d4"/><rect x="33.82" y="186.84" width="97.01" height="5.77" rx="2.88" style="fill:#d1d3d4"/><rect x="33.82" y="199.13" width="97.01" height="5.77" rx="2.88" style="fill:#d1d3d4"/></svg> ',
        link: 'contact',
        description: 'A footer is a section at the bottom of a document, webpage, or email. It has important information like copyrights, contacts, or links. It helps users navigate or find additional details.',
      },
      {
        id:3, 
        title: 'Rename this section',
        image: '',
        link: 'rename',
        description: 'Change the name or title of this section to better reflect its content, purpose, or context. By renaming this section, you can make the content more clear, relevant, and easily understandable for the intended audience.',
      },
      {
        id:4, 
        title: 'Create your blog page',
        image: '',
        link: 'addBlog',
        description: 'Your place to express your ideas, share expertise, tell stories, provide insights, or offer advice to a wide audience. you can incorporate text, images, and multimedia elements to engage readers.',
      },
      {
        id:5, 
        title: 'Editing your blog page',
        image: '',
        link: 'editBlog',
        description: 'Feel free to revise and refining written content to ensure clarity, coherence, accuracy, and overall quality.',
      },
      {
        id:6, 
        title: 'Comments on your page',
        image: '',
        link: 'comment',
        description: 'An interactive space for readers to engage in discussions, share their thoughts, and provide feedback on your post. You can enable comment sections for the public, subscribers or employees.',
      },  
      {
        id:7, 
        title: 'Rename this Section',
        image: '',
        link: 'renameEvent',
        description: 'Change the name or title of this section to better reflect its content, purpose, or context. By renaming this section, you can make the content more clear, relevant, and easily understandable for the intended audience.',
      },  
      {
        id:8, 
        title: 'Create an Event',
        image: '',
        link: 'addEvent',
        description: 'Easily create and promote your event on our site. From conferences to intimate gathering, our user-friendly platform has all the tools you need.',
      },  
      {
        id:9, 
        title: 'Edit your Events',
        image: '',
        link: 'editEvent',
        description: 'Feel free to make any necessary revisions and improvements to your events, provided that the event date has not passed. This allows you the flexibility to refine and enhance your event details, ensuring that everything is up to your standard.',
      }, 
      {
        id:10, 
        title: 'Rename this Section',
        image: '',
        link: 'renameService',
        description: 'Change the name or title of this section to better reflect its content, purpose, or context. By renaming this section, you can make the content more clear, relevant, and easily understandable for the intended audience.',
      },  
      {
        id:11, 
        title: 'Create a Service',
        image: '',
        link: 'addService',
        description: 'A service is a valuable offering provided to customers or clients to fulfill specific needs or desires. It involves the delivery of intangible actions, expertise, or assistance to enhance or solve a problem for the recipient.',
      },  
      {
        id:12, 
        title: 'Edit a Service',
        image: '',
        link: 'editService',
        description: 'Feel free to revise and refining written content to ensure clarity, coherence, accuracy, and overall quality.',
      },  
      {
        id:13, 
        title: 'Rename this Section',
        image: '',
        link: 'renameProduct',
        description: 'Change the name or title of this section to better reflect its content, purpose, or context. By renaming this section, you can make the content more clear, relevant, and easily understandable for the intended audience.',
      },  
      {
        id:14, 
        title: 'Add a product',
        image: '',
        link: 'addProduct',
        description: "Get started with publising your products. A product is something created or offered for sale that satisfies a customer's need or desire.",
      },  
      {
        id:15, 
        title: 'Edit your Product',
        image: '',
        link: 'editProduct',
        description: 'Change the name or title of this section to better reflect its content, purpose, or context. By renaming this section, you can make the content more clear, relevant, and easily understandable for the intended audience.',
      },  
      {
        id:16, 
        title: 'Rename this Section',
        image: '',
        link: 'renameAbout',
        description: 'Change the name or title of this section to better reflect its content, purpose, or context. By renaming this section, you can make the content more clear, relevant, and easily understandable for the intended audience.',
      },  
      {
        id:17, 
        title: 'Add an about Section',
        image: '',
        link: 'addAbout',
        description: 'The "About Us" section allows you to share your story, values, and expertise with users. It builds trust, showcases your achievements, and highlights your commitment.Feel free to customize and adapt this section to fit your specific company or organization.  ',
      },  
      {
        id:18, 
        title: 'Show employees',
        image: '',
        link: 'showEmployee',
        description: 'Enabling employee visibility on your About Us page allows you to shine a spotlight on the remarkable individuals comprising your team. It provides a glimpse into the committed professionals who bring passion and expertise to all aspects of your work.',
      },  
      {
        id:19, 
        title: 'Add Department',
        image: '',
        link: 'addDepartment',
        description: 'Incorporating distinct departments within your organization, ensure streamlined workflows, efficient collaboration, and effective resource allocation such as employee management, task allocation and etc',
      },  
      {
        id:20, 
        title: 'Add Employee',
        image: '',
        link: 'addEmployee',
        describe:''
        // description: 'Easily create profiles for each employee, providing key information such as their name, and role within your organization. Explore the profiles of your team members, celebrate their contributions, and promote a culture of transparency and collaboration.',
      },  
      {
        id:21, 
        title: 'Link social Media to your Home page',
        image: '',
        link: 'linkSocial',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',
        
      },  
      {
        id:22, 
        title: 'Manage your social media through GM',
        image: '',
        link: 'manageSocial',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',
      },  
      {
        id:23, 
        title: 'Approve users through SMS',
        image: '',
        link: 'customizeEmailSMS',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',

      },  
      {
        id:24, 
        title: 'Email signature',
        image: '',
        link: 'emailSignature',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',
      }, 
      {
        id:25, 
        title: 'Customize the form your client fills',
        image: '',
        link: 'customizeClientForm',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',
      },  
      {
        id:26, 
        title: 'Add an pwa install instruction modal',
        image: '',
        link: 'pwaPopup',
        description: 'A popup window is a small window that appears on top of the main content, typically containing a message, offer, or advertisement. It can be triggered after a set time.',
      },
      {
        id:27, 
        title: 'Brand Setup',
        image: '',
        link: 'brandSetup',
        description: 'Customize the app to your specific needs. You can set up your brand by customizing the color, application name, admin emails and other elements.',
      },
      {
        id:28, 
        title: 'General Settings',
        image: '',
        link: 'generalSetup',
        description: 'Customize the app to your specific needs.',
      },
      {
        id:29, 
        title: 'Areas of interest',
        image: '',
        link: 'areasOfInterest',
        description: 'Add areas of interest so that users can choose from them and subscribe to a specific area(s).',
      },
      {
        id: 30, 
        title: 'Appointment Setting',
        image: '',
        link: 'appointmentSetting',
        description: ''
        // description: 'Add appointment settings here. If the appointment is turned you can add appointment to the services and be able to add schedule to the employees',
      },
      {
        id: 31,
        title: 'Create Custom page',
        image: '',
        link: 'custom',
        description: ''
      },
      {
        id: 32,
        title: 'Social Media integration',
        image: '',
        link: 'socialMediaPage',
        description: ''
      },
      {
        id: 33,
        title: 'FAQ',
        image: '',
        link: 'faq',
        description: ' Adding a FAQ section can enhance user experience by providing readily accessible information and reducing the need for direct assistance.'
      },
      {
        id:34, 
        title: 'SMS content customization',
        image: '',
        link: 'smsContentCustomization',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',
      }, 
      {
        id:35, 
        title: 'SMS signature',
        image: '',
        link: 'smsSignature',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',
      }, 

      {
        id:36, 
        title: 'Add Custom Page',
        image: '',
        link: 'AddCustomPage',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',
      }, 
      {
        id:37, 
        title: 'SEO Setup',
        image: '',
        link: 'seo',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint nobis veniam nostrum excepturi repellat id ex, perspiciatis quaerat error ea velit esse nisi quo possimus maiores omnis adipisci atque consequatur?',
      }, 
    ] 
  public static isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false
  public static currentAppName=  this.isNationApp ? 'First Nation Leaders App' : 'Going Mobile'
  public static defaultLogo=  this.isNationApp ? 
  'https://api.goingmobile.app/media/personal-app-assets/img/webp/Nation-logo.webp' : 
  'https://api.goingmobile.app/media/personal-app-assets/img/webp/Going%20Mobile%20-%203.webp'
  public static demoUrl=  this.isNationApp ? 'https://firstnationleaders.app' : 'https://demo.goingmobile.app'

  }
