import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { filter } from 'rxjs/internal/operators/filter';
import { Platform } from '@angular/cdk/platform';
import { FCMpushService } from './services/firebase/FCMpush.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { map } from 'rxjs/operators';
import { GoogleAnalyticsService } from './services/google-analytics/analytics.service'
import { AuthService } from 'src/app/services/firebase/auth.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { MemberModel } from 'src/app/models/memberModel';
import { MenuSettingsService } from './services/firebase/menu-settings.service';
import { ProductsServiceMenuService } from './services/api/data.service';
import * as filestack from 'filestack-js';
import { Datepicker, Input, initTE } from "tw-elements";
import { EnvironmentService } from './services/helper/env';
import { RoutMetaService } from './services/helper/rout-meta.service';
import { NotificationsService } from './services/firebase/notifications.service';
import { Store } from '@ngrx/store';
import { UserState } from "src/app/state/app.state";
import * as AppActions from './state/app.actions';
import { ScheduleService } from './services/firebase/employee-schedule.service';

declare var M: any;
declare const gtag: Function;

const client = filestack.init('AlIW5XToVRT2qVS1UhHhNz');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'MyNation';
  displayName = environment.firstNation.displayName;
  src: string = 'https://via.placeholder.com/350x150';
  route = '';
  model: any;
  navbarOption: any;
  themeColor: string;
  defaultThemeColor = this.envService.getColor('primary');
  landingModel: any;
  nationLogo: string;
  rightOrLeftSidebar: boolean;
  appName: any;
  isLogged = false;
  user: any;
  role: any;
  fireMember: Observable<MemberModel>;
  isAdminApproved: any;
  openSidebar = false;
  minimizedMenu = false;
  connectionStatusMessage: any;
  connectionStatus: any;
  // Observables & Subscriptions to check online/offline connectivity status
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  //
  menu
  metaTagSettings: any;
  settings:any;
  
  // toast
  toastMessage: any;
  // toastClass: any;
  toastType: any;
  openToast = false;
  appointmentNotifications: [];
  user$: Observable<any>;
  employeeScheduleIDs: any[] = [];
  constructor(
              private swUpdate: SwUpdate,
              public router: Router,
              private location: Location,
              public settingsService: AppSettingsService,
              public menuService: MenuSettingsService,
              private authService: AuthService,
              private productsServiceMenuService:ProductsServiceMenuService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private fcmPushService:FCMpushService,
              private platform: Platform,
              private envService: EnvironmentService,
              private routMeta: RoutMetaService,
              private appSettingsService : AppSettingsService,
              private notificationService: NotificationsService,
              private store: Store,
              private scheduleService: ScheduleService
    ) {

      this.checkOnlineStatus()
    if ( typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web'  && (this.platform.ANDROID || this.platform.IOS)) {
      fcmPushService.onMessageReceived();
    }

    googleAnalyticsService.startTracking();
    // to check which route when navigating
    router.events.pipe(filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (location.path() !== '') {
        this.route = location.path();
        // gtag('event', 'page_view', { page_path: event.urlAfterRedirects});

      }
    });


    const available = swUpdate.versionUpdates.pipe(
      filter(evt => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE'
      })),
    );
      

    available.subscribe(up => {
      if (up.type === 'UPDATE_AVAILABLE') {
        if (confirm('New update available. \n Please click OK to install and run the new version of ' + this.displayName + ' App ?')) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      }

    });

  }

  // constructor(private platform: Platform, private fcmPushService: FCMpushService) {
  //   if (this.platform.ANDROID || this.platform.IOS) {
  //     this.fcmPushService.onMessageReceived();
  //   }
  // }
    
   
  ngOnInit() {

    console.log('theme color ', this.defaultThemeColor)

    this.toast({html: 'Upcoming appointment!', classes: 'green', type: 'success'});
    this.getMetaTagSettings();
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    initTE({ Datepicker, Input });
    
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
    }));


  this.rightOrLeftSidebar = true;
  this.navbarOption = 'right';
  this.model = '';

  this.landingModel = '';
  this.nationLogo = '';
  this.authService.afAuth.authState.subscribe(auth => {

    if (auth) {
      this.fireMember = this.authService.getCurrentUserObservable();
      this.isLogged = true;
      this.fireMember.subscribe((data: any) => {
        if(data){
          this.role = data.role ? data.role : '';
          this.user = data;
          let userObj: Partial<UserState> = this.user;
          this.store.dispatch(AppActions.addUser(userObj));

          this.notificationService.getMyNotifications(data.uid).valueChanges().subscribe((notif: any) => {
            this.appointmentNotifications = notif.filter(notification => notification.type === 'Appointment' && notification.isSeen === false);
          });
          if (this.role === 'Admin'){
            this.isAdminApproved = data.isAdminApproved ? data.isAdminApproved : false;
          }
        }else {
          this.role = '';
          this.isLogged = false;
        }
      });

    } else {
      this.role = '';
      this.isLogged = false;
    }
  });

  this.scheduleService.getAllSchedules().valueChanges().subscribe(schs =>{
    if(schs && schs.length > 0){
      const updatedEmployeeScheduleIDs = [...this.employeeScheduleIDs];
      schs.forEach((sch: any) => {
        if (!updatedEmployeeScheduleIDs.includes(sch.employeeId)) {
          updatedEmployeeScheduleIDs.push(sch.employeeId);
        }
      });
      this.store.dispatch(AppActions.addEmployeeIdsFromSchedule({ schedule: updatedEmployeeScheduleIDs }));
    }
  })
  this.settingsService.getAppSettingsList().valueChanges().subscribe(
    appSettings => {
      if (appSettings) {
        if (appSettings.length > 0) {
          this.model = appSettings[0];
          if(this.model){
            this.store.dispatch(AppActions.addAppSettings({ settings: this.model }));
          }
          document.documentElement.style.setProperty('--color-primary',
          this.model.themeColor ? this.model.themeColor : this.defaultThemeColor);

          let secondary =  this.model.themeColor ? this.model.themeColor : this.defaultThemeColor
          let accentColor = this.accentColour(this.hexToRGB(secondary), secondary)
          document.documentElement.style.setProperty('--color-secondary',
          accentColor);

          // this.navbarOption = this.model.navbarOption;
          this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          this.nationLogo = this.model.logo ? this.model.logo : '';
          this.appName = this.model.appName ? this.model.appName : 'MyNation';
          // this.rightOrLeftSidebar = this.model.rightOrLeftSidebar;
          if (this.model.isBusinessApp){
            this.getServiceProductsMenu();
          }
        } else {
            document.documentElement.style.setProperty('--color-primary', this.defaultThemeColor);

        }

      } else {
          document.documentElement.style.setProperty('--color-primary', this.defaultThemeColor);

      }
    }
    );


    document.documentElement.style.setProperty('--color-brandColor', this.envService.getColor('primary'));
    document.documentElement.style.setProperty('--color-brandColorSecondary', this.envService.getColor('secondary'));
    document.documentElement.style.setProperty('--color-brandColorAccent', this.envService.getColor('accent'));
  

  }

  accentColour = (rgb, hex) => {

    const rgbLightness = (r, g, b) => 0.2126 * r + 0.587 * g + 0.114 * b;

    const blendColours = (hex1, hex2, amount) => {
      // remove "#"
      [ hex1, hex2 ] = [ parseInt(hex1.slice(1), 16), parseInt(hex2.slice(1), 16) ];
      const [ hex1_12 , hex2_12 ] = [ hex1 >> 16 , hex2 >> 16 ];
      const [ hex1_34, hex2_34 ] = [ hex1 >> 8 & 255, hex2 >> 8 & 255 ];
      const [ hex1_56, hex2_56 ] = [ 255 & hex1, 255 & hex2 ];

      return "#" + [
        16777216,
        65536 * (Math.round((hex2_12 - hex1_12) * amount) + hex1_12),
        256 * (Math.round((hex2_34 - hex1_34) * amount) + hex1_34),
        (Math.round((hex2_56 - hex1_56) * amount) + hex1_56),
      ]
        .reduce((a, b) => a + b, 0)
        .toString(16)
        .slice(1);
    };
    // let myTuple: [any, any,any] = [...rgb];
    return (

      (
        rgbLightness(rgb[0],rgb[1],rgb[2]) < 180)
        ?
        blendColours(hex, "#FFFFFF", 0.75)
        :
        blendColours(hex, "#000000", 0.5)
    );
  };

  hexToRGB(hex: string,) {

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;

    }

  getServiceProductsMenu(){
    let data = {
      services: 'Services',
      products: 'Products',
      productsCategory: 'Category',
      servicesCategory: 'Category',
      blogs: 'Blogs',
      events: 'Events',
      aboutUs: 'About Us',
      currency: 'USD',
      socialMedia: 'Social Media',
      contactUs: 'Contact Us'

    };
    if (this.model.appSections){
      this.model.appSections.forEach((section) => {
        if (section.originalName === 'Services'){
          data.services = section.name;
        }
        if (section.originalName === 'Products'){
          data.products = section.name;
        }
        if (section.originalName === 'Blogs'){
          data.blogs = section.name;
        }
        if (section.originalName === 'Event'){
          data.events = section.name;
        }
        if (section.originalName === 'About Us'){
          data.aboutUs = section.name;
        }
        if (section.originalName === 'Social Media Management'){
          data.socialMedia = section.name;
        }
        if (section.originalName === 'Contact Us'){
          data.contactUs = section.name;
        }

      });
    }
    if (this.model.currency){
      data.currency = this.model.currency;
    }
    // data.products = this.model.productName;
    this.productsServiceMenuService.updateMenu(data);
  }

  back(){
    this.location.back();
  }
  forward(){
    this.location.forward();
  }

  signOut() {
    this.authService.signOut();
  }
  toggleSideBar(){
    this.openSidebar = !this.openSidebar;
  }
  toggleMinimizeMenu(){
    this.minimizedMenu = !this.minimizedMenu;
  }



//Get the online/offline connectivity status from browser window
checkOnlineStatus = () => {
  this.onlineEvent = fromEvent(window, 'online');
  this.offlineEvent = fromEvent(window, 'offline');

  this.subscriptions.push(this.onlineEvent.subscribe(() => {
  }   
   ));
  this.subscriptions.push(this.offlineEvent.subscribe(() => { } ));
}



ngOnDestroy(): void {
  this.subscriptions.forEach(subscription => subscription.unsubscribe());
}

fileUpload(fileObject: File){
  client.upload(fileObject).then(data =>{});
}
getMetaTagSettings() {
  this.settings = this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
    let lastIndex = metaTags?.length - 1;
    if (lastIndex || lastIndex === 0) {
      this.metaTagSettings = metaTags?.[lastIndex];
      // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
      this.routMeta.updateTitle(metaTags[0]?.appTitle ? metaTags[0]?.appTitle : 'title');
      // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
      this.routMeta.updateDescription(metaTags[0]?.description);
      // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
      this.routMeta.updateKeywords(metaTags[0]?.keyWords ? metaTags[0]?.keyWords : "");

    }
    this.settings.unsubscribe();
  });
}

toast(obj){
  this.toastMessage = obj.html;
  // this.toastClass = obj.classes ? obj.classes : 'green' ;
  if(obj.more){
    this.toastType = 'seeMore';
  }else {

    this.toastType = 'success';
  }
  this.openToast = true;
  // setTimeout(() => {
  //   this.openToast = false;
    
  // }, 2000);
}
closeModal(item){
  this.notificationService.markNotificationAsSeen(item.id).then(() => {
  }).catch((error) => {
    console.error('Error marking notification as seen:', error);
  });
}
showAll(){
if(this.user.role=='Employee'){
  this.router.navigate(['/employee/appointments']);
}
if(this.user.role=='Admin'){
  this.router.navigate(['/admin/appointments']);  
}
}
toastContainerClicked(item){
  if(item.type === 'Appointment'){
    if(this.user.role=='Employee'){
      this.closeModal(item);
      this.router.navigate(['/employee/appointment-detail/', item?.appointmentId]);

    }
    if(this.user.role=='Admin'){
      this.closeModal(item);
      this.router.navigate(['/admin/appointment-detail/', item?.appointmentId]);
    }
  }

}
}

