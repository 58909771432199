import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { filter, Observable, Subscription } from 'rxjs';
import { MemberModel } from 'src/app/models/memberModel';
import { MenuSettingsService } from 'src/app/services/firebase/menu-settings.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { RoutMetaService } from 'src/app/services/helper/rout-meta.service';
import { Store } from '@ngrx/store';

import { selectAppSettings, selectRole, selectUserData } from 'src/app/state/app.selectors';
import * as AppActions from '../../../state/app.actions';
import { EnvironmentService } from 'src/app/services/helper/env';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['./admin-main.component.css']
})

export class AdminMainComponent implements OnInit {
  scrWidth: number;
  title = 'MyNation';
  nationOfuse = environment.firstNation.name;
  displayName = environment.firstNation.displayName;
  appName1 = environment.firstNation.name;
  route = '';
  model: any;
  navbarOption: any;
  themeColor: string;
  defaultThemeColor = this.envService.getColor('primary');
  landingModel: any;
  appLogo: string;
  rightOrLeftSidebar: boolean;
  appName: any;
  isLogged = false;
  user: any;
  role: any;
  fireMember: Observable<MemberModel>;
  isAdminApproved: any;
  openSidebar = false;
  minimizedMenu = false;
  isDemo = false;
  communicationsActive: any;
  subscriptionsActive: any;
  isBusinessApp = false;
  currentUser: any;
  collapseShow = 'hidden';
  menu
  servicesName
  productsName
  clientGlobal: any;
  form: any;

  previewModal=false;
  isAdmin: boolean;
  perm: any;
  permission: any;
  openSubscribersViewCollapse = false;
  openManageAppCollapse = false;
  openOrganizationCollapse = false;
  openSubscribersMgtCollapse = false;
  backClickedData = ''
  theme = 'light'
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  showInstallButton = false;
  deferredPrompt: any;
  previewHomePage = false
  gettingStartedPercentage: number;

  gettingStarted = {
    firstLogin: true,
    homepage: false,
    employee: false,
    service: false,
    about: false,
    product: false,
    event: false, 
    blog: false,
    audience: false,
    socialMediaPage: false, 
    customPage: false
  }
  selectedPageStatus= [];
  isMenuLocked = true;
  @Input() calledFromOnboarding: any;
  pageView: any;

  metaTagSettings: any = null;
  settings:any;
  pageName: string;
  appSettings$: Subscription;

  close:boolean = false;

  constructor(public router: Router,
    private location: Location,
    public settingsService: AppSettingsService,
    public menuService: MenuSettingsService,
    private authService: AuthService,
    private productsServiceMenuService: ProductsServiceMenuService,
    private clientFormService: ClientFormService,
    private customSectionService: CustomSectionService,
    private routMeta: RoutMetaService,
    private store: Store,
    private envService: EnvironmentService,
    private helperService: HelperService
    ) {
      this.store.select(selectUserData).subscribe((user) => { this.currentUser = {...user};
        this.user = {...user}
       });
      this.store.select(selectRole).subscribe((role) => { this.role = role; });

      this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { this.model = this.helperService.cloneDeep(settings);  });
      
    // router.events.subscribe((_: NavigationEnd) => this.route = _.url);
    // this.scrWidth = window.innerWidth;

    // register A2HS event
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.deferredPrompt = event;
      this.showInstallButton = true;
    });

  }

  ngOnInit() {
    this.pageName = this.router.url.split('?')[0]
    this.router.events.pipe(filter(event=> event instanceof NavigationEnd)).subscribe((event: NavigationEnd) =>{
      this.pageName = event.urlAfterRedirects
      if(this.pageName=='/admin/landing-page-settings'){
        this.minimizedMenu = true
      }else{
        this.minimizedMenu = false
      }
    })

    // this.session.startSession();
    this.rightOrLeftSidebar = true;
    this.navbarOption = 'right';
    this.model = '';

    this.landingModel = '';
    this.appLogo = '';

    this.getMetaTagSettings();

    this.isAdmin = this.role === 'Admin';
    if(this.currentUser){   
      this.isAdminApproved = this.currentUser.isAdminApproved ? this.currentUser.isAdminApproved : false;
      this.isLogged = true;
    }else {
      this.role = '';
      this.isLogged = false;
    }


    this.clientGlobal = this.clientFormService.getFormList().valueChanges().subscribe(form => {
      this.form = form[0];
      this.clientGlobal.unsubscribe();
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            // this.store.dispatch(AppActions.addAppSettings({ settings: this.model }));
          
            if(this.model.viewType){
              if(this.model.viewType == 'list' || this.model.viewType == 'card'){
                this.pageView ='web'
              }else{
                this.pageView = this.model.viewType
              }
            }else{
              this.pageView ='web'
            }

            this.model.gettingStarted = this.model.gettingStarted ? this.model.gettingStarted : this.gettingStarted          
            if(this.model && this.model.gettingStarted && this.model.gettingStarted.firstLogin){
              this.gettingStartedPercentage = 0
              this.isMenuLocked = true;
            }else if(this.model.gettingStarted && this.model.gettingStarted.pageSelection && this.model.gettingStarted.pageSelection.length > 0){
                this.model.gettingStarted.pageSelection.forEach(element => {
                  if(element.isEnabled){
                      this.selectedPageStatus.push({
                        originalName: element.originalName,
                        status: element.originalName == 'settings' ? this.model.gettingStarted['appSetup'] : this.model.gettingStarted[element.originalName]
                      })
                  }
                })
        
              let value = 0;
              // let length = (Object.keys(this.selectedPageStatus).length)-1;
              this.selectedPageStatus.forEach(el =>{
                if(el.status){
                  value +=1
                }
              })
              this.gettingStartedPercentage = Math.trunc((value/this.selectedPageStatus.length)*100)
              this.isMenuLocked = this.gettingStartedPercentage < 100
            }
            this.isBusinessApp = true;
            document.documentElement.style.setProperty('--color-primary',
              this.model.themeColor ? this.model.themeColor : this.defaultThemeColor);

            // this.navbarOption = this.model.navbarOption;
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.appLogo = this.model.logo ? this.model.logo : '';
            this.appName1 = this.model.appName ? this.model.appName : 'My App';
           // this.rightOrLeftSidebar = this.model.rightOrLeftSidebar;

            if (this.isBusinessApp) {
              this.productsServiceMenuService.productsName.subscribe(p => {
                this.productsName = p;
              });
              this.productsServiceMenuService.servicesName.subscribe(p => {
                this.servicesName = p;
              });
            }
          } else {
            document.documentElement.style.setProperty('--color-primary', this.defaultThemeColor);
            if(this.gettingStartedPercentage == 100){
              this.router.navigate(['/admin/homepage'])
            }else{
              this.router.navigate(['/admin/onboarding-page']);
            }
          }

        } else {
          document.documentElement.style.setProperty('--color-primary', this.defaultThemeColor);
          if(this.gettingStartedPercentage == 100){
            this.router.navigate(['/admin/homepage'])
          }else{
            this.router.navigate(['/admin/onboarding-page']);
          }
        }
      }
    );

    this.isDemo = environment.firstNation.name === 'Demo';

   
  }
  ngOnChanges(changes: SimpleChanges){
    if(this.pageName=='/admin/landing-page-settings'){
      this.minimizedMenu = true
    }
  }
  // this.pageName = this.router.url.split('?')[0]

  // prompt install
  promptInstall() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
        } else {
        }
        this.deferredPrompt = null;
        this.showInstallButton = false;
      });
    }
  }

  back() {
    this.location.back();
  }
  forward() {
    this.location.forward();
  }
  changeTheme() {
    if (this.theme == 'light') {
      this.theme = 'dark'
    } else {
      this.theme = 'light'
    }
  }

  signOut() {
    this.authService.signOut();
  }
  closeModal(){
    this.close = false;
  }
  OpenConfim(){
    this.close = true;
  }

  toggleSideBar() {
    this.openSidebar = !this.openSidebar;
  }
  closeSideBar() {
    this.openSidebar = false;
    this.communicationsActive = false;
    this.subscriptionsActive = false;
  }
  toggleMinimizeMenu() {
    this.minimizedMenu = !this.minimizedMenu;
  }

  backButton() {
    const fromPage = this.productsServiceMenuService.getCalledFromData()
    if(fromPage=='onBoarding'){
      this.productsServiceMenuService.setOnboardingSectionData('Blog')
      this.router.navigate(['/admin/onboarding-page']);
    }
    this.location.back()

  }

  redirectToServices() {
      this.router.navigate(['/admin/services']);
  }

  dropDown() {
    document.querySelector('#submenu').classList.toggle('hidden');
    document.querySelector('#arrow').classList.toggle('rotate-0');
  }

  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }

  navigate() {
    if (!this.form) {
      this.router.navigate(['/admin/users-list/import-contact'])

    } else {
      this.router.navigate(['/admin/customer-form'])
    }
  }

  navigateVerification() {
    this.router.navigate(['/admin/verify-account-request']);
  }
  closeAllCollapse() {
    this.openSubscribersViewCollapse = false;
    this.openManageAppCollapse = false;
    this.openOrganizationCollapse = false;
    this.openSubscribersMgtCollapse = false;
  }
  selectCollapseToOpen(setupName) {
    if(!this.isMenuLocked){
      if (setupName === 'SubscribersView') {
        if (this.openSubscribersViewCollapse === false) {
          this.closeAllCollapse();
          this.openSubscribersViewCollapse = true;
        } else {
          this.openSubscribersViewCollapse = false;
        }
      }
      if (setupName === 'ManageApp') {
        if (this.openManageAppCollapse === false) {
          this.closeAllCollapse();
          this.openManageAppCollapse = true;
        } else {
          this.openManageAppCollapse = false;
        }
      }
      if (setupName === 'Organization') {
        if (this.openOrganizationCollapse === false) {
          this.closeAllCollapse();
          this.openOrganizationCollapse = true;
        } else {
          this.openOrganizationCollapse = false;
        }
      }
      if (setupName === 'SubscribersManagement') {
        if (this.openSubscribersMgtCollapse === false) {
          this.closeAllCollapse();
          this.openSubscribersMgtCollapse = true;
        } else {
          this.openSubscribersMgtCollapse = false;
        }
      }
    }

  }
  redirectToSectionPage(section) {
    this.customSectionService.updateFromMenu(section.originalName);
    this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe((customSection: any) => {
      if (customSection.length > 0) {
        this.router.navigate(['/admin/custom-section-list/', customSection[0].id])
      }
    })
  }
  handleLinkClick(link){
    if(!this.isMenuLocked){
      this.router.navigate(link);
    }
    this.closeSideBar()
  }
  changeViewType(view){
    this.pageView = view
    this.model.viewType = view
      this.settingsService.updateAppSettings(this.model).then(() => {
      });

  }
  
  getMetaTagSettings() {
    this.settings = this.settingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
        this.routMeta.updateTitle(metaTags[0]?.appTitle ? metaTags[0]?.appTitle : 'title');
        // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(metaTags[0]?.description);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(metaTags[0]?.keyWords ? metaTags[0]?.keyWords : "");
  
      }
      this.settings.unsubscribe();

    });
  }
}

