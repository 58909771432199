<div class="flex">
    <div class="w-full md:w-full">
        <div class="min-h-screen font-body bg-veryLightGray max-h-max">
            <div class="flex flex-col flex-wrap px-4 py-2 md:flex-row md:gap-x-6 gap-y-4 ">
                <div
                    class="flex flex-col items-center w-full px-2 py-8 space-x-6 bg-white rounded-md md:items-start">

                    <div class="grid w-full grid-cols-3 gap-4 px-4 my-3">

                        <!-- first label -->
                        <div class="flex" *ngFor="let item of selectedContact | keyvalue">
                            <ng-container *ngIf="item?.key !== 'id' && item?.key !== 'created_at'">
                                <h1 class="text-sm font-bold">{{generatePascalCase(item.key)}} : </h1>
                                <h1 class="text-sm" *ngIf="item.key === 'created_at'">{{getCreatedDate(item.value)}}
                                </h1>
                                <h1 class="text-sm" *ngIf="item.key !== 'created_at'">{{item.value?.value}}</h1>
                            </ng-container>

                        </div>
                    </div>

                    <div class="bottom-0 flex space-x-4 flex-coljustify-between">
                        <button 
                           (click)="openModal()"
                            class="flex items-center justify-center px-4 py-2 space-x-2 text-sm font-bold text-white rounded-lg md:px-6 bg-customTeal min-w-max h-min md:text-base hover:bg-darkBlue focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                            </svg>
                            <span>Send Message</span>
                        </button>

                        <button 
                            class="flex items-center px-4 py-2 font-bold bg-white border rounded-md shadow-lg border-customTeal text-customTeal">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                class="inline w-4 h-4 mr-2 text-customTeal fill-customTeal">
                                <path
                                    d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                            </svg>
                            Delete
                        </button>



                    </div>
                </div>

            </div>

        </div>




    </div>
</div>


<div class="modal modal-open" *ngIf="openContactModal">
    <div class="max-w-4xl modal-box">
        <div class="modal-content">
            <h3 class="text-lg font-bold">Contact </h3>
            <p class="pt-4 pl-1 text-gray-400">You can contact this using email or SMS
                if the user
                registed
                his/her phonenumber</p>

            <form [formGroup]="contactForm" (ngSubmit)="sendClient(contactForm.value)">

                <div class="flex items-center mt-4 space-x-8 ">
                    <div class="flex-col px-2 text-black">Contact with:</div>

                    <div class="flex">
                    <div class="flex" *ngIf="EmailStatus">
                        <input type="radio" id="Email" value="Email" class="mx-2 cursor: text;"
                            formControlName="contact" [(ngModel)]=contactMsg
                            [disabled]="!EmailStatus">
                        <label for="">Email</label><br>
                    </div>
                    <div *ngIf="SMSStatus"> 
                        <input type="radio" id="Sms" value="SMS" class="mx-2 cursor: text;" formControlName="contact"
                            [(ngModel)]=contactMsg [disabled]="!SMSStatus">
                        <label for="">SMS</label><br>
                    </div>
                    </div>

                </div>
                <br />

                <div *ngIf="(contactMsg == 'SMS') ">
                    <div class="w-full px-2 mt-2">
                        <label for=" " class="mb-2 text-sm font-bold "> Message: </label>

                        <textarea id="message" formControlName="messageSMS"
                            class="w-full h-32 px-3 py-4 mt-2 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline "
                            placeholder="Message"></textarea>

                    </div>

                </div>

                <div *ngIf="contactMsg == 'Email'">
                    <div class="w-full px-2 mt-2 ">
                        <div>
                            <label for=" " class="mb-2 text-sm font-bold "> Subject: </label>
                            <input name="subject" formControlName="subject" id="subject"
                                class="block w-full px-3 py-3 mt-1 bg-white border rounded-md shadow-sm form-control border-slate-300 placeholder-slate-400 outline outline-offset-1 outline-1 focus:outline-1 focus:border-sky-500 focus:ring-sky-500 sm:text-sm focus:ring-1"
                                placeholder="Subject" />

                            <br />
                        </div>
                        <label for=" " class="mb-2 text-sm font-bold "> Message: </label>

                        <app-editor [control]="emailbody" class="bg-white" required
                            [fromComponent]="'message'"></app-editor>

                    </div>


                </div>

              

                <div class="flex justify-between mt-2">
                    <button type="button" (click)="cancelSendBtnClicked()"
                        class="text-black bg-white border-none btn hover:bg-gray-400 hover:text-white">Cancel</button>
                    <button [disabled]="!contactForm.valid" 
                        class="p-2 text-white border-none rounded-md bg-customTeal hover:bg-gray-100 hover:text-customTeal"
                        type="submit">Send Message</button>
                </div>

            </form>
            <div
                class="pt-2 mx-2 font-bold text-blue-600 underline underline-offset-2 text sm"
                [routerLink]="['/admin/app-setup']">Create Signature</div>

        </div>
    </div>
</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>