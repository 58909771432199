<div>
<div data-te-modal-init class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none "
    id="onboardingModal" tabindex="-1" aria-labelledby="rightTopModalLabel" aria-hidden="true" >
    <div data-te-modal-dialog-ref class="pointer-events-none absolute flex justify-end h-auto w-full overflow-x-hidden translate-x-[100%] opacity-0 transition-all duration-300 ease-in-out ">
        <div class="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto overflow-x-hidden relative overflow-y-scroll  h-screen flex w-full md:w-3/4 flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div  #topSection class="flex overflow-y-scroll flex-col md:flex-row flex-shrink-0 py-4 bg-white space-y-4 md:space-y-0 items-center md:justify-between rounded-t-md  border-neutral-100 border-opacity-100 px-4 dark:border-opacity-50">
                <div class=" w-full md:w-2/3 space-y-4">
                        <!--Modal title-->
                    <h5 class="md:w-1/2 w-full  text-xl font-bold text-customTeal leading-normal text-neutral-800 dark:text-neutral-200"
                        id="exampleModalLabel">
                            Customizing {{displayName}}!
                    </h5>
                    <div class="flex items-center space-x-4">
                                <!-- Skip the current setup -->
                                <button data-te-ripple-init *ngIf="sectionName!='appSetup'" type="button"
                                class="inline-block rounded bg-white text-customTeal border-2 border-gray-300 px-3 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                (click)="previousClicked()"
                                data-te-ripple-color="light">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="size-5 stroke-gray-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                  </svg>
                                  
                                </button>
                                <button data-te-ripple-init *ngIf="sectionName!=finalStep" type="button"
                                    class="inline-block rounded bg-white text-customTeal border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                    (click)="skipClicked()"
                                    data-te-ripple-color="light">
                                        Skip {{displayName}}
                                </button>
                                        
                                <!-- For the last page(social media) -->
                                <button *ngIf="sectionName==finalStep" type="button"
                                    class="inline-block rounded bg-white text-customTeal border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                    onclick="my_modal_1.showModal()" >
                                    Finish Setup
                                </button>
                        
                    </div>
                </div>
                <div class=" w-full md:w-1/3 flex flex-shrink-0 flex-wrap items-center md:justify-end rounded-b-md border-neutral-100 border-opacity-100 md:px-4 dark:border-opacity-50">
                    <!-- Preivew -->
                    <div class="px-4 rounded py-2 bg-customTeal mr-4 cursor-pointer" (click)="previewHomepage = true"> 
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-4 w-4" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
                        <p class="text-white hidden hover:block">Preview Home Page</p>
                    </div>     
       
                    <!-- Close modal -->
                    <button type="button"
                        class="inline-block rounded bg-custom-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-custom-700 transition duration-150 ease-in-out hover:bg-custom-accent-100 focus:bg-custom-accent-100 focus:outline-none focus:ring-0 active:bg-custom-accent-200"
                        data-te-modal-dismiss data-te-ripple-init data-te-ripple-color="light" id="onBoardingClose"
                        (click)="step=0">
                        Close
                    </button>
                </div>
            </div>
            <hr>
            <!-- Top progress bar -->
            <div  *ngIf="(allSteps && allSteps.length > 0)" class="flex gap-x-2 mt-2  md:space-x-3 w-full justify-start flex-wrap md:space-y-0 gap-y-4 items-center md:px-10 px-4 md:mx-auto md:py-4">
             
                <ng-container *ngIf="(allSteps && allSteps.length > 0) && !(this.appointmentType=='appointment' && this.sectionName=='appointment')">
                    <div *ngFor="let item of allSteps[0].quickGuide; let i =index"class="flex gap-x-2 items-center" >
                        <!-- *ngIf="i!=0 && (quickGuide[item].title =='Appointment Setting' && takeAppointment) || quickGuide[item].title !== 'Appointment Setting'" -->
                        <ng-container *ngIf="i!=0 ">
                            <i class="material-icons text-xl">arrow_forward_ios</i>
                        </ng-container>
                        <!-- (quickGuide[item].title =='Appointment Setting' && takeAppointment) || quickGuide[item].title !== 'Appointment Setting' -->
                        <div class="px-4 py-1 border hover:bg-customTeal hover:text-white cursor-pointer text-sm rounded-full border-solid border-customTeal shadow-md "
                            (click)="setupLandingPage = false" (click)="stepClicked(i)"
                            [ngClass]="{'  bg-customTeal text-white': step==i}">
                            <div *ngIf="(quickGuide[item].title).substring(0,5)=== 'Add a'; else notAnAddElement">
                                Add a {{displayName}}
                            </div>
                            <div >
                                
                                <ng-template #notAnAddElement>
                                    {{quickGuide[item].title}} 
                                </ng-template>
                            </div>

                        </div>
                    </div>
                </ng-container>
            </div>
        

            <div></div>

            <!-- Success card -->
            <ng-container *ngIf="displaySuccess">
                <!-- button type (next or close ) make close if  -->
                <app-success-card  [name]='successValue' [type]="successType" [displayName]="displayName" [sectionPreview] = 'sectionName'
                (progressEvent)="eventPageSelectedItem = $event" (keepAdding)="keepAdding($event)" (progressEvent)="nextClicked()" [serviceType]="serviceType" [appointmentType]="(this.appointmentType == 'both' ? 'Employee/Appointment': this.appointmentType)" [customSectionID]="customSection"></app-success-card>
            </ng-container>

            <!-- The steps -->
            <ng-container *ngIf="!displaySuccess" >
                <!-- Step 1 -->
                <ng-container *ngIf="step==0">
                    <div class="" *ngIf="sectionName=='appSetup'"> 
                        <app-general-setup calledFromEmptyDashboard="GeneralSetup"  (generalSetupSaved)="displaySuccessCard($event, 'create')"></app-general-setup>
                    </div>
                    <div *ngIf="sectionName=='homePage'">
                        <app-slider-setting calledFromEmptyDashboard='Slider' (carouselCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()"></app-slider-setting>
                    </div>
                    
                      
                    <!-- *ngIf="sectionName=='appointment' && takeAppointment.value " -->
                    <div *ngIf="(appointmentType == 'both' || appointmentType=='employee') && (sectionName=='appointment' || sectionName=='employee')">
                       
                        <div 
                        class="flex md:flex-row flex-col md:justify-center space-y-2 md:space-y-0 md:space-x-5 p-5 md:p-4">
                            <div class="flex space-x-2 cursor-pointer items-center px-8 py-3 border shadow-sm border-solid bg-white  rounded-md hover:border-customTeal hover:shadow-lg"
                                (click)="showCreated = true"
                                [ngClass]="{' text-customTeal border-b-4 border-solid border-customTeal': showCreated}">
                                <div><i class="material-icons mr-3 ">mark_email_read</i></div>
                                <div ><p class="font-bold">Show Invited Employee</p></div>
                                
                            </div>
                            <div class="flex space-x-2 cursor-pointer shadow-sm items-center px-8 py-3 border border-solid bg-white  rounded-md hover:border-customTeal hover:shadow-lg"
                                (click)="showCreated=false"
                                [ngClass]="{' text-customTeal border-b-4 border-solid border-customTeal': !showCreated}">
                                <div><i class="material-icons mr-3">add</i> </div>
                                <div ><p class="font-bold"> Invite Employee</p></div>
                                
                            </div>
                        </div>
                        <ng-container *ngIf="showCreated">
                            <app-invited-employees calledFrom="onBoarding"></app-invited-employees>
                        </ng-container>
                        <ng-container *ngIf="!showCreated" >
                            <div class="m-5 px-10">
                                <app-employee-form  employeeFromHome="Employee" (employeeInvited)="displaySuccessCard($event, 'create')" (skip)="nextClicked()"></app-employee-form>
                            </div>
                        </ng-container>
                    </div>
                    <!-- for rename sections -->
                    <div *ngIf="sectionName=='about' || sectionName=='product' || sectionName=='service' || sectionName=='event' || sectionName=='blog'">
                        <app-rename-section [section]="originalName" calledFromComponent="onboarding" (sectionRenamed)="displaySuccessCard($event, 'rename')"></app-rename-section>
                    </div>
                    <div *ngIf="sectionName=='audience' ">
                        <div class="space-y-5 mx-auto p-6">
                            <!--Approve sms-->
                            <app-app-setup calledFromEmptyDashboard='audienceManagement' selectedTab='chooseNumber'  (skip)="nextClicked()" (changesSaved)="displaySuccessCard($event, 'create')"></app-app-setup>
                        </div>
                    </div>
                    <div *ngIf="sectionName=='faq'">
                        <div class="flex md:flex-row flex-col md:justify-center space-y-2 md:space-y-0 md:space-x-5 p-5 md:p-4">
                            <div class="flex space-x-2 cursor-pointer items-center px-8 py-3 border shadow-sm border-solid bg-white  rounded-md hover:border-customTeal hover:shadow-lg"
                                (click)="showCreated = true"
                                [ngClass]="{' text-customTeal border-b-4 border-solid border-customTeal': showCreated}">
                                <div><i class="material-icons mr-3 ">mark_email_read</i></div>
                                <div><p class="font-bold">Show Created</p></div>
                            </div>
                            <div class="flex space-x-2 cursor-pointer shadow-sm items-center px-8 py-3 border border-solid bg-white  rounded-md hover:border-customTeal hover:shadow-lg"
                                (click)="showCreated=false"
                                [ngClass]="{' text-customTeal border-b-4 border-solid border-customTeal': !showCreated}">
                                <div><i class="material-icons mr-3">add</i> </div>
                                <div><p class="font-bold">Add New</p></div>
                            </div>
                        </div>
                        <div *ngIf="showCreated">
                            <app-faq-list calledFromComponent="faq"></app-faq-list>
                        </div>
                        <div *ngIf="!showCreated">
                            <app-faq-setting calledFromComponent="faq" [isShowCreated]="false" (faqCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()"  (goToTopOfNextPage)="goToTop()"></app-faq-setting>
                        </div>
                    </div>
                    <div *ngIf="sectionName=='custom'">
                        <div  class="flex md:flex-row flex-col md:justify-center space-y-2 md:space-y-0 md:space-x-5 p-5 md:p-4">
                            <div class="flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-5 ">
                                <div class="flex space-x-2 cursor-pointer items-center px-8 py-3 border shadow-sm border-solid bg-white  rounded-md hover:border-customTeal hover:shadow-lg"
                                    (click)="showCreated = true"
                                    [ngClass]="{' text-customTeal border-b-4 border-solid border-customTeal': showCreated}">
                                    <div><i class="material-icons mr-3 ">mark_email_read</i></div>
                                    <div><p class="font-bold">Show Created</p></div>
                                </div>
                                <div class="flex space-x-2 cursor-pointer shadow-sm items-center px-8 py-3 border border-solid bg-white  rounded-md hover:border-customTeal hover:shadow-lg"
                                    (click)="showCreated=false"
                                    [ngClass]="{' text-customTeal border-b-4 border-solid border-customTeal': !showCreated}">
                                    <div><i class="material-icons mr-3">add</i> </div>
                                    <div><p class="font-bold">Add New</p></div>
                                </div>
                            </div>
                        </div>
                           
                            <div *ngIf="showCreated">
                          
                                <app-custom-sections-list calledFromComponent="custom"></app-custom-sections-list>
                            </div>
                            <div *ngIf="!showCreated">
                                <app-create-new-section 
                                calledFromEmptyDashboard='Custom'
                                (customCreated)="displaySuccessCard($event, 'create')"
                                (goToTopOfNextPage)="goToTop()"
                                ></app-create-new-section>
                            </div>
                    </div>
                    <div *ngIf="sectionName=='socialMediaPage'">
                        <div class="space-y-5  md:px-10 px-4 bg-gray-50 mx-auto shadow-md p-6">
                            <div class="space-y-4">
                                <div>
                                    <div class="flex space-x-2 items-center">
                                    <p class="font-bold text-customTeal">
                                        Social media integration
                                    </p>
                                    <!-- <svg *ngIf="pageSections.gettingStarted?.socialMediaPage" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-customTeal">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg> -->
                                    </div>
                                    <p class="text-sm text-gray-500">Connect your app to all your social media accounts and post directly from your app.</p>
                                </div>
                                <button
                                    (click)="finalizeSetup()"
                                    type="button" data-te-ripple-init
                                    data-te-ripple-color="light"
                                    class="inline-block rounded h-min bg-customTeal w-max px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                                    Go to Socials Page
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Step 2 -->
                <ng-container *ngIf="step==1">
                    <div *ngIf=" sectionName=='product' || sectionName=='service' || sectionName=='event' || sectionName=='blog' || sectionName=='about'"
                    class="flex md:flex-row flex-col md:justify-center space-y-2 md:space-y-0 md:space-x-5 p-5 md:p-4">
                        <div class="flex space-x-2 cursor-pointer items-center px-8 py-3 border shadow-sm border-solid bg-white  rounded-md hover:border-customTeal hover:shadow-lg"
                            (click)="showCreated = true"
                            [ngClass]="{' text-customTeal border-b-4 border-solid border-customTeal': showCreated}">
                            <div class="flex space-x-2" ><i class="material-icons mr-3 ">mark_email_read</i>
                                <p class="font-bold">Show Created</p>
                            </div>            
                        </div>
                        <div class="flex space-x-2 cursor-pointer shadow-sm items-center px-8 py-3 border border-solid bg-white  rounded-md hover:border-customTeal hover:shadow-lg"
                            (click)="showCreated=false"
                            [ngClass]="{' text-customTeal border-b-4 border-solid border-customTeal': !showCreated}">
                            <div class="flex space-x-2"><i class="material-icons mr-3">add</i>
                            <p class="font-bold">Add New</p></div>
                        </div>
                    </div>
                    <div class="" *ngIf="sectionName=='appSetup'" >
                        <app-brand-setup  calledFromEmptyDashboard="BrandSetup"   (brandSetupSaved)="displaySuccessCard($event, 'create')" ></app-brand-setup>
                    </div>
                    <div *ngIf="sectionName=='homePage'">
                        <app-welcome-setting  calledFromEmptyDashboard='Popup'  
                        (welcomePopupCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" (previewSelected)="fromPreview = true" (previewClosed)="closePreview()"></app-welcome-setting>
                    </div>
                    <div *ngIf="(appointmentType == 'both' || appointmentType=='appointment') && sectionName=='appointment'" class="md:px-10">
                      
                        <app-appointment-setting calledFromEmptyDashboard='Appointment Setting' (settingSaved)="displaySuccessCard($event, 'create')"></app-appointment-setting>
                    </div>
                    <div *ngIf="sectionName=='about'" class="block rounded-lg p-6 bg-gray-100">
                        <ng-container *ngIf="showCreated">
                            <app-about-setting calledFromEmptyDashboard = 'About' (aboutCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" [isShowCreated]="true"></app-about-setting>
                        </ng-container>
                        <ng-container *ngIf="!showCreated">
                            <app-about-setting calledFromEmptyDashboard = 'About' (aboutCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" [isShowCreated]="false" (previewSelected)="fromPreview = true" (previewClosed)="closePreview()"  (goToTopOfNextPage)="goToTop()"></app-about-setting>                
                        </ng-container>
                    </div>
                    <div *ngIf="sectionName=='product'">
                        <ng-container *ngIf="!showCreated">
                            <app-create-product  calledFromEmptyDashboard='Product' (productCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" (previewSelected)="fromPreview = true" (previewClosed)="closePreview()" (goToTopOfNextPage)="goToTop()"></app-create-product>
                        </ng-container>
                        <ng-container *ngIf="showCreated">
                            <app-products calledFrom="onBoarding"></app-products>
                        </ng-container>
                    </div>
                    <div *ngIf="sectionName=='service'">
                        <ng-container *ngIf="!showCreated">
                            <app-create-service calledFromEmptyDashboard='Service' (serviceCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" (previewSelected)="fromPreview = true" (previewClosed)="closePreview()" (goToTopOfNextPage)="goToTop()"> </app-create-service>
                        </ng-container>
                        <ng-container *ngIf="showCreated">
                            <app-services-list calledFrom="onBoarding"></app-services-list>        
                        </ng-container>
                    </div>
                    <div *ngIf="sectionName=='event'">
                        <ng-container *ngIf="!showCreated">
                            <app-create-event calledFromEmptyDashboard='Event' (eventCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" (previewSelected)="fromPreview = true" (previewClosed)="closePreview()" (goToTopOfNextPage)="goToTop()"></app-create-event>
                        </ng-container>
                        <ng-container *ngIf="showCreated">
                            <app-view-events-list calledFrom="onBoarding"></app-view-events-list>    
                        </ng-container>
                    </div>
                    
                    <div *ngIf="sectionName=='blog'">
                        <ng-container *ngIf="!showCreated">
                            <app-create-blog calledFromEmptyDashboard = 'Blog' (blogCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" (previewSelected)="fromPreview = true" (previewClosed)="closePreview()" (goToTopOfNextPage)="goToTop()"></app-create-blog>
                        </ng-container> 
                        <ng-container *ngIf="showCreated">
                            <app-blog-list calledFrom="onBoarding"></app-blog-list>
                        </ng-container> 
                    </div>
                    <div *ngIf="sectionName=='audience'">
                        <div class="space-y-5 mx-auto p-6">
                            <!-- <app-import-contact (skip)="nextClicked()"></app-import-contact> -->
                            <div>
                                <!-- <app-app-setup calledFromEmptyDashboard='audienceManagement' selectedTab='email'  (skip)="nextClicked()" (changesSaved)="displaySuccessCard($event, 'create')"></app-app-setup> -->
                                <app-app-setup calledFromEmptyDashboard='audienceManagement' selectedTab='smsCustom'  (changesSaved)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" ></app-app-setup>
                    
                            </div>
                        </div>

                    </div>

                    <div  *ngIf="sectionName=='custom'" class="space-y-6">
                         <app-custom-sections-list calledFromDashboardCreate="true" (customSection)="createCustomSection($event)"></app-custom-sections-list>
                         <div *ngIf="customSection">
                            <app-create-section-element [sectionID]="customSection" calledFromOnboarding="custom" (customElementCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" (goToTopOfNextPage)="goToTop()"></app-create-section-element>
                         </div>
                    </div>
                </ng-container>

                <!-- Step 3 -->
                <ng-container *ngIf="step==2">
                <div *ngIf="sectionName=='appSetup'" class="md:px-10">
                        <app-seo-setting (seoCreated)="displaySuccessCard($event, 'create')" (seoCreated)="displaySuccessCard($event, 'create')"></app-seo-setting>
                    </div>
                    <div *ngIf="sectionName=='homePage'">
                        <app-footer-setting calledFromEmptyDashboard='Footer' (footerCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()"></app-footer-setting>
                    </div>
                    <div  *ngIf="sectionName=='audience' " class="mx-10">
                        <app-app-setup calledFromEmptyDashboard='audienceManagement' selectedTab='sms'  (changesSaved)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" ></app-app-setup>
                        <!-- <app-app-setup calledFromEmptyDashboard='audienceManagement' selectedTab='seo' (customized)="displaySuccessCard($event, 'create')" (changesSaved)="displaySuccessCard($event, 'create')" (skip)="nextClicked()"></app-app-setup> -->
                    </div>
                </ng-container>

    
                <!-- Step 4 -->
                <ng-container *ngIf="step==3">
                    <div *ngIf="sectionName=='homePage'">
                        <app-contact-settings calledFromEmptyDashboard = 'linkSocials' (socialsCreated)="displaySuccessCard($event, 'create')" (skip)="nextClicked()"></app-contact-settings>
                    </div>

                    <div  *ngIf="sectionName=='audience' " >
                        <app-app-setup calledFromEmptyDashboard='audienceManagement' selectedTab='seo'  (changesSaved)="displaySuccessCard($event, 'create')" (skip)="nextClicked()" ></app-app-setup>
                        <!-- <app-app-setup calledFromEmptyDashboard='audienceManagement' selectedTab='seo' (customized)="displaySuccessCard($event, 'create')" (changesSaved)="displaySuccessCard($event, 'create')" (skip)="nextClicked()"></app-app-setup> -->
                    </div>
                    
                </ng-container>

            </ng-container>
        </div>
    </div>
    <app-preview-hompage *ngIf="previewHomepage" [view]="view"  class="w-full z-[2300]" [modalOpen] = 'true' (viewType)="changeViewType($event)" (closeModal)="previewHomepage = false"></app-preview-hompage>
</div>
</div>