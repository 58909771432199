<div class="w-full min-h-screen pt-6 font-body max-h-max">
    <div
        class="flex flex-col p-4 mt-2 space-y-4 bg-white rounded-lg m-4 md:m-0 md:w-4/6 h-max md:p-12 md:translate-x-1/4">
        <div class="mt-4 text-xl text-center font-bold text-customTeal md:text-left  md:mt-0">Customize contact header
        </div>

        <p class="flex items-center gap-2 text-xs text-customTeal">
            <i class="text-sm text-white rounded-full bg-customTeal material-icons h-fit">add</i>
            If you have not specified the field name to be used during the import of contacts, this icon will be
            displayed
        </p>

        <div class="flex flex-row items-end justify-end w-full gap-2">
            <button (click)="navigateToFieldForm()"
                class="flex items-center space-x-2 rounded bg-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    class="w-4 h-4 stroke-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                <span>Add Field</span>
            </button>
        </div>

        <div class="flex my-3 mb-8 space-x-10">
            <div class="grid w-full grid-cols-1 md:grid-cols-2 gap-3 px-4 md:px-0 ">
                <div class="flex flex-col space-y-2 md:w-full " *ngFor="let field of baseFields; let i= index">
                    <label for=" " class="flex items-center gap-1 text-xs font-bold text-gray-500">{{field?.name}}
                        <span class="text-red-500 " *ngIf="field?.required">*</span>
                        <button type="button" (click)="openModal(field)" *ngIf="!field?.active">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4 stroke-customTeal">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </button>
                        <button type="button" *ngIf="field?.active" (click)="openEditModal(field)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4 stroke-customTeal">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>
                        </button>
                        <!--                     
                        <i class="p-1 text-sm text-white rounded-sm bg-purple-500 material-icons" >edit</i>
                        <i class="text-xs text-white bg-red-600 rounded-sm material-icons" *ngIf="field?.active && !field?.default"
                            (click)="openEditModal(field)">delete</i> -->
                    </label>
                    <input maxlength="60" class="w-full  input input-bordered rounded-md border-gray-300  md:w-full"
                        [type]="field?.type" [placeholder]="field?.placeholder" disabled>
                </div>

            </div>
            <!-- <hr class="bg-green-500 border-4"/> -->
        </div>
        <hr />
        <div class="w-full">
            <label (click)="downloadSampleCSV()"
                class="flex cursor-pointerjustify-start items-center text-green-500 text-xs w-full  px-4 py-2 transition bg-white border-2 border-green-500 border-dashed rounded-md appearance-none cursor-pointer focus:outline-none">
                Reminder: You can download a sample CSV file from here if needed.
            </label>
        </div>
        <hr />

        <div class="w-full">
            <label
                class="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                <span class="flex items-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-600" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <span class="font-medium text-gray-600">
                        Drop files to Attach, or
                        <span class="text-blue-600 underline">browse</span>
                    </span>
                </span>
                <input type="file" name="file_upload" class="hidden"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    (change)="csvImport($event)">
            </label>
        </div>

    </div>
</div>

<app-alert class="fixed top-0 flex justify-center w-full" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<app-modal [modalTitle]="modalTitle" btnName="Yes" *ngIf="showModal" (cancelBtnClicked)="closeModal()"
    (btnClicked)="submitFieldType()">
    <hr />
    <!--<p class="mb-1 text-center text-md">Add field name for <span class="font-semibold text-green-500"> {{selectedField?.label}}</span>
    </p>-->
    <div class="flex flex-col md:space-y-2 md:w-full ">
        <label for=" " class="text-xs font-bold text-gray-500 "> Field Name
        </label>
        <input class="w-full bg-gray-100 input input-bordered input-primary md:w-full" type="text"
            placeholder="fieldName" [(ngModel)]="formFieldName">
    </div>

</app-modal>

<div class="modal modal-open" *ngIf="showFieldModal">
    <div class="modal-box rounded-md">
        <div class="flex justify-between items-center">
            <h3 class="mb-2 text-lg font-bold text-center">Field Information</h3>
            <button (click)="closeModal()">
                <svg class="w-6 h-6 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd" />
                </svg>
            </button>

        </div>
        <!-- Modal Body -->
        <div class="modal-body">
            <div class="flex flex-col items-center justify-center w-full">
                <!-- field label -->
                <div class="flex flex-col w-full gap-2 mt-4">
                    <label for="fieldName" class="flex gap-3 text-sm ">Field Name from the original document <span
                            class="text-red-500">*
                        </span>
                        <i class="flex justify-end text-base cursor-pointer material-icons text-customTeal"
                            (click)=" selectedFieldForInfo == 'fieldName' ? selectedFieldForInfo = null : selectedFieldForInfo = 'fieldName'">info</i>
                    </label>
                    <div *ngIf="selectedFieldForInfo === 'fieldName'"
                        class="flex items-center justify-center gap-2 p-2 border-2 border-solid border-sky-500/10 ">
                        <i class="flex justify-end text-sm material-icons text-customTeal ">info</i>
                        <p class="text-sm ">This field name should be the same as with the one specified on the doc
                        </p>
                    </div>
                    <input id="fieldName" type="text" [(ngModel)]="fieldForm.fieldName" disabled="true"
                        placeholder="Use the name used on the excel sheet"
                        class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline" />
                </div>

                <div class="flex flex-col w-full gap-2  mt-4" *ngIf="!selectedField && !selectedFieldForEdit?.default">
                    <label for="fieldName" class="text-sm ">Field Label <span class="text-red-500">*</span></label>
                    <input id="fieldName" type="text" [(ngModel)]="fieldForm.fieldLabel" disabled="true"
                        class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline" />

                </div>
            </div>
        </div>

        <div class="items-center justify-between modal-action">
            <!-- <button (click)="closeModal()"
                class="flex items-center space-x-2 rounded bg-gray-500 border border-solid border-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white"><span>Cancel</span></button> -->

            <div class="flex gap-2 w-full">
                <button *ngIf="showFieldModal && showDeleteButton()" (click)="editField()"
                    class="flex items-center justify-center space-x-2 rounded bg-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] w-full"
                    type="submit"><span>Go to edit page</span>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- show confirmation modal -->
<div class="modal modal-open" *ngIf="showConfirmationModal">
    <div class="modal-box">
        <h3 class="mb-2 text-xl font-bold text-center">{{baseFieldBoolean?.length ? 'Contact Import Confirmation': 'Are
            you sure you want to import the contacts?'}}</h3>

        <!-- Modal Body -->
        <div class="py-3 modal-body" *ngIf="baseFieldBoolean?.length">
            <p class="gap-3 mb-1 text-sm font-bold text-center font">Some fields are missing, We could not import</p>
            <div class="flex justify-center gap-1 flex-wrap">
                <p class="flex gap-1 mb-1 text-sm text-center text-customTeal flex-wrap"
                    *ngFor="let selectedField of baseFieldBoolean; let i = index">
                    {{selectedField?.field}}
                    <span class=" text-customTeal" *ngIf=" i != baseFieldBoolean?.length - 1 "> ,</span>

                </p>

            </div>
        </div>

        <!-- show email that already been  imported -->
        <div class="gap-3 py-3 border border-t-2 border-gray-600 modal-body" *ngIf="existingContactEmails?.length">
            <p class="mb-1 text-sm font-bold text-center ">There are duplicate email addresses within the system.</p>
            <div class="flex flex-wrap justify-center gap-1">
                <p class="flex flex-wrap gap-1 mb-1 text-sm text-center text-customTeal"
                    *ngFor="let contactEmail of existingContactEmails; let i = index">
                    {{contactEmail}}
                    <span class=" text-customTeal" *ngIf=" i != existingContactEmails?.length - 1 "> ,</span>

                </p>

            </div>
        </div>

        <div class="items-center justify-between modal-action">
            <label (click)="closeConfirmationModal()"
                class="p-2 font-bold text-customTeal hover:border-customTeal hover:rounded-md hover:font-normal hover:border-2 hover:p-2">
                Cancel
            </label>
            <label>
                <button class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal" type="button"
                    (click)="importToDB()">Continue</button>
            </label>
        </div>
    </div>
</div>