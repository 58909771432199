<div class="font-body min-h-screen max-h-max pt-6 md:max-w-7xl mx-auto">
    <div class="flex items-center space-x-2 px-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
        </svg>
        
        <p class="font-bold text-xl mx-4  text-black ">Get Verified </p>
    </div>
    <form [formGroup]="verifyForm" (ngSubmit)="updateForm(verifyForm.value)">
        <div class="flex flex-col mx-4  m-4 bg-white h-max p-4 md:p-12 rounded-lg space-y-4">
            <p class="py-2 text-sm font-bold">Basic Information</p>
            <div class="flex items-center text-sm text-yellow-500"><i class="material-icons">warning</i> You won't be able to
                edit your Personal Information. If you want to modify you personal infomation edit in your profile
                section </div>
            <div class="flex flex-col pt-2 space-y-4 mb-4 md:flex-col  ">
                <div class="px-4 md:px-0 space-y-4 md:space-y-0  md:grid grid-cols-2 gap-6">
                    <div class="flex space-y-2 md:w-80  flex-col">
                        <label for=" " class="text-xs text-gray-500 font-bold "> First Name <span
                                class="text-red-500">*</span> </label>
                        <input maxlength="60" [(ngModel)]="names" [readonly]="true" formControlName="firstName"
                            class=" border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
                            type="text" placeholder="Jane" required>
                        <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                            <small *ngIf="firstName.errors.required" class="text-red-500"> The First Name is required.
                            </small>
                        </div>
                    </div>

                    <div class="flex space-y-2 md:w-80 flex-col ">
                        <label for=" " class="text-xs text-gray-500 font-bold "> Last Name <span
                                class="text-red-500">*</span> </label>
                        <input formControlName="lastName" [(ngModel)]="lname" [readonly]="true"
                            class=" border bg-gray-100 rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
                            type="text" placeholder="John" required>
                        <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                            <small *ngIf="lastName.errors.required" class="text-red-500"> The Last Name is required.
                            </small>
                        </div>
                    </div>

                    <div class="flex space-y-2 md:w-96 flex-col">
                        <label for=" " class="text-xs text-gray-500 font-bold "> Email <span
                                class="text-red-500">*</span></label>
                        <input type="email" formControlName="email" [readonly]="true"
                            class="peer border bg-gray-100 rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline"
                            id="Email" type="email" placeholder="JaneJohn@gmail.com" required />

                        <div *ngIf="(email.dirty || email.touched) && email.invalid">
                            <small *ngIf="email.errors.required" class="text-red-500"> The Email is required.
                            </small>
                        </div>
                    </div>

                    <div class="flex space-y-2 md:w-96 flex-col ">
                        <label for=" " class="text-xs text-gray-500 font-bold "> Mobile </label>
                        <input type="text" formControlName='cellPhone' 
                            id='cellPhone' placeholder="000-000-0000" [readonly]="true"
                            class="bg-gray-100 w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " />

                    </div>
                </div>



            </div>
        </div>


        <div class="flex flex-col mx-4  m-4 bg-white h-max p-4 rounded-lg space-y-4">
            <label for=" " class="text-xs text-gray-500 font-bold "> Upload Image <span
                class="text-red-500">*</span></label>
            <div class="flex flex-col space-y-4 mb-4 md:flex-col">
                <div class="px-4 md:px-0 space-y-4 md:space-y-0  md:grid grid-cols-2 gap-6">                        
                    <div class="flex space-y-2 md:w-96 flex-col">
                        <div class="flex w-full space-x-16 justify-between">
                            <div
                                class="flex px-12 content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">
                                <label class="custom-file-upload  self-center flex flex-col ">
                                    <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                                    <input type="file" type="file" accept="file"
                                        (change)="handleFileInput($event.target.files)" formControlName="pdfLink" />
                                    <span class="block font-bold pt-1 text-sm">Upload Image File</span>
                                </label>
                            </div>
                        
                            <!-- <ng-container *ngIf="binDoc">
                                <div class=" flex gap-x-3 px-4 pt-4">
                                    <i class="material-icons float-left text-4xl">feed</i><span
                                        class="font-bold break-all">{{binDoc.name}}</span>
                                </div>
                                <button (click)="removeFile()"
                                    class="rounded-full bg-white px-4 py-2 w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                    <i class="fa fa-trash text-md"></i>
                                </button>
                            </ng-container> -->

                            <div class="mx-4" *ngIf="base64Image">
                                <!-- <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Image</p> -->
                                <div class="flex flex-wrap gap-8">

                                    <div class="relative">
                                        <img loading="lazy" [src]="base64Image" class="object-cover w-72 rounded-md cover"
                                            alt="Image">
                                        <button (click)="removeFile()"
                                            class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4 hover:border-customTeal shadow-md">
                                            <i class="fa fa-trash text-md"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
                            <strong><small>{{imgErrorMsg}}</small></strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="flex flex-col mx-4 md:mx-24 m-4 bg-white h-max p-4 md:p-12 rounded-lg space-y-4">
            <div class="flex flex-col space-y-4 mb-4 md:flex-col  ">                  
                
                <div class="px-4 md:px-0 space-y-4 md:space-y-0  md:grid grid-cols-2 gap-6">

                    <div class="flex space-y-2 md:w-96 flex-col" *ngIf="!userApplied">
                        <label for=" " class="text-xs text-gray-500 font-bold "> Security Question </label>
                        <select formControlName="securityQuestion" 
                            class="validate py-3 px-4 select select-bordered w-full bg-gray-100"
                            (change)="answerTypeChangeState($event.target.value)">
                            <option value="" disabled selected>Please Select the State </option>
                            <option *ngFor="let secure of security" [value]="secure" title="Tooltip">
                                {{secure}}
                            </option>
                        </select>
                    </div>

                    <div class="flex space-y-2 md:w-96 flex-col" *ngIf="!userApplied">
                        <label for=" " class="text-xs text-gray-500 font-bold "> Answer 
                        </label>
                        <input formControlName="answer"
                            class="bg-gray-100 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96"
                            type="text" placeholder="answer" >
                    </div>
                </div>

                <div class="flex flex-col w-full ">
                  
                    <div class="flex justify-between mt-2 w-1/2">
                       
                    </div>
                </div>

                <div class="flex items-center space-x-2">
                    <input formControlName="acceptance"
                        class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-2 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer"
                        type="checkbox" value="" id="accept" required><span>I assure that all the information provided
                        is true, any false information will lead to </span>
                </div>

            </div>
        </div> -->

        <div class="px-8 py-3  md:py-6 flex justify-between w-full">
            <button type="button"
                class="flex space-x-2 px-4 md:px-6 py-2 text-black w-max h-min text-sm md:text-base font-bold ">
                <span class="font-bold">Cancel</span>
            </button>
            <button type="button" (click)="openSignature()"
                class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  "
                [disabled]="!verifyForm.valid">
                <span>Submit</span>
        </button>
        </div>

        <div id="my-modal-signature" class="modal" *ngIf="signModal"
        [ngClass]="{'modal-open': signModal, 'modal-action': !signModal }">
      
        <div class="modal-box">
          <div class="modal-content">
          <div (click)="closeSignature()" class="flex justify-end"><i class="material-icons">close</i></div>
            <h3 class="font-bold text-lg text-center mb-2">Signature</h3>
            <hr />
            <span class=""><canvas class="border-2 border-black-200 bg-gray-100" style="height: 90%; width: 100%;" *ngIf="!userApplied" ></canvas></span>
      
            <!-- <div class="flex justify-center">
                <input [(ngModel)]="answer" type="text" class="mx-8 flex justify-center bg-gray-100 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96" placeholder="Answer">
            </div> -->
      
            <div class="modal-action items-center justify-between m-4">
              <button type="submit" for="my-model"><button class="font-bold border-none text-white bg-customTeal px-4 py-2" type="submit"
                  >Submit Signature</button></button>
              <label (click)="clearSignature()" class="px-4 py-2 rounded-md text-white bg-customTeal" *ngIf="!userApplied">Clear</label>
            </div>
          </div>
        </div>
      </div>
      


        <!-- </div> -->
    </form>
</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>


    <!-- <div id="my-modal-securityQuestion" class="modal" *ngIf="securityModal"
    [ngClass]="{'modal-open': securityModal, 'modal-action': !securityModal }">

    <div class="modal-box">
      <div class="modal-content">
        <h3 class="font-bold text-lg text-center mb-2">Security Question</h3>
        <hr />
        <p class="py-2 text-center">Clients which are not able to answer the security questions wont be able to request for the reapplication.</p>
        <hr />
        <p class="text-center py-2 font-bold" *ngIf="userApplied">{{userApplied.securityQuestion}}</p>
        <div class="flex justify-center">
            <input [(ngModel)]="answer" type="text" class="mx-8 flex justify-center bg-gray-100 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-96" placeholder="Answer">
        </div>
        <div class="modal-action items-center justify-end m-4">
          <label for="my-model"><button class="font-bold border-none text-white bg-customTeal px-4 py-2" type="button"
              (click)="secured()">Submit</button></label>
        </div>
      </div>
    </div>
  </div> -->




