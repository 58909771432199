
<div class=" mt-5"  [ngClass]="{'w-full ': preview && view=='mobile', 'md:mx-20 mx-5 ' : !preview || (preview && view=='web')}" >
  <!-- FAQ list -->
  <div>
    <div class="font-bold text-lg text-gray-600">
      <div *ngIf="calledFromComponent!='landingpage'">FAQ Lists</div>

      <div *ngIf="!calledFromComponent && (currentUser && !isUser)">
        <div class="flex md:justify-start justify-center  gap-4 ">
          <!-- Add new btn -->
          <button (click)="navigateToCreateFaq()"
            class="flex flex-col md:flex-row items-center gap-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] border border-customTeal">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              class="w-4 h-4 stroke-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <span>Add New</span>
          </button>

      <app-homepage-section-number section="faq" [length] = "getSortedList(faqsList).length" (confirmationModal) = "openConfirmationModal()"></app-homepage-section-number>
  
          <!-- Arrange items -->
          <button type="button"
            (click)="openMoveModal=true"
            class="flex flex-col md:flex-row gap-2 items-center bg-white rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10">
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
              </path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
            <span>Arrange Items</span>
          </button>
          <div >
            <app-rename-section section="faq" ></app-rename-section>
          </div>
        </div>
      </div>
      <!-- List -->
      <!-- <div *ngFor="let faq of getSortedList(faqsList) | paginate: { itemsPerPage: 6, currentPage: p }"> -->
      <div *ngFor="let faq of getSortedList(faqsList) | slice:0:itemNumber">
        <div class="flex ">
          <!-- FAQ -->
          <div  class="collapse collapse-arrow bg-white rounded-sm my-2 border border-solid border-gray-200">
            <input type="checkbox" name="my-accordion-2" class="peer" />
            <div class="collapse-title text-xl font-semibold text-customTeal" [innerHTML]="faq.question">
            </div>
            <div class="collapse-content text-base" [innerHTML]="faq.answer">
            </div>
          </div>
  
          <div class="flex items-center" *ngIf="(currentUser && !isUser) && !calledFromComponent">
            <div (click)="editFAQ(faq)"
                class="cursor-pointer p-3 h-min rounded-full border-solid text-customTeal">
              <i class="material-icons gray-text-600">edit</i>
            </div>
            <div (click)="deleteOpen(faq)"
              class="cursor-pointer h-min p-3 rounded-full border-solid text-gray-500">
              <i class="material-icons gray-text-600">delete</i>
            </div>
          </div>
        </div>
      </div>

     


      <!-- Pagination -->
      <div class="w-full text-center mt-10">
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
      </div>
    </div>
  </div>
</div>

<!-- Edit faq modal -->
<div class="modal" id="editFaqModal" [ngClass]="{'modal-open': openEditFaqModal, 'modal-action': !openEditFaqModal }">
  <div class="modal-box md:w-10/12 w-11/12 max-w-4xl" >
    <p>Edit FAQ</p>
    <form [formGroup]="editFaqForm" (ngSubmit)="saveFaqChanges(editFaqForm.value)">
      <div class="lg:px-12 px-5 ">
        <!-- Question -->
        <div class="mt-6  ">
          <label for="Title" class="block tracking-wide text-gray-700 text-sm font-bold mb-2"> Question
            <span class="text-red-500">*</span></label>
            <app-editor [control]="question"  class="bg-gray-100 " [inputType]="'question'" fromComponent="FAQ"></app-editor>
          <div class="flex justify-end">
            <app-button class="justify-end items-end"
              [customConfig]="{ text: 'Use ChatGpt', type: 'Button', buttonType: 'secondaryButton' }"
              (click)="onUseChatGpt()"></app-button>
          </div>
        </div>

        <!-- Answer -->
        <div class="w-full my-6">
          <label for="Description" class="block text-sm font-bold mb-2 ">Answer
            <span class="text-red-500">*</span> </label>
            <app-editor [control]="answer"  class="bg-gray-100 " [inputType]="'answer'" fromComponent="FAQ"></app-editor>
          <div class="flex justify-end">

            <app-button class="justify-end items-end"
              [customConfig]="{ text: 'Use ChatGpt', type: 'Button', buttonType: 'secondaryButton' }"
              (click)="onUseChatGptA()"></app-button>
          </div>
        </div>

        <!-- Action buttons -->
        <div class="flex justify-between">
          <div class="text-red-500 py-2 px-6 rounded-[5px]" style="border: 1px solid red;" (click)="openEditFaqModal = false;">
            Cancel
          </div>
          <div>
            <button (click)="editModalOpen=true" type="button"
              [ngClass]="{'bg-[#cccccc] text-[#666666]': !editFaqForm.valid}" [disabled]="!editFaqForm.valid"
              class="py-2 px-6 gap-2 bg-customTeal rounded-md text-white float-right">Save changes</button>

          </div>
        </div>

      </div>
    </form>
  </div>
</div>


<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteFAQ()
  (cancelDeleteClicked)="deleteModalOpen=false" deletedFromComponent="FAQ"></app-delete-confirmation>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal()
  (saveBtnClicked)=saveFaqChanges(editFaqForm.value) editedFromComponent='FAQ'></app-edit-confirmation>

<!-- ChatGpt Modal -->
<app-chat-gpt *ngIf="chatGptModalOpen" [theInput]="selectedInput" [fromComponent]="'FAQ'"
  [requestMessage]="requestMessage" (onMessageSelect)="updateFAQquestion($event)"
  (closeChatGptModal)="closeChatGptModal()"></app-chat-gpt>

<!-- Move elements -->
<app-move-elements *ngIf="openMoveModal" [elementsList]="faqsList" sectionType="FAQ"
  (closeClicked)="openMoveModal=false"></app-move-elements>