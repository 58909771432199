import { UserService } from "./user.service";
import { Injectable, Injector } from "@angular/core";
// import  {auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { MemberModel } from "src/app/models/memberModel";
import { FireMembersService } from "./fire-members.service";
import { Observable, Subscription } from "rxjs";
import { GoogleAnalyticsService } from "../google-analytics/analytics.service";
import { environment } from "src/environments/environment";
import { AppSettingsService } from "./app-settings.service";
// import { NotificationService } from './notification.service';
import { FCMpushService } from "./FCMpush.service";
import { AccountService } from "../api/account.service";
import { ClientAnswersService } from "./client-answers.service";
import { EmailService } from "../api/email.service";
import { EmailModel } from "src/app/models/emailModel";
import { PermissionService } from "./permission.service";
import { FCMService } from "../api/fcm.service";
import { take } from "rxjs/operators";
import { FCMTokenService } from "./FCMToken.service";
import { ScheduleService } from "./employee-schedule.service";
import { GetAppService } from "./get-app.service";
import { Store } from "@ngrx/store";
import { selectAppSettings } from "src/app/state/app.selectors";
import { HelperService } from "src/app/services/helper/helper";

// declare var M: any;
declare var gapi: any;
declare var google: any;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser: any = null;
  fireMember: Observable<any>;
  member: Observable<any>;
  isLogged = false;
  role = "";
  status = "";
  verified = false;
  reason: any;
  userID: string;
  GivenName = "";
  loggedInUser: any;
  confirmationResult: any;
  public isNewUser = false;
  globalUser: any;
  displayName = environment.firstNation.displayName;
  trackingId: any;

  initalLogin = false

  // added
  accountSetup = "";
  jobTitle = "";
  appointment = false;
  biography = "";
  workPhone = "";
  extension = "";
  greetings = "";
  signOff = "";
  messageSMS = "";

  ShowEmployee: "";
  notification = "";
  contact = [];
  receiveNotificationFor = [];
  sendNotificationFor = [];
  areas = [];
  settings = [];
  isVerifyUsers: boolean;
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  tokenClient;
  gapiInited = false;
  gisInited = false;
  userCurrentToken;
  accountUser;
  host =
    window.location.hostname !== "localhost"
      ? "https://" + window.location.hostname + "/auth/verify-email/"
      : "http://localhost:4200/auth/verify-email/";
  domain = environment.firstNation.name;
  // domainName = "https://" + this.domain + ".goingmobile.app";
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";

  storeEmail = [];

  appName: any;
  appLogo: any;
  private pageTokens: Array<string | number | null> = [null];
  loginCount: any;
  newApp = true;
  hasAnApp1: boolean = false;
  verificationMethod: any;
  appSettings$: Subscription;
  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    private fireMembersService: FireMembersService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private emailService: EmailService,
    private userService: UserService,
    private injector: Injector,
    private settingsService: AppSettingsService,
    private accountService: AccountService,
    private clientAnswerService: ClientAnswersService,
    private permissionService: PermissionService,
    private store: Store,
    private afs: AngularFirestore,
    private fcmService: FCMService,
    private fcmTokenService: FCMTokenService,
    private apiService: FCMService,
    private scheduleService: ScheduleService,
    private getAppService: GetAppService,
    private helperService: HelperService
  ) {
    this.settingsService
    .getAppSettingsList()
    .valueChanges()
    .subscribe((settings: any) => {
      if (settings) {
        this.settings = settings;
        if (settings.length > 0) {
          this.newApp = false;
          this.isVerifyUsers = this.settings[0].verifyUsers;
          this.appName = this.settings[0].appName;
          this.appLogo = this.settings[0].logo;
          this.verificationMethod = this.settings[0].verifySubscribers;
        }
      }
    });

    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.accountUser = user;
        this.accountService.updateProfileKey(this.accountUser);
        this.processAccount(user);

      } else {
        this.role = "";
        this.fireMember = null;
        this.isLogged = false;
        this.ShowEmployee = null;
        this.globalUser = null;
        this.role = null;
        this.verified = false;
        this.status = null;
        this.reason = null;
        this.loggedInUser = null;
        this.accountSetup = null;
        this.notification = null;
        this.jobTitle = null;
        this.appointment = false;
        this.biography = null;
        this.workPhone = null;
        this.contact = null;
        this.greetings = null;
        this.signOff = null;
        this.messageSMS = null;
        this.receiveNotificationFor = [];
        this.sendNotificationFor = [];

        this.areas = null;
        // this.router.navigate(['home']);
        if (this.displayName && user === null) {
          // start comment -> measurementId removed for the moment
          this.trackingId = environment["firebase"].measurementId;
          var cookie = this.googleAnalyticsService.getCookie("UnsignedId");
          if (cookie.startsWith("_ga")) {
            this.googleAnalyticsService.loadGoogleAnalyticsNonLoggedIn(
              this.trackingId,
              cookie
            );
          } else {
            this.googleAnalyticsService.setCookie("UnsignedId", "true", 30);
            let cookie2;
            cookie2 = this.googleAnalyticsService.getCookie("UnsignedId");
            this.googleAnalyticsService.loadGoogleAnalyticsNonLoggedIn(
              this.trackingId,
              cookie2
            );
          }
          // end comment -> measurementId removed for the moment
        }
      }
    });
  }

  private processAccount(user: any) {
    this.fireMember = this.fireMembersService
      .getMemberByID(user.uid)
      .valueChanges();

    this.fireMember.subscribe((data) => {
      if (data) {
        this.isLogged = true;
        this.globalUser = data;
        this.role = data.role;
        this.verified = data.verified;
        this.status = data.status;
        this.reason = data.reason;
        this.loggedInUser = data;
        this.accountSetup = data.accountSetup;
        this.jobTitle = data.jobTitle;
        this.appointment = data.appointment;
        this.biography = data.biography;
        this.greetings = data.greetings;
        this.signOff = data.signOff;
        this.messageSMS = data.messageSMS;
        this.workPhone = data.workPhone;
        this.notification = data.notification;
        this.ShowEmployee = data.ShowEmployee;
        this.contact = data.contact;
        this.areas = data.areas;
        this.receiveNotificationFor = data.receiveNotificationFor;
        this.sendNotificationFor = data.sendNotificationFor;
        this.hasAnApp(data.Email);

        if (this.status !== "Enabled") {
          this.toast({
            html: "Your account is disabled please contact your case manager.",
            classes: "red",
            type: "failure",
          });
          setTimeout(() => {
            this.signOut();
          }, 10000);
          return;
        }

        if (data.role === "User") {

          this.userID = data.uid;
          this.accountUser = data.uid;
          this.accountService.updateProfileKey(this.accountUser);
          localStorage.setItem("uid", data.uid.toString());
          localStorage.setItem("userFirstName", data.GivenName);
          localStorage.setItem("userLastName", data.LastName);


          if (data.hasActiveStripeSession) {
            //If The Customer Has Active Stripe Checkout
            //He Must Be Redirected To The Returned Url From The Api 
            //This Stops The Normal Redirecting To The Home Page Or app Page           
            return;
          }


          if ((this.accountSetup === "empty" && this.isVerifyUsers) && !this.hasAnApp1) {
            if (this.accountSetup === "empty") {
              this.router.navigate(["/user/settings"]);
            }

            else if (
              this.accountSetup === "empty" &&
              data.verified &&
              !this.isVerifyUsers
            ) {
              this.router.navigate(["/user/setting"]);
            }
          }
          else if ((this.accountSetup === "empty" && this.isVerifyUsers) && !this.hasAnApp1) {
            this.router.navigate(["/user/homepage"]);
          }
          else {

            if (this.hasAnApp1) {
              this.router.navigate(["/user/manage-app/" + this.userID]);
            } else {
              this.router.navigate(["/user/homepage"]);
            }
          }

          this.googleAnalyticsService.startTracking();

          if (this.displayName) {
            this.trackingId = environment["firebase"].measurementId;
            this.googleAnalyticsService.loadGoogleAnalyticsLoggedIn(
              this.currentUser.uid,
              this.trackingId
            );
          }
          return;
        } else if (this.role === "Admin") {
          // this.session.startSession(data.uid);
          localStorage.setItem("userFirstName", data.FirstName);
          localStorage.setItem("userLastName", data.LastName);
          if (this.newApp) {
            this.router.navigate(["/admin/onboarding-page"]);
          }
          else {
            this.router.navigate(["/admin/homepage"]);
          }
          return;
        } else if (this.role === "Employee") {
          localStorage.setItem("userFirstName", data.FirstName);
          localStorage.setItem("userLastName", data.LastName);
          // if(this.accountSetup === 'empty'){
          //   this.router.navigate(['/auth/employeeAccount-setup'])
          // }
          // else{
          this.router.navigate(["/employee/dashboard"]);
          // }

          this.googleAnalyticsService.startTracking();
          if (this.displayName) {
            this.trackingId = environment["firebase"].measurementId;
            this.googleAnalyticsService.loadGoogleAnalyticsLoggedIn(
              this.currentUser.uid,
              this.trackingId
            );
          }

          return;
        }
      }
    });
  }



  hasAnApp(email) {
    this.hasAnApp1 = false
    if (email) {
      this.getAppService.getRequestByEmail(email).valueChanges().subscribe((user) => {
        if (user && user.length > 0) {
          this.hasAnApp1 = true;
          return this.hasAnApp1;
        }
        else {
          this.hasAnApp1 = false;
          return this.hasAnApp1;
        }
      })
    }
    else {
      return this.hasAnApp1
    }
  }


  emailLogin(email: string, password: string) {
    let hasAnApp = this.hasAnApp(email)
    return this.afAuth.signInWithEmailAndPassword(email, password).then((x) => {
      this.isNewUser = x.additionalUserInfo.isNewUser;
      let firebaseSubscribed = false;
      this.fireMember = this.fireMembersService
        .getMemberByID(x.user.uid)
        .valueChanges(take(1));

      this.fireMember.subscribe((data) => {
        if (data) {
          // auth().setPersistence(auth.Auth.Persistence.LOCAL);
          this.loggedInUser = data;
          this.role = data.role;
          this.verified = data.verified;
          this.status = data.status;
          this.reason = data.reason;
          this.globalUser = data;
          this.accountSetup = data.accountSetup;
          this.notification = data.notification;
          this.ShowEmployee = data.ShowEmployee;
          this.jobTitle = data.jobTitle;
          this.appointment = data.appointment;
          this.biography = data.biography;
          this.greetings = data.greetings;
          this.signOff = data.signOff;
          this.messageSMS = data.messageSMS;
          this.workPhone = data.workPhone;
          this.contact = data.contact;
          this.receiveNotificationFor = data.receiveNotificationFor;
          this.sendNotificationFor = data.sendNotificationFor;
          this.extension = data.extension;
          this.areas = data.areas;
          this.loginCount = data.loginCount;
          let allowNotification = data?.allowPushNotification;

          if (this.status !== "Enabled") {
            this.signOut();
            return;
          }

          // check if the user wants to recieve the notificaton popup based on his preference
          if (!firebaseSubscribed && allowNotification) {
            // const fcmService = this.injector.get<FCMpushService>(FCMpushService);
            // fcmService.requestPermission();

          }

          firebaseSubscribed = true;

          if (data.role === "User") {
            this.userID = data.uid;
            this.accountUser = data.uid;
            this.accountService.updateProfileKey(this.accountUser);
            localStorage.setItem("uid", data.uid.toString());
            localStorage.setItem("userFirstName", data.GivenName);
            localStorage.setItem("userLastName", data.LastName);

            //data.uid  = this.db.createId()
            //this.db.collection('ClientAnswers').doc(data.uid).set({firstName: data.GivenName, lastName:data.LastName, cellPhone:data.cellPhone, email:data.Email}).then(() =>{})
            // data.verified = true;

            if (this.accountSetup !== "empty") {
              if (this.hasAnApp1) {
                this.router.navigate(["/user/manage-app/" + this.userID]);
              }
              else {
                this.router.navigate(["/user/homepage"]);
              }
            }
            else if (this.accountSetup == "empty") {
              this.router.navigate(["/user/settings"]);
            }
            else if (this.accountSetup === "empty" && this.isVerifyUsers) {
              this.router.navigate(["/user/settings"]);
              // this.router.navigate(["/auth/account-setup"]);
            }
            //else if (this.accountSetup === 'empty' && (x.user ?.emailVerified) && !this.isVerifyUsers){
            else if (this.accountSetup === "empty" && data.verified && !this.isVerifyUsers) {
              this.router.navigate(["/user/settings"]);
              // this.router.navigate(["/auth/account-setup"]);
            } else if (
              this.accountSetup === "empty" &&
              !x.user?.emailVerified &&
              !this.isVerifyUsers
            ) {
              this.router.navigate(["/user/settings"]);
              // do nothing since email is not verified
            } else if (this.accountSetup === "empty" && !this.isVerifyUsers) {
              this.router.navigate(["/user/settings"]);
              // this.router.navigate(["/auth/account-setup"]);
            } else {
              if (this.hasAnApp1) {
                this.router.navigate(["/user/manage-app/" + this.userID]);
              } else {
                this.router.navigate(["/user/homepage"]);
              }

            }

            this.googleAnalyticsService.startTracking();
            if (this.displayName) {
              this.trackingId = environment["firebase"].measurementId;
              this.googleAnalyticsService.loadGoogleAnalyticsLoggedIn(
                this.currentUser.uid,
                this.trackingId
              );
            }
            return;
          } else if (data.role === "Admin") {
            localStorage.setItem("userFirstName", data.FirstName);
            localStorage.setItem("userLastName", data.LastName);
            if (this.newApp) {
              this.router.navigate(["/admin/onboarding-page"]);
            }
            else {
              this.router.navigate(["/admin/homepage"]);
            }
            return;
          } else if (data.role === "Employee") {
            localStorage.setItem("userFirstName", data.FirstName);
            localStorage.setItem("userLastName", data.LastName);
            // this.router.navigate(['/employee/dashboard']);
            // if(this.accountSetup ==='empty'){
            //   this.router.navigate(['/auth/employeeAccount-setup'])
            // }
            // else{
            this.router.navigate(["/employee/dashboard"]);
            // }

            this.googleAnalyticsService.startTracking();

            if (this.displayName) {
              this.trackingId = environment["firebase"].measurementId;
              this.googleAnalyticsService.loadGoogleAnalyticsLoggedIn(
                this.currentUser.uid,
                this.trackingId
              );
            }
            return;
          }
        }
      });
      return x.user;
    });
  }

  phoneNumberVerification(num: any, appVerifier: any) {
    return this.afAuth
      .signInWithPhoneNumber(num, appVerifier)
      .then((result) => {
        this.confirmationResult = result;
      });
  }

  phoneNumberLogin(verificationCode: any) {
    return this.confirmationResult
      .confirm(verificationCode)
      .then((x) => {
        this.isNewUser = x.additionalUserInfo.isNewUser;
        this.fireMember = this.fireMembersService
          .getMemberByID(x.user.uid)
          .valueChanges();
        this.fireMember.subscribe((data) => {
          this.loggedInUser = data;
          this.userID = data.uid;
          this.role = data.role;
          this.verified = data.verified;
          this.status = data.status;
          this.reason = data.reason;
          this.accountSetup = data.accountSetup;
          this.jobTitle = data.jobTitle;
          this.biography = data.biography;
          this.greetings = data.greetings;
          this.signOff = data.signOff;
          this.messageSMS = data.messageSMS;
          this.appointment = data.appointment;
          this.workPhone = data.workPhone;
          this.ShowEmployee = data.ShowEmployee;
          this.notification = data.notification;
          this.contact = data.contact;
          this.receiveNotificationFor = data.receiveNotificationFor;
          this.sendNotificationFor = data.sendNotificationFor;
          this.areas = data.areas;

          if (this.status !== "Enabled") {
            this.signOut();
            this.toast({
              html: "Your account is disabled please contact your case manager.",
              classes: "red",
              type: "failure",
            });
            return;
          }

          // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
          // toast({ html: 'Authenticated Successfully !', classes: 'green' });

          if (data.role === "User") {
            localStorage.setItem("userID", data.uid.toString());
            if (data.hasActiveStripeSession) {
              this.router.navigate(["/user/subscription-success/" + data.stripeSessionID]);
            }
            // let hasAnApp = this.hasAnApp(data.email)
            if (this.hasAnApp1) {
              this.router.navigate(["/user/manage-app/" + this.userID]);
            } else {
              this.router.navigate(["/user/homepage"]);
            }

            // if email works this has to be changed too
          } else if (data.role === "Admin") {
            if (this.newApp) {
              this.router.navigate(["/admin/onboarding-page"]);
            }
            else {
              this.router.navigate(["/admin/homepage"]);
            }
          }
        });

        return x.user;
      })
      .catch((error) => {
        //  this.toast({ html: error.message, classes: 'red' })
      });
  }

  // SendVerificationMail() {
  //   return this.afAuth.currentUser
  //     .then((user) => {
  //       return user.sendEmailVerification();
  //     })
  //     .then(() => {
  //       this.router.navigate(['/auth/verify-email']);
  //     });
  // }
  sendEmailForVerification(user: any) {
    //  this.router.navigate(['auth/verify-email', {state : {forgotPassword : this.forgotPassword}}]);
    // this.toast({ html: 'There is something wrong. Not able to send mail to your email.', classes: 'red', type: 'failure' });
    //  return user.sendEmailVerification();

    return this.afAuth.currentUser
      .then((u) => {
        u.sendEmailVerification();
      })
      .then(
        (res) => {
          this.router.navigate(["auth/verify-email"]);
          // this.router.navigate(['auth/verify-email', {state : {forgotPassword : this.forgotPassword}}]);
        },
        (err: any) => {
          this.toast({
            html: "There is something wrong. Not able to send mail to your email.",
            classes: "red",
            type: "failure",
          });
        }
      );
  }

  emailSignUp(member: any, password: string, signUpFromAdmin: boolean = false) {
    return this.afAuth
      .createUserWithEmailAndPassword(member.Email, password)
      .then((credential) => {
        this.isNewUser = credential.additionalUserInfo.isNewUser;
        const newUser = {
          uid: credential.user.uid,
          GivenName: member.GivenName,
          MiddleName: member.MiddleName ? member.MiddleName : "",
          LastName: member.LastName,
          Email: member.Email,
          DateAdded: new Date(),
          role: member.role ? member.role : "User",
          verified: true,
          isBusinessAccount: member.isBusinessAccont
            ? member.isBusinessAccount
            : false,
          status: "Enabled",
          reason: member.reason ? member.reason : "",
          // added
          accountSetup: "empty",
          ShowEmployee: member.ShowEmployee ? member.ShowEmployee : "", // ShowEmployee: false CHANGE TO
          appointment: member.appointment ? member.appointment : false,
          biography: member.biography ? member.biography : "",
          greetings: member.greetings ? member.greetings : "",
          signOff: member.signOff ? member.signOff : "",
          messageSMS: member.messageSMS ? member.messageSMS : "",
          jobTitle: member.jobTitle ? member.jobTitle : "",
          workPhone: member.workPhone ? member.workPhone : "",
          extension: member.extension ? member.extension : "",
          notification: "on",
          contact: member.contact ? member.contact : ['Email', 'SMS', 'InApp'],
          receiveNotificationFor: member.receiveNotificationFor ? member.receiveNotificationFor : [],
          sendNotificationFor: member.sendNotificationFor ? member.sendNotificationFor : [],
          areas: [],
          lastLogin: new Date(),
          loginCount: 0,
          cellPhone: member.cellPhone ? member.cellPhone : "",
          City: member.city ? member.City : "",
          profileImgLink: member.profileImgLink ? member.profileImgLink : "",
          profileImages: member.profileImages ? member.profileImages : [],
          allowPushNotification: true,
          recieveAppointmentEmail: member.recieveAppointmentEmail ? member.recieveAppointmentEmail : true,
          recieveAppointmentSMS: member.recieveAppointmentSMS ? member.recieveAppointmentSMS : true,
              
        };
        setTimeout(() => {
          if (member.schedule) {
            let empSchedule = { employeeId: credential.user.uid, schedule: member.schedule }
            this.scheduleService.createEmployeeSchedule(empSchedule);
          }
          this.clientAnswerService.getClientByEmail(member.Email).valueChanges().subscribe(client => {
            if (client.length == 0) {
              this.db.collection('ClientAnswers').doc(newUser.uid).set({ cellPhone: member.cellPhone, id: newUser.uid });
            }
          })

          if (signUpFromAdmin) {
            this.db.collection('Users').doc(member.uid).update(newUser).then(() => {
              this.sendConfirmationEmail(newUser, credential, member);
            }
            );
          } else {
            this.db.collection('Users').doc(newUser.uid).set(newUser).then(() => {
              this.sendConfirmationEmail(newUser, credential, member);
            }
            );
          }

        }, 20);
      })
      .catch((error) => {
        throw error;
      });
  }

  sendConfirmationEmail(newUser: any, credential: any, member: any) {
    if (newUser.role === 'User') {
      if (this.verificationMethod === 'Email' && !this.isVerifyUsers) {
        //this.sendEmailForVerification(credential.user);
        let hostname = `${this.host}${credential.user.uid}`;
        let domainName1 = `https://${this.domain}.goingmobile.app`
        const emailModel = new EmailModel();
        emailModel.subject = ` ${this.appName} Email Verification`;
        emailModel.isNationApp = this.isNationApp;

        emailModel.body = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
        emailModel.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
        emailModel.body += '<center>';
        if (this.appLogo) {
          emailModel.body += `<img src="${this.appLogo}"
        width="150" height="150" style=" display: block; alt="Logo">`;
        }
        emailModel.body += '</center>';

        emailModel.body += `<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px;">  Hello ${member.GivenName}, </h2> ` + '<br /> '
        emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you for registering with ${this.appName}. </p> `;
        emailModel.body += '<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Please click the button below to activate your account. </p>';
        emailModel.body += `<p><a href="${hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 18px 24px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 10px 2px; cursor: pointer; font-weight: bold; color: white; ">Activate My Account</a></p>`;
        emailModel.body += '<br /> <p style="padding-left: 20px; padding-right: 20px;"> If you didn’t ask to verify this address, you can ignore this email.</p> <br /> <p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you </p> ';
        emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;"><a href="${domainName1}" style=" cursor: pointer; border-radius: 10px; text-align: center; text-decoration: underline; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: blue; text-decoration-color: blue; "><u>${domainName1}</u></a></p><br /><br />`;
        this.storeEmail.push(member.Email);
        emailModel.emailList = this.storeEmail;
        // this.emailService.sendEmail(emailModel).subscribe((res) => {
        //   if (res) {
        //     this.router.navigate(['auth/verify-email']);
        //   }
        // })

      }
      else if (this.verificationMethod === 'Email' && this.isVerifyUsers && !this.hasAnApp1) {
        this.router.navigate(['/auth/account-setup']);
      } else if (this.verificationMethod === "noVerification") {
        this.router.navigate(["/user/homepage"]);
      }
      else {
        // let hasAnApp = this.hasAnApp(member.email)
        if (this.hasAnApp1) {
          this.router.navigate(["/user/manage-app/" + this.userID]);
        } else {
          this.router.navigate(["/user/homepage"]);
        }
      }
    }
    else if (newUser.role === 'Employee') {
      //member.permission.employeeId = member.uid;
      this.permissionService.savePermission({ id: '', permission: member.permission, employeeId: newUser.uid }).then(() => {
        setTimeout(() => {
          this.router.navigate(['/employee/dashboard']);
        }, 1000);
      })
    }

  }

  adminRegReq(member: any, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(member.Email, password)
      .then((credential) => {
        this.isNewUser = credential.additionalUserInfo.isNewUser;
        const newUser = {
          uid: credential.user.uid,
          GivenName: member.GivenName,
          MiddleName: member.MiddleName ? member.MiddleName : "",
          LastName: member.LastName,
          DOB: member.DOB ? member.DOB : "",
          Sex: member.Sex ? member.Sex : "",
          Email: member.Email,
          DateAdded: new Date(),
          role: "Admin",
          status: "Enabled",
          verified: member.verified ? member.verified : false,
          lastLogin: new Date(),
          loginCount: 0,
          PostalCode: member.PostalCode ? member.PostalCode : "",
          Province: member.Province ? member.Province : "",
          cellPhone: member.cellPhone ? member.cellPhone : "",
          City: member.city ? member.City : "",
          profileImgLink: member.profileImgLink ? member.profileImgLink : "",
          profileImages: member.profileImages ? member.profileImages : [],
          isAdminApproved: false,
        };

        setTimeout(() => {
          this.db
            .collection("Users")
            .doc(newUser.uid)
            .set(newUser)
            .then(() => {
              if (this.newApp) {
                this.router.navigate(["/admin/onboarding-page"]);
              }
              else {
                this.router.navigate(["/admin/homepage"]);
              }
            });
        }, 25);
      })
      .catch((error) => {
        throw error;
      });
  }

  demoEmailSignUp(model: any, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(model.email, password)
      .then((credential) => {
        model.uid = credential.user.uid;
        this.db
          .collection("Users")
          .doc(model.uid)
          .set(model)
          .then(() => {
            this.router.navigate(["auth/account-setup"]);
          });
      })
      .catch((error) => {
        throw error;
      });
  }

  phoneSignUp(member: MemberModel, phone: any, verifier: any) {
    return this.afAuth
      .signInWithPhoneNumber(phone, verifier)
      .then((resp) => { });
  }

  adminEmailSignUp(model: any, isDemoAccount: any, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(model.Email_Address, password)
      .then((credential) => {
        if (credential) {
          const newAdmin = {
            uid: credential.user.uid,
            id: model.uid,
            FirstName: model.FirstName,
            LastName: model.LastName,
            Email: model.Email_Address,
            DateAdded: new Date(),
            role: "Admin",
            status: "Enabled",
            isDemo: isDemoAccount,
          };
          setTimeout(() => {
            this.db
              .collection("Users")
              .doc(newAdmin.uid)
              .set(newAdmin)
              .then(() => {
                if (this.newApp) {
                  this.router.navigate(["/admin/onboarding-page"]);
                }
                else {
                  this.router.navigate(["/admin/homepage"]);
                }
              });
          }, 25);
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  getCurrentUser() {
    return this.currentUser
  }

  getCurrentUserID() {
    return this.userID;
  }
  isFirstLogin() {
    return this.isNewUser;
  }
  getGlobalUser(): any {
    return this.globalUser;
  }

  setUserCurrentToken(currentToken: any) {
    this.userCurrentToken = currentToken;
  }

  getUserCurrentToken() {
    return this.userCurrentToken;
  }

  getCurrentUserObservable() {
    if (this.currentUser) {
      this.fireMember = this.fireMembersService
        .getMemberByID(this.currentUser.uid)
        .valueChanges();
      return this.fireMember;
    }
  }
  getCurrentUserData() {
    if (this.currentUser) {
      this.fireMember = this.fireMembersService
        .getMemberByID(this.currentUser.uid)
        .valueChanges();
      return this.fireMember;
    }
  }

  resetPassword(email: string) {
    // this.router.navigate(['/auth/verify-email'], {state : {forgotPassword : this.forgotPassword}});
    return this.afAuth.sendPasswordResetEmail(email);
    // route to verify email componenet when the user clicks reset password form the login page
  }

  deleteUser(user) {
    // this.afAuth.currentUser.delete().then(() => {
    //   this.afAuth.auth.signOut()
    // })
    this.afAuth.currentUser.then((user) => user?.delete());
    //this.router.navigate('');
    window.location.reload();
    // this.role = '';
    // this.fireMember = null;
    // this.isLogged = false;
    // this.globalUser = null;
    // this.status = null;
    // this.reason = null;
    // this.loggedInUser = null;

    // await this.afAuth.signOut();
    // this.router.navigate(['/#/']);
  }

  async signOut() {
    // read from the local storage
    let savedRegistrationToken = localStorage.getItem("registrationToken");

    // if (savedRegistrationToken) {

    //   // grab the registration token and remove it from the database
    //   await this.updateRegistrationToken(this.loggedInUser, savedRegistrationToken);

    //   // unsubscribe the user from a topic based on role
    //   await this.unsubscribeFromATopic(
    //     this.loggedInUser,
    //     savedRegistrationToken
    //   );
    // }

    this.role = "";
    this.fireMember = null;
    this.isLogged = false;
    this.globalUser = null;
    this.status = null;
    this.reason = null;
    this.loggedInUser = null;
    this.verified = false;

    await this.afAuth.signOut();
    window.location.reload();
    return;
  }

  async updateRegistrationToken(user, currentToken) {
    await this.fcmTokenService.deleteUserFCMToken(currentToken);

    // save it in the local storage
    localStorage.removeItem("registrationToken");
  }

  // to remove the registration token from the device id
  async removeTokenFromDeviceID(user, currentToken) {
    try {
      // if a device group key is available , then what we need to do is to add the current token to the device group key
      await this.fcmService
        .updateDeviceGroup(
          "new_group",
          user?.deviceGroupKey,
          [currentToken],
          "remove"
        )
        .subscribe((notificationToken) => {
        });
    } catch (err) {
      this.toast({
        html: "Failed when trying to remove the device group id",
        classes: "red",
        type: "failure",
      });

    }
  }

  async unsubscribeFromATopic(user, currentToken) {
    try {
      // if a device group key is available , then what we need to do is to add the current token to the device group key
      this.apiService.unsubscribeToTopic(user?.role === "Admin" ? "Owner_Notification" : "User_Notification", currentToken).subscribe((data) => {
      });
    } catch (err) {
      // this.toast({
      //   html: "Failed when trying to remove the device group id",
      //   classes: "red",
      //   type: "failure",
      // });

    }
  }

  getRole() {
    return this.role;
  }

  async signOutWithoutRedirect() {
    localStorage.clear();
    this.role = "";
    this.fireMember = null;
    this.isLogged = false;
    this.globalUser = null;
    this.status = null;
    this.reason = null;
    this.loggedInUser = null;
    await this.afAuth.signOut();
    window.location.reload();
  }

  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : "green";
    this.toastType = obj.type ? obj.type : "success";
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

  // --------------------------- SESSION MONITOR -----------------------------------

  // facebookLogin() {
  //   const provider = new auth.FacebookAuthProvider();
  //   provider.addScope('id');
  //   provider.addScope('first_name');
  //   provider.addScope('last_name');
  //   provider.addScope('picture');

  //   return auth().signInWithPopup(provider).then(x => {

  //     // The signed-in user info.
  //     const user = x.user;

  //     // ...
  //   }).catch(error => {
  //     // Handle Errors here.
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     // The email of the user's account used.
  //     const email = error.email;
  //     // The firebase.auth.AuthCredential type that was used.
  //     const credential = error.credential;

  //   });
  // }


  appRequestSignUp(member: any, password: string) {
    if (member.lastName === null || typeof member.lastName === 'undefined') {
      member.lastName = ""
    }

    return this.afAuth
      .createUserWithEmailAndPassword(member.email, password)
      .then((credential) => {
        this.isNewUser = credential.additionalUserInfo.isNewUser;
        const newUser = {
          uid: credential.user.uid,
          GivenName: member.firstName,
          LastName: member.lastName,
          Email: member.email,
          DateAdded: new Date(),
          role: "User",
          verified: true,
          status: "Enabled",
          // added
          accountSetup: "empty",
          notification: "on",
          contact: ['Email', 'SMS', 'InApp'],
          receiveNotificationFor: [],
          sendNotificationFor: [],
          areas: [],
          lastLogin: new Date(),
          loginCount: 0,
          cellPhone: member.mobileNumber,
          profileImgLink: "",
          profileImages: [],
          allowPushNotification: true,
        };
        setTimeout(() => {
          this.db.collection('Users').doc(newUser.uid).set(newUser).then(() => {
            member.apprequestModel.userId = credential.user.uid,
              this.getAppService.saveRequest(member.apprequestModel).then(req => {
                if (this.accountSetup === 'empty' && !this.isVerifyUsers) {
                  //this.sendEmailForVerification(credential.user);
                  let hostname = `${this.host}${credential.user.uid}`;
                  let domainName1 = `https://${this.domain}.goingmobile.app`
                  const emailModel = new EmailModel();
                  emailModel.subject = ` ${this.appName} Email Verification`;
                  emailModel.isNationApp = this.isNationApp;

                  emailModel.body = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
                  emailModel.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
                  emailModel.body += '<center>';
                  if (this.appLogo) {
                    emailModel.body += `<img src="${this.appLogo}"
                width="150" height="150" style=" display: block; alt="Logo">`;
                  }
                  emailModel.body += '</center>';

                  emailModel.body += `<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px;">  Hello ${member.GivenName}, </h2> ` + '<br /> '
                  emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you for registering with ${this.appName}. </p> `;
                  emailModel.body += '<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Please click the button below to activate your account. </p>';
                  emailModel.body += `<p><a href="${hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 18px 24px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 10px 2px; cursor: pointer; font-weight: bold; color: white; ">Activate My Account</a></p>`;
                  emailModel.body += '<br /> <p style="padding-left: 20px; padding-right: 20px;"> If you didn’t ask to verify this address, you can ignore this email.</p> <br /> <p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you </p> ';
                  emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;"><a href="${domainName1}" style=" cursor: pointer; border-radius: 10px; text-align: center; text-decoration: underline; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: blue; text-decoration-color: blue; "><u>${domainName1}</u></a></p><br /><br />`;
                  this.storeEmail.push(member.Email);
                  emailModel.emailList = this.storeEmail;
                  this.emailService.sendEmail(emailModel).subscribe((res) => {
                  })


                }
              })



          }
          );
        }, 50);
      })
      .catch((error) => {
        throw error;
      });
  }
}

