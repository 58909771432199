import { UserService } from 'src/app/services/firebase/user.service';
import { Location } from '@angular/common';
import { EmployeeServicesService } from './../../../services/firebase/employee-services.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { CartService } from 'src/app/services/checkout/cart.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';

declare var Stripe;

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})

export class ServiceComponent implements OnInit {
  model: any[];
  serviceId: any;
  showNews = false;
  service: any;
  noNews = false;
  adminThemeColor = '';
  employees = [];
  firstEmployee = [];
  firstEmpSchedule = [];
  currentUser: any;

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  settings:any;
  servicesName
  currency;
  constructor(
    private empService: EmployeeServicesService,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cartService: CartService,
    private scheduleService: ScheduleService,
    private productsServiceMenuService:ProductsServiceMenuService,
    public settingsService: AppSettingsService) { 
      
    }

  ngOnInit() {
    this.productsServiceMenuService.servicesName.subscribe(p=>{
      this.servicesName=p
    }
    )
    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });

    this.currentUser = this.authService.getGlobalUser();

    this.route.paramMap.subscribe(params => {
      this.serviceId = params.get('id');
    });
    
    if(this.currentUser){
      if(this.currentUser.role == 'User' || this.currentUser.role =='Employee'){
        this.empService.getAllServices().valueChanges().subscribe(x =>{
          if(x.length > 0){
            this.model = x;
  
          }
        });
      }
    }
    else{
      this.empService.getPublicServices().valueChanges().subscribe(x => {
        if (x) {
          if (x.length > 0) {
            this.model = x;
            // this.checkoutService.initStripeElements();
          }

        }
      });
    }

    // get employee

    if (this.serviceId){
      this.empService.getServiceById(this.serviceId).valueChanges().subscribe(service => {
        if (service.length > 0 ){
          this.service = service[0];
          this.service.assignedTo.forEach((empId) => {
            this.userService.getUserById(empId).valueChanges().subscribe(user => {
              this.employees.push(user[0]);
              
              if (this.firstEmployee.length === 0){
                this.firstEmployee = this.employees[0];
                this.scheduleService.getScheduleByEmployeeId(this.employees[0].uid).valueChanges().subscribe((empSchedule: any) => {
                  if (empSchedule.length > 0) {
                    empSchedule[0].schedule.forEach((schd: any) => {
                      if (!schd.unAvailable){
                        this.firstEmpSchedule.push(schd);
                      }
                    });
                  }
                });
              }
              // this.checkoutService.initStripeElements();

            });
          });
        }
      });
    }
    else{
      if(this.currentUser.role != 'User' && this.currentUser.role !='Admin' && this.currentUser.role != 'Employee'){
      this.empService.getPublicServices().valueChanges().subscribe(x => {
        if (x) {
          if (x.length > 0) {
            this.model = x;
            // this.checkoutService.initStripeElements();
          }

        }
      });
    }
    }

    this.settingsService.getAppSettingsList().valueChanges().subscribe(menu =>{
      if (menu && menu.length >0){
        this.settings=menu[0]
      }
    });
  }


 

  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }
  backClicked() {
    this.location.back();
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  AddToCart(serviceID: any) {
    this.cartService.addServiceToCart(serviceID, 1)
    this.toast({html: 'Service Successfully added to cart', classes: 'green', type: 'success'});
  }


  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.router.navigate(['/admin/services-setting']);
    }, 2000);
  }

  navigate(service){
    if(this.currentUser.role == 'User'){
      this.router.navigate(['/user/service',service.id]);
    }
    if(this.currentUser.role == 'Employee'){
      this.router.navigate(['/employee/services', service.id]);
    }
    if(this.currentUser.role == 'Admin'){
      this.router.navigate(['/admin/service',service.id]);
    }
  }

}
