<div class="flex">

  <div class="w-full md:w-full">


    <div class="bg-gray-100 w-11/12  mx-4">
      <!--Here is the profile settings for the admin-->
      <div class="font-body min-h-screen max-h-max pt-6 w-full">
        <div class="w-full flex justify-center">
          <div class="flex flex-col w-full mx-6 md:w-10/12 my-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:justify-center">
   
           <div class="flex items-center space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-gray-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            
            <p class=" font-bold text-xl text-gray-500 ">Profile Settings</p>
           </div>
            <hr>

            <form [formGroup]="EditProfileForm" (ngSubmit)="SubmitRequest(EditProfileForm.value)">
              <div class="flex flex-col space-y-4 mb-4 md:flex-col  ">
           

                <div class="px-4 md:px-0 space-y-4 md:space-y-0 ">
                 <div class="flex w-full">
                  <div class="flex space-y-2 md:w-1/2 mr-4  flex-col">
                    <label for=" " class="text-xs text-gray-500 font-bold "> First Name * </label>
                    <input maxlength="60" formControlName="firstName" id="firstName" #firstName [(ngModel)]="names"
                      class="input input-bordered focus:bg-gray-50 focus:border-customTeal"
                      type="text" placeholder="" required>
                    <div *ngIf="currentUser.firstName =='' ">
                      <small class="text-red-500 italic"> The First Name is required. </small>
                    </div>
                  </div>
                  <div class="flex space-y-2 md:w-1/2 flex-col ">
                    <label for=" " class="text-xs text-gray-500 font-bold "> Last Name *</label>
                    <input formControlName="lastName" #lastName id="lastName"
                      class="input input-bordered focus:bg-gray-50 focus:border-customTeal"
                      type="text" placeholder="" required>
                    <!-- <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                                        <small *ngIf="lastName.errors.required" class="text-red-500 italic"> The Last Name is
                                            required. </small>
                                    </div> -->
                  </div>
                 </div>
                 <div class=" mt-4">
                  <div class="flex space-y-2 md:w-1/2 mt-4 flex-col ">
                    <label for=" " class="text-xs text-gray-500 font-bold "> Phone Number</label>
                    <input formControlName="cellPhone"  #cellPhone id="cellPhone"
                      class="input input-bordered focus:bg-gray-50 focus:border-customTeal"
                      type="text" placeholder=" ">
                  </div>
                 </div>


                 

                </div>

                <div class="flex  flex-col space-y-2 items-baseline">
                  <label for=" " class="text-xs text-gray-500 font-bold "> Profile Picture</label>
                  <div class="form-controlmax-w-x ">
                    <div class="flex md:flex-row flex-col gap-x-3 w-full">
                      <div *ngIf="!isMobile"
                        class="flex justify-center  w-72  content-center  h-44 max-w-x border border-solid border-gray-200 bg-white rounded-lg  gap-x-3">
                

                        <label class="custom-file-upload  self-center flex flex-col">
                          <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                          <input type="file" type="file" accept="image/*"
                            (change)="handleFileInput($event.target.files)">
                          <span class="block font-bold pt-1">Upload Image</span>
                        </label>
                      </div>
                      <div *ngIf="isMobile">
                        <button type="button"
                          class="py-2 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg w-full"
                          type="button" (click)="handleMobilePhotoInput()">
                          Upload Image
                        </button>
                      </div>
                      <div class="pt-5" *ngIf="base64Image">
                        <!-- <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Image</p> -->
                        <div class="flex flex-wrap gap-8">

                          <div class="relative">
                            <img [src]="base64Image" loading="lazy" class="  w-40  rounded-md object-contain" alt="Image">
                            <button (click)="removeFile()"
                              class="absolute top-0 right-0 translate-x-4 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                              <i class="fa fa-trash text-md"></i>
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>


                    <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
                      <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                    </p>

                  </div>
                </div>
              </div>

            <div class="flex md:flex-row flex-col-reverse gap-y-6 items-center md:justify-between">
              <div *ngIf="currentUser && currentUser.role == 'Admin'" class="flex justify-end md:mr-0">
                <button type="button" (click)="openDeleteModalOne()"
                class="flex items-center rounded bg-red-600 px-6 py-2 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">

                  Delete Account
                </button>
              </div>

              <div class="pt-6 flex items-center space-x-4 justify-end">
                <div class="">
                  <button type="submit"
                  class="flex items-center rounded bg-white text-customTeal border border-solid border-customTeal px-6 py-2 text-xs font-medium  leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                    <span>Cancel</span>
                  </button>
                </div>
                <div class=" ">
                  <button type="button" (click)="openSaveModal()"
                  class="flex items-center rounded bg-customTeal px-6 py-2 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                    <span>Save Changes</span>
                  </button>
                </div>
              </div>

                      
      
            </div>

              <div id="my-modal-saveModal" *ngIf="saveModal" class="modal"
                [ngClass]="{'modal-open': saveModal, 'modal-action': !saveModal }">
                <div class="modal-box">
                  <div class="modal-content">
                    <div class="flex justify-end "(click)="closeSaveModal()"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>

                    <h3 class="font-bold text-lg text-center mb-2">Update Profile</h3>
                    <hr />
                    <p class="text-center mt-3"> <span class="font-bold">{{names | titlecase}},</span> are you sure you
                      want to update your account?</p>
                    <!-- <p class="text-center mt-3">If you delete your account you will not be able to login again to {{appName}}. <span class="font-bold">Do you want to proceed?</span></p> -->

                    <div class="modal-action items-center justify-between m-4">
                      <button (click)="closeSaveModal()"
                        class="py-2 px-4 text-black border-customTeal border-2 rounded-md ">No</button>
                      <button type="submit"
                        class="py-2 px-4 bg-red-500 text-white border-red-500 rounded-md ">Yes</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <br /> <br />
  </div>

</div>



<div id="my-modal-confirmDeleteModal" *ngIf="confirmDeleteModal" class="modal"
  [ngClass]="{'modal-open': confirmDeleteModal, 'modal-action': !confirmDeleteModal }">
  <div class="modal-box">
    <div class="modal-content">
      <div class="flex justify-end "(click)="closeDeleteModalOne()"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>

      <h3 class="font-bold text-lg text-center mb-2">Delete My Account</h3>
      <hr />
      <p class="text-center mt-3"> <span class="font-bold">{{names | titlecase}},</span> are you sure you want to delete
        your account?</p>
      <p class="text-center mt-3">If you delete your account you will not be able to login again to {{appName}}. <span
          class="font-bold">Do you want to proceed?</span></p>

      <div class="modal-action items-center justify-between m-4">
        <label (click)="closeDeleteModalOne()"
          class="py-2 px-4 text-black border-customTeal border-2 rounded-md ">No</label>
        <label (click)="openSecondModalTwo()"
          class="py-2 px-4 bg-red-500 text-white border-red-500 rounded-md ">Yes</label>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-confirmSecondModal" *ngIf="confirmSecondModal" class="modal"
  [ngClass]="{'modal-open': confirmSecondModal, 'modal-action': !confirmSecondModal }">
  <div class="modal-box">
    <div class="modal-content">
      <div class="flex justify-end "(click)="closeSecondModalTwo()"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>

      <h3 class="font-bold text-lg text-center mb-2">Delete My Account</h3>
      <hr />
      <p class="text-center mt-3"> Once you confirm you will not be able to log in to {{appName}}</p>
      <p class="text-center mt-3"> <span class="font-bold">Do you want to proceed?</span></p>

      <div class="modal-action items-center justify-between m-4">
        <label (click)="closeSecondModalTwo()"
          class="py-2 px-4 text-black border-customTeal border-2 rounded-md ">No</label>
        <label (click)="openThreeModal()" class="py-2 px-4 bg-red-500 text-white border-red-500 rounded-md ">Yes</label>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-confirmThreeModal" *ngIf="confirmThreeModal" class="modal"
  [ngClass]="{'modal-open': confirmThreeModal, 'modal-action': !confirmThreeModal }">
  <div class="modal-box">
    <div class="modal-content">
      <div class="flex justify-end "(click)="closeThreeModal()"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>

      <h3 class="font-bold text-lg text-center mb-2">Delete My Account</h3>
      <hr />
      <p class="text-center mt-3"> Are you sure?</p>
      <div class="modal-action items-center justify-between m-4">
        <label (click)="closeThreeModal()"
          class="py-2 px-4 text-black border-customTeal border-2 rounded-md ">Cancel</label>
        <label (click)="beforeDelete()"
          class="py-2 px-4 bg-red-500 text-white border-red-500 rounded-md ">Delete</label>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-delete" *ngIf="beforeDeleteModal" class="modal"
  [ngClass]="{'modal-open': beforeDeleteModal, 'modal-action': !beforeDeleteModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-2">Your Account has been deleted</h3>
      <hr />
      <p class="text-center mt-3"> Have a great day.</p>
    </div>
  </div>
</div>


<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"> </app-alert>