import { NgModule } from '@angular/core';
import { BookAppointmentComponent } from '../shared/book-appointment/book-appointment.component';
import { ViewServiceComponent } from '../shared/services/view-service/view-service.component';
import { ViewEventComponent } from '../shared/events/view-event/view-event.component';
import { ViewEventsListComponent } from '../shared/events/view-events-list/view-events-list.component';
import { RouterModule, Routes } from '@angular/router';
import { UserAuthGuard } from 'src/app/guards/user-auth.guard';
import { MemberMainComponent } from './member-main/member-main.component';
import { SettingsComponent } from './settings/settings.component';
import { MyDiscussionComponent } from './member-messaging/my-discussion/my-discussion.component';
import { MessagingComponent } from './messaging/messaging.component';
import { ViewProfileComponent } from '../shared/view-profile/view-profile.component';
import { GroupChatsListComponent } from './../shared/group-chats-list/group-chats-list.component';
import { ViewChatComponent } from './chat/view-chat/view-chat.component';
import { MemberHomeComponent } from './member-home/member-home.component';
import { EmployeeList } from './employee/employee-list/employee-list.component';
import { EmployeeAppointmentComponent } from './employee/employee-appointment/employee-appointment.component';
import { ServiceComponent } from '../app/service/service.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { ProductsComponent } from '../app/products/products.component';
import { BlogListComponent } from './../shared/blog/blog-list/blog-list.component';
import { AppointmentRescheduleComponent } from '../shared/appointment-reschedule/appointment-reschedule.component';
import { ViewProductComponent } from '../shared/view-product/view-product.component';
import { ViewBlogComponent } from '../shared/blog/view-blog/view-blog.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { CartComponent } from './cart/cart.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { EditProfileComponent } from '../shared/edit-profile/edit-profile.component';
import { ServicesComponent } from '../admin/service/services/services.component';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { ChatMessagingComponent } from '../shared/messaging/messaging.component';
import { ViewFormComponent } from './profile-form/view-form/view-form.component';
import { CreateClientComponent } from '../admin/invite-subscriber/create-client/create-client.component';
// import { EditClientInfoComponent } from '../admin/invite-subscriber/create-client/edit-client-info/edit-client-info.component';
import { GetVerifiedComponent } from './get-verified/get-verified.component';
import { ResetPasswordComponent } from '../app/reset-password/reset-password.component';
import { CreateAppointmentComponent } from '../admin/create-appointment/create-appointment.component';
import { ServicesListComponent } from '../shared/services/services-list/services-list.component';
import { ManageAppComponent } from './manage-app/manage-app.component';
import { ManageSubscriptionsComponent } from './subscriptions/manage-subscriptions/manage-subscriptions.component';
import { SubscriptionSuccessComponent } from './subscriptions/subscription-success/subscription-success.component';
import { SubscriptionFailComponent } from './subscriptions/subscription-fail/subscription-fail.component';
import { EditClientInfoComponent } from '../admin/invite-subscriber/create-client/edit-client-info/edit-client-info.component';
import { CustomSectionsListComponent } from '../admin/custom_section/custom-sections-list/custom-sections-list.component';
import { SectionListComponent } from '../shared/custom-section/section-list/section-list.component';
import { ViewSectionComponent } from '../shared/custom-section/view-section/view-section.component';

const routes: Routes = [
  {
    path: 'user',
    component: MemberMainComponent,
    canActivate: [UserAuthGuard],
    children: [
      {
        path:'manage-app/:id',
        component: ManageAppComponent,
        canActivate:[UserAuthGuard]

      },
      {
        path: 'edit-clientInfo/:id',
        component: EditClientInfoComponent,
        canActivate: [UserAuthGuard],
      },
      // {
      //   path: 'settings',
      //   component: SettingsComponent,
      //   canActivate: [UserAuthGuard],
      // },
      {
        path: 'homepage',
        component: MemberHomeComponent,
        canActivate: [UserAuthGuard],
      },
      {
        path: 'view-profile/:id',
        component: ViewProfileComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'user-home',
        component: MessagingComponent,
        canActivate: [UserAuthGuard],
      },
  
      {
        path: 'chat-list',
        component: ChatMessagingComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'chat-list/:chatType',
        component: ChatMessagingComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'client-info',
        component: CreateClientComponent
      },
      {
        path: 'custom-sections-list/:id',
        component: SectionListComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'view-custom-section/:id',
        component: ViewSectionComponent,
        canActivate: [UserAuthGuard]

      },
      {
        path: 'chat/:id',
        component: ViewChatComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'view-product/:id',
        component: ViewProductComponent
      },
      {
        path:'view-blog/:id',
        component:ViewBlogComponent
      },
      {
        path: 'group-chats',
        component: GroupChatsListComponent,
        canActivate: [UserAuthGuard]
      },
      // {
      //   path: 'my-inbox',
      //   component: MyInboxComponent,
      //   canActivate: [UserAuthGuard],
      // },
      {
        path: 'discussion/:id',
        component: MyDiscussionComponent,
        canActivate: [UserAuthGuard],
      },
      // {
      //   path: 'messages',
      //   component: MessagingComponent,
      //   canActivate: [UserAuthGuard],
      // },
      // {
      //   path: 'my-profile',
      //   component: UpdateProfileComponent,
      //   canActivate: [UserAuthGuard]
      // },
      {
        path: 'view-profile/:id',
        component: ViewProfileComponent,
        canActivate: [UserAuthGuard]
      },
      // {
      //   path: 'verification',
      //   component: VerificationComponent,
      //   canActivate: [UserAuthGuard]
      // },
      {
        path:'get-verified',
        component: GetVerifiedComponent,
      },
      {
        path: 'reset-password/:id',
        component: ResetPasswordComponent
      },
      {
        path: 'events',
        component: ViewEventsListComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'event/:id',
        component: ViewEventComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'view-services',
        component: ViewServiceComponent,
        canActivate: [UserAuthGuard]
      },
      // {
      //   path: 'services/:id',
      //   component: ViewServiceComponent,
      //   canActivate: [UserAuthGuard]
      // },
      {
        path: 'services/:id',
        component: ServicesComponent,
      },
      {
        path: 'service/:id',
        component: ViewServiceComponent,
      },
      {
        path: 'employee-list',
        component: EmployeeList,
        canActivate: [UserAuthGuard]
      },
       {
        path: 'employee-appointment/:id',
        component: EmployeeAppointmentComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'book-appointment/:id',
        component: BookAppointmentComponent,
        canActivate: [UserAuthGuard]
      },
      {
        path: 'services',
        component: ServicesListComponent
      },
      {
        path: 'view-products',
        component: ProductsComponent
      },
      {
        path: 'products/:id',
        component: ProductsComponent
      },
      {
        path: 'create-client',
        component: CreateClientComponent
      },
      {
        path: 'blog',
        component: BlogListComponent
      },
      {
        path: 'view-blog/:id',
        component: ViewBlogComponent,
      },
      {
        path: 'appointments',
        component: AppointmentsComponent,
        canActivate: [UserAuthGuard]
      },
      //Added
        {
        path: 'book-appointment',
        component: BookAppointmentComponent,
        canActivate: [UserAuthGuard]
      },
      // {
      //   path: 'appointment-reschedule',
      //   component: AppointmentRescheduleComponent,
      //   canActivate: [UserAuthGuard]
      // },
      {
        path: 'appointment-reschedule/:id',
        component: AppointmentRescheduleComponent,
        canActivate: [UserAuthGuard]

      },
      {
        path:'user-notifications',
        component: UserNotificationsComponent,
        canActivate:[UserAuthGuard]
      },
      {
        path: 'payment-status',
        component: PaymentStatusComponent,
        canActivate:[UserAuthGuard]
      },
      {
        path: 'cart',
        component: CartComponent,
        canActivate:[UserAuthGuard]
      },
      {
        path:'edit-profile/:id',
        component: EditProfileComponent
      },
      {
        path:'profile-form/:id',
        component: ProfileFormComponent
      },
      {
        path:'view-form',
        component: ViewFormComponent
      },
  

      {
        path:'manage-subscriptions',
        component: ManageSubscriptionsComponent,
        canActivate:[UserAuthGuard]
      },

      {
        path:'subscription-success/:id',
        component: SubscriptionSuccessComponent,
        canActivate:[UserAuthGuard]
      },

      {
        path:'subscription-fail',
        component: SubscriptionFailComponent,
        canActivate:[UserAuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserRoutingModule { }
