import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-list-view-rearrange',

  templateUrl: './list-view-rearrange.component.html',
  styleUrl: './list-view-rearrange.component.css'
})
export class ListViewRearrangeComponent implements OnInit {
  @Input() landingPageView
  @Input() pageSections
  @Input() pageView
  @Input() sectionsSetting
  @Input() listElement
  @Output() save = new EventEmitter();
  

    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;

    
  constructor(){

  }
  
  ngOnInit(): void {
  
  }
  landingPageConfirmationUpdate(){
    this.save.emit(this.pageSections)
  }
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listElement, event.previousIndex, event.currentIndex);
        // Update position property for all elements
        this.updatePositions();
  }

  
  updatePositions(): void {
    this.listElement.forEach((element, index) => {
      element.position = index;
      this.pageSections.appSections.forEach(section => {
        if(section.listIndex== null){
          console.log(section.originalName)
        }
        if(element.section.originalName == 'Welcome Popup'){
          section.listIndex = ''
        }else if(element.section.listIndex == null || section.listIndex == null ){
          section.listIndex = ''
        }
        if(element.section.originalName == section.originalName){
          section.listIndex = element.position + 1
        }

      
      });
    });
    // this.saveRenameChange();
  }

}
