import { AuthService } from './../../../../services/firebase/auth.service';
import { MediaService } from './../../../../services/api/media.service';
import { Component, OnInit } from '@angular/core';
// import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
// declare var navigator: any;
// declare var $: any;

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.css']
})
export class AppSettingsComponent implements OnInit {

  appSettingsForm: UntypedFormGroup;
  model: any;
  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  isSidebarMenu = false;
  isBottomMenu = false;
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  showLogoInput = true;
  showImageFromServer = false;
  oldUrl: any;
  currentUser: any;
  isBusinessApp = false;
  isMobile = false;
  mobileBase64Image: any;

  //Added B
  isDirectSignup = false;

    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
  useBase64=false
  appSections = GlobalConstants.AppSections;

  constructor(public appSettingsService: AppSettingsService, private fb: UntypedFormBuilder,
              private location: Location,
              private mediaService: MediaService, private authService: AuthService) {

                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
                  this.isMobile = true;
                }
              }

  ngOnInit() {
    this.currentUser = this.authService.getGlobalUser();
    this.appSettingsForm = this.fb.group({
      appName: ['', [Validators.required]],
      description: [''],
      appLogo: [''],
      buttonOption: [''],
      navbarOption: [''],
      themeColor: [''],
      rightOrLeftSidebar: [false],
      serviceType: ['Both'],
      serviceName: ['Services'],
      productName: ['Products'],
      takeAppointment: [false],
      isBusinessApp: [true],
      isDirectSignup: [false],
      welcomeMessage: [''],
      adminEmail: this.fb.array([this.fb.control('', [Validators.email])])
    });

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.isBusinessApp = this.model.isBusinessApp;
            this.isDirectSignup = this.model.isDirectSignup;
            this.appSettingsForm.patchValue({
              appName: this.model.appName ? this.model.appName : this.currentUser.GivenName,
              buttonOption: this.model.buttonOption ? this.model.buttonOption : 'rectangularbutton',
              navbarOption: this.model.navbarOption ? this.model.navbarOption : 'bottomnavbar',
              themeColor: this.model.themeColor ? this.model.themeColor : '',
              rightOrLeftSidebar: this.model.rightOrLeftSidebar ? this.model.rightOrLeftSidebar : false,
              description: this.model.description ? this.model.description : '',
              serviceType: this.model.serviceType ? this.model.serviceType : 'Both',
              appLogo: this.model.logo ? this.model.logo: '',
              takeAppointment: this.model.takeAppointment ? this.model.takeAppointment : false,
              isBusinessApp: this.model.isBusinessApp ? this.model.isBusinessApp : false,
              serviceName: this.model.serviceName ? this.model.serviceName : 'Services',
              productName: this.model.productName ? this.model.productName : 'Products',
              isDirectSignup: this.model.isDirectSignup ? this.model.isDirectSignup : false,
              welcomeMessage: this.model.welcomeMessage ? this.model.welcomeMessage : '',
              adminEmail: this.model.adminEmail ? this.model.adminEmail : []
            });
            if (this.model.navbarOption === 'sidenavbar') {
              this.isSidebarMenu = true;
            }
            if (this.model.logo){
              this.showImageFromServer = true;
              this.oldUrl = this.model.logo ? this.model.logo : '';

            }

            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
      }
    }
  });
    // $('select').formSelect();
  }
  get adminEmail(){return this.appSettingsForm.get('adminEmail') as UntypedFormArray; }
  get serviceType(){return this.appSettingsForm.get('serviceType') }

  addAdminEmail(){
    this.adminEmail.push(this.fb.control('', Validators.email));

  }
  get appLogo() { return this.appSettingsForm.get('appLogo'); }

  SaveSettings(modelValues: any) {
      // update option
      this.model.buttonOption = modelValues.buttonOption;
      this.model.navbarOption = modelValues.navbarOption;
      this.model.themeColor = modelValues.themeColor;
      this.model.appName = modelValues.appName;
      this.model.description = modelValues.description;
      this.model.rightOrLeftSidebar = modelValues.rightOrLeftSidebar;
      this.model.serviceType = modelValues.serviceType;
      this.model.takeAppointment = modelValues.takeAppointment;
      this.model.isBusinessApp = true;
      this.model.serviceName = modelValues.serviceName;
      this.model.productName = modelValues.productName;
      this.model.isDirectSignup = modelValues.isDirectSignup;
      this.model.welcomeMessage = modelValues.welcomeMessage;
      this.model.adminEmail = modelValues.adminEmail;
      // updating
      if (this.model){
        if (this.isMobile){
          if (this.mobileBase64Image){
            const img = new ImageModel();
            img.imgBase64 = this.mobileBase64Image;
            this.mediaService.uploadImage(img).subscribe((upload) => {
              if (upload){
                this.model.logo = (upload.imgLink) ? (upload.imgLink) : '';
                this.appSettingsService.updateAppSettings(this.model).then(() => {
                  this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success'});
                  // this.backClicked();
                }).catch(err => {
                  this.toast({html: err.message, classes: 'red'});
                });
              }
            });

          } else {
            this.model.logo = '';
            this.appSettingsService.updateAppSettings(this.model).then(() => {
              this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });
              // this.backClicked();
            }).catch(err => {
              this.toast({html: err.message, classes: 'red'});
            });
          }
        } else {
          if (this.binDoc && this.oldUrl){
            this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(upload => {
              if (upload){
                this.model.logo = (upload.imgLink) ? upload.imgLink : '';
                this.appSettingsService.updateAppSettings(this.model).then(() => {
                  this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });
                  // this.backClicked();
                }).catch(err => {
                  this.toast({html: err.message, classes: 'red', type: 'failure'});
                });
              }
            });

          } else if (this.binDoc && !this.oldUrl){
            this.mediaService.UploadBinImage(this.binDoc).subscribe(
              upload => {
                if (upload){
                  this.model.logo = (upload.imgLink) ? upload.imgLink : '';
                  this.appSettingsService.updateAppSettings(this.model).then(() => {
                    this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });
                    // this.backClicked();
                  }).catch(err => {
                    this.toast({html: err.message, classes: 'red', type: 'failure'});
                  });
                }
              }
            );
          } else if (!this.binDoc && this.oldUrl){
            this.model.logo = this.oldUrl;
            this.appSettingsService.updateAppSettings(this.model).then(() => {
              this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });
              // this.backClicked();
            }).catch(err => {
              this.toast({html: err.message, classes: 'red', type: 'failure'});
            });
          } else{
            this.model.logo = '';
            this.appSettingsService.updateAppSettings(this.model).then(() => {
              this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });
              // this.backClicked();
            }).catch(err => {
              this.toast({html: err.message, classes: 'red', type: 'failure'});
            });
          }
        }

      }
      // creating new setting
      else {
        modelValues.appSections = this.appSections;
        if (this.isMobile && this.mobileBase64Image){
          const img = new ImageModel();
          img.imgBase64 = this.mobileBase64Image;
          this.mediaService.uploadImage(img).subscribe(upload => {
            if (upload){
              this.model.logo = upload.imgLink;
              this.appSettingsService.saveAppSettings(modelValues).then(
                () => {
                this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
                // this.backClicked();
              }).catch(err => {console.error(err); });

            }
          });
        }
        else if (!this.isMobile && this.binDoc){
            this.mediaService.UploadBinImage(this.binDoc).subscribe(
              upload => {
                if (upload){
                  this.model.logo = upload.imgLink;
                  this.appSettingsService.saveAppSettings(modelValues).then(
                    () => {
                    this.toast({ html: 'App Settings Successfully Saved!', classes: 'green', type: 'success' });
                    // this.backClicked();
                  }).catch(err => {console.error(err); });

                }
              }
            );
        }
         else {
          this.model.logo = '';
          this.appSettingsService.saveAppSettings(modelValues).then(
            () => {
            this.toast({ html: 'App Settings Successfully Saved!', classes: 'green', type: 'success'});
            // this.backClicked();
          }).catch(err => {console.error(err); });

        }

      }

  }

  cancelButtonClicked() {
  }

  // to darken and lighten colors
  shadeColor(originalColor: string, amount) {
    return '#' + originalColor.replace(/^#/, '')
    .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount))
    .toString(16))
    .substr(-2));
}
sidebarClicked() {
  this.isBottomMenu = false;
  this.isSidebarMenu = true;
}
bottomNavbarClicked() {
  this.isBottomMenu = true;
  this.isSidebarMenu = false;
}

backClicked(){
  this.location.back();
}
handleImageInput(files: FileList) {
  if (!this.validateImage(files[0].name)) {
    this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
    this.appLogo.patchValue('');
    return;
  }
  this.binDoc = files[0];
  this.imgErrorMsg = undefined;
  const reader = new FileReader();
  reader.readAsDataURL(files[0]);
  reader.onload = () => {
    this.base64Image = reader.result as string;
  };
}
private validateImage(name: any) {
  const ext = name.substring(name.lastIndexOf('.') + 1);
  if (ext.toLowerCase() === 'png') {
    return true;
  } else if (ext.toLowerCase() === 'jpg') {
    return true;
  } else if (ext.toLowerCase() === 'jpeg') {
    return true;
  } else {
    return false;
  }
}
removeFile() {
  this.binDoc = undefined;
  this.base64Image = undefined;
  this.appLogo.patchValue('');
}

removeSavedFile() {
  this.binDoc = undefined;
  this.base64Image = undefined;
  this.oldUrl = this.model.logo;
  this.model.logo = '';
  this.appLogo.patchValue('');
  this.showImageFromServer = false;
  this.showLogoInput = true;
}
onImageUpload(response:ImageUploaderResponseModel) {
  if (response.error){
    this.imgErrorMsg = response.errorMessage
    return
  }

  this.binDoc = response.binDoc;
  this.mobileBase64Image = response.imgBase64;
  this.base64Image = 'data:image/jpeg;base64,' + response.imgBase64;

  this.useBase64=true

}
handleMobilePhotoInput(){
  // navigator.camera.getPicture((image: any) => {
  //   const imageName = 'IMG.jpeg';
  //   const imageBlob = this.dataURItoBlob(image);
  //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
  //   this.binDoc = imageFile;
  //   this.base64Image = 'data:image/jpeg;base64,' + image;
  //   this.mobileBase64Image = image;

  // }, (error) => {
  //   alert(error);
  // },
  // {
  //   quality: 50,
  //   destinationType: navigator.camera.DestinationType.DATA_URL,
  //   encodingType: navigator.camera.EncodingType.JPEG,
  //   mediaType: navigator.camera.MediaType.PICTURE,
  //   sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
  // }
  // );
}

dataURItoBlob(dataURI) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/png' });
  return blob;
}

onAppTypeChange(){
  this.isBusinessApp = this.appSettingsForm.value.isBusinessApp;
  // $('.tooltipped').tooltip();
}

onAppSignupChange(){
  this.isDirectSignup = this.appSettingsForm.value.isDirectSignup;
}

toast(obj){
  this.toastMessage = obj.html;
  this.toastClass = obj.classes ? obj.classes : 'green' ;
  this.toastType = obj.type ? obj.type : 'success';
  this.openToast = true;
  setTimeout(() => {
    this.openToast = false;
  }, 2000);
}
}
