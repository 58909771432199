import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { ImageModel } from 'src/app/models/ImageModel';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { environment } from 'src/environments/environment';
import {
  Modal,
  Ripple,
  initTE,
} from "tw-elements";

@Component({
  selector: 'app-customize-card',
  templateUrl: './customize-card.component.html',
  styleUrls: ['./customize-card.component.css']
})
export class CustomizeCardComponent implements OnInit, OnChanges {
  @Input() cardViewSection;
  @Input() modalOpen;
  @Output() closeModal = new EventEmitter<string>();

  //card customization
  themeColor: any 
  defaultThemeColor = this.envService.getColor('primary');

  hexColor: string = '';
  backgroundColor: string = '';
  textColor: string;
  borderRadius
  boxWidth:number;
  boxHeight: number;
  cardRef: any;
  backgroundImage: any;
  position: number;
  imageChangedEventCardCustomization: any;
  cropModalOpenCardCustomization = false;
  base64ImageCardCustomization: any
  base64Image = '';
  imgErrorMsg: any;
  usePopupBase64=false
  mediaList = [];
  isMobile = false;

  appSettings:any
  pageSections: any
  section:any
  applyToEachSection = false
    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
  sliderRef: any;
  blogsRef: any;
  eventRef: any;
  productRef: any;
  aboutRef: any;
  serviceRef: any;
  appStoreRef: any;
  socialMediaRef: any;
  footerRef: any;
  welcomeRef: any;
  model: any[];
  selectedColor: any;
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };

  hello = ''
  mediaLink: any;
  uploadFileModalOpen = false;

  customizeImageModal = false;

  cardDesign =  {
    fontSize: '12px', 
    fontWeight: 'bold', 
    fontPosition: 'bottom', 
    cardShape: 'rectangle',
    imageShape: 'none', 
    imageStyle: 'full', 
    imageWidth: 60,
    imageHeight: 60,
    width: 160,
    height: 160, 
    boxColor: this.envService.getColor('primary'), 
    textColor: '#ffffff'
  }
  constructor(
    private router: Router,
    private settingsService: AppSettingsService, 
  private envService: EnvironmentService) { 
    this.getSettings();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardViewSection) {
      // this.getCardDetail()
      // Code to run when yourInputData changes
    }
  }

  ngOnInit(): void {
    initTE({ Modal, Ripple });
    if (!this.pageSections) {
      this.pageSections = GlobalConstants.EmptyPageSections;
  }


  }


  


  getSettings() {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          // this.backgroundColor = this.pageSections.themeColor;
          
          this.sliderRef = this.pageSections.appSections.filter(s => s.name === 'Slider')[0];
          this.blogsRef = this.pageSections.appSections.filter(s => s.originalName === 'Blogs')[0]; 
          this.eventRef = this.pageSections.appSections.filter(s => s.originalName === 'Events')[0]; 
          this.productRef = this.pageSections.appSections.filter(s => s.originalName === 'Products')[0]; 
          this.aboutRef = this.pageSections.appSections.filter(s => s.originalName === 'About Us')[0]; 
          this.serviceRef = this.pageSections.appSections.filter(s => s.originalName === 'Services')[0]; 
          this.appStoreRef = this.pageSections.appSections.filter(s => s.name === 'AppStore')[0]; 
          this.socialMediaRef = this.pageSections.appSections.filter(s => s.name === 'SocialMedia')[0]; 
          this.footerRef = this.pageSections.appSections.filter(s => s.originalName === 'Footer')[0]; 
          this.welcomeRef = this.pageSections.appSections.filter(s => s.name === 'WelcomePopup')[0]; 
        }
      
      }
    });

  }
  updateDimensions() {
    // Ensure the input values are positive numbers
    if (this.boxWidth < 0) {
      this.boxWidth = 0;
    }
    if (this.boxHeight < 0) {
      this.boxHeight = 0;
    }
  }
  updateBackgroundColor() {
    // Remove any leading '#' character from the hex color input
    const cleanedHexColor = this.hexColor.replace(/^#/, '');
    
    // Check if the input is a valid hex color code
    if (/^[0-9A-Fa-f]{6}$/.test(cleanedHexColor)) {
      this.backgroundColor = `#${cleanedHexColor}`;
    } else {
      this.backgroundColor = '';
    }
  }

  closeCroppingModal(){
    this.cropModalOpenCardCustomization = false
  }

  getCardDetail(){
    // this.pageSections.appSections.forEach((section:any) => {
    //   if((section.originalName == this.cardViewSection.originalName)){
    //     this.section = section
    //   }
    // });

    this.hello = this.cardViewSection.originalName;
  }

  saveSettings(){
    if(this.mediaList.length > 0){
      this.uploadFileModalOpen = true;
  }

  if(this.mediaList.length === 0){
    this.saveChanges()
  }
  }
  saveChanges(){

    if(this.applyToEachSection ){
      // this.pageSections.defaultCardView.backgroundColor = this.cardViewSection.section.cardDetail.backgroundColor
      // this.pageSections.defaultCardView.textColor = this.cardViewSection.section.cardDetail.textColor
      // this.pageSections.defaultCardView.boxWidth= this.cardViewSection.section.cardDetail.boxWidth
      // this.pageSections.defaultCardView.boxHeight= this.cardViewSection.section.cardDetail.boxHeight
      // this.pageSections.defaultCardView.borderRadius= this.cardViewSection.section.cardDetail.borderRadius
      this.pageSections.appSections.forEach((section:any) => {
        if(section.cardDetail){
          section.cardDetail.backgroundColor = this.cardViewSection.section.cardDetail.backgroundColor
          section.cardDetail.textColor = this.cardViewSection.section.cardDetail.textColor
          section.cardDetail.boxWidth= this.cardViewSection.section.cardDetail.boxWidth
          section.cardDetail.boxHeight= this.cardViewSection.section.cardDetail.boxHeight
          section.cardDetail.borderRadius= this.cardViewSection.section.cardDetail.borderRadius
        }else{
          section.cardDetail= this.cardViewSection.section.cardDetail
          
        }
        
 
      });

      this.updateCard()

    }else{
      this.pageSections.appSections.forEach((section:any) => {
        if((section.originalName == this.cardViewSection.originalName) && !this.applyToEachSection){
          section.cardDetail.backgroundColor = this.cardViewSection.section.cardDetail.backgroundColor
          section.cardDetail.textColor = this.cardViewSection.section.cardDetail.textColor
          section.cardDetail.boxWidth= this.cardViewSection.section.cardDetail.boxWidth
          section.cardDetail.boxHeight= this.cardViewSection.section.cardDetail.boxHeight
          section.cardDetail.borderRadius= this.cardViewSection.section.cardDetail.borderRadius
           this.updateCard()
        }
      });
    }
    
    

  
  }

  updateCard(){
    this.settingsService.updateAppSettings(this.pageSections).then(() => {
        this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
    });
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
   
    }, 2000);
  }

  closeCustomizeModal(){
    // this.closeModal.emit();

  }
  openUploadImageModal(){
    this.customizeImageModal = true;
  }
  closeCustomizeImageModal(){
    this.customizeImageModal = false
  }
}
