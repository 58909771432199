<div class="block rounded-lg md:w-9/12  mx-auto w-full py-4 bg-white text-center ">
  <div class="p-6">
    <div class="mb-2 flex flex-col w-full items-center justify-center gap-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
      <svg id="Layer_1" data-name="Layer 1" class="w-8 h-8 fill-green-600 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.8 199.67"><path d="M227.75,241.27c-6.71-3-10.69,1.69-15.65,7.34-14.6,16.65-42.42,14.65-55.94-3.47-2.49-3.35-4.46-4.72-9.14-4.18-27,3.09-46.08-17.17-41.8-43.93.43-2.72-.34-4.11-2.49-5.73C79.94,174,80.06,146.06,103,129a4.89,4.89,0,0,0,2.23-5.44C101,95.74,121.15,75.44,148.86,79.8c2.71.42,4.18-.22,5.78-2.44,16.24-22.62,45.89-22.74,61.84-.24,1.69,2.37,3.22,3.12,6.16,2.65,27.14-4.39,48.08,16.57,43.5,43.52-.51,3,.4,4.46,2.73,6.13,21.87,15.71,22.25,44.75.73,61-3.05,2.31-3.94,4.36-3.24,8.23C270.53,221.4,253,241.33,227.75,241.27Zm-79.34-90.5c-4,.09-7,1.67-8.85,5.36-2.11,4.19-1.1,7.88,1.92,11,8.14,8.34,16.32,16.64,24.72,24.71,4.44,4.29,9.59,4,14.22-.33,3.16-2.95,6.11-6.13,9.17-9.18l38.88-38.81c2.78-2.78,5.15-5.77,4.11-10.09-1.93-8-10.86-10.18-17.19-4-12.22,12-24.31,24.18-36.4,36.34-5.94,6-5.87,6-11.89-.1-3.86-3.91-7.76-7.77-11.63-11.67A9.56,9.56,0,0,0,148.41,150.77Z" transform="translate(-85.71 -60.32)"/></svg>
     
      <span *ngIf="type=='rename'">
        Section Renamed
      </span>
      <ng-container *ngIf="type=='create'">
        <span *ngIf="sectionsThatCanBeRenamed.indexOf(name) === -1; else newNameBlock">
          {{ messages[name] }}
        </span>
        <ng-template #newNameBlock>
          {{ returnNewName(name) }}
        </ng-template>
      </ng-container>
    </div>

  </div>

  <div
  class="flex gap-1 p-1 md:p-0 md:gap-10 mx-auto w-full justify-center">

      <button
      (click)="keepAddingEvent()"
      type="button"
      class="inline-block rounded border-2 border-gray-200   px-6 py-4  text-xs font-medium uppercase leading-normal text-customTeal transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
      data-te-ripple-init>
      <span *ngIf="['Hero', 'carouselCreated', 'welcomePopupCreated', 'footerCreated', 'socialsCreated', 'appointmentSetting', 'generalSetupSaved', 'brandSetupSaved', 'chooseNumber', 'seoCreated', 'email', 'sms', 'smsCustom'].includes(name)" class="flex space-x-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-6 h-6 stroke-customTeal">
          <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
        </svg>
        
      <span>Edit More</span>
      </span>
      <div class="flex gap-2  items-center justify-center" *ngIf="['blogCreated', 'eventCreated', 'productCreated', 'serviceCreated', 'aboutCreated','faqCreated', 'customElementCreated'].includes(name)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        <span>Add New {{displayName}}</span>
      </div>
      <span *ngIf="name=='employeeInvited'" >
        Add Employee
      </span>
      <span *ngIf="['Services', 'Products', 'About Us', 'Blogs', 'Events'].includes(name)">
        Rename this section
      </span>
      <span *ngIf="name=='customPageCreated'">Add New page</span>
      <span *ngIf="name=='showEmployee'">Edit employee setting</span>
      <span *ngIf="name == 'smsCustomized' || name == 'emailCustomized' ">Edit Email/SMS Signature</span>
      
  </button>
  <button
    (click)="nextStepActivated()"
    type="button"
    class="flex  items-center gap-2 justify-center rounded bg-customTeal px-6 py-4  text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
    data-te-ripple-init
    data-te-ripple-color="light">

  <span>
    {{nextStepName}} 
  </span> 
  <span class="flex space-x-2 p-1 rounded-full border border-solid border-white items-center">
  
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  </span>
  </button>
  
  </div>

  <!-- preview -->
 
  <div class="pt-8" *ngIf="['blogCreated', 'eventCreated', 'productCreated', 'serviceCreated', 'aboutCreated','customCreated', 'customElementCreated', 'faqCreated'].includes(name)">
    <hr> 
    <span class="my-6 flex flex-col w-full items-center justify-center gap-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">Pages Created</span>
    <app-view-events-list *ngIf="sectionPreview == 'event'" calledFrom="onBoarding"></app-view-events-list>    
    <app-services-list *ngIf="sectionPreview =='service'" calledFrom="onBoarding"></app-services-list>        
    <app-products *ngIf="sectionPreview =='product'" calledFrom="onBoarding"></app-products>
    <app-blog-list *ngIf="sectionPreview == 'blog'" calledFrom="onBoarding"></app-blog-list>
    <app-about-setting *ngIf="sectionPreview == 'about'" calledFromEmptyDashboard='About' [isShowCreated]="true"></app-about-setting>
    <app-custom-sections-list *ngIf="sectionPreview == 'custom' && name=='customCreated'" calledFromComponent="onBoarding"></app-custom-sections-list>
    <app-section-list *ngIf="sectionPreview == 'custom' && name=='customElementCreated'" calledFromEmptyDashboard ="custom" [sectionID]="customSectionID" > </app-section-list>
    <app-faq-list  *ngIf="sectionPreview == 'faq'" calledFromComponent="landingpage"></app-faq-list>
  </div>
</div>