import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
// import { toast } from 'materialize-css';
import { AreasOfInterestService } from './../../../services/firebase/areas-of-interest.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { PostsService } from 'src/app/services/firebase/posts.service';
import { ProductsService } from 'src/app/services/firebase/products.service';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';

// import * as moment from 'moment';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import moment from 'moment';
import { GetAppService } from 'src/app/services/firebase/get-app.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Subscription } from 'rxjs';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { EnvironmentService } from 'src/app/services/helper/env';
// declare var $: any;

@Component({
  selector: 'app-member-home',
  templateUrl: './member-home.component.html',
  styleUrls: ['./member-home.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class MemberHomeComponent implements OnInit {

  //Added
  date: any = moment();
  isProductClicked:boolean = false;
  isServiceClicked:boolean = false;
  isBlogClicked:boolean = false;
  isEventClicked:boolean = true;
  totalServices:number = 0;
  totalBlogs:number = 0;
  totalEvents:number = 0;
  totalProducts:number = 0;
  upcomingAppointments:number = 0;
  totalAppointments:number = 0;
  Events = [];
  allAppointments = [];
  allServices =[];
  allEvents = [];
  products = [];
  allBlogs = [];

  Service = [];
  allProducts = [];
  Blog = [];
  Event = [];

  //Added arrays
  allUpcomingServices=[];
  allUpcomingEvents=[];
  UpcomingEvents = [];
  //card detail
  cardElement = []
  customSectionCount = 0

  userId: string;
  user: any;
  currentUserId = '';
  myProfileMenu = true;
  showDefaultMenu = true;
  appMenu: any;
  appSettings:any;
  currentUser: any;

  routes = {
    Chats: '/user/chat-list',
    Staff: '/user/employee-list',
    News: '/user/news-list',
    Events: '/user/events',
    Setting: '/user/settings',
    UpdateProfile: '/user/my-profile',
    Posts: '/user/view-posts',
    ViewServices: '/user/view-services',

  };
  allAreasOfInterest: any = [];
  servicesName
  productsName
  eventsName = 'Events';
  blogsName='Blogs'
  hasService=false
  hasProducts=false
  filteredActiveAppointments = [];

  feedbackModal: boolean = false;
  emitted: any;

  userEmailForAppRequest: any;
  firstName:string;
  lastName:string;
  email:string;
  cellPhone: any;
  currentUserEmail: any;
  requestStatus: any;
  acceptedRequests: any;
  rejectedRequests: any;
  pendingRequests: any;
  groupedPendingItems:any;

  numberOfAccepted: number;
  numberOfPending:number;
  userRequested: boolean = false;

  ///////
  model:any;
  appLogo: any;
  appName: any;
  isServiceEnabled = false;

  hasAnApp:boolean = false;
  events : any;
  posts: any;
  globalUser: any;

  unOpenedEvents = 0;
  unOpenedPosts = 0;
  // newEventGlobal : any;
  newEventGlobal: Subscription | null = null;
  newBlogGlobal: Subscription | null = null;

  numberOfApps: number;
  apps:any;
  customSection: any;

  currentDate = new Date().toISOString().split('T')[0];
  filteredEventsList: any;
  invitedGlobal: any;

  constructor(
    private location: Location,
    private authService: AuthService,
    private customSectionService : CustomSectionService,
    private router: Router,
    private appSettingService: AppSettingsService,
    private areaService: AreasOfInterestService,
    private empService: EmployeeServicesService, //added
    private newsEventService: NewsEventService,
    private postsService: PostsService,
    private productsService: ProductsService,
    private appointmentService: AppointmentService,
    private productsServiceMenuService:ProductsServiceMenuService,
    private getAppService: GetAppService,
    private userService: UserService,
    private helperService: HelperService,
    private envService: EnvironmentService
    ) { }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    // this.globalUser = this.authService.getGlobalUser();

    this.currentUserId = this.currentUser.uid;
    this.currentUserEmail = this.currentUser.email;

    this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe((user:any)=>{
      if(user && user.length){
        this.globalUser = user[0];
        if(this.globalUser){
          this.getEvents();
          this.getBlogs();

        }
      }

    })

    this.appSettingService.getAppSettingsList().valueChanges().subscribe(menu =>{
      if (menu){
        if (menu.length > 0){
          this.model = menu[0];
          this.appLogo = this.model.logo ? this.model.logo : '';
          this.appName = this.model.appName ? this.model.appName : 'Going Mobile';
          if (this.model.isBusinessApp){
            this.productsServiceMenuService.productsName.subscribe(p=>{
              this.productsName = p;
            });
            this.productsServiceMenuService.servicesName.subscribe(p=>{
              this.servicesName = p;
            });
          }
          this.getCardSettings()
          this.model.appSections.forEach(element => {

            if(element.originalName=='Services' && element.isEnabled){
              if(element.isEnabled){
                this.isServiceEnabled = true;
              }
              else {
                this.isServiceEnabled = false;
              }
            }
          });
        }
      }
    });

    this.getAppService.getRequestByEmail(this.currentUserEmail).valueChanges().subscribe((user) => {
      if(user && user.length != 0){
        this.numberOfApps = user.length;
        this.apps = user.slice(0,4);
        this.hasAnApp = true;
      }
      else{
        this.hasAnApp = false;
      }
  })     

      this.getAppService.getRequestByEmail(this.currentUserEmail).valueChanges().subscribe((request: any) => {
        if(request && request.length != 0){
          this.userRequested = true;
         var splitFullName = request[0].fullName.split(" ");
          this.firstName = splitFullName[0];
          this.lastName = splitFullName[1];
          this.email = request[0].email
          this.cellPhone = request[0].mobileNumber
        }
      })
  }


  navigateTo(name) {

    if(name =='Blogs' || name=='Blog'){
    this.router.navigate(['/user/blog']);
    }
    else if(name =='Events'){
    this.router.navigate(['/user/events']);
    }
    else if(name =='Products'){
      this.router.navigate(['/user/view-products']);
    }
    else if(name =='Services'){
      this.router.navigate(['/user/services']);
    }
    else if(name =='Account'){
      this.router.navigate(['/user/settings']);
    }
    else if(name =='Message'){
      this.router.navigate(['/user/chat-list']);
    }
    else if(name =='Manage'){
      this.router.navigate([`/user/manage-app/${this.currentUser.uid}`]);
    }
    else if(name =='Verified'){
      this.router.navigate(['/user/get-verified']);
    }
    else if(name =='Client'){
      this.router.navigate([`/user/edit-clientInfo/${this.currentUserId}`]);
    }
    else if(name =='Manage'){
      this.router.navigate([`/user/manage-app/${this.currentUser.uid}`]);
    }
    else {
      this.customSectionRoute(name)
    }
    }

    customSectionRoute(sectionname){
      this.customSectionService.getCustomSectionByOriginalName(sectionname).valueChanges().subscribe((sec : any) => {
        this.customSection = sec;  
        if(this.customSection){
          this.router.navigate(['/user/custom-sections-list', this.customSection[0].id])
       }      
      })
    }

    getBlogs() {
      if (this.globalUser && this.globalUser.role === 'User') {
        this.newBlogGlobal = this.postsService.getUserPosts().valueChanges().subscribe((posts: any) => {
          if (posts && posts.length > 0) {
            this.posts = posts;
            this.unOpenedPosts = this.calculateUnopenedPosts();
          }
        });
      }
    }

    private calculateUnopenedPosts(): number {
      let count = 0;
  
      for (let i = 0; i < this.posts.length; i++) {
        let openedUsers = this.posts[i].opened;
  
        if (openedUsers && openedUsers.length !== 0 && !openedUsers.includes(this.globalUser.uid)) {
          count++;
        } else if(openedUsers && openedUsers.length !== 0 && openedUsers.includes(this.globalUser.uid)){
        } else {
          count++;
        }
      }
      return count;
    }


    getEvents() {
      if (this.globalUser && this.globalUser.role === 'User') {
        this.newEventGlobal = this.newsEventService.getUserEvents().valueChanges().subscribe((events: any) => {
          if (events && events.length > 0) {
            this.events = events;
            this.filteredEventsList = this.getSortedList(events);
            this.invitedGlobal = this.newsEventService.getEventsByShareType('Invited').valueChanges().subscribe((events:any) => {
              if(events && events.length > 0){
                events.forEach((element) =>{
                  
                  element.invitedSubs.forEach(elements => {
                    if(this.currentUser.uid === elements.uid){
                      this.filteredEventsList = [...this.filteredEventsList, element];
                    }
                    this.invitedGlobal.unsubscribe();
                  });
                
                })    
              }
              this.unOpenedEvents = this.calculateUnopenedEvents();

            })
          }
          this.newEventGlobal.unsubscribe();
        });
      }
    }

    getSortedList(unsortedElements: Array<any>){
      // Filter out past events
      const futureEvents = unsortedElements.filter(event => event.eventStartDate >= this.currentDate);
      // Sort the remaining events by date
      futureEvents.sort((a, b) => a.eventStartDate.localeCompare(b.eventStartDate));
      return futureEvents;
    }

    private calculateUnopenedEvents(): number {
      let count = 0;
    
      for (let i = 0; i < this.filteredEventsList.length; i++) {
        let openedUsers = this.filteredEventsList[i].opened;
        if (openedUsers && openedUsers.length !== 0) {
          if (!openedUsers.includes(this.globalUser.uid)) {
            count++;
          } else {
          }
        } else {
          count++;
        }
      }
      return count;
    }
    
  
    // private calculateUnopenedEvents(): number {
    //   let count = 0;
  
    //   for (let i = 0; i < this.filteredEventsList.length; i++) {
    //     let openedUsers = this.filteredEventsList[i].opened;
    //     if (openedUsers && openedUsers.length !== 0 && !openedUsers.includes(this.globalUser.uid)) {
    //       count++;
    //     }else if(openedUsers && openedUsers.length !== 0 && openedUsers.includes(this.globalUser.uid)){
    //     } else {
    //       count++;
    //     }
    //   }
    //   return count;
    // }

    ngOnDestroy() {
      if (this.newEventGlobal) {
        this.newEventGlobal.unsubscribe();
      }
    }

  getCardSettings(){
    let index = 1
    let length = 1

    this.model.appSections.forEach(section => {
      if(section.isCustomSection && section.isEnabled){
        this.customSectionCount++
      }
      if(section.originalName === 'Events' || ['Blogs', 'News'].includes(section.originalName) || section.originalName === 'Products'|| section.originalName === 'Services'|| section.isCustomSection || section.originalName === 'About Us' || section.originalName === 'Footer' ){
        let existingIndex = this.cardElement.findIndex(element => element.originalName === section.originalName);
        if (existingIndex !== -1) {
          // Element already exists, update it
          if( section &&  section.cardDetail &&  section.cardDetail.position){
            this.cardElement[existingIndex] = {name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position}
          }else if(section.cardDetail && !section.cardDetail.position ){
            this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: index} 
            index += 1
            length +=1

          }else{
            section.cardDetail = {
              backgroundColor: this.model.defaultCardView ? this.model.defaultCardView.backgroundColor: this.model.themeColor? this.model.themeColor :  this.envService.getColor('primary'),
              textColor: this.model.defaultCardView ? this.model.defaultCardView.textColor:  '#FFFFFF',
              borderRadius : this.model.defaultCardView ? this.model.defaultCardView.borderRadius :  12,
              boxWidth : this.model.defaultCardView ? this.model.defaultCardView.boxWidth:  160,
              boxHeight : this.model.defaultCardView ? this.model.defaultCardView.boxHeight:  160,
              backgroundImage :this.model.defaultCardView ? this.model.defaultCardView.backgroundImage:  '',
              position: length
            }
            this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: length}
            length +=1

          }

        } else {
          // Element doesn't exist, append it
        
          if(section.cardDetail && section.cardDetail.position){
            this.cardElement.push({name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position}) 
            length +=1
          }else if(section.cardDetail && section.cardDetail.position == 0){
            this.cardElement.push({name:section.name, originalName:section.originalName, section: section, position: 0});
            length +=1
           }else if(section.cardDetail){
            this.cardElement.push({name: section.name, originalName:section.originalName, section: section, position: index}) 
            index += 1
          }else{        
            section.cardDetail = {
              backgroundColor: this.model.defaultCardView ? this.model.defaultCardView.backgroundColor: this.model.themeColor? this.model.themeColor : this.envService.getColor('primary'),
              textColor: this.model.defaultCardView ? this.model.defaultCardView.textColor:  '#FFFFFF',
              borderRadius : this.model.defaultCardView ? this.model.defaultCardView.borderRadius :  12,
              boxWidth : this.model.defaultCardView ? this.model.defaultCardView.boxWidth:  160,
              boxHeight : this.model.defaultCardView ? this.model.defaultCardView.boxHeight:  160,
              backgroundImage :this.model.defaultCardView ? this.model.defaultCardView.backgroundImage:  '',
              position: length
            }
            this.cardElement.push({name: section.name, originalName: section.originalName, section: section, position: length}) 
            length +=1 
          }
        }
  
      } 
    });
    this.cardElement = this.cardElement.slice().sort((a, b) => a.position - b.position)
  }

}

