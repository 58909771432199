import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberModel } from 'src/app/models/memberModel';
import { environment } from 'src/environments/environment';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
// import { toast } from 'materialize-css';
import { WindowService } from 'src/app/services/window-service/window.service';
// import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
@Component({
  selector: 'app-mobile-registration',
  templateUrl: './mobile-registration.component.html',
  styleUrls: ['./mobile-registration.component.css']
})
export class MobileRegistrationComponent implements OnInit {

  windowRef: any;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;

  applicantID: string = null;
  member: MemberModel = null;


  public verificationCodeMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  step1 = true;
  step2 = false;
  step3 = false;


  verificationForm: UntypedFormGroup;
  get sin() { return this.verificationForm.get('sin'); }
  get isn() { return this.verificationForm.get('isn'); }
  get question() { return this.verificationForm.get('question'); }

  registrationForm: UntypedFormGroup;
  recaptchaVerified = false;

  get phoneNumber() { return this.registrationForm.get('phoneNumber'); }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private winService: WindowService,
    private db: AngularFirestore,
    private auth: AngularFireAuth,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        const id = + params.get('id');
        this.memberService.getMember(id).subscribe(data => {
          if (data) {
            this.member = data[0];
            this.verificationForm = this.fb.group({
            });

            if (this.member.PWA_Verify_SIN) {
              this.verificationForm.addControl('sin', new UntypedFormControl('', Validators.required));
            }
            if (this.member.PWA_Verify_ISN) {
              this.verificationForm.addControl('isn', new UntypedFormControl('', Validators.required));
            }
            if (this.member.PWA_Secret_Question) {
              this.verificationForm.addControl('question', new UntypedFormControl('', Validators.required));
            }
          }
        });
      }
    });


  }




  verification(data: any) {

    if (this.member.PWA_Verify_SIN) {
      if (data.value.sin !== Number(this.member.SIN)) {
        // tslint:disable-next-line: max-line-length
        // toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Social Insurance Number ! </span>', classes: 'red' });
        return;
      }
    }

    if (this.member.PWA_Verify_ISN) {
      if (data.value.isn !== Number(this.member.IndianStatusNo)) {
        // toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Indian Status Number ! </span>', classes: 'red' });
        return;
      }
    }

    if (this.member.PWA_Secret_Question) {

      const answer = this.member.PWA_Secret_Answer;
      const response = data.value.question;
      if (response.replace(/\s/g, '').toLowerCase() !== answer.replace(/\s/g, '').toLowerCase()) {
        // toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Answer ! </span>', classes: 'red' });
        return;
      }
    }

    // tslint:disable-next-line: max-line-length
    // toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Profile Verification Success. </span> ', classes: 'green' });

    this.step1 = false;
    this.step2 = true;

    // setTimeout(() => {
    //   this.windowRef = this.winService.windowRef;
    //   this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //     callback: (response) => {
    //       this.recaptchaVerified = true;
    //     }
    //   });
    //   this.windowRef.recaptchaVerifier.render();
    // }, 25);
  }

  sendVerificationCode() {

    if (!this.recaptchaVerified) {
      // tslint:disable-next-line: max-line-length
      // toast({ html: '<span> <i class="material-icons left">error_outline</i> Please Check Im Not A Robot Button ! </span>', classes: 'red' })
      return;
    }

    const appVerifier = this.windowRef.recaptchaVerifier;
    const num = '+1' + this.member.cell_phone_num;

    this.auth.signInWithPhoneNumber(num, appVerifier)
      .then(result => {
        this.windowRef.confirmationResult = result;
        this.registrationForm = this.fb.group({
          verificationCode: ['', Validators.required],
        });
        this.step2 = false;
        this.step3 = true;
      })
      .catch(error => console.error(error));
  }

  verifyLoginCode(value: any) {
    this.windowRef.confirmationResult
      .confirm(value.verificationCode)
      .then(result => {
        this.authService.isNewUser = result.additionalUserInfo.isNewUser;
        const newUser = {
          uid: result.user.uid,
          applicantID: this.member.applicantID,
          GivenName: this.member.GivenName,
          middle_name: this.member.middle_name,
          LastName: this.member.LastName,
          DOB: this.member.DOB,
          Sex: this.member.Sex,
          Band: this.member.Band,
          Email: this.member.Email,
          DateAdded: new Date(),
          role: 'Member',
          status: 'Enabled'
        };
        this.db.collection('BandMembers').doc(newUser.uid).set(newUser).then(() => {
          // tslint:disable-next-line: max-line-length
          // toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Phone Number Verification Success. </span> ', classes: 'green' });
          this.router.navigate(['member/home']);
        });

      })
      .catch(error => {
        // toast({ html: '<span> <i class="material-icons left">error_outline</i> ' + error.message + ' ! </span>', classes: 'red' })
      });
  }



}
