import { Component, OnInit, } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { GroupConversationsModel } from 'src/app/models/Conversations.Model';
import { ConversationsService } from 'src/app/services/firebase/conversations.service';
import { MessagesModel, NotificationModel } from 'src/app/models/Messages.Model';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { FCMpushService } from 'src/app/services/firebase/FCMpush.service';
import { AreasOfInterestService } from 'src/app/services/firebase/areas-of-interest.service';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';

// declare var navigator: any;

@Component({
    selector: 'app-group-messages',
    templateUrl: './group-messages.component.html',
    styleUrls: ['./group-messages.component.css'],

})
export class GroupMessagesComponent implements OnInit {

    isAdmin
    currentUser
    userId
    createModalOpen = false
    editModalOpen = false
    usersList
    newGroupForm: UntypedFormGroup
    editGroupForm: UntypedFormGroup

    // images
    imgErrorMsg: any;
    base64Image: any;
    binDoc: File;
    mobileBase64Image: any;

    isMobile = false
    filteredUser = []

    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
    groupSelected = false
    selectedConv
    convUsers = []
    showMessages = false
    interstsList


    selectedInterests = []
    areaOfInterestUsers = []
    filterByInterest = false
    step = 1
    loading = false
    selectedGroup
    onEdit = false

    perm: any;
    permMember: any;
    permPES: any;
    permAnalytics: any;
    permSocial: any;
    permSpecial: any;
    permChat: any;
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private mediaService: MediaService,
        private conversationsService: ConversationsService,
        private messagingService: MessagingService,
        private fcmPushService: FCMPushNotificationsService,
        private areaService: AreasOfInterestService,
        private permissionService: PermissionService,
        private notificationService: NotificationPreferenceService

    ) {
        if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web') {
            this.isMobile = true;
        }
    }


    ngOnInit() {
        this.newGroupForm = this.fb.group({
            name: ['', Validators.required],
            profilePicture: [''],
            description: [''],
            searchQuery: [''],
            users: this.fb.array([])
        });

        this.currentUser = this.authService.getGlobalUser();

        if (this.currentUser) {
            if (this.currentUser.role == "Admin") {
                this.isAdmin = true;


            }

            this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
                if (perm.length != 0) {
                    this.perm = perm[0];
                    //this.permission = this.perm.permission[0];
                    this.permMember = this.perm.permission[0]
                    this.permPES = this.perm.permission[1]
                    this.permAnalytics = this.perm.permission[2];
                    this.permSocial = this.perm.permission[3];
                    this.permSpecial = this.perm.permission[4];
                    this.permChat = this.perm.permission[5];
                }
            })
        }
    }

    get name() { return this.newGroupForm.get('name'); }
    get searchQuery() { return this.newGroupForm.get('searchQuery'); }
    get profilePicture() { return this.newGroupForm.get('profilePicture'); }
    get users() { return this.newGroupForm.get('users') as UntypedFormArray }


    nextStep() {
        this.step = this.step === 2 ? 1 : 2;
    }
    removeUserFromGroup(index) {
        this.users.removeAt(index)
    }
    onAreaSelect(event, area) {
    }

    onCreateGroup(updateUser = false) {
        this.createModalOpen = true
        this.areaService.getAreasOfInterest().valueChanges().subscribe(interests => {
            if (interests && interests.length > 0) {
                this.interstsList = interests
            }
        })

        this.userService.getUserByRole('User').valueChanges().subscribe((users: any) => {

            this.usersList = users
            if (updateUser) {
                this.usersList.push(this.currentUser)
                this.usersList.map(user => {
                    if (this.selectedConv.users.includes(user.uid)) {
                        this.selectUser(user)
                    }

                })
            }
            // this.filteredUser = users
        });
    }
    closeModal() {
        this.onEdit = false
        this.closeCreateModal()
        this.removeFile()
    }
    closeCreateModal() {
        this.step = 1
        this.createModalOpen = false
        this.filteredUser = []
        this.newGroupForm.reset()
        this.users.clear()


    }


    searchChat() {
        let value = this.searchQuery.value
        if (value && value.trim() != '') {
            // this.filteredUser = this.usersList
            this.filteredUser = this.usersList.filter(friend => {
                return (friend?.GivenName?.toLowerCase().includes(value?.toLowerCase()) || friend?.LastName?.toLowerCase().includes(value?.toLowerCase()))
            })


        } else {
            this.filteredUser = []
        }
    }

    filterByInterests() {

    }


    openUserSuggestion() {
        // this.filteredUser = this.usersList
    }

    selectUser(user) {
        let userExists = this.users.value.some(u => u.uid == user.uid)
        if (userExists) return

        let name = user.GivenName || user.FirstName
        let userData = new UntypedFormGroup({
            'firstName': new UntypedFormControl(name),
            'lastName': new UntypedFormControl(user.LastName),
            'uid': new UntypedFormControl(user.uid),
            'role': new UntypedFormControl(user.role)
        })
        this.users.push(userData)


    }
    onImageUpload(response: ImageUploaderResponseModel) {
        if (response.error) {
            this.imgErrorMsg = response.errorMessage
            return
        }

        this.binDoc = response.binDoc;
        this.mobileBase64Image = response.imgBase64;
        this.base64Image = 'data:image/jpeg;base64,' + response.imgBase64;
    }


    handleMobilePhotoInput() {
        // navigator.camera.getPicture((image: any) => {
        //     const imageName = 'IMG.jpeg';
        //     const imageBlob = this.dataURItoBlob(image);
        //     const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
        //     this.binDoc = imageFile;
        //     this.base64Image = 'data:image/jpeg;base64,' + image;
        //     this.mobileBase64Image = image;

        // }, (error) => {
        //     alert(error);
        // },
        //     {
        //         quality: 50,
        //         destinationType: navigator.camera.DestinationType.DATA_URL,
        //         encodingType: navigator.camera.EncodingType.JPEG,
        //         mediaType: navigator.camera.MediaType.PICTURE,
        //         sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
        //     }
        // );
    }

    handleImageInput(files: FileList) {
        if (!this.validateImage(files[0].name)) {
            this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

            return;
        }
        this.binDoc = files[0];
        this.imgErrorMsg = undefined;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        this.removeSavedFile()
        reader.onload = () => {
            this.base64Image = reader.result as string;
        };
    }

    private validateImage(name: any) {
        const ext = name.substring(name.lastIndexOf('.') + 1);
        if (ext.toLowerCase() === 'png') {
            return true;
        } else if (ext.toLowerCase() === 'jpg') {
            return true;
        } else if (ext.toLowerCase() === 'jpeg') {
            return true;
        } else {
            return false;
        }
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }


    removeFile() {
        this.binDoc = undefined;
        this.base64Image = undefined;
    }

    removeSavedFile() {
        this.profilePicture.patchValue('')
    }

    createGroup(values) {
        this.closeCreateModal()

        if (this.base64Image) {
            this.uploadImage(values)
        } else {
            this.saveGroup(values)
        }
    }


    uploadImage(values) {
        if (this.isMobile) {
            if (this.mobileBase64Image) {
                const img = new ImageModel();
                img.imgBase64 = this.mobileBase64Image;
                this.mediaService.uploadImage(img).subscribe((upload) => {
                    // this.loading = false;
                    if (upload) {
                        values.profilePicture = upload.imgLink;
                        this.saveGroup(values)
                        return;
                    }
                });
            }
        }
        else {
            if (this.binDoc) {
                this.mediaService.UploadBinImage(this.binDoc).subscribe(
                    upload => {
                        // this.loading = false;

                        if (upload) {
                            values.profilePicture = upload.imgLink;
                            this.saveGroup(values)
                            return;
                        }
                    }
                );
            }
        }
    }


    saveGroup(values) {
        if (this.onEdit) {
            this.updateGroup(values)
            this.onEdit = false
            return
        }
        let model = new GroupConversationsModel()
        model.admin = this.currentUser.uid
        model.dateCreated = new Date()
        model.name = values.name
        model.description = values.description
        model.profilePicture = values.profilePicture || ''
        model.users = values.users.map(user => user.uid)
        model.usersInfo = values.users;

        model.users.push(this.currentUser.uid)
        model.usersInfo.push(this.currentUser)
        model.unreadCount = model.users.map(user => { return { 'user': user, 'unreadCount': 0 } })

        let lastMessage = { message: '', timeSent: null };
        lastMessage.message = '';
        lastMessage.timeSent = new Date();
        model.lastMessage = lastMessage;
        model.isPrivate = false;
        model.previousMembers = [];

        this.conversationsService.createGroupConversation(model).then(x => {
            this.toast({ html: 'New Group created!', classes: 'green', type: 'success' });
        }).catch(() => {
            this.toast({ html: 'Something went wrong creating group.', classes: 'red', type: 'failure' });
        });

        this.removeFile()


    }

    updateGroup(values) {

        this.selectedConv.name = values.name
        this.selectedConv.description = values.description || ''
        this.selectedConv.profilePicture = values.profilePicture || ''


        let newUsers = [], allUsers = [], removedUsers = []
        values.users.map(user => {
            if (!this.selectedConv.users.includes(user.uid)) newUsers.push(user.uid)

            allUsers.push(user.uid)
        })
        this.selectedConv.users.map(user => {
            if (!allUsers.includes(user) && user !== this.currentUser.uid) {
                removedUsers.push(user)
            }
        })
        this.selectedConv.users = allUsers

        // remove user from unread count
        removedUsers.map(user => {
            let index = this.selectedConv.unreadCount.findIndex(u => u.user === user)
            if (index !== -1) {
                this.selectedConv.unreadCount.splice(index, 1)
            }
        })

        // if user was a previous memeber and added again remove from previous member list
        newUsers.map(user => {
            this.selectedConv.unreadCount.push({ 'user': user, 'unreadCount': 0 })
            let index = this.selectedConv.previousMembers.findIndex(u => u === user)
            if (index !== -1) {
                this.selectedConv.previousMembers.splice(index, 1)
            }

        })

        this.selectedConv.previousMembers = removedUsers


        this.conversationsService.updateGroupChat(this.selectedConv).then(x => {
            this.toast({ html: 'Group updated successfully!', classes: 'green', type: 'success' });
        }).catch(() => {
            this.toast({ html: 'Something went wrong updating group.', classes: 'red', type: 'failure' });
        });
        this.removeFile()
    }



    onGroupSelect(event) {
        this.selectedConv = event.group
        // this.selectedConv = event.group.id
        // this.convUsers= event.group.users
        this.groupSelected = true
    }

    onGroupEdit(event) {
        this.newGroupForm.patchValue({
            name: this.selectedConv.name,
            description: this.selectedConv.description,
            profilePicture: this.selectedConv.profilePicture
        })

        this.onEdit = true
        this.onCreateGroup(true)
    }




    onMobileGroupSelect(event) {
        this.showMessages = true
        this.onGroupSelect(event)
    }
    onBack(event) {
        this.showMessages = false
    }
    onMessageSent(values) {
        let newMessageObj = new MessagesModel();
        newMessageObj.timeStamp = new Date();
        newMessageObj.message = values.message;
        newMessageObj.fromId = this.currentUser.uid
        newMessageObj.conversationId = this.selectedConv.id

        newMessageObj.toId = this.selectedConv.id

        newMessageObj.edited = false;
        newMessageObj.likes = {
            count: 0,
            users: []
        };
        newMessageObj.attachment = values.attachment
        // this.sendMessage(newMessageObj)
        this.messagingService.createMessage(newMessageObj);
        // create last message for this conversation
        // update this last message

        let lastMessage = { message: '', timeSent: null };
        lastMessage.timeSent = newMessageObj.timeStamp;
        let unreadCount = this.selectedConv.unreadCount.map(u => {
            if (u.user !== this.currentUser.uid) {
                u.unreadCount = u.unreadCount + 1
            }
            else {
                u.unreadCount = 0
            }
            return u
        })
        lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${newMessageObj.message}`;

        this.selectedConv.unreadCount = unreadCount
        this.selectedConv.lastMessage = lastMessage
        this.conversationsService.updateGroupChat(this.selectedConv)
        // send push notification about new message
        this.sendNotification(newMessageObj)

    }

    async sendNotification(newMessageObj) {
        let notification = new NotificationModel()
        notification.title = this.selectedConv.name
        notification.body = newMessageObj.message
        notification.data = { chatType: 'group', notificationType: 'Message' }

        let users = this.selectedConv.users.filter(user => user !== this.currentUser.uid)
        if (newMessageObj.attachment.type && newMessageObj.attachment.type === 'image') {
            notification.image = newMessageObj.attachment.link
        }

        // to grab the whole user info ..
        let fullUsersList = [];

        // iterate over the users array and grab the whole user information
        for (let i = 0; i < users?.length; i++) {

            let user = this.selectedConv.usersInfo.find((selectedUser) => selectedUser?.uid == users[i]);
            fullUsersList.push(user);

        }

        this.selectedConv.usersInfo = fullUsersList;

        // send notification for the selected users
        if (fullUsersList?.length) {
            await this.notificationService.sendNotificationForListOfUsers(notification, fullUsersList);
        }

    }

    toast(obj) {
        this.toastMessage = obj.html;
        this.toastClass = obj.classes ? obj.classes : 'green';
        this.toastType = obj.type ? obj.type : 'success';
        this.openToast = true;
        setTimeout(() => {
            this.openToast = false;
        }, 2000);

        this.removeFile()
        this.newGroupForm.reset()
    }


    getUserData(user) {

        let data = {
            "img": user.profileImgLink || '',
            "imgWidth": 12,
            "text": `${user.GivenName || user.FirstName} ${user.LastName}` || '',
        }
        return data


    }
}
