import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  createID() {
    return this.db.createId();
  }

  createVerificationRequest(model: any) {
    model.ID = this.db.createId();
    return this.db.collection('VerificationRequests').doc(model.id).set(model);

  }

  updateVerificationRequest(model: any) {
    return this.db.collection('VerificationRequests').doc(model.id).update(model);

  }
  getVerificationReqByUserId(userId: string){
    return this.db.collection('VerificationRequests', ref => ref.where('uid', '==', userId));
  }

  getVerificationReqByEmail(email: string){
    return this.db.collection('VerificationRequests', ref => ref.where('email', '==', email));
  }

  getVerifiedAccounts(){
    return this.db.collection('VerificationRequests', ref => ref.where('status', '==', "Accepted"));
  }

  deleteVerificationRequest(id: string){
    return this.db.collection('VerificationRequests').doc(id).delete();
  }
  getPendingVerificationRequests(){
    return this.db.collection('VerificationRequests', ref => ref.where('status', '==', 'Pending').limit(100));
  }
  getAcceptedVerificationRequests(){
    return this.db.collection('VerificationRequests', ref => ref.where('status', '==', 'Accepted').limit(100));
  }
  getDeclinedVerificationRequests(){
    return this.db.collection('VerificationRequests', ref => ref.where('status', '==', 'Declined').limit(100));
  }
  getAllVerificationRequests(){
    return this.db.collection('VerificationRequests');
  }
}
