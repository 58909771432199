<div>
    <div>
      <label class="flex space-x-1 items-center cursor-pointer bg-customTeal px-4 py-1 rounded-full shadow-md" (click)="addNote()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" hight="12" class="text-white fill-white"
          viewBox="0 0 448 512">
          <path
            d="M240 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H176V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H240V80z" />
        </svg>
        <p class="font-bold text-white text-sm">Add a Note</p>
      </label>
    </div>
    <hr>
    <ng-container *ngIf="notes">
      <div *ngFor="let note of notes  | slice:0:3;" class=" ">
        <!-- Note Item -->
        <div class="px-4 py-2">
          <a [routerLink]="['/admin/client-detail',note.userId]" class="">
            <p class="font-bold text-sm text-customTeal">{{note.subscriberName | titlecase}}</p>
            <p class="text-sm">{{truncateHTML(note.Message,100)}}</p>
            <div class="flex items-center w-40 space-x-2 border border-solid border-gray-300 rounded-full px-2 py-1 bg-white shadow-sm" *ngIf="note.reminder && note.reminderTime">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-gray-500" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
              <p class="text-xs text-gray-500 ">{{note.reminder}} : {{note.reminderTime}}</p>  
            </div>
          </a>
        </div>
        <hr>
      </div>

      <a href="" class="flex justify-end font-bold text-sm mx-4 my-2" *ngIf=" notes && notes.length > 4"
        [routerLink]="['/admin/search-users']">View all Notes</a>

      <!-- Empty Notes -->
      <div *ngIf="notes.length==0" class="my-4">
        <div class="flex items-center justify-center">
          <img loading="lazy" data-src="../../../../assets/icons/Illustrations/empty reminder-01.svg" class="block w-24 items-center justify-center" alt="">
        </div>
        <div  *ngIf="isAll">
          <p class="text-customTeal text-base text-center font-bold" >Seems like you don't have a reminder. </p>
          <p class="text-gray-400 text-sm text-center">Your notes will appear here.</p> 
        </div>
      </div>
    </ng-container>

  </div>

  <div id="my-modal-disable" *ngIf="noteModal" class="modal"
  [ngClass]="{'modal-open': noteModal, 'modal-action': !noteModal }">
  <div class="modal-box max-w-3xl">
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-2"> Add Note </h3>
      <hr />

      <form [formGroup]="notesForm" (ngSubmit)="userNote(notesForm.value)">
        <!-- Select subscriber -->
        <div class="dropdown w-full">
          <label class="text-sm text-gray-500 font-bold ">Select a subscriber</label>
          <label tabindex="0"
          class="btn  w-full rounded-sm bg-white mx-0 px-0 text-gray-700 flex flex-row justify-between hover:bg-gray-100">
          <input type="email" class="input focus:border-none  w-full"
          id="search-text" aria-describedby="search-text" 
          formControlName="subscriber" [(ngModel)]="searchText"[ngClass]="{
            'border-red-500': (subscriber.dirty || subscriber.touched) && subscriber.invalid,
            'border-gray-200': !((subscriber.dirty || subscriber.touched) && subscriber.invalid)}"
            placeholder="Enter Subscriber's name" autofocus>
            </label>
          <ul tabindex="0" onclick="document.activeElement.blur()" class="dropdown-content menu p-2 shadow  bg-white text-gray-700 rounded-box w-full max-h-32 overflow-y-scroll">
            <ng-container *ngFor="let sub of users; let i=index">
                <li><a onclick="document.activeElement.blur()" (click)="setSubscriber(sub)">{{sub.GivenName}} {{sub.LastName}}</a></li>
            </ng-container>
          </ul>
        </div>

        <!-- Message/note -->
        <div>
          <label class="text-sm text-gray-500 font-bold">Notes<span
            class="text-red-500">*</span></label>
          <textarea id="message" rows="4" formControlName="note"
            class="block md:ml-3 ml-0 p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder=" Notes..."></textarea>
          <div *ngIf="(note.dirty || note.touched) && note.invalid">
            <small *ngIf="note.errors.required" class="text-red-500 italic">Reminder Note is requried </small>
          </div>
        </div>

        <div class="w-full my-2 md:mb-0 md:flex-row flex-col flex">
          <!-- Date -->
          <div class="flex flex-col w-full">
            <label class="text-sm text-gray-500 font-bold mb-2">Date</label>
            <input id="ReminderDate" formControlName="ReminderDate" type="date"
              class="input input-bordered md:w-2/3">
            <div *ngIf="(ReminderDate.dirty || ReminderDate.touched) && ReminderDate.invalid">
              <small *ngIf="ReminderDate.errors.required" class="text-red-500 italic">Date of the Reminder is required. </small>
            </div>
          </div>

          <!-- Time -->
          <div class="flex flex-col w-full">
           
          <label class="text-sm text-gray-500 font-bold mb-2">Time</label>
            <div class="flex w-full">
              <div class="bg-white rounded-lg w-full">
                <div class="dropdown  w-full">
                  <label tabindex="0"
                    class="btn bg-white mx-0 px-0 w-full rounded-sm text-gray-700   flex flex-row justify-between hover:bg-gray-100">

                    <input class="input focus:border-none  w-full" maxlength="8"
                      formControlName="ReminderTime" [ngClass]="{
                      'border-red-500': (ReminderTime.dirty || ReminderTime.touched) && ReminderTime.invalid,
                      'border-gray-200': !((ReminderTime.dirty || ReminderTime.touched) && ReminderTime.invalid)}" />
                    <!-- <i class="material-icons">arrow_drop_down</i> -->
                  </label>
                  <ul tabindex="0" onclick="document.activeElement.blur()"
                    class="dropdown-content menu p-2 shadow  bg-white text-gray-700 rounded-box w-full max-h-52  overflow-y-scroll">
                    <ng-container *ngFor="let item of times">
                      <li><a onclick="document.activeElement.blur()" (click)="setStartTime(item)">{{item}}</a></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {{notesForm.vaild}}
        <div class="modal-action items-center justify-between mx-6">
          <button (click)="closeNote()" class="font-bold text-customTeal border-none">Cancel</button>
          <button class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md" type="submit"
            [disabled]="!notesForm.valid">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
