import { Component, OnInit } from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { PostAnalyticsModel,analysisPlatforms,commentPlatforms, PostCommentModel, DeletePostModel, platformColors} from 'src/app/models/SocialMediaPostModel';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import { icons } from 'src/app/models/SocialMediaIcons';
// import { EmbedVideoService } from 'ngx-embed-video';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { HelperService } from 'src/app/services/helper/helper';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { NotificationModel } from 'src/app/models/Messages.Model';


@Component({
  selector: 'app-social-media-post-detail',
  templateUrl: './post.detail.component.html',
  styleUrls: ['./post.detail.component.css']
})
export class SocialMediaPostDetailComponent implements OnInit {


    availablePlatforms=[]
    profileKey
    imgErrorMsg
    socialMediaIcons=icons
    id
    analyticsModel
    platformAnalytics
    post
    loading
    hasActiveAccounts
    userProfile
    mediaAnalysisPlatforms=[...analysisPlatforms]
    commentablePlatforms=commentPlatforms
    objectKeys = Object.keys
    commentModal
    facebookComments=[]
    instagramComments=[]
    twitterComments=[]
    comments
    selectedComments=[]
    selectedPlatform
    postCommentForm: UntypedFormGroup;

    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
    commentModalOpen=false
    deleteModalOpen=false

    currentUser: any;
    notifSettings
    constructor(
        private socialMediaService:AyrshareService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private location:Location,
        private profieKeyService:ProfieKeyService,
        // private embedService: EmbedVideoService,
        private fb: UntypedFormBuilder,
        private pushNotificationsService: FCMPushNotificationsService,
        private appSettingsService: AppSettingsService,
        private helperervice: HelperService,
        private authService: AuthService,
      


    ) { }

    ngOnInit() {
      this.mediaAnalysisPlatforms.push('youtube')
      this.activatedRoute.paramMap.subscribe(params => {
        this.id = params.get('id');
  
      });
      
      this.profieKeyService.currentProfileKey.subscribe(p=>{
        this.profileKey=p
      })


      if(this.profileKey){
        this.loading = true;
        this.socialMediaService.getUserByProfileKey(this.profileKey).toPromise()
        .then(data => {

            this.userProfile = data
            this.hasActiveAccounts = true ? data['activeSocialAccounts'] && data['activeSocialAccounts'].length > 0 : false
            if ( this.userProfile.displayNames.some(x=>x.platform=='linkedin' && x.type=='corporate')) this.mediaAnalysisPlatforms.push('linkedin')
            this.getPost()
            this.loading=false
        })
        .catch(err => {

            this.loading = false;


        })

        this.currentUser = this.authService.getGlobalUser();
        if(this.currentUser && (this.currentUser.role == 'Employee' || this.currentUser.role == 'Admin')){
          this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x=>{
            if(x && x.length > 0){
              this.notifSettings=x[0]
            }
          })
        }


    }

    this.postCommentForm = this.fb.group({
      comment:['',[Validators.required]],
      platforms:this.fb.array([],[Validators.required]),
  })


    }

    get commentPlatforms(){
      return (<UntypedFormArray>this.postCommentForm.get('platforms'))
  
    }

    getPost(){
      if(this.id){
        

        this.socialMediaService.getHistoryById(this.id,this.profileKey).toPromise().then(post=>{
         
          this.post=post
  
          this.analyticsModel = new PostAnalyticsModel()
          this.analyticsModel.id = this.id
          this.analyticsModel.profileKey = this.profileKey
          let platforms = this.post.platforms.filter(platform=>this.mediaAnalysisPlatforms.includes(platform))
                
          // this.analyticsModel.platforms = this.post.platforms.filter(platform=>this.mediaAnalysisPlatforms.includes(platform))
          if (this.post.status == 'success'){

            // if( this.hasActiveAccounts && this.getUserMediaPlatforms().length > 0 && this.analyticsModel.platforms.length > 0){
            if( this.hasActiveAccounts && this.getUserMediaPlatforms().length > 0 && platforms.length > 0){
            
            this.socialMediaService.getPostAnalytics(this.analyticsModel).toPromise()
                      .then(a=>{
                          this.platformAnalytics = a
                         
                         
                         
                      }).catch(err=>{
                          this.platformAnalytics=[]
                          console.error(err)
                      })
                  }
            
            if( this.getCommentsPlatforms().length>0 &&  this.getUserCommentablePlatforms().length>0 ){
                    this.getComments()
            
                  }
          }
          
          
        }).catch(err=>{
          console.error(err)
        })
      }
    }

    openCommentModal() {
      this.commentModalOpen = true;
    }

    getComments(){


        this.socialMediaService.getCommentsOnPost(this.post.id,this.profileKey).toPromise()
        .then(comment=>{
          this.comments=comment

          this.selectPlatform(this.getCommentsPlatforms()[0])
            

        }).catch(err=>{
            console.error(err)
        })

    }

    commentable(){

      return this.post.platforms.reduce((res,p)=>{
        if(this.commentablePlatforms.includes(p)) res=true
        return res
      },false)
    }

    selectPlatform(p){
      if(this.selectedPlatform){
        let i: number = 0;

          this.commentPlatforms.controls.forEach((ctrl: UntypedFormControl) => {
            if (ctrl.value == this.selectedPlatform) {
              this.commentPlatforms.removeAt(i);
              return;
            }

            i++;
          });
      }
      this.selectedPlatform=p
      this.commentPlatforms.push(new UntypedFormControl(this.selectedPlatform))

      this.selectedComments = this.comments[this.selectedPlatform]
    }

    imageMedia(url){
      
      url = new URL(url)
      
      let urlSplit= url.pathname.split('/')
      let media = urlSplit[3]
      
      if((url.host =="images.ayrshare.com") || ["images",'image'].includes(media.toLowerCase())){
        return true
      }else{
        return false
      }
    }

    sanitizeVideoUrl(url){
    // return this.embedService.embed(url)
   
    }
    getUserMediaPlatforms(){
      return this.userProfile['activeSocialAccounts'].filter(s=>{
          return this.mediaAnalysisPlatforms.includes(s)
      })
  }

    getCommentsPlatforms(){
      return this.post.platforms.filter(s=>{
          return this.commentablePlatforms.includes(s)
      })
  }

  getUserCommentablePlatforms(){

    return this.userProfile['activeSocialAccounts'].filter(s=>{
      return this.commentablePlatforms.includes(s)
  })
}


getBorder(platform){
  var classList='';
  switch (platform) {
      case 'instagram':
          classList = 'instagram-wrapper wrapper--rounded'; 
          break
      case 'tiktok':
          classList = 'tiktok-wrapper wrapper--rounded'; 
          break
      default:
          classList = '';
  }
  return classList;
}

getStyleBorder(){
  return {'border-color':  platformColors[this.selectedPlatform], 'border-style': 'solid'}
}

borderdPlatform(){
  return ['instagram','tiktok'].includes(this.selectedPlatform) ? false : true
}


    getPlatformData(platform,analytics){
      let handler
      switch (platform) {
          case 'facebook':
              handler = this.getFacebookData
              break
          case 'twitter':
              handler = this.getTwitterData
              break
          case 'pinterest':
              handler = this.getPinterestData
              break
          case 'instagram':
              handler = this.getInstagramData
              break
          case 'youtube':
                handler = this.getYoutubeData
                break
          case 'linkedin':
                handler = this.getLinkedinData
                break
          case 'tiktok':
                handler = this.getTikTokData
                break

      }
      return handler(analytics)
  }

  getLinkedinData(analytics){
    return {
        color:"#0072b1",
        middleSubText:true,
        content:
           {
                Likes:analytics.likeCount,
                Comments:analytics.commentCount,
                Engagment:analytics.engagement,
                Share:analytics.shareCount,
                Clicks:analytics.clickCount,
                Impressions:analytics.impressionCount,
            },
        platform:"linkedin",
    }
}


  getYoutubeData(analytics){
    return {
        color:"#c4302b",
        middleSubText:true,
        content:
           {
                Likes:analytics.likes,
                'Average view duration':analytics.averageViewDuration,
                Views:analytics.views,
                Dislikes:analytics.dislikes,
                Comments:analytics.comments,
                'Subscribers gained':analytics.subscribersGained

            },
        platform:"youtube"
    }
}

  getFacebookData(analytics){
      return {
          color:"#4267B2",
          middleSubText:true,
          content:
             {
                  Engagment:analytics.engagedUsers,
                  Impression:analytics.impressionsUnique,
                  Clicks:analytics.clicksUnique,
                  'Negative feeedbak':analytics.negativeFeedback,
                  Reactions:analytics.reactions.total ? analytics.reactions && Object.keys(analytics.reactions).length > 0 : 0

              },
          platform:"facebook"
      }
  }
  getTwitterData(analytics){
      return {
          color:"#1da1f2",
          middleSubText:true,
          content:
             {
                  Likes:analytics.organicMetrics.likeCount,
                  Impression:analytics.organicMetrics.impressionCount,
                  Replies:analytics.organicMetrics.replyCount,
                  'User profile clicks':analytics.organicMetrics.userProfileClicks,
                  Retweets:analytics.organicMetrics.replyCount,
              },
          platform:"twitter",
          image:analytics.profileImageUrl
      }
  }
  getPinterestData(analytics){
      return {
          color:"#E60023",
          middleSubText:true,
          content:
             {
                  'Outbound click':analytics.outboundClick,
                  Impression:analytics.impression,
                  Save:analytics.save,
                  'Pin click':analytics.pinClick,
                  'Video view':analytics.videoMrcView,
              }
          ,
          platform:"pinterest"
      }
  }
  getInstagramData(analytics){
      return {
          color:"#C32889",
          middleSubText:true,
          content:
             {
                  Likes:analytics.likeCount,
                  Comments:analytics.commentsCount,
                  Impressions:analytics.impressionsCount,
                  Engagement:analytics.engagementCount,
                  Saved:analytics.savedCount,
              },
          platform:"instagram"
      }
  }

  getTikTokData(analytics){
      return {
          color:"#E60023",
          middleSubText:true,
          content:
             {
                  Engagment:analytics.engagement,
                  Impression:analytics.impression,
                  Save:analytics.save,
                  'Pin click':analytics.pinClick,
              },
          platform:"tiktok"
      }
  }


  
  commentOnPost(values){

    let model = new PostCommentModel()
  
    Object.keys(values).forEach(key=>{
  
      model[key] =values[key]
  })
 
    model.profileKey = this.profileKey
    model.id = this.post.id

    this.commentModalOpen=false

    this.socialMediaService.commentOnPost(model).toPromise().then(message=>{
      
      this.toast({ html: 'Comment Successfully Posted', classes: 'green', type: 'success' });
      this.sendPushNotificationToOwner(model.platforms)

      setTimeout(() => {
        this.ngOnInit()
      }, 2000);
      
    }).catch(err=>{
      console.error(err)
  
    })
  }

  sendPushNotificationToOwner(platforms){
    if(this.notifSettings && this.currentUser && this.currentUser.role === "Employee"){
      let conditions=["socialMedia","employeeChange"]
      if(this.helperervice.checkNotificationForOwner(this.notifSettings,conditions)){
        let  createdByFullName= this.currentUser.GivenName + ' ' + this.currentUser.LastName
        let notification = new NotificationModel()
        notification.title='Employee Commented on Social Media Post'
        notification.body=`${createdByFullName} commented on a post in ${platforms.join()}.`
        this.pushNotificationsService.sendNotificationByRole(notification,'Admin')
        // this.pushNotificationsService.sendNotificationToAllTheMembers('Employee Commented on Social Media Post',`${createdByFullName} commented on a post in ${platforms.join()}.`,'Owner_Notification')

      }
    }
  }

  deletePost(){

    let model = new DeletePostModel()
    model.profileKeys= this.profileKey
    model.id = this.post.id
 
    model.profileKeys = this.profileKey
    model.id = this.post.id

    this.deleteModalOpen=false

    this.socialMediaService.deletePost(model).toPromise().then(message=>{
      
      this.toast({ html: 'Post Successfully Deleted', classes: 'green', type: 'success' });
      setTimeout(() => {
        this.backToPosts();
      }, 2000);

    }).catch(err=>{
      console.error(err)
      this.toast({html: err["error"]['message'], classes: 'red'})




    })

  }

  toast(obj) {

    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }


  backToPosts(){
    this.router.navigate(['../../posts'],
        {
            relativeTo: this.activatedRoute
        }
        )


  }



}
