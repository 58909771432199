import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Location } from '@angular/common';
import { AuthService } from "src/app/services/firebase/auth.service";
import { PermissionService } from "src/app/services/firebase/permission.service";
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

@Component({
  selector: 'app-create-new-section',
  templateUrl: './create-new-section.component.html',
  styleUrls: ['./create-new-section.component.css']
})
export class CreateNewSectionComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Output() customCreated = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();

  newSectionForm: UntypedFormGroup;
  createModalOpen = false;
  cancelModalOpen = false;
  optionsList = [
    {
      optionName: '',
      originalOptionName: 'Title',
      isRequired: true,
      optionDescription: ''
    },
    {
      optionName: '',
      originalOptionName: 'Description',
      isRequired: false,
      optionDescription: ''
    },
    // {
    //   optionName: '',
    //   originalOptionName: 'Lists',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    {
      optionName: '',
      originalOptionName: 'Price',
      isRequired: false,
      optionDescription: ''
    },
    // {
    //   optionName: '',
    //   originalOptionName: 'Start Time',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    // {
    //   optionName: '',
    //   originalOptionName: 'Location',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    // {
    //   optionName: '',
    //   originalOptionName: 'End Time',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    {
      optionName: '',
      originalOptionName: 'Comment',
      isRequired: false,
      optionDescription: ''
    },
    {
      optionName: '',
      originalOptionName: 'Link',
      isRequired: false,
      optionDescription: ''
    },    {
      optionName: '',
      originalOptionName: 'Image Upload',
      isRequired: false,
      optionDescription: ''
    },
    {
      optionName: '',
      originalOptionName: 'Video Upload',
      isRequired: false,
      optionDescription: ''
    },
    // {
    //   optionName: '',
    //   originalOptionName: 'PDF Upload',
    //   isRequired: false,
    //   optionDescription: ''
    // },
  ];
  appSettings: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  currentUser: any;
  permCustomPage: any;
  perm: any;
  haveAccessPermission = false;
  appSettings$: Subscription;
  constructor(private fb: FormBuilder, 
    public appSettingsService: AppSettingsService,
    private customSectionService: CustomSectionService, 
    private helperService: HelperService,
    private location: Location,
    private authService: AuthService,
    private permissionService: PermissionService,
    private store: Store) {
      this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
        this.appSettings = this.helperService.cloneDeep(settings) || { appSections: [] };
      });
    }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser){
      if(this.currentUser.role === 'Employee'){
        this.permissionService
        .getPermissionByEmployeeId(this.currentUser.uid)
        .valueChanges()
        .subscribe((perm: any) => {
          if (perm.length > 0) {
            this.perm = perm[0];
            this.permCustomPage = this.perm.permission[6];
            if (this.permCustomPage.createPage) {
              this.haveAccessPermission = true;
            }
          }
        });
      }
      if(this.currentUser.role === 'Admin'){
        this.haveAccessPermission = true;
      }
    }
    this.newSectionForm = this.fb.group({
      sectionTitle: ['', Validators.required],
      sectionDesign: [''],
      options: this.fb.array([], [Validators.required]),
      addToMenu: [true], 
      enableInLandingPage: [true]
    });
  }

  get sectionTitle(){ return this.newSectionForm.get('sectionTitle'); }
  get sectionDesign(){ return this.newSectionForm.get('sectionDesign'); }
  get options() { return this.newSectionForm.get('options'); }
  get enableInLandingPage() { return this.newSectionForm.get('enableInLandingPage'); }
  
  
  saveSection(value){
    this.createModalOpen = false;
    value.sectionTitle = this.helperService.getInnerText(value.sectionTitle);
    value.originalName = value.sectionTitle.replace(/\s/g, '')
    let sectionTitles = []
    this.appSettings.appSections.forEach(element => {
      sectionTitles.push(element.name);
      sectionTitles.push(element.originalName)
    });
    if(sectionTitles.includes(value.sectionTitle)){
        this.toast({html: "Page exists", classes: 'red', type:'failure'});
    }
    if(this.appSettings && !sectionTitles.includes(value.sectionTitle)){
      this.appSettings.appSections.push({
        index: this.appSettings.appSections.length + 1,
        isEnabled: false,
        bgColor: '#FFFFFF',
        name: value.sectionTitle,
        originalName: value.sectionTitle.replace(/\s/g, ''),
        displayName: value.sectionTitle,
        addToMenu: value.addToMenu,
        displayNameColor: '#000000',
        isCustomSection: true
      });
      this.customSectionService.saveCustomSection(value).then(() => {
        this.appSettingsService.updateAppSettings(this.appSettings).then(() => {
          this.toast({ html: 'Custom Section created successfully!', classes: 'green', type: 'success'});
          if(this.calledFromEmptyDashboard == 'Custom'){
            this.customCreated.emit('customPageCreated')
            this.goToTopOfNextPage.emit();
          }
        }).catch(err => {
          this.toast({html: err.message, classes: 'red', type: 'failure'});
        });
      }).catch(err => {
        this.toast({html: err.message, classes: 'red', type:'failure'});
      });
    }
  }



  onCheckboxChange(e: any) {
    let option ={};
    const checkArray: UntypedFormArray = this.newSectionForm.get('options') as UntypedFormArray;
    if (e.target.checked) {
      option = this.optionsList.filter(opt => opt.originalOptionName === e.target.value);
      checkArray.push(new UntypedFormControl(option[0]));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value.on == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }


  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if(this.calledFromEmptyDashboard !='Custom'){
        this.backClicked();
      }
      
    }, 2000);
  }
  
  closeCreateModal(){
    this.createModalOpen = false;
  }
  
  closeCancelModal(){
    this.cancelModalOpen = false;
  }
  
  backClicked(){
    this.location.back();
  }
}
