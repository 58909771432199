<div class="flex gap-4 md:flex-row flex-col">
  
<div >

  <app-page-view-setup type="primary" calledFrom="card"></app-page-view-setup>

  <!-- Card style customization -->
  <div class="collapse collapse-arrow bg-white rounded-sm shadow-sm border border-solid border-gray-200"  (click)="customizeCardStatus(true)">
      <input type="checkbox" class="peer"  /> 
      <div class="collapse-title text-xl font-medium flex space-x-4 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
        <p>Card Style Customization </p>
      </div>
      <div class="collapse-content "> 
        <div *ngFor="let section of cardElement;  index as i">
          <div>
            <a class="text-base p-2 cursor-pointer" (click)="cardViewSectionPreview(section)" >
              {{section.name}}</a>
          </div>
        </div>
     
      </div>

  </div>
  <!-- Rearrange -->
  <div class="collapse collapse-arrow bg-white rounded-sm shadow-sm border border-solid border-gray-200"  (click)="customizeCardStatus(false)">
      <input type="checkbox" class="peer" /> 
      <div class="collapse-title text-xl font-medium flex space-x-4 items-center" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="group-hover:stroke-black" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
        </svg>
        
        <p >Rearrange Cards</p>
      </div>
      <div class="collapse-content" > 
        <div class="flex flex-col space-y-2 w-full">

           <app-rearrange-cards (positionChange)="onPositionDataChange($event)" (closeModal)="closeRearrangeModal()"  ></app-rearrange-cards>
          
      
         </div>
      </div>
  </div>
  <!-- Image Style -->
  <div class="collapse collapse-arrow bg-white rounded-sm shadow-sm border border-solid border-gray-200"  (click)="customizeCardStatus(false)">
      <input type="checkbox" class="peer" /> 
      <div class="collapse-title text-xl font-medium flex space-x-4 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
        <p>Image Style</p>
      </div>
      <div class="collapse-content "> 
       <div class="flex flex-col space-y-4  py-4 ">
      <div class="flex flex-col space-y-16">
        <div class="flex space-x-6">
          <input type="radio" name="radio-1" class="radio" [checked]="cardDesign.imageStyle== 'full'" name="imageStyle" value="full" (change)="onImageStyle('full')" />
          <div class="md:w-72 h-32 w-52 rounded-md ">
            <div class="relative " >
              <img src="https://images.unsplash.com/photo-1467636094440-e181ddb0d2bf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Norway" class=" object-cover w-52 md:w-72 h-44 rounded-md" style="width:100%;">
              <div class="absolute inset-0 bg-gray-700 opacity-50 rounded-md"></div>
              <div class="absolute inset-0 flex items-center justify-center">
                <h2 class="text-white text-2xl font-bold">Card Title</h2>
            </div>
            </div>
          </div>
        </div>
        <div class=" flex flex-col" >
         <!-- Color Picker -->
         <div>
           <label for="color-picker" class=" pr-4">Choose Overlay Color:</label>
           <input 
             type="color" 
             id="color-picker" 
             [(ngModel)]="overlayColorPicker"
             (input)="onColorChange($event)" 
           />
         </div>
          
          <!-- Opacity Slider -->
         <div class="flex flex-col space-y-2">
          <label for="opacity-slider" class="">Adjust Overlay Opacity:</label>
          <input 
            class="w-full"
            type="range" 
            id="opacity-slider" 
            min="0" 
            max="1" 
            step="0.01" 
            [(ngModel)]="cardDesign.overlayOpacity"
            (input)="onOpacityChange($event)"
          />
         </div>
        </div>
      </div>
            
        <div class="flex space-x-4">
          <input type="radio" name="radio-1" class="radio" [checked]="cardDesign.imageStyle== 'partial'" name="imageStyle" value="partial" (change)="onImageStyle('partial')" />

          
            <div class="flex flex-col justify-center cursor-pointer w-52 md:w-72 h-44  rounded-md bg-customTeal" >
              <div class="w-full space-y-2">
                  <div class="flex justify-center">
                    <div class="avatar mx-auto"  >
                      <div class="w-24 rounded-full">
                        <img src="https://images.unsplash.com/photo-1467636094440-e181ddb0d2bf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Norway" class=" w-full object-cover rounded-md" style="width:100%;">
                      </div>
                    </div>
                  </div>
                
                <div>
                  <h2 class="text-white text-xl text-center">Card Title</h2>
                </div>
              </div>
            </div> 

        
          </div> 
         
        </div>
        <div class=" w-full flex justify-end">
          <button 
          type="button"
          class="ml-1 float-right inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        
              style="margin-bottom: 5px;" (click)="updateChanges();">
        Save Changes </button>
              
      </div>
      </div>
  </div>
  <!-- Shape -->
  <div class="collapse collapse-arrow bg-white rounded-sm shadow-sm border border-solid border-gray-200"  (click)="customizeCardStatus(false)">
      <input type="checkbox" class="peer"/> 
      <div class="collapse-title text-xl font-medium flex space-x-4 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M315.4 15.5C309.7 5.9 299.2 0 288 0s-21.7 5.9-27.4 15.5l-96 160c-5.9 9.9-6.1 22.2-.4 32.2s16.3 16.2 27.8 16.2H384c11.5 0 22.2-6.2 27.8-16.2s5.5-22.3-.4-32.2l-96-160zM288 312V456c0 22.1 17.9 40 40 40H472c22.1 0 40-17.9 40-40V312c0-22.1-17.9-40-40-40H328c-22.1 0-40 17.9-40 40zM128 512a128 128 0 1 0 0-256 128 128 0 1 0 0 256z"/></svg>
        
        <p >Shape</p>
      </div>
      <div class="collapse-content"> 
        <div class="flex flex-col space-y-2">
          <h2 class="text-gray-500 font-bold">Card Shape</h2>
          <hr>
          <div class="flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-4 py-6">
            <div class="flex space-x-4">
              <input type="radio" name="radio-2" class="radio" [checked]="cardDesign.cardShape== 'square'" name="cardShape" value="square" (change)="onCardShape('square')"  />
              <div class="flex flex-col justify-center space-y-2">
              <p>Square</p>
              <div class="w-16 h-16 bg-customTeal"></div>
              </div>
             </div>
             <div class="flex space-x-4">
              <input type="radio" name="radio-2" class="radio"  [checked]="cardDesign.cardShape== 'rectangle'" name="cardShape" value="rectangle" (change)="onCardShape('rectangle')"  />
              <div class="flex flex-col justify-center space-y-2">
                <p>Rectangle</p>
                <div class="w-28 h-16 bg-customTeal"></div>
                </div>
             </div>
             <div class="flex space-x-4">
              <input type="radio" name="radio-2" class="radio" [checked]="cardDesign.cardShape== 'circle'" name="cardShape" value="circle" (change)="onCardShape('circle')"  />
              <div class="flex flex-col justify-center space-y-2">
                <p>Circle</p>
                <div class="h-16 w-16 rounded-full  bg-customTeal"></div>
              </div>
             </div>
             <div class="flex space-x-4">
              <input type="radio" name="radio-2" class="radio" [checked]="cardDesign.cardShape== 'none'" name="cardShape" value="none" (change)="onCardShape('none')" />
              <p class="">No shape</p>
             </div>
          </div>
          <hr>
          <!-- have a checkbox to apply the width and height  -->
          <!-- <label class="label cursor-pointer">
            <input type="checkbox" class="radio checked:bg-customTeal" formControlName="changeWidthandHeight" [value]=changeWidthandHeight
               />
            <span><strong class="label-text pl-2 font-bold ">Apply width and height </strong></span>
          </label> -->

          <div class="form-control">
            <div class="flex items-center space-x-2 cursor-pointer">
              <input type="checkbox" [checked]="changeboxWidthHeight" class="changeWidthandHeight" (change)="changeWidthandHeight()"/>
              <span class="label-text">Apply box width and height </span>
            </div>
          </div>
     
      <div class="flex w-full flex-wrap space-y-4 items-center" *ngIf="changeboxWidthHeight">
        <div class="flex flex-col w-full">
          <p>Box Width</p>
        <input 
        type="number" 
        placeholder="212px"
        class="w-full md:w-96 rounded-r border border-solid border-gray-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-gray-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-gray-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-gray-600 dark:text-gray-200 dark:placeholder:text-gray-200 dark:focus:border-customTeal" 
        [(ngModel)]="cardDesign.width"
        (input)="validateValue()"
        [min]="minValue" 
        [max]="maxValue" 
        (input)="onWidth(cardDesign.width)">
        <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>
        </div>

        <div class="flex flex-col w-full" >
          <p>Box Height</p>
          <input 
          type="number" 
          placeholder="212px"
          class="w-full md:w-96 rounded-r border border-solid border-gray-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-gray-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-gray-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-gray-600 dark:text-gray-200 dark:placeholder:text-gray-200 dark:focus:border-customTeal" 
          (input)="validateValue()"
          [(ngModel)]="cardDesign.height" 
          (input)="onHeight(cardDesign.height)" 
          [min]="minValue" 
          [max]="maxValue" 
          [ngClass]="{'bg-gray-200': cardDesign.cardShape == 'square' || cardDesign.cardShape=='circle', 'bg-transparent': cardDesign.cardShape == 'rectangle' }"
          [disabled]="(cardDesign.cardShape == 'square' || cardDesign.cardShape=='circle')"
          >
          <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>
        </div>
        
      </div>
         </div>
         <div class="flex flex-col space-y-2 py-6">
          <h2 class="text-gray-500 font-bold">Image Shape</h2>
          <hr>
          <div class="flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-4 py-6">
            <div class="flex space-x-4">
              <input type="radio" name="radio-5" class="radio" [checked]="cardDesign.imageShape == 'square'" name="imageShape" value="square" (change)="onImageShape('square')" />
              <div class="flex flex-col justify-center space-y-2">
              <p>Square</p>
              <div class="w-16 h-16 bg-customTeal"></div>
              </div>
             </div>
             <div class="flex space-x-4">
              <input type="radio" name="radio-5" class="radio" [checked]="cardDesign.imageShape == 'rectangle'" name="imageShape" value="rectangle" (change)="onImageShape('rectangle')"  />
              <div class="flex flex-col justify-center space-y-2">
                <p>Rectangle</p>
                <div class="w-28 h-16 bg-customTeal"></div>
                </div>
             </div>
             <div class="flex space-x-4">
              <input type="radio" name="radio-5" class="radio" [checked]="cardDesign.imageShape == 'circle'" name="imageShape" value="circle" (change)="onImageShape('circle')"  />
              <div class="flex flex-col justify-center space-y-2">
                <p>Circle</p>
                <div class="h-16 w-16 rounded-full  bg-customTeal"></div>
              </div>
             </div>
     
          </div> 
          <div class="form-control">
            <div class="flex items-center space-x-2 cursor-pointer">
              <input type="checkbox" [checked]="changeimgWidthHeight" class="changeImageWidthandHeight" (change)="changeImageWidthandHeight()"/>
              <span class="label-text">Apply image width and height </span>
            </div>
          </div>
            <!-- image width and height -->
            <div *ngIf="changeimgWidthHeight" class="flex w-full flex-wrap space-y-4 items-center">
              <div class="flex flex-col w-full">
                <p>Image Width</p>
              <input 
              type="number" 
              placeholder="150px"
              class="w-full md:w-96 rounded-r border border-solid border-gray-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-gray-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-gray-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-gray-600 dark:text-gray-200 dark:placeholder:text-gray-200 dark:focus:border-customTeal" 
              [(ngModel)]="cardDesign.imageWidth" 
              [min]="minValue" 
              [max]="maxValue" 
              (input)="onImageWidth(cardDesign.imageWidth)"
              (input)="validateValue()">
              </div>
  
              <div class="flex flex-col w-full" >
                <p>Height</p>
                <input 
                type="number" 
                placeholder="150px"
                class="w-full md:w-96 rounded-r border border-solid border-gray-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-gray-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-gray-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-gray-600 dark:text-gray-200 dark:placeholder:text-gray-200 dark:focus:border-customTeal" 
                [(ngModel)]="cardDesign.imageHeight" (input)="onImageHeight(cardDesign.imageHeight)" 
                [min]="minValue" 
                [max]="maxValue" 
                (input)="validateValue()"
                [ngClass]="{'bg-gray-200': cardDesign.imageShape == 'square' || cardDesign.imageShape=='circle', 'bg-transparent': cardDesign.imageShape == 'rectangle' }"
                [disabled]="(cardDesign.imageShape == 'square' || cardDesign.imageShape=='circle')"
                >
              </div>
            </div>

          </div>
          <div class=" w-full flex justify-end">
            <button 
            type="button"
            class="ml-1 float-right inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          
                style="margin-bottom: 5px;" (click)="updateChanges();" (click)="updateAllSections()">
          Save Changes </button>
                
        </div>
      
         </div>
  </div>
  <!-- Font -->
  <div class="collapse collapse-arrow bg-white rounded-sm shadow-sm border border-solid border-gray-200"  (click)="customizeCardStatus(false)">
      <input type="checkbox" class="peer"/> 
      <div class="collapse-title text-xl font-medium flex space-x-4 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-1.8l18-48H303.8l18 48H160c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H390.2L254 52.8zM279.8 304H168.2L224 155.1 279.8 304z"/></svg>
       <p>Text</p> 
      </div>
      <div class="collapse-content"> 
       <div class="flex flex-col space-y-2">
        <h2 class="text-gray-500 font-bold">Font size</h2>
        <hr>
        <div class="flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-4 py-6">
          <div class="flex space-x-4" >
            <input type="radio" class="radio" [checked]="cardDesign.fontSize== '12px'" name="fontSize" value="small" (change)="onFontSizeChange('small')"  />
            <p class="text-base font-bold">Small</p>
           </div>
           <div class="flex space-x-4" >
            <input type="radio" class="radio" [checked]="cardDesign.fontSize == '14px'" name="fontSize"  value="medium" (change)="onFontSizeChange('medium')" />
            <p class="font-bold">Medium</p>
           </div>
           <div class="flex space-x-4" >
            <input type="radio" class="radio" [checked]="cardDesign.fontSize == '16px'" name="fontSize" value="large" (change)="onFontSizeChange('large')" />
            <p class="text-xl font-bold">Large</p>
           </div>
           <div class="flex space-x-4" >
            <input type="radio" class="radio" [checked]="cardDesign.fontSize == '20px'" name="fontSize" value="extraLarge" (change)="onFontSizeChange('extraLarge')" />
            <p class="text-2xl font-bold">Extra Large</p>
           </div>
        </div>
       </div>
       <div class="flex flex-col space-y-2">
        <h2 class="text-gray-500 font-bold">Font weight</h2>
        <hr>
        <div class="flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-4 py-6">
          <div class="flex space-x-4" >
            <input type="radio" class="radio" [checked]="cardDesign.fontWeight== '300'" name="fontWeight" value="light" (change)="onFontWeightChange('300')"  />
            <p class="font-[300]">Light</p>
           </div>
           <div class="flex space-x-4" >
            <input type="radio" class="radio" [checked]="cardDesign.fontWeight == '600'" name="fontWeight"  value="semiBold" (change)="onFontWeightChange('600')" />
            <p class="font-[600]">Semi-Bold</p>
           </div>
           <div class="flex space-x-4" >
            <input type="radio" class="radio" [checked]="cardDesign.fontWeight == '800'" name="fontWeight" value="bold" (change)="onFontWeightChange('800')" />
            <p class="font-[800] ">Bold</p>
           </div>
     
        </div>
       </div>
       <div class="flex flex-col space-y-2">
        <h2 class="text-gray-500 font-bold">Text Position</h2>
        <hr>
        <div class="flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-4 py-6">
         
           <div class="flex space-x-4">
            <input type="radio" name="radio-4" class="radio"  [checked]="cardDesign.fontPosition== 'top'" name="textPosition" value="top" (change)="onTextPositionChange('top')"  />
            <p class="">Text on Top</p>
           </div>
           <div class="flex space-x-4" *ngIf="cardDesign.imageStyle=='full'">
            <input type="radio" name="radio-4" class="radio" [checked]="cardDesign.fontPosition== 'center'" name="textPosition" value="center" (change)="onTextPositionChange('center')" />
            <p class="">Text on Center</p>
           </div>
           <div class="flex space-x-4">
            <input type="radio" name="radio-4" class="radio" [checked]="cardDesign.fontPosition== 'bottom'" name="textPosition" value="bottom" (change)="onTextPositionChange('bottom')" />
            <p class="">Text on Bottom</p>
           </div>

        </div>
       </div>
          <div class=" w-full flex justify-end">
            <button 
            type="button"
            class="ml-1 float-right inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          
                style="margin-bottom: 5px;" (click)="updateChanges();">
          Save Changes </button>
                
        </div>
      </div>
  </div>
  <!-- Color -->
  <div class="collapse collapse-arrow bg-white rounded-sm shadow-sm border border-solid border-gray-200"  (click)="customizeCardStatus(false)">
      <input type="checkbox" class="peer"/> 
      <div class="collapse-title text-xl font-medium flex space-x-4 items-center">
        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M512 256c0 .9 0 1.8 0 2.7c-.4 36.5-33.6 61.3-70.1 61.3L344 320c-26.5 0-48 21.5-48 48c0 3.4 .4 6.7 1 9.9c2.1 10.2 6.5 20 10.8 29.9c6.1 13.8 12.1 27.5 12.1 42c0 31.8-21.6 60.7-53.4 62c-3.5 .1-7 .2-10.6 .2C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>
       <p>Color</p> 
      </div>
      <div class="collapse-content"> 
       <div class="flex flex-col space-y-2">
        <h2 class="text-gray-500 font-bold">Background Color</h2>
        <hr>
        <div class="flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-4 py-6">
          <div>
          <div class="flex space-x-3">
       
            <input type="color" name="favcolor" class="bg-customTeal" (input)="updateBackgroundColor($event)" >
            <div class="relative flex flex-nowrap w-80 items-stretch">
              <span
                class="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-gray-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-gray-700 dark:border-gray-600 dark:text-gray-200 dark:placeholder:text-gray-200"
                id="addon-wrapping"
                >#</span
              >
              <input
              placeholder="Enter hex color code" 
                type="text"
                class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-gray-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-gray-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customTeal focus:text-gray-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-gray-600 dark:text-gray-200 dark:placeholder:text-gray-200 dark:focus:border-customTeal"
                placeholder="hex color(optional)"
                aria-label="hexColor"
                aria-describedby="addon-wrapping" />
            </div>
          </div>
          </div>
        </div>
       </div>

       <div class="flex flex-col space-y-2">
        <h2 class="text-gray-500 font-bold">Text Color</h2>
        <hr>
        <div class="flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-4 py-6">
          <input type="color" name="favcolor" class="bg-customTeal" (input)="updateTextColor($event)"  >
          <div class="relative flex flex-nowrap w-80 items-stretch">
            <span
              class="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-gray-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-gray-700 dark:border-gray-600 dark:text-gray-200 dark:placeholder:text-gray-200"
              id="addon-wrapping"
              >#</span
            >
            <input
            placeholder="Enter hex color code" 
              type="text"
              class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-gray-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-gray-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customTeal focus:text-gray-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-gray-600 dark:text-gray-200 dark:placeholder:text-gray-200 dark:focus:border-customTeal"
              placeholder="hex color(optional)"
              aria-label="hexColor"
              aria-describedby="addon-wrapping" />
          </div>
      </div>
       </div>
          <div class=" w-full flex justify-end">
            <button 
            type="button"
            class="ml-1 float-right inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          
                style="margin-bottom: 5px;" (click)="updateChanges();">
          Save Changes </button>
                
        </div>
      </div>
  </div>
  </div>

    <!-- Preview -->
    <div class="bg-white p-4 shadow-md space-y-4 w-full ">

      <div *ngIf="customizeCard">
        <div class="flex  px-4 justify-center  flex-wrap items-center  gap-y-4 my-4 w-full">
                 <div class="w-full">
                   <!-- card item -->
                   <div class="mr-4 w-full" >
                   <div  class="space-y-2 w-full"  *ngIf=" sectionToPreview">
                     <app-customize-card class="w-full"  [cardViewSection]="sectionToPreview"></app-customize-card>
                   </div>           
                     <div *ngIf="!sectionToPreview">
                       <p>Select a card item to view</p>
                     </div>
                   </div>
                 </div>
               </div>          
      </div>
      <div *ngIf="!customizeCard">
        <app-card-view-elements [cardElement]="cardElement" [cardDesign]="cardDesign" [preview]="true"></app-card-view-elements>

      </div>

    </div>


</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" 
[toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>


