import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-homepage-section-number',
  templateUrl: './homepage-section-number.component.html',
  styleUrls: ['./homepage-section-number.component.css']
})
export class HomepageSectionNumberComponent implements OnInit {

  @Input() section;
  @Output() confirmationModal = new EventEmitter<string>();
  @Input() length;

  
  saveButton =  false;
  pageSections: any;
  name: any;
  value: any;

    

  constructor( private settingsService: AppSettingsService,) { }

  ngOnInit(): void {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          this.pageSections.appSections.forEach(element => {
            if(element.originalName == this.section){
              this.value = element.itemOnHomepage ? element.itemOnHomepage : 3
            }      
          });
        }
      }
    });
  }

  selectNumber(number){
    this.saveButton =true
    this.value = number
  }
  
  saveItemNumber(){
    this.pageSections.appSections.forEach(element => {
      if(element.originalName == this.section){
        element.itemOnHomepage = this.value;
      }      
    });
    
    this.settingsService.updateAppSettings(this.pageSections).then(x => {
      this.confirmationModal.emit()
    });

  }


  }
