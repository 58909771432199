import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InviteService } from './../../../services/firebase/invite.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { RequesttojoinService } from 'src/app/services/firebase/requesttojoin.service';
import { HelperService } from 'src/app/services/helper/helper';
import { UserService } from 'src/app/services/firebase/user.service';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { finalize, from, switchMap, take, tap } from 'rxjs';

// import { toast } from 'materialize-css';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

@Component({
  selector: 'app-register-from-invite',
  templateUrl: './register-from-invite.component.html',
  styleUrls: ['./register-from-invite.component.css']
})
export class RegisterFromInviteComponent implements OnInit {

  isPrivacy: boolean;
  id: any;
  invitedUserEmail: any;

  registrationForm: UntypedFormGroup;
  inviteCodeForm: UntypedFormGroup;
  canadianNumber = ['^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$'];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  hasCode = false;
  invitation: any;
  invitedUserRole: any;
  invitedcellPhone: String = '';

  appName: any;
  model: any;
  themeColor: any;
  appDescription = '';
  appLogo: any;
  noAdmin = true;
  admins = [];
  defaultThemeColor = environment.appTheme.themeColor;

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  requestId: any;
  requestCopy = [];
  isVerifyUsers: boolean;

  //Added for employee
  jobTitle: any;
  extension: any;
  firstNameInvite: any;
  lastNameInvite: any;
  inviteId: any;
  appointment: any;
  biography: any;
  workPhone: any;
  contact: any;
  profileImgLink: any;
  showEmployee: any;

  //Added from Subscribers
  DOB: any;

  greetings: any;
  signOff: any;
  messageSMS: any;
  nationOfUse = environment.firstNation.name;

  // for the inputs to be editable
  isEditable = false;
  // visible
  showPassword = false;
  notifSettings

  signupSettings;
  sideImage;
  backDropColor;
  user;
  permission: any;
  inputvalue = '';
  inputLastvalue = '';
  empSchedule: [];
  verificationMethod: any;
  submitModalOpen: boolean = false;
  showAstrikes: boolean = true;

  verifyGlobal : any;
  getCodeGlobal: any;
  wrongCode: boolean = false;
  afterSubmitModalOpen: boolean = false;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false
  postObj : any;
  data : any;
  recieveAppointmentSMS: boolean;
  recieveAppointmentEmail: boolean;

  constructor(private inviteService: InviteService,
    private route: ActivatedRoute,
    public router: Router,
    private fb: UntypedFormBuilder, private authService: AuthService,
    private appSettingsService: AppSettingsService,
    private settingsService: AppSettingsService,
    private requesttojoin: RequesttojoinService,
    private helperervice: HelperService,
    private userService: UserService,
    private smsService : SMSService,
    private notificationPreferenceService: NotificationPreferenceService
  ) {

    // get all admins
    this.userService.getAllAdmins().valueChanges().subscribe(admins => {
      if (admins) {
        if (admins.length > 0) {
          this.noAdmin = false;
          this.admins = admins;
        }
      }
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.appName = this.model.appName;
            this.appDescription = this.model.description;
            this.appLogo = this.model.logo;
            this.isVerifyUsers = this.model.verifyUsers;
            this.verificationMethod = this.model.verifySubscribers;
            this.showAstrikes = this.showAstrikes || (this.verificationMethod ==='SMS');
          }
        }
      }
    );
    this.settingsService.getSignupSettingsList().valueChanges().subscribe(s => {
      if (s) {
        if (s.length > 0) {
          this.signupSettings = s[0]
          this.sideImage = this.signupSettings.logo && this.signupSettings.logo != '' ? this.signupSettings.logo : ""
          this.backDropColor = this.signupSettings.backDropColor
        }
      }
    })
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((appSettings: any) => {
      if (appSettings.length > 0) {
        this.appName = appSettings[0].appName;
        this.verificationMethod  = appSettings[0].verifySubscribers;
      }

    });

    this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.notifSettings = x[0]
      }
    })

    this.registrationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      middleName: [''],
      cellPhone: [''],
      gender: [''],
      email: ['', [Validators.required, Validators.email]],
      // address: [''],
      // city: [''],
      // officeNumber: [''],
      // takeAppointment: [''],
      //isBusinessAccount: [false],
      createPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
      privacyPolicy: [false, [Validators.required]],
    });

    if (this.id) {
      this.inviteService.getInviteById(this.id).valueChanges().subscribe((invite: any) => {
        if (invite.length > 0) {
          this.hasCode = true;
          this.invitedUserEmail = invite[0].inviteEmail;
          this.invitedUserRole = invite[0].userType;
          this.greetings = invite[0].greetings;
          this.invitedcellPhone = invite[0].cellPhone;
          this.jobTitle = invite[0].jobTitle;
          this.extension = invite[0].extension;
          this.workPhone = invite[0].workPhone;
          this.biography = invite[0].biography;
          this.contact = invite[0].contact;
          this.appointment = invite[0].appointment;
          this.firstNameInvite = invite[0].firstName;
          this.lastNameInvite = invite[0].lastName;
          this.inviteId = invite[0].id;
          this.showEmployee = invite[0].showEmployee;
          this.profileImgLink = invite[0].profileImgLink;
          this.DOB = invite[0].DOB;
          this.signOff = invite[0].signOff;
          this.messageSMS = invite[0].messageSMS;
          this.permission = invite[0].permission;
          this.empSchedule = invite[0].schedule ? invite[0].schedule : []
          this.recieveAppointmentSMS = invite[0].recieveAppointmentSMS ? invite[0]?.recieveAppointmentSMS : true;
          this.recieveAppointmentEmail = invite[0].recieveAppointmentEmail ? invite[0]?.recieveAppointmentEmail : true;
          if (this.invitedUserRole === 'Employee') {
            //this.permission = invite[0].permisssion;
            this.isEditable = true;
          } else {
            this.isEditable = false;
          }
          this.invitation = invite[0];
          this.registrationForm.patchValue({
            firstName: this.firstNameInvite ? this.firstNameInvite : '',
            lastName: this.lastNameInvite ? this.lastNameInvite : '',
            email: this.invitedUserEmail ? this.invitedUserEmail : '',
            cellPhone: this.invitedcellPhone ? this.invitedcellPhone : '',
            // email: this.user.Email ? this.user.Email : this.currentUser.Email,
          });
        }
      });


    }

  }
  get firstName() { return this.registrationForm.get('firstName'); }
  get middleName() { return this.registrationForm.get('middleName'); }
  get lastName() { return this.registrationForm.get('lastName'); }
  get email() { return this.registrationForm.get('email'); }
  get cellPhone() { return this.registrationForm.get('cellPhone'); }
  get gender() { return this.registrationForm.get('gender'); }
  get address() { return this.registrationForm.get('address'); }
  get accountSetup() { return this.registrationForm.get('accountSetup'); }
  get city() { return this.registrationForm.get('city'); }
  get officeNumber() { return this.registrationForm.get('officeNumber'); }
  get createPassword() { return this.registrationForm.get('createPassword'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }
  get privacyPolicy() { return this.registrationForm.get('privacyPolicy'); }
  get getEmpInstagramAcc() { return this.registrationForm.get('empInstagramAcc'); }
  get getEmpTwitterAcc() { return this.registrationForm.get('empTwitterAcc'); }
  get getEmpLinkedinAcc() { return this.registrationForm.get('empLinkedinAcc'); }
  get getEmpYoutubeAcc() { return this.registrationForm.get('empYoutubeAcc'); }

  register(data: any) {
    this.data = data;
    this.postObj = {
      Address: data.address,
      // cellPhone: this.invitedcellPhone ? this.invitedcellPhone :data.cellPhone,
      cellPhone: data.cellPhone,
      City: data.city,
      Email: data.email,
      GivenName: data.firstName,
      Sex: data.gender,
      verified: false,
      LastName: data.lastName,
      MiddleName: data.middleName,
      OfficeNumber: data.officeNumber,
      jobTitle: this.jobTitle,
      extension: this.extension,
      workPhone: this.workPhone,
      biography: this.biography,
      contact: this.contact,
      ShowEmployee: this.showEmployee,
      profileImgLink: this.profileImgLink,
      appointment: this.appointment,
      TakeAppointment: data.takeAppointment,
      role: this.invitedUserRole,
      //DOB : this.DOB,
      greetings: this.greetings,
      signOff: this.signOff,
      messageSMS: this.messageSMS,
      accountSetup: data.accountSetup,
      isBusinessAccount: data.isBusinessAccount ? data.isBusinessAccount : false,
      verifyUsers: data.verifyUsers ? data.verifyUsers : false,
      permission: this.permission,
      schedule: this.empSchedule,
      recieveAppointmentEmail: data.recieveAppointmentEmail ? data.recieveAppointmentEmail : true,
      recieveAppointmentSMS: data.recieveAppointmentSMS ? data.recieveAppointmentSMS : true,
    };
    if (this.invitedUserEmail !== data.email && this.isVerifyUsers) {
      this.toast({ html: 'Please use the email that you have been invited.', classes: 'red', type: 'failure' });
    } else {

      if(this.verificationMethod === 'SMS'){
        this.userService.getUserByEmail(data.email).valueChanges().subscribe((user) =>{
          if(user && user.length != 0){
            this.toast({html: "The user already exists", classes:'red', type: 'failure'});
          }else{
            this.openSubmitButton();
          }
        })
       
      }else{
      this.authService.emailSignUp(this.postObj, data.createPassword).then(() => {
        // UPDATE INVITATION
        let fullName = data.firstName + ' ' + data.lastName

        this.sendPushNotificationToOwner(data, fullName)

        if (this.invitation && this.id) {
          this.inviteService.updateInvite(this.invitation);
          this.requesttojoin.getRegistrationRequestByEmail(this.email.value).valueChanges().subscribe((request) => {
            if (request.length > 0) {
              this.requestCopy = request;
              this.requestId = this.requestCopy[0].id;
              // this.requesttojoin.deleteRequestByID(this.requestCopy[0].id);
              this.inviteService.updateInvite(this.invitation);
            }
          });
        }
      }).catch(error => {
        this.toast({ html: error.message, classes: 'red', type: 'failure' });
      });
    }


    }
  }
  togglePrivacy() {
    this.isPrivacy = !this.isPrivacy;
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }


  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

  async sendPushNotificationToOwner(data, fullName) {
    if (this.notifSettings) {
      let conditions = ["signUp"]
      if (this.helperervice.checkNotificationForOwner(this.notifSettings, conditions)) {

        let notification = new NotificationModel()
        notification.title='New User Registration'
        notification.body=`${fullName} has registered to ${environment.firstNation.website}.`
        // this.pushNotificationsService.sendNotificationByRole(notification,'Admin')
        await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['Admin'], 'signUp')

       
      }
    }
  }

  changeToSentenceCase(event) {
    this.inputvalue = event.replace(/\b\w/g,
      event => event.toLocaleUpperCase());

    // (ngModelChange)="inputLastvalue = $event[0].toUpperCase()" 
  }
  changeToSentenceCaseLast(event) {
    this.inputLastvalue = event.replace(/\b\w/g,
      event => event.toLocaleUpperCase());
  }

  closeModal(){
    this.submitModalOpen = false;
  }
  resendCode(code){
    this.openSubmitButton();
  }

  // async openSubmitButton() {
  //   console.log("It is here inside the openSubmitButton");
  //   this.submitModalOpen = true;
  
  //   try {
  //     // Get the verification code list
  //     const codes = await this.smsService.getVerificationCode(this.cellPhone.value).toPromise();
  
  //     if (codes && codes.length > 0) {
  //       // If verification codes exist, delete the first one
  //       await this.smsService.deleteVerificationCode(codes[0]);
  //       console.log("Old verification code deleted");
  //     }
  
  //     // Generate a new verification code
  //     const verification = await this.smsService.GenerateVerification(this.cellPhone.value, this.isNationApp).toPromise();
      
  //     // Add the phone number to the verification object
  //     verification.phoneNumber = this.cellPhone.value;
  
  //     // Save the new verification code
  //     const saved = await this.smsService.saveVerificationCode(verification);
  //     console.log("Verification code saved:", saved);
  
  //   } catch (error) {
  //     // Handle any errors that might occur during the async operations
  //     console.error("An error occurred:", error);
  //   }
  // }
  

  openSubmitButton(){
    this.submitModalOpen = true;
      this.verifyGlobal = this.smsService.getVerificationCode(this.cellPhone.value).valueChanges().subscribe((x)=>{
        if(x && x.length > 0){
          this.smsService.deleteVerificationCode(x[0]).then((deleted) => {
          this.smsService.GenerateVerification(this.cellPhone.value, this.isNationApp).subscribe((x) => {
              x.phoneNumber = this.cellPhone.value;
              this.smsService.saveVerificationCode(x).then((saved) => {
              })
            })
          })
        }else{
          this.smsService.GenerateVerification(this.cellPhone.value, this.isNationApp).subscribe((x) => {
            x.phoneNumber = this.cellPhone.value;
            this.smsService.saveVerificationCode(x).then((saved) => {
            })
          })
        }
        this.verifyGlobal.unsubscribe();
      })
  }

  verifyClicked(event) {
    let stringWithHyphens = "";
    // Split the string based on the hyphen
    const numbersArray = event.split('-');

    // Convert the array of strings to an array of numbers
    const numbers = numbersArray.map(Number);

    // Join the numbers array into a string with hyphens
    stringWithHyphens = numbers.join('');
    this.getCodeGlobal = this.smsService.getVerificationCode(this.cellPhone.value).valueChanges().subscribe((code : any) => {
        
        if (code && code.length > 0) {
            if(code[0].code === stringWithHyphens ){

              // this.sendRequest(registrationForm);
              this.authService.emailSignUp(this.postObj, this.data.createPassword).then(() => {
                // UPDATE INVITATION
                let fullName = this.data.firstName + ' ' + this.data.lastName
        
                this.sendPushNotificationToOwner(this.data, fullName)
        
                if (this.invitation && this.id) {
                  this.inviteService.updateInvite(this.invitation);
                  this.requesttojoin.getRegistrationRequestByEmail(this.email.value).valueChanges().subscribe((request) => {
                    if (request.length > 0) {
                      this.requestCopy = request;
                      this.requestId = this.requestCopy[0].id;
                      // this.requesttojoin.deleteRequestByID(this.requestCopy[0].id);
                      this.inviteService.updateInvite(this.invitation);
                    }
                  });
                }
              }).catch(error => {
                this.toast({ html: error.message, classes: 'red', type: 'failure' });
              });

              this.submitModalOpen = false;

              this.afterSubmitModalOpen = true;

              if(this.afterSubmitModalOpen){
                this.smsService.deleteVerificationCode(code[0]).then((deleted) =>{
                })
              }else{
              }
            }else{
              this.wrongCode = true;
              this.toast({ html: "You entered Wrong Verification code, Try Again", classes: 'red', type: 'failure' });
            }
        }else{
        }
        this.getCodeGlobal.unsubscribe();
    })
    
  }


  // registerWithCode(data: any){
  //   this.inviteService.getInviteById(data.inviteCode).valueChanges().subscribe((invite: any) => {
  //     if (invite.length > 0 ){
  //       this.id = data.inviteCode;
  //       this.hasCode = true;
  //       this.invitedUserEmail = invite[0].inviteEmail;
  //       this.invitedUserRole = invite[0].userType;
  //       this.invitation = invite[0];
  //     } else {
  //       // toast({html: 'Wrong Invite Code', classes: 'red'});
  //     }
  //   });
  // }


}
