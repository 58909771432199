import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-box',
  templateUrl: './modal-box.component.html',
  styleUrl: './modal-box.component.css'
})
export class ModalBoxComponent {
  @Input() isOpen = false;
  @Input() title 
  @Input() size: 'small' | 'medium' | 'large' | 'extraLarge' | 'xxl' = 'medium'; 
  @Output() close = new EventEmitter<void>();

  closeModal() {
    this.close.emit();
  }

  getModalSizeClass() {
    switch (this.size) {
      case 'small':
        return 'max-w-sm';
      case 'large':
        return 'max-w-4xl';
      case 'extraLarge':
        return 'max-w-5xl';
      case 'xxl':
          return 'max-w-7xl';
      default:
        return 'max-w-lg'; // Medium is default
    }
  }
}
