<ng-container *ngIf="settings && settings.isBusinessApp">
  <div>
    <div *ngIf="viewService">
      <div class="flex flex-wrap md:px-8 gap-4 w-full justify-center md:justify-start  items-center" *ngIf="isAdmin || (permPES && permPES.createServices)" >
        <button (click)="navigateCreate()" 
        class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
     
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white " height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
          <span>Add New</span>
          </button>
      
      
          <div>
            <app-rename-section section="Services" ></app-rename-section>
          </div>
          <app-homepage-section-number  section="Services" (confirmationModal) = "openConfirmationModal()"> </app-homepage-section-number>

            <button 
            type="button"
            *ngIf="services && services.length > 0"
            class="flex items-center md:ml-5 text-customTeal space-x-2 rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            (click)="openMoveModal=true">
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24" class="w-4 h-4">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
            <span>Arrange Items</span>  
            
          </button>
     

    
        </div>

      <div *ngIf="services">
        <div class="flex flex-wrap mx-auto md:mx-10">
          <div *ngFor="let service of getSortedList(services)" class="md:w-1/4 w-full m-4 border h-min border-gray-200 drop-shadow-lg rounded-lg hover:scale-110 hover:ease-in hover:duration-300 bg-white" [ngStyle]="{'background-color': service.backgroundColor}">
            <div class=" flex flex-col w-full items-center space-y-4 px-4  py-4  md:px-6 " (click)="navigateDetails(service)">

              <ng-container>
                <div *ngIf="service.imgLink && !service.videoLink"   class="rounded-xl cursor-pointer">
                  <div class="top-0 left-0 flex w-full justify-end right-3 bottom-4 mb-4  translate-y-2 -translate-x-4">
                    <app-share-status  [status] = 'service.shareWithPublic ? "Public" : "User"' design = 'Primary' ></app-share-status>
                  </div>
                  <div class="flex justify-center h-40 md:h-48">
                    <img [src]="service.imgLink" class="md:w-56 h-full w-full rounded-lg" style="object-fit: contain;">
                  </div>
                </div>
                <div>
                  <div *ngIf="service.videoLink && service.imgLink" (click)="navigateDetails(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
                    <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo" [poster]="service.imgLink"></video>           
                  </div>
                  <div *ngIf="service.videoLink && !service.imgLink" (click)="navigateDetails(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
                    <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo"></video>           
                  </div>
                </div>
                
                <div class="break-words w-full">
                  <h6  class=" cursor-pointer max-w-sm font-bold py-2" [ngStyle]="{'color':service.titleColor}">{{truncateHTML(getInnerText(service.serviceName),60)}}</h6>
                </div>
                <div *ngIf=" !service.imgLink">
                  <app-share-status  [status] = 'service.shareWithPublic ? "Public" : "User"' design = 'Secondary' ></app-share-status>
                </div>

              </ng-container>
              <div *ngIf="service.allServices" class="flex flex-col w-full ">
                <ng-container *ngFor="let ser of service.allServices" class="w-full">
                  <div class="flex flex-col w-full mb-5">
                    <div tabindex="0" class="w-full collapse collapse-plus rounded-sm border border-base-300 shadow-sm bg-gray-100 ">
                      <input type="checkbox" class="peer" />
                      <div  class="collapse-title cursor-pointer font-bold text-customTeal" >
                        {{truncateHTML(ser.serviceTitle, 60)}}
                      </div>
                      <div *ngIf="currentUser" class="collapse-content">
                        <p class="font-bold text-customTeal">$ {{ser.servicePrice}}</p>
                        <p class="italic">{{truncateHTML(ser.serviceUnderDescription,60)}}</p>
                        <button *ngIf="ser.hasAppointment==true" class="btn-custom-solid float-right" (click)="BookServiceAppointment(service,ser)">Book</button>
              
                     </div>
                     <div *ngIf="!currentUser" class="collapse-content">
                      <p class="font-bold text-customTeal">$ {{ser.servicePrice}}</p>
                      <p class="italic">{{ser.serviceUnderDescription}}</p>
                      <button *ngIf="ser.hasAppointment==true" class="btn-custom-solid float-right" (click)="OpenLoginPopup()">Book</button>
            
                   </div>
                    </div>
                  </div>
                </ng-container>
              </div>  
              <div *ngIf="!service.allServices || service.allServices.length==0">
                <!-- <div *ngIf="service.serviceDescription">
                  {{truncateHTML(getInnerText(service.serviceDescription), 90)}}
                </div> -->
                
              </div> 
              <div *ngIf="service.price">
                <div *ngIf=" service.price!='dontInclude'">
                  <div *ngIf="service.price > 0" href="" class="px-3 lg:px-6 py-2  justify-start text-white  bg-customTeal rounded-md baseline font-bold bottom-0   md:ml-0">
                    <ng-container *ngIf="currency"> {{service.price | currency: currency:'symbol-narrow'}}</ng-container>
                    <ng-container *ngIf="!currency">{{service.price | currency: 'USD':'symbol-narrow'}}</ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
        <div class="w-full text-center ">
          <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        </div>
      </div>
      <div *ngIf="!services" class="w-4/5 mx-auto space-y-4">
        <app-empty-state *ngIf="isAdmin && !(calledFrom == 'onBoarding')" calledFrom="service"></app-empty-state>
        <p class="font-bold pt-4">
          Post Sample
        </p>
        <div class="w-full md:w-[341px] border h-min border-gray-200 drop-shadow-lg rounded-lg  bg-white" >
                    <div class=" flex flex-col w-full items-center space-y-4 px-4  py-4  md:px-6 " >
                      <ng-container>
                        <div class="w-full h-40 md:h-48 rounded-xl cursor-pointer" [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0)),url('+ emptyService.img +'); background-size: cover; background-repeat: no-repeat; background-position: center;'">
                          <div class="top-0 left-0 flex w-full justify-end right-3 bottom-4 mb-4  translate-y-2 -translate-x-4">
                            <app-share-status  [status] = 'emptyService.status' design = 'Primary' ></app-share-status>
                          </div>
                        </div>
                        <h4 class=" cursor-pointer max-w-sm text-2xl font-bold text-center " >{{getInnerText(emptyService.postTitle)}}</h4>
                      </ng-container>
                      <div class="flex flex-col w-full ">
                        <ng-container class="w-full">
                          <div class="flex flex-col w-full mb-5">
                            <div tabindex="0" class="w-full collapse collapse-plus rounded-sm border border-base-300 shadow-sm bg-gray-100 ">
                              <input type="checkbox" class="peer" />
                              <div  class="collapse-title cursor-pointer font-bold text-customTeal" >
                                {{emptyService.postTitle}}
                              </div>
                              <div *ngIf="currentUser" class="collapse-content">
                                <p class="font-bold text-customTeal">$ {{emptyService.price}}</p>
                                <p class="italic">{{emptyService.message}}</p>
                               
                             </div>
                     
                            </div>
                          </div>
                        </ng-container>
                      </div>  
                   
                      <div *ngIf="emptyService.price">
                          <div  href="" class="px-3 lg:px-6 py-2  justify-start text-white  bg-customTeal rounded-md baseline font-bold bottom-0   md:ml-0">
                            <ng-container > {{emptyService.price | currency: currency:'symbol-narrow'}}</ng-container>
                          </div>
                    
                      </div>
                    </div>
       
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
  
<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [aspectRatio]="4 / 3" (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>


<app-move-elements *ngIf="openMoveModal"  [elementsList]="services" sectionType="Services" (closeClicked)="openMoveModal=false"></app-move-elements>