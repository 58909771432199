import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  ChatGptChatModel,
  ImageGenerateModel,
} from "src/app/models/chatGptModel";
import { ApiKeyManagerService } from "./apiKeyManager.Service";

@Injectable({
  providedIn: "root",
})
export class ChatGptService {
  constructor(
    private httpClient: HttpClient,
    private keyManager: ApiKeyManagerService
  ) {}

  url = environment.firstNation.apiUrl + "api/OpenAI/";

  ayrshareUrl = environment.socialMedia.url;
  apiKey = environment.socialMedia.key;

  private apiUrl = 'https://api.openai.com/v1/chat/completions';


  // the response from api is 'an over-stringified string' so need to 'JSON.parse' response twice
  startChat(content: string): Observable<any> {
    let model = {
      content,
      model: "gpt-3.5-turbo",
    };
    const headers = new HttpHeaders({
      SOCIALAPIKEY: this.keyManager.getKey(),
      "Content-Type": "application/json; charset=utf-8",
    });
    return this.httpClient.post(
      this.url + "StartChat/",
      JSON.stringify(model),
      { headers }
    );
  }

  generateChat(model: ChatGptChatModel) {
    let data = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "user",
          content: model.message,
        },
      ],
      temperature: 0.7,
    };

    const headers = new HttpHeaders({
      Authorization:
        "Bearer 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",
      "Content-Type": "application/json",
    });
    return this.httpClient.post(this.url, data, { headers });
  }

  generatePost(model: ChatGptChatModel) {
    let text = model.message.replace(/(\r\n|\n|\r)/gm, "");
    let data = { text };
    // data ={
    //     "text":"what day is it today"
    // }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.apiKey}`,
      "Content-Type": "application/json",
    });

    return this.httpClient.post(this.ayrshareUrl + "generate/post", data, {
      headers,
    });
  }

  generateImage(model: ImageGenerateModel) {
    let data = {
      prompt: model.message,
    };

    const headers = new HttpHeaders({
      SOCIALAPIKEY: this.keyManager.getKey(),
      "Content-Type": "application/json; charset=utf-8",
    });
    return this.httpClient.post(
      this.url + "StartImageGeneration/",
      JSON.stringify(data),
      // data,
      { headers }
    );
  }

  generateQuestion(prompt: string): Observable<any> {
    prompt = 'I want to generate 4 questions to ask potential clients about their business for SEO purposes. The answers will help me create relevant keywords. Can you create the questions and return them in an array without any additional text?';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer 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`
    });

    const body = {
      model: 'gpt-4', // or 'gpt-3.5-turbo'
      messages: [{ role: 'system', 
        // content: 'Generate 4 different questions based on the user input and give me as an array  .'
        content: 'I want to set up the SEO for my potential clients on our website building platform and I want to use chatgpt to help me generate 4 questions to ask them about their business and after that I will pass their answer to chat and that will help me create keywords for their business can you please create the question for me and put it in an array',
      },
                 { role: 'user', content: prompt }],
      max_tokens: 100,
      n: 1,
      stop: null,
      temperature: 0.7,
    };

    return this.httpClient.post(this.apiUrl, body, { headers });
  }

  parseToJSON(data: any) {
    return JSON.parse(JSON.parse(data));
  }
}
