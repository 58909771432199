import { UserService } from './../../../services/firebase/user.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AbstractControl, Form, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ImageModel } from 'src/app/models/ImageModel';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { GetAppService } from 'src/app/services/firebase/get-app.service';
import { EmailService } from 'src/app/services/api/email.service';
import { AutomationService } from 'src/app/services/backend-automation/automation.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { AES } from 'crypto-js'
import * as CryptoJs from 'crypto-js'
import { EnvironmentService } from 'src/app/services/helper/env';
import { AddUserToFirebaseModel, UserCollectionModel } from 'src/app/models/AutomationModel';
import { SMSService } from 'src/app/services/api/sms.service';

import {
  Carousel,
  initTE,
} from "tw-elements";

 import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { SMSModel } from 'src/app/models/SMSModel';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

// declare var navigator: any;
@Component({
  selector: 'app-get-app',
  templateUrl: './get-app.component.html',
  styleUrls: ['./get-app.component.css']
})



export class GetAppComponent implements OnInit {
  secretKey: any = "zLeCHJYKf25WENP";

  appRequestForm: UntypedFormGroup;
  currentUser: any;
  model: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  showLogoInput = true;
  imgErrorMsg: any;
  isMobile = false;
  imgLink: any;
  isBusinessApp = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  step = 1;
  isList;
  existingRequests = [];
  isDemo = false;
  inputContactInfo = false;
  businessApp = false;
  shareMySocialMediaLink = false;
  haveWebsite = false;
  submitModalOpen = false;
  nationOfuse = environment.firstNation.name;
  image = environment.firstNation.Origin === "FNLeaders" ? '../../../../assets/img/start building your platform.png' : '../../../../assets/img/Purple Minimalist Healthy App Instagram Post (6).png'
  tribalNames = GlobalConstants.tribalNames
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false
  is_NationApp =  environment.firstNation.name == "FNLeaders" ? true : false
  openNationsModal = false
  nationSelected = {}
  officeToRun = ['Nation', 'Provincial', 'Federal', 'Tribal Council', 'Advocacy group']

  password: string = '';
  showPassword: boolean = false;
  showPassword1: boolean = false;
  appMainName: any;
  appManagerId: any;
  firstName: any;
  lastName: any;
  SMSchecked: boolean = true;
  // SMSchecked: boolean;
  userContactPreference: any | string[] = [];
  loggedInUser: any;
  Emailchecked: boolean = true;
  Inappchecked: boolean = true;
  inputValue: string = '';
  disableSms: boolean;
  inputText: any;
  checkboxDisabled: boolean = true;
  options: any[];
  selectedIndustry: any;
  otherServiceClicked: any = false;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/auth/verify-email/' : 'http://localhost:4200/auth/verify-email/';
  domain = environment.firstNation.name;
  settings: any;
  appLogo: any;
  applicationName: any;
  storeEmail = [];
  currentAppName = this.envService.currentAppName
  projectId = environment.firebase.projectId
  alternativeUrl: any = []

  afterSubmitModalOpen: boolean = false;
  verifyGlobal: any;
  getCodeGlobal: any;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  
  wrongCode: boolean = false;
  adminEmail = [];
  existingUser = [];
  selectedCountry = { code: '+1', flag: 'https://flagcdn.com/w40/ca.png', name: 'Canada' };

  countries = [
    { code: '+1', flag: 'https://flagcdn.com/w40/ca.png', name: 'Canada' },
    { code: '+1', flag: 'https://flagcdn.com/w40/us.png', name: 'USA' },
    // Add more countries
  ];

  selectCountry(country: any) {
    this.selectedCountry = country;
  }
  constructor(private fb: UntypedFormBuilder,
    private getAppService: GetAppService,
    private authService: AuthService,
    private location: Location,
    private mediaService: MediaService,
    private emailService: EmailService,
    private settingsService: AppSettingsService,
    private userService: UserService,
    private router: Router,
    private automationService: AutomationService,
    private envService: EnvironmentService,
    private smsService: SMSService,
  ) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    initTE({ Carousel });
    // Initialize your options array here
    this.options = [
      { label: 'Health and Wellness Services (massage therapy, chiropractic, fitness nutrition coaching)', value: 'Health and Wellness Services (massage therapy, chiropractic, fitness nutrition coaching)' },
      { label: 'Beauty and Personal Care Services (nails, hair salon, skincare…)', value: 'OBeauty and Personal Care Services (nails, hair salon, skincare…)' },
      { label: 'Technology', value: 'technology' },
      { label: 'Education and tutoring', value: 'education and tutoring' },
      { label: 'Financial services', value: 'financial services' },
      { label: 'Real estate', value: 'real estate' },
      { label: 'Travel and tourism', value: 'travel' },
      { label: 'Home services (cleaning, gardening…)', value: 'home services (cleaning, gardening…)' },
      { label: 'Skilled Trades', value: 'skilled trades' },
      { label: 'Marketing', value: 'marketing' },
      { label: 'Other', value: 'other' },
    ]; 

    this.settingsService.getAppSettingsList().valueChanges().subscribe((settings: any) => {
      if (settings) {
        this.settings = settings;
        if (settings.length > 0) {
          this.appLogo = this.settings[0].logo;
        }
      }
    });

    this.userService.getAllAdmins().valueChanges().subscribe(admins => {
      if (admins && admins.length > 0) {
        admins.forEach((admin: any) => {
          this.adminEmail.push(admin.Email);
        });  
      }
    });

    this.userContactPreference.push('InApp');
    this.userContactPreference.push('Email');
    this.userContactPreference.push('SMS')
    window.scroll(0, 0);
    // let regexForUrl = /^[a-z][-a-z0-9]{5,29}$/;
    let regexForUrl = /^[a-z][-a-z0-9]{1,14}$/;
    let regexForPassword = /^[A-Za-z0-9!@#$%^&*()]{6,}$/;
    this.isDemo = environment.firstNation.name === 'Demo';
    this.currentUser = this.authService.getGlobalUser();
    this.appRequestForm = this.fb.group({
      appName: [''],
      email: ['', [Validators.required, Validators.email, this.validateEmail()]],
      mobileNumber: ['', [Validators.required,Validators.pattern(/^\d{10}$/)]],
      fullName: ['', Validators.required],
      url: ['', [Validators.required, this.validateUrl(), this.customValidator("webURL", regexForUrl)]],
      isBusinessApp: [false],
      serviceType: [],
      hasStaff: [],
      hasAppointments: [],
      sellingOnline: [],
      hasContactInfo: [false],
      companyEmail: ['', Validators.email],
      businessName: [''],
      webURL: [''],
      hasAWebsite: [,],
      isSharingSocialMediaLink: [,],
      // message: ['', Validators.required],
      createPassword: ['', [Validators.required],],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
      isNationMember: [''],
      isNationSelected: [''],
      isInOffice: [''],
      typeOfOffice: [''],
      otherOfficeType: [false],
      tribalGroups: this.fb.array([]), //[Validators.required, notFalseValueValidator]
      runningForOffice: [''],
      runningOfficeType: [''],
      otherRunningOffice: [false],
      otherServiceName: ['']
    });

    ['Tribal Council', 'Advocacy Group', 'Other'].map(group => this.tribalGroups.push(this.addTribalGroup(group)))
    this.getAppService.getRequests().valueChanges().subscribe((getRequests) => {
      if (getRequests.length > 0) {
        this.existingRequests = getRequests;
        console.log("The Existing Requests are ", this.existingRequests)
      }
    });
    this.userService.getUsers().valueChanges().subscribe((users:any) => {
      if(users && users.length != 0){
        this.existingUser= users;
      }
    })

    if (this.isNationApp) {
      this.updateFormForNationApp()
    }

  }

  addTribalGroup(group, value = false) {
    return this.fb.group({
      tribeName: [''],
      value: [value],
      name: [group],
    })
  }

  onGroupSelect(group) {
  }
  updateFormForNationApp() {
    this.businessAppControl.patchValue(false)
    this.hasAWebsite.patchValue(false)
    this.isSharingSocialMediaLink.patchValue(false)

    this.appRequestForm.get('hasAppointments').patchValue(false)

    this.businessName.clearValidators()
    this.hasAWebsite.clearValidators()
    this.isSharingSocialMediaLink.clearValidators()
    this.appRequestForm.get('hasAppointments').clearValidators()
    // this.message.clearValidators()

    this.businessAppControl.updateValueAndValidity()
    this.hasAWebsite.updateValueAndValidity()
    this.appRequestForm.get('hasAppointments').updateValueAndValidity()
    this.businessName.updateValueAndValidity()
    this.isSharingSocialMediaLink.updateValueAndValidity()
    // this.message.updateValueAndValidity()

    this.isNationMember.addValidators([Validators.required])
    this.isNationMember.updateValueAndValidity()

  }
  isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }
  get appName() { return this.appRequestForm.get('appName'); }
  get email() { return this.appRequestForm.get('email'); }
  get mobileNumber() { return this.appRequestForm.get('mobileNumber'); }
  get fullName() { return this.appRequestForm.get('fullName'); }
  get url() { return this.appRequestForm.get('url'); }
  get companyEmail() { return this.appRequestForm.get('companyEmail'); }
  get businessName() { return this.appRequestForm.get('businessName'); }
  // get message() { return this.appRequestForm.get('message'); }
  get webURL() { return this.appRequestForm.get('webURL'); }
  get hasAWebsite() { return this.appRequestForm.get('hasAWebsite'); }
  get createPassword() { return this.appRequestForm.get('createPassword'); }
  get confirmPassword() { return this.appRequestForm.get('confirmPassword'); }
  get isSharingSocialMediaLink() { return this.appRequestForm.get('isSharingSocialMediaLink'); }
  get businessAppControl() { return this.appRequestForm.get('isBusinessApp'); }
  get isNationMember() { return this.appRequestForm.get('isNationMember'); }
  get isNationSelected() { return this.appRequestForm.get('isNationSelected'); }
  get isInOffice() { return this.appRequestForm.get('isInOffice'); }
  get typeOfOffice() { return this.appRequestForm.get('typeOfOffice'); }
  get otherOfficeType() { return this.appRequestForm.get('otherOfficeType'); }
  get tribalGroups() { return this.appRequestForm.get('tribalGroups') as UntypedFormArray }
  get runningForOffice() { return this.appRequestForm.get('runningForOffice'); }
  get runningOfficeType() { return this.appRequestForm.get('runningOfficeType'); }
  get otherRunningOffice() { return this.appRequestForm.get('otherRunningOffice'); }
  get otherServiceName() { return this.appRequestForm.get('otherServiceName') }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  sendRequest(value: any) {
    this.submitModalOpen = false;
    let mobileno = value.mobileNumber !== undefined ? value.mobileNumber : '';
    const encodedPass = CryptoJs.AES.encrypt(value.createPassword, this.secretKey).toString();
    const encodedConfirmPass = CryptoJs.AES.encrypt(value.confirmPassword, this.secretKey).toString()

    const model = {
      userId: '',
      appName: value.appName,
      email: value.email.toLowerCase(),
      mobileNumber: mobileno,
      fullName: value.fullName,
      url: value.url.toLowerCase(),
      imgLink: '',
      requestDate: new Date(),
      isBusinessApp: value.isBusinessApp,
      serviceType: value.serviceType,
      hasStaff: value.hasStaff,
      hasAppointments: value.hasAppointments,
      sellingOnline: value.sellingOnline,
      companyEmail: value.companyEmail,
      hasContactInfo: value.hasContactInfo,
      status: 'Pending',
      contact : ['Email', 'SMS', 'InApp'],
      allowPushNotification: true,
      // message: this.selectedIndustry == 'other' ? value.otherServiceName : value.message,
      businessName: value.businessName,
      createPassword: encodedPass,
      confirmPassword: encodedConfirmPass,
      isNationMember: value.isNationMember,
      nation: this.nationSelected,

      isInOffice: value.isInOffice,
      typeOfOffice: value.typeOfOffice || "",
      tribalGroups: value.tribalGroups.filter(group => group.value === true),

      runningForOffice: value.runningForOffice,
      runningOfficeType: value.runningOfficeType,
    };
    let adminEmail = [];
    this.userService.getAllAdmins().valueChanges().subscribe(admins => {
      if (admins && admins.length > 0) {
        admins.forEach((admin: any) => {
          adminEmail.push(admin.Email);
        });  
      }
    });
    
    var splitFullName = model.fullName.split(" ");
    this.firstName = splitFullName[0];
    this.lastName = splitFullName[1];

    const newUserCollectionModel =  {
      email : model.email.toLowerCase(),
      password : value.createPassword,
      firstName : this.firstName,
      lastName : this.lastName,
      mobileNumber : mobileno,
      allowPushNotification : true,
    apprequestModel: model}

    const smsModel = new SMSModel();
    smsModel.body = "A new application received \n";
    smsModel.body += `FullName:- ${model.fullName}\n`;
    smsModel.body += `Email:- ${model.email.toLowerCase()}\n`;
    smsModel.body += `PhoneNumber:- ${mobileno}\n`;
    smsModel.body += `URL:- ${model.url}\n`;
    smsModel.phoneNumberList.push("7808411880");
    smsModel.from = "";

    this.authService.appRequestSignUp(newUserCollectionModel,value.createPassword).then(x=> {
      this.emailService.sendAppRequestEmail(model, adminEmail);
      this.smsService.sendSMS(smsModel).subscribe((res)=>{
      })
      this.emailService.myAppRequestWelcome(model);
      setTimeout(() => {
        this.router.navigate(['auth/login']);
      }, 100);
    }).catch((error) => {
      this.toast({ html: error.message, classes: "red", type: "failure" });
    });   
  }

  appRequestAndSignup(model: any, password: any) {
    this.authService.emailSignUp(model, password)
  }
  getTribeOfProvince() {
    return this.tribalNames.filter(tribe => tribe.province == this.nationSelected['province'])
  }
  backClicked() {
    this.location.back();
  }
  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (obj.isRequest) {
        // this.router.navigate(['/get-app-confirmation']);
        if(this.envService.isNationApp) {
          window.location.href = "https://firstnationleaders.app/auth/login";
        } else if(this.nationOfuse === 'Build') {
          window.location.href = "https://myaccount.goingmobile.app/auth/login";
        } else {
          window.location.href = "https://" + this.nationOfuse+".goingmobile.app/auth/login";
        }
      }
      else if (obj.redirect) {
        this.backClicked();
      }
    }, 2000);
  }

  sendVerificationEmail() {
    let hostname = `${this.host}${this.appManagerId}`;
    let domainName1 = `${this.domainName}`
    const emailModel = new CustomAdminEmailModel();
    emailModel.subject = ` Email Verification`;
    emailModel.isNationApp = this.isNationApp;

    emailModel.body = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
    emailModel.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
    emailModel.body += '<center>';
    if (this.appLogo) {
      emailModel.body += `<img src="${this.appLogo}"
      width="150" height="150" style=" display: block; alt="Logo">`;
    }
    emailModel.body += '</center>';
    emailModel.body += `<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px;">  Hello ${this.fullName.value}, </h2> ` + '<br /> '
    emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you for registering with Going Mobile </p> `;
    emailModel.body += '<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Please click the button below to activate your account. </p>';
    emailModel.body += `<p><a href="${hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 18px 24px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 10px 2px; cursor: pointer; font-weight: bold; color: white; ">Activate My Account</a></p>`;
    emailModel.body += '<br /> <p style="padding-left: 20px; padding-right: 20px;"> If you didnt ask to verify this address, you can ignore this email.</p> <br /> <p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you </p> ';
    emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;"><a href="${domainName1}" style=" cursor: pointer; border-radius: 10px; text-align: center; text-decoration: underline; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: blue; text-decoration-color: blue; "><u>${domainName1}</u></a></p><br /><br />`;
    this.storeEmail.push(this.email.value.toLowerCase());
    emailModel.emailList = this.storeEmail;
    emailModel.role = "User";
    // custom send email used
    emailModel.host = "smtp.gmail.com",
    emailModel.adminEmail = "mkproject.app@gmail.com",
    emailModel.appPassword = "ytklyidomvqfmtky",
    // this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
    //   if (res) {
    //     this.router.navigate(['/get-app-confirmation']);
    //   }
    //   else {
    //     // Sending email error
    //   }
    // })

    this.emailService.sendEmail(emailModel).subscribe((res) => {
      if (res) {
        this.router.navigate(['/get-app-confirmation']);
      }
      else {
        // Sending email error
      }
    })
  }

  nextStep() {
    this.step = this.step === 2 ? 1 : 2;
  }

  stepHasError() {
    let step1Sections = [this.email, this.fullName, this.createPassword, this.confirmPassword,
    this.isNationMember, this.isNationSelected]
    if (this.isNationApp) {
      step1Sections.push(this.webURL)
    }
    return step1Sections.some(section => section.invalid)
  }

  onAppTypeChange() {
    this.isBusinessApp = this.appRequestForm.value.isBusinessApp;
  }

  onContactInfoChange() {
    this.inputContactInfo = this.appRequestForm.value.hasContactInfo;
  }

  onBusinessAppChange() {
    this.businessApp = this.appRequestForm.value.isBusinessApp;
    if (this.businessApp) {
      this.businessName.addValidators([Validators.required])
    } else {
      this.businessName.patchValue('')
      this.businessName.clearValidators()
    }
    this.businessName.updateValueAndValidity()
  }

  onShareSocialMediaLinkChange() {
    this.shareMySocialMediaLink = this.appRequestForm.value.isSharingSocialMediaLink;
  }

  onWebsiteChange() {
    this.haveWebsite = this.appRequestForm.value.hasAWebsite;
    if (this.haveWebsite) {
      this.webURL.addValidators([Validators.required])
    } else {
      this.webURL.patchValue('')
      this.webURL.clearValidators()
    }
    this.webURL.updateValueAndValidity()
  }

  onNationMemberChange() {
    if (this.isNationMember.value) {
      this.isNationSelected.addValidators([Validators.required])
      this.onOpenNationsModal()
    } else {
      this.isNationSelected.patchValue('')
      this.isNationSelected.clearValidators()
      this.nationSelected = {}
      this.openNationsModal = false
    }
    this.isNationSelected.updateValueAndValidity()
  }

  onInOfficeChange(office) {
    if (office == 'Other') {
      this.typeOfOffice.patchValue('')
      this.otherOfficeType.patchValue(true)
    } else {
      this.typeOfOffice.patchValue(office)
      this.otherOfficeType.patchValue(false)
    }
    this.typeOfOffice.updateValueAndValidity()
    this.otherOfficeType.updateValueAndValidity()
  }

  onRunningOfficeChange(office) {
    if (office == 'Other') {
      this.runningOfficeType.patchValue('')
      this.otherRunningOffice.patchValue(true)
    } else {
      this.runningOfficeType.patchValue(office)
      this.otherRunningOffice.patchValue(false)
    }
    this.runningOfficeType.updateValueAndValidity()
    this.otherRunningOffice.updateValueAndValidity()
  }

  onOpenNationsModal() {
    this.openNationsModal = true
  }

  onNationSelect($event) {
    this.nationSelected = $event
    this.isNationSelected.patchValue(true)
    this.isNationSelected.updateValueAndValidity()
    this.openNationsModal = false
  }

  closeNationSearchModal() {
    this.isNationSelected.patchValue('')
    this.isNationSelected.updateValueAndValidity()
    this.nationSelected = {}
    this.openNationsModal = false
  }

  closeCreateModal() {
    this.afterSubmitModalOpen = false;
    this.submitModalOpen = false;
  }

  removeImage() {
    this.binDoc = [];
    this.base64Image = '';
  }

  private validateEmail(): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } => {
      let existingEmail = [];
      let existingEmail2 = [];
      existingEmail = this.existingRequests.filter(req => req.email === control.value);
      existingEmail2 = this.existingUser.filter(user =>{
        return user.Email === control.value
      });
      if (existingEmail.length > 0) {
        return { emailExists: true };
      }
      if ("admin@goingmobile.app" === control.value) {
        return { invalidEmail: true };
      }
      if(existingEmail2.length > 0){
        return { emailExists: true };
      }
      else {
        return null;
      }
    };
  }

  generateRandomUrl(url) {

    // Generate a random number between 1 and 100.
    let urlSuggestion = []
    for (let i = 0; urlSuggestion.length < 3; i++) {
      const randomNumber = Math.floor(Math.random() * 10);

      // Check if the random number is already in the array.
      if (!urlSuggestion.includes(randomNumber)) {

        // Check if the appended url suggestion name has been taken.
        if (!this.checkExistence(url + randomNumber)) {

          // Append the alternative url to the array
          urlSuggestion.push(url + randomNumber);

        }
      }
    }

    return urlSuggestion;
  }

  checkExistence(optionalUrl) {
    let existingURL = []
    existingURL = this.existingRequests.filter(req => req.url === optionalUrl);
    if (existingURL.length > 0) {
      return true
    } else {
      return false
    }

  }

  private validateUrl(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let existingURL = [];
      this.alternativeUrl = []
      existingURL = this.existingRequests.filter(req => req.url === control.value);
      if (existingURL.length > 0) {

        this.alternativeUrl = this.generateRandomUrl(control.value)

        return { urlExists: true };
      }
      else {
        return null;
      }
    };
  }

  updateUrl(option:string):void {
    // this.url.setValue(option)
    this.appRequestForm.controls['url'].setValue(option);

  }

  private isValidProjectId(input) {
    const regex = /^[a-z][-a-z0-9]{5,29}$/;
    return regex.test(input);
  }

  private customValidator(controlName: string, pattern: RegExp) {
    return (formControl: UntypedFormControl) => {
      const controlValue = formControl.value;
      if (controlValue && !pattern.test(controlValue.toLowerCase())) {
        return { [controlName]: true };
      }
      return null;
    };
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  togglePasswordVisibility1(): void {
    this.showPassword1 = !this.showPassword1;
  }
  // userContactPreference
  toggleSMS(event) {
    // If the input has a value, enable the checkbox
    this.SMSchecked = !this.SMSchecked;
    if (event.target.checked || this.SMSchecked) {
      this.userContactPreference.push('SMS');
    }
    else {
      var SMS = this.userContactPreference.indexOf('SMS');//get  "sms" index
      this.userContactPreference.splice(SMS, 1);
    }

  }

  toggleEmail(event) {
    this.Emailchecked = !this.Emailchecked;
    if (event.target.checked || this.Emailchecked) {
      this.userContactPreference.push('Email');
    }
    else {
      var Email = this.userContactPreference.indexOf('Email');//get  "sms" index
      this.userContactPreference.splice(Email, 1);
    }

  }

  toggleInapp(event) {
    this.Inappchecked = !this.Inappchecked;
    if (event.target.checked || this.Inappchecked) {
      this.userContactPreference.push('InApp');
    }
    else {
      var InApp = this.userContactPreference.indexOf('InApp');//get  "sms" index
      this.userContactPreference.splice(InApp, 1);
    }


  }

  extractDigits(str: string): string {
    return str.replace(/\D/g, '');
  }

  checkInput(event: any) {
    this.inputText = event.target.value;
    this.checkboxDisabled = this.extractDigits(this.inputText).length <= 9 ? true : false
  }

  navigateToURL() {

    if(this.nationOfuse == "FNLeaders"){
      window.location.href = "https://firstnationleaders.app/login";
    }else {
      window.location.href = "https://" + this.nationOfuse + ".goingmobile.app/login";

    }

  }

  industryType(e: any, index: any) {
    this.formControls
    this.selectedIndustry = e
    if (e === 'other') {
      this.otherServiceClicked = true
    } else {
      this.otherServiceClicked = false
    }
  }

  openSubmitButton() {
    this.submitModalOpen = true;
    // if (this.submitModalOpen) {
    if(this.mobileNumber.value){
      this.verifyGlobal = this.smsService.getVerificationCode(this.mobileNumber.value).valueChanges().subscribe((x)=>{
        if(x && x.length > 0){
          this.smsService.deleteVerificationCode(x[0]).then((deleted) => {
          this.smsService.GenerateVerification(this.mobileNumber.value, this.isNationApp).subscribe((x) => {
            // console.log("what is x",x)
              x.phoneNumber = this.mobileNumber.value;
              this.smsService.saveVerificationCode(x).then((saved) => {
              })
            })
          })
        }else{
          this.smsService.GenerateVerification(this.mobileNumber.value, this.isNationApp).subscribe((x) => {
            x.phoneNumber = this.mobileNumber.value;
            this.smsService.saveVerificationCode(x).then((saved) => {
            })
          })
        }
        this.verifyGlobal.unsubscribe();
      })
    }
    // }
  }

  verifyClicked(event, appRequestForm) {
    let stringWithHyphens = "";
    // Split the string based on the hyphen
    const numbersArray = event.split('-');
    // Convert the array of strings to an array of numbers
    const numbers = numbersArray.map(Number);
    // Join the numbers array into a string with hyphens
    stringWithHyphens = numbers.join('');
    this.getCodeGlobal = this.smsService.getVerificationCode(this.mobileNumber.value).valueChanges().subscribe((code : any) => {
        if (code && code.length > 0) {
          console.log("CODE1", code);
          console.log("String with Hyphen which should be equal to code1", stringWithHyphens)
          if(code[0].code === stringWithHyphens ){
            console.log("CODE2", code[0].code);
              this.sendRequest(appRequestForm);
              this.submitModalOpen = false;
              this.afterSubmitModalOpen = true;
              if(this.afterSubmitModalOpen){
                this.smsService.deleteVerificationCode(code[0]).then((deleted) =>{
                })
              }else{
              }

            }else{
              // this.submitModalOpen = false;
              this.wrongCode = true;
              this.toast({ html: "You entered Wrong Verification code, Try Again", classes: 'red', type: 'failure' });
            }
        }else{
        }
        this.getCodeGlobal.unsubscribe();
    })
    
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  closeModal() {
    this.submitModalOpen = false;
  }

  resendCode(code){
    this.openSubmitButton();
  }

  get formControls() {
    return this.appRequestForm.controls;
  }
  navigateToHome() {
    window.location.href =(this.envService.isNationApp) ? "https://firstnationleaders.app/" : "https://goingmobile.app/";
  }

}

