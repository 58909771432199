import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PollsService } from 'src/app/services/firebase/polls.service';
import { environment } from 'src/environments/environment';
// import { toast } from 'materialize-css';
import { Location } from '@angular/common';

// declare var $: any;
// declare var M: any;

@Component({
  selector: 'app-edit-poll',
  templateUrl: './edit-poll.component.html',
  styleUrls: ['./edit-poll.component.css']
})
export class EditPollComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any;
  editedModel: any;
  surveyId = '';
  surveyForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  questionArray: UntypedFormArray;
  answersArray: UntypedFormArray;
  questionNumber = 1;
  showMultiAnswer = false;
  answerTypes: any = [
    { type: 'Multiple Choice', helpText: 'Displays a list of answers the participant can choose from' },
    { type: 'Open Ended Answer', helpText: 'Allows participants to answer in open text format' },
    { type: 'Scale', helpText: 'Displays a scale from any range from 0 to 10' }];
  scales = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  numberOfAnswers: any = ['2', '3', '4'];
  showSurveyResult = false;
  step1 = true;
  step2 = false;
  step3 = false;
  createPoll = false;


  constructor(private route: ActivatedRoute, private pollsService: PollsService, private fb: UntypedFormBuilder, private location: Location,) { }

  get surveyName() { return this.surveyForm.get('surveyName'); }
  get startDate() { return this.surveyForm.get('startDate'); }
  get endDate() { return this.surveyForm.get('endDate'); }
  get showResult() { return this.surveyForm.get('showResult'); }
  get showResultTo() { return this.surveyForm.get('showResultTo'); }
  get questions() { return this.questionForm.get('questionItems') as UntypedFormArray; }
  getAnswers(index) {
    const questions = this.questionForm.get('questionItems') as UntypedFormArray;
    return questions.controls[index].get('answers') as UntypedFormArray;
  }
  getScales(index){
    const questions = this.questionForm.get('questionItems') as UntypedFormArray;
    return questions.controls[index].get('scales');
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.surveyId = params.get('id');
    });

    if (this.surveyId) {
      this.pollsService.getPollByID(this.surveyId).valueChanges().subscribe(survey => {
        if (survey) {
          this.model = survey;

          this.surveyForm = this.fb.group({
            type: [{value: this.model.type, disabled: true}, [Validators.required]],
            surveyName: [this.model.name, [Validators.required]],
            startDate: [this.model.startDate, [Validators.required]],
            endDate: [this.model.endDate, [Validators.required]],
            showResult: [this.model.showResult, [Validators.required]],
            showResultTo: [this.model.showResultTo ? this.model.showResultTo : '']
          });

          if (this.model.type === 'Poll') {
            this.answerTypes = [{ type: 'Multiple Choice', 'helpText': 'Displays a list of answers the participant can choose from' },];
            this.createPoll = true;
          }

          if (this.model.showResult === 'yes') {
            this.onShowResultYes();
          }

          this.surveyForm.get('showResult').valueChanges.subscribe(x => {
            if (x === 'yes') {
              this.surveyForm.get('showResultTo').setValidators([Validators.required]);
            } else {
              this.surveyForm.get('showResultTo').clearValidators();
            }
            this.surveyForm.get('showResultTo').updateValueAndValidity();
          });

          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          const endDate1 = new Date();
          endDate1.setDate(endDate1.getDate() + 30);

          setTimeout(() => {
            // $('#startDate').datepicker(
            //   {
            //     format: 'dd/mmm/yyyy',
            //     minDate: tomorrow,
            //     maxDate: endDate1,
            //     defaultDate: tomorrow,
            //     setDefaultDate: tomorrow,
            //     yearRange: 1,
            //     onSelect: (date => {
            //       const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            //       const selectedDate = new Date(date);
            //       const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
            //       localStorage.setItem('surveyStartDate', date);
            //     })
            //   }
            // );

            const startDate2 = new Date();
            startDate2.setDate(startDate2.getDate() + 2);
            const endDate2 = new Date();
            endDate2.setDate(endDate2.getDate() + 120);

            // $('#endDate').datepicker(
            //   {
            //     format: 'dd/mmm/yyyy',
            //     minDate: startDate2,
            //     maxDate: endDate2,
            //     defaultDate: startDate2,
            //     setDefaultDate: startDate2,
            //     yearRange: 1,
            //     onSelect: (date => {
            //       const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            //       const selectedDate = new Date(date);
            //       const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
            //       localStorage.setItem('surveyEndDate', date);
            //     })
            //   }
            // );
            // M.updateTextFields();
            // $('select').formSelect();
            // $('.tooltipped').tooltip();
          }, 25);
        }
      });
    }
  }

  // typeChanges(value) {
  //   if (value === 'Poll') {
  //     this.createPoll = true;
  //     this.answerTypes = [{ 'type': 'Multiple Choice', 'helpText': '' }];
  //   } else {
  //     this.createPoll = false;
  //     this.answerTypes = [
  //       { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the participant can choose from' },
  //       { 'type': 'Open Ended Answer', 'helpText': 'Allows participants to answer in open text format' },
  //       { 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' }];
  //   }
  // }


  startDateChange() {
    if (this.surveyForm) { this.surveyForm.patchValue({ startDate: $('#startDate').val() }); }
  }

  endDateChange() {
    if (this.surveyForm) { this.surveyForm.patchValue({ endDate: $('#endDate').val() }); }
  }


  onShowResultNo() {
    this.showSurveyResult = false;
    this.showResultTo.reset();
  }

  onShowResultYes() {
    this.showSurveyResult = true;
    setTimeout(() => {
      // $('select').formSelect();
      // M.updateTextFields();
    }, 25);
  }

  submitSurveyInfo(value: any) {

    value.type = this.model.type;

    this.editedModel = {
      id: this.model.id,
      name: value.surveyName,
      type: value.type,
      numberOfQuestions: this.model.numberOfQuestions,
      startDate: value.startDate,
      endDate: value.endDate,
      showResult: value.showResult,
      showResultTo: value.showResultTo,
      questions: [],
      adminID: '1',
      adminName: 'Reda',
      datePosted: new Date(),
    };



    this.questionForm = this.fb.group({
      questionItems: this.fb.array([])
    });

    this.model.questions.forEach(question => {
      this.addQuestionFromModel(question);
    });

    this.step1 = false;
    this.step2 = true;

    setTimeout(() => {
      // $('select').formSelect();
    }, 25);
  }

  addQuestionFromModel(question: any): void {
    this.questionNumber += 1;
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.push(this.createQuestionFromModel(question));
  }

  createQuestionFromModel(question: any) {

    const scales =  this.fb.group({
      start: [''],
      startLabel: [],
      end: [''],
      endLabel: []
    });

    let answers = this.fb.array([]);
    if (question.answerType === 'Multiple Choice'){
      question.answers.forEach(a => {
        answers.push(this.addAnswersFromModel(a.answer));
      });
    } else if (question.answerType === 'Scale'){
      scales.get('start').patchValue(question.scales.start);
      scales.get('startLabel').patchValue(question.scales.startLabel);
      scales.get('end').patchValue(question.scales.end);
      scales.get('endLabel').patchValue(question.scales.endLabel);
      setTimeout(() => {
        // $('select').formSelect();
        // M.updateTextFields();
      }, 25);
    }

    return this.fb.group({
      question: [question.question, [Validators.required]],
      answerType: [question.answerType, [Validators.required]],
      allowMultipleAnswers: [question.allowMultipleAnswers, [Validators.required]],
      answers,
      scales ,
      // numberOfAnswers : [model.numberOfAnswers, [Validators.required]],
      // answer1 : [model.answer1],
      // answer2 : [model.answer2],
      // answer3 : [model.answer3],
      // answer4 : [model.answer4],
    });
  }

  addAnswersFromModel(value) {
    return this.fb.group({
      answer: [value, [Validators.required]]
    });
  }

  addQuestion(): void {
    this.questionNumber += 1;
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.push(this.createQuestion());
    setTimeout(() => {
      // $('select').formSelect();
    }, 25);
  }

  createQuestion() {
    return this.fb.group({
      question: ['', [Validators.required]],
      answerType: ['', [Validators.required]],
      allowMultipleAnswers: [false, [Validators.required]],
      // numberOfAnswers: [''],
      answers: this.fb.array([]),
      scales: this.fb.group({
        start: [''],
        startLabel: [],
        end: [''],
        endLabel: []
      }),
      // answer1 : [''],
      // answer2 : [''],
      // answer3 : [''],
      // answer4 : [''],
    });
  }

  removeFile(index: any) {
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.removeAt(index);
  }

  answerTypeChange(e: any, index: any) {
    this.resetControls(index);
    const answers = this.getAnswers(index);
    if (e === 'Multiple Choice') {
      if (answers.length < 2) {
        answers.push(this.addAnswers());
        answers.push(this.addAnswers());
      }
    }else if(e === 'Scale'){
      this.getScales(index).get('start').patchValue('1');
      this.getScales(index).get('end').patchValue('10');
    } else{}

    setTimeout(() => {
      // $('select').formSelect();
    }, 25);
  }

  resetControls(index){
    const answers = this.getAnswers(index);
    while (answers.length !== 0) {
        answers.removeAt(0);
      }
    const scales = this.getScales(index);
    // answers.reset();
    answers.clearValidators();
    answers.updateValueAndValidity();
    scales.reset();
    scales.clearValidators();
    scales.updateValueAndValidity();
  }


  addAnswers() {
    return this.fb.group({
      answer: ['', [Validators.required]]
    });
  }

  onAddAnswer(index) {
    const answers = this.getAnswers(index);
    answers.push(this.addAnswers());
  }

  removeAnswer(questionIndex, answerIndex) {
    const answers = this.getAnswers(questionIndex);
    answers.removeAt(answerIndex);
  }

  submitQuestions(value: any) {
    this.editedModel.questions = [];

    let qNumber = 0;
    value.questionItems.forEach(element => {
      qNumber += 1;

      const question = {
        questionNumber: qNumber,
        question: element.question,
        answerType: element.answerType,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers,
        scales:  element.scales
        // numberOfAnswers : '',
        // answer1 : '',
        // answer2 : '',
        // answer3 : '',
        // answer4 : '',
      };

      if (element.answerType === 'Scale'){
        // var items: number[] = [];
        for (let i = element.scales.start; i <= element.scales.end; i++) {
          element.answers.push({answer: i.toString()});
        }
      }

      // if (element.answerType === 'Multiple Choice') {
      //   question.numberOfAnswers = element.numberOfAnswers;
      //   question.answer1 = element.answer1;
      //   question.answer2 = element.answer2;
      //   if (question.numberOfAnswers === '3') { question.answer3 = element.answer3; }
      //   if (question.numberOfAnswers === '4') { question.answer3 = element.answer3; question.answer4 = element.answer4; }
      // } else {
      //   question.numberOfAnswers = '';
      // }

      this.editedModel.questions.push(question);

    });

    this.editedModel.numberOfQuestions = this.editedModel.questions.length;

    this.pollsService.updatePoll(this.editedModel).then(x => {
      // toast({ html: 'Survey Successfully updated!', classes: 'green' });
      this.step2 = false;
      this.location.back();
    });
  }

  backClicked() {
    this.location.back();
  }



}
