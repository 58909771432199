import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl, NgModel, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { HelperService } from 'src/app/services/helper/helper';
import { EnvironmentService } from 'src/app/services/helper/env';
import { EmployeeScheduleModel } from 'src/app/models/ScheduleModel';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { ManifestService } from 'src/app/services/api/manifest.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { AvailableNumbersModel } from 'src/app/models/AvailableNumbersModel';
import { TwilioPhoneNumberModel } from 'src/app/models/TwilioPhoneNumberModel';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { ScrollToTopService } from 'src/app/services/helper/scroll-to-top.service';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { RoutMetaService } from 'src/app/services/helper/rout-meta.service';
import { ChatGptService } from 'src/app/services/api/chat-gpt.serivce';
import { select, Store } from '@ngrx/store';
import { AppState, UserState } from 'src/app/state/app.state';
import {  selectAppSettings, selectRole, selectUserData } from 'src/app/state/app.selectors';
import * as AppSections from 'src/app/state/app.actions';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-app-setup',
  templateUrl: './app-setup.component.html',
  styleUrls: ['./app-setup.component.css']
})
export class AppSetupComponent implements OnInit {
  @Input() calledFromEmptyDashboard: string;
  @Input() selectedTab: string;
  @Output() customized = new EventEmitter<string>();
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();
  @Output() changesSaved = new EventEmitter<string>();
  appSettingsForm: FormGroup;
  metaSettingsForm: FormGroup;

  generalSettingsForm: FormGroup;
  manifestForm: FormGroup;

  model: any;
  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  isSidebarMenu = false;
  isBottomMenu = false;
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  showLogoInput = true;
  showImageFromServer = false;
  oldUrl: any;
  currentUser: any;
  isBusinessApp = false;
  isMobile = false;
  mobileBase64Image: any;
  useBase64 = false

  // Added B
  verifyUsers = false;
  isChecked = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  // modal
  sectionSettingsModalOpen = false;
  eachSectionSettingsModalOpen = false;
  sectionResetModalOpen = false;
  hasActiveSection = false;
  section: any;
  editModalOpen = false;
  settingsToBeSaved: any;
  deleteModalOpen = false;
  sectionToBeDeleted: any;
  appSections = GlobalConstants.AppSections;
  currencies = GlobalConstants.Currencies;

  provinces = GlobalConstants.provinces;
  states = GlobalConstants.states;

  // signatureForm: FormGroup;
  // greetings : any;
  // signOff : any;
  role: any;
  isAdmin: boolean;

  cropModalOpen = false;
  imageChangedEvent: any = '';
  openBrandCollapse = false;
  openSeoCollapse = false;
  openGeneralCollapse = false;
  openSectionCollapse = false;
  openAreasCollapse = false;
  openPushCollapse = false;
  openEmailCollapse = false;
  openSmsCollapse = false;
  openInfoCollapse = false;
  openSmsAndEmailCollapse = false;
  openAudienceCollapse = false;
  openSEOCollapse = false;


  mediaList = [];
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };
  uploadFileModalOpen = false;
  newModel: any;

  // isNationApp = environment.firstNation.name == "FNLeaders" ? true : false

  // chatgpt

  selectedInput;
  chatGptModalOpen = false;
  requestMessage;

  manifestModel: any;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';

  manifest: any;
  newSchedule: any;
  existingSchedule: any;
  defaultSchedule: any;

  appUrlFinal2 = ((environment.firstNation.website).slice(-2)) == '/#' ? (environment.firstNation.website).slice(0, -2) : environment.firstNation.website;

  appUrlFinal = this.appUrlFinal2;

  imageResizer_72: any;
  imageResizer_96: any;
  imageResizer_128: any;
  imageResizer_192: any;
  imageResizer_256: any;
  imageResizer_512: any;
  writeFileParameter: any;

  previewToShow: boolean = false;

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  availablePhones: any;
  activePhones = [];
  generic: boolean;

  //the selected
  selectedCountry: string;
  selectedProvince: string;
  selectedState: string;
  selectedPreference: string;

  locality: any;
  selectedPhone: any;

  toBeChanged: boolean = false;

  fetchedPhoneNumber = [];
  fetched = [];

  displayReleaseBtn: boolean = false;
  maxKeyWords: number = GlobalConstants.maxChatGptKeywords
  maxHashTags: number = GlobalConstants.maxChatGptKeywords

  domain = environment.firstNation.name;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";

  // links : any;

  ////////
  // linkSocial: boolean = false;
  // customizeUnsubscribe: boolean = false;
  // changeGreetings: boolean = false;

  facebook: any;
  instagram: any;
  linkedin: any;
  linkGlobal = '';

  showUnsubscribe: boolean = false;
  subscribe: boolean = false;

  changeMetaTagSetting: boolean = false;
  metaTagSettings: any = null

  userInput: string = '';
  generatedQuestion: string = '';

  settings: any;
  data: any;
  user$: Subscription;
  role$: Subscription;
  isAdmin$: Subscription;
  appSettings: any;
  appSettings$: Subscription;
  saveSeo : any;

  constructor(public appSettingsService: AppSettingsService,
    public store: Store<UserState>,
    private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private customSectionService: CustomSectionService,
    private helperService: HelperService,
    private envService: EnvironmentService,
    private manifestService: ManifestService,
    private scheduleService: ScheduleService,
    private smsService: SMSService,
    private socialMedia: SocialMediaService,
    private scrollService: ScrollToTopService,
    private metaService: Meta,
    private titleService: Title,
    private ChatgptService: ChatGptService,
    private routMeta: RoutMetaService,
    private activatedRoute: ActivatedRoute,

  ) {
      this.user$ = this.store.select(selectUserData).subscribe((user) => { this.currentUser = { ...user }; });
      this.role$ = this.store.select(selectRole).subscribe((role) => { this.role = role; });
      this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
        this.appSettings = settings;  
        this.model = JSON.parse(JSON.stringify(settings));
        console.log("MODEL", this.model)
      });
                
      
    if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web') {

      this.isMobile = true;
    }
  }
  ngOnDistroy() { 
    this.user$.unsubscribe();
    this.role$.unsubscribe();
    this.appSettings$.unsubscribe();
  }

  ngOnInit() {
    this.scrollService.scrollToTopOnNavigation();
    this.scrollService.scrollToTop();

    // fetch exsting metatag
    this.getMetaTagSettings();
    this.isAdmin = this.role === 'Admin';
    if (this.envService.fromFNLeaders) {
      this.updateSettingsDefault()
    }
    this.generalSettingsForm = this.fb.group({
      serviceType: [''],
    });

    this.metaSettingsForm = this.fb.group({
      appTitle: [''],
      description: [''],
      keyWords: this.fb.array([]),
      hashTags: this.fb.array([]),
      quesAns: this.fb.array([]),
      answers: this.fb.array([]),
      questions: this.fb.array([]),
      keyWordQuery: [''],
      HashTagsQuery: ['']
    });

    this.appSettingsForm = this.fb.group({
      appName: ['', [Validators.required, Validators.maxLength(30),noWhitespaceValidator()]],
      appTitle: [''],
      description: [''],
      appLogo: [''],
      buttonOption: [''],
      navbarOption: [''],
      themeColor: [''],
      serviceType: ['Both'],
      serviceName: ['Services'],
      productName: ['Products'],
      takeAppointment: [''],
      hasEmployees: [false],
      isBusinessApp: [true],
      verifyUsers: [false],
      welcomeMessage: ['',Validators.maxLength(100)],
      currency: ['CAD'],
      greetings: [''],
      signOff: [''],
      messageSMS: [''],
      // description:[''],
      phoneNumber: [''],
      availablePhone: [''],
      localityNumber: [''],
      purchased: [false],
      verifySubscribers: [''],
      general: [''],
      keyWordQuery: [''],
      HashTagsQuery: [''],
      keyWords: this.fb.array([]),
      hashTags: this.fb.array([]),
      links: [''],
      linkSocial: [''],
      customizeUnsubscribe: [false],
      changeGreetings: [''],
      greetingMessage: [''],
      unSubscribe: [''],
      adminEmail: this.fb.array([this.fb.control('', [Validators.email])]),
      messageFromChat: ['']
    });

    // this.smsService.fetchAllPhoneNumber().pipe(
    //   filter((fetched) => !!fetched && fetched.length > 0),
    //   map((fetched) => fetched.map((item) => item.phoneNumber))
    // ).subscribe((phoneNumbers) => {
    //   this.fetched = phoneNumbers;
    // });
    this.smsService.fetchAllPhoneNumber()
  .pipe(
    tap((fetched) => console.log('Fetched data:', fetched)), // Debugging log
    filter((fetched) => Array.isArray(fetched) && fetched.length > 0),
    map((fetched) => fetched.map((item) => item.phoneNumber))
  )
  .subscribe({
    next: (phoneNumbers) => {
      this.fetched = phoneNumbers;
    },
    error: (err) => {
      console.error('Error fetching phone numbers:', err);
    }
  });


    this.socialMedia.getSocialMedia().valueChanges().subscribe((links: any) => {
      if (links && links.length) {
        this.facebook = links[0].facebook ? links[0].facebook : '';
        this.instagram = links[0].instagram ? links[0].instagram : '';
        this.linkedin = links[0].linkedin ? links[0].linkedin : '';

      }
    })

    this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {

      if (metaTags && metaTags.length > 0) {

        if (metaTags[0]?.keyWords && metaTags[0]?.keyWords.length > 0) {
          if(Array.isArray(metaTags[0].keyWords)){
            this.keyWords.clear();
            metaTags[0].keyWords.forEach((word) => this.keyWords.push(this.fb.control(word)));
        }else{
          this.keyWords.clear();
          JSON.parse(metaTags[0].keyWords).forEach((word) => this.keyWords.push(this.fb.control(word)));
        }
      }
        if (metaTags[0].hashTags && metaTags[0].hashTags.length > 0) {
          if(Array.isArray(metaTags[0].keyWords)){
            this.hashTags.clear();
            metaTags[0].hashTags.forEach((word) => this.hashTags.push(this.fb.control(word)));

          }else{
            this.hashTags.clear();
           JSON.parse(metaTags[0].hashTags).forEach((word) => this.hashTags.push(this.fb.control(word)));

        }
      }
        this.metaSettingsForm.patchValue({
          appTitle: metaTags[0].appTitle,
          description: metaTags[0].description,
          // keyWords : metaTags[0].keyWords,
        })
      }
    })
  


    this.smsService.activePhone().subscribe((active: any) => {
      if (active && active.length) {
        this.activePhones = active.filter((phoneNumber) => !this.fetched.includes(phoneNumber));
      }
    });

    this.appSettingsService.getMetadata().valueChanges().subscribe((meta) => {
      if (meta && meta.length > 0) {
        this.data = meta[0];
        console.log("DATA",this.data);
        this.metaSettingsForm.patchValue({
          description: this.data?.description ? this.data?.description : '',
          appTitle: this.data?.appTitle ? this.data?.appTitle : '',
          answers: this.data?.answers ? this.data?.answers : '',


        })
      }
    })

    if (this.appSettings && this.appSettings.appSections) { 
        this.appSections = this.appSettings.appSections;
        this.appSections.forEach(section => {
          if (section.isEnabled) {
            this.hasActiveSection = true;
          }
        });
        if (this.model) {
          this.isBusinessApp = this.model.isBusinessApp;
          this.verifyUsers = this.model.verifyUsers;
          this.model.logo = this.model.logo ? this.model.logo : '';
          if (this.model.adminEmail.length > 0) {
            this.model.adminEmail = this.model.adminEmail.filter((ae) => ae !== '')
          }

          this.appSettingsForm.patchValue({
            appName: this.model.appName ?? '',
            buttonOption: this.model.buttonOption ?? 'rectangularbutton',
            navbarOption: this.model.navbarOption ?? 'bottomnavbar',
            themeColor: this.model.themeColor ?? '',
            serviceType: this.model.serviceType ?? 'Both',
            appLogo: this.model.logo,
            takeAppointment: this.model.takeAppointment ?? '',
            hasEmployees: this.model.hasEmployees ?? false,
            isBusinessApp: this.model.isBusinessApp ?? true,
            serviceName: this.model.serviceName ?? 'Services',
            productName: this.model.productName ?? 'Products',
            verifyUsers: this.model.verifyUsers ?? false,
            welcomeMessage: this.model.welcomeMessage ?? '',
            currency: this.model.currency ?? 'USD',
            adminEmail: this.model.adminEmail ?? [],
            greetings: this.model.greetings ?? '',
            signOff: this.model.signOff ?? '',
            localityNumber: this.model.localityNumber ?? '',
            messageSMS: this.model.messageSMS ?? '',
            phoneNumber: this.model.phoneNumber ?? '',
            availablePhone: this.model.availablePhone ?? '',
            purchased: true, // This is always true, so no need for a check
            verifySubscribers: this.model.verifySubscribers ? this.model?.verifySubscribers : '',
            general: this.model.general ?? '',
            linkSocial: this.model.linkSocial ? this.model.linkSocial : { facebook: '', instagram: '', linkedin: '' },
            links: this.model.links ?? '',
            customizeUnsubscribe: this.model.customizeUnsubscribe ? this.model?.customizeUnsubscribe :  '',
            changeGreetings: this.model.changeGreetings ?? { greetings: '', firstName: false, lastName: false },
            greetingMessage: this.model.greetingMessage ?? 'hello',
            unSubscribe: this.model.unSubscribe ?? `\n To stop receiving emails please login to ${this.domainName} \n Go to your preferences and disable Email, SMS or Both.`,
            messageFromChat: this.model.messageFromChat ?? ''
        });
          if (!this.model.changeGreetings) {
            this.model.changeGreetings = { greetings: '', firstName: false, lastName: false };
          }
          if (!this.model.linkSocial) {
            this.model.linkSocial = { facebook: '', instagram: '', linkedin: '' };
          }
          if (this.currentUser.Email) {
            if (this.model.adminEmail && !this.model.adminEmail.includes(this.currentUser.Email)) {
              this.model.adminEmail.push(this.currentUser.Email);
            }
          }
          this.appSettingsForm.patchValue({
            adminEmail: this.model.adminEmail as FormArray
          })
          if (this.model.adminEmail.length > 1) {
            for (let i = 1; i < this.model.adminEmail.length; i++) {
              this.adminEmail.push(this.fb.control(this.model.adminEmail[i], [Validators.email, Validators.required]));
            }
          }

          // if (this.model.keyWords && this.model.keyWords.length > 0) {
          //   this.model.keyWords.forEach((word) => this.keyWords.push(this.fb.control(word)))
          //     ;
          // }

          if (this.model.navbarOption === 'sidenavbar') {
            this.isSidebarMenu = true;
          }
          if (this.model.logo) {
            this.showImageFromServer = true;
            this.oldUrl = this.model.logo;

          }
          if (this.model.linkSocial) {
            // this.toggleLinkSocials();
            if (this.linkSocial.value) {
              let links = ``;
              if (this.model?.linkSocial?.facebook) {
                links += `Facebook: ${this.model?.linkSocial?.facebook} \n`
              } if (this.model.linkSocial.instagram) {
                links += `Instagram: ${this.model?.linkSocial?.instagram} \n`
              } if (this.model.linkSocial.linkedin) {
                links += `Linkedin: ${this.model?.linkSocial?.linkedin} \n`
              }
              this.linkGlobal = links;
              this.appSettingsForm.patchValue({
                links: links ? links : ''
              })
            } else {
              this.appSettingsForm.patchValue({
                links: ''
              })
            }
          }
          if (this.model.customizeUnsubscribe) {
            this.showUnsubscribe = true;
          }

          this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
        }


        if (this.envService.fromFNLeaders) {
          this.updateSettingsDefault()
        }

    }

  }
  get appName() {
    return this.appSettingsForm.get("appName");
  }
  addAdminEmail() {
    this.adminEmail.push(this.fb.control('', [Validators.email, Validators.required]));
  }

  removeAdminEmail(indexOfEmailToBeRemoved) {
    this.adminEmail.removeAt(indexOfEmailToBeRemoved);
  }

  getMetaTagSettings() {
    this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;

      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        this.metaSettingsForm.get('appTitle').setValue(this.metaTagSettings?.appTitle)
        this.routMeta.updateTitle(this.metaSettingsForm.get('appTitle').value);
        this.metaSettingsForm.get('description').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(this.metaSettingsForm.get('description').value);
        // this.metaSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(this.metaSettingsForm.get('keyWords').value);
      }
    });
  }

  updateSettingsDefault() {
    // this.businessAppControl.patchValue(false)
    // this.customizeUnsubscribe.patchValue(false)
    // this.verifySubscribers.patchValue(false)
    // this.takeAppointment.patchValue('')
    // this.hasEmployees.patchValue(false)
    // this.serviceType.patchValue('None')
    // this.businessAppControl.updateValueAndValidity()
    // this.takeAppointment.updateValueAndValidity()
    // this.hasEmployees.patchValue(false)
    // this.serviceType.updateValueAndValidity()
  }

  SaveSettings(modelValues: any) {
    // updating
    if (this.model) {
      // update option
      this.model.buttonOption = modelValues.buttonOption;
      this.model.navbarOption = modelValues.navbarOption;
      this.model.themeColor = modelValues.themeColor;
      this.model.appName = modelValues.appName;
      // this.model.description = modelValues.description;
      this.model.serviceType = modelValues.serviceType;
      this.model.takeAppointment = modelValues.takeAppointment;
      this.model.hasEmployees = modelValues.hasEmployees;
      this.model.isBusinessApp = modelValues.isBusinessApp;
      this.model.serviceName = modelValues.serviceName;
      this.model.productName = modelValues.productName;
      this.model.verifyUsers = modelValues.verifyUsers;
      this.model.verifySubscribers = modelValues.verifySubscribers ? modelValues.verifySubscribers : '';
      this.model.general = modelValues.general ? modelValues.general : '';
      this.model.linkSocial = modelValues.linkSocial ? modelValues.linkSocial : { facebook: '', instagram: '', linkedin: '' };
      this.model.customizeUnsubscribe = modelValues.customizeUnsubscribe ? modelValues.customizeUnsubscribe : '';
      this.model.changeGreetings = modelValues.changeGreetings ? modelValues.changeGreetings : { firstName: false, lastName: false };
      this.model.unSubscribe = modelValues.unSubscribe ? modelValues.unSubscribe : '';
      this.model.welcomeMessage = modelValues.welcomeMessage;
      this.model.adminEmail = modelValues.adminEmail;
      this.model.currency = modelValues.currency;
      this.model.appSections = this.appSections;
      this.model.greetings = modelValues.greetings ? modelValues.greetings : '';
      this.model.signOff = modelValues.signOff ? modelValues.signOff : '';
      this.model.messageSMS = modelValues.messageSMS ? modelValues.messageSMS : '';
      // this.model.description = modelValues.description ? modelValues.description : '';
      this.model.phoneNumber = modelValues.phoneNumber ? modelValues.phoneNumber : '';
      this.model.availablePhone = modelValues.availablePhone ? modelValues.availablePhone : '';
      this.model.localityNumber = modelValues.localityNumber ? modelValues.localityNumber : '';
      this.model.purchased = modelValues.purchased ? modelValues.purchased : false;
      this.model.greetingMessage = modelValues.greetingMessage ? modelValues.greetingMessage : { greetings: 'Hello', firstName: false, lastName: false };
      this.model.links = modelValues.links ? modelValues.links : { facebook: false, instagram: false, inkedin: false };
      this.model.messageFromChat = modelValues.messageFromChat ? modelValues.messageFromChat : '';
      if(this.data){
      this.data.keyWords = modelValues?.keyWords ? modelValues?.keyWords : [];
      this.data.hashTags = modelValues?.hashTags ? modelValues?.hashTags : [];
      this.data.appTitle = modelValues?.appTitle ? modelValues?.appTitle : 'title',
      this.data.description = modelValues.description ? modelValues.description : '';
      }
      // if(modelValues.greetingMessage){
      //   this.model.greetingMessage = {greetings: modelValues.greetingMessage, firstName: this.model.greetingMessage.firstName, lastName: this.model.greetingMessage.lastName }
      // }
      if (modelValues.takeAppointment == 'yes') {
        if (this.existingSchedule) {
          this.existingSchedule.employeeId = this.currentUser.uid;
          this.scheduleService.updateSchedule(this.existingSchedule)
        }
        else if (this.newSchedule) {
          this.newSchedule.employeeId = this.currentUser.uid;
          this.scheduleService.createEmployeeSchedule(this.newSchedule)
        }
        else {
          if (this.defaultSchedule) {
            this.newSchedule = { schedule: [], employeeId: this.currentUser.uid, id: '' };
            this.newSchedule.schedule = this.defaultSchedule.value.schedules;
          
            const subscription = this.scheduleService.getScheduleByEmployeeId(this.currentUser.uid).valueChanges().subscribe((schedule: any) => {
              if (schedule && schedule.length > 0) {
                this.newSchedule.id = schedule[0].id;
                this.scheduleService.updateSchedule(this.newSchedule);
              } else {
                this.scheduleService.createEmployeeSchedule(this.newSchedule);
              }
          
              // Unsubscribe to avoid infinite calls
              subscription.unsubscribe();
            });
          }
        }
      }

      if (this.mediaList.length > 0) {
        this.uploadFileModalOpen = true;
      }
      if (this.mediaList.length == 0) {
        this.updateAppSettings(this.model);
      }

    }
    // creating new setting
    else {
      this.newModel = modelValues;

      this.newModel.appSections = this.appSections;
      if (this.mediaList.length > 0) {
        this.uploadFileModalOpen = true;
      }
      if (this.mediaList.length == 0) {
        this.createAppSettings(this.newModel);
      }
    }

  }

  updateAppSettings(model) {
    this.imageResizer_72 = {
      width: 72,
      height: 72,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_96 = {
      width: 96,
      height: 96,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_128 = {
      width: 128,
      height: 128,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_192 = {
      width: 192,
      height: 192,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_256 = {
      width: 256,
      height: 256,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_512 = {
      width: 512,
      height: 512,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.appSettingsService.updateAppSettings(model).then(() => {
      this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });
      if (this.calledFromEmptyDashboard == 'audienceManagement' && this.selectedTab == 'email') {
        this.customized.emit('emailCustomized');
      } else if (this.calledFromEmptyDashboard == 'audienceManagement' && this.selectedTab == 'sms') {
        this.customized.emit('smsCustomized');
      }
    }).then((x) => {

      this.manifestService.imageResizer(this.imageResizer_72).subscribe(x => {
      })
      this.manifestService.imageResizer(this.imageResizer_96).subscribe(x => {
      });
      this.manifestService.imageResizer(this.imageResizer_128).subscribe(x => {
      });
      this.manifestService.imageResizer(this.imageResizer_192).subscribe(x => {
      });
      this.manifestService.imageResizer(this.imageResizer_256).subscribe(x => {
      });
      this.manifestService.imageResizer(this.imageResizer_512).subscribe(x => {
      });
    }).then((y) => {
      this.writeFileParameter = {
        theme_color: this.model.themeColor,
        appInfo: {
          appName: environment.firstNation.name,
          appUrl: this.appUrlFinal
        }
      }
      this.manifestService.WriteFile(this.writeFileParameter).subscribe(x => {
      });
    }).catch(err => {
      this.toast({ html: err.message, classes: 'red', type: 'failure' });
    });
    this.mediaList = [];
  }

  updateMetaTag(models) {

    models["appTitle"] = models["appTitle"] ? models["appTitle"] : '';
    models["description"] = models["description"] ? models["description"] : '';
    // let model = {
    //   appTitle: this.metaSettingsForm.get('appTitle').value,
    //   description: this.metaSettingsForm.get('description').value,
    //   keywords: this.keyWordQuery.value,
    //   hashTags: this.HashTagsQuery.value
    // }

    if (this.metaTagSettings && typeof this.metaTagSettings !== 'undefined') {
      models['id'] = this.metaTagSettings?.id;
      this.settings = this.appSettingsService.updateMetaDataSettings(models).then((response) => {
        this.toast({ html: 'Meta data Successfully Updated!', classes: 'green', type: 'success' });
        this.changeMetaTagSetting = null;
        this.openSeoCollapse = true;
        this.editModalOpen = false;
        this.getMetaTagSettings();
        this.settings.unsubscribe();
        

      }).catch((err) => {
        this.editModalOpen = false;
      })

    } else {
      this.saveSeo = this.appSettingsService.saveMetaDataSettings(models).then((response) => {
        this.toast({ html: 'Meta data Successfully Updated!', classes: 'green', type: 'success' });
        this.changeMetaTagSetting = null;
        this.openSeoCollapse = true;
        this.editModalOpen = false;
        this.getMetaTagSettings();
        this.saveSeo.unsubscribe();
      }).catch((err) => {
        this.editModalOpen = false;
      })

    }

  }

  createAppSettings(model) {
    this.imageResizer_72 = {
      width: 72,
      height: 72,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_96 = {
      width: 96,
      height: 96,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_128 = {
      width: 128,
      height: 128,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_192 = {
      width: 192,
      height: 192,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_256 = {
      width: 256,
      height: 256,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_512 = {
      width: 512,
      height: 512,
      imageUrl: this.model.logo ? this.model.logo : '',
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.appSettingsService.saveAppSettings(model).then(
      () => {
        this.toast({ html: 'App Settings Successfully Saved!', classes: 'green', type: 'success' });
      }).then((x) => {
        this.manifestService.imageResizer(this.imageResizer_72)
        this.manifestService.imageResizer(this.imageResizer_96)
        this.manifestService.imageResizer(this.imageResizer_128)
        this.manifestService.imageResizer(this.imageResizer_192)
        this.manifestService.imageResizer(this.imageResizer_256)
        this.manifestService.imageResizer(this.imageResizer_512)
      }).then((y) => {
        this.writeFileParameter = {
          theme_color: this.model.themeColor,
          appInfo: {
            appName: environment.firstNation.name,
            appUrl: this.appUrlFinal
          }
        }
        this.manifestService.WriteFile(this.writeFileParameter)
      }).catch(err => { console.error(err); });
    this.mediaList = [];
  }

  toggleFirstName(event) {
    if (event.target.checked) {
      this.model.changeGreetings.firstName = true;
    }
    else {
      this.model.changeGreetings.firstName = false
    }
  }

  toggleLastName(event) {
    if (event.target.checked) {
      this.model.changeGreetings.lastName = true;
    }
    else {
      this.model.changeGreetings.lastName = false
    }
  }

  toggleFacebook(eventFace) {
    if (eventFace.target.checked) {
      this.model.linkSocial.facebook = this.facebook;
      if (!this.linkGlobal.includes(this.facebook)) {
        this.linkGlobal += `Facebook: ${this.facebook} \n`
        this.appSettingsForm.patchValue({
          links: this.linkGlobal ? this.linkGlobal : ''
        })
      }
    }
    else {
      this.model.linkSocial.facebook = '';
      let social = 'Facebook: ' + this.facebook + ' \n';
      this.linkGlobal = this.linkGlobal.replace(social, "");
      this.appSettingsForm.patchValue({
        links: this.linkGlobal ? this.linkGlobal : ''
      })
    }
  }

  toggleInstagram(eventInsta) {
    if (eventInsta.target.checked) {
      this.model.linkSocial.instagram = this.instagram;
      if (!this.linkGlobal.includes(this.instagram)) {
        this.linkGlobal += `Instagram: ${this.instagram} \n`
        this.appSettingsForm.patchValue({
          links: this.linkGlobal ? this.linkGlobal : ''
        })
      } else {

      }
    }
    else {
      this.model.linkSocial.instagram = '';
      let social = 'Instagram: ' + this.instagram + ' \n';
      this.linkGlobal = this.linkGlobal.replace(social, "");
      this.appSettingsForm.patchValue({
        links: this.linkGlobal ? this.linkGlobal : ''
      })
    }

  }

  toggleLinkedIn(eventLink) {
    if (eventLink.target.checked) {
      this.model.linkSocial.linkedin = this.linkedin;

      if (!this.linkGlobal.includes(this.linkedin)) {
        this.linkGlobal += `Linkedin: ${this.linkedin} \n`
        this.appSettingsForm.patchValue({
          links: this.linkGlobal ? this.linkGlobal : ''
        })

      }
    }
    else {
      this.model.linkSocial.linkedin = '';
      let social = 'Linkedin: ' + this.linkedin + ' \n';
      this.linkGlobal = this.linkGlobal.replace(social, "");
      this.appSettingsForm.patchValue({
        links: this.linkGlobal ? this.linkGlobal : ''
      })
    }
  }

  cancelButtonClicked() { }

  selectKeyWord() {
    let wordExists = this.keyWords.value.some(w => w === this.keyWordQuery.value)
    if (wordExists) return
    this.keyWords.push(this.fb.control(this.keyWordQuery.value))
    // this.keyWords.push(this.keyWordQuery.value)
    this.keyWordQuery.patchValue('')

  }
  removeKeyWord(index) {
    this.keyWords.removeAt(index)
  }

  selectHashTags() {
    let wordExists = this.hashTags.value.some(w => w === this.HashTagsQuery.value)
    if (wordExists) return
    this.hashTags.push(this.fb.control(this.HashTagsQuery.value))
    // this.keyWords.push(this.keyWordQuery.value)
    this.HashTagsQuery.patchValue('')
  }

  removeHashTags(index) {
    this.hashTags.removeAt(index)
  }

  // to darken and lighten colors
  shadeColor(originalColor: string, amount) {
    return '#' + originalColor.replace(/^#/, '')
      .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount))
        .toString(16))
        .substr(-2));
  }

  sidebarClicked() {
    this.isBottomMenu = false;
    this.isSidebarMenu = true;
  }

  bottomNavbarClicked() {
    this.isBottomMenu = true;
    this.isSidebarMenu = false;
  }

  backClicked() {
    this.location.back();
  }

  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.appLogo.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.appLogo.patchValue('');
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = '';
    this.model.logo = '';
    this.appLogo.patchValue('');
    this.showImageFromServer = false;
    this.showLogoInput = true;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  onAppTypeChange() {
    this.isBusinessApp = this.appSettingsForm.value.isBusinessApp;
  }

  onAppSignupChange() {
    this.verifyUsers = this.appSettingsForm.value.verifyUsers;
  }

  toggleGenericPhone() {
    if (this.general.value === "buyNum") {
      this.generic = false;
    } else if (this.general.value === "existingNumber") {
      this.generic = true;
    }
    this.resetInput();
  }

  sendCode() {
    if (this.verifySubscribers?.value == 'SMS') {
      this.appSettingsForm.controls["general"].setValidators(Validators.required);
      this.appSettingsForm.controls["general"].updateValueAndValidity();
    } else {
      this.appSettingsForm.controls["general"].clearValidators();
      this.appSettingsForm.controls["general"].updateValueAndValidity();
    }
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.closeAllCollapse();
      // this.backClicked();
    }, 2000);
  }

  selectItem(offer) {
    this.hasActiveSection = false;
    this.appSections.forEach(section => {
      if (section.originalName === offer.originalName) {
        section.isEnabled = !section.isEnabled;
        if (section.isEnabled) {
          this.section = section;
          this.openEditEachSectionModal();
        }
      }
      if (section.isEnabled) {
        this.hasActiveSection = true;
      }
    });
  }

  openDeleteSectionModal(offer) {
    this.sectionToBeDeleted = offer;
    // this.hasActiveSection = false;
    this.deleteModalOpen = true;
  }

  redirectToEditPage(offer) {
    // this.router.navigate(['/admin/editSection', offer.customSectionId]);
    this.customSectionService.getCustomSectionBySectionTitle(offer.originalName).valueChanges().subscribe((sections: any) => {
      if (sections.length > 0) {
        this.router.navigate(['/admin/edit-custom-section/', sections[0].id]);
      }
    });
  }

  deleteItem() {
    this.deleteModalOpen = false;
    let sec: any;
    if (this.sectionToBeDeleted) {

      // delete section from db
      this.customSectionService.getCustomSectionBySectionTitle(this.sectionToBeDeleted.originalName).valueChanges().subscribe(sections => {
        sec = sections[0];
        if (sec) {
          this.customSectionService.deleteCustomSection(sec);
          this.customSectionService.deleteElementsBySectionId(sec.customSectionId);
        }
      })

      //delete from menu
      this.appSections = this.appSections.filter(section => {
        return section.originalName != this.sectionToBeDeleted.originalName
      })
    }

    this.model.appsections = this.appSections;
    this.SaveSettings(this.model);
  }

  onOfferChange() {
    if (this.serviceType.value === 'Service') {
      this.appSections.forEach(section => {
        if (section.originalName === 'Services') {
          section.isEnabled = true;
        }
        if (section.originalName === 'Products') {
          section.isEnabled = false;
        }
      });
    }
    if (this.serviceType.value === 'Product') {
      this.appSections.forEach(section => {
        if (section.originalName === 'Products') {
          section.isEnabled = true;
        }
        if (section.originalName === 'Services') {
          section.isEnabled = false;
        }
      });
    }
    if (this.serviceType.value === 'Both') {
      this.appSections.forEach(section => {
        if (section.originalName === 'Services' || section.originalName === 'Products') {
          section.isEnabled = true;
        }
      });
    }
    if (this.serviceType.value === 'Personal') {
      this.appSections.forEach(section => {
        if (section.originalName === 'Services' || section.originalName === 'Products') {
          section.isEnabled = false;
        }
      });
    }
  }

  closeEditSectionModal() {
    this.sectionSettingsModalOpen = false;
    this.eachSectionSettingsModalOpen = false;
  }

  openEditSectionModal() {
    this.sectionSettingsModalOpen = true;
  }

  openEditEachSectionModal() {
    this.eachSectionSettingsModalOpen = true;
  }

  sectionInputChange(offer, event) {
    this.hasActiveSection = false;
    this.appSections.forEach(section => {
      if (section.isEnabled) {
        this.hasActiveSection = true;
      }
      if (section.originalName === offer.originalName) {
        section.name = event.target.value;
      }
    });
  }

  visibilityChange(offer, event) {
    this.appSections.forEach(section => {
      if (section.originalName === offer.originalName) {
        section.isEnabled = event.target.value;
      }
    });
  }

  saveVisibilityChange() {
    this.model.appSections = this.appSections;
    this.closeEditSectionModal();
    this.imageResizer_72 = {
      width: 72,
      height: 72,
      imageUrl: this.model.logo,
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_96 = {
      width: 96,
      height: 96,
      imageUrl: this.model.logo,
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_128 = {
      width: 128,
      height: 128,
      imageUrl: this.model.logo,
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_192 = {
      width: 192,
      height: 192,
      imageUrl: this.model.logo,
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_256 = {
      width: 256,
      height: 256,
      imageUrl: this.model.logo,
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_512 = {
      width: 512,
      height: 512,
      imageUrl: this.model.logo,
      appInfo: {
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.appSettingsService.updateAppSettings(this.model).then(
      () => {
        this.toast({ html: 'App Settings Successfully Saved!', classes: 'green', type: 'success' });
      }).then((x) => {
        this.manifestService.imageResizer(this.imageResizer_72)
        this.manifestService.imageResizer(this.imageResizer_96)
        this.manifestService.imageResizer(this.imageResizer_128)
        this.manifestService.imageResizer(this.imageResizer_192)
        this.manifestService.imageResizer(this.imageResizer_256)
        this.manifestService.imageResizer(this.imageResizer_512)
      }).then((y) => {
        this.writeFileParameter = {
          theme_color: this.model.themeColor,
          appInfo: {
            appName: environment.firstNation.name,
            appUrl: this.appUrlFinal
          }
        }

        this.manifestService.WriteFile(this.writeFileParameter)
      }).catch(err => { });
  }

  resetDefaultSectionNames() {
    this.sectionResetModalOpen = false;
    this.appSections.forEach(section => {
      section.name = section.originalName;
    });
    this.model.appSections = this.appSections;
    this.SaveSettings(this.appSettingsForm.value);
  }

  openSectionResetModal() {
    this.sectionResetModalOpen = true;
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  saveEmailSign() {
    this.model.greetings = this.greetings;
    this.model.signOff = this.signOff;
    this.store.dispatch(AppSections.updateAppSettings({settings: this.model}))
      this.toast({ html: 'Email Signature Successfully Saved!', classes: 'green', type: 'success' })
    

  }

  saveSmsSign() {
    this.model.messageSMS = this.messageSMS;
    this.appSettingsService.updateAppSettings(this.model).then(() => {
      this.toast({ html: 'Email Signature Successfully Saved!', classes: 'green', type: 'success' })
    }).catch(err => { });
  }

  fileChangeEvent(event) {
    this.imgErrorMsg = ''
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }

  closeCroppingModal() {
    this.cropModalOpen = false;
  }

  onImageUpload(response: ImageUploaderResponseModel) {
    if (response.error) {
      this.imgErrorMsg = response.errorMessage
      return
    }

    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64 = true
    this.cropModalOpen = true;
  }

  processCroppedImage(event) {
    this.cropModalOpen = false;
    this.base64Image = event;
    // this.binDoc = this.base64Image;
    let base64result = this.base64Image.split(',')[1];
    this.useBase64 = false;
    const img = new ImageModel();

    if (this.isMobile) {
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({ type: 'Image', file: img });
    } else {
      img.imgBase64 = base64result;
      this.mediaList.push({ type: 'Image', file: img });
    }
  }

  newSection() {
    if (this.currentUser.role == 'Admin') {
      this.router.navigate(['/admin/create-new-section'])
    } else {
      this.router.navigate(['/employee/create-new-section'])
    }
  }

  confirmUpdate(appsettings) {
    this.settingsToBeSaved = appsettings;
    this.editModalOpen = true;
  }

  editConfirmed() {
    if (this.changeMetaTagSetting) {
       this.updateMetaTag(this.metaSettingsForm.value)

    } else {
      this.saveUpdate();
    }
  }

  saveUpdate() {
    this.editModalOpen = false;
    this.SaveSettings(this.settingsToBeSaved);
    if (this.calledFromEmptyDashboard == 'audienceManagement') {
      this.changesSaved.emit(this.selectedTab)
    }
  }

  closeEditModal() {
    this.editModalOpen = false;
  }

  closeAllCollapse() {
    this.openBrandCollapse = false;
    this.openSeoCollapse = false;
    this.openGeneralCollapse = false;
    this.openSectionCollapse = false;
    this.openAreasCollapse = false;
    this.openPushCollapse = false;
    this.openEmailCollapse = false;
    this.openSmsCollapse = false;
    this.openInfoCollapse = false;
    this.openSmsAndEmailCollapse = false;
    this.openAudienceCollapse = false;
    this.openSEOCollapse = false;
  }

  getTheUrlsSaved(event) {
    this.returnedMediaLinks = event;
    this.uploadFileModalOpen = false;
    if (this.model) {
      this.model.logo = this.returnedMediaLinks.imageURL;
      this.updateAppSettings(this.model);
    }
    if (this.newModel) {
      this.newModel.logo = this.returnedMediaLinks.imageURL;
      this.createAppSettings(this.newModel);
    }
  }

  selectCollapseToOpen(setupName) {
    if (setupName === 'Brand') {
      if (this.openBrandCollapse === false) {
        this.closeAllCollapse();
        this.openBrandCollapse = true;
      } else {
        this.openBrandCollapse = false;
      }
    }
    if (setupName === 'Seo') {
      if (this.openSeoCollapse === false) {
        this.closeAllCollapse();
        this.openSeoCollapse = true;
      } else {
        this.openSeoCollapse = false;
      }
    }
    if (setupName === 'General') {
      if (this.openGeneralCollapse === false) {
        this.closeAllCollapse();
        this.openGeneralCollapse = true;
      } else {
        this.openGeneralCollapse = false;
      }
    }
    if (setupName === 'Section') {
      if (this.openSectionCollapse === false) {
        this.closeAllCollapse();
        this.openSectionCollapse = true;
      } else {
        this.openSectionCollapse = false;
      }
    }
    if (setupName === 'Areas') {
      if (this.openAreasCollapse === false) {
        this.closeAllCollapse();
        this.openAreasCollapse = true;
      } else {
        this.openAreasCollapse = false;
      }
    }
    if (setupName === 'Push') {
      if (this.openPushCollapse === false) {
        this.closeAllCollapse();
        this.openPushCollapse = true;
      } else {
        this.openPushCollapse = false;
      }
    }
    if (setupName === 'Audience') {
      if (this.openAudienceCollapse === false) {
        this.closeAllCollapse();
        this.openAudienceCollapse = true;
      } else {
        this.openAudienceCollapse = false;
      }
    }
    if (setupName === 'Email') {
      if (this.openEmailCollapse === false) {
        this.closeAllCollapse();
        this.openEmailCollapse = true;
      } else {
        this.openEmailCollapse = false;
      }
    }
    if (setupName === 'Sms') {
      if (this.openSmsCollapse === false) {
        this.closeAllCollapse();
        this.openSmsCollapse = true;
      } else {
        this.openSmsCollapse = false;
      }
    }
    if (setupName === 'SmsAndEmail') {
      if (this.openSmsAndEmailCollapse === false) {
        this.closeAllCollapse();
        this.openSmsAndEmailCollapse = true;
      } else {
        this.openSmsAndEmailCollapse = false;
      }
    }
    if (setupName === 'Info') {
      if (this.openInfoCollapse === false) {
        this.closeAllCollapse();
        this.openInfoCollapse = true;
      } else {
        this.openInfoCollapse = false;
      }
    }

    if (setupName === 'SEO') {
      if (this.openSEOCollapse === false) {
        this.closeAllCollapse();
        this.openSEOCollapse = true;
      } else {
        this.openSEOCollapse = false;
      }
    }

    this.scrollService.scrollToElementByID(setupName)
    // const element = document.getElementById('TOP')
    // if(element){
    //   element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    // }
  }

  showPreview() {
    this.previewToShow = true;
  }

  onUseChatGpt() {
    this.selectedInput = this.welcomeMessage;
    this.requestMessage = this.helperService.getInnerText(this.welcomeMessage.value);
    this.chatGptModalOpen = true;
  }

  closeChatGptModal() {
    this.chatGptModalOpen = false;
  }

  updateWelcomeMessage(event) {
    event.input.patchValue(event.message)
    this.closeChatGptModal();
  }

  initializeSchedule(data) {
    this.defaultSchedule = data;
  }

  updateSchedule(data) {
    const employeeSchedule = {
      employeeId: this.currentUser.uid,
      schedule: this.helperService.validateSchedule(data.data.schedules),
      id: data.savedEmployeeSchedule.id
    };
    this.existingSchedule = employeeSchedule;
  }

  createSchedule(data) {
    const employeeSchedule = new EmployeeScheduleModel();
    employeeSchedule.employeeId = this.currentUser.id;
    employeeSchedule.schedule = this.helperService.validateSchedule(data.schedules);
    this.newSchedule = employeeSchedule;
  }

  selectPhone(selected) {
    this.selectedPhone = selected;

  }

  selectCountry(country) {
    this.selectedCountry = country;
  }

  selectState(state) {
    this.selectedState = state;
  }

  selectProvince(province) {
    this.selectedProvince = province;
  }

  selectPreference(preference) {
    this.selectedPreference = preference;
  }

  filter() {
    const availableModel = new AvailableNumbersModel();
    availableModel.countryCode = this.selectedCountry;
    availableModel.locality = this.localityNumber.value ? parseInt(this.localityNumber.value) : 0;
    if (this.selectedCountry == 'US') {
      if (this.selectedPreference != "number") {
        availableModel.inRegion = this.selectedState ? this.selectedState : '';
        availableModel.locality = 0;
      } else {
        availableModel.inRegion = '';
      }

    } else {
      if (this.selectedPreference != "number") {
        availableModel.inRegion = this.selectedProvince ? this.selectedProvince : '';
        availableModel.locality = 0;
      } else {
        availableModel.inRegion = '';
      }
    }
    this.smsService.availablePhoneLocalities(availableModel).subscribe((available: any) => {
      if (available && available.length >= 0) {
        this.availablePhones = available;
      }
    })
  }

  buy() {
    const phoneModel = new TwilioPhoneNumberModel();

    if (this.model.availablePhone !== '' && this.fetched.includes(this.model.availablePhone)) {
      phoneModel.phoneNumber = this.model.availablePhone;
      this.smsService.deletePhoneNumberFromSQL(phoneModel).subscribe((x) => {
        this.model.availablePhone = this.selectedPhone;

        phoneModel.phoneNumber = this.availablePhone.value;

        this.smsService.createPhoneNumber(phoneModel).subscribe((buy) => {
          if (buy && buy.length != 0) {
            this.toast({ html: 'You have Successfully bought the Phone Number!', classes: 'green', type: 'success' });
            this.model.purchased = true;
            this.toBeChanged = false;
            this.appSettingsService.updateAppSettings(this.model).then(() => {
            }).catch(err => { });

          } else {
            this.toast({ html: 'Failed to buy the number', classes: 'red', type: 'failure' })
          }
        })
      })
    } else {
      this.model.availablePhone = this.selectedPhone;

      phoneModel.phoneNumber = this.availablePhone.value;

      this.smsService.createPhoneNumber(phoneModel).subscribe((buy) => {
        if (buy && buy.length != 0) {
          this.toast({ html: 'You have Successfully bought the Phone Number!', classes: 'green', type: 'success' })
          this.model.purchased = true;
          this.appSettingsService.updateAppSettings(this.model).then(() => {
            this.toBeChanged = false;
          }).catch(err => { });

        } else {
          this.toast({ html: 'Failed to buy the number', classes: 'red', type: 'failure' })
        }
      })
    }
  }

  use() {
    if (this.model.purchased) {
      const phoneModel = new TwilioPhoneNumberModel();
      phoneModel.phoneNumber = this.model.availablePhone;
      this.smsService.deletePhoneNumberFromSQL(phoneModel).subscribe((x) => {
        this.model.availablePhone = this.selectedPhone;
        //to update when there is use this number
        this.model.verifySubscribers = this.verifySubscribers.value;
        this.model.purchased = false;
        this.appSettingsService.updateAppSettings(this.model).then(() => {
          this.toBeChanged = false;
          this.toast({ html: 'You have Successfully Saved the Phone Number!', classes: 'green', type: 'success' })
          // this.generic = false
        }).catch(err => { });
      })
    } else {
      //to be updated when there is use this number
      this.model.verifySubscribers = this.verifySubscribers.value;
      this.model.availablePhone = this.selectedPhone;
      this.model.purchased = false;
      this.appSettingsService.updateAppSettings(this.model).then(() => {
        this.toBeChanged = false;
        this.toast({ html: 'You have Successfully Saved the Phone Number!', classes: 'green', type: 'success' })
        // this.generic = false
      }).catch(err => { });
    }

  }

  release() {
    const phoneModel = new TwilioPhoneNumberModel();
    phoneModel.phoneNumber = this.model.availablePhone;
    this.smsService.releasePhoneNumber(this.model.availablePhone).subscribe(x => {
      this.smsService.deletePhoneNumberFromSQL(phoneModel).subscribe(x => {

      })
      this.model.availablePhone = '';
      this.appSettingsService.updateAppSettings(this.model).then(() => {
        this.toBeChanged = false;
        this.toast({ html: 'You have Successfully released the Phone Number!', classes: 'green', type: 'success' })
      }).catch(err => { });
    })

  }

  // releaseNumber(phoneNumber){
  //   const phoneModel = new TwilioPhoneNumberModel();
  //   phoneModel.phoneNumber = phoneNumber;
  //   this.smsService.releasePhoneNumber(phoneNumber).subscribe(x=>{
  //     this.smsService.deletePhoneNumberFromSQL(phoneModel).subscribe(x =>{
  //     })
  //   })
  // }

  change() {
    this.toBeChanged = true;
  }

  // onSubmit() {
  //   if (this.description) {
  //     this.ChatgptService.generateQuestion(this.description.value).subscribe(response => {
  //       this.generatedQuestion = response.choices[0].message.content.trim();
  //     });
  //   }
  // }

  checkIfActiveOrBought(phoneNumber) {
    if (this.fetched.includes(phoneNumber)) {
      this.displayReleaseBtn = true;
    }
  }

  resetInput() {
    this.selectedCountry = "";
    this.selectedState = "";
    this.selectedProvince = "";
    this.selectedPreference = "";
  }

  toggleLinkSocials() {
    if (this.linkSocial.value) {
      let link = ``;
      if (this.facebook) { link += `Facebook: ${this.facebook} \n` }
      if (this.instagram) { link += `Instagram: ${this.instagram} \n` }
      if (this.linkedin) { link += `Linkedin: ${this.linkedin} \n` }
      this.appSettingsForm.patchValue({
        links: link ? link : ''
      })
    }
  }

  toggleUnsubscribe(event) {
    if (event.target.checked) {
      this.showUnsubscribe = this.unSubscribe.value;
    } else {
      this.model.unSubscribe = '';
    }
  }

  skipPage() {
    this.skip.emit()
  }

  saveMetaUpdate(metaInfo) {
    
  }

  toggle(verify) {
    if (!(verify == 'SMS')) {
      this.appSettingsForm.controls["general"].clearValidators();
      this.appSettingsForm.controls["general"].updateValueAndValidity();
    } else {
      this.appSettingsForm.controls["general"].setValidators(Validators.required);
      this.appSettingsForm.controls["general"].updateValueAndValidity();
    }

  }

  addTag() {
    ///To be continued

    // this.titleService.setTitle(this.messageSMS.toString())
    this.metaService.addTag({ name: 'description', content: 'Article Description' });
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });
    this.metaService.addTag({ property: 'og:title', content: 'Content Title for social media' });
  }
  get businessAppControl() { return this.appSettingsForm.get('isBusinessApp') }
  get welcomeMessage() { return this.appSettingsForm.get('welcomeMessage') }
  get adminEmail() { return this.appSettingsForm.get('adminEmail') as FormArray; }
  get serviceType() { return this.appSettingsForm.get('serviceType'); }
  get greetings() { return this.appSettingsForm.get('greetings') };
  get signOff() { return this.appSettingsForm.get('signOff') }
  get messageSMS() { return this.appSettingsForm.get('messageSMS') };
  get takeAppointment() { return this.appSettingsForm.get('takeAppointment') }
  get hasEmployees() { return this.appSettingsForm.get('hasEmployees'); }
  get phoneNumber() { return this.appSettingsForm.get('phoneNumber') }
  get availablePhone() { return this.appSettingsForm.get('availablePhone') }
  get localityNumber() { return this.appSettingsForm.get('localityNumber') }
  get appLogo() { return this.appSettingsForm.get('appLogo'); }
  get verifySubscribers() { return this.appSettingsForm.get('verifySubscribers'); }
  get general() { return this.appSettingsForm.get('general'); }
  get links() { return this.appSettingsForm.get('links'); }
  get linkSocial() { return this.appSettingsForm.get('linkSocial'); }
  get customizeUnsubscribe() { return this.appSettingsForm.get('customizeUnsubscribe'); }
  get changeGreetings() { return this.appSettingsForm.get('changeGreetings'); }
  get greetingMessage() { return this.appSettingsForm.get('greetingMessage'); }
  get unSubscribe() { return this.appSettingsForm.get('unSubscribe'); }

  get keyWords() { return this.metaSettingsForm.get('keyWords') as FormArray }
  get hashTags() { return this.metaSettingsForm.get('hashTags') as FormArray }
  get HashTagsQuery() { return this.metaSettingsForm.get('HashTagsQuery') }
  get keyWordQuery() { return this.metaSettingsForm.get('keyWordQuery') }
  get description() { return this.metaSettingsForm.get('description')}
  get appTitle() { return this.metaSettingsForm.get('appTitle') }


  hasAppointment(){
    if(this.takeAppointment.value == 'yes'){
      return true
    } else {
      return false
    }
  }


  // import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


}
function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { required: true } : null;
  };
}