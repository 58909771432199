
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiKeyManagerService } from '../api/apiKeyManager.Service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { switchMap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AppinfoService {

  //   url = environment.firstNation.apiUrl + 'api/stats/';
  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService, private db: AngularFirestore) { }


  // savePushNotificationSettings(model: any) {
  //   model.id = this.db.createId();
  //   return this.db.collection('PushNotificationSettings').doc(model.id).set(model);
  // }

  addAppInfo(model) {
    const newContent = {
      id: this.db.createId(),
      appName: model.appName,
      backendConfName: model.backendConfName,
      executedStatus: model.executedStatus,
      link: model.link,
      accountId: model.accountId
    }
    return this.db.collection('AppInfo').doc(newContent.id).set(newContent);
  }


  getAppByName(name) {
    return this.db.collection('AppInfo', ref => ref.where('appName', '==', name));
  }

  getAppByURL(url) {
    return this.db.collection('AppInfo', ref => ref.where('link', '==', url));
  }
  deleteAppByLink(url: string): Observable<void> {
    return this.db.collection('AppInfo', (ref) => ref.where('link', '==', url))
      .get()
      .pipe(
        switchMap((querySnapshot) => {
          const doc = querySnapshot.docs[0]; // Assuming there is only one matching document
          return doc.ref.delete();
        })
      );
  }

  updateAppInfo(model) {
    return this.db.collection('AppInfo').doc(model.id).update(model);
  }

  addConfigSetting(model) {

    const newContent = {
      id: "defaultAccountId",
      accountId: model.accountId
    }

    return this.db.collection('ConfigDefaultSetting').doc(newContent.id).set(newContent);


  }

  getConfigSettingById(id) {
    return this.db.collection('ConfigDefaultSetting', ref => ref.where('accountId', '==', id));
  }

  getConfigSetting() {
    return this.db.collection('ConfigDefaultSetting');

  }

  updateConfigSetting(newAccId: any) {
    let accountId = "defaultAccountId"

    const docRef = this.db.collection('ConfigDefaultSetting').doc(accountId);
    return docRef.get().subscribe((doc) => {
      if (doc.exists) {
        docRef.update({
          accountId: newAccId,
        });
      } else {
        docRef.set({
          accountId: newAccId,
        });
      }
    });
  }

  defaultBillilngAccount

  // updateDefaultBilling(newBillingAcc: any) {
  //   let billingAccount = "defaultBillilngAccount"

  //   const docRef = this.db.collection('ConfigDefaultSetting').doc(billingAccount);
  //   return docRef.get().subscribe((doc) => {
  //     if (doc.exists) {
  //       docRef.update({
  //         billingAccount: newBillingAcc,
  //       });
  //     } else {
  //       docRef.set({
  //         billingAccount: newBillingAcc,
  //       });
  //     }
  //   });
  // }

  //here 
  addDefaultBilling(model) {

    const newContent = {
      id: "defaultBillingAccount",
      billingAccount: model.billingAccount
    }

    return this.db.collection('DefaultBillingAccount').doc(newContent.id).set(newContent);


  }

  getDefaultBillingById(name) {
    return this.db.collection('DefaultBillingAccount', ref => ref.where('billingAccount', '==', name));
  }

  getDefaultBilling() {
    return this.db.collection('DefaultBillingAccount');

  }

  updateDefaultBilling(newBillingName: any) {
    let billingName = "defaultBillingAccount"

    const docRef = this.db.collection('DefaultBillingAccount').doc(billingName);
    return docRef.get().subscribe((doc) => {
      if (doc.exists) {
        docRef.update({
          billingAccount: newBillingName,
        });
      } else {
        docRef.set({
          billingAccount: newBillingName,
        });
      }
    });
  }


  // email-password setup

  // emailPassSetting(email: any, password:any) {
  //   let accountId = "defaultAccountId"

  //   const docRef = this.db.collection('EmailPassSetting').doc(accountId);
  //   return docRef.get().subscribe((doc) => {
  //     if (doc.exists) {
  //       docRef.update({
  //         email: email,
  //         password: password,
  //         setup: true
  //       });
  //     } else {
  //       docRef.set({
  //         email: email,
  //         password: password,
  //         setup: true
  //       });
  //     }
  //   });
  // }

  emailPassSetting(email: any, password: any) {
    let accountId = "defaultAccountId"

    const docRef = this.db.collection('EmailPassSetting').doc(accountId);
    return docRef.get().subscribe((doc) => {
        if (doc.exists) {
            // Retrieve the existing data
            let existingData = doc.data();
            if (!existingData['emailPasswordPairs']) {
                existingData['emailPasswordPairs'] = [];
            }

            // Append new email-password pair
            existingData['emailPasswordPairs'].push({ email: email, password: password });

            // Update the document with the modified data
            docRef.update({
                emailPasswordPairs: existingData['emailPasswordPairs'],
                setup: true
            });
        } else {
            // If document doesn't exist, create a new one with the email-password pair
            docRef.set({
                emailPasswordPairs: [{ email: email, password: password }],
                setup: true
            });
        }
    });
}


  getEmailPassSetting() {
    return this.db.collection('EmailPassSetting');

  }

  updateDefaultEmail(newEmailName: any) {
    let emailName = "defaultEmail"

    const docRef = this.db.collection('DefaultEmail').doc(emailName);
    return docRef.get().subscribe((doc) => {
      if (doc.exists) {
        docRef.update({
          email: newEmailName,
        });
      } else {
        docRef.set({
          email: newEmailName,
        });
      }
    });
  }

  getDefaultEmail() {
    return this.db.collection('DefaultEmail');

  }

  


}
