import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionListComponent implements OnInit {
  @Input() sectionID;
  @Input() calledFromEmptyDashboard;

  showLoading = false;
  posts: any;
  currentUser: any;
  isMobile = false;
  filteredPosts: any = [];
  interestAreas: any = [];
  selectedInterestAreas: any = [];
  selectedInterestAreaIDs: any = [];
  addInterestModalOpen = false;
  filterOpen = false;
  postTitleFilter: any;
  postPlaceFilter: any;
  postTimelineFilter = '';
  beforeTitle = [];
  beforePlace = [];
  beforeInterest = [];
  beforeTimeline = [];
  isAdmin = false;
  isUser = false;
  isEmployee = false;
  popularPosts = [];
  viewAsFeed = false;
  p = 1;

  permission: any;
  perm: any;
  blogsName = 'Blogs';

  areaFilterClicked = false;
  depFilterClicked = false;
  timeFilterClicked = false;

  permCustomPage:any;
  sectionId: any;
  sectionName: any;
  pageSections: any;
  renameModalOpen = false;
  section: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  currentSection: any;
  openMoveModal = false;

  constructor(private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    private permissionService: PermissionService,
    private helperServices: HelperService,
    private customSectionService: CustomSectionService,
    private cdr: ChangeDetectorRef,
    private helperService: HelperService,
    private settingsService: AppSettingsService,
  ) {
    if (typeof window['cordova'] !== 'undefined') {
    this.isMobile = true;
    }
    this.getSettings();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if(this.calledFromEmptyDashboard =='custom'){
      this.sectionId = this.sectionID

    }else{
    this.route.paramMap.subscribe(params => {
      this.sectionId = params.get('id');
      this.cdr.detectChanges();
    });
  }
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser){
      if (this.currentUser.role == 'Admin'){
        this.isAdmin = true;
      }
    }

    this.posts = [];
    this.customSectionService.getCustomSectionById(this.sectionId).valueChanges().subscribe((section: any) => {
      if(section.length > 0){
        this.currentSection = section[0]
        this.customSectionService.getAllElementsBySectionId(section[0].id).valueChanges().subscribe(elements => {
          // if (elements.length > 0){
            this.posts = elements;
            this.cdr.detectChanges();
          // }
  
        });
      }
    })


    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.role === 'Admin') {
        this.isAdmin = true;
      }
      if (this.currentUser.role === 'User') {
        this.isUser = true;
      }
      if (this.currentUser.role === 'Employee') {
        this.isEmployee = true;
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
          if (permission && permission.length > 0){
            this.perm = permission[0];
            this.permCustomPage = this.perm.permission[6];
          }
        });

        this.cdr.detectChanges();
      }
    }

    if(this.calledFromEmptyDashboard =='custom' ){
      this.sectionId = this.sectionID
    }else{
      this.route.paramMap.subscribe(params => {
        this.sectionId = params.get('id');
        this.cdr.detectChanges();
      });
    }

    this.posts = [];
    this.customSectionService.getFromMenu().subscribe((from )=> {
      if(from && from.length > 0){
        this.sectionName = from;
      }
      this.customSectionService.getCustomSectionBySectionTitle(from).valueChanges().subscribe((sectionDetail: any) => {
        if(sectionDetail && sectionDetail.length > 0){
          this.customSectionService.getAllElementsBySectionId(sectionDetail[0].id).valueChanges().subscribe(elements => {
            if (elements && elements.length > 0){
              this.posts = elements;
              // this.posts.sort((a, b) => {
              //   return b.timeStamp - a.timeStamp;
              // });
              this.cdr.detectChanges();
            }
    
          });
        }
      })
    });


  }

  backClicked(){
    this.location.back();
  }
  getUrl(url) {
    return this.helperServices.getUrl(url);
  }

  truncateHTML(text: any, limit): string {
   return this.helperServices.truncateHTML(text, limit);
  }  

  navigate(section) {
    if (this.currentUser) {
      if (this.currentUser.role === 'Admin') {
        this.router.navigate(['/admin/view-custom-section', section.id]);
      }
      if (this.currentUser.role === 'User') {
        this.router.navigate(['/user/view-custom-section', section.id]);
      }
      if (this.currentUser.role === 'Employee') {
        this.router.navigate(['/employee/view-custom-section', section.id]);
      }
    } else {
      this.router.navigate(['/view-custom-section', section.id]);
    }
  }

  getSortedList(unsortedElements: Array<any>){
    let sortedList = unsortedElements.sort((a:any, b:any ) => {
        return a.position- b.position;
    });
    return sortedList
  }
  getInnerText(html){
    return this.helperService.getInnerText(html);
  }

  selectItem(){
    this.pageSections.appSections.forEach(section => {
      if(section.originalName == this.currentSection.originalName){
        this.section = section;
        this.renameModalOpen = true;
      }
    });
  }
  renamePage(){
    this.settingsService.updateAppSettings(this.pageSections).then(()=>{
      this.renameModalOpen = false;
      this.toast({html: "Page renamed succesfully!", type: 'success', classes: 'red' })
    })
  }
  getSettings() {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          this.cdr.detectChanges();
          }
        }
      }
    )
  }
  sectionInputChange(offer, event){
    this.pageSections.appSections.forEach(section => {
      if (section.originalName == offer.originalName){
        section.name = event.target.value;
      }
    });
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.cdr.detectChanges();
      // this.backClicked();
    }, 2000);
  }

  openConfirmationModal(){
    this.toast({html: 'Successfully  posted a blog!', classes: 'green', type: 'success'});
  }
  checkVisibility(visibility: string = 'Public'): boolean {
    const currentRole = this.currentUser?.role || 'Public';

    const roleHierarchy = {
        'Public': 1,
        'Employee': 2,
        'User': 3
    };

    return roleHierarchy[currentRole] >= roleHierarchy[visibility];
  }
}



