<div class="flex gap-4 md:flex-row flex-col">
  <div class="flex flex-col bg-white p-4 shadow-md gap-4 w-full md:max-w-sm">
      <app-page-view-setup type="primary" calledFrom="list"></app-page-view-setup>
      <div class="flex justify-end bg-slate-100 py-1 w-full px-4 gap-x-12">
        <p class="text-gray-600 text-base font-bold ">Go to Page</p>
        <p class="text-gray-600 text-base font-bold ">
          MOVE
        </p>
        
      </div>
      <div *ngIf="sectionsSetting && pageSections " cdkDropList class="example-list w-full  " (cdkDropListDropped)="drop($event)">
      
        <div *ngFor="let section of listElement" cdkDrag class="example-box flex justify-between w-full" >
          <div class="flex flex-col space-y-1 pl-4">
            <span>
              <span class="font-bold">
                {{section.name}} 
              </span>
              <label  (click)="selectItem(section.section)" class="text-base cursor-pointer flex items space-x-2" for="my-modal">
                  <svg id="Layer_1" data-name="Layer 1" width="16" height="16"  class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 765 598.6"><path d="M107.93,396.26q-6.66,0-10.69-3.05A12.36,12.36,0,0,1,92.38,385q-.84-5.14,1.94-11.53l73.31-162.44q3.6-8.06,8.75-11.52a20.51,20.51,0,0,1,11.8-3.48,20.1,20.1,0,0,1,11.52,3.48q5.13,3.47,8.75,11.52L282,373.49q3.06,6.39,2.22,11.66a12.17,12.17,0,0,1-4.72,8.2q-3.88,2.91-10.27,2.91-7.77,0-12.08-3.75T249.54,381l-18-41.93,15,9.72H129.31l15-9.72L126.53,381Q122.92,389,119,392.65T107.93,396.26Zm79.69-159.39-38.87,92.47-7.22-8.88h92.74l-6.94,8.88-39.15-92.47Z" transform="translate(-20 -6.7)" style="fill:#231f20"/><path d="M331.46,394q-9.16,0-14-4.86t-4.86-14v-158q0-9.16,4.86-14t14-4.86H398.1q20.55,0,35,6.11t22.07,17.49q7.63,11.38,7.64,27.21,0,17.78-10.27,30t-28,16.38V291.3q20.55,3.06,31.93,15.55t11.39,32.49q0,26.1-17.63,40.4t-49,14.3ZM346.73,281.3H392q18.33,0,27.35-7.08t9-21q0-13.89-9-21T392,225.21H346.73Zm0,85.81h50q18.89,0,27.77-7.36t8.89-22.08q0-15-8.89-22.21t-27.77-7.22h-50Z" transform="translate(-20 -6.7)" style="fill:#231f20"/><path d="M729.53,111.86H613.2V39.06h96.64a16.18,16.18,0,0,0,0-32.36H484.21a16.18,16.18,0,0,0,0,32.36h96.64v72.8H75.47A55.53,55.53,0,0,0,20,167.33V444.67a55.53,55.53,0,0,0,55.47,55.47H580.85v72.8H484.21a16.18,16.18,0,0,0,0,32.36H709.84a16.18,16.18,0,0,0,0-32.36H613.2v-72.8H729.53A55.53,55.53,0,0,0,785,444.67V167.33A55.53,55.53,0,0,0,729.53,111.86ZM75.47,467.78a23.13,23.13,0,0,1-23.11-23.11V167.33a23.13,23.13,0,0,1,23.11-23.11H580.85V467.78Zm677.17-23.11a23.14,23.14,0,0,1-23.11,23.11H613.2V144.22H729.53a23.14,23.14,0,0,1,23.11,23.11Z" transform="translate(-20 -6.7)" style="fill:#231f20"/></svg>
                  <span>Rename</span>
              </label>
               </span>
         
          </div>
          <div class="flex space-x-4 md:space-x-16 pr-4">
            <button type="button" (click)="navigateTo(section.section.originalName)">
              <i class="material-icons white-text">edit</i>
          </button>
            <div class="example-handle" cdkDragHandle>
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
          </div>
            </div>
    
            <div *ngIf="listElement.length == 0" class="flex flex-col justify-center space-x-2 my-4">
              <p  class="text-center text-xl font-bold text-customTeal">Only visible sections will be displayed here</p>
              <p class="text-center text-base  text-gray-500">Please change the visibility of the pages you would like to show on your Homepage</p>
           <div class="w-full  flex justify-center pt-4">
            <button
            type="button"
            [routerLink]="['/admin/dashboard']"
            class="flex space-x-2 items-center rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        >
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="fill-white" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
            <span>Back to Dashboard</span></button
            >  
           </div> 
            </div>
    
      </div>
      <div *ngIf="sectionsSetting && pageSections " class=" w-full flex justify-end ">
        <button 
        type="button"
        class="ml-1 float-right inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      
            style="margin-bottom: 5px;" (click)="landingPageConfirmationUpdate();">
       Save Changes </button>
      
      
    </div>
</div>

              <!-- preview -->
              <div class="bg-white p-4 shadow-md space-y-4 w-full md:w-full">
                <!-- Mobile view vs Web view tab -->
           <!-- <div class="flex font-bold  mx-auto justify-star w-full " >
                  <div class="text-sm text-center"><button type="button"  (click)="changePageView('web')" class="px-2 py-2 font-bold border-2 md:px-10 rounded-md border-gray-200 flex items-center space-x-2" [ngClass]="{'text-white bg-customTeal fill-white' : pageView == 'web', 'text-customTeal bg-white fill-customTeal': pageView !='web'}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                    </svg>
                    <span class="text-base">Web View</span>
                  </button></div>
                  <div class=" text-sm text-center"><button type="button" (click)="changePageView('mobile')"  class="px-2 py-2 font-bold border-2  rounded-md md:px-10 border-gray-200 flex items-center space-x-2  " [ngClass]="{'text-white bg-customTeal fill-white': pageView == 'mobile', 'text-customTeal bg-white fill-customTeal': pageView !='mobile'}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                    </svg>
                    <span class="text-base">Phone View</span>
                  </button></div>
               
             </div> -->
             <hr>
          <div  class=" space-y-4" >
            <p class="font-bold capitalize">{{pageView}} Preview </p>
        
            <div *ngIf="pageView=='web'">
              
              <div class="mockup-window border bg-gray-300" >
                <div class=" flex  justify-center px-4 overflow-x-hidden max-h-screen scrollbar bg-gray-200">
                  <app-landing-page class="w-full" [preview]="true" [view]='pageView' [landingPageSettings]="true"></app-landing-page>
                </div>
              </div>
        
            </div>
        
            <div *ngIf="pageView=='mobile'">
        
              <div class=" max-w-sm max-h-screen overflow-x-hidden scrollbar shadow-md rounded-sm border-2 border-solid  border-gray-200">
                <app-landing-page [preview]="true" view='mobile'></app-landing-page>
              </div>
            </div>
        </div>
  
        
      
        </div>
</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" 
[toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
