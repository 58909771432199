import { NavigationExtras, Router } from '@angular/router';
import { PostsService } from './../../../../services/firebase/posts.service';
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { isSameYear, isSameWeek, isSameMonth, isToday } from 'date-fns';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { HelperService } from 'src/app/services/helper/helper';


@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {

  @Input() calledFrom;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  showLoading = false;
  posts: any;
  currentUser: any;
  isMobile = false;
  filteredPosts: any = [];
  addInterestModalOpen = false;
  filterOpen = false;
  postTitleFilter: any;
  postPlaceFilter: any;
  postTimelineFilter = '';
  beforeTitle = [];
  beforePlace = [];
  beforeInterest = [];
  beforeTimeline = [];
  isAdmin = false;
  isUser = false;
  popularPosts = [];
  viewAsFeed = false;
  p = 1;

  permission: any;
  perm: any;
  blogsName = 'Blogs';

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;

  emptyBlog ={
    img: 'https://images.unsplash.com/photo-1699469786384-3717a73da415?q=80&w=2013&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 
    date: new Date().toJSON().slice(0, 10),
    shareWith: 'Public',
    postTitle : 'Examples of Blogs From Every Industry, Purpose, & Readership',
    message: "When you hear the word “blog”, what do you think of? Maybe your mind goes to stories about travel, yoga, and exciting new restaurants to try. What if I told you that, although these thoughts may be valid, other terms and phrases should be coming to mind? These include conversions, a boost in revenue, calls to action, inbound marketing, and improving customer relationships. Blogs are powerful business tools. They improve conversion rate, foster relationships between your business and audience members and customers, boost revenue, promote brand awareness, increase your ranking on search engines, and positively impact your bottom line. In this guide, we’ll review the different types of blogs there are and examples of each. We've also added a 'Niche Industries' section, because there's a blog for everything out there, including your industry. These will give you a better understanding of the various ways your business can write and publish content to help you achieve the benefits we listed above … and more."
  }
  openMoveModal = false;
  openModal: boolean;

  
  displayedBlogs: any[] = []; // Array to hold the users to be displayed on the current page
  // p: number = 1;
  total: number = 0;
  itemsPerPage: number = 5;
  
  constructor(private location: Location,
              private authService: AuthService,
              private postsService: PostsService,
              private router: Router,
              private permissionService: PermissionService,
              private helperServices: HelperService,
              private productsServiceMenuService: ProductsServiceMenuService,
    ) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.role === 'Admin') {
        this.isAdmin = true;
      }
      if (this.currentUser.role === 'User') {
        this.isUser = true;
      }
      if (this.currentUser.role === 'Employee') {
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
          if (permission && permission.length > 0){
      
            this.perm = permission[0];
            //this.permission = this.perm.permission[0];
  
            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];
          }
        });
      }
      
    }
    this.showLoading = true;
    this.getPosts();

    this.productsServiceMenuService.blogsName.subscribe(blogname => {
      this.blogsName = blogname;
    });

  }
  getPosts(){
    if (this.currentUser){
      if (['Admin', 'Employee'].includes(this.currentUser.role)){
        this.postsService.getAllPosts().valueChanges().subscribe(posts => {
          if (posts.length > 0){
            this.posts = posts;
            this.filteredPosts = posts;
            this.filteredPosts.sort((a, b) => {
              return b.timeStamp - a.timeStamp;
            });
            this.updateDisplayedUsers()

            this.popularPosts = [...this.posts];
            this.popularPosts.sort((a, b) => {
              return b.comments.length - a.comments.length;
             });

            this.showLoading = false;
          }
        });
      }
      if (this.currentUser.role === 'User'){
        this.postsService.getUserPosts().valueChanges().subscribe(posts => {
          if (posts.length > 0){
            this.posts = posts;
            this.filteredPosts = posts;
            this.filteredPosts.sort((a, b) => {
              return b.timeStamp - a.timeStamp;
            });
            this.updateDisplayedUsers()

            this.popularPosts = [...this.posts];
            this.popularPosts.sort((a, b) => {
              return b.comments.length - a.comments.length;
             });

            this.showLoading = false;
          }
        });
      }
    } else {
      this.postsService.getPostsByShareType('Public').valueChanges().subscribe(posts => {
        if (posts.length > 0){
          this.posts = posts;
          this.filteredPosts = posts;
          this.filteredPosts.sort((a, b) => {
            return b.timeStamp - a.timeStamp;
          });
          this.updateDisplayedUsers()

          this.popularPosts = [...this.posts];
          this.popularPosts.sort((a, b) => {
            return b.comments.length - a.comments.length;
           });

          this.showLoading = false;
        }
      });

    }
    this.showLoading = false;
  }

  backClicked(){
    this.location.back();
  }
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
      return url;
    }
  }

  onTimeframeChange() {
    if (this.postTimelineFilter === 'allTime') {
      this.filteredPosts = this.filteredPosts;
    }

    if (this.postTimelineFilter === 'thisYear') {
      this.filteredPosts = this.filteredPosts.filter((post) => {
        return isSameYear(new Date(post.timeStamp.toDate()), new Date());
      });
    }

    if (this.postTimelineFilter === 'thisWeek') {
      this.filteredPosts = this.filteredPosts.filter((post) => {
        return isSameWeek(new Date(post.timeStamp.toDate()), new Date());
      });
    }

    if (this.postTimelineFilter === 'thisMonth') {
      this.filteredPosts = this.filteredPosts.filter((post) => {
        return isSameMonth(new Date(post.timeStamp.toDate()), new Date());
      });
    }

    if (this.postTimelineFilter === 'today') {
      this.filteredPosts = this.filteredPosts.filter((post) => {
        return isToday(new Date(post.timeStamp.toDate()));
      });
    }
  }

  truncateHTML(text: any, limit): string {
   return this.helperServices.truncateHTML(text, limit);
  }
  

  navigate(blog) {
    if (this.currentUser) {
      if (this.currentUser.role === 'Admin') {
        this.productsServiceMenuService.setCalledFromData(this.calledFrom)
        this.router.navigate(['/admin/view-blog', blog.id]);
      }
      if (this.currentUser.role === 'User') {
        this.router.navigate(['/user/view-blog', blog.id]);
      }
      if (this.currentUser.role === 'Employee') {
        this.router.navigate(['/employee/view-blog', blog.id]);
      }
    } else {
      this.router.navigate(['/view-blog', blog.id]);

    }

  }
  getSortedList(unsortedElements: Array<any>){
    let sortedList = unsortedElements.sort((a:any, b:any ) => {
        return a.position- b.position;
    });
    return sortedList
  }
  getInnerText(text){
    return this.helperServices.getInnerText(text);
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  openConfirmationModal(){
    this.toast({html: 'Successfully  posted a blog!', classes: 'green', type: 'success'});
  }

  pageChangeEvent(event: number) {
    this.p = event;
    this.updateDisplayedUsers();
  }

  updateDisplayedUsers() {
    this.total = this.filteredPosts.length;
    const startIndex = (this.p - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedBlogs = this.filteredPosts.slice(startIndex, endIndex);
  }
}
