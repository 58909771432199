<ng-container *ngIf="settings && settings.isBusinessApp" class="bg-gray-100">

<div class="bg-gray-100 min-h-screen">
  <div *ngIf="viewProduct">
    <div class="lg:mr-24 flex justify-end">
      <!-- <button [routerLink]="['/admin/create-product']" class="btn-custom-solid mx-5 "><i
        class="material-icons float-left mr-2">inventory_2</i>Add {{productsName}}</button> -->
      <!-- <button  class="lg:ml-3 hover:text-black py-2 px-6 mt-2 mb-3 rounded-md border border-customTeal lg:mr-16 bg-gray-100 text-customTeal" (click)="toggleFilter()"><i class="material-icons float-left">tune</i>Filter</button> -->
      <div *ngIf="isAdmin">
        <button 
        type="button"
        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
       (click)="openMoveModal=true" 
        class="flex items-center mx-5 space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
        <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
          <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
        <span>Arrange Items</span>  
        
      </button>
      </div>
    </div><br/>

    <div class=" ">
      <div *ngIf="products.length > 0" class="flex flex-wrap gap-x-4  w-full  md:gap-y-8 gap-y-4 px-4 md:gap-x-6 justify-center  mx-auto mt-5 ">
        <div *ngFor="let product of getSortedList(products)" class="w-full h-max   md:mx-4  md:mb-10 flex  md:min-h-max  border bg-white md:w-[341px] md:h-[311px] drop-shadow-md rounded-lg hover:scale-100 hover:ease-in hover:duration-300" [ngStyle]="{'background-color': product.backgroundColor}">
          <!--has product list  -->
          <!-- <div  *ngIf="product.allProducts && product.allProducts.length > 0" class=" border border-gray-200 drop-shadow-lg rounded-lg hover:scale-105 hover:ease-in hover:duration-300 " [ngStyle]="{'background-color': product.backgroundColor}">
          <ng-container (click)="navigate(product)">
            <div *ngIf="product.imgLink && !product.videoLink" (click)="navigate(product)"  class="bg-white relative w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
              
              <div class="flex justify-center h-40 md:h-48 " >
                <img [src]="product.imgLink" class="md:w-56 h-full w-full rounded-lg mt-2" style="object-fit: contain;">
              </div>
              
            </div>
            <div>
              <div *ngIf="product.videoLink && product.imgLink" (click)="navigate(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo" [poster]="product.imgLink"></video>           
              </div>
              <div *ngIf="product.videoLink && !product.imgLink" (click)="navigate(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo"></video>           
              </div>
              <div *ngIf="!product.imgLink && !product.videoLink" (click)="navigate(product)" class="relative w-full h-40 md:h-48 rounded-t-xl cursor-pointer bg-gray-200" >  
                
              </div>
            </div>
            
          </ng-container> 
          <div class="break-words">
            <h4  (click)="navigate(product)" class="w-full bottom-0 text-black bg-white font-bold cursor-pointer max-w-sm px-4 py-2 " >{{truncateHTML(getInnerText(product.productName),60)}}</h4>
              
          </div>
          <div class=" flex flex-col w-full space-y-4 px-4 rounded-b-xl  py-4  bg-white ">
                  
          
              
              <div *ngIf="product.allProducts " class="flex flex-col">
                <ng-container *ngFor="let ser of product.allProducts | slice:0:2;" class="w-full">
                  <div class="flex flex-col w-full mb-5">
                    <div tabindex="0" class="w-full collapse collapse-plus border  rounded-sm border-base-300 bg-gray-100 ">
                      <input type="checkbox" class="peer" />
                      <div  class="collapse-title cursor-pointer text-base font-semibold break-words" >
                        {{ser.productTitle}}
                      </div>
                      <div class="collapse-content break-words">
                        <p>$ {{ser.productPrice}}</p>
                        <p class="italic">{{ser.productUnderDescription}}</p>
                    
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="flex justify-end" (click)="navigate(product) " *ngIf="product.allProducts.length > 2 ">
                  <p class="text-sm font-bold text-customTeal">Show all</p>

                </div>
              </div>  
       
            </div>
          </div>  -->
          <!-- doesnt have product list -->
          <div (click)="navigate(product)"  class="flex flex-col w-full   px-4  py-4  md:px-6   md:h-min border   rounded-lg hover:scale-100 hover:ease-in hover:duration-300 ">
            
            <ng-container>
              <div *ngIf="product.imgLink && !product.videoLink" (click)="navigate(product)"  class="w-full md:h-48 rounded-t-xl  cursor-pointer">
                <div class="flex justify-center md:h-48 " >
                  <img [src]="product.imgLink" class="md:w-56 h-full w-full rounded-lg mt-2 object-contain" >
                </div>
              </div>
              <div *ngIf="product.videoLink && !product.imgLink" (click)="navigate(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo"></video>           
              </div>
              <div *ngIf="!product.imgLink && !product.videoLink" (click)="navigate(product)" class="relative w-full h-40 md:h-48 rounded-t-xl cursor-pointer bg-gray-200" >  
                
              </div>
              <div>
                <div *ngIf="product.videoLink && product.imgLink" (click)="navigate(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                  <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo" [poster]="product.imgLink"></video>           
                </div>
                <div *ngIf="product.videoLink && !product.imgLink" (click)="navigate(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                  <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo"></video>           
                </div>
              </div>
              
            </ng-container>
            <div class=" flex flex-col w-full space-y-2 px-4  break-words">
                  
             
              <h4 (click)="navigate(product)"  class="cursor-pointer max-w-sm text-xl font-bold py-2 text-center " >{{truncateHTML(getInnerText(product.productName),40)}}</h4>

              <div *ngIf="product.price">
                <div *ngIf=" product.price!='dontInclude'">
                  <div *ngIf="product.price > 0" href="" class="  text-center  rounded-md baseline  bottom-0 md:ml-0">
                    <ng-container *ngIf="currency"> {{product.price | currency: currency:'symbol-narrow'}}</ng-container>
                    <ng-container *ngIf="!currency">{{product.price | currency: 'USD':'symbol-narrow'}}</ng-container>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
        
        <!-- <div class="w-full text-center">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div> -->
      </div>
      <div *ngIf="products.length==0 || !products" class="mt-10 p-6 max-w-sm mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 text-center">
        <div>
          <div><i class="material-icons w-20 h-20">search</i></div>
          <p class="font-bold">Sorry! No results found</p>
          <p>We couldn't find products that matches your search! </p>
          <button class="btn bg-slate-300 text-black hover:bg-gray-50 " (click)="clearFilters()">Okay</button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
</ng-container>

<app-move-elements *ngIf="openMoveModal" [elementsList]="products" (closeClicked)="openMoveModal=false" sectionType="Products"></app-move-elements>