import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';

@Component({
  selector: 'app-view-form',
  templateUrl: './view-form.component.html',
  styleUrls: ['./view-form.component.css']
})
export class ViewFormComponent implements OnInit {

forms: any;

  constructor(private clientFormService: ClientFormService,
              private router: Router) { }

  ngOnInit(): void {


    this.clientFormService.getFormList().valueChanges().subscribe(form => {
      this.forms = form;
    });

  }


  goToForm(form){
    this.router.navigate(['user/profile-form',form.id]);
  }
  
  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }
}
